const userGroups = [
  {
    group_name: 'LGU Admin',
    id: 1,
  },
  {
    group_name: 'LGU Health Worker',
    id: 2,
  },
  {
    group_name: 'CareGo Admin',
    id: 3,
  },
  {
    group_name: 'Company Admin',
    id: 4,
  },
  {
    group_name: 'Company Staff',
    id: 5,
  },
  {
    group_name: 'Facility Admin',
    id: 6,
  },
  {
    group_name: 'Facility Staff',
    id: 7,
  },
];

export { userGroups };
