import { Box, Divider, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, RegularButton } from 'core/components';
import React, { useContext, useEffect, useState } from 'react';
import {
  createAndDestroy,
  getClinicAccessOfAPatient,
  removePatientClinicAccess,
} from 'company/api/patient-clinic-access';

import { FacilityContext } from 'core/context/facility.context';
import FacilitySearchDropDown from 'company/components/dropdown/FacilitySearchDropdown';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { tokens } from 'theme/theme';

type Props = {
  selectedPatientId: number;
};

export default function PatientClinicAccess({ selectedPatientId }: Props) {
  const { facility } = useContext(FacilityContext);
  const [loadingClinics, setLoadingClinics] = useState<boolean>(false);
  const [clinics, setClinics] = useState([]);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [selectedClinicToAdd, setSelectedClinicToAdd] = useState([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getPatientAccess() {
      setLoadingClinics(true);
      const res = await getClinicAccessOfAPatient(facility.id, selectedPatientId);
      setClinics(res.data);
      setLoadingClinics(false);
    }
    getPatientAccess();
  }, [selectedPatientId, forceRefresh, facility?.id]);

  const addedClinics = clinics.map((clinic: any) => {
    return { key: clinic.facility_name, value: clinic.facility_id };
  });

  async function handleSubmit() {
    const clinics = selectedClinicToAdd.map((clinic: any) => clinic.value);
    const data = {
      patient_id: selectedPatientId,
      clinics,
    };

    setSubmitLoading(true);
    await createAndDestroy(facility.id, data);
    setSubmitLoading(false);
    setForceRefresh((prev) => prev + 1);
  }

  return (
    <Box sx={{ width: '100%' }}>
      {!loadingClinics && clinics && addedClinics && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '15px',
            mb: '20px',
          }}
        >
          <Box flex={1}>
            <FacilitySearchDropDown
              useTemporaryMultipleInitialValueSolution
              preventRemovalOfInitialValue
              initialValue={addedClinics}
              handleChangeCallback={(record) => {
                setSelectedClinicToAdd(record);
              }}
            />
          </Box>
          <RegularButton onClick={() => handleSubmit()} styles={{ flex: 1, heigth: '100%' }} loading={submitLoading} />
        </Box>
      )}

      {loadingClinics && <LoadingSkeleton />}
      {!loadingClinics && !clinics && <Typography>No Clinics Found.</Typography>}
      {!loadingClinics && clinics && (
        <ClinicList
          clinics={clinics}
          setForceRefresh={setForceRefresh}
          setSelectedClinicToAdd={setSelectedClinicToAdd}
        />
      )}
    </Box>
  );
}

function ClinicList({ clinics, setForceRefresh, setSelectedClinicToAdd }: any) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedClinic, setSelectedClinic] = useState<any>({});

  async function handleRemoveAccess(selectedClinic: any) {
    await removePatientClinicAccess(facility.id, selectedClinic.id);
    setForceRefresh((ref: number) => ref + 1);
    setSelectedClinicToAdd((prev: any) => prev.filter((clinic: any) => clinic.value !== selectedClinic.facility_id));
  }

  if (!clinics) return <></>;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', maxHeight: '1000px', overFlowY: 'auto' }}>
      <Divider sx={{ height: '5px' }}></Divider>
      <Typography sx={{ fontWeight: '600', fontSize: '18px', color: colors.accent }}>Clinics with access</Typography>
      {clinics.map((clinic: any, index: number) => (
        <>
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '8px',
              backgroundColor: colors.light_blue_background,
              padding: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <LocalHospitalIcon sx={{ color: colors.accent }} />
              <Typography sx={{ fontWeight: '600' }}>
                {clinic.facility_name} {clinic.facility_id === facility.id ? `(In Use)` : ''}
              </Typography>
            </Box>
            <Tooltip title="Remove" placement="left-end">
              <RemoveCircleOutlineOutlinedIcon
                sx={{ color: colors.redAccent, cursor: 'pointer' }}
                onClick={() => {
                  setSelectedClinic(clinic);
                  setOpenDeleteModal(true);
                }}
              />
            </Tooltip>
          </Box>
        </>
      ))}
      <ConfirmationDialog
        content={`Remove patient ${selectedClinic.full_name} from ${selectedClinic.facility_name} clinic?`}
        setOpen={setOpenDeleteModal}
        open={openDeleteModal}
        confirmButtonLabel="Confirm"
        onConfirm={() => handleRemoveAccess(selectedClinic)}
      ></ConfirmationDialog>
    </Box>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <Skeleton animation="wave" height="50px" />
      <Skeleton animation="wave" height="50px" />
      <Skeleton animation="wave" height="50px" />
    </>
  );
}
