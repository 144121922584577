import { EXPENSE_ENTITY_NAME, ExpenseModel, expenseEntityFields, visibleExpenseColumns } from './ExpenseModel';
import { deleteExpense, getExpenses } from 'company/api/expenses';
import { forwardRef, useContext, useRef, useState } from 'react';

import ExpenseForm from './ExpenseForm';
import { FacilityContext } from 'core/context/facility.context';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from '../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';

type Props = {
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  visibleColumns?: string[];
  withContainer?: boolean;
  tableAction?: TableActionProps;
  tableRef?: any;
};

const ExpenseTable = forwardRef((props: Props, ref) => {
  const { facility } = useContext(FacilityContext);
  const { setDrawerSelectedRow, drawerSelectedRow, visibleColumns, withContainer, tableRef } = props;

  const [selectedRow, setSelectedRow] = useState<ExpenseModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleDelete = async (rowid: number) => {
    await deleteExpense(facility.id, rowid);
  };

  const handleGetExpenses = async () => {
    return getExpenses(facility.id);
  };

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        ref={mainRef}
        rowId={'id'}
        entityName={EXPENSE_ENTITY_NAME}
        fields={expenseEntityFields}
        visibleColumns={visibleColumns ?? visibleExpenseColumns}
        templateForm={<ExpenseForm expense={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
        handleDelete={handleDelete}
        getData={handleGetExpenses}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        withContainer={withContainer}
        tableComponent={{ hideSearch: !!selectedRow }}
      />
    </WaitForFacility>
  );
});

export default ExpenseTable;
