/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CustomTabs } from 'core/components';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import SmsActivity from './SmsActivity';
import SmsTemplates from './SmsTemplates';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import { TabProps } from 'core/components/CustomTabs';

const SmsTemplatesScreen = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    { label: 'Schedules and History', content: <SmsActivity />, icon: <EventRepeatOutlinedIcon /> },
    { label: 'SMS Templates', content: <SmsTemplates />, icon: <SpeakerNotesOutlinedIcon /> },
  ];

  useEffect(() => {
    setBreadcrumb([{ label: 'SMS' }]);
  }, []);

  return (
    <Box>
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default SmsTemplatesScreen;
