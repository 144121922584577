import 'react-quill/dist/quill.snow.css';

import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import { ImageResize } from 'quill-image-resize-module-ts';
import { tokens } from 'theme/theme';

Quill.register('modules/imageResize', ImageResize);
type RichTextEditorProps = {
  content: string;
  onChange?: (value: string) => void;
  height?: string;
  readOnly?: boolean;
  editFormMode?: boolean;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  content,
  onChange,
  height = '40vh',
  readOnly,
  editFormMode,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [value, setValue] = useState<any>(content);
  var toolbarOptions = [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      'link',
      'image',
      { list: 'ordered' },
      { align: [] },
      { indent: '-1' },
      { indent: '+1' },
      { header: [1, 2, 3, false] },
      { color: [] },
      { background: [] },
      { font: [] },
    ],
    // ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // ['link', 'image'],
    // [{ list: 'ordered' }],
    // [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ align: [] }],
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ header: [1, 2, 3, false] }],
    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ direction: 'rtl' }], // text direction

    // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

    // ['clean'], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const handleChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    let cleanedContent = content ?? '';
    const threeOrMoreBlanks = /(<p><br><\/p>\s*){2,}/g;
    cleanedContent = cleanedContent.replace(threeOrMoreBlanks, '');
    setValue(cleanedContent);
  }, [content]);

  return (
    <Box
      sx={{
        '& .ql-container': {
          minHeight: readOnly ? (editFormMode ? '100px' : undefined) : height,
          border: 'none',
          borderRadius: readOnly && !editFormMode ? '10px' : undefined,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          backgroundColor: readOnly ? undefined : theme.palette.mode === 'light' ? '#f0f0f0' : '#464749',
          padding: readOnly ? 0 : '12px',
        },
        '& .ql-editor.ql-blank::before': {
          left: 'unset',
          right: 'unset',
          content: readOnly && !editFormMode ? `"No Data"` : undefined,
          color: colors.text,
        },
        '& .ql-editor': {
          minHeight: readOnly ? (editFormMode ? '100px' : undefined) : height,
          padding: readOnly ? 0 : undefined,
        },
        '& .ql-toolbar': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          padding: '10px',
          justifyContent: 'center',
          display: readOnly && !editFormMode ? 'none' : 'flex',
        },
        '& .ql-editor p': {
          fontFamily: 'Poppins',
        },
      }}
    >
      <ReactQuill
        theme="snow"
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        modules={module}
        placeholder="Write here"
      />
    </Box>
  );
};

export default RichTextEditor;
