import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import CustomIconButton from './IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RegularButton from './RegularButton';
import { tokens } from 'theme/theme';

type UpdateButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  styles?: SxProps<Theme>;
};

const UpdateButton: React.FC<UpdateButtonProps> = ({ onClick, styles }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isTablet = useMediaQuery('(max-width:1000px)');

  if (isTablet) {
    return (
      <CustomIconButton
        icon={<EditOutlinedIcon sx={{ color: colors.greenAccent }} />}
        onClick={onClick}
        tooltip="Update"
      />
    );
  }

  return (
    <RegularButton
      styles={{
        textTransform: 'none',
        backgroundColor: colors.greenAccent,
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: colors.green_hovered,
        },
        ...styles,
      }}
      size="small"
      startIcon={<EditOutlinedIcon color="success" fontSize="small" sx={{ color: '#fff' }} />}
      onClick={onClick}
      label="Update"
    />
  );
};

export default UpdateButton;
