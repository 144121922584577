/* eslint-disable react-hooks/exhaustive-deps */

import {
  INVOICE_ENTITY_NAME,
  createPatientBillingFormFields,
  patientBillingFields,
  patientBillingFormFields,
  patientBillingInitialValues,
  patientBillingSchema,
} from './PatientBillingModel';
import { createInvoice, updateInvoice } from 'company/api/patient-billing';

import { FacilityModel } from 'core/model/Entities';
import { RegularButton } from 'core/components';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { tokens } from 'theme/theme';
import { useState } from 'react';
import { useTheme } from '@mui/material';

type Props = {
  facility: FacilityModel;
  patientBilling?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  patientId?: number;
  callbackAfterSubmit?: (data?: any) => void;
};

const PatientBillingForm = (props: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isDraft, setIsDraft] = useState(false);
  const [loading, setLoading] = useState(false);

  const { facility, patientBilling, readOnly, showSubmitAtTop, patientId, callbackAfterSubmit } = props;

  const handleSubmit = (data: any) => {
    setLoading(true);
    const cleanServices = excludeServiceIfBlank(data.services);
    const cleanProducts = excludeProductIfBlank(data.products);
    data.services = cleanServices;
    data.products = cleanProducts;
    data = { ...data, is_posted: !isDraft };

    return patientBilling ? updateInvoice(facility.id, patientBilling.id, data) : createInvoice(facility.id, data);
  };

  const excludeServiceIfBlank = (services: any) => {
    let newData: any[] = [];
    if (services) {
      services.forEach((service: any) => {
        if (service.service && service.service !== '') {
          newData.push(service);
        }
      });
    }

    return newData;
  };

  const excludeProductIfBlank = (products: any) => {
    let newData: any[] = [];
    if (products) {
      products.forEach((product: any) => {
        if (product.product && product.product !== '') {
          newData.push(product);
        }
      });
    }

    return newData;
  };

  return (
    <TemplateForm
      entityName={INVOICE_ENTITY_NAME}
      fields={patientBillingFields(colors, facility.is_admin)}
      visibleFields={patientBilling ? patientBillingFormFields : createPatientBillingFormFields}
      entity={patientBilling}
      initialValues={
        patientBilling ? changeNullToBlank(patientBilling) : { ...patientBillingInitialValues, patient_id: patientId }
      }
      schema={patientBillingSchema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      submitButtonText={'Save and Confirm'}
      altSubmitComponent={
        <>
          {!loading && (
            <RegularButton
              type="submit"
              id={'patient-billing-submit-as-draft-btn'}
              variant={'outlined'}
              onClick={() => setIsDraft(true)}
              label=" Save As Draft"
            />
          )}
        </>
      }
    />
  );
};

export default PatientBillingForm;
