import { SERVICE_ENTITY_NAME, serviceFields, serviceTableColumns } from './ServiceModel';
import { deleteClinicService, getServicesAssigned } from 'company/api/services';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityModel } from 'core/model/Entities';
import ServiceForm from './ServiceForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from '../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';

type Props = {
  facility?: FacilityModel;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableAction?: TableActionProps;
  tableRef?: any;
  useColoredTable?: boolean;
  labServiceFiltered?: boolean;
};

const ServiceTable = forwardRef((props: Props, ref) => {
  const {
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    tableAction,
    tableRef,
    useColoredTable,
    labServiceFiltered,
  } = props;

  const { facility } = useContext(FacilityContext);
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const getData = (query: ApiQuery) => {
    const queryFilter = labServiceFiltered ? { ...query, type: 'is_lab_test' } : query;
    return getServicesAssigned(facility.id, queryFilter);
  };

  const handleDelete = (data: any) => {
    return deleteClinicService(data);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        tableComponent={{
          mobileViewConfig: () => ({
            title_fields: ['service_name'],
          }),
          rowHeight: 50,
        }}
        useColoredTable={useColoredTable}
        ref={mainRef}
        tourTriggerIndex={4}
        entityName={SERVICE_ENTITY_NAME}
        fields={serviceFields}
        visibleColumns={visibleColumns ?? serviceTableColumns}
        templateForm={<ServiceForm service={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
        getData={getData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        hideAddButton
        //if same lang ang addActionRequiredAuth and rowActionRequiredAuth, pwede iremove si addActionRequiredAuth
        tableAction={{
          ...tableAction,
          addActionRequiredAuth: ['MANAGE_SERVICES'],
          rowActionRequiredAuth: ['MANAGE_SERVICES'],
        }}
      />
    </WaitForFacility>
  );
});

export default ServiceTable;
