import { Box } from '@mui/material';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children }) => {
  return <Box sx={value !== index ? { height: 0, overflow: 'hidden' } : {}}>{children}</Box>;
};

export default TabPanel;
