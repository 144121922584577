import {
  CHART_OF_ACCOUNT_ENTITY_NAME,
  chartOfAccountFields,
  chartOfAccountVisibleColumns,
} from './ChartOfAccountModel';
import { deleteOne, getMany } from 'company/api/chart-of-account';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import BookIcon from '@mui/icons-material/Book';
import { Box } from '@mui/material';
import ChartOfAccountForm from './ChartOfAccountForm';
import { FacilityContext } from 'core/context/facility.context';
import GeneralLedgerTable from './Ledger/GeneralLedgerReport';
import JournalEntries from './Ledger/JournalEntries';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { useReactToPrint } from 'react-to-print';

type Props = {
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

// just a simple mock data
const ledgerReportMockDataFormat = [
  {
    id: 1,
    createdAt: '2023-01-04 12:47:00',
    transaction_type_code: 'EXPENSE', // pk
    reference_id: '0001',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 1, // pk
    account_name: 'Sample Customer',
    account_type_code: 'EXPENSE',
    account_type: 'Expenses',
    description: 'Bought something',
    transaction_type: 'Expenses',
    entry_type: 'debit',
    amount: 55.0,
  },
  {
    id: 2,
    createdAt: '2023-01-05 12:47:00',
    transaction_type_code: 'INVOICE',
    reference_id: '0002',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 1,
    account_name: 'Sample Customer',
    account_type_code: 'EXPENSE',
    account_type: 'Expenses',
    description: 'Invoice something',
    transaction_type: 'Invoice',
    entry_type: 'debit',
    amount: 500.0,
  },
  {
    id: 3,
    createdAt: '2023-01-06 12:47:00',
    transaction_type_code: 'PAYMENT',
    reference_id: '0003',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 1,
    account_name: 'Sample Customer',
    account_type_code: 'EXPENSE',
    account_type: 'Expenses',
    description: 'Paid something',
    transaction_type: 'Payment',
    entry_type: 'credit',
    amount: 124.5,
  },
  {
    id: 4,
    createdAt: '2023-01-07 12:47:00',
    transaction_type_code: 'INVOICE',
    reference_id: '0004',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 1,
    account_name: 'Sample Customer',
    account_type_code: 'EXPENSE',
    account_type: 'Expenses',
    description: 'Invoice something',
    transaction_type: 'Invoice',
    entry_type: 'credit',
    amount: 500.0,
  },
  {
    id: 5,
    createdAt: '2023-01-06 12:47:00',
    transaction_type_code: 'PAYMENT',
    reference_id: '0003',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 2,
    account_name: 'Another Customer',
    account_type_code: 'CASH',
    account_type: 'Cash',
    description: 'Got paid',
    transaction_type: 'Payment',
    entry_type: 'debit',
    amount: 1300.25,
  },
  {
    id: 6,
    createdAt: '2023-01-07 12:47:00',
    transaction_type_code: 'INVOICE',
    reference_id: '0004',
    source_account_id: 1,
    source_account_name: '-',
    account_id: 2,
    account_name: 'Another Customer',
    account_type_code: 'CASH',
    account_type: 'Cash',
    description: 'Released sum cash',
    transaction_type: 'Invoice',
    entry_type: 'credit',
    amount: 500.0,
  },
];

const ChartOfAccountTable = forwardRef((props: Props, ref) => {
  const { tableRef, drawerSelectedRow, setDrawerSelectedRow } = props;
  const { facility } = useContext(FacilityContext);

  const [selectedRow, setSelectedRow] = useState();

  const printableRef: any = useRef();
  const printableJournalRef: any = useRef();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.closeFormModal();
    mainRef.current.refreshTable();
  };

  const handlePrintLedger = useReactToPrint({
    content: () => printableRef.current,
  });

  const handlePrintJournal = useReactToPrint({
    content: () => printableJournalRef.current,
  });

  const handleGetData = (query?: ApiQuery) => {
    return getMany(facility.id, query);
  };

  return (
    <>
      <TemplateTable
        ref={mainRef}
        entityName={CHART_OF_ACCOUNT_ENTITY_NAME}
        fields={chartOfAccountFields}
        selectedRow={selectedRow}
        visibleColumns={chartOfAccountVisibleColumns}
        drawerSelectedRow={drawerSelectedRow}
        tableAction={{
          includeAddButton: true,
          includeUpdateAction: true,
          rowActions: [
            {
              label: 'Generate General Ledger Report',
              action: () => handlePrintLedger(),
              icon: <ReceiptLongIcon />,
            },
            {
              label: 'Generate Journal',
              action: () => handlePrintJournal(),
              icon: <BookIcon />,
            },
          ],
        }}
        setSelectedRow={setSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        getData={handleGetData}
        handleDelete={deleteOne}
        templateForm={
          <ChartOfAccountForm
            selectedRow={drawerSelectedRow ?? selectedRow}
            callbackAfterSubmit={callbackAfterSubmit}
          />
        }
      />

      {/* printable ledger */}
      <Box
        display="none"
        children={
          <GeneralLedgerTable
            startDate={new Date()}
            endDate={new Date()}
            ref={printableRef}
            ledgerReportData={ledgerReportMockDataFormat}
          />
        }
      ></Box>

      {/* printable journal */}
      <Box
        display="none"
        children={
          <JournalEntries
            startDate={new Date()}
            endDate={new Date()}
            ref={printableJournalRef}
            reportData={ledgerReportMockDataFormat}
          />
        }
      ></Box>
    </>
  );
});

export default ChartOfAccountTable;
