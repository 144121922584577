/* eslint-disable react-hooks/exhaustive-deps */

import { ConfirmationDialog, CustomModal, PrimaryButton, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { getPackagesAssigned, removePackagesFromClinic } from 'company/api/health-packages';
import { useEffect, useState } from 'react';

import AddTable from '../AddModal';
import { ApiQuery } from 'core/model/interface';
import { Box, useMediaQuery } from '@mui/material';
import { CompanyFacilityModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSnackbar } from 'notistack';

type ClinicHealthPackagesProps = {
  clinic: CompanyFacilityModel;
};

const ClinicHealthPackages: React.FC<ClinicHealthPackagesProps> = ({ clinic }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const columns: GridColDef[] = [
    {
      field: 'package_name',
      headerName: 'Name',
      flex: 2,
    },
  ];

  const getPackageList = (query: ApiQuery) => {
    return getPackagesAssigned(clinic.id, query);
  };

  const handlePackageDelete = (data: any) => {
    const packageList = JSON.stringify(data ? [data] : selectedRows);
    const packages = { packages: packageList };

    return removePackagesFromClinic(clinic.id, packages).then(() => {
      setForceRefresh((prev: number) => prev + 1);
      if (selectedRows.length > 1 && !data) {
        enqueueSnackbar('Health Session successfully removed!', { variant: 'success' });
      }
    });
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    refreshTable();
  }, [clinic]);

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Health Session',
    useColoredTable: isMobilePhone,
    tableComponent: {
      columns: columns,
      getData: getPackageList,
      forceRefresh: forceRefresh,
      hideAddSelectedButton: isMobilePhone,
      checkboxSelection: true,
      selectableRow: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          {!isMobilePhone && (
            <PrimaryButton
              onClick={() => {
                setOpenAddModal(true);
              }}
              label="Add Health Session"
            />
          )}
          {!isMobilePhone && (
            <RegularButton
              onClick={() => {
                if (selectedRows.length) {
                  setOpenDeleteDialog(true);
                  setAlertContent(
                    `Are you sure you want to remove ${selectedRows.length} health ${
                      selectedRows.length !== 1 ? 'sessions' : 'session'
                    } from ${clinic?.facility_name}?`
                  );
                } else {
                  enqueueSnackbar('Invalid action. Select health sessions first!', { variant: 'error' });
                }
              }}
              label="Remove Health Sessions"
              disabled={!selectedRows.length}
              startIcon={<RemoveIcon />}
              color="error"
            />
          )}
        </Box>
      ),
    },
    tableAction: !isMobilePhone
      ? {
          includeUpdateAction: false,
          includeDeleteAction: true,
          deleteButtonLabel: 'Remove',
          deleteApiFunction: handlePackageDelete,
          alertCustomObjectColumn: 'package_name',
          customDeleteSnackBarMessageOnSuccess: 'removed',
        }
      : {},
  } as PageBuilderTableType;

  return (
    <Box width="100%">
      <PageBuilder content={content} />

      <CustomModal width={900} header={'Add Health Sessions'} open={openAddModal} setOpen={setOpenAddModal}>
        <AddTable entity={'Health Sessions'} clinic={clinic} setRefreshMainTable={setForceRefresh} isPackage />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={handlePackageDelete}
        confirmButtonColor={false}
      />
    </Box>
  );
};

export default ClinicHealthPackages;
