import { createContext, useMemo, useState } from 'react';

import { Step } from 'react-joyride';
import { TOUR_ID } from 'core/screens/Helper/GuidedTour/steps';

export interface AppState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  tourId: TOUR_ID;
}

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  tourId: 0,
};

export const TourContext = createContext({
  tourState: appState,
  setTourState: (state?: any) => undefined,
});

export const useTour = () => {
  const [tourState, setTourState] = useState(appState);

  const tourMemo = useMemo(() => ({ tourState, setTourState }), [tourState]);
  return [tourMemo];
};
