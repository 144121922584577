import 'core/styles/Components.css';

import { Box, TextField, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import { tokens } from 'theme/theme';

type CustomTextFieldProps = {
  type?: React.HTMLInputTypeAttribute;
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChangeCallback?: (value: string) => void;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  // required?: boolean;
  optional?: boolean;
  size?: 'small' | 'medium' | undefined;
  multiline?: boolean;
  rows?: number;
  noLabel?: boolean;
  readOnly?: boolean;
  autoSave?: () => void;
  handlerOnFocus?: () => void;
  placeholder?: string;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  type,
  label,
  handleBlur,
  handleChange,
  handleChangeCallback,
  value,
  fieldName,
  disabled,
  touched,
  error,
  optional,
  size = 'small',
  multiline,
  rows,
  noLabel,
  readOnly,
  autoSave,
  handlerOnFocus,
  placeholder,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [textValue, setTextValue] = useState(value ?? '');

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      handleChange && handleChange(event);
      handleChangeCallback && handleChangeCallback(event.target.value);
      autoSave && autoSave();
      setTextValue(event.target.value);
    }
  };

  useEffect(() => setTextValue(value ?? ''), [value]);

  return (
    <Box>
      <TextField
        fullWidth
        variant="filled"
        type={type}
        label={label + (optional ? ' (optional)' : '')}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleTextChange}
        value={textValue}
        name={fieldName}
        error={!!touched && !!error}
        helperText={touched && error}
        onFocus={handlerOnFocus}
        placeholder={placeholder}
        // required={required}
        size={size}
        multiline={multiline}
        minRows={rows}
        inputProps={{ readOnly: readOnly }}
        sx={{
          '.MuiFormLabel-root.Mui-focused': { color: colors.light },
          '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
          '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
          '.MuiFilledInput-root:before': { border: 'none' },
          '.Mui-disabled:before': { border: 'none !important' },
          '.MuiFilledInput-root:after': {
            borderBottom: `1px solid ${colors.primary}`,
            borderRadius: 2,
            height: '100%',
          },
          '.MuiFilledInput-input': { height: '22px' },
          '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
          // '& .MuiFormHelperText-root': {
          //     marginLeft: '11px',
          //     marginTop: '4px',
          //     position: 'static',
          //     fontSize: '0.65rem',
          //     lineHeight: '1.2',
          //     overflow: 'hidden',
          //     textOverflow: 'ellipsis',
          //     whiteSpace: 'normal'
          // },
          ...(noLabel
            ? {
                '.MuiInputBase-root.MuiFilledInput-root': { padding: '20px 8px 5px', paddingTop: '5px' },
                '.MuiInputBase-input.MuiFilledInput-input': { padding: '20px 8px 5px', paddingTop: '5px' },
              }
            : {}),
        }}
      />
    </Box>
  );
};

export default CustomTextField;
