/* eslint-disable react-hooks/exhaustive-deps */

import { CustomTable, Header, PrimaryButton, RegularButton } from 'core/components';
import { ProgramModel, ProgramPatientModel } from 'company/model/Entities';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { SmsScheduleModal } from 'company/components';
import { getPatientsEnrolledInProgram } from 'company/api/program-patients';

type HealthProgramPatientProps = {
  handleAddNewCycle: (patient: ProgramPatientModel) => void;
  program?: ProgramModel;
  patient?: ProgramPatientModel;
  refreshTable: number;
  setRefreshTable?: (refresh: any) => void;
  tableColumns: GridColDef[];
  showEnrollmentModal: () => void;
  tags: any[];
};

const HealthProgramPatient: React.FC<HealthProgramPatientProps> = ({
  handleAddNewCycle,
  program,
  patient,
  refreshTable,
  setRefreshTable,
  tableColumns,
  showEnrollmentModal,
  tags,
}) => {
  const { facility } = useContext(FacilityContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<ProgramPatientModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  const pendingSmsSchedules = async (query: ApiQuery) => {
    if (facility && program) {
      try {
        setLoading(true);

        if (patient) {
          query.patient_id = patient.patient_id;
        } else {
          query.get_all = 1;
        }

        const tags = Array.isArray(selectedTags) ? selectedTags.map((tag) => tag.value) : [];
        const data = { tags: JSON.stringify(tags) };

        let res = await getPatientsEnrolledInProgram(facility.id, program.program_code, query, data);
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const refreshPatientCycles = () => {
    setRefreshTable && setRefreshTable((prev: number) => prev + 1);
  };

  const handleTagsChange = (event: any, newTags: any[]) => {
    setSelectedTags(newTags);
    refreshPatientCycles();
  };

  useEffect(() => {
    if (patient?.patient_id) {
      refreshPatientCycles();
    }
  }, [patient]);

  useEffect(() => {
    pendingSmsSchedules({ page: 1, length: 10 });
  }, [program]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={pendingSmsSchedules}
        forceRefresh={refreshTable}
        initialOrder="desc"
        initialOrderBy="start_datetime"
        headerComponent={
          <Box display="block">
            {!patient ? (
              <>
                <Header title={program?.program_name} mb="1rem" />
                <Box display="flex" gap="10px" alignItems="flex-end">
                  <PrimaryButton onClick={showEnrollmentModal} label="Add Cycle" id='menstrual-cup-reminder-add-button'/>
                  <RegularButton
                    onClick={() => {
                      setOpenSmsModal(true);
                    }}
                    label="SMS Schedules"
                    startIcon={<SmsOutlinedIcon />}
                  />
                </Box>
                {program && !program?.sensitive_program && (
                  <Box width="250px" mt="10px">
                    <CustomAutoCompleteMultipleSelect
                      options={tags}
                      label={'Patient Groups'}
                      fieldName={'tags'}
                      value={selectedTags}
                      handleChange={handleTagsChange}
                    />
                  </Box>
                )}
              </>
            ) : (
              <PrimaryButton onClick={() => handleAddNewCycle(rows[0])} label="Add Cycle" />
            )}
          </Box>
        }
      />

      {program && (
        <SmsScheduleModal
          open={openSmsModal}
          setOpen={setOpenSmsModal}
          programCode={program.program_code}
          sensitive={program?.sensitive_program}
          // fromAppointment
          // passActualPatientID
        />
      )}
    </>
  );
};

export default HealthProgramPatient;
