/* eslint-disable react-hooks/exhaustive-deps */

import {
  CC_ENTITY_NAME,
  createExpenseTypeSchema,
  expenseTypeFields,
  expenseTypeFormFields,
  expenseTypeInitialValues,
} from './ExpenseTypeModel';
import { createExpenseType, updateExpenseType } from 'company/api/expense-types';

import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  expenseType?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const ExpenseTypeForm = (props: Props) => {
  const { expenseType, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    return expenseType ? updateExpenseType(expenseType.id, data) : createExpenseType(data);
  };

  return (
    <TemplateForm
      entityName={CC_ENTITY_NAME}
      fields={expenseTypeFields}
      visibleFields={expenseTypeFormFields}
      entity={expenseType}
      initialValues={expenseType ? changeNullToBlank(expenseType) : expenseTypeInitialValues}
      schema={createExpenseTypeSchema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ExpenseTypeForm;
