import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { updateSputumDetails } from 'lgu/api/tb-acf';
import { update_sputum_schedule_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type SputumDetailsBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  sputum_test_schedule: string;
  sputum_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  sputum_test_schedule: '',
  sputum_test_venue: '',
};

const SputumDetailsBatch: React.FC<SputumDetailsBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const sputumFormFields: UserFieldInput[] = [
    {
      field_name: 'sputum_test_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
    },
    { field_name: 'sputum_test_venue', display_name: 'Venue', type: 'text' },
  ];

  const updateSputum = (data: any) => {
    setButtonLoading(true);

    const patientData = {
      patients: patients,
      sputum_test_schedule: data.sputum_test_schedule,
      sputum_test_venue: data.sputum_test_venue,
    };

    updateSputumDetails(patientData)
      .then((res) => {
        setButtonLoading(false);
        enqueueSnackbar('Sputum Details successfully saved!', { variant: 'success' });
        openModal(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateSputum}
          schema={update_sputum_schedule_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={sputumFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default SputumDetailsBatch;
