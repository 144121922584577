import { Box, Typography, useMediaQuery } from '@mui/material';

type InfoItemProps = {
  label: string;
  value: string | number;
  last?: boolean;
};

const InfoItem: React.FC<InfoItemProps> = ({ label, value, last }) => {
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  return (
    <Box
      display="grid"
      gridTemplateColumns="40% 1fr"
      columnGap="20px"
      alignItems="center"
      // borderBottom={last ? undefined : '.3px dotted grey'}
      pb="5px"
      sx={{
        wordBreak: 'break-word',
        '& p': { fontSize: isMobilePhone ? '13px' : '12px' },
        '& > *:last-child': { justifySelf: 'end', textAlign: 'right' },
      }}
    >
      <Typography>{label}</Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Typography>{value}</Typography>
      </Box>
    </Box>
  );
};

export default InfoItem;
