import { CompanyProtectedComponent, CustomModal, RegularButton } from 'core/components';
import { PRODUCT_ENTITY_NAME, ProductModel, productFields, visibleProductColumns } from './ProductModel';
import { deleteClinicProduct, getProducts } from 'company/api/products';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ProductCategoryTable from '../ProductCategory/ProductCategoryTable';
import ProductForm from './ProductForm';
import ProductVariantTable from './ProductVariant/ProductVariantTable';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { useNavigate } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const ProductTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const navigate = useNavigate();
  const [openCategoriesModal, setOpenCategoriesModal] = useState<boolean>(false);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const [selectedRow, setSelectedRow] = useState<ProductModel>();

  const callbackAfterSubmit = (data: any) => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();

    if (data.message.includes('created')) {
      navigate(`/company/inventory/products/variant?id=${data.variant?.id}`);
    }
  };

  const getData = (query: ApiQuery) => {
    return getProducts(query);
  };

  const viewCategories = () => {
    setOpenCategoriesModal(true);
  };

  useEffect(() => {
    if (visibleColumns) mainRef.current.refreshTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns]);

  return (
    <>
      <TemplateTable
        ref={mainRef}
        entityName={PRODUCT_ENTITY_NAME}
        fields={productFields}
        visibleColumns={visibleColumns ?? visibleProductColumns}
        getData={getData}
        handleDelete={deleteClinicProduct}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        tableComponent={{
          hideSearch: !!selectedRow,
          displayChildComponent: (row: ProductModel) => <Box>{row.product_name}</Box>,
          headerComponent: (
            <CompanyProtectedComponent requiredAuth={['MANAGE_PRODUCTS_AND_VARIANTS']}>
              <RegularButton
                onClick={() => viewCategories()}
                label="View Categories"
                startIcon={<CategoryOutlinedIcon />}
              />
            </CompanyProtectedComponent>
          ),
          mobileViewConfig: () => ({
            title_fields: ['product_name'],
            subtitle_fields: ['category'],
          }),
          mobileViewDetailsDisplay: (data) => <ProductVariantTable product={data} />,
        }}
        templateForm={<ProductForm product={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      />
      <CustomModal open={openCategoriesModal} setOpen={setOpenCategoriesModal} header="Categories" width={900}>
        <ProductCategoryTable parentTableRef={mainRef} />
      </CustomModal>
    </>
  );
});

export default ProductTable;
