import { AccountType, UserModel } from 'core/model/Entities';

import { ApiQuery } from 'core/model/interface';
import Cookies from 'js-cookie';
import { Credentials } from 'core/model/LoginModel';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'user';

export const login = (credentials: Credentials, type: AccountType) => {
  return axios.post(`${baseURL}/auth/login/${type}`, credentials);
};

export const googleLoginCallback = (accessToken: string) => {
  return axios.post(`${baseURL}/auth/google/callback`, { access_token: accessToken });
};

export const refreshToken = () => {
  const refresh_token = Cookies.get('refresh_token');
  return axios.post(`${baseURL}/auth/refresh-token`, { refresh_token });
};

export const getCurrentUser = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const registerUser = (user: UserModel) => {
  return axios.post(`${baseURL}/${path}`, user);
};

export const updateUser = (id: number, user: UserModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, user);
};

export const getAccounts = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/accounts`, { params: query });
};

export const logout = () => {
  return axios.post(`${baseURL}/logout`);
};
