import { APPOINTMENT_ACTION, ApiQuery } from 'core/model/interface';
import { Box, Button, Divider, IconButton, Popover, Typography, useTheme } from '@mui/material';
import {
  ColoredTable,
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  ProtectedComponent,
  RegularButton,
} from 'core/components';
import { EventClickArg, formatDate } from '@fullcalendar/react';
import { addAppointment, cancelBooking, confirmBooking, getBookings } from 'company/api/bookings';
import { capitalizeWord, formatDateTime, formatTime } from 'core/utils';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import AppointmentSettingsModal from 'company/screens/Appointments/components/modal/AppointmentSettingsModal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Close } from '@mui/icons-material'; // Import the close icon
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { FacilityContext } from 'core/context/facility.context';
import FullCalendar from '@fullcalendar/react';
import { GridColDef } from '@mui/x-data-grid';
import { SIZES } from 'theme/constants';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WaitForFacility from '../../../components/WaitForFacility';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getAppointmentInDateRange } from 'company/api/appointments';
import { getPatients } from 'company/api/patient';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import styled from 'styled-components';
import timeGridPlugin from '@fullcalendar/timegrid';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type CalendarProps = {
  refresh?: number;
  handleDateSelected?: (date: Date) => void;
};

const Calendar = forwardRef((props: CalendarProps, ref) => {
  const { refresh, handleDateSelected } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const colors = tokens(theme.palette.mode);
  const calendarRef: any = useRef(null);
  const navigate = useNavigate();
  const [action, setAction] = useState<APPOINTMENT_ACTION>(APPOINTMENT_ACTION.NO_ACTION);
  const [selectedAppointment, setSelectedAppointment] = useState<any | null>(null);
  const { facility } = useContext(FacilityContext);
  const [visibleEvents, setVisibleEvents] = useState<any[]>([]);
  const [openAppointmentModals, setOpenAppointmentModals] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [registerConfirmAlertContent, setRegisterConfirmAlertContent] = useState<string>('');
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();
  const [registerLoading, setRegisterLoading] = useState<boolean>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({ refreshEvents }));

  const handleDateClick = (selected: any) => {
    if (!facility.is_admin) {
      handleDateSelected && handleDateSelected(selected.start);
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    const appointment = visibleEvents.find((event: any) => event.id === parseInt(arg.event.id));
    setSelectedAppointment(appointment);
    setAnchorEl(arg.el as HTMLElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const fetchData = async (start: Date, end: Date, setEvents: (list: any[]) => void, eventsToday?: boolean) => {
    try {
      setLoadingData(true);
      const response = await getAppointmentInDateRange(
        facility.id,
        moment(start).format('YYYY-MM-DD'),
        moment(end).format('YYYY-MM-DD')
      );
      const format_response = response.data.data.map((schedule: CompanyAppointmentModel) => {
        return {
          ...schedule,
          title: `${schedule.full_name} - [${schedule.service_names ?? 'Appointment'}] `,
          backgroundColor: '#C8E6F5',
          borderColor: colors.primary,
          start: schedule.schedule,
          end: moment(schedule.schedule)
            .add(schedule.duration > 0 ? schedule.duration : 30, 'minutes')
            .format(),
        };
      });

      const bookingsResponse = await getBookings(facility.id, { start, end, status: 'pending' });

      const format_booking_response = bookingsResponse.data.data.map((schedule: CompanyAppointmentModel) => {
        return {
          ...schedule,
          title: `[${schedule.service_names ?? 'Booking'}] ${schedule.full_name}`,
          start: schedule.schedule,
          end: moment(schedule.schedule)
            .add(schedule.duration ?? 30, 'minutes')
            .format(),
          backgroundColor: '#FCB18B',
          borderColor: '#F55302',
          fromBooking: true,
        };
      });
      setEvents([...format_response, ...format_booking_response]);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
  };

  const patientColumns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      hideable: false,
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      type: 'Date',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {[params.row.address, params.row.municipality_name, params.row.province_name, params.row.region_name].join(
            ', '
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            setOpenRegisterModal(true);
            setRegisterConfirmAlertContent(
              'Are you sure you want to confirm booking for ' + params.row.full_name + '?'
            );
            setIsNewPatient(false);
            setSelectedPatient(params.row.patient_id);
          }}
        >
          Select
        </Button>
      ),
    },
  ];

  // const delayedFetchData = debounce(fetchData, 1500);

  const getAppointments = async (start: Date, end: Date) => {
    if (facility) {
      fetchData(start, end, setVisibleEvents);
    }
  };

  const refreshEvents = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const start = calendarApi.view.activeStart;
      const end = calendarApi.view.activeEnd;
      getAppointments(start, end);
    }
  };

  useEffect(() => {
    if (refresh && facility) {
      refreshEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, facility]);

  const handleEventDrop = (info: any) => {
    const { event } = info;
    const { id } = event;
    const appointmentIndex = visibleEvents.findIndex((event: any) => event.id === parseInt(id));

    if (appointmentIndex !== -1) {
      const updatedEvents = [...visibleEvents];
      const updatedAppointment = { ...updatedEvents[appointmentIndex] };
      updatedAppointment.schedule = info.event.start;
      updatedEvents[appointmentIndex] = updatedAppointment;
      setVisibleEvents(updatedEvents);

      setAction(APPOINTMENT_ACTION.RESCHEDULE);
      setSelectedAppointment(updatedAppointment);
      setOpenAppointmentModals(true);
    }
  };

  const getPatientsList = async (query: ApiQuery) => {
    if (facility) {
      return getPatients(facility.id, query);
    }
  };

  const handleRegisterConfirm = async () => {
    if (isNewPatient) {
      confirmBooking(facility.id, selectedAppointment.id)
        .then((res) => {
          successConfirm(res.data.message);
        })
        .finally(() => {
          setRegisterLoading(false);
        });
    } else if (selectedPatient) {
      addAppointment(facility.id, selectedPatient, selectedAppointment.id)
        .then((res) => {
          successConfirm(res.data.message);
        })
        .finally(() => {
          setRegisterLoading(false);
        });
    }
  };

  const successConfirm = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' });
    setOpenConfirmModal(false);
    refreshEvents();
    handleClosePopover();

    setSelectedPatient(undefined);
  };

  const handleRejectBooking = async () => {
    cancelBooking(facility.id, selectedAppointment.id)
      .then((res) => {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        refreshEvents();
        handleClosePopover();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box width="100%">
        <WaitForFacility facility={facility}>
          {/* CALENDAR */}
          <Box
            flex="100%"
            borderRadius="20px"
            border="1.5px solid #F1F1F1;"
            sx={{
              '& .fc-button-primary': {
                backgroundColor: 'transparent',
                borderColor: colors.border,
                color: colors.text,
                textTransform: 'capitalize',
                borderRadius: '20px',
                padding: '5px 15px',
                fontSize: '12px',
              },
              '& .fc-button-primary:not(:disabled):hover': {
                backgroundColor: colors.primary,
                borderColor: colors.secondary_background,
              },
              '& .fc-button-primary:disabled': {
                backgroundColor: colors.dark,
                borderColor: colors.secondary_background,
              },
              '& .fc-button-active': {
                backgroundColor: colors.accent + ' !important',
                borderColor: colors.secondary_background + ' !important',
              },
              '& .fc-toolbar-title': {
                fontSize: '18px',
              },
              '& .fc-theme-standard .fc-list, .fc .fc-scrollgrid, .fc-theme-standard th': {
                borderColor: colors.border,
              },
              '& .fc-theme-standard th': {
                borderBottom: '1.5px solid ' + colors.primary,
              },
              '& .fc-col-header-cell': {
                border: 'none',
              },
              '& .fc-col-header': {
                border: 'none',
              },
              '& .fc-today-button': {
                backgroundColor: colors.primary,
                color: colors.text,
              },
              '& .fc-timegrid-axis': {
                border: 'none',
              },
              '& .fc-scrollgrid-sync-inner': {
                paddingBlock: '10px',
              },
              '& .fc-col-header-cell-cushion ': {
                fontSize: '13px',
              },
              '& .fc-scrollgrid': {
                border: 'none',
              },

              '& .fc-timegrid-axis-frame fc-scrollgrid-shrink-frame fc-timegrid-axis-frame-liquid': {
                paddingBlock: '10px',
              },

              '& .fc .fc-list-sticky .fc-list-day > *': {
                background: colors.secondary_background,
                color: colors.text,
              },
              '& .fc-theme-standard .fc-list-day-cushion': {
                background: 'transparent !important',
                color: colors.text,
              },
              '& .fc-list-event:hover td': {
                background: colors.hoverBackground,
              },
              '& .fc .fc-day-today': {
                background: 'rgba(35, 155, 215, 0.05)',
              },
              '& .fc-theme-standard td': {
                borderColor: colors.border,
              },
              '& .fc-popover': {
                // border: 'none',
                borderColor: colors.border,
                boxShadow: '0px 0px 10px 0px ' + (theme.palette.mode === 'dark' ? '#0c0c0d' : '#c2c1c1'),
                borderRadius: '9px',
                color: colors.text,

                '& .fc-popover-body': {
                  backgroundColor: colors.secondary_background,
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '4px 2px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',

                  '& .fc-daygrid-event': {
                    padding: '4px 8px',
                  },
                },
                ' & .fc-popover-header ': {
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  padding: '10px',
                  fontWeight: 'bold',
                  backgroundColor: colors.secondary_background,
                  borderBottom: '1px solid ' + colors.border,
                },
              },

              '& .fc-daygrid-event-dot': {
                borderColor: colors.accent,
              },
              '& .fc-timegrid-slot-label': {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },

              '& .fc-toolbar.fc-header-toolbar': {
                display: 'flex !important',
                padding: '0 20px',
                marginBottom: '5px',
              },
              '& .fc-timegrid-axis-frame': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '& .fc-header-toolbar-center': {
                display: 'flex !important',
              },
              '& .fc-timegrid-slot-label-frame': {
                margin: '10px',
              },
              '& .fc-toolbar-chunk > div': {
                display: 'flex',
              },
              '& .fc-timegrid-slot': {
                padding: '10px 50px',
              },
              '& .fc-prev-button': {
                border: 'none !important',
              },
              '& .fc-next-button': {
                border: 'none !important',
              },
              '& .fc-event-main': {
                color: 'black',
                padding: '2px',
              },
              '& .fc-daygrid-event-harness': {
                border: '2px solid',
                borderColor: 'transparent',
                borderRadius: '5px',
              },
              '& .fc-daygrid-event:hover': {
                backgroundColor: '#C8E6F5',
              },
              '& .fc-event-title': {
                fontWeight: 'bold',
              },
            }}
          >
            <Box padding="30px 0px">
              {loadingData && (
                <Box
                  display="flex"
                  top={100}
                  left={0}
                  right={0}
                  bottom={0}
                  justifyContent="center"
                  alignItems="center"
                  height="calc(100vh - 200px)"
                  zIndex={999}
                  position="absolute"
                >
                  <CustomLoadingIndicator />
                </Box>
              )}

              <FullCalendar
                ref={calendarRef}
                height="90vh"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: 'today',
                  center: 'prev,title,next',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                }}
                initialView="timeGridWeek"
                nowIndicator
                editable
                selectable
                selectMirror
                dayMaxEvents
                eventClick={handleEventClick}
                select={handleDateClick}
                datesSet={(date) => getAppointments(date.start, date.end)}
                events={visibleEvents}
                slotDuration={'01:00:00'}
                defaultTimedEventDuration={'00:30:00'}
                eventDrop={handleEventDrop}
              />
              {openAppointmentModals && selectedAppointment && (
                <AppointmentSettingsModal
                  openModal={openAppointmentModals}
                  setOpenModal={setOpenAppointmentModals}
                  appointment={selectedAppointment}
                  setSelectedAppointment={setSelectedAppointment}
                  facility={facility}
                  action={action}
                  updateAppointmentData={refreshEvents}
                />
              )}
            </Box>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ zIndex: 1 }}
            >
              <Box width="280px">
                <Box
                  padding="10px 20px"
                  sx={{ backgroundColor: colors.lightBackground }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box display="flex" gap="10px" alignItems="center" width="100%">
                      <Typography
                        variant="h4"
                        display="flex"
                        fontWeight="bold"
                        alignItems="center"
                        gap="10px"
                        color={colors.primary}
                      >
                        <Span
                          onClick={() =>
                            navigate(`/company/patient-management?patient_id=${selectedAppointment?.patient_id}`)
                          }
                        >
                          {selectedAppointment && selectedAppointment.full_name}
                        </Span>
                      </Typography>
                      {selectedAppointment && !selectedAppointment.fromBooking && !facility.is_admin && (
                        <ProtectedComponent
                          requiredAuth={['MANAGE_APPOINTMENTS']}
                          requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                        >
                          <AppointmentSettings
                            appointment={selectedAppointment!}
                            updateAppointmentData={() => {
                              handleClosePopover();
                              refreshEvents();
                            }}
                            navigateToEditor
                          />
                        </ProtectedComponent>
                      )}
                    </Box>
                  </Box>
                  <IconButton onClick={handleClosePopover}>
                    <Close />
                  </IconButton>
                </Box>
                <Divider />
                {selectedAppointment && (
                  <Box padding="20px">
                    <Typography variant="h6" display="flex" alignItems="center" gap="5px" padding={'0 0 20px 0'}>
                      <ScheduleIcon sx={{ color: colors.primary, fontSize: '15px' }} />
                      <Span onClick={() => navigate(`/company/appointments/${selectedAppointment?.id}`)}>
                        <Typography fontSize={'13px'}>
                          {formatDateTime(selectedAppointment.schedule)} - {formatTime(selectedAppointment.end)}
                        </Typography>
                      </Span>
                    </Typography>
                    <Box display={'flex'} gap="10px" paddingBottom="10px">
                      <Typography sx={{ color: colors.primary }}>Mobile Number</Typography>
                      <Typography>{selectedAppointment.mobile_number}</Typography>
                    </Box>

                    <Box display={'flex'} gap="10px" paddingBottom="10px">
                      <Typography sx={{ color: colors.primary }}>Service</Typography>
                      <Typography>
                        {selectedAppointment.service_names
                          ? selectedAppointment.service_names
                          : selectedAppointment.service_name}
                      </Typography>
                    </Box>

                    <Box display={'flex'} gap="10px" paddingBottom="10px">
                      <Typography sx={{ color: colors.primary }}>Service Provider</Typography>
                      <Typography>
                        {selectedAppointment.service_provider_with_title ?? selectedAppointment.service_provider}
                      </Typography>
                    </Box>

                    {!(selectedAppointment && selectedAppointment.fromBooking) && (
                      <Box alignItems="center" display={'flex'} gap="10px" paddingBottom="10px">
                        <Typography sx={{ color: colors.primary }}>Status</Typography>
                        <Box
                          sx={{
                            borderRadius: '5px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            backgroundColor: colors.accent,
                            color: 'white',
                            padding: '5px 10px',
                            fontSize: '10px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ fontSize: '13px' }} />
                          <Typography sx={{ fontSize: '10px' }}>
                            {capitalizeWord(selectedAppointment.status)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {selectedAppointment && selectedAppointment.fromBooking && (
                      <Box mt="20px" display="flex" gap="10px">
                        <Button
                          startIcon={<DangerousOutlinedIcon />}
                          fullWidth
                          variant="contained"
                          size="small"
                          sx={{ padding: '8px 0', borderRadius: '10px' }}
                          color="error"
                          onClick={() => {
                            setOpenConfirmationDialog(true);
                            setAlertContent('Are you sure you want to cancel this booking?');
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          startIcon={<CheckCircleOutlineOutlinedIcon />}
                          fullWidth
                          sx={{ padding: '8px 0', borderRadius: '10px', backgroundColor: 'green', color: 'white' }}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setOpenConfirmModal(true);
                            setAlertContent('Confirm booking for ' + selectedAppointment?.full_name);
                          }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Popover>
          </Box>
        </WaitForFacility>
      </Box>
      <>
        <ConfirmationDialog
          open={openConfirmationDialog}
          setOpen={setOpenConfirmationDialog}
          content={alertContent}
          onConfirm={handleRejectBooking}
        />
        <CustomModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          header={alertContent}
          width={1000}
          headerComponent={
            <Box>
              <Box display="flex" gap="10px" alignItems={'center'} mt={SIZES.paddingS}>
                <Typography fontSize={14}> [a] As a new patient:</Typography>
                <RegularButton
                  onClick={() => {
                    setOpenRegisterModal(true);
                    setRegisterConfirmAlertContent(
                      'Are you sure you want to register as new patient and then confirm booking for ' +
                        selectedAppointment.full_name +
                        '?'
                    );
                    setIsNewPatient(true);
                  }}
                  label="Register as a new patient"
                  loading={registerLoading}
                />
              </Box>
            </Box>
          }
        >
          <Divider style={{ marginBottom: 15 }} />

          <ColoredTable
            rowId={'patient_id'}
            columns={patientColumns}
            getData={getPatientsList}
            headerComponent={<Typography fontSize={14}>[b] As an existing patient:</Typography>}
          />
        </CustomModal>
      </>
      <ConfirmationDialog
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        content={registerConfirmAlertContent}
        onConfirm={handleRegisterConfirm}
      />
    </>
  );
});

export default Calendar;
const Span = styled.span`
  cursor: pointer;
  &:hover {
    color: #25638b;
  }
`;
