import { updateSputumResult, updateStage } from 'lgu/api/tb-acf';

import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { sputum_result_options } from 'lgu/data/select-options';
import { update_sputum_test_result_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type SputumResultBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  sputum_test_result: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  sputum_test_result: '',
};

const SputumResultBatch: React.FC<SputumResultBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const sputumFormFields: UserFieldInput[] = [
    {
      field_name: 'sputum_test_result',
      display_name: 'Proceed to:',
      type: 'select',
      options: sputum_result_options.map((option) => {
        return { key: option.key, value: option.value };
      }),
    },
  ];

  const updateSputum = (data: any) => {
    setButtonLoading(true);

    switch (data.sputum_test_result) {
      case 'Negative-Checkup':
        saveSputumResult('Presumptive', 'Doctor Appointment');
        break;
      case 'Positive':
        saveSputumResult('Positive', 'TB-DOTS Enrollment');
        break;
      case 'Negative':
        saveSputumResult('Negative', 'Sputum Test');
        break;
      case 'Rifampicin-resistant':
        saveSputumResult('Rifampicin-resistant', 'Sputum Test');
        break;
    }
  };

  const saveSputumResult = (sputum_test_result: string, stage: string) => {
    const patientData = {
      patients: patients,
      stage: stage,
    };

    const patientSputumResult = {
      patients: patients,
      sputum_test_result: sputum_test_result,
    };

    updateSputumResult(patientSputumResult)
      .then(() => {
        if (sputum_test_result !== 'Negative' && sputum_test_result !== 'Rifampicin-resistant') {
          updateStage(patientData)
            .then(() => {
              enqueueSnackbar('Sputum Test Result successfully saved!', { variant: 'success' });
              setButtonLoading(false);
              openModal(false);
              refreshTable();
            })
            .catch((error) => console.error(error));
        } else {
          enqueueSnackbar('Sputum Test Result successfully saved!', { variant: 'success' });
          setButtonLoading(false);
          openModal(false);
          refreshTable();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateSputum}
          schema={update_sputum_test_result_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={sputumFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default SputumResultBatch;
