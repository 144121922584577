import { AnalyticsQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'firewall/analytics';

export const getAnalyticsByDayRange = (query?: AnalyticsQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};
