import { PROMO_ENTITY_NAME, promoFields, promoTableColumns } from './PromoModel';
import { deletePromotion, getPromotions } from 'company/api/promotions';
import { forwardRef, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import { FacilityModel } from 'core/model/Entities';
import PromoForm from './PromoForm';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  facility?: FacilityModel;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const PromoTable = forwardRef((props: Props, ref) => {
  const { facility, visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const getData = (query: ApiQuery) => {
    if (facility) return getPromotions(facility.id, query);
    return getPromotions(1, query); //check
  };

  const handleDelete = (data: any) => {
    return deletePromotion(facility!.id, data);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <TemplateTable
      ref={mainRef}
      entityName={PROMO_ENTITY_NAME}
      fields={promoFields}
      visibleColumns={visibleColumns ?? promoTableColumns}
      templateForm={
        facility ? (
          <PromoForm promotion={selectedRow} facility={facility} callbackAfterSubmit={callbackAfterSubmit} />
        ) : (
          <></>
        )
      }
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
    />
  );
});

export default PromoTable;
