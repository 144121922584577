import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CustomLocationDropdown, { Municipality, Province, Region } from './CustomLocationDropdown';
import { LocationValues } from './CustomLocationDropdown';
import { municipalityData, region, provinces } from '../LocationData';

const CustomLocationSearchGroupDropdown = () => {
  const [municipalities, setMunicipalities] = useState<Municipality[]>(municipalityData);

  const [selectedLocation, setSelectedLocation] = useState<LocationValues>({
    region: null,
    province: null,
    municipality: null,
  });

  const handleLocationChange = (values: LocationValues) => {
    setSelectedLocation(values);
  };

  return (
    <CustomLocationDropdown
      regions={region}
      provinces={provinces}
      municipalities={municipalities}
      onChange={setSelectedLocation}
      initialValues={selectedLocation}
    />
  );
};

export default CustomLocationSearchGroupDropdown;
