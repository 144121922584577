import * as React from 'react';

import { StepLabel, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { tokens } from 'theme/theme';

type CustomStepperNonLinearProps = {
  activeStep: number;
  setActiveStep: (step: number) => void;
  steps: any[];
  strict?: boolean;
  disabled?: boolean;
};

const CustomStepperNonLinear: React.FC<CustomStepperNonLinearProps> = ({
  steps,
  activeStep,
  setActiveStep,
  strict,
  disabled
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label.label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': { color: colors.accent },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': { color: colors.accent },
              '& .MuiStepLabel-root .Mui-active': { color: colors.accent, fontWeight: 'bold' },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': { color: colors.accent },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' },
              '& .MuiStepLabel-root .MuiStepIcon-text': { fontSize: '14px' },
              '& .MuiStepLabel-alternativeLabel': { fontSize: '13px', fontWeight: 'bold' },
              '& .MuiStepIcon-root': { height: '30px', width: '30px' },
              '& .MuiSvgIcon-root': { height: '25px', width: '25px' },
            }}
          >
            {strict ? (
              <StepLabel>{label.label}</StepLabel>
            ) : (
              <StepButton color="inherit" icon={label.icon} onClick={() => setActiveStep(index)} disabled={disabled}>
                {label.label}
              </StepButton>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepperNonLinear;
