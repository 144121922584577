/* eslint-disable react-hooks/exhaustive-deps */

import { CustomForm, CustomModal } from 'core/components';
import { addServiceToStage, getServicesByStage } from 'company/api/health-packages';
import { useContext, useEffect, useState } from 'react';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { HealthPackageStage } from 'company/model/Entities';
import { add_services_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type UserInput = {
  service_id: number[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  service_id: [],
};

type AddServicesProps = {
  openAddServicesModal: boolean;
  setOpenAddServicesModal: (openAddMultipleStagesModal: boolean) => void;
  stage: HealthPackageStage;
  callbackAfterChanges?: () => void;
};

const AddServices: React.FC<AddServicesProps> = ({
  openAddServicesModal,
  setOpenAddServicesModal,
  stage,
  callbackAfterChanges,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [services, setServices] = useState<any>(initialValues);

  const formFields: UserFieldInput[] = [
    {
      field_name: 'service_id',
      type: DROPDOWN_FIELD.SERVICE,
      span: 4,
      multiple: true,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setLoading(true);
    data.services = data.service_id ? JSON.stringify(data.service_id.map((service: any) => service.value)) : '';

    if (data) {
      return addServiceToStage(stage.package_id, stage.id, data)
        .then((res) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((err) => console.error(err));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Services successfully added!`, { variant: 'success' });
      setOpenAddServicesModal(false);
      callbackAfterChanges && callbackAfterChanges();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setLoading(false);
  };

  const getStageServices = async () => {
    return getServicesByStage(facility.id, stage.id).then((res) => {
      setServices(res.data.data);
    });
  };

  useEffect(() => {
    getStageServices();
  }, [openAddServicesModal]);

  return (
    <CustomModal header={'Add Services'} open={openAddServicesModal} setOpen={setOpenAddServicesModal} width={500}>
      <CustomForm
        onSubmit={handleSubmit}
        fields={formFields}
        initialValues={{
          service_id:
            services.length > 0
              ? services.map((u: any) => {
                  return { key: u.service_name, value: u.service_id };
                })
              : [],
        }}
        schema={add_services_schema}
        loading={loading}
        submitButtonId="add-services-form-submit-button"
      />
    </CustomModal>
  );
};

export default AddServices;
