/* eslint-disable react-hooks/exhaustive-deps */
import { CustomForm, CustomModal } from 'core/components';
import { getAllBarangayHealthworkers, updateBarangayAssignment } from 'lgu/api/health-workers';
import { useEffect, useState } from 'react';

import { FieldInput } from 'core/model/interface';
import { update_bhw_assigment_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

type HealthworkerAssignmentProps = {
  open: boolean;
  setOpen: (openModal: boolean) => void;
  healthworker: any;
  selectedBarangay: string[];
  barangays: any[];
  refreshTable: () => void;
};

type UserInput = {
  healthworker: string | number;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const HealthworkerAssignment: React.FC<HealthworkerAssignmentProps> = ({
  healthworker,
  selectedBarangay,
  barangays,
  open,
  setOpen,
  refreshTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [healthWorkers, setHealthWorkers] = useState<any[]>([]);
  const [selectedBarangays, setSelectedBarangays] = useState<any[]>([]);

  const initialValues: UserInput = {
    healthworker: healthworker.id ? healthworker.id : '',
  };

  const healthworkFields: UserFieldInput[] = [
    {
      field_name: 'healthworker',
      display_name: 'Barangay Healthworker',
      type: 'select',
      options: healthWorkers.map((healthworker) => {
        return { key: healthworker.full_name, value: healthworker.id };
      }),
    },
  ];

  const getHealthworkers = () => {
    getAllBarangayHealthworkers()
      .then((res) => {
        setHealthWorkers(res.data.data);
      })
      .catch();
  };

  const handleSubmit = (data: any) => {
    setLoading(true);

    var barangayCodes = selectedBarangays.map((brgy) => brgy.value.toString());

    updateBarangayAssignment(data.healthworker, JSON.stringify(barangayCodes))
      .then(() => {
        enqueueSnackbar('Barangay Health Worker successfully updated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
        setOpen(false);
      })
      .catch((err) => console.error(err));
  };

  const getBarangayList = () => {
    const options = barangays.map((u) => {
      return {
        key: u.barangay_name,
        value: u.barangay_code,
      };
    });

    const selected = options.filter((barangay) => selectedBarangay.includes(barangay.value));

    setSelectedBarangays(selected);
  };

  useEffect(() => {
    getBarangayList();
    getHealthworkers();
  }, []);

  return (
    <>
      <CustomModal
        header={healthworker ? 'Health Worker Assigned in ' + healthworker.barangay_name : 'Health Worker Assigned'}
        open={open}
        setOpen={setOpen}
      >
        <CustomForm
          onSubmit={handleSubmit}
          fields={healthworkFields}
          schema={update_bhw_assigment_schema}
          initialValues={initialValues}
          loading={loading}
          span={1}
        />
      </CustomModal>
    </>
  );
};

export default HealthworkerAssignment;
