/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, useTheme } from '@mui/material';
import { CareGoDrawer, CareGoPage, CareGoTable } from 'core/PageBuilder';
import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { EnrollToProgramReminderModal, PatientFormModal, SmsScheduleModal } from 'company/components';
import { ProgramModel, ProgramPatientModel } from 'company/model/Entities';
import { enrollPatient, getPatientsEnrolledInProgram, unenroll, updatePatient } from 'company/api/program-patients';
import { getFormFields, getFormFieldsValues, getTableColumns } from '../Utils/Customization';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import HealthProgramPatient from './components/HealthProgramPatient';
import { Link as RouterLink } from 'react-router-dom';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { UserContext } from 'core/context/user.context';
import { calculateAge } from 'core/utils';
import { getProgram } from 'company/api/program';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ViewAllRemindersProps = {
  refreshTable: number;
  setRefreshTable: (refreshTable: any) => void;
  tags: any[];
};

const HealthProgramReminder: React.FC<ViewAllRemindersProps> = ({ refreshTable, setRefreshTable, tags }) => {
  const { program_code } = useParams() as any;
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openEnrollModal, setOpenEnrollModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [selectedPatient2, setSelectedPatient2] = useState<ProgramPatientModel>(); // for adding new cycle from parent table
  const [selectedPatients2, setSelectedPatients2] = useState<ProgramPatientModel[]>([]);
  const [selectedRow, setSelectedRow] = useState<ProgramPatientModel>();
  const [selectedCycle, setSelectedCycle] = useState<ProgramPatientModel>();

  const [smsPatientId, setSmsPatientId] = useState<number>();
  const [smsActualPatientId, setSmsActualPatientId] = useState(false);
  const [header, setHeader] = useState('Enroll');

  const [program, setProgram] = useState<ProgramModel>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const [schema, setSchema] = useState<any>({});

  const [refreshPatientCycles, setRefreshPatientCycles] = useState(0);
  const [clearSelected, setClearSelected] = useState(0);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  const StyledLink = styled(RouterLink)`
    text-decoration: none;
    color: ${colors.primary};
    &:hover {
      text-decoration: underline;
    }
  `;

  const handleSelectRow = (selected: any) => {
    setSelectedRow(selected);
  };

  const refresh = () => {
    setRefreshTable((prev: number) => prev + 1);
  };

  const handleUpdatePatient = (patient: ProgramPatientModel) => {
    setHeader('Update');
    setSelectedPatient2(patient);
    getFormFieldsValues(program_code, setInitialValues, patient);
    setOpenFormModal(true);
  };

  const handleAddNewCyclePatient = (patient?: ProgramPatientModel) => {
    setHeader('New Cycle for');
    let data = undefined;

    if (patient) {
      data = {
        ...patient,
        start_datetime: patient.next_cycle ? patient.next_cycle : new Date().toDateString(),
      };
      setSelectedPatient2(patient);
    } else {
      setSelectedPatient2(selectedRow);
    }

    getFormFieldsValues(program_code, setInitialValues, data, true);
    setOpenFormModal(true);
  };

  const showPatientSms = (data: any) => {
    setSmsPatientId(data.patient_id);
    setSmsActualPatientId(true);
    setOpenSmsModal(true);
  };

  const handleDeleteCycle = async () => {
    if (selectedCycle) {
      const patientList = JSON.stringify([selectedCycle.id]);
      const data = { patients: patientList };
      try {
        await unenroll(facility.id, program_code, data);
        enqueueSnackbar('Cycle successfully deleted!', { variant: 'success' });
        setRefreshPatientCycles((prev: number) => prev + 1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const showPatientCycleSms = (data: any) => {
    setSmsPatientId(data.id);
    setSelectedCycle(data);
    setSmsActualPatientId(false);
    setOpenSmsModal(true);
  };

  const showUnenrollPatient = (data: any) => {
    setSelectedCycle(data);
    setOpenDelete(true);
  };

  const closeDrawer = () => {
    setSelectedRow(undefined);
    setClearSelected((prev: number) => prev + 1);
  };

  const getPatientsEnrolled = (query?: ApiQuery) => {
    const tags = Array.isArray(selectedTags) ? selectedTags.map((tag) => tag.value) : [];
    const data = { tags: JSON.stringify(tags) };

    return getPatientsEnrolledInProgram(facility.id, program_code, { page: 1, length: 10, ...query }, data);
  };

  const showEnrollmentModal = () => {
    setHeader('Enroll');
    getFormFieldsValues(program_code, setInitialValues);
    setOpenEnrollModal(true);
  };

  const patient_columns: GridColDef[] = [
    {
      field: program?.sensitive_program ? 'uic' : 'full_name',
      headerName: program?.sensitive_program ? 'UIC' : 'Patient Name',
      flex: 1.5,
    },
    {
      field: program?.sensitive_program ? 'encrypted_mobile_number' : 'mobile_number',
      headerName: 'Mobile Number',
      flex: 1,
    },
    {
      field: 'age',
      headerName: 'Age',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'action',
      flex: 0.5,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <RowAction
          actions={[
            { label: 'Add New Cycle', action: handleAddNewCyclePatient, icon: <AddIcon /> },
            { label: 'View Patient SMS', action: showPatientSms, icon: <SmsOutlinedIcon /> },
          ]}
          data={params.row}
        />
      ),
    },
  ];

  const handleTagsChange = (event: any, newTags: any[]) => {
    setSelectedTags(newTags);
    refresh();
  };

  useEffect(() => {
    if (user) {
      if (
        (program_code === 'ocp' && user.company?.with_ocp_reminders) ||
        (program_code === 'injectables' && user.company?.with_injectable_reminders)
      ) {
        setProgram(undefined);
        setSelectedRow(undefined);
        setSelectedPatient2(undefined);
        setSelectedPatients2([]);
        getProgram(program_code)
          .then((res) => {
            setProgram(res.data);
          })
          .catch(() => {
            navigate('/company/not-found');
          });
      } else {
        // navigate('/forbidden');
      }
    }
  }, [program_code, user]);

  useEffect(() => {
    if (program) {
      // Customize action based on program
      const actions: DropdownOptionProps[] = [
        { label: 'Update Cycle', action: handleUpdatePatient, icon: <EditOutlinedIcon /> },
        { label: 'Delete Cycle', action: showUnenrollPatient, icon: <DeleteOutlinedIcon /> },
        { label: 'View SMS Schedules', action: showPatientCycleSms, icon: <SmsOutlinedIcon /> },
      ];

      const actionColumn: GridColDef = {
        field: 'action',
        flex: 0.5,
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: any) => <RowAction actions={actions} data={params.row} />,
      };

      getTableColumns(program_code, actionColumn, setColumns);
      getFormFields(program_code, setFormFields, setSchema);
      setOpenEnrollModal(false);
      setOpenFormModal(false);
      setOpenSmsModal(false);

      setBreadcrumb([{ label: program.program_name }]);
    } else {
      setBreadcrumb([]);
    }
  }, [program]);

  return (
    <CareGoPage loading={!program}>
      <CareGoDrawer
        parentHeader={program ? program.program_name : ''}
        parentSubHeader={'Click the row to see more details.'}
        childHeader={
          selectedRow ? (
            program?.sensitive_program ? (
              selectedRow.uic
            ) : (
              <StyledLink to={`/company/patient-management?patient_id=${selectedRow.patient_id}`}>
                {selectedRow.full_name}
              </StyledLink>
            )
          ) : (
            ''
          )
        }
        childSubHeader={
          selectedRow
            ? 'Mobile #: ' +
              (program?.sensitive_program ? selectedRow.encrypted_mobile_number : selectedRow.mobile_number)
            : ''
        }
        parentWidth={300}
        showChild={!!selectedRow}
        handleClose={closeDrawer}
        parentComponent={
          <CareGoTable
            entityName={program ? program.program_name : ''}
            tableComponent={{
              columns: selectedRow
                ? [patient_columns[0], patient_columns[patient_columns.length - 1]]
                : patient_columns,
              getData: getPatientsEnrolled,
              selectableRow: true,
              onSelectRow: handleSelectRow,
              clearSelected,
              forceRefresh: refreshTable,
              initialOrderBy: 'start_datetime',
              initialOrder: 'desc',
              headerComponent: (
                <>
                  <PrimaryButton onClick={() => showEnrollmentModal()} label="Enroll Patients" />
                  {!selectedRow && (
                    <RegularButton
                      onClick={() => {
                        setOpenSmsModal(true);
                        setSmsPatientId(undefined);
                      }}
                      label="SMS Schedules"
                      startIcon={<SmsOutlinedIcon />}
                    />
                  )}
                </>
              ),
              filterComponent: program?.sensitive_program ? undefined : (
                <Box width="250px">
                  <CustomAutoCompleteMultipleSelect
                    options={tags}
                    label={'Patient Groups'}
                    fieldName={'tags'}
                    value={selectedTags}
                    handleChange={handleTagsChange}
                  />
                </Box>
              ),
            }}
          />
        }
        childComponent={
          <>
            {selectedRow && (
              <HealthProgramPatient
                handleAddNewCycle={handleAddNewCyclePatient}
                program={program}
                patient={selectedRow}
                refreshTable={refreshPatientCycles}
                setRefreshTable={setRefreshPatientCycles}
                tableColumns={columns}
                showEnrollmentModal={showEnrollmentModal}
                tags={tags}
              />
            )}
          </>
        }
      />

      {/* Modals */}
      <CustomModal header={'Enroll Patients'} open={openEnrollModal} setOpen={setOpenEnrollModal} width={900}>
        <EnrollToProgramReminderModal
          programCode={program_code}
          setPatientToEnroll={setSelectedPatient2}
          setOpenEnrollmentFormModal={setOpenFormModal}
          setPatientsToEnroll={setSelectedPatients2}
          getAllPatients
          sensitive={program?.sensitive_program}
        />
      </CustomModal>

      <PatientFormModal
        header={header}
        programCode={program_code}
        selectedPatient={selectedPatient2}
        selectedPatients={selectedPatients2}
        refreshTable={refresh}
        setRefreshOtherTable={setRefreshPatientCycles}
        initialValues={initialValues}
        schema={schema}
        open={openFormModal}
        setOpen={setOpenFormModal}
        formFields={formFields}
        enroll={enrollPatient}
        updatePatient={updatePatient}
      />

      <SmsScheduleModal
        open={openSmsModal}
        setOpen={setOpenSmsModal}
        programCode={program_code}
        patientID={smsPatientId}
        passActualPatientID={smsActualPatientId}
        sensitive={program?.sensitive_program}
      />

      <ConfirmationDialog
        open={openDelete}
        setOpen={setOpenDelete}
        content={'Are you sure you want to delete this cycle?'}
        onConfirm={handleDeleteCycle}
        onClose={() => setSelectedCycle(undefined)}
      />
    </CareGoPage>
  );
};

export default HealthProgramReminder;
