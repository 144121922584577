import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'inventory';

export const getDashboardStat = (facility_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/dashboard`);
};

export const getBestSeller = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/best-seller`, { params: query });
};
