import styled from 'styled-components';

const Partners = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <Content>
            <Con>
              <Logos src="./assets/landingassets/partn.png" />
            </Con>
          </Content>
        </Container>
      </MainContainer>
    </>
  );
};

export default Partners;

const MainContainer = styled.div`
  width: 100%;
  /* height: 40%; */
  display: flex;
  justify-content: center;
  color: white;
`;
const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 40%;
  }
`;

const Logos = styled.img`
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const Con = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 50%;
`;
