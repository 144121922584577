export const COLORS = {
  primary: '#239BD7',
  secondary: '#0463AE',
  white: '#FFFFFF',
  darkgray: '#141414',
  lightgray: '#888888',
  verylightgray: '#F8F8F8',

  opacity12: '#000000',
  header_gradient: 'linear-gradient(100deg, #239BD7 7.46%, #0463AE 95.68%)',
};

export const BUTTON_COLORS = {
  button_idle: '#239BD7',
  button_clicked: '#0463AE',
  button_dark_idle: '#232324',
  button_dark_clicked: '#FFFFFF',
};

export const BUTTON_STYLES = {
  light_idle: { color: COLORS.white, backgroundColor: BUTTON_COLORS.button_idle },
  light_clicked: { color: COLORS.white, backgroundColor: BUTTON_COLORS.button_clicked },
  dark_idle: { color: COLORS.white, backgroundColor: COLORS.darkgray },
  dark_clicked: { color: COLORS.secondary, backgroundColor: COLORS.white },
};

export const SIZES = {
  h1: '25px',
  h2: '20px',
  h3: '15px',
  h4: '14px',
  h5: '13px',
  body: '12px',
  primary: '18px',
  standard: '16px',
  secondary: '14px',
  borderRadiusS: '12px',
  borderRadius: '20px',
  borderRadiusL: '30px',
  padding: '20px',
  paddingS: '10px',
  paddingL: '40px',
};

export const FONTS = {
  h1: { fontSize: SIZES.h1, fontWeight: 'bold', fontFamily: 'Source-Sans-Pro' },
  h2: { fontSize: SIZES.h2, fontWeight: 'bold', fontFamily: 'Source-Sans-Pro' },
  h3: { fontSize: SIZES.h3, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
  h4: { fontSize: SIZES.h4, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
  h5: { fontSize: SIZES.h5, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
  primary: { fontSize: SIZES.primary, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
  standard: { fontSize: SIZES.standard, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
  secondary: { fontSize: SIZES.secondary, fontWeight: 'regular', fontFamily: 'Source-Sans-Pro' },
};

const appTheme = { COLORS, SIZES, FONTS, BUTTON_STYLES, BUTTON_COLORS };

export default appTheme;
