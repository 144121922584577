import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getHealthPackage, getHealthPackages } from 'company/api/health-packages';

const HealthPackageSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Health Session'}
      getData={getHealthPackages}
      getById={getHealthPackage}
      processOption={(healthPackage) => ({ key: healthPackage.package_name, value: healthPackage.id })}
      processNewRecord={(record) => record.package}
    />
  );
};

export default HealthPackageSearchDropdown;
