import * as yup from 'yup';

export const program_schema = yup.object().shape({
  program_name: yup.string().required('Program Name is required.'),
  program_category_code: yup.string().required('Program Category is required.'),
  program_code: yup.string().required('Program Code is required.'),
  default_sending_time: yup.string().required('Default Sending Time is required.'),
  reminder_count: yup.number().required('Reminder Count is required.'),
  frequency_type: yup.number().required('Frequency is required.'),
});

export const add_lgu_schema = yup.object().shape({
  region_id: yup.number().required('Region is required.'),
  province_id: yup.number().required('Province is required.'),
  lgu_code: yup.string().required('LGU is required'),
  office: yup.string().required('Office is required'),
  language_id: yup.number().required('Language is required'),
});

export const update_lgu_schema = yup.object().shape({
  region_name: yup.string().required('Region is required.'),
  province_name: yup.string().required('Province is required.'),
  lgu_name: yup.string().required('LGU Name is required'),
  office: yup.string().required('Office is required'),
  language_id: yup.number().required('Language is required'),
});

export const lgu_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  username: yup
    .string()
    .min(3, 'Username must be at least 3 characters long')
    .max(30, 'Username must be at most 30 characters long')
    .matches(/^[a-zA-Z0-9_]+$/, 'Can only contain alphanumerics/underscores')
    .required('Username is required'),
  user_group_id: yup.number().required('User Type is required'),
  password: yup.string().required('Password is required'),
});

export const company_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  username: yup
    .string()
    .min(3, 'Username must be at least 3 characters long')
    .max(30, 'Username must be at most 30 characters long')
    .matches(/^[a-zA-Z0-9_]+$/, 'Can only contain alphanumerics/underscores')
    .required('Username is required'),
  user_group_id: yup.number().required('User Type is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(64, 'Password must be at most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must include at least one special character')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required'),
});

export const update_company_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  user_group_id: yup.number().required('User Type is required'),
});

export const company_schema = yup.object().shape({
  company_name: yup.string().required('Company Name is required'),
  primary_language: yup.number().required('Language is required'),
  email: yup.string().matches(
    // eslint-disable-next-line
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    'Please enter a valid email address'
  ),
});

export const create_facility_schema = yup.object().shape({
  facility_name: yup.string().required('Facility Name is required'),
  primary_language: yup.number().required('Language is required'),
  region_name: yup.string().required('Region is required.'),
  province_name: yup.string().required('Province is required.'),
  municipality_name: yup.string().required('Municipality is required.'),
  address: yup.string().required('Address is required.'),
  smart_mobile_number: yup
    .string()
    .matches(/^(09|639)(08|18|19|20|21|28|29|39|46|47|49|51|61|98|99)\d{7}$/, {
      message: 'Smart Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  globe_mobile_number: yup
    .string()
    .matches(
      /^(09|639)(05|06|15|16|17|26|27|35|36|37|45|53|54|55|56|65|66|67|75|76|77|78|79|95|96|97)\d{7}|(0817)\d{7}$/,
      {
        message: 'Globe Mobile Number is invalid.',
        excludeEmptyString: false,
      }
    )
    .nullable(),
});

export const update_facility_schema = yup.object().shape({
  facility_name: yup.string().required('Facility Name is required'),
  primary_language: yup.number().required('Language is required'),
  region_name: yup.string().required('Region is required.'),
  province_name: yup.string().required('Province is required.'),
  municipality_name: yup.string().required('Municipality is required.'),
  address: yup.string().required('Address is required.'),
  smart_mobile_number: yup.string().nullable(),
  globe_mobile_number: yup.string().nullable(),
});

export const update_password_schema = yup.object().shape({
  new_password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(64, 'Password must be at most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must include at least one special character')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required'),
});

export const template_schema = yup.object().shape({
  template_name: yup.string().required('Template Name is required'),
  template_code: yup.string().required('Template Code is required'),
});

export const free_trial_schema = yup.object().shape({
  free_trial_period: yup.date().required('Free Trial Period is required'),
});

export const template_category_schema = yup.object().shape({
  category_name: yup.string().required('Category Name is required'),
});

export const add_local_template_schema = yup.object().shape({
  language_id: yup.string().required('Language is required'),
  template_body: yup.string().required('Template Body is required'),
});

export const add_email_template_schema = yup.object().shape({
  template_name: yup.string().required('Template Name is required'),
  template_code: yup.string().required('Template Code is required'),
  email_subject: yup.string().required('Subject is required'),
});

export const local_template_schema = yup.object().shape({
  template_body: yup.string().required('Template Body is required'),
});

export const rename_file_schema = yup.object().shape({
  file_name: yup.string().required('File Name is required'),
});

export const add_health_programs_schema = yup.object().shape({
  programs: yup.array().min(1, 'At least one program must be selected'),
});

export const update_health_programs_schema = yup.object().shape({
  order: yup.number().typeError('Invalid input. Numbers only.').required('Order is required'),
  visible: yup.number().required('Visibility is required'),
});

export const company_onboarding_schema = yup.object().shape({
  company_name: yup.string().required('Company Name is required'),
  primary_language: yup.number().required('Language is required'),
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  username: yup
    .string()
    .min(3, 'Username must be at least 3 characters long')
    .max(30, 'Username must be at most 30 characters long')
    .matches(/^[a-zA-Z0-9_]+$/, 'Can only contain alphanumerics/underscores')
    .required('Username is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(64, 'Password must be at most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must include at least one special character')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required'),
  health_programs: yup.array().min(1, 'At least one program must be selected'),
  email: yup
    .string()
    .matches(
      // eslint-disable-next-line
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      'Please enter a valid email address'
    )
    .required('Email is required'),
});

export const subscription_schema = yup.object().shape({
  plan: yup.string().required('Plan is required'),
});

export const create_invoice_schema = yup.object().shape({
  services: yup.array().min(1, 'Choose at least one service'),
});

export const create_note_template_schema = yup.object().shape({
  template_name: yup.string().required('Template Name is required'),
});

export const create_chart_schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  chart_code: yup.string().required('Chart Code is required'),
});

export const report_template_schema = yup.object().shape({
  form_id: yup.string().required('Template is required'),
  field_name: yup.string().required('Field is required'),
});
