import {
  EXPENSE_ENTITY_NAME,
  ExpenseModel,
  createExpenseModelSchema,
  expenseEntityFields,
  expenseFormFields,
  expenseInitialValues,
} from './ExpenseModel';
import { createExpense, updateExpense } from 'company/api/expenses';
import { useContext, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';

type Props = {
  expense?: ExpenseModel;
  readonly?: boolean;
  submitOnTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  source?: string;
};

const ExpenseForm: React.FC<Props> = (props: Props) => {
  const { facility } = useContext(FacilityContext);
  const { callbackAfterSubmit, expense } = props;
  const [options, setOptions] = useState({});

  const handleFormSubmit = (data: any) => {
    return expense ? updateExpense(facility.id, expense.id, data) : createExpense(facility.id, data);
  };

  return (
    <>
      <TemplateForm
        submitButtonId={'create_expense_submit_button'}
        entityName={EXPENSE_ENTITY_NAME}
        fields={expenseEntityFields}
        entity={expense}
        visibleFields={expenseFormFields}
        initialValues={expenseInitialValues}
        dropdownOptions={options}
        schema={createExpenseModelSchema}
        formSubmitApiFunction={handleFormSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
      />
    </>
  );
};

export default ExpenseForm;
