/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountType } from 'core/model/Entities';
import CompanyDetailsForm from './components/CompanyDetailsForm';
import Cookies from 'js-cookie';
import HealthProgramsForm from './components/HealthProgramsForm';
import UserAccessForm from './components/UserAccessForm';
import { login } from 'core/api/user';
import { onboardNewClientInSignUp } from 'core/api/public';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

interface ImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  step: number;
}
function getSteps() {
  return ['Company Details', 'User Access', 'Health Programs'];
}

function SignUpScreen() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [activeStep, setActiveStep] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const steps = getSteps();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [dots, setDots] = useState('');

  const [companyDetails, setCompanyDetails] = useState({
    company_name: '',
    primary_language: 3, //english
    company_logo: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png',
    company_logo_dark: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png',
    plan: 'free',
    email: '',
  });

  const [userAccess, setUserAccess] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    user_group_id: 4, //company admin ID
    username: '',
    password: '',
  });

  const [healthPrograms, setHealthPrograms] = useState({
    health_programs: [] as any[],
  });

  const handleNext = () => {
    if (isFormValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsFormValid(false);

      if (activeStep === steps.length - 1) {
        handleFinish();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsFormValid(false);
  };

  const loginUser = (username: string, password: string) => {
    login({ username, password }, AccountType.COMPANY).then(function (response) {
      if (response.data.token) {
        Cookies.set('access_token', response.data.token);

        if (locationState) {
          const { redirectTo } = locationState as any;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else {
          navigate('/company');
        }
      }
    });
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '.';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleFinish = useCallback(() => {
    const formData = {
      ...companyDetails,
      ...userAccess,
      ...healthPrograms,
    };

    return onboardNewClientInSignUp(formData)
      .then(() => {
        loginUser(userAccess.username, userAccess.password);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [companyDetails, userAccess, healthPrograms, navigate]);

  return (
    <>
      <Container>
        <Image src="./assets/Signup/ILLUSTRATION.png" step={activeStep} />

        <MainCon>
          <StepCon>
            <Box
              height="60%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{
                '@media screen and (max-width: 768px)': {
                  justifyContent: 'flex-start',
                  gap: '30px',
                },
              }}
            >
              <div className="sign-title">
                Sign Up
                <Box
                  display="flex"
                  gap="10px"
                  sx={{
                    '@media screen and (max-width: 768px)': {
                      justifyContent: 'center',
                      width: '100%',
                    },
                  }}
                >
                  <Typography color="white">Already have an account?</Typography>

                  <Typography
                    onClick={navigateToLogin}
                    variant="body1"
                    className="login-link"
                    sx={{ cursor: 'pointer', color: 'white', fontWeight: 'bold' }}
                  >
                    Log in
                  </Typography>
                </Box>
              </div>
              {window.innerWidth > 768 && (
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={{
                    '& .MuiStepLabel-label': {
                      fontSize: '1.2rem',
                      color: 'white',
                      borderColor: 'white',
                    },
                    '& .MuiStepLabel-completed': {
                      color: 'white',
                    },
                    '& .MuiStepLabel-active': {
                      color: 'white',
                    },
                    '& .MuiStepIcon-root': {
                      fontSize: '2rem',
                      color: colors.accent,
                      border: '1px solid white',
                      borderRadius: '50%',
                      background: 'white',
                    },
                    '& .MuiStepIcon-active': {
                      backgroundColor: 'white',
                      color: 'blue',
                    },
                    '& .MuiStepConnector-line': {
                      height: '10vh',
                    },
                    '& .MuiStepLabel-iconContainer': {
                      borderColor: 'white',
                    },
                    '& .css-y8lbgt-MuiStepLabel-label.Mui-completed': {
                      color: 'white',
                    },
                    '& .css-y8lbgt-MuiStepLabel-label.Mui-active': {
                      color: 'white',
                    },
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
              {window.innerWidth <= 768 && (
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      color: 'white',
                      borderColor: 'white',
                    },
                    '& .MuiStepLabel-completed': {
                      color: 'white',
                    },
                    '& .MuiStepIcon-root': {
                      fontSize: '1.7rem',
                      color: colors.accent,
                      border: '1px solid white',
                      borderRadius: '50%',
                      background: 'white',
                    },
                    '& .MuiStepIcon-active': {
                      backgroundColor: 'white',
                      color: 'blue',
                    },

                    '& .MuiStepLabel-iconContainer': {
                      borderColor: 'white',
                    },
                    marginBottom: '10px',
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Box>
          </StepCon>
          <div className="signup-container">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="65%"
              gap="12px"
              marginLeft="60px"
              sx={{
                width: '60%',
                borderRadius: '10px',

                '@media screen and (max-width: 768px)': {
                  width: '70%',
                  alignItems: 'center',
                },
              }}
            >
              <Images src={process.env.PUBLIC_URL + '/assets//landingassets/NewLogo.png'} alt="Logo" />

              {activeStep === 0 && (
                <div className="login-title">
                  Company Details
                  <Typography
                    sx={{
                      '@media screen and (max-width: 768px)': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    Please provide the following information to help us understand more about your organization.
                  </Typography>
                </div>
              )}

              {activeStep === 1 && (
                <div className="login-title">
                  User Access
                  <Typography>
                    Please provide personal details to enable user access to the features of CareGo Health Suite.
                  </Typography>
                </div>
              )}
              {activeStep === 2 && (
                <div className="login-title">
                  Health Programs
                  <Typography>
                    Enroll in CareGo Health Suite's programs by completing the health programs form.
                  </Typography>
                </div>
              )}
              <div>
                {activeStep === steps.length ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <p
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        display: 'inline-block',
                        cursor: 'pointer',
                        fontSize: '20px',
                        textAlign: 'center',
                      }}
                    >
                      Sign up successful! You will be logged in shortly{dots}
                    </p>
                  </Box>
                ) : (
                  <div>
                    {activeStep === 0 && (
                      <CompanyDetailsForm
                        formData={companyDetails}
                        setFormData={setCompanyDetails}
                        setIsFormValid={setIsFormValid}
                      />
                    )}
                    {activeStep === 1 && (
                      <Box
                        sx={{
                          '@media screen and (max-width: 768px)': {
                            paddingBottom: '50px',
                          },
                        }}
                      >
                        <UserAccessForm
                          formData={userAccess}
                          setFormData={setUserAccess}
                          setIsFormValid={setIsFormValid}
                        />
                      </Box>
                    )}
                    {activeStep === 2 && (
                      <HealthProgramsForm
                        formData={healthPrograms}
                        setFormData={setHealthPrograms}
                        setIsFormValid={setIsFormValid}
                      />
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                        '@media screen and (max-width: 768px)': {
                          paddingBottom: '30px;',
                        },
                      }}
                    >
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ color: 'black', '&:hover': { color: 'gray' } }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          width: '80px',
                          borderRadius: '20px',
                          background: isFormValid ? 'linear-gradient(to right, #239bd7, #0463ae)' : 'inherit',
                        }}
                        onClick={handleNext}
                        disabled={!isFormValid}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </Box>
                  </div>
                )}
              </div>
            </Box>
          </div>
        </MainCon>
      </Container>
    </>
  );
}

export default SignUpScreen;
const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const StepCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  flex-direction: column;
  flex: 1;

  background-image: url('./assets/Signup/BACKGROUND.png');
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-image: url('./assets/Signup/BackMob.png');
    margin: 0;
    max-height: 60vh;
  }
`;
const Image = styled.img<ImageProps>`
  height: 340px;
  width: auto;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  @media (max-width: 1500px) {
    height: 270px;
  }
  @media (max-width: 768px) {
    height: 160px;
    z-index: 1;
    top: ${(props) => {
      if (props.step === 1) {
        return '980px';
      } else if (props.step === 2) {
        return '650px';
      } else {
        return '700px';
      }
    }};
  }
`;
const Images = styled.img`
  height: auto;
  width: 250px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;
const MainCon = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
