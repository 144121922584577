/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { CustomContainer, CustomTabs } from 'core/components';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CampaignSmsSchedules from './components/CampaignSmsSchedules';
import ChatIcon from '@mui/icons-material/Chat';
import ClientSmsSchedules from './components/ClientSmsSchedules';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import { FacilityContext } from 'core/context/facility.context';
import HealthPackageSmsSchedules from './components/HealthPackageSmsSchedules';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';
import { TabProps } from 'core/components/CustomTabs';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { getDashboardStat } from 'company/api/dashboard';
import { tokens } from 'theme/theme';

const SmsActivity = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [pendingSMSCount, setPendingSMSCount] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [smsSentThisWeek, setSmsSentThisWeek] = useState(0);
  const [smsSendThisMonth, setSmsSentThisMonth] = useState(0);

  const setDashboardStats = () => {
    const now = new Date();
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    const endOfWeek = new Date(now.getFullYear(), now.getMonth(), startOfWeek.getDate() + 6);

    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const dateRange = {
      first_day_of_week: new Date(startOfWeek.setHours(0, 0, 0, 0)),
      last_day_of_week: new Date(endOfWeek.setHours(23, 59, 59, 59)),
      first_day_of_month: new Date(startOfMonth.setHours(0, 0, 0, 0)),
      last_day_of_month: new Date(endOfMonth.setHours(23, 59, 59, 59)),
      facility_id: facility.id,
    };

    getDashboardStat(dateRange)
      .then((res) => {
        setPendingSMSCount(res.data.pending_sms);
        setAvailableCredits(res.data.sms_credits);
        setSmsSentThisWeek(res.data.sms_sent_week);
        setSmsSentThisMonth(res.data.sms_sent_month);
      })
      .catch((error) => console.error(error));
  };

  const tabs: TabProps[] = [
    {
      label: 'Appointments / Reminders',
      icon: <TextsmsOutlinedIcon />,
      content: <ClientSmsSchedules facility={facility} />,
    },
    {
      label: 'Campaigns',
      icon: <TextsmsOutlinedIcon />,
      content: <CampaignSmsSchedules facility={facility} />,
    },
    {
      label: 'Health Sessions',
      icon: <TextsmsOutlinedIcon />,
      content: <HealthPackageSmsSchedules facility={facility} />,
    },
  ];

  useEffect(() => {
    setBreadcrumb([{ label: 'SMS' }]);
  }, []);

  useEffect(() => {
    facility && setDashboardStats();
  }, [facility]);

  return (
    <Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px" marginBottom="20px">
        {/* ROW 1 */}

        <CustomDashboardWidget
          count={smsSentThisWeek?.toLocaleString()}
          label="SMS sent this week"
          icon={SendIcon}
          sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
        />
        <CustomDashboardWidget
          count={smsSendThisMonth?.toLocaleString()}
          label="SMS sent this month"
          icon={SendIcon}
          sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
        />
        <CustomDashboardWidget
          count={pendingSMSCount?.toLocaleString()}
          label="Pending SMS"
          icon={ScheduleSendIcon}
          sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
        />
        <CustomDashboardWidget
          count={availableCredits?.toLocaleString()}
          label="Available Credits"
          icon={ChatIcon}
          sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
        />
      </Box>
      <CustomContainer>
        <Box width="100%">
          <CustomTabs tabs={tabs} tabBackgroundColor={colors.hoverBackground} tabGroupId="sms-tab" />
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default SmsActivity;
