import { ConfirmationDialog, CustomModal, RowAction } from 'core/components';
import { EnrollToProgramReminderModal, PatientFormModal, SmsScheduleModal } from 'company/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType } from 'core/PageBuilder';
import { ProgramModel, ProgramPatientModel } from 'company/model/Entities';
import { enrollPatient, unenroll, updatePatient } from 'company/api/program-patients';
import { getFormFields, getFormFieldsValues, getTableColumns } from '../../Utils/Customization';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import HealthProgramPatient from './HealthProgramPatient';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { getProgram } from 'company/api/program';
import { useSnackbar } from 'notistack';

/* eslint-disable react-hooks/exhaustive-deps */

type ViewAllRemindersProps = {
  refreshTable: number;
  setRefreshTable: (refreshTable: any) => void;
  tags: any[];
};

const ViewAllReminders: React.FC<ViewAllRemindersProps> = ({ refreshTable, setRefreshTable, tags }) => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const { program_code } = useParams() as any;
  const [program, setProgram] = useState<ProgramModel>();
  const [selectedPatient2, setSelectedPatient2] = useState<ProgramPatientModel>(); // for adding new cycle from parent table
  const [selectedPatients2, setSelectedPatients2] = useState<ProgramPatientModel[]>([]);
  const [selectedRow, setSelectedRow] = useState<ProgramPatientModel>();
  const [selectedCycle, setSelectedCycle] = useState<ProgramPatientModel>();
  const [smsPatientId, setSmsPatientId] = useState<number>();
  const [smsActualPatientId, setSmsActualPatientId] = useState(false);
  const [header, setHeader] = useState('Add Cycle to ');
  const navigate = useNavigate();
  const [openEnrollModal, setOpenEnrollModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [schema, setSchema] = useState<any>({});
  const [clearSelected, setClearSelected] = useState<number>(0);

  const handleUpdatePatient = (patient: ProgramPatientModel) => {
    setHeader('Update');
    setSelectedPatient2(patient);
    getFormFieldsValues(program_code, setInitialValues, patient);
    setOpenFormModal(true);
  };

  const handleAddNewCyclePatient = (patient?: ProgramPatientModel) => {
    setHeader('New Cycle for');
    let data = undefined;

    if (patient) {
      data = {
        ...patient,
        start_datetime: patient.next_cycle ? patient.next_cycle : new Date().toDateString(),
      };
      setSelectedPatient2(patient);
    } else {
      setSelectedPatient2(selectedRow);
    }

    getFormFieldsValues(program_code, setInitialValues, data, true);
    setOpenFormModal(true);
  };

  const showEnrollmentModal = () => {
    setHeader('Add Cycle to ');
    getFormFieldsValues(program_code, setInitialValues);
    setOpenEnrollModal(true);
  };

  const showUnenrollPatient = (data: any) => {
    setSelectedCycle(data);
    setOpenDelete(true);
  };

  const handleDeleteCycle = async () => {
    if (selectedCycle) {
      const patientList = JSON.stringify([selectedCycle.id]);
      const data = { patients: patientList };
      try {
        await unenroll(facility.id, program_code, data);
        enqueueSnackbar('Cycle successfully deleted!', { variant: 'success' });
        refresh();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const showPatientCycleSms = (data: any) => {
    setSmsPatientId(data.id);
    setSelectedCycle(data);
    setSmsActualPatientId(false);
    setOpenSmsModal(true);
  };

  const refresh = () => {
    setRefreshTable((prev: number) => prev + 1);
  };

  useEffect(() => {
    setProgram(undefined);
    setSelectedRow(undefined);
    setSelectedPatient2(undefined);
    setSelectedPatients2([]);
    getProgram(program_code)
      .then((res) => {
        setProgram(res.data);
      })
      .catch(() => {
        navigate('/company/not-found');
      });
  }, [program_code]);

  useEffect(() => {
    if (!openFormModal) {
      setHeader('New Cycle for ');
    }
  }, [openFormModal]);

  useEffect(() => {
    if (program) {
      // Customize action based on program
      const actions: DropdownOptionProps[] = [
        { label: 'Update Cycle', action: handleUpdatePatient, icon: <EditOutlinedIcon /> },
        { label: 'Delete Cycle', action: showUnenrollPatient, icon: <DeleteOutlinedIcon /> },
        { label: 'View SMS Schedules', action: showPatientCycleSms, icon: <SmsOutlinedIcon /> },
      ];

      const actionColumn: GridColDef = {
        field: 'action',
        flex: 0.5,
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: any) => <RowAction actions={actions} data={params.row} />,
      };

      getTableColumns(program_code, actionColumn, setColumns, true);
      getFormFields(program_code, setFormFields, setSchema);
      setOpenEnrollModal(false);
      setOpenFormModal(false);
      setOpenSmsModal(false);

      setBreadcrumb([{ label: program.program_name }]);
    } else {
      setBreadcrumb([]);
    }
  }, [program]);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.CUSTOM,
        block: (
          <HealthProgramPatient
            handleAddNewCycle={handleAddNewCyclePatient}
            program={program}
            patient={undefined}
            tableColumns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            showEnrollmentModal={showEnrollmentModal}
            tags={tags}
          />
        ),
      } as any,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />

      <CustomModal header={'Add Cycle to Patients'} open={openEnrollModal} setOpen={setOpenEnrollModal} width={900}>
        <EnrollToProgramReminderModal
          programCode={program_code}
          setPatientToEnroll={setSelectedPatient2}
          setOpenEnrollmentFormModal={setOpenFormModal}
          setPatientsToEnroll={setSelectedPatients2}
          getAllPatients
          inHealthPrograms
          clearSelected={clearSelected}
          sensitive={program?.sensitive_program}
        />
      </CustomModal>

      <PatientFormModal
        header={header}
        programCode={program_code}
        selectedPatient={selectedPatient2}
        selectedPatients={selectedPatients2}
        refreshTable={refresh}
        setRefreshOtherTable={setRefreshTable}
        initialValues={initialValues}
        schema={schema}
        open={openFormModal}
        setOpen={setOpenFormModal}
        formFields={formFields}
        enroll={enrollPatient}
        updatePatient={updatePatient}
        setClearSelected={setClearSelected}
      />

      <SmsScheduleModal
        open={openSmsModal}
        setOpen={setOpenSmsModal}
        programCode={program_code}
        patientID={smsPatientId}
        passActualPatientID={smsActualPatientId}
        sensitive={program?.sensitive_program}
      />

      <ConfirmationDialog
        open={openDelete}
        setOpen={setOpenDelete}
        content={'Are you sure you want to delete this cycle?'}
        onConfirm={handleDeleteCycle}
        onClose={() => setSelectedCycle(undefined)}
      />
    </>
  );
};

export default ViewAllReminders;
