import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import { FieldInput } from 'core/model/interface';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Scroll5 = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const freeTrialField: FieldInput[] = [
    { field_name: 'name', display_name: ' Name', type: 'text' },
    { field_name: 'clinic_name', display_name: 'Clinic Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'email', display_name: 'Email', type: 'text' },
    { field_name: 'message', display_name: 'Message', type: 'text' },
  ];
  const tryFunc = () => {
    navigate('/demo');
  };
  const handleDemoFormSubmit = (formData: Record<string, any>) => {
    setOpenFreeTrialModal(false);
    setButtonLoading(false);
  };
  return (
    <>
      <MainContainer>
        <Container>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Images src="./assets/Landing2/p2/Waves.png" alt="Logo" />
            <Header>
              <span
                style={{
                  background: 'linear-gradient(to right, #239bd7, #0463ae)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  display: 'inline-block',
                }}
              >
                Get Started
              </span>{' '}
              in three easy steps
            </Header>
          </Box>
          {window.innerWidth <= 768 && (
            <Stepper>
              <Image1 src="./assets/Landing2/p2/icon8.svg" alt="Logo" />
              <ImageContainer>
                <Contain>
                  <TextContainer>
                    <ImageText>Request a Demo</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
              <Image2 src="./assets/Landing2/p2/icon9.svg" alt="Logo" />
              <ImageContainer>
                <Contain>
                  <TextContainer>
                    <ImageText>Sign up for a free trial.</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>{' '}
              <ImageContainer>
                <Image3 src="./assets/Landing2/p2/icon10.svg" alt="Logo" />
                <Contain>
                  <TextContainer>
                    <ImageText>Track patient engagement</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            </Stepper>
          )}
          {window.innerWidth >= 769 && (
            <Stepper>
              <ImageContainer>
                <Image src="./assets/Landing2/p2/icon8.svg" alt="Logo" />
                <Contain>
                  <TextContainer>
                    <ImageText>Request a Demo</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
              <ImageContainer>
                <Image src="./assets/Landing2/p2/icon9.svg" alt="Logo" />
                <Contain>
                  <TextContainer>
                    <ImageText>Sign up for a free trial.</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>{' '}
              <ImageContainer>
                <Image src="./assets/Landing2/p2/icon10.svg" alt="Logo" />
                <Contain>
                  <TextContainer>
                    <ImageText>Track patient engagement</ImageText>
                  </TextContainer>
                </Contain>
              </ImageContainer>
            </Stepper>
          )}
          <Con3>
            <Div>
              <Paragraph>
                Don't miss out on the opportunity to experience CareGo Health Suite's remarkable benefits in action.
              </Paragraph>
              <Paragraph>
                Request a demo by clicking the button below, and one of our experts will guide you through the features
                and functionalities that can transform your clinics performance.
              </Paragraph>
              <Button onClick={tryFunc}>Get started</Button>
            </Div>
          </Con3>
        </Container>
        <CustomModal header={'Get Started'} open={openFreeTrialModal} setOpen={setOpenFreeTrialModal} width={800}>
          <Box>
            <CustomForm
              initialValues={{}}
              onSubmit={handleDemoFormSubmit}
              fields={freeTrialField}
              loading={buttonLoading}
            />
          </Box>
        </CustomModal>
      </MainContainer>
    </>
  );
};

export default Scroll5;
const Contain = styled.div`
  height: 120px;
  width: 320px;

  border-radius: 20px;
  display: flex;

  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  align-items: center;
  z-index: -1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media (max-width: 1800px) {
    height: 80px;
    width: 280px;
  }
  @media (max-width: 768px) {
    position: absolute;
    bottom: auto;
    height: 60px;
    width: 230px;
  }
`;
const ImageContainer = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 800px;

    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
`;
const ImageText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 500;
  }
`;
const TextContainer = styled.div`
  text-align: center;
  padding: 20px 60px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  @media (max-width: 1900px) {
    gap: 10px;
  }
`;
const MainContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url('./assets/Landing2/p2/des.png');
  background-size: cover;
  background-position: center;

  background-size: 100% 95%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-size: 100% 100%;
    background-image: url('./assets/Landing2/p3/mobile.png');
    z-index: -10;
    height: 105vh;
  }
  ::before {
    content: '';
    position: absolute;
    top: 430px;
    bottom: 0px;
    left: 70%;
    width: 40%;
    height: 600px;

    background-image: url('./assets//Landing2/p5/rafiki.svg');

    background-repeat: no-repeat;
    z-index: -2;
    @media (max-width: 1900px) {
      top: 200px;
      height: 600px;
      left: 60%;
      width: 70%;
    }
    @media (max-width: 768px) {
      top: 55%;
      height: 45%;
      left: 40%;
      width: 70%;
      background-image: url('./assets//Landing2/p5/rafikimob.svg');
    }
  }
`;
const Container = styled.div`
  flex-direction: column;
  padding: 100px;
  width: 75%;
  height: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Images = styled.img`
  height: auto;
  width: 80px;
  height: 10px;
  @media (max-width: 1700px) {
    width: 70px;
    height: 10px;
  }
`;

const Stepper = styled.div`
  display: flex;

  width: 70vw;
  height: 160px;
  position: relative;
  justify-content: space-around;

  align-items: center;

  flex-shrink: 0;
  @media (max-width: 1800px) {
    width: 1000px;
    height: 130px;
  }
  @media (max-width: 768px) {
    height: 270px;
    flex-direction: column;
    justify-content: flex-end;

    align-items: center;
  }
`;

const Con3 = styled.div`
  display: flex;

  gap: 30px;
  width: 80%;
  justify-content: center;
  align-items: center;
  @media (max-width: 1800px) {
    width: 85%;
  }
`;
const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Image = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const Image1 = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    height: 40px;
    position: absolute;
    top: 10px;
  }
`;
const Image2 = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    height: 40px;
    position: absolute;
    top: 100px;
  }
`;
const Image3 = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 768px) {
    height: 40px;
    position: absolute;
    top: 185px;
  }
`;
const Header = styled.div`
  font-size: 40px;
  text-align: center;
  font-family: Fredoka;
  font-weight: bold;
  @media (max-width: 1500px) {
    font-size: 35px;
  }
  @media (max-width: 1800px) {
    font-size: 18px;
    text-align: none;
    width: 250px;
  }
`;
const Paragraph = styled.p`
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  word-spacing: 1px;
  font-family: Nunito;
  width: 100%;

  min-width: 400px;
  @media (max-width: 1500px) {
    font-size: 16px;
    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    min-width: 260px;
  }
`;

const Button = styled.button`
  width: 120px;
  padding: 15px;
  border-radius: 25px;
  background: linear-gradient(to right, #239bd7, #0463ae);
  border: none;
  font-weight: bold;
  text-align: center;
  font-family: Nunito;
  color: white;
  align-self: center;
  @media (max-width: 768px) {
    width: 100px;
    padding: 12px;
    font-size: 10px;
  }
`;
