import { ApiQuery } from 'core/model/interface';
import { Services } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'service-groups';

export const getAllGroups = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getGroup = (id: number) => {
  return axios.get(`${baseURL}/${path}/one/${id}`);
};

export const getGroupServices = (facility_id: number, group_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/services/${group_id}`, { params: query });
};

export const updateServiceGroup = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const removeService = (facility_id: number, data: any) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}`, { data });
};

export const createServiceGroup = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const addServices = (data: any) => {
  return axios.post(`${baseURL}/${path}/add-services`, data);
};

export const deleteGroup = (id: number) => {
  return axios.delete(`${baseURL}/${path}/group/${id}`);
};

export const getServicesNotAdded = (facility_id: number, group_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/get-services-not-added/${group_id}`, { params: query });
};
