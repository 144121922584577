import {
  PRODUCT_VARIANT_ENTITY_NAME,
  ProductVariantModel,
  productVariantFields,
  productVariantVisibleColumns,
} from './ProductVariantModel';
import { deleteVariant, getInventories, getVariantsOfProduct } from 'company/api/inventories';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import { ProductModel } from '../ProductModel';
import ProductVariantForm from './ProductVariantForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';

type Props = {
  product?: ProductModel;
  tableAction?: TableActionProps;
};

const ProductVariantTable = forwardRef((props: Props, ref) => {
  const { product, tableAction } = props;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<ProductVariantModel>();
  useImperativeHandle(ref, () => ({ refreshTable }));

  const tableRef: any = useRef();
  const refreshTable = () => {
    tableRef.current.refreshTable();
  };

  const callbackAfterSubmit = () => {
    refreshTable();
    tableRef.current.closeFormModal();
  };

  const handleGetData = () => {
    return product ? getVariantsOfProduct(product?.id as number) : getInventories(facility.id);
  };

  useEffect(() => {
    if (product) refreshTable();
  }, [product]);

  return (
    <TemplateTable
      tableComponent={{
        mobileViewConfig: () => ({
          title_fields: ['variant_name'],
          subtitle_fields: ['sku', 'variant_price'],
        }),
      }}
      ref={tableRef}
      entityName={PRODUCT_VARIANT_ENTITY_NAME}
      fields={productVariantFields}
      visibleColumns={productVariantVisibleColumns}
      getData={handleGetData}
      handleDelete={deleteVariant}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      templateForm={
        <ProductVariantForm product={product} productVariant={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />
      }
      tableAction={{
        ...tableAction,
        rowActionRequiredAuth: ['MANAGE_PRODUCTS_AND_VARIANTS'],
      }}
    />
  );
});

export default ProductVariantTable;
