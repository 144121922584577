import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { formatArray, formatCurrency, formatDate, formatDateTime, getDiscountAmount } from 'core/utils';

import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { OrderProps } from 'company/screens/InventorySystem/POS';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { getInvoiceItems } from 'company/api/invoice-items';
import { getPatient } from 'company/api/patient';
import { tokens } from 'theme/theme';

type Props = {
  invoice: any;
  smallPrinter?: boolean;
};

interface ServiceItem {
  id: number;
  service_name: string;
  price: number;
}
const PrintableInvoice = forwardRef(({ invoice, smallPrinter }: Props, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const [patient, setPatient] = useState<PatientModel>();
  const [items, setItems] = useState<OrderProps[]>([]);

  useEffect(() => {
    if (invoice && facility) {
      getPatient(facility.id, invoice.patient_id).then((patient) => {
        setPatient(patient.data);
      });
      getInvoiceItems(facility.id, 'all', invoice.id, { length: 1000 }).then((items) => {
        setItems(items.data.data);
      });
    }
  }, [facility, invoice]);

  const formatIncludedServices = (included: ServiceItem[] | string | undefined) => {
    if (!included) return null;

    if (typeof included === 'string') {
      return included;
    }

    if (Array.isArray(included)) {
      return included.map((service) => service.service_name).join(', ');
    }

    return null;
  };

  if (smallPrinter) {
    const getFontSize = (size: number) => {
      const adjustment = 1;
      return size + adjustment + 'px';
    };
    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref} sx={{ '& p': { fontWeight: '600 !important' } }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'1px'}
            alignItems={'center'}
            sx={{ '& p': { textAlign: 'center' } }}
          >
            {user.company?.company_logo && (
              <img alt="clinic-logo" width={'40px'} height="auto" id="logo" src={user.company?.company_logo} />
            )}
            <Typography fontSize={getFontSize(10)} fontWeight={'bold'}>
              {facility.facility_name}
            </Typography>
            <Typography fontSize={getFontSize(7)}>
              {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
            </Typography>
            <Typography fontSize={getFontSize(7)}>
              {formatArray([facility.address, facility.municipality_name, facility.province_name])}
            </Typography>
          </Box>

          <Box my="10px">
            <Typography fontSize={'8px'} textAlign="center" fontWeight={'bold'}>
              INVOICE # {invoice.invoice_number}
            </Typography>
            <Typography fontSize={'7px'} textAlign="center">
              {formatDate(invoice.issue_date)}
            </Typography>
          </Box>

          <Box sx={{ '& p': { fontSize: getFontSize(7) + ' !important', color: 'black !important' } }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <PersonPinOutlinedIcon sx={{ fontSize: '12px' }} />
                <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                  Patient Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap="2px" mt="2px" paddingLeft="15px">
                <PatientField label="Full Name" value={patient?.full_name} sx={{ flexDirection: 'row', gap: '5px' }} />
                <PatientField
                  label="HMO"
                  value={invoice?.hmo_name ?? 'None'}
                  sx={{ flexDirection: 'row', gap: '5px' }}
                />
                <PatientField
                  label="HMO Card Number"
                  value={invoice?.hmo_card_number ?? 'None'}
                  sx={{ flexDirection: 'row', gap: '5px' }}
                />
                {invoice?.company_name && (
                  <PatientField
                    label="Corporate Client"
                    value={invoice?.company_name ?? 'None'}
                    sx={{ flexDirection: 'row', gap: '5px' }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <Box mt="10px" sx={{ '& p': { fontSize: getFontSize(7) + ' !important', color: 'black !important' } }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <MedicalInformationIcon sx={{ fontSize: '12px' }} />
                <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                  Service Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap="2px" mt="2px" paddingLeft="15px">
                <PatientField
                  label="Service Provider Name"
                  value={invoice.service_provider_names ?? 'None'}
                  sx={{ flexDirection: 'row', gap: '5px' }}
                />
                <Grid container spacing={0}>
                  {invoice.approval_code && (
                    <Grid item xs={12}>
                      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="5px">
                        <Typography color={colors.primary}>Approval Code: </Typography>
                        <Typography color={colors.primary}>Approved By:</Typography>
                      </Box>
                    </Grid>
                  )}
                  {[1, 2, 3, 4, 5].map((num) => {
                    if (num === 1 && !invoice.approval_code) return <></>;
                    if (num > 1 && !invoice['approval_code_' + num]) return <></>;
                    return (
                      <Grid item xs={12}>
                        <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="5px">
                          <Typography>
                            {num}. {num === 1 ? invoice.approval_code : invoice['approval_code_' + num]}
                          </Typography>
                          <Typography>
                            {num}. {num === 1 ? invoice.approved_by ?? '-' : invoice['approval_code_' + num] ?? '-'}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box mt="10px" sx={{ '& p': { fontSize: getFontSize(7) + ' !important' } }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <ReceiptIcon sx={{ fontSize: '12px' }} />
                <Typography sx={{ fontSize: getFontSize(10) + ' !important', fontWeight: '600' }}>
                  Invoice Details
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="2fr 1fr" gap="2px" mt="2px" paddingRight="10px">
                <Typography fontWeight="600">Items</Typography>
                <Typography fontWeight="600" textAlign="right">
                  Amount
                </Typography>
                <Divider style={{ width: '100%', gridColumn: 'span 2' }} />
                {items.map((invoiceItem, index) => (
                  <>
                    <Typography>
                      {invoiceItem.quantity} × {invoiceItem.item_name}
                    </Typography>
                    {/* <Typography textAlign="right">{getDiscountAmount(invoiceItem)}</Typography> */}
                    <Typography textAlign="right">{formatCurrency(invoiceItem.total_price)}</Typography>
                  </>
                ))}
                <Divider style={{ width: '100%', gridColumn: 'span 2' }} />
                <Box>
                  <Typography>Subtotal:</Typography>
                  <Typography>Total VAT (12%):</Typography>
                  <Typography>VAT Exempt:</Typography>
                  <Typography>Total Discount:</Typography>
                  <Typography fontWeight="600">Total:</Typography>
                </Box>
                <Box sx={{ '& p': { textAlign: 'right' } }}>
                  <Typography>{formatCurrency(invoice.original_amount)}</Typography>
                  <Typography>{formatCurrency(invoice.total_tax)}</Typography>
                  <Typography>{formatCurrency(invoice.total_vat_exempt)}</Typography>
                  <Typography>{formatCurrency(invoice.total_discount)}</Typography>
                  <Typography fontWeight="600">{formatCurrency(invoice.total_amount)}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <PrintableInvoice invoice={invoice} /> */}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <style>{styles}</style>
      <Box ref={ref}>
        <Box>
          {!!user && (
            <Box display={'flex'} justifyContent={'space-between'} mb={'40px'}>
              <Box flex={1} display={'flex'} flexDirection={'column'} rowGap={'20px'} alignItems={'start'}>
                <Typography variant="h1" textAlign="right" fontWeight={'bold'}>
                  INVOICE
                </Typography>
                <Box display={'flex'} flexDirection={'column'} rowGap={'8px'} alignItems={'start'} mt={'5px'}>
                  <Typography variant="h5" textAlign="right" fontWeight={'bold'}>
                    Invoice Date
                  </Typography>
                  <Typography variant="h5" textAlign="left">
                    {formatDate(invoice.issue_date)}
                  </Typography>
                  <Typography variant="h5" textAlign="right" fontWeight={'bold'}>
                    Invoice No.
                  </Typography>
                  <Typography variant="h5" textAlign="left">
                    {invoice.invoice_number}
                  </Typography>
                </Box>
              </Box>

              <Box flex={2} display={'flex'} flexDirection={'column'} rowGap={'5px'} alignItems={'end'}>
                {user.company?.company_logo && (
                  <img alt="clinic-logo" width="90px" height="90px" id="logo" src={user.company?.company_logo} />
                )}
                <Typography variant="h3" textAlign="right" color={colors.primary} fontWeight={'bold'}>
                  {facility.facility_name}
                </Typography>
                <Typography variant="h5" textAlign="right">
                  {formatArray([facility.address, facility.municipality_name, facility.province_name])}
                </Typography>
                <Typography variant="h5" textAlign="right">
                  {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={SIZES.padding}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <PersonPinOutlinedIcon color="primary" />
              <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Patient Information
              </Typography>
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt={SIZES.paddingS}>
              <PatientField label="Full Name" value={patient?.full_name} />
              <PatientField label="HMO" value={invoice?.hmo_name ?? 'None'} />
              <PatientField label="HMO Card Number" value={invoice?.hmo_card_number ?? 'None'} />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt={SIZES.paddingS}>
              <PatientField label="Corporate Client" value={invoice?.company_name ?? 'None'} />
            </Box>
          </Box>

          {/* Service Information, Approval Codes and Approvers */}
          <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding} className="PageBreaker">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MedicalInformationIcon color="primary" />
              <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Service Information
              </Typography>
            </Box>

            <Box mt={SIZES.paddingS}>
              <PatientField label="Service Provider Name" value={invoice.service_provider_names ?? 'None'} />
            </Box>

            {/* Approval Codes and Approvers */}
            {invoice.approval_code && (
              <Box sx={{ '& p': { padding: '0px !important' } }} mt={SIZES.paddingS}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(2, 1fr)"
                      gap="5px"
                      paddingBottom={SIZES.paddingS}
                      sx={{ '& p': { fontSize: '11px !important' } }}
                    >
                      <Typography color={colors.primary}>Approval Code: </Typography>
                      <Typography color={colors.primary}>Approved By:</Typography>
                    </Box>
                  </Grid>
                  {invoice && (
                    <>
                      {invoice.approval_code && (
                        <Grid item xs={12}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gap="4px"
                            paddingBottom={SIZES.paddingS}
                            sx={{ '& p': { fontSize: '11px !important' } }}
                          >
                            <Typography>1. {invoice.approval_code}</Typography>
                            <Typography>1. {invoice.approved_by ?? '-'}</Typography>
                          </Box>
                        </Grid>
                      )}
                      {invoice.approval_code_2 && (
                        <Grid item xs={12}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gap="4px"
                            paddingBottom={SIZES.paddingS}
                            sx={{ '& p': { fontSize: '11px !important' } }}
                          >
                            <Typography>2. {invoice.approval_code_2}</Typography>
                            <Typography>2. {invoice.approved_by_2 ?? '-'}</Typography>
                          </Box>
                        </Grid>
                      )}
                      {invoice.approval_code_3 && (
                        <Grid item xs={12}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gap="4px"
                            paddingBottom={SIZES.paddingS}
                            sx={{ '& p': { fontSize: '11px !important' } }}
                          >
                            <Typography>3. {invoice.approval_code_3}</Typography>
                            <Typography>3. {invoice.approved_by_3 ?? '-'}</Typography>
                          </Box>
                        </Grid>
                      )}
                      {invoice.approval_code_4 && (
                        <Grid item xs={12}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gap="4px"
                            paddingBottom={SIZES.paddingS}
                            sx={{ '& p': { fontSize: '11px !important' } }}
                          >
                            <Typography>4. {invoice.approval_code_4}</Typography>
                            <Typography>4. {invoice.approved_by_4 ?? '-'}</Typography>
                          </Box>
                        </Grid>
                      )}
                      {invoice.approval_code_5 && (
                        <Grid item xs={12}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, 1fr)"
                            gap="4px"
                            paddingBottom={SIZES.paddingS}
                            sx={{ '& p': { fontSize: '11px !important' } }}
                          >
                            <Typography>5. {invoice.approval_code_5}</Typography>
                            <Typography>5. {invoice.approved_by_5 ?? '-'}</Typography>
                          </Box>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Box>
            )}
          </Box>
          {invoice.diagnosis && (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <LocalHospitalIcon color="primary" />
                <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                  Diagnosis Details
                </Typography>
              </Box>
              <Box display="grid" gap={SIZES.paddingS} mt={SIZES.paddingS}>
                <PatientField label="Diagnosis" value={invoice.diagnosis ?? 'None'} />
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: SIZES.padding }}>
            <ReceiptIcon color="primary" />
            <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              Invoice Details
            </Typography>
          </Box>
        </Box>
        <Box>
          <ContainerColumn gap={SIZES.paddingL}>
            <Box>
              <table>
                <thead>
                  <tr>
                    <td width="5%">
                      <Typography fontWeight="600">#</Typography>
                    </td>
                    <td width="35%">
                      <Typography fontWeight="600">Services/Products</Typography>
                    </td>
                    <td width="5%" align="right">
                      <Typography fontWeight="600">Qty</Typography>
                    </td>
                    <td width="15%" align="right">
                      <Typography fontWeight="600">Unit Price</Typography>
                    </td>
                    <td width="20%" align="right">
                      <Typography fontWeight="600">Discount</Typography>
                    </td>
                    <td width="20%" align="right">
                      <Typography fontWeight="600">Amount</Typography>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {items.map((invoiceItem, index) => (
                    <tr>
                      <td>
                        <Typography>{index + 1}</Typography>
                      </td>
                      <td>
                        <Typography>{invoiceItem.item_name}</Typography>
                        {invoiceItem.included_services && (
                          <Typography fontSize={'10px'}>
                            Includes: {formatIncludedServices(invoiceItem.included_services)}
                          </Typography>
                        )}
                      </td>
                      <td align="right">
                        <Typography>{invoiceItem.quantity}</Typography>
                      </td>
                      <td align="right">
                        <Typography>{formatCurrency(invoiceItem.original_price)}</Typography>
                      </td>
                      <td align="right">
                        <Typography>{getDiscountAmount(invoiceItem)}</Typography>
                        <Box display="flex" gap="5px" alignItems="center" width="100%" justifyContent="flex-end">
                          {(!!invoiceItem.discount_type || !!invoiceItem.sc_pwd_discounted) && (
                            <LocalMallIcon
                              style={{
                                verticalAlign: 'middle',
                                fontSize: '12px',
                                marginTop: '-4px',
                                marginLeft: '10px',
                              }}
                            />
                          )}
                          {!!invoiceItem.discount_type && <Typography>Promo</Typography>}
                          {!!invoiceItem.discount_type && !!invoiceItem.sc_pwd_discounted && <Typography>&</Typography>}
                          {!!invoiceItem.sc_pwd_discounted && <Typography>SC/PWD</Typography>}
                        </Box>
                      </td>
                      <td align="right">
                        <Typography>{formatCurrency(invoiceItem.total_price)}</Typography>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} style={{ backgroundColor: '#f3f9ff' }}></td>
                    <td colSpan={2} align="right" style={{ backgroundColor: '#f3f9ff' }}>
                      <Typography>Subtotal:</Typography>
                      <Typography>Total VAT (12%):</Typography>
                      <Typography>VAT Exempt:</Typography>
                      <Typography>Total Discount:</Typography>
                      <Typography fontWeight="600">Total:</Typography>
                    </td>
                    <td align="right" style={{ backgroundColor: '#f3f9ff' }}>
                      <Typography>{formatCurrency(invoice.original_amount)}</Typography>
                      <Typography>{formatCurrency(invoice.total_tax)}</Typography>
                      <Typography>{formatCurrency(invoice.total_vat_exempt)}</Typography>
                      <Typography>{formatCurrency(invoice.total_discount)}</Typography>
                      <Typography fontWeight="600">{formatCurrency(invoice.total_amount)}</Typography>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <Box width="100%">
                        <Typography sx={{ height: '50px' }}></Typography>
                        <Box position="fixed" bottom="0" width="100%">
                          <hr
                            style={{
                              border: 'none',
                              borderTop: '0.5px solid #0192D5',
                              width: '100%',
                              transform: 'scaleY(0.5)',
                            }}
                          />

                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                              <img
                                alt="cphi-logo"
                                width="35px"
                                height="35px"
                                id="logo"
                                src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                              />

                              <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                                  Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Box>
          </ContainerColumn>
        </Box>
      </Box>
    </Box>
  );
});

const styles = `
  table {
    border-collapse: collapse;
  
    width: 100%;
  }

  thead td, tbody td {
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
  }
`;

export default PrintableInvoice;
