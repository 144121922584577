import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import CustomIconButton from './IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RegularButton from './RegularButton';
import { tokens } from 'theme/theme';

type DeleteButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  styles?: SxProps<Theme>;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, styles }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isTablet = useMediaQuery('(max-width:1000px)');

  if (isTablet) {
    return (
      <CustomIconButton
        icon={<DeleteOutlinedIcon sx={{ color: colors.deleteColor }} />}
        onClick={onClick}
        tooltip="Delete"
      />
    );
  }

  return (
    <RegularButton
      styles={{
        textTransform: 'none',
        backgroundColor: colors.deleteColor,
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: colors.red_hovered,
        },
        ...styles,
      }}
      size="small"
      startIcon={<DeleteOutlinedIcon color="error" sx={{ color: '#fff' }} />}
      onClick={onClick}
      label="Delete"
    />
  );
};

export default DeleteButton;
