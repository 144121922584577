import { PC_ENTITY_NAME, productCategoryFields, productCategoryTableColumns } from './ProductCategoryModel';
import { deleteProductCategory, getProductCategories } from 'company/api/product-categories';
import { forwardRef, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { CompanyServiceModel } from 'company/model/Entities';
import ProductCategoryForm from './ProductCategoryForm';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
  parentTableRef?: any;
};

const ProductCategoryTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef, parentTableRef } = props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const getData = (query: ApiQuery) => {
    return getProductCategories(query);
  };

  const handleDelete = (data: any) => {
    const categoryList = JSON.stringify([data]);
    const categories = { categories: categoryList };

    deleteProductCategory(categories).then((res) => {
      parentTableRef.current.refreshTable();
    });
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <TemplateTable
      tableComponent={{
        mobileViewConfig: () => ({
          title_fields: ['category'],
          subtitle_fields: ['description'],
        }),
      }}
      ref={mainRef}
      entityName={PC_ENTITY_NAME}
      fields={productCategoryFields}
      visibleColumns={visibleColumns ?? productCategoryTableColumns}
      templateForm={<ProductCategoryForm productCategory={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
    />
  );
});

export default ProductCategoryTable;
