import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'setup';

export const getHealthProgramCategories = () => {
  return axios.get(`${baseURL}/${path}/program-categories`);
};

export const getCollections = () => {
  return axios.get(`${baseURL}/${path}/collections`);
};
