/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { BreadcrumbContext, BreadcrumbTabProps } from 'core/context/breadcrumb.context';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  Dropdown,
  Header,
} from 'core/components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { changeNullToBlank, formatDate, tranformFormErrors } from 'core/utils';
import { deletePatient, getPatient, updatePatient } from 'lgu/api/patient';
import { useContext, useEffect, useState } from 'react';

import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { PatientModel } from 'lgu/model/Entities';
import dayjs from 'dayjs';
import { getBarangays } from 'lgu/api/barangay';
import { getProgramSchedulesOfPatient } from 'lgu/api/program-schedule';
import { tokens } from 'theme/theme';
import { update_patient_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  sex: string;
  birthday: string;
  barangay_code: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const ViewPatientProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [barangays, setBarangays] = useState<any[]>([]);
  const [patient, setPatient] = useState<PatientModel>();
  const navigate = useNavigate();
  const [openPatientModal, setOpenPatientModal] = useState<boolean>(false);
  const [patientInitialValues, setPatientInitialValues] = useState<UserInput>();
  const [openPatientDelete, setOpenPatientDelete] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const getPatientDetails = () => {
    getPatient(parseInt(id as string))
      .then((res) => {
        setPatient(res.data);
      })
      .catch();
  };

  const getSchedulesofPatient = (query: ApiQuery) => {
    setLoading(true);
    getProgramSchedulesOfPatient(parseInt(id as string), query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const updatePatientInfo = () => {
    if (patient) {
      setPatientInitialValues(changeNullToBlank(patient));
    }

    setOpenPatientModal(true);
  };

  const deletePatientInfo = () => {
    setOpenPatientDelete(true);
    if (patient) {
      setAlertContent('Are you sure you want to delete ' + patient.full_name + '?');
    }
  };

  const settings_actions = [
    {
      label: 'Update Patient',
      action: updatePatientInfo,
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Delete Patient',
      action: deletePatientInfo,
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (patient) {
      updatePatient(patient.patient_id, data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
      return;
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully updated!`, { variant: 'success' });
      setOpenPatientModal(false);
      getPatientDetails();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const handlePatientDelete = () => {
    setLoading(true);
    deletePatient(patient!.patient_id)
      .then((res: any) => {
        enqueueSnackbar('Patient successfully deleted!', { variant: 'success' });
        navigate('/patient-management');
      })
      .catch((error) => console.error(error));
    return;
  };

  const columns: GridColDef[] = [
    {
      field: 'schedule_name',
      headerName: 'Schedule Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/programs/program-schedules/details/${params.row.id}`} className="emphasis">
            {params.row.schedule_name}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'program_name', headerName: 'Program Name', flex: 1.5 },
    {
      field: 'program_date_time',
      headerName: 'Schedule Date',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{dayjs(params.row.program_date_time).format('lll')}</CustomGridCell>,
    },
    {
      field: 'attended',
      headerName: 'Attendance',
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CustomGridCell>
          {dayjs().isAfter(dayjs(params.row.program_date_time)) ? (
            params.row.attended ? (
              <Tooltip title="Attended" placement="left" arrow>
                <CheckBoxRoundedIcon fontSize="large" color="success" />
              </Tooltip>
            ) : (
              <Tooltip title="Absent" placement="left" arrow>
                <DisabledByDefaultRoundedIcon fontSize="large" color="error" />
              </Tooltip>
            )
          ) : (
            <Typography
              sx={{
                backgroundColor: 'gold',
                color: 'black',
                padding: '2px 10px',
                borderRadius: '2px',
              }}
            >
              Upcoming
            </Typography>
          )}
        </CustomGridCell>
      ),
    },
  ];

  // Form Fields
  const patientFields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'birthday', display_name: 'Birthday (optional)', type: 'date', disableFuture: true },
    {
      field_name: 'sex',
      display_name: 'Sex Assigned At Birth',
      type: 'select',
      options: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
      ],
    },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay',
      type: 'select',
      options: barangays.map((u) => {
        return { key: u.barangay_name, value: u.barangay_code };
      }),
    },
  ];

  useEffect(() => {
    const breadcrumbs: BreadcrumbTabProps[] = [{ label: 'Patient Management', link: '/patient-management' }];

    if (patient) {
      breadcrumbs.push({ label: patient.full_name });
    }

    setBreadcrumb(breadcrumbs);
  }, [patient]);

  useEffect(() => {
    getPatientDetails();
    getBarangayList();
  }, []);

  return (
    <>
      <CustomContainer m="0 0 20px 0">
        {patient && (
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Header title={patient?.full_name} />
              <Dropdown optionList={settings_actions} buttonLabel="Settings" />
            </Box>
            <Box display="grid" gridTemplateColumns="100px 1fr 100px 1fr" columnGap="20px" alignItems="center">
              <Typography>Mobile Number</Typography>
              <Typography>{patient.mobile_number}</Typography>

              <Typography>Barangay</Typography>
              <Typography>{patient.barangay_name}</Typography>

              <Typography>Birthday</Typography>
              <Typography>{formatDate(patient?.birthday)}</Typography>

              <Typography>Date Registered</Typography>
              <Typography>{formatDate(patient?.created_at)}</Typography>
            </Box>
          </Box>
        )}
      </CustomContainer>

      <CustomContainer>
        <Typography variant="h4" component="h3" color={colors.text} fontWeight="bold">
          Enrolled Programs
        </Typography>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getSchedulesofPatient}
          checkboxSelection={false}
          selectableRow={false}
          disableSelectionOnClick
          keepNonExistentRowsSelected={false}
        />
      </CustomContainer>
      <ConfirmationDialog
        open={openPatientDelete}
        setOpen={setOpenPatientDelete}
        content={alertContent}
        onConfirm={handlePatientDelete}
      />
      <CustomModal header={'Update Patient'} open={openPatientModal} setOpen={setOpenPatientModal}>
        <CustomForm
          initialValues={patientInitialValues}
          onSubmit={handleSubmit}
          fields={patientFields}
          schema={update_patient_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default ViewPatientProfile;
