import { CompanySettings, HelpDesk, HelpDeskArticle, HelpDeskCategory, SmsScreen } from 'company/screens';

import About from 'company/screens/Settings/screens/About';
import { AccountType } from 'core/model/Entities';
import ActivityLogs from 'company/screens/Settings/screens/ActivityLogs';
import CompanyUserManagement from 'company/screens/CompanyUserManagement';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';
import TermsOfUse from 'company/screens/Settings/screens/TermsOfUse';

const SettingsRoutes = (
  <Route path="/company" element={<ProtectedRoute accountType={AccountType.COMPANY} />}>
    <Route path="user-management" element={<CompanyUserManagement />} />

    <Route
      path="settings"
      element={
        <ProtectedRoute
          requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']}
          element={<CompanySettings />}
        />
      }
    />
    <Route
      path="sms"
      element={
        <ProtectedRoute requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']} element={<SmsScreen />} />
      }
    />

    <Route path="helpdesk">
      <Route index element={<HelpDesk />} />
      <Route path=":category/:article" element={<HelpDeskArticle />} />
      <Route path=":categoryCode" element={<HelpDeskCategory />} />
    </Route>

    <Route path="about" element={<About />} />
    <Route path="activity-log" element={<ActivityLogs />} />
    <Route path="terms" element={<TermsOfUse />} />
  </Route>
);

export default SettingsRoutes;
