import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const IArticle1: React.FC = () => {
  return (
    <Box>
      <Step>1.Go to the “Family Planning” - "Injectables Reminders" - "Patient View" tab in application.</Step>
      <Step>2. Click on the “Enroll Cilents” button.</Step>
      <Image src="/assets/FaqAssets/injectables/1/1-2.png" alt="Step 1-2 Image" />

      <Step>3.Select patient you want to enroll and click on the “Enroll” button to enroll patients</Step>
      <Image src="/assets/FaqAssets/injectables/1/3.png" alt="Image" />
    </Box>
  );
};

export default IArticle1;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
