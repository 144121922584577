import { forwardRef, useContext, useEffect, useState } from 'react';
import { getExpenseLineItems, updateExpenseLineItems } from 'company/api/expense-line-items';

import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { DROPDOWN_FIELD } from 'core/model/interface';
import { ExpenseModel } from '../Expense/ExpenseModel';
import { FacilityContext } from 'core/context/facility.context';
import { FormikHelpers } from 'formik';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { WaitForFacility } from 'company/components';
import { expenseLineItemInitialValues } from './ExpenseLineItemModel';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type Props = {
  expense: ExpenseModel;
  tableAction?: TableActionProps;
  refreshTable?: () => void;
};

const ExpenseLineItemTable = forwardRef((props: Props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const { expense, refreshTable } = props;
  const [initialValues, setInitialValues] = useState(expenseLineItemInitialValues);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (expense) {
      updateExpenseLineItems(facility.id, expense.id, data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Expense Line Items successfully updated!`, { variant: 'success' });
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    refreshTable && refreshTable();
    setButtonLoading(false);
  };

  useEffect(() => {
    if (expense) {
      const fetchData = async () => {
        try {
          const response = await getExpenseLineItems(facility.id, expense?.id as number);
          const data = response.data.data;
          const formattedData = data.map((item: any) => ({
            expense_type: item.expense_type as number,
            description: item.description,
            amount: item.amount,
          }));
          setInitialValues((prevState) => ({
            ...prevState,
            line_items: formattedData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [expense, facility.id]);

  return (
    <WaitForFacility facility={facility}>
      <Box display="flex" flexDirection="column" gap="20px">
        <CustomForm
          onSubmit={handleSubmit}
          fields={[
            {
              type: 'table',
              field_name: 'line_items',
              display_name: 'Expenses',
              columns: [
                { fieldName: 'expense_type', displayName: 'Expense Type', type: DROPDOWN_FIELD.EXPENSE_TYPE },
                { fieldName: 'description', displayName: 'Description' },
                { fieldName: 'amount', displayName: 'Amount' },
              ],
            },
          ]}
          initialValues={initialValues}
          loading={buttonLoading}
        />
      </Box>
    </WaitForFacility>
  );
});

export default ExpenseLineItemTable;
