import { CustomContainer, Header } from 'core/components';
import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { useTheme } from '@mui/material';

const About = () => {
  const theme = useTheme();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  useEffect(() => {
    setBreadcrumb([{ label: 'About' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CustomContainer>
      <Header title="About CareGo Health Suite" />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
        <img
          src={
            process.env.PUBLIC_URL +
            (theme.palette.mode === 'light'
              ? '/assets/carego_health_suite_logo.png'
              : '/assets/carego_health_suite_logo_white.png')
          }
          className="about_logo"
          alt="CareGo Logo"
          style={{ width: '30%', margin: '0px' }}
        />
      </div>

      <div
        className="aboutInfo"
        style={{ display: 'block', fontSize: '16px', textAlign: 'justify', textJustify: 'inter-word' }}
      >
        <p style={{ textIndent: '50px', margin: '0 100px', lineHeight: '30px' }}>
          CareGo Health Suite is a clinic management software and an automated SMS nudge platform designed to help
          clinics inform and engage their patients and patients about the availability of health services.
        </p>

        <p style={{ textIndent: '50px', margin: '15px 100px', lineHeight: '30px' }}>
          For questions or details please message us at{' '}
          <span style={{ color: 'teal' }}>partnerships@cphealthinnovations.com</span>.
        </p>
      </div>

      <div
        className="contactInfo"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          paddingTop: '50px',
        }}
      >
        <span style={{}}> CareGo Health Suite </span>
        <span style={{}}> by CP Health Innovations Inc. </span>
        <span style={{}}> U214-215, Commercenter building, </span>
        <span style={{}}>Commerce Avenue,Filinvest, Alabang, Muntinlupa City</span>
      </div>
    </CustomContainer>
  );
};

export default About;
