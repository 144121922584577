import { CustomModal } from 'core/components';
import EmployeeCredentials from './components/EmployeeCredentials';

type AccountManagementProps = {
  open: boolean;
  setOpen: (openModal: boolean) => void;
};

const AccountManagement: React.FC<AccountManagementProps> = ({ open, setOpen }) => {
  return (
    <>
      <CustomModal header={'Update Password'} open={open} setOpen={setOpen} width={500}>
        <EmployeeCredentials />
      </CustomModal>
    </>
  );
};

export default AccountManagement;
