/* eslint-disable react-hooks/exhaustive-deps */
import { ConfirmationDialog, CustomGridCell, CustomTable, PrimaryButton, RowAction } from 'core/components';
import { changeNullToBlank, formatDate } from 'core/utils';
import { deactivate, exportUserAccounts, getUserAccounts, reactivate } from 'carego-admin/api/company-user';
import { useEffect, useState } from 'react';

import AddCompanyUser from './AddCompanyUser';
import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CompanyModel } from 'company/model/Entities';
import { CompanyUserModel } from 'carego-admin/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PasswordIcon from '@mui/icons-material/Password';
import UpdatePassword from './UpdatePassword';
import { getActiveCompanyFacilities } from 'carego-admin/api/facility';
import { useSnackbar } from 'notistack';
import { userGroups } from '../data/list';

type UserAccessProps = {
  company: CompanyModel;
};

const UserAccess: React.FC<UserAccessProps> = ({ company }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const [openAddUserAccountModal, setOpenAddUserAccountModal] = useState<boolean>(false);

  const [companyUser, setCompanyUser] = useState<CompanyUserModel>();

  //deactivate or reactivate
  const [openConfirmationCompanyUserModal, setOpenConfirmationCompanyUserModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [userStatus, setUserStatus] = useState<number>(1);

  //update password
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState<boolean>(false);

  const [facilities, setFacilities] = useState<any[]>([]);

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'full_name', headerName: 'Full Name', flex: 1 },
    {
      field: 'user_group_id',
      headerName: 'User Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {userGroups?.map((u: any) => {
            if (u.id === params.row.user_group_id) return u.group_name;
            return '';
          })}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Registered',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    { field: 'assigned_facilities', headerName: 'Facilities', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <div onClick={() => setUserStatus(params.row.is_active)}>
          <RowAction actions={actions} data={params.row} />
        </div>
      ),
    },
  ];

  const updateCompanyUserPassword = (data: any) => {
    setCompanyUser(changeNullToBlank(data));
    setOpenUpdatePasswordModal(true);
  };

  const updateUserStatus = (data: any) => {
    if (data.is_active === 1) {
      showAlertDialog('Are you sure you want to deactivate ' + data.full_name + '?');
    } else {
      showAlertDialog('Are you sure you want to reactivate ' + data.full_name + '?');
    }
    setCompanyUser(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenConfirmationCompanyUserModal(true);
    setAlertContent(content);
  };

  const actions = [
    { label: 'Update Password', action: updateCompanyUserPassword, icon: <PasswordIcon /> },
    {
      label: userStatus === 1 ? 'Deactivate' : 'Reactivate',
      action: updateUserStatus,
      icon: userStatus === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
  ];

  const getUsers = (query: ApiQuery) => {
    setLoading(true);
    getUserAccounts(company.id, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleCompanyUserDeactivation = () => {
    deactivate(companyUser!.id)
      .then(() => {
        enqueueSnackbar('Company User successfully deactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const handleCompanyUserReactivation = () => {
    reactivate(companyUser!.id)
      .then(() => {
        enqueueSnackbar('Company User successfully reactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const getFacilities = (query?: ApiQuery) => {
    getActiveCompanyFacilities(company.id, query!)
      .then((res) => {
        setFacilities(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    company && getFacilities();
  }, [company]);

  return (
    <>
      <Box display="flex" gap="10px" width="100%">
        <PrimaryButton onClick={() => setOpenAddUserAccountModal(true)} label="Create User Account" />
      </Box>

      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getUsers}
        exportRecords={() => exportUserAccounts({ page: 1, length: 10 }, company.company_name, company.id)}
        forceRefresh={forceRefresh}
      />

      <AddCompanyUser
        open={openAddUserAccountModal}
        setOpen={setOpenAddUserAccountModal}
        refreshTable={refreshTable}
        company={company!}
        facilities={facilities}
      />

      <ConfirmationDialog
        open={openConfirmationCompanyUserModal}
        setOpen={setOpenConfirmationCompanyUserModal}
        content={alertContent}
        onConfirm={userStatus === 1 ? handleCompanyUserDeactivation : handleCompanyUserReactivation}
        confirmButtonColor={userStatus === 1 ? false : true}
      />

      <UpdatePassword
        open={openUpdatePasswordModal}
        setOpen={setOpenUpdatePasswordModal}
        companyUser={companyUser!}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default UserAccess;
