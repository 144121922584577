import { Box, Button, Grid } from '@mui/material';
import { ConfirmationDialog, CustomGridCell, CustomInlineForm, CustomTable } from 'core/components';
import { createLguProgramScheduleSMS, deleteLguProgramScheduleSMS, getLguProgramScheduleSMS } from 'lgu/api/sms';
import { formatDate, formatDateTime, formatTime, tranformFormErrors } from 'core/utils';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { Header } from 'core/components';
import { ProgramScheduleModel } from 'lgu/model/Entities';
import dayjs from 'dayjs';
import { sms_reminder_schema } from 'lgu/model/schema';
import { sms_sending_options } from 'lgu/data/select-options';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type ScheduleSmsRemindersProps = {
  schedule: ProgramScheduleModel;
};

type UserInput = {
  send_date: Date;
  send_time: string;
  sms_sending_id: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  send_date: new Date(),
  send_time: '',
  sms_sending_id: '',
};

const ScheduleSmsReminders: React.FC<ScheduleSmsRemindersProps> = ({ schedule }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [reminderID, setReminderID] = useState<number>();
  const [hideDate, setHideDate] = useState<boolean>(true);

  const [openSmsDeleteReminder, setOpenSmsDeleteReminder] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  const columns: GridColDef[] = [
    {
      field: 'sms_sending_id',
      headerName: 'SMS Sending',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {sms_sending_options.find((option) => option.value === params.row.sms_sending_id + '')?.key}
        </CustomGridCell>
      ),
    },
    {
      field: 'send_date',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.send_date_time)}</CustomGridCell>,
    },
    {
      field: 'send_time',
      headerName: 'Time',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatTime(params.row.send_date_time)}</CustomGridCell>,
    },
    { field: 'creator', headerName: 'Created By', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              showSmsReminderDialog('Are you sure you want to delete this SMS reminder?');
              setReminderID(params.row.id);
            }}
            disabled={params.row.status === 'Completed'}
          >
            {params.row.status === 'Completed' ? 'Sent' : 'Delete'}
          </Button>
        </Box>
      ),
    },
  ];

  // Form Fields
  const reminderFields: UserFieldInput[] = [
    {
      field_name: 'sms_sending_id',
      display_name: 'Date of SMS Sending',
      type: 'select',
      size: 'small',
      span: 2,
      options: sms_sending_options.map((u) => {
        return { key: u.key, value: u.value };
      }),
      onChange: (value: string, setFieldValue: any) => {
        if (value === '5') {
          setFieldValue('send_date', '');
          setHideDate(false);
        } else {
          setHideDate(true);
          if (value === '1') {
            setFieldValue('send_date', dayjs(schedule.program_date_time).subtract(1, 'week').format('YYYY-MM-DD'));
          } else if (value === '2') {
            setFieldValue('send_date', dayjs(schedule.program_date_time).subtract(3, 'day').format('YYYY-MM-DD'));
          } else if (value === '3') {
            setFieldValue('send_date', dayjs(schedule.program_date_time).subtract(1, 'day').format('YYYY-MM-DD'));
          } else if (value === '4') {
            setFieldValue('send_date', dayjs(schedule.program_date_time).format('YYYY-MM-DD'));
          }
        }
      },
    },
    { field_name: 'send_date', display_name: 'Date', type: 'date', size: 'small', hidden: hideDate, span: 2 },
    { field_name: 'send_time', display_name: 'Time', type: 'time', size: 'small', minutesStep: 5, span: 2 },
  ];

  const getRemindersList = () => {
    setLoading(true);
    if (schedule) {
      getLguProgramScheduleSMS(schedule.id)
        .then((res) => {
          setRows(res.data.data);
          setRowCount(res.data.meta.total);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const send_datetime = data.send_date + ' ' + data.send_time;
    const smsData = {
      send_date_time: send_datetime,
      sms_sending_id: data.sms_sending_id,
    };
    if (schedule) {
      createLguProgramScheduleSMS(schedule.id, smsData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`SMS Reminder successfully added!`, { variant: 'success' });
      getRemindersList();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const showSmsReminderDialog = (content: string) => {
    setOpenSmsDeleteReminder(true);
    setAlertContent(content);
  };

  const handleSmsReminderDelete = () => {
    deleteLguProgramScheduleSMS(reminderID!)
      .then((res) => {
        enqueueSnackbar('SMS Reminder successfully deleted!', { variant: 'success' });
        getRemindersList();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {schedule ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header title={schedule.schedule_name} subtitle={formatDateTime(schedule.program_date_time)} />
            <CustomInlineForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              fields={reminderFields}
              loading={buttonLoading}
              submitButtonText="Add SMS Schedule"
              schema={sms_reminder_schema}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={columns}
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              rowId={'id'}
              getData={getRemindersList}
            />
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <ConfirmationDialog
        open={openSmsDeleteReminder}
        setOpen={setOpenSmsDeleteReminder}
        content={alertContent}
        onConfirm={handleSmsReminderDelete}
      />
    </>
  );
};

export default ScheduleSmsReminders;
