import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { deleteCreditRequests, getCreditRequests, updateCreditRequests } from 'carego-admin/api/credit-request';

import { ApiQuery } from 'core/model/interface';
import { ConfirmationDialog } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const SMSCredits = () => {
  const [refreshTable, setRefreshTable] = useState(0);

  const [openApprovePurchaseRequestModal, setOpenApprovePurchaseRequestModal] = useState<boolean>(false);
  const [approveId, setApproveId] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    { field: 'company_name', headerName: 'Company Name', flex: 1 },
    { field: 'credits', headerName: 'SMS', flex: 1 },
    { field: 'cost', headerName: 'Cost in PHP', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <Typography>{formatDate(params.row.created_at)}</Typography>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getCreditRequests(query);
  };

  const handleDelete = (data: any) => {
    return deleteCreditRequests(data);
  };

  const handleApprove = () => {
    approveId &&
      updateCreditRequests(approveId, 'Approved')
        .then(() => {
          enqueueSnackbar(`Purchase Request successfully approved!`, { variant: 'success' });
          setRefreshTable((prev: number) => prev + 1);
          setOpenApprovePurchaseRequestModal(false);
        })
        .catch((err) => console.error(err));
  };

  // setRefreshTable((prev: number) => prev + 1);

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Credit Requests',
    subHeader: 'See all the requests for sms credit purchases here.',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Credit Request',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
        },
        tableAction: {
          includeUpdateAction: true,
          includeDeleteAction: true,
          updateButtonLabel: 'Approve',
          deleteButtonLabel: 'Decline',
          deleteApiFunction: handleDelete,
          handleRowActionsClick: (selectedRow, action) => {
            if (action === 'update') {
              setApproveId(selectedRow.id);
              setOpenApprovePurchaseRequestModal(true);
            }
          },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <ConfirmationDialog
        content="Are you sure you want to approve this request?"
        open={openApprovePurchaseRequestModal}
        setOpen={setOpenApprovePurchaseRequestModal}
        onConfirm={handleApprove}
      />
      <PageBuilder content={content} />
    </>
  );
};

export default SMSCredits;
