import { Box } from '@mui/material';

type CustomContainerProps = {
  m?: string;
  p?: string;
  gap?: string;
  children: React.ReactNode;
};

const CustomContainer: React.FC<CustomContainerProps> = ({ m, p = '0px', gap = '25px', children }) => {
  return (
    <Box
      display="flex"
      gap={gap}
      flexDirection="column"
      alignItems="flex-start"
      // sx={{ backgroundColor: colors.secondary_background }}
      p={p}
      m={m}
      width="100%"
      borderRadius={2}
    >
      {children}
    </Box>
  );
};

export default CustomContainer;
