import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'note-templates-graph';

export const getGraphs = (template_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/${template_id}`, {
    params: query,
  });
};

export const getGraph = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createGraphs = (template_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${template_id}`, data);
};

export const updateGraphs = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteGraphs = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
