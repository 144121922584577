import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'transfers';

export const getTransfer = (facility_code: string, id: number) => {
  return axios.get(`${baseURL}/public/${path}/${facility_code}/${id}`);
};

export const getTransfers = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const createTransfer = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

// export const updateTransfer = (id: number, data: any) => {
//   return axios.put(`${baseURL}/${path}/${id}`, data);
// };

// export const deleteTransfer = (id: number) => {
//   return axios.delete(`${baseURL}/${path}/${id}`);
// };
