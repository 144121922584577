/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import InputContainer from '../common/InputContainer';
import { TbAcfCompanyPatientModel } from 'company/model/Entities';
import dayjs from 'dayjs';
import { enrollToTbDots } from 'company/api/tb-acf';
import { enroll_to_tbdots_company_schema } from 'company/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type TbDotsEnrollmentProps = {
  patient: TbAcfCompanyPatientModel;
  refreshTable: () => void;
  result: number;
  setResult: (result: number) => void;
  setStatus: (status: string) => void;
};

type UserInput = {
  start_datetime: string;
  sending_time: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  start_datetime: '',
  sending_time: '09:00',
};

const TbDotsEnrollment: React.FC<TbDotsEnrollmentProps> = ({ patient, refreshTable, setResult, result, setStatus }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const savedValues: UserInput = {
    start_datetime: patient.medication_day_one ? patient.medication_day_one.split(' ')[0] : '',
    sending_time: patient.medication_day_one ? patient.medication_day_one.split(' ')[1].slice(0, 5) : '',
  };

  const [medicationInitialValues, setMedicationInitialValues] = useState<UserInput>(
    patient.medication_day_one && patient.medication_day_one !== '0000-00-00 00:00:00' ? savedValues : initialValues
  );
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [submitButtonText, setSubmitButtonText] = useState<string>(result ? 'Enrolled' : 'Enroll');
  const [disableSubmit, setDisableSubmit] = useState<boolean>(patient.enrolled_tb_dots === 1 ? true : false);

  const medicationFormFields: UserFieldInput[] = [
    {
      field_name: 'start_datetime',
      display_name: 'Start Date of Medication',
      type: 'date',
      disablePast: true,
      format: 'YYYY-MM-DD',
      minutesStep: 5,
      disabled: patient.enrolled_tb_dots ? true : false,
    },
    {
      field_name: 'sending_time',
      display_name: 'SMS Reminder Time',
      type: 'time',
      minutesStep: 5,
      format: 'HH:mm',
      disabled: patient.enrolled_tb_dots ? true : false,
    },
  ];

  const handleSubmit = (data: any) => {
    setButtonLoading(true);

    const start_datetime = dayjs(data.start_datetime);
    const patientList = JSON.stringify([patient.id]);
    const patientData = {
      patients: patientList,
      start_datetime: data.start_datetime,
      sending_time: data.sending_time + ':00',
      tb_status: 'Intensive',
      tb_sputum_schedule1: dayjs(start_datetime.add(8, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule2: dayjs(start_datetime.add(20, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule3: dayjs(start_datetime.add(24, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
    };

    if (!result) {
      enrollToTbDots(facility.id, patientData)
        .then((res) => {
          enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
          setButtonLoading(false);
          setSubmitButtonText('Enrolled');
          setResult(1);
          refreshTable();
          setDisableSubmit(true);
          setStatus('Enrolled');
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    setStatus('Positive');

    if (patient.medication_day_one && patient.medication_day_one !== '0000-00-00 00:00:00') {
      setMedicationInitialValues(savedValues);
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px" marginY="20px">
        <Typography variant="h5" color={colors.text} fontWeight="bold">
          TB-DOTS Details
        </Typography>
      </Box>

      <InputContainer>
        <CustomForm
          onSubmit={handleSubmit}
          schema={enroll_to_tbdots_company_schema}
          initialValues={medicationInitialValues}
          loading={buttonLoading}
          fields={medicationFormFields}
          submitButtonText={submitButtonText}
          disabled={disableSubmit}
        />
      </InputContainer>
    </>
  );
};

export default TbDotsEnrollment;
