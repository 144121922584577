import {
  PatientEnrollment,
  ProgramPatientModel,
  SetProgramRemindersSchedule,
  TbAcfScreeningEnrollmentModel,
  TbAcfScreeningResult,
  TbAcfSputumEnrollmentModel,
  TbAcfSputumResult,
  TbAcfStageModel,
  TbAcfUpdateCheckupModel,
  TbAcfUpdateScreeningModel,
  TbAcfUpdateSputumModel,
} from 'company/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-tb-acf-patients';

export const getPatientsEnrolledInTbAcf = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, {
    params: query,
  });
};

export const enroll = (
  facility_id: number,
  patients: TbAcfScreeningEnrollmentModel | TbAcfSputumEnrollmentModel | TbAcfStageModel
) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, patients);
};

export const updatePatient = (facility_id: number, id: number, patient: ProgramPatientModel) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, patient);
};

export const unenroll = (facility_id: number, patients: PatientEnrollment) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}`, {
    params: patients,
  });
};

export const getTbAcfPatient = (facility_id: number, patient_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${patient_id}`);
};

export const updateStage = (facility_id: number, patients: TbAcfStageModel) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/stage`, patients);
};

export const updateScreeningDetails = (facility_id: number, patients: TbAcfUpdateScreeningModel) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/screening`, patients);
};

export const updateSputumDetails = (facility_id: number, patients: TbAcfUpdateSputumModel) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/sputum`, patients);
};

export const updateCheckupDetails = (facility_id: number, patients: TbAcfUpdateCheckupModel) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/checkup`, patients);
};

export const updateScreeningResult = (facility_id: number, patients: TbAcfScreeningResult) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/screening-result`, patients);
};

export const updateSputumResult = (facility_id: number, patients: TbAcfSputumResult) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/sputum-result`, patients);
};

export const saveReferredHospital = (facility_id: number, id: number, referred_hospital: string) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/referred-hospital/${id}`, referred_hospital);
};

export const enrollToTbDots = (facility_id: number, patients: SetProgramRemindersSchedule) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/tb-dots-enrollment`, patients);
};

export const unenrollToTbDots = (facility_id: number, patients: SetProgramRemindersSchedule) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/tb-dots-unenrollment`, patients);
};
