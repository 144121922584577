import { UserGroupAuthority } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'user-group-authority';

export const getUserGroupAuthorities = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const getUserGroupAuthority = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createUserGroupAuthority = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const createManyUserGroupAuthority = (id: number, group_name: string) => {
  return axios.post(`${baseURL}/${path}/many`, { id, group_name });
};

export const updateUserGroupAuthority = (data: UserGroupAuthority) => {
  return axios.put(`${baseURL}/${path}`, data);
};

export const deleteUserGroupAuthority = (group_id: number, user_authority_id: number) => {
  return axios.delete(`${baseURL}/${path}/${group_id}/${user_authority_id}`);
};

export const deleteManyUserGroupAuthority = (group_id: number, group_name: string) => {
  return axios.delete(`${baseURL}/${path}/many/${group_id}/${group_name}`);
};

export const applyAllUserGroup = (group_id: number) => {
  return axios.put(`${baseURL}/${path}/many/${group_id}`);
};

export const deleteAllUserGroupAuthority = (group_id: number) => {
  return axios.delete(`${baseURL}/${path}/many/${group_id}`);
};