import {
  CHART_OF_ACCOUNT_ENTITY_NAME,
  chartOfAccountFields,
  chartOfAccountInitialValues,
  chartOfAccountVisibleColumns,
} from './ChartOfAccountModel';
import { create, update } from 'company/api/chart-of-account';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { useContext } from 'react';

type Props = {
  selectedRow?: any;
  callbackAfterSubmit?: () => void;
};

const ChartOfAccountForm: React.FC<Props> = (props) => {
  const { selectedRow, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);

  const handleFormSubmit = (data: any) => {
    if (selectedRow) data.id = selectedRow.id;
    return selectedRow ? update(data) : create(facility.id, data);
  };

  return (
    <TemplateForm
      entity={selectedRow}
      entityName={CHART_OF_ACCOUNT_ENTITY_NAME}
      fields={chartOfAccountFields}
      initialValues={selectedRow ?? chartOfAccountInitialValues}
      visibleFields={chartOfAccountVisibleColumns}
      formSubmitApiFunction={handleFormSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};
export default ChartOfAccountForm;
