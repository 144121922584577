import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-clinic-feedback';

export const getFeedbacks = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, {
    params: query,
  });
};

export const createFeedback = (facility_id: number, feedback: any) => {
  return axios.post(`${baseURL}/public/feedback/${facility_id}`, feedback);
};

export const getAverages = (facility_id: number) => {
  return axios.get(`${baseURL}/public/feedback/average/${facility_id}`);
};


