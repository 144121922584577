import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'medicine-instructions';

export const getMedicineInstructions = (medicine_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/many/${medicine_id}`, {
    params: query,
  });
};

export const getMedicineInstruction  = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createMedicineInstruction = (medicine_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${medicine_id}`, data);
};

export const updateMedicineInstruction = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteMedicineInstruction = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};