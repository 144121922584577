/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme } from '@mui/material';

import { CustomModal } from 'core/components';
import { SmsScheduleModel } from 'company/model/Entities';
import { formatDateTimeWithDay } from 'core/utils';
import { tokens } from 'theme/theme';

type ViewContentProps = {
  content: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  schedule?: SmsScheduleModel;
  onClose?: () => void;
};

const ViewContent: React.FC<ViewContentProps> = ({ content, open, setOpen, schedule, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <CustomModal header={'SMS Template Preview'} open={open} setOpen={setOpen} onClose={onClose}>
      {schedule && (
        <Box margin="0 1rem 2rem 1rem">
          <Box display="grid" gridTemplateColumns="190px 1fr" columnGap="10px" rowGap="5px" width="100%">
            <Typography
              variant="h5"
              paddingRight="5.5rem"
              sx={{ color: colors.accent, '@media (max-width: 600px)': { fontSize: '14px' } }}
            >
              Patient Name:
            </Typography>
            <Typography variant="h5" sx={{ '@media (max-width: 600px)': { fontSize: '14px' } }}>
              {schedule.full_name}
            </Typography>

            <Typography
              variant="h5"
              paddingRight="4.2rem"
              sx={{ color: colors.accent, '@media (max-width: 600px)': { fontSize: '14px' } }}
            >
              Mobile Number:
            </Typography>
            <Typography variant="h5" sx={{ '@media (max-width: 600px)': { fontSize: '14px' } }}>
              {schedule.mobile_number}
            </Typography>

            <Typography
              variant="h5"
              paddingRight="2rem"
              sx={{ color: colors.accent, '@media (max-width: 600px)': { fontSize: '14px' } }}
            >
              Sending Date & Time:
            </Typography>
            <Typography variant="h5" sx={{ '@media (max-width: 600px)': { fontSize: '14px' } }}>
              {formatDateTimeWithDay(schedule.send_date_time)}
            </Typography>
          </Box>
        </Box>
      )}
      <Box marginLeft="1rem" marginRight="1rem">
        <Typography variant="h5" sx={{ '@media (max-width: 600px)': { fontSize: '14px' } }}>
          {content}
        </Typography>
      </Box>
    </CustomModal>
  );
};

export default ViewContent;
