import { ConfirmationDialog, CustomContainer, CustomTable, Header, PrimaryButton, RowAction } from 'core/components';
import { deleteProgram, getPrograms } from 'carego-admin/api/program';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridColDef } from '@mui/x-data-grid';
import ProgramModal from './ProgramModal';
import { ProgramModel } from 'carego-admin/model/Entities';
import dayjs from 'dayjs';
import { format24HourTime } from 'core/utils';
import { useSnackbar } from 'notistack';

const HealthPrograms = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const [program, setProgram] = useState<ProgramModel>();

  //create program
  const [openProgramModal, setOpenProgramModal] = useState<boolean>(false);

  //update program
  const [toEdit, setToEdit] = useState<boolean>(false);
  const [updateInitialValues, setUpdateInitialValues] = useState<any>();

  //delete program
  const [openDeleteProgramDialog, setOpenDeleteProgramDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  const columns: GridColDef[] = [
    { field: 'program_name', headerName: 'Program Name', flex: 0.75 },
    { field: 'program_category', headerName: 'Program Category', flex: 1 },
    { field: 'program_code', headerName: 'Program Code', flex: 1 },
    {
      field: 'default_sending_time',
      headerName: 'Default Reminder Time',
      flex: 1,
      renderCell: (params) => format24HourTime(params.row.default_sending_time),
    },
    { field: 'frequency', headerName: 'Frequency', flex: 1 },
    { field: 'reminder_count', headerName: 'Reminder Count', headerAlign: 'right', align: 'right', flex: 0.75 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const getHealthPrograms = (query?: ApiQuery) => {
    setLoading(true);
    getPrograms({ page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const editProgram = (data: any) => {
    setToEdit(true);
    setProgram(data);

    const default_sending_time = dayjs(data.default_sending_time, 'HH:mm:ss').format('HH:mm');

    setUpdateInitialValues({
      program_name: data.program_name,
      program_code: data.program_code,
      program_category_code: data.program_category_code,
      reminder_count: data.reminder_count,
      frequency_type: data.frequency_type,
      template_code: data.template_code,
      default_sending_time: default_sending_time,
    });
    setOpenProgramModal(true);
  };

  const removeProgram = (data: any) => {
    showAlertDialog('Are you sure you want to delete ' + data.program_name + '?');
    setProgram(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenDeleteProgramDialog(true);
    setAlertContent(content);
  };

  const handleProgramDelete = () => {
    setLoading(true);
    deleteProgram(program!.id)
      .then((res: any) => {
        enqueueSnackbar('Program successfully deleted!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
    return;
  };

  const actions = [
    {
      label: 'Update Program',
      action: editProgram,
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Delete Program',
      action: removeProgram,
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
    },
  ];

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (openProgramModal === false) {
      setToEdit(false);
    }
  }, [openProgramModal]);

  useEffect(() => {
    setBreadcrumb([
      {
        label: 'Health Programs',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box marginTop="3rem">
      <Header title="Health Programs" />
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton
            onClick={() => {
              setOpenProgramModal(true);
            }}
            label="Create Program"
          />
        </Box>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getHealthPrograms}
          forceRefresh={forceRefresh}
        />
      </CustomContainer>

      <ProgramModal
        open={openProgramModal}
        setOpen={setOpenProgramModal}
        toEdit={toEdit}
        refreshTable={refreshTable}
        selectedProgram={program}
        updateInitialValues={updateInitialValues}
      />

      <ConfirmationDialog
        open={openDeleteProgramDialog}
        setOpen={setOpenDeleteProgramDialog}
        content={alertContent}
        onConfirm={handleProgramDelete}
      />
    </Box>
  );
};

export default HealthPrograms;
