import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';

import AccessibilitySharpIcon from '@mui/icons-material/AccessibilitySharp';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import CustomFilter from 'core/components/CustomFilter';
import { CustomTable } from 'core/components';
// ICONS
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MasksIcon from '@mui/icons-material/Masks';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import { tokens } from 'theme/theme';
import { useState } from 'react';

// ||||||||||||||||||||||||||||||||||||   MOCK DATA ONLY ||||||||||||||||||||||||||||||||||||||||||||||||||||//
const columns: GridColDef[] = [
  { field: 'accountName', headerName: 'Account Name', type: 'string', flex: 1, align: 'center', headerAlign: 'center' },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'principalDependent',
    headerName: 'Principal/Dependent',
    type: 'string',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  { field: 'receivedBy', headerName: 'Received By', type: 'string', flex: 1, align: 'center', headerAlign: 'center' },
  {
    field: 'dateReceived',
    headerName: 'Date Received',
    type: 'string',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'emailedEmployee',
    headerName: 'Emailed Employee',
    type: 'boolean',
    flex: 1,
    renderCell: (params) => (
      <div>
        <label>
          <input type="radio" name={`emailedEmployee-${params.id}`} value="yes" checked={params.value === 'yes'} />
          Yes
        </label>
        <label style={{ marginLeft: '10px' }}>
          <input type="radio" name={`emailedEmployee-${params.id}`} value="no" checked={params.value === 'no'} />
          No
        </label>
      </div>
    ),
  },
  {
    field: 'claimed',
    headerName: 'Claimed',
    type: 'singleSelect',
    flex: 1,
    valueOptions: ['Yes', 'No'],
    headerAlign: 'center',
    align: 'center',
  },
];

const rows: GridRowModel[] = [
  {
    id: 1,
    accountName: 'John Doe',
    accountNumber: 123123412,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 3,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 2,
    accountName: 'John Doe',
    accountNumber: 2,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 4,
    accountName: 'John Doe',
    accountNumber: 123123412,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },
  {
    id: 5,
    accountName: 'John Doe',
    accountNumber: 123123412,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },
  {
    id: 6,
    accountName: 'John Doe',
    accountNumber: 123123412,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },
  {
    id: 7,
    accountName: 'John Doe',
    accountNumber: 123123412,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 8,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },
  {
    id: 9,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 10,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 11,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 12,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 13,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },

  {
    id: 14,
    accountName: 'John Doe',
    accountNumber: 1,
    principalDependent: 'Jane Doe',
    receivedBy: 'Olive Yew',
    dateReceived: '12/24/2001',
    emailedEmployee: 'yes',
    claimed: 'Yes',
  },
];

export default function ClinicTracker() {
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [genderFilters, setGenderFilters] = useState<string[]>([]);
  const [multipleExportFilters, setmultipleExportFilters] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isNonMobile = useMediaQuery('(min-width:700px)');
  //   const isMediumSized = useMediaQuery('(min-width:)')

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems={'top'}>
        {/* {isNonMobile && <PageHeader title=" "  />} */}
        <Box
          minWidth="650px"
          display="flex"
          justifyContent="space-between"
          gap={'12px'}
          alignItems={'center'}
          marginBottom={'20px'}
          flexDirection={isNonMobile ? 'row' : 'column'}
        >
          <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} hideQuickFilter />
          <Box display="flex" flexDirection="row" gap="10px">
            <CustomFilter
              filterLabel="Filter by Sex"
              optionList={[
                { label: 'Male', id: 'male' },
                { label: 'Female', id: 'female' },
              ]}
              selected={genderFilters}
              setSelected={setGenderFilters}
            />

            <CustomFilter
              buttonLabel="Export"
              filterLabel="Multiple Export"
              selectAllOption={true}
              customIcon={<FileUploadIcon />}
              optionList={[
                { label: 'Top Diagnosis', id: 'top_diagnosis' },
                { label: 'Top Chief Complaint', id: 'top_chief_complaint' },
                { label: 'Top Medicine Dispensed', id: 'top_medicine_dispensed' },
                { label: 'Top Reasons for ER', id: 'top_reasons_for_er' },
                { label: 'Top Reasons for Sent Home', id: 'top_reasons_for_sent_home' },
                { label: 'Referrals to Specialist', id: 'referrals_to_specialist' },
              ]}
              selected={multipleExportFilters}
              setSelected={setmultipleExportFilters}
            />
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns={isNonMobile ? 'repeat(8, 1fr)' : 'repeat(2, 1fr)'}
        gap="20px"
        marginBottom={'20px'}
      >
        {/* ROW 1 */}
        <CustomDashboardWidget
          count={'59'}
          label="No. of patients who claimed HMO"
          iconBackgroundColor="#6999B3"
          icon={AccessibilitySharpIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#337497', height: '100px', alignItems: 'center' }}
        />
        <CustomDashboardWidget
          count={'6'}
          label="No. of patients who claimed HMO "
          iconBackgroundColor="#2496AD"
          icon={NightShelterIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#0085A0', height: '100px', alignItems: 'center' }}
        />
        <CustomDashboardWidget
          count={'0'}
          label="No. of patients who are sent home"
          iconBackgroundColor="#24A895"
          icon={MasksIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#00A283', height: '100px', alignItems: 'center' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of patients who are ER rush"
          iconBackgroundColor="#9EC66A"
          icon={MonitorHeartOutlinedIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#7CB236', height: '100px', alignItems: 'center' }}
        />
      </Box>

      {/* TABLE */}
      <Box
        sx={{
          borderRadius: '15px',
          border: '2px solid #E7E7E7',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.light_blue_background_2,
            marginBottom: '10px',
          },

          '$ .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-row': {
            backgroundColor: colors.light_blue_background_2,
            height: '50px',
            margin: '5px 0',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px',
            borderBottom: '2px solid  #E7E7E7',
          }}
        >
          <Typography sx={{ fontSize: '1.2rem', fontWeight: '500' }}>
            Clinic summary on patients who claimed HMO
          </Typography>

          <CustomFilter
            buttonLabel="Export"
            filterLabel="Multiple Export"
            selectAllOption={true}
            customIcon={<FileUploadIcon />}
            optionList={[{ label: 'Top Diagnosis', id: 'top_diagnosis' }]}
            selected={multipleExportFilters}
            setSelected={setmultipleExportFilters}
          />
        </Box>

        <CustomTable
          tableHeight="50vh"
          hideSearch
          hideViews
          columns={columns}
          rows={rows}
          rowCount={rows.length}
          rowId="id"
          getData={() => rows}
        />
      </Box>
    </>
  );
}
