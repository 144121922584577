import * as yup from 'yup';

const today = new Date();

export const create_user_schema = yup.object().shape({
  username: yup.string().required('Username is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  user_group_name: yup.string().required('User Group is required.'),
  region_code: yup.string().required('Region is required.'),
  province_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return !['RMTL', 'CHD', 'National'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
  municipality_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return ['MHO/PHN'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
});

export const update_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  user_group_name: yup.string().required('User Group is required.'),
  region_code: yup.string().required('Region is required.'),
  province_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return !['RMTL', 'CHD', 'National'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
  municipality_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return ['MHO/PHN'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
});

export const create_program_schema = yup.object().shape({
  program_name: yup.string().required('Program Name is required.'),
  program_category: yup.string().required('Category is required.'),
  sms_template: yup.string().required('SMS Template is required.'),
});

export const update_program_schema = yup.object().shape({
  program_name: yup.string().required('Program Name is required.'),
  program_category: yup.string().required('Category is required.'),
  sms_template: yup.string().required('SMS Template is required.'),
});

export const create_program_schedule_schema = yup.object().shape({
  schedule_name: yup.string().required('Schedule Name is required.'),
  venue: yup.string().required('Venue is required.'),
  program_date_time: yup
    .date()
    .min(today, 'Invalid Date')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date & Time'),
  barangay_code: yup.string().required('Barangay is required.'),
});

export const update_program_schedule_schema = yup.object().shape({
  schedule_name: yup.string().required('Schedule Name is required.'),
  venue: yup.string().required('Venue is required.'),
  program_date_time: yup
    .date()
    .min(today, 'Invalid Date')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('Invalid Date & Time'),
  barangay_code: yup.string().required('Barangay is required.'),
});

export const create_eod_receiver_schema = yup.object().shape({
  title: yup.string(),
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
});

export const update_eod_receiver_schema = yup.object().shape({
  title: yup.string(),
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
});

export const create_patient_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const create_patients_schema = yup.object().shape({
  firstName: yup.string().required('First Name is required.'),
  middleName: yup.string(),
  lastName: yup.string().required('Last Name is required.'),
  mobileNumber: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  //REMOVED THE BARANGAY CODE
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const update_patient_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const sms_reminder_schema = yup.object().shape({
  sms_sending_id: yup.string().required('Please choose sms sending option.'),
  send_date: yup
    .date()
    .default(() => today)
    .typeError('Invalid Date'),
  send_time: yup.string().required('Time is required.'),
});

export const family_planning_schema = yup.object().shape({
  date_started: yup.date().required('Date Started is required').typeError('Invalid Date'),
  sending_time: yup.string().required('SMS Sending Time is required.'),
});

export const update_family_planning_schema = yup.object().shape({
  date_started: yup.date().required('Date Started is required').typeError('Invalid Date'),
  sending_time: yup.string().required('SMS Sending Time is required.'),
  last_restock: yup.string(),
  new_cycle: yup.string(),
});

export const tb_dots_schema = yup.object().shape({
  tb_status: yup.string(),
  tb_refill_interval: yup
    .number()
    // .required('Refill Interval is required')
    // .min(1, 'SMS should not be 0.')
    .typeError('Invalid Input'),
  date_started: yup.date().required('Date Started is required').typeError('Invalid Date'),
  sending_time: yup.string().required('SMS Sending Time is required.'),
});

export const tb_dots_company_schema = yup.object().shape({
  tb_status: yup.string(),
  start_datetime: yup.date().required('Date Started is required').typeError('Invalid Date'),
  sending_time: yup.string().required('SMS Sending Time is required.'),
});

export const program_representative_schema = yup.object().shape({
  program_code: yup.string().required('Program is required.'),
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
});

export const update_staff_schema = yup.object().shape({
  // first_name: yup.string().required("First Name is required."),
  // middle_name: yup.string(),
  // last_name: yup.string().required("Last Name is required."),
  current_password: yup
    .string()
    .required('Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  new_password: yup
    .string()
    .required('New Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  confirm_new_password: yup
    .string()
    .required('Confirm Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8)
    .oneOf([yup.ref('new_password'), null], 'Password does not match.'),
});

export const request_purchase_schema = yup.object().shape({
  sms_credits: yup.number().required('SMS is required.').min(2000, 'SMS should not be 0.'),
});

export const create_staff_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  role: yup.string().required('Role is required'),
  password: yup
    .string()
    .required('Password is required')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  username: yup.string().required('Username is required.'),
});

export const tb_acf_enroll_schema = yup.object().shape({
  stage: yup.string().required('Stage is required'),
  screening_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
  sputum_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
});

export const update_screening_schedule_schema = yup.object().shape({
  screening_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
  screening_test_venue: yup.string().nullable(),
});

export const update_sputum_schedule_schema = yup.object().shape({
  sputum_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
  sputum_test_venue: yup.string().nullable(),
});

export const update_referred_hospital_schema = yup.object().shape({
  referred_hospital: yup.string().nullable(),
});

export const update_checkup_schema = yup.object().shape({
  check_up_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
  check_up_venue: yup.string().nullable(),
});

export const enroll_to_tbdots_schema = yup.object().shape({
  date_started: yup.date().nullable().required('Date is required.').typeError('Invalid Date'),
  sending_time: yup.string().nullable().required('Time is required'),
  tb_refill_interval: yup
    .number()
    // .required('Refill Interval is required')
    // .min(1, 'SMS should not be 0.')
    .typeError('Invalid Input'),
});

export const update_screening_result_schema = yup.object().shape({
  screening_result: yup.string().nullable().required('Result is required.'),
});

export const update_sputum_test_result_schema = yup.object().shape({
  sputum_test_result: yup.string().nullable().required('Result is required.'),
});

export const update_checkup_result_schema = yup.object().shape({
  checkup_test_result: yup.string().nullable().required('Result is required.'),
});

export const update_sputum_company_schema = yup.object().shape({
  id: yup.number(),
  start_datetime: yup.string().typeError('Invalid Date & Time'),
  tb_sputum_schedule1: yup.string().required('Value is required.'),
  tb_sputum_schedule2: yup.string().required('Value is required.'),
  tb_sputum_schedule3: yup.string().required('Value is required.'),
});

export const update_sputum_test_schedules_schema = yup.object().shape({
  sputum_test_schedule1: yup.string().required('Value is required.').typeError('Invalid Date & Time'),
  sputum_test_schedule2: yup.string().typeError('Invalid Date & Time'),
  sputum_test_schedule3: yup.string().typeError('Invalid Date & Time'),
});

export const create_message_template = yup.object().shape({
  template_name: yup.string().required('Template Name should not be empty.'),
  template_body: yup.string().required('Template Body should not be empty.'),
});

export const insert_variable_form = yup.object().shape({
  insert_variable: yup.string().nullable(),
});

export const create_bhw_schema = yup.object().shape({
  barangays: yup.string().nullable(),
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    }),
    // .required('Mobile Number is required.'),
  sex: yup.string().required('Sex is required.'),
});

export const update_bhw_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    }),
    // .required('Mobile Number is required.'),
  sex: yup.string().required('Sex is required.'),
});

export const update_bhw_assigment_schema = yup.object().shape({
  barangay_codes: yup.array().min(1, 'Choose at least one barangay or select multiple barangays.'),
  healthworker: yup.number().required('Health worker is required.'),
});
