import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { enrollPatient } from 'lgu/api/tb-acf';
import { tb_acf_enroll_schema } from 'lgu/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type TbAcfEnrollModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  programCode: string;
  selectedProgram: string;
  selectedPatientIDs: any[];
  selectedPatientID: number;
  type: 'create' | 'update';
  updateInitialValues?: UserInput;
  refreshNotEnrolledTable: () => void;
  refreshEnrolledTable: () => void;
};

export type UserInput = {
  stage: string;
  screening_test_schedule: string;
  screening_test_venue: string;
  sputum_test_schedule: string;
  sputum_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  stage: '',
  screening_test_schedule: '',
  screening_test_venue: '',
  sputum_test_schedule: '',
  sputum_test_venue: '',
};

const TbAcfEnrollModal: React.FC<TbAcfEnrollModalProps> = ({
  header,
  open,
  setOpen,
  selectedPatientIDs,
  selectedPatientID,
  updateInitialValues,
  refreshNotEnrolledTable,
  refreshEnrolledTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [hideScreening, setHideScreening] = useState<boolean>(true);
  const [hideSputum, setHideSputum] = useState<boolean>(true);

  const starting_stage = [
    { key: 'Chest X-ray', value: 'Screening' },
    { key: 'Sputum Test', value: 'Sputum Test' },
  ];

  const patientFields: UserFieldInput[] = [
    {
      field_name: 'stage',
      display_name: 'Starting Stage',
      type: 'select',
      options: starting_stage.map((option) => {
        return { key: option.key, value: option.value };
      }),
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('sputum_test_schedule', '');
        setFieldValue('sputum_test_venue', '');
        setFieldValue('screening_test_venue', '');
        setFieldValue('screening_test_schedule', '');
        if (value === 'Screening') {
          setHideSputum(true);
          setHideScreening(false);
        } else if (value === 'Sputum Test') {
          setHideSputum(false);
          setHideScreening(true);
        } else {
          setHideSputum(true);
          setHideScreening(true);
        }
      },
    },
    {
      field_name: 'screening_test_venue',
      display_name: 'Screening Test Venue (optional)',
      type: 'text',
      hidden: hideScreening,
    },
    {
      field_name: 'screening_test_schedule',
      display_name: 'Screening Test Schedule (optional)',
      type: 'datetime',
      minutesStep: 5,
      hidden: hideScreening,
    },
    { field_name: 'sputum_test_venue', display_name: 'Sputum Test Venue (optional)', type: 'text', hidden: hideSputum },
    {
      field_name: 'sputum_test_schedule',
      display_name: 'Sputum Test Schedule (optional)',
      type: 'datetime',
      minutesStep: 5,
      hidden: hideSputum,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const patientList = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);

    const patientListScreeningData = {
      patients: patientList,
      stage: data.stage,
      screening_test_schedule: data.screening_test_schedule,
      screening_test_venue: data.screening_test_venue,
    };

    const patientListSputumData = {
      patients: patientList,
      stage: data.stage,
      sputum_test_schedule: data.sputum_test_schedule,
      sputum_test_venue: data.sputum_test_venue,
    };

    const patientListEnrollData = {
      patients: patientList,
      stage: data.stage,
    };

    if (data.screening_test_schedule && data.screening_test_venue) {
      enrollPatient(patientListScreeningData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => setButtonLoading(false));
    } else if (data.sputum_test_schedule && data.sputum_test_venue) {
      enrollPatient(patientListSputumData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => setButtonLoading(false));
    } else {
      enrollPatient(patientListEnrollData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => setButtonLoading(false));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      refreshNotEnrolledTable();
      refreshEnrolledTable();
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  return (
    <>
      <CustomModal header={header} open={open} setOpen={setOpen}>
        <CustomForm
          initialValues={updateInitialValues ? updateInitialValues : initialValues}
          onSubmit={handleSubmit}
          fields={patientFields}
          schema={tb_acf_enroll_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default TbAcfEnrollModal;
