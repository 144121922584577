import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { capitalizeWord } from 'core/utils';

export const HA_ENTITY_NAME = 'Health Assessment';

export const healthAssessmentSchema = yup.object().shape({
  patient_id: yup.number().required('Patient is required.'),
});
export const healthAssessmentFields: EntityFields[] = [
  { fieldName: 'patient_id', displayName: 'Patient', type: DROPDOWN_FIELD.PATIENT },
  {
    fieldName: 'full_name',
    displayName: 'Patient Name',
    renderCell: (params) => (
      <Typography
        sx={{
          fontSize: '16px',
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/company/patient-management?patient_id=${params.row.patient_id}`}>
          {params.row.full_name}
        </Link>
      </Typography>
    ),
  },
  { fieldName: 'assessment_date', displayName: 'Asssessment Date', type: 'datetime' },
  {
    fieldName: 'status',
    displayName: 'Status',
    renderCell: (params) => <Typography>{capitalizeWord(params.row.status)}</Typography>,
  },
  {
    fieldName: 'batch_id',
    displayName: 'Batch',
    type: DROPDOWN_FIELD.HEALTH_ASSESSMENT_BATCH,
    span: 4,
    optional: true,
  },
];

export interface HealthAssessmentInput {
  patient_id: number | string;
  assessment_date: Date;
  status: string;
}

export const healthAssessmentInitialValues: HealthAssessmentInput = {
  patient_id: '',
  assessment_date: new Date(),
  status: 'pending',
};

export interface HealthAssessmentModel {
  id: number;
  company_id: number;
  patient_id: number;
  assessment_date: Date;
  status: string;
  findings?: string;
  recommendations?: string;
  class?: string;
}

// table
export const healthAssessmentColumnTable: string[] = ['full_name', 'assessment_date', 'status'];

// form
export const healthAssessmentFormFields: string[] = ['patient_id', 'assessment_date', 'batch_id'];
