import * as yup from 'yup';

import { EntityFields } from '../../../utils';

export const icdCodeSchema = yup.object().shape({
  code: yup.string().required('Code is required.'),
});


export interface ICDCodeModel {
  id: number;
  company_id: number;
  code: string;
}


export const ICD_CODE_ENTITY_NAME = 'ICD-10 Code';

export const icdCodeFields: EntityFields[] = [
  { fieldName: 'id', displayName: 'ID'},
  { fieldName: 'company_id', displayName: 'Company' },
  { fieldName: 'company_name', displayName: 'Company' },
  { fieldName: 'code', displayName: 'Code' },
  { fieldName: 'description', displayName: 'Description' },
];

export const icdCodeFormInitialValues = {
  code: ''
};


export const icdCodeTableColumns = ['code', 'description'];
export const icdCodeFormFields = [
  'code',
];

