import { AccordionDetails, Box, Typography, useTheme } from '@mui/material';
import { HideOrShowComponent, RegularButton } from 'core/components';
import {
  CORPORATE_CLIENT_STEPS,
  PATIENT_ADD_STEPS,
  SERVICE_ADD_STEPS,
  TOUR_ID,
} from 'core/screens/Helper/GuidedTour/steps';
import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { HelpOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { TourContext } from 'core/context/tour.context';
import { categories } from './categories';
import { isDevMode } from 'core/components/HideOrShowComponent';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

const HelpDesk = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { setTourState } = useContext(TourContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setBreadcrumb([{ label: 'Help Desk' }]);
  }, [setBreadcrumb]);

  const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: ${colors.primary};
    }
  `;

  return (
    <Box padding={3}>
      <TitleContainer>
        <HelpOutline fontSize="large" />
        <StyledTitle>Help Desk</StyledTitle>
      </TitleContainer>
      <HideOrShowComponent hidden={!isDevMode()}>
        <RegularButton
          onClick={() =>
            setTourState((prev: any) => {
              return {
                ...prev,
                steps: SERVICE_ADD_STEPS,
                tourId: TOUR_ID.SERVICE_ADD_STEPS,
                run: true,
                tourActive: true,
                stepIndex: 0,
              };
            })
          }
          label="How to Add Service?"
          styles={{ marginTop: 2 }}
          startIcon={<LiveHelpOutlinedIcon />}
        />
        <RegularButton
          onClick={() =>
            setTourState((prev: any) => {
              return {
                ...prev,
                steps: PATIENT_ADD_STEPS,
                tourId: TOUR_ID.PATIENT_ADD_STEPS,
                run: true,
                tourActive: true,
                stepIndex: 0,
              };
            })
          }
          label="How to Register Patient?"
          styles={{ marginTop: 2 }}
          startIcon={<LiveHelpOutlinedIcon />}
        />
        <RegularButton
          onClick={() =>
            setTourState((prev: any) => {
              return {
                ...prev,
                steps: CORPORATE_CLIENT_STEPS,
                tourId: TOUR_ID.CORPORATE_CLIENT_STEPS,
                run: true,
                tourActive: true,
                stepIndex: 0,
              };
            })
          }
          label="How to Add Corporate Client?"
          styles={{ marginTop: 2 }}
          startIcon={<LiveHelpOutlinedIcon />}
        />
      </HideOrShowComponent>

      <Box display="flex" flexDirection="column" gap="20px">
        {categories.map((category, index) => (
          <Box
            sx={{
              backgroundColor: colors.secondary_background,
              borderRadius: '10px',
              padding: '20px',
            }}
            key={index}
          >
            <StyledLink to={`${window.location.pathname}/${category.category_code}`}>
              <Typography variant="h4" fontWeight="bold" mb="10px">
                {category.category_name}
              </Typography>
            </StyledLink>

            <AccordionDetails>
              <Box display="flex" flexDirection="column" width="100%" gap="15px">
                {category.articles.map((article, articles_index) => (
                  <Box key={`${index}-${articles_index}`}>
                    <StyledLink to={`${category.category_code}/${article.article_code}`}>
                      <FiberManualRecordIcon color="primary" />
                      <Typography variant="h5" marginLeft="10px">
                        {article.article_title}
                      </Typography>
                    </StyledLink>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HelpDesk;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledTitle = styled(Typography)`
  font-size: 2em;
  font-weight: bold;
  margin-left: 10px;
`;
