import React, { useMemo } from 'react';
import { ColoredTable } from 'core/components';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { GridColDef } from '@mui/x-data-grid';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

interface ProviderScheduleProp {
  events: CompanyAppointmentModel[];
}

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

interface ProviderStatsRow {
  serviceProvider: string;
  Monday: number;
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
  rowColor?: string;
  bold?: boolean;
}

const ProviderScheduledTable: React.FC<ProviderScheduleProp> = ({ events }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const providerStats = useMemo<ProviderStatsRow[]>(() => {
    const stats: { [provider: string]: Record<DayOfWeek, number> } = {};

    events.forEach((event) => {
      if (event.status === 'pending' || event.status === 'rescheduled') {
        const serviceProvider = event.service_provider || 'Unassigned';
        const dayOfWeek = moment(event.schedule).format('dddd') as DayOfWeek;

        if (!stats[serviceProvider]) {
          stats[serviceProvider] = {
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0,
          };
        }

        stats[serviceProvider][dayOfWeek]++;
      }
    });

    return Object.entries(stats).map(([serviceProvider, data]) => ({
      serviceProvider,
      ...data,
    }));
  }, [events]);

  const totals = useMemo<ProviderStatsRow>(() => {
    return providerStats.reduce(
      (acc, curr) => {
        acc.Monday += curr.Monday;
        acc.Tuesday += curr.Tuesday;
        acc.Wednesday += curr.Wednesday;
        acc.Thursday += curr.Thursday;
        acc.Friday += curr.Friday;
        acc.Saturday += curr.Saturday;
        acc.Sunday += curr.Sunday;
        return acc;
      },
      {
        serviceProvider: 'Total',
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0,
        rowColor: colors.light_blue_background_2,
        bold: true,
      }
    );
  }, [colors.light_blue_background_2, providerStats]);

  const dataRows = [...providerStats, totals];

  const columns: GridColDef[] = [
    { field: 'serviceProvider', headerName: 'Service Provider', flex: 2, sortable: false },
    { field: 'Sunday', headerName: 'Sunday', flex: 1, sortable: false },
    { field: 'Monday', headerName: 'Monday', flex: 1, sortable: false },
    { field: 'Tuesday', headerName: 'Tuesday', flex: 1, sortable: false },
    { field: 'Wednesday', headerName: 'Wednesday', flex: 1, sortable: false },
    { field: 'Thursday', headerName: 'Thursday', flex: 1, sortable: false },
    { field: 'Friday', headerName: 'Friday', flex: 1, sortable: false },
    { field: 'Saturday', headerName: 'Saturday', flex: 1, sortable: false },
  ];

  return <ColoredTable columns={columns} dataRows={dataRows} rowHeight={50} />;
};

export default ProviderScheduledTable;
