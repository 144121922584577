/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ColoredTable, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteSupplier, getSuppliers } from 'company/api/suppliers';
import { formatCurrency, formatDate } from 'core/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerRow } from 'core/components/containers';
import CustomFilter from 'core/components/CustomFilter';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DeleteButton from 'core/components/buttons/DeleteButton';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import { SIZES } from 'theme/constants';
import { SupplierForm } from 'company/entities/forms';
import Transactions from './components/Transactions';
import UpdateButton from 'core/components/buttons/UpdateButton';
import { UserContext } from 'core/context/user.context';
import { getInventories } from 'company/api/inventories';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

interface Supplier {
  id: number;
  company_id: number;
  created_by: number;
  supplier_name: string;
  contact_person: string;
  contact_number: string;
  email: string;
  is_active: number;
  created_at: Date;
  updated_at: Date;
}

interface Product {
  id: number;
  product_id: number;
  variant_id: number;
  variant_name: string;
  variant_description: string;
  price: string;
  variant_price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: string;
  exclusive_supplier: number;
  exclusive_supplier_name: string;
  supplier_name: string;
  category: string;
  category_id: number;
  description: string;
  category_description: string;
  is_active: string;

  created_at: string;
}

const SupplierPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
  const [supplierSearchQuery, setSupplierSearchQuery] = useState<string>('');
  const [openSupplierModal, setOpenSupplierModal] = useState<boolean>(false);
  const [openDeleteSupplier, setOpenDeleteSupplier] = useState<boolean>(false);
  const [productFilters, setProductFilters] = useState<string[]>([]);

  const [refreshTransactions, setRefreshTransactions] = useState<boolean>(false);
  const [fetchingSuppliers, setFetchingSuppliers] = useState<boolean>(false);

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const tableRef: any = useRef();

  const refreshTable = () => {
    if (tableRef.current) {
      fetchSuppliers();
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  const handleDeleteSupplier = () => {
    if (selectedSupplier) {
      deleteSupplier(selectedSupplier.id)
        .then((res) => {
          enqueueSnackbar('Supplier successfully deleted!', { variant: 'success' });
          refreshTable();
        })
        .catch((error) => {
          enqueueSnackbar('Error occured! Unable to delete supplier.', { variant: 'error' });
        });
    }
  };

  const fetchSuppliers = () => {
    setFetchingSuppliers(true);
    if (facility) {
      return getSuppliers({}).then((res) => {
        const suppliers = res.data.data;
        setSuppliers(suppliers);
        setFetchingSuppliers(false);
      });
    }
  };

  const getAllProducts = () => {
    if (facility && selectedSupplier) {
      return getInventories(facility.id, {
        length: 1000,
        order_by: 'updated_at',
        order: 'desc',
        search: supplierSearchQuery,
      }).then((res) => {
        const newProducts = res.data.data;
        setProducts(newProducts);
      });
    }
  };

  const handleSelectRow = (selected: any) => {
    if (selected) {
      setSelectedSupplier(selected);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [facility, selectedSupplier]);

  useEffect(() => {
    fetchSuppliers();
  }, [facility]);

  return (
    <>
      <CareGoPage loading={!user}>
        {!fetchingSuppliers ? (
          <Box display="grid" gridTemplateColumns="1fr 350px" gap="30px">
            <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none' }}>
              <ColoredTable
                ref={tableRef}
                getData={getSuppliers}
                searchOnLeft
                handleSelectRow={handleSelectRow}
                headerComponent={
                  <ContainerRow gap="10px" sx={{ justifyContent: 'flex-end' }}>
                    <CustomFilter
                      optionList={[
                        { label: 'Available', id: 'available' },
                        { label: 'Low Stock', id: 'lowStock' },
                        { label: 'Unavailable', id: 'unavailable' },
                      ]}
                      buttonLabel="Status"
                      selected={productFilters}
                      setSelected={setProductFilters}
                    />
                    <RegularButton
                      startIcon={<AddIcon />}
                      label="Add Supplier"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setSelectedSupplier(undefined);
                        setOpenSupplierModal(true);
                      }}
                    />
                  </ContainerRow>
                }
                columns={[
                  {
                    field: 'supplier_name',
                    headerName: 'Supplier Name',
                    flex: 1.25,
                  },
                  {
                    field: 'contact_number',
                    headerName: 'Mobile No.',
                    flex: 0.75,
                  },
                  {
                    field: 'contact_person',
                    headerName: 'Contact Person',
                    flex: 1.5,
                  },
                  {
                    field: 'action',
                    sortable: false,
                    headerName: 'Actions',
                    headerAlign: 'center',
                    align: 'center',
                    flex: 1,
                    renderCell: (params) => (
                      <Box mr="50px" display="flex" gap="10px">
                        <UpdateButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSupplier(params.row);
                            setOpenSupplierModal(true);
                          }}
                        />
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSupplier(params.row);
                            setOpenDeleteSupplier(true);
                          }}
                        />
                      </Box>
                    ),
                  },
                ]}
                displayChildComponent={(row) => {
                  const filteredProducts = products.filter((product) => {
                    const quantityFilter =
                      +product.quantity_in_stock <= 0
                        ? 'unavailable'
                        : +product.quantity_in_stock >= 10
                        ? 'available'
                        : 'lowStock';
                    const idFilter = product.exclusive_supplier === row.id;
                    return (!productFilters.length || productFilters.includes(quantityFilter)) && idFilter;
                  });

                  return (
                    <Box
                      sx={{ overflowY: 'auto' }}
                      display="flex"
                      flexDirection="column"
                      gap={'20px'}
                      padding={SIZES.paddingS}
                      paddingRight={0}
                    >
                      {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                          <Grid
                            key={product.variant_id}
                            container
                            spacing={1}
                            sx={{
                              borderRadius: '10px',
                              padding: '20px 15px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              '&:hover': {
                                backgroundColor: colors.hoverTableRow,
                              },
                            }}
                          >
                            <Grid item xs={12} sm={6} md={6} lg={2.75}>
                              <Link
                                to={`/company/inventory/products/variant?id=${product.id}`}
                                style={{ textDecoration: 'none' }}
                                state={{ from: '/company/inventory/pos' }}
                              >
                                <Typography
                                  variant="h5"
                                  color={colors.accent}
                                  mb="5px"
                                  fontWeight="bold"
                                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                                >
                                  {product.product_name} ({product.variant_name})
                                </Typography>
                              </Link>
                              <Typography fontSize={'10px'} mb="3px" color={colors.grey_text}>
                                Category: {product.category}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              lg={1.5}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                SKU
                              </Typography>
                              <Typography>{product.sku}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              lg={1.5}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                Price
                              </Typography>
                              <Typography color={colors.accent} fontWeight="bold">
                                {formatCurrency(product.price)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={1}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              {parseInt(product.quantity_in_stock) <= 0 ? (
                                <Typography color={'#e74c3c'} fontSize={'10px'}>
                                  Unavailable
                                </Typography>
                              ) : parseInt(product.quantity_in_stock) > 0 &&
                                parseInt(product.quantity_in_stock) < 10 ? (
                                <Typography color={'#fe9801'} fontSize={'10px'}>
                                  Low Stock
                                </Typography>
                              ) : (
                                <Typography color={'#4CAF50'} fontSize={'10px'}>
                                  Available
                                </Typography>
                              )}
                              <Typography>{product.quantity_in_stock}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={1.5}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                Date Added
                              </Typography>
                              <Typography>{formatDate(product.created_at)}</Typography>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography variant="h6" color="textSecondary" align="center">
                          No products found.
                        </Typography>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>

            <Transactions refresh={refreshTransactions} suppliers={suppliers} />
          </Box>
        ) : (
          <Box>
            <CustomLoadingIndicator />
          </Box>
        )}
      </CareGoPage>

      <CustomModal
        header={selectedSupplier ? 'Update Supplier' : 'Add Supplier'}
        open={openSupplierModal}
        setOpen={setOpenSupplierModal}
        width={800}
      >
        <SupplierForm
          supplier={selectedSupplier}
          callbackAfterSubmit={() => {
            refreshTable();
            setOpenSupplierModal(false);
          }}
        />
      </CustomModal>

      {selectedSupplier && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedSupplier.supplier_name + '?'}
          open={openDeleteSupplier}
          setOpen={setOpenDeleteSupplier}
          onConfirm={handleDeleteSupplier}
        />
      )}
    </>
  );
};

export default SupplierPage;
