import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notifications';

export const getAllNotifications = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const markAsRead = (id:number) => {
  return axios.patch(`${baseURL}/${path}/${id}`)
}

export const clearNotifications = () => {
  return axios.patch(`${baseURL}/${path}/clear`);
}

// export const getNotification = (id:any) => {
//     return axios.get(`${baseURL}/${path}/{id}`);
// }