import { CustomContainer, CustomTable, Header } from 'core/components';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { getSmsCredits } from 'carego-admin/api/sms-credits';
import { smsCreditsColumns } from '../../utils/table-columns';
import { useState } from 'react';

const SmsCreditRequests = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getCredits = (query: ApiQuery) => {
    setLoading(true);
    getSmsCredits(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Box marginTop={'50px'}>
      <Header title="SMS Credits" />
      <CustomContainer>
        <CustomTable
          columns={smsCreditsColumns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getCredits}
        />
      </CustomContainer>
    </Box>
  );
};

export default SmsCreditRequests;
