import { CustomContainer, CustomGridCell, CustomTable } from 'core/components';
import { tb_acf_sms_counter, tb_dots_sputum_sms_counter } from 'lgu/data/select-options';

import { ApiQuery } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from 'core/utils';
import { getTbSMS } from 'lgu/api/sms';
import { useState } from 'react';

const SMS = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getSmsType = (row: any) => {
    if (row.program_code === 'tb_dots_pre_enrollment') {
      return tb_acf_sms_counter[row.counter - 1];
    }

    if (row.program_code === 'tb_dots') {
      return row.counter <= 300 ? 'TB-DOTS Daily Reminders' : tb_dots_sputum_sms_counter[row.counter - 301];
    }

    return '';
  };

  const smsColumns: GridColDef[] = [
    { field: 'full_name', headerName: 'Patient Name', flex: 1 },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'send_date_time',
      headerName: 'SMS Reminder Date',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.send_date_time)}</CustomGridCell>,
    },
    {
      field: 'counter',
      headerName: 'SMS Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getSmsType(params.row)}</CustomGridCell>,
    },
  ];

  const getTBSms = (query: ApiQuery) => {
    setLoading(true);
    getTbSMS(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      <CustomContainer>
        <CustomTable
          columns={smsColumns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getTBSms}
          rowHeight={35}
          showRefreshButton
        />
      </CustomContainer>
    </>
  );
};

export default SMS;
