import { HP_ENTITY_NAME, HealthPackageField, HealthPackageModel, healthPackageTableColumn } from './HealthPackageModel';
import { deleteHealthPackage, duplicateHealthPackage } from 'company/api/health-packages';
import { forwardRef, useRef, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HealthPackageForm from './HealthPackageForm';
import TemplateTable from '../../../Template/TemplateTable';
import { getHealthPackages } from 'company/api/health-packages';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow: (data: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const HealthPackageTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<HealthPackageModel>();
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const refreshCallback = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleDuplicate = (data: any) => {
    duplicateHealthPackage(data.id)
      .then(() => {
        mainRef.current.refreshTable();
        mainRef.current.closeFormModal();
      })
      .catch((error) => console.error(error));
  };

  return (
    <TemplateTable
      ref={mainRef}
      entityName={HP_ENTITY_NAME}
      fields={HealthPackageField}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      handleDelete={deleteHealthPackage}
      visibleColumns={visibleColumns ?? healthPackageTableColumn}
      templateForm={<HealthPackageForm healthPackage={selectedRow} callbackAfterSubmit={refreshCallback} />}
      getData={getHealthPackages}
      tableAction={{
        rowActions: [
          {
            label: 'Duplicate',
            action: handleDuplicate,
            icon: <ContentCopyIcon />,
          },
        ],
      }}
      tableComponent={{
        initialOrderBy: 'created_at',
        initialOrder: 'desc',
      }}
    />
  );
});

export default HealthPackageTable;
