import { CreateUserModel, PasswordModel } from 'lgu/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'users';

export const getUsers = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getUser = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createUser = (user: CreateUserModel) => {
  return axios.post(`${baseURL}/${path}`, user);
};

export const updateUser = (id: number, user: CreateUserModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, user);
};

export const deactivateUser = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updatePassword = (id: number, password: PasswordModel) => {
  return axios.patch(`${baseURL}/${path}/update-password/${id}`, password);
};
