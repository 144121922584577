import { CustomForm, CustomModal } from 'core/components';
import { getCities, getProvinces, getRegions } from 'carego-admin/api/location';
import { useEffect, useState } from 'react';

import { FieldInput } from 'core/model/interface';
import { addLgu } from 'carego-admin/api/lgu';
import { add_lgu_schema } from 'carego-admin/model/schema';
import { capitalizeWord } from 'core/utils';
import { useSnackbar } from 'notistack';

type AddLguProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  languages: any;
};

export type UserInput = {
  region_id: string | number;
  province_id: string | number;
  lgu_code: string;
  geo_code: string;
  office: string;
  language_id: string | number;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const AddLgu: React.FC<AddLguProps> = ({ open, setOpen, refreshTable, languages }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<any>();
  const [provinces, setProvinces] = useState<any>();
  const [cities, setCities] = useState<any>();

  const [regionCode, setRegionCode] = useState<any>();
  const [provinceCode, setProvinceCode] = useState<any>();

  const initialValues: UserInput = {
    region_id: '',
    province_id: '',
    lgu_code: '',
    geo_code: '',
    office: '',
    language_id: '',
  };

  const form_fields: UserFieldInput[] = [
    {
      field_name: 'region_id',
      display_name: 'Region',
      type: 'select',
      options:
        regions &&
        regions.map((u: any) => {
          return { key: u.name, value: u.id };
        }),
      onChange: (value: string) => {
        setRegionCode(value);
      },
    },
    {
      field_name: 'province_id',
      display_name: 'Province',
      type: 'select',
      options: regionCode
        ? provinces &&
          provinces
            .filter(function (province: any) {
              return province.region_code === regionCode;
            })
            .map(function (province: any) {
              return { key: province.name, value: province.id };
            })
        : provinces &&
          provinces.map((u: any) => {
            return { key: u.name, value: u.id };
          }),
      onChange: (value: string) => {
        setProvinceCode(value);
      },
    },
    {
      field_name: 'lgu_code',
      display_name: 'LGU',
      type: 'select',
      options: provinceCode
        ? cities &&
          cities
            .filter(function (city: any) {
              return city.province_code === provinceCode;
            })
            .map(function (city: any) {
              return { key: city.name, value: city.id };
            })
        : cities &&
          cities.map((u: any) => {
            return { key: u.name, value: u.id };
          }),
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('geo_code', value);
      },
    },
    { field_name: 'geo_code', display_name: 'Geographic Code', type: 'text', disabled: true },
    {
      field_name: 'office',
      display_name: 'Office',
      type: 'select',
      options: [
        { key: 'CHO', value: 'CHO' },
        { key: 'RHU', value: 'RHU' },
      ],
    },
    {
      field_name: 'language_id',
      display_name: 'Language',
      type: 'select',
      options: languages?.map((u: any) => {
        return { key: u.language, value: u.id };
      }),
    },
  ];

  const getName = (list: any, id: string) => {
    const location = list.find((u: { id: string }) => u.id === id);
    return location.name;
  };

  const add = (data: any) => {
    setLoading(true);

    data['lgu_name'] = capitalizeWord(getName(cities, data.geo_code));
    data['region_name'] = getName(regions, regionCode);
    data['province_name'] = capitalizeWord(getName(provinces, provinceCode));

    addLgu(data)
      .then((res) => {
        enqueueSnackbar('LGU successfully added!', { variant: 'success' });
        setLoading(false);
        refreshTable();
        setOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const getRegionList = () => {
    getRegions()
      .then((res) => {
        setRegions(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const getProvinceList = () => {
    getProvinces()
      .then((res) => {
        setProvinces(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const getCitiesList = () => {
    getCities()
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getRegionList();
    getProvinceList();
    getCitiesList();
  }, []);

  return (
    <CustomModal header={'Add LGU'} open={open} setOpen={setOpen}>
      <CustomForm
        onSubmit={add}
        fields={form_fields}
        schema={add_lgu_schema}
        initialValues={initialValues}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AddLgu;
