import AppLayout from './components/AppLayout';
import InventoryAppSidebar from './components/InventoryAppSidebar';
import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
};

const InventoryAppLayout: React.FC<LayoutProps> = ({ children }) => {
  return <AppLayout sidebar={<InventoryAppSidebar />}>{children}</AppLayout>;
};

export default InventoryAppLayout;
