/* eslint-disable react-hooks/exhaustive-deps */

import { BOOKING_ENTITY_NAME, BookingModel, bookingFields, bookingTableColumns } from './BookingModel';
import { cancelBooking, getBookings } from 'company/api/bookings';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import BookingForm from './BookingForm';
import CancelIcon from '@mui/icons-material/Cancel';
import { ConfirmationDialog } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import TemplateTable from '../../../Template/TemplateTable';
import { UserContext } from 'core/context/user.context';
import { useSnackbar } from 'notistack';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const BookingTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const [selectedRow, setSelectedRow] = useState<BookingModel>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [booking, setBooking] = useState<any>();

  const getData = (query: ApiQuery) => {
    return getBookings(1, query);
  };

  const handleBookingStatus = async () => {
    booking &&
      facility &&
      cancelBooking(facility.id, booking.id)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: 'success' });
        })
        .catch((error) => console.error(error));
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <>
      <TemplateTable
        ref={mainRef}
        entityName={BOOKING_ENTITY_NAME}
        fields={bookingFields}
        visibleColumns={visibleColumns ?? bookingTableColumns}
        getData={getData}
        handleDelete={() => {}}
        hideUpdateAction
        hideDeleteAction
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        tableAction={{
          getRow: setBooking,
          rowActions: [
            {
              label: 'Cancel',
              hidden: booking && booking.status === 'cancelled' && user?.authorities?.includes('MANAGE_APPOINTMENTS'),
              icon: <CancelIcon />,
              action: (data: any) => {
                setOpenConfirmationDialog(true);
                setAlertContent('Are you sure you want to cancel this booking?');
                setBooking(data);
              },
            },
          ],
        }}
        templateForm={
          facility ? (
            <BookingForm booking={selectedRow} facility={facility} callbackAfterSubmit={callbackAfterSubmit} />
          ) : (
            <></>
          )
        }
        processFormInitialValues={(data) => {
          data.options =
            data.options &&
            data.options.map((service: any) => {
              return { key: service.service_name, value: service.id };
            });
        }}
      />

      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        content={alertContent}
        onConfirm={handleBookingStatus}
      />
    </>
  );
});

export default BookingTable;
