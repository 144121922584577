import { Box, Checkbox, FormControlLabel, FormGroup, SxProps, Theme, Typography } from '@mui/material';
import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react';

import { SIZES } from 'theme/constants';
import { SelectOption } from 'core/model/interface';

type CustomCheckboxGroupProps = {
  options: SelectOption[];
  label: string;
  disabled?: boolean;
  value?: string[];
  fieldName: string;
  span?: number;
  optionsColumn?: number;
  handleChangeCallback?: (value: string[]) => void;
  margin?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  readOnly?: boolean;
  optional?: boolean;
  autoSave?: () => void;
  style?: CSSProperties;
  sx?: SxProps<Theme>;
};

const CustomCheckboxGroup: React.FC<CustomCheckboxGroupProps> = ({
  options,
  label,
  disabled,
  value,
  fieldName,
  span,
  optionsColumn = 2,
  handleChangeCallback,
  margin,
  setFieldValue,
  readOnly,
  optional,
  autoSave,
  style,
  sx,
}) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, option: string) => {
    if (!readOnly) {
      if (event.target.checked) {
        setChecked((prev) => [...prev, option]);
      } else {
        setChecked((prev) => prev.filter((item) => item !== option));
      }
    }
  };

  useEffect(() => {
    if (!firstLoad) {
      handleChangeCallback && handleChangeCallback(checked);
      setFieldValue && setFieldValue(fieldName, checked);
      autoSave && autoSave();
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (value && firstLoad) setChecked(value);
  }, [value, firstLoad]);

  return (
    <Box>
      <Typography mb="10px">
        {label} {optional ? ' (optional)' : ''}
      </Typography>
      <FormGroup
        sx={{
          gridColumn: span ? 'span ' + span : undefined,
          justifyContent: 'center',
          alignContent: 'center',
          display: 'grid',
          gridTemplateColumns: `repeat(${optionsColumn}, 1fr)`,
          paddingLeft: SIZES.paddingS,
          '& .MuiCheckbox-root': { padding: '5px' },
          ...style,
        }}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            sx={{ '& .MuiCheckbox-root': { padding: '0px 9px' } }}
            control={
              <Checkbox
                disabled={disabled}
                checked={checked.includes(option.value as string)}
                onChange={(e) => handleCheckboxChange(e, option.value as string)}
                name={fieldName}
              />
            }
            label={option.key}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default CustomCheckboxGroup;
