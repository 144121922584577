import { CustomContainer, CustomTable, Header } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import ViewContent from 'core/components/modals/ViewContent';
import { getCommonSMSTemplates } from 'lgu/api/sms';
import { useState } from 'react';

const CommonTemplates = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>();
  const [openSmsPreview, setOpenSmsPreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [clearSelected, setClearSelected] = useState(0);

  const columns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 1 },
    { field: 'template_body', headerName: 'Message Template', flex: 3 },
  ];

  const getTemplates = (query: ApiQuery) => {
    setLoading(true);

    getCommonSMSTemplates(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const viewTemplateBody = async (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const selectedTemplate = rows.find((localizedTemplate) => localizedTemplate.id === selected[0]);
      setSelectedRow(selectedTemplate);
      setPreviewContent(selectedTemplate.template_body);
      setOpenSmsPreview(true);
    } else {
      setSelectedRow(undefined);
    }
  };

  return (
    <CustomContainer>
      <Box display="block" width="100%">
        <Header title="Message Templates" />
        <CustomTable
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowId={'id'}
          getData={getTemplates}
          loading={loading}
          selectableRow
          handleSelectRow={(data: any) => viewTemplateBody(data)}
          clearSelected={clearSelected}
        />
      </Box>

      <ViewContent
        content={previewContent}
        open={openSmsPreview}
        setOpen={setOpenSmsPreview}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      />
    </CustomContainer>
  );
};

export default CommonTemplates;
