/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import BiotechIcon from '@mui/icons-material/Biotech';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CustomTabs } from 'core/components';
import MedicationIcon from '@mui/icons-material/Medication';
import SMS from './SMS';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { TabProps } from 'core/components/CustomTabs';
import TbAcf from './TbAcf';
import TbDots from './TbDots';

const TB = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      icon: <BiotechIcon />,
      label: 'TB ACF',
      content: <TbAcf refresh={refresh} setRefresh={setRefresh} />,
    },
    {
      icon: <MedicationIcon />,
      label: 'TB-DOTS',
      content: <TbDots refresh={refresh} setRefresh={setRefresh} />,
    },
    {
      icon: <SmsOutlinedIcon />,
      label: 'SMS',
      content: <SMS />,
    },
  ];

  // useEffect(() => {
  //   setRefresh((refresh) => refresh + 1);
  // }, [selected]);

  useEffect(() => {
    setBreadcrumb([{ label: 'TB-DOTS' }]);
  }, []);

  return (
    <Box>
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default TB;
