import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { formatNumber, shuffle } from 'core/utils';

import { PieChart } from '@mui/x-charts/PieChart';
import { chart_colors } from 'company/screens/PatientRecords/components/ChartEditor';
import { tokens } from 'theme/theme';

export interface PieChartData {
  value: number;
  label: string;
}

interface Props {
  processData: (setPieChartData: (data: PieChartData[]) => void) => void;
  decimalPlaces?: number;
  title: string;
}

const DashboardPieChart: React.FC<Props> = ({ title, processData, decimalPlaces = 2 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);
  const [chartSize, setChartSize] = useState({ width: 300, height: 300 }); // Default size
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const chartColors = useMemo(() => shuffle(chart_colors), []);

  const updateChartSize = useCallback(() => {
    const container = document.getElementById('pie-chart-container');
    if (container) {
      setChartSize({
        width: container.clientWidth || 300, // Fallback default size
        height: container.clientHeight - 40 || 300, // Subtracting header height, fallback size
      });
    }
  }, []);

  // Use useLayoutEffect for initial size update after DOM renders
  useLayoutEffect(() => {
    updateChartSize();
    window.addEventListener('resize', updateChartSize);
    return () => window.removeEventListener('resize', updateChartSize);
  }, [updateChartSize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      processData(setPieChartData);
    }, 2000);
    return () => clearTimeout(timer);
  }, [processData]);

  if (!pieChartData?.length) {
    return null; // Or return a loading state
  }

  const chartInnerRadius = Math.min(chartSize.width, chartSize.height) * 0.1;
  const chartOuterRadius = Math.min(chartSize.width, chartSize.height) * 0.35;

  return (
    <Box
      id="pie-chart-container"
      sx={{
        gridColumn: isMobilePhone ? 'span 12' : 'span 6',
        border: `1px solid ${colors.border}`,
        borderRadius: '8px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '12px 24px',
      }}
    >
      <Box
        sx={{
          border: 'none',
          borderBottom: '1px solid #CCCCCC',
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
          <Typography variant="h3" fontWeight="700">
            {title}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: isMobilePhone ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '4px',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{ flex: 1, minWidth: 0, minHeight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <PieChart
            colors={chartColors}
            series={[
              {
                data: pieChartData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: chartInnerRadius, additionalRadius: -10, color: 'gray' },
                innerRadius: chartInnerRadius,
                outerRadius: chartOuterRadius,
                // paddingAngle: 2,
                cornerRadius: 5,
              },
            ]}
            slotProps={{
              legend: { hidden: true },
            }}
            width={chartSize.width}
            height={chartSize.height}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: isMobilePhone ? '100px' : '100%',
            overflowY: 'auto',
            padding: '0 8px',
            flexShrink: 0,
            width: isMobilePhone ? '100%' : '30%',
          }}
        >
          {pieChartData.map((entry, index) => (
            <Box key={`item-${index}`} display="flex" alignItems="center" mb={0.5}>
              <Box width={12} height={12} bgcolor={chartColors[index]} mr={1} flexShrink={0} />
              <Typography fontSize="11px" noWrap>
                {`${entry.label} - ${formatNumber(entry.value, decimalPlaces)}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPieChart;
