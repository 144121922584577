/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Chip, Typography, useTheme } from '@mui/material';
import { CustomContainer, Header, ProtectedComponent } from 'core/components';
import { capitalizeWord, formatDateTime } from 'core/utils';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppointmentSettings from '../components/AppointmentSettings';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CompanyAppointmentModel } from 'company/model/Entities';
import { FacilityContext } from 'core/context/facility.context';
import { LoadingScreen } from 'core/screens';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PatientNotesContainer from 'company/screens/PatientManagement/components/BasicHealthInfo/Sections/PatientNotesContainer';
import { getAppointment } from 'company/api/appointments';
import { getColorByStatus } from 'company/components/util';
import { getPatient } from 'company/api/patient';

const AppointmentDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { id } = useParams() as any;
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);

  const [appointment, setAppointment] = useState<CompanyAppointmentModel>();
  const [patient, setPatient] = useState<PatientModel>();

  const updateAppointmentData = async () => {
    const updatedAppointment = await getAppointment(facility.id, appointment!.id);
    setAppointment(updatedAppointment.data);
  };

  useEffect(() => {
    if (facility) {
      getAppointment(facility.id, id)
        .then((res) => {
          setAppointment(res.data);
          getPatient(facility.id, res.data.patient_id).then((patientRes) => {
            setPatient(patientRes.data);
          });
        })
        .catch(() => {
          navigate('/company/not-found');
        });
    }
  }, [id, facility]);

  useEffect(() => {
    if (appointment) {
      setBreadcrumb([
        { label: 'Appointments', link: '/company/appointments' },
        { label: appointment.full_name, link: `/company/patient-management?patient_id=${appointment.patient_id}` },
      ]);
    } else {
      setBreadcrumb([]);
    }
  }, [appointment]);

  return (
    <Box>
      <LoadingScreen loading={!appointment}>
        <Box>
          <Box display="flex" flexDirection="column" gap="20px" mb="20px">
            <CustomContainer>
              <Box width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Header title={`${appointment?.full_name} - ${appointment?.service_names}`} />
                  {facility && !facility.is_admin && (
                    <ProtectedComponent
                      requiredAuth={['MANAGE_APPOINTMENTS']}
                      requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                    >
                      <Box>
                        <AppointmentSettings
                          appointment={appointment!}
                          updateAppointmentData={updateAppointmentData}
                          showLabel
                        />
                      </Box>
                    </ProtectedComponent>
                  )}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="100px 1fr 100px 1fr"
                  columnGap="20px"
                  alignItems="center"
                  rowGap="5px"
                >
                  <Typography>Schedule</Typography>
                  <Typography>{formatDateTime(appointment?.schedule)}</Typography>

                  <Typography>Status</Typography>
                  <Box>
                    {appointment && (
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          appointment.status === 'done'
                            ? 'Attended'
                            : capitalizeWord(appointment.status) &&
                              (appointment.status === 'pending' ? (
                                <Typography variant="inherit">Confirmed</Typography>
                              ) : (
                                capitalizeWord(appointment.status)
                              ))
                        }
                        sx={{
                          borderColor: getColorByStatus(appointment.status, theme.palette.mode),
                          color: getColorByStatus(appointment.status, theme.palette.mode),
                          fontSize: 12,
                          padding: '1px',
                          borderWidth: '2px',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </CustomContainer>
          </Box>
          <PatientNotesContainer patient={patient!} appointmentId={parseInt(id)} />
        </Box>
      </LoadingScreen>
    </Box>
  );
};

export default AppointmentDetails;
