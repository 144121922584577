import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getHMO, getHMOs } from 'company/api/company-hmos';

import { ApiQuery } from 'core/model/interface';

const HMOSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'HMO'}
      getData={(query: ApiQuery) => getHMOs(query)}
      getById={(id: number) => getHMO(id)}
      processOption={(record) => ({ key: record.hmo_name, value: record.id })}
      processNewRecord={(record) => record.hmo}
    />
  );
};

export default HMOSearchDropdown;
