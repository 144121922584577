import { CLINIC_ENTITY_NAME, ClinicModel, clinicFields, clinicTableColumns } from './ClinicModel';
import { forwardRef, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import ClinicForm from './ClinicForm';
import TemplateTable from '../../../Template/TemplateTable';
import { getCompanyFacilities } from 'company/api/facility';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const ClinicTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const [selectedRow, setSelectedRow] = useState<ClinicModel>();

  const getData = (query: ApiQuery) => {
    return getCompanyFacilities(query);
  };

  const handleDelete = (query: ApiQuery) => {
    return;
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };
  return (
    <TemplateTable
      tableComponent={{
        mobileViewConfig: () => ({
          title_fields: ['facility_name'],
          subtitle_fields: ['facility_code'],
        }),
        rowHeight: 70,
      }}
      ref={mainRef}
      entityName={CLINIC_ENTITY_NAME}
      fields={clinicFields}
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      visibleColumns={visibleColumns ?? clinicTableColumns}
      hideAddButton
      templateForm={<ClinicForm clinic={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
    />
  );
});

export default ClinicTable;
