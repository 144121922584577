import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PAYMENT_ENTITY_NAME, paymentFields, paymentTableColumns } from './PaymentModel';
import { formatCurrency, formatDate, formatPaymentMethodLabel } from 'core/utils';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { getPayments, getPaymentsByInvoiceId } from 'company/api/billing-payment';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/system';
import { CompanyServiceModel } from 'company/model/Entities';
import { FacilityContext } from 'core/context/facility.context';
import { PatientBillingModel } from '../PatientBilling/PatientBillingModel';
import PaymentForm from './PaymentForm';
import TemplateTable from '../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';
import { tokens } from 'theme/theme';

type Props = {
  invoice?: PatientBillingModel;
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const PaymentTable = forwardRef((props: Props, ref) => {
  const { invoice, visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const { facility } = useContext(FacilityContext);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const [paymentsByInvoiceId, setPaymentsByInvoiceId] = useState<any>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');
  const getData = (query: ApiQuery) => {
    if (invoice) {
      return getPaymentsByInvoiceId(facility.id, invoice.id, { ...query, length: 1000 });
    }
    return getPayments(facility.id, query);
  };

  useEffect(() => {
    async function getData(query: ApiQuery) {
      if (invoice) {
        const res = await getPaymentsByInvoiceId(facility.id, invoice.id, { ...query, length: 1000 });
        setPaymentsByInvoiceId(res.data.data);
      }
    }
    getData({});
  }, [invoice]);

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  // useEffect(() => {
  //   if (facility && invoice) mainRef.current.refreshTable();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invoice]);
  if (isMobile && paymentsByInvoiceId)
    return (
      <Box sx={{ display: 'flex', gap: '10px', padding: '10px', flexDirection: 'column', mt: '10px' }}>
        {paymentsByInvoiceId.map((payment: any) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                    {formatDate(payment.payment_date)}
                  </Typography>
                  <Typography>{formatPaymentMethodLabel(payment.payment_method)}</Typography>
                </Box>
                <Typography sx={{ fontSize: '1rem', fontWeight: '600', color: colors.primary }}>
                  {formatCurrency(payment.amount)}
                </Typography>
              </Box>
              <Divider />
            </>
          );
        })}
      </Box>
    );

  return (
    <WaitForFacility facility={!!facility && !!invoice}>
      <TemplateTable
        ref={mainRef}
        entityName={PAYMENT_ENTITY_NAME}
        fields={paymentFields}
        visibleColumns={visibleColumns ?? paymentTableColumns}
        templateForm={
          <PaymentForm payment={selectedRow} invoice={invoice!} callbackAfterSubmit={callbackAfterSubmit} />
        }
        hideAddUpdateDeleteAction
        hideSearch
        hidePagination
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        tableComponent={{
          initialOrder: 'desc',
          initialOrderBy: 'payment_date',
        }}
      />
    </WaitForFacility>
  );
});

export default PaymentTable;
