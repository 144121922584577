import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Typography } from '@mui/material';
import { downloadFromAxios } from 'core/utils';

interface Props {
  filename: string;
  bytes: string;
  downloadLink: string;
  onDelete: () => void;
}

const ArchiveCard = ({ bytes, downloadLink, filename, onDelete }: Props) => {
  return (
    <Card sx={{ minWidth: '20em', maxWidth: '20em' }}>
      <CardHeader
        action={
          <>
            <IconButton title="Delete Archive" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              title="Download"
              onClick={() => {
                downloadFromAxios(filename, downloadLink);
              }}
            >
              <SystemUpdateAltIcon />
            </IconButton>
          </>
        }
        title={
          <Typography title={filename}>{filename.length > 20 ? filename.slice(0, 16) + '...' : filename}</Typography>
        }
        subheader={<div title={bytes + ' Bytes'}>{bytes.length > 15 ? bytes.slice(0, 16) + '...' : bytes} Bytes</div>}
      />
    </Card>
  );
};

export default ArchiveCard;
