/* eslint-disable react-hooks/exhaustive-deps */

import { PATIENT_ENTITY_NAME, patientFields, patientFilesTableColumns } from './PatientModel';
import { deleteFile, getFiles } from 'company/api/file-upload';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { FileUpload } from 'core/components';
import { SIZES } from 'theme/constants';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { TbAcfCompanyPatientModel } from 'company/model/Entities';
import TemplateTable from '../../../Template/TemplateTable';
import { useLocation } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  hideSearch?: boolean;
  patientId: number;
};

const PatientFilesTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction, hideSearch, patientId } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<TbAcfCompanyPatientModel>();
  const location = useLocation();

  const getData = (query: ApiQuery) => {
    return getFiles(facility.id, patientId, query);
  };

  const handleDelete = (data: any) => {
    return deleteFile(facility!.id, data);
  };

  useEffect(() => mainRef.current.refreshTable(), [patientId, location]);

  return (
    <ContainerColumn gap={SIZES.paddingS}>
      <FileUpload
        uploadOnly
        source="notes-table"
        patientId={patientId}
        refreshFilesTable={() => mainRef.current.refreshTable()}
      />
      <TemplateTable
        hideAddButton
        hideUpdateAction
        hideSearch={hideSearch}
        ref={mainRef}
        tourTriggerIndex={3}
        entityName={PATIENT_ENTITY_NAME}
        fields={patientFields}
        visibleColumns={visibleColumns ?? patientFilesTableColumns}
        getData={getData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        tableAction={tableAction}
        fileViewer
        onFilesRefresh={() => mainRef.current.refreshTable()}
        tableComponent={{
          stickyTop: 95,
          onFilesRefresh: () => mainRef.current.refreshTable(),
          mobileViewConfig: (data) => ({
            title_fields: ['file_name'],
            subtitle_fields: ['file_size'],
          }),
        }}
      />
    </ContainerColumn>
  );
});

export default PatientFilesTable;
