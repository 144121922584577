import { CustomForm, CustomModal } from 'core/components';
import { useContext, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { ProgramPatientModel } from 'company/model/Entities';
import { enroll } from 'company/api/tb-acf';
import { tb_acf_enroll_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type TbAcfEnrollModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  programCode: string;
  selectedPatient?: ProgramPatientModel;
  selectedPatients?: ProgramPatientModel[];
  updateInitialValues?: UserInput;
  refreshTable: () => void;
  setRefreshUnenrolled: any;
  type: 'create' | 'update';
};

export type UserInput = {
  stage: string;
  screening_test_schedule: string;
  screening_test_venue: string;
  sputum_test_schedule: string;
  sputum_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  stage: '',
  screening_test_schedule: '',
  screening_test_venue: '',
  sputum_test_schedule: '',
  sputum_test_venue: '',
};

const TbAcfEnrollModal: React.FC<TbAcfEnrollModalProps> = ({
  header,
  open,
  setOpen,
  programCode,
  selectedPatient,
  updateInitialValues,
  refreshTable,
  setRefreshUnenrolled,
  type,
  selectedPatients,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [hideScreening, setHideScreening] = useState<boolean>(true);
  const [hideSputum, setHideSputum] = useState<boolean>(true);

  const starting_stage = [
    { key: 'Chest X-ray', value: 'Screening' },
    { key: 'Sputum Test', value: 'Sputum Test' },
  ];

  const patientFields: UserFieldInput[] = [
    {
      field_name: 'stage',
      display_name: 'Starting Stage',
      type: 'select',
      options: starting_stage.map((option) => {
        return { key: option.key, value: option.value };
      }),
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('sputum_test_schedule', '');
        setFieldValue('sputum_test_venue', '');
        setFieldValue('screening_test_venue', '');
        setFieldValue('screening_test_schedule', '');
        if (value === 'Screening') {
          setHideSputum(true);
          setHideScreening(false);
        } else if (value === 'Sputum Test') {
          setHideSputum(false);
          setHideScreening(true);
        } else {
          setHideSputum(true);
          setHideScreening(true);
        }
      },
    },
    {
      field_name: 'screening_test_venue',
      display_name: 'Screening Test Venue (optional)',
      type: 'text',
      hidden: hideScreening,
    },
    {
      field_name: 'screening_test_schedule',
      display_name: 'Screening Test Schedule (optional)',
      type: 'datetime',
      minutesStep: 5,
      hidden: hideScreening,
    },
    { field_name: 'sputum_test_venue', display_name: 'Sputum Test Venue (optional)', type: 'text', hidden: hideSputum },
    {
      field_name: 'sputum_test_schedule',
      display_name: 'Sputum Test Schedule (optional)',
      type: 'datetime',
      minutesStep: 5,
      hidden: hideSputum,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if ((selectedPatients && selectedPatients.length > 0) || selectedPatient) {
      const patients = JSON.stringify(
        selectedPatients && selectedPatients.length > 0 ? selectedPatients : [selectedPatient?.patient_id]
      );

      const patientListScreeningData = {
        patients: patients,
        stage: data.stage,
        screening_test_schedule: data.screening_test_schedule,
        screening_test_venue: data.screening_test_venue,
      };

      const patientListSputumData = {
        patients: patients,
        stage: data.stage,
        sputum_test_schedule: data.sputum_test_schedule,
        sputum_test_venue: data.sputum_test_venue,
      };

      const patientListEnrollData = {
        patients: patients,
        stage: data.stage,
      };

      if (data.screening_test_schedule && data.screening_test_venue) {
        enroll(facility.id, patientListScreeningData)
          .then((res: any) => {
            successFormSubmit(res, formikHelpers);
          })
          .catch((error) => setButtonLoading(false));
      } else if (data.sputum_test_schedule && data.sputum_test_venue) {
        enroll(facility.id, patientListSputumData)
          .then((res: any) => {
            successFormSubmit(res, formikHelpers);
          })
          .catch((error) => setButtonLoading(false));
      } else {
        enroll(facility.id, patientListEnrollData)
          .then((res: any) => {
            successFormSubmit(res, formikHelpers);
          })
          .catch((error) => setButtonLoading(false));
      }
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
    setRefreshUnenrolled((prev: number) => prev + 1);
  };

  return (
    <>
      <CustomModal
        header={`${header} ${
          selectedPatients && selectedPatients.length > 0
            ? selectedPatients?.length + ' Patients'
            : selectedPatient?.full_name
        }`}
        open={open}
        setOpen={setOpen}
      >
        <CustomForm
          initialValues={updateInitialValues ? updateInitialValues : initialValues}
          onSubmit={handleSubmit}
          fields={patientFields}
          schema={tb_acf_enroll_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default TbAcfEnrollModal;
