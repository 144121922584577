import { Box, Typography } from '@mui/material';
import { Ref, forwardRef } from 'react';
import { formatDate, formatGenLedgerDate, formatNumber } from 'core/utils';

import styled from 'styled-components';

const LIMIT_ROW_PER_PAGE = 27;

interface LedgerReportData {
  id: number;
  transaction_type_code: string;
  reference_id: string | number;
  description: string;
  amount: number | string;
  account_id: number;
  account_name: string;
  account_type_code: string;
  account_type: string;
  entry_type: string;
  created_at?: string;
}

type JournalEntriesProps = {
  reportData: LedgerReportData[];
  startDate: Date;
  endDate: Date;
};

/////////////////////////
//  Styled components  //
/////////////////////////
const Th = styled.th`
  padding-left: 0.5vh;
  text-align: left;
`;

const Td = styled.td`
  text-align: left;
  padding-left: 0.5vh;
`;

const PageBreaker = styled('div')`
  page-break-after: always;
`;

const JournalEntriesBody = (props: { data: LedgerReportData }) => {
  const { data } = props;
  return (
    <tr>
      <Td>{data.id}</Td>
      <Td>{formatDate(data.created_at)}</Td>
      <Td>{data.transaction_type_code}</Td>
      <Td>{data.reference_id}</Td>
      <Td>Customer x</Td>
      <Td>-</Td>
      <Td>-</Td>
      <Td>{data.account_name}</Td>
      <Td>{data.entry_type === 'debit' ? '₱' + formatNumber(data.amount) : '-'}</Td>
      <Td>{data.entry_type === 'credit' ? '₱' + formatNumber(data.amount) : '-'}</Td>
    </tr>
  );
};

const JournalEntriesHeader = () => {
  return (
    <thead style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
      <Th>TRANSACTION ID</Th>
      <Th>DATE</Th>
      <Th>TRANSACTION TYPE</Th>
      <Th>NUMBER</Th>
      <Th>NAME</Th>
      <Th>MEMO/DESCRIPTION</Th>
      <Th>ACCOUNT NO.</Th>
      <Th>FULL NAME</Th>
      <Th>DEBIT</Th>
      <Th>CREDIT</Th>
    </thead>
  );
};

const JournalEntries = forwardRef((props: JournalEntriesProps, ref: Ref<HTMLDivElement>) => {
  const { reportData, startDate, endDate } = props;

  function renderContents(localData: LedgerReportData[]) {
    const contents = [];
    if (localData.length === 0) return [];

    let currentRowSize = 0;
    let firstPage = true;

    for (let i = 0; i < localData.length; i++) {
      contents.push(<JournalEntriesBody key={i} data={localData[i]} />);
      currentRowSize += 1;

      if (currentRowSize >= (firstPage ? LIMIT_ROW_PER_PAGE - 5 : LIMIT_ROW_PER_PAGE)) {
        contents.push(<PageBreaker />);
        currentRowSize = 0;
        firstPage = false;
      }
    }

    return contents;
  }

  return (
    <div ref={ref} style={{ padding: '0.5in' }}>
      <style>{styles}</style>

      <div style={{ textAlign: 'center', width: '100%' }}>
        <Typography variant="h4" fontWeight={'bold'} align="center">
          PICH
        </Typography>
        <Typography variant="h5" align="center">
          Journal
        </Typography>
        <Typography variant="h5" align="center">
          {formatGenLedgerDate(startDate, endDate)}
        </Typography>
      </div>

      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <JournalEntriesHeader />
        <tbody>
          {/* {reportData.map((data, index) => (
            <JournalEntriesBody key={index} data={data} />
          ))} */}
          {renderContents(reportData)}
        </tbody>
      </table>

      {/* footer */}
      <div className="footer" style={fixedFooterStyle}>
        <Box display={'flex'} alignItems={'center'}>
          <img
            alt="cphi-logo"
            width="120px"
            height="120px"
            id="logo"
            src={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'}
          />
        </Box>
      </div>
    </div>
  );
});

export default JournalEntries;

const fixedFooterStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '10px',
  textAlign: 'center',
  pageBreakBefore: 'always',
};

const styles = `
  @page {
    size: landscape;
    margin: 1cm;
  }
`;
