import * as Yup from 'yup';

import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomInlineForm, FileUpload, RegularButton } from 'core/components';
import { DISPLAY_ONLY_FIELDS, FieldInput } from 'core/model/interface';
import {
  addPatientNoteSummaryFields,
  getPatientNotesSummaryTemplateFields,
  removeFieldFromNoteSummary,
  reorderSummaryTemplateFields,
} from 'company/api/patient-notes-summary-template-fields';
import {
  getSinglePatientNoteSummary,
  updateTemplateFile,
  updateTemplateFileStartRow,
} from 'company/api/patient-note-summary-template';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ContainerRow } from 'core/components/containers';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { PatientNoteSummaryTemplateModel } from 'company/entities/modules/ClinicManagement/PatientNoteSummaryTemplate/PatientNoteSummaryTemplateModel';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { getTemplates } from 'company/api/note-templates';
import { indexToColumn } from 'core/utils';
import { report_template_schema } from 'carego-admin/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type NoteTemplate = {
  id: number;
  template_name: string;
  fields: FieldInput[];
};

type ReportTemplateField = {
  id?: number;
  form_id: number;
  field_name: string;
  order: number;
};

type ReportTemplateProps = {
  summary_id?: number;
};

const ReportTemplate = ({ summary_id }: ReportTemplateProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [templateDetails, setTemplateDetails] = useState<PatientNoteSummaryTemplateModel>();
  const [templates, setTemplates] = useState<NoteTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [reorderedFields, setReorderedFields] = useState<ReportTemplateField[]>([]);
  const [openConfirmRemoveField, setOpenConfirmRemoveField] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<any>();
  const [refresh, setRefresh] = useState<number>(0);
  const [startRow, setStartRow] = useState(6);

  const pathname = window.location.pathname;

  const validationSchema = Yup.object().shape({
    template_file_start_row: Yup.number()
      .min(1, 'Start row must be at least 1')
      .max(10, 'Start row must be at most 10')
      .required('Start row is required'),
  });

  const note_summary_id = useMemo(
    () => (summary_id ? Number(summary_id) : Number(pathname.split('/').pop())),
    [pathname, summary_id]
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    async function getFields() {
      const res = await getPatientNotesSummaryTemplateFields(note_summary_id);
      const data = res.data
        ?.map((fields: any) => ({
          id: fields.id,
          form_id: fields.source_note_template_id,
          field_name: fields.source_note_field_name,
          field_name_alternative: fields.source_note_field_name_alternative,
          order: fields.order,
          type: fields.type,
        }))
        .sort((a: any, b: any) => a.order - b.order);

      setReorderedFields(data);
    }
    getFields();
  }, [note_summary_id, refresh]);

  useEffect(() => {
    if (note_summary_id) {
      getSinglePatientNoteSummary(note_summary_id).then((res) => setTemplateDetails(res.data));
    }
  }, [note_summary_id]);

  async function handleAddNewField(data: ReportTemplateField) {
    await addPatientNoteSummaryFields(note_summary_id, data);
    setReorderedFields((prev) => [
      ...prev,
      { form_id: data.form_id, field_name: data.field_name, id: data.id, order: prev.length + 1 },
    ]);
    setRefresh((c) => c + 1);
  }

  const getNoteTemplates = async () => {
    try {
      const { data } = await getTemplates({ length: 1000 });
      setTemplates(
        data.data.map((template: any) => {
          let fields: FieldInput[] = JSON.parse(template.body)[0].fields ?? [];
          fields = fields.filter((field) => !DISPLAY_ONLY_FIELDS.includes(field.type as string));
          return {
            id: template.id,
            template_name: template.header,
            fields: fields,
          };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    getNoteTemplates();
  }, []);

  const patientFields = useMemo(
    () => [
      { key: 'ID', value: 'id' },
      { key: 'Full Name', value: 'full_name' },
      { key: 'Sex', value: 'sex' },
      { key: 'Birthday', value: 'birthday' },
      { key: 'Age', value: 'age' },
      { key: 'Civil Status', value: 'civil_status' },
      { key: 'Address', value: 'address' },
      { key: 'Employee ID', value: 'employee_id' },
      { key: 'Company Name', value: 'company_name' },
      { key: 'Section Name', value: 'section_name' },
      { key: 'Department Name', value: 'department_name' },
    ],
    []
  );
  const assessmentFields = useMemo(
    () => [
      { key: 'Findings', value: 'findings' },
      { key: 'Recommendations', value: 'recommendations' },
      { key: 'Class', value: 'class' },
    ],
    []
  );

  const fields: FieldInput[] = useMemo(() => {
    let fields: FieldInput[] = [];
    if (selectedTemplate) {
      fields = templates.find((template) => template.id === selectedTemplate)?.fields ?? [];
    }
    return [
      {
        field_name: 'form_id',
        display_name: 'Template',
        type: 'select',
        options: [
          { value: -1, key: 'Patient Data' },
          { value: -2, key: 'Assessment Data' },
          ...templates.map((template) => ({ value: template.id, key: template.template_name })),
        ],
        onChange: (value, setFieldValue) => {
          setSelectedTemplate(value);
          setFieldValue && setFieldValue('field', '');
        },
      },
      {
        field_name: 'field_name',
        display_name: 'Field',
        type: 'select',
        options:
          selectedTemplate === -1
            ? patientFields
            : selectedTemplate === -2
            ? assessmentFields
            : fields.map((field) => ({ value: field.field_name, key: field.display_name })),
        disabled: !selectedTemplate,
      },
      {
        field_name: 'field_name_alternative',
        display_name: 'Alternative Field',
        optionnal: true,
        type: 'select',
        options:
          selectedTemplate === -1
            ? patientFields
            : selectedTemplate === -2
            ? assessmentFields
            : fields.map((field) => ({ value: field.field_name, key: field.display_name })),
        disabled: !selectedTemplate,
      },
    ] as FieldInput[];
  }, [selectedTemplate, templates, patientFields, assessmentFields]);

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newFields = [...reorderedFields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    setReorderedFields(newFields);

    await reorderSummaryTemplateFields(newFields.map((field, index) => ({ ...field, order: index })));
  };

  async function handleRemoveField(data: any) {
    await removeFieldFromNoteSummary(toDelete);
    setToDelete(null);
    setRefresh((c) => c + 1);
  }

  function handleOpenRemoveDialog(data: any) {
    setOpenConfirmRemoveField(true);
    setToDelete(data);
  }

  const AddedFields = useCallback(() => {
    return (
      <Box flex="1">
        <DraggableList
          title={'Fields'}
          droppableId={'template-fields'}
          fromModal
          handleDragEnd={handleDragEnd}
          list={reorderedFields}
          style={{ padding: '5px 10px' }}
          displayItem={(item, index) => {
            const form = templates.find((template) => template.id === item.form_id);
            let field = form?.fields.find((field) => field.field_name === item.field_name);
            if (!item.form_id) {
              if (item.type === 'assessment') {
                const pf = assessmentFields.find((field) => field.value === item.field_name);
                field = { display_name: pf?.key };
              } else {
                const pf = patientFields.find((field) => field.value === item.field_name);
                field = { display_name: pf?.key };
              }
            }

            let alternative = '';
            if (item.field_name_alternative) {
              let field = form?.fields.find((field) => field.field_name === item.field_name_alternative);
              alternative = field?.display_name;
            }

            return (
              <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px">
                  <Typography width={20} fontWeight="600">
                    {indexToColumn(index + 1)}:
                  </Typography>
                  {field?.display_name ? (
                    <Typography fontWeight="500">
                      {form?.template_name ?? (item.type === 'assessment' ? 'Asessment Data' : 'Patient Data')} -{' '}
                      {field?.display_name}
                      {alternative ? ` (Alternative: ${alternative})` : ''}
                    </Typography>
                  ) : (
                    <Typography fontWeight="500">
                      {item?.field_name === 'blank' ? 'Blank Column' : 'Sequence Number'}
                    </Typography>
                  )}
                </Box>
                <RemoveCircleOutlineOutlinedIcon
                  sx={{ color: colors.redAccent, cursor: 'pointer' }}
                  onClick={() => {
                    handleOpenRemoveDialog(item.id);
                  }}
                />
              </Box>
            );
          }}
        />
        <ContainerRow sx={{ justifyContent: 'flex-start' }}>
          <RegularButton
            label="Add Sequence Number"
            color="warning"
            onClick={() =>
              handleAddNewField({ form_id: -1, field_name: 'sequence', order: reorderedFields.length + 1 })
            }
          />

          <RegularButton
            label="Add Blank Column"
            onClick={() => handleAddNewField({ form_id: -1, field_name: 'blank', order: reorderedFields.length + 1 })}
          />
        </ContainerRow>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderedFields, templates]);

  const startRowField: FieldInput[] = [
    {
      field_name: 'template_file_start_row',
      display_name: 'Start Row',
      type: 'number',
      span: 1,
    },
  ];

  const handleUpdateStartRow = async (data: any) => {
    try {
      await updateTemplateFileStartRow(note_summary_id, data.template_file_start_row);
      setStartRow(data.template_file_start_row);
      enqueueSnackbar('Successfully updated start row', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating start row', { variant: 'error' });
    }
  };

  return (
    <Box>
      <ContainerRow>
        <AddedFields />
        <Box flex="1">
          <CustomForm
            fields={fields}
            onSubmit={(data, formikHelpers) => {
              const newField = { ...data, order: reorderedFields.length + 1 };
              handleAddNewField(newField);
              // setReorderedFields((prev) => [
              //   ...prev,
              //   { form_id: data.form_id, field_name: data.field_name, id: data.id },
              // ]);
              formikHelpers.resetForm();
            }}
            initialValues={{ form_id: '', field_name: '', field_name_alternative: '' }}
            loading={false}
            schema={report_template_schema}
          />
          <Box>
            {/* Change to file name */}
            <Typography m="10px 0" fontWeight="bold">
              {templateDetails?.template_file_url ? (
                <a
                  href={templateDetails.template_file_url}
                  download={templateDetails.file_name}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.textDecoration = 'underline';
                    e.currentTarget.style.color = colors.primary;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.textDecoration = 'none';
                    e.currentTarget.style.color = colors.black_text;
                  }}
                >
                  {templateDetails.file_name}
                </a>
              ) : (
                templateDetails?.file_name
              )}
            </Typography>

            <FileUpload
              label={templateDetails?.template_file_url ? 'Change Excel Template' : 'Upload Excel Template (optional)'}
              source={'template'}
              maxFilesAllowed={1}
              maxFilesonDrop={1}
              uploadOnly
              fileTypes={{
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
              }}
              callbackAfterFileUpload={async (data) => {
                try {
                  await updateTemplateFile(note_summary_id, {
                    template_file_url: data.file_url,
                    template_file_start_row: 6,
                    file_name: data.file_name,
                  });

                  setTemplateDetails((prev: any) => ({
                    ...prev,
                    file_name: data.file_name,
                    template_file_url: data.file_url,
                  }));
                } catch (error) {
                  console.error('Error updating template file:', error);
                }
              }}
            />
          </Box>
        </Box>
      </ContainerRow>

      <Box>
        <Divider sx={{ margin: '15px 0px 15px 0px' }} />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography mb={'15px'} variant="h5" color={'#0192D5'}>
              Enter the row number where you want the data to start.
            </Typography>

            <CustomInlineForm
              submitButtonSize="small"
              fields={startRowField}
              onSubmit={handleUpdateStartRow}
              initialValues={{ template_file_start_row: templateDetails?.template_file_start_row }}
              submitButtonText="Submit"
              enableRowButtonMobile={true}
              schema={validationSchema}
            />
          </Grid>
        </Grid>
      </Box>

      <ConfirmationDialog
        content="Remove this field from note summary?"
        onConfirm={() => handleRemoveField(toDelete)}
        open={openConfirmRemoveField}
        setOpen={setOpenConfirmRemoveField}
      />
    </Box>
  );
};

export default ReportTemplate;
