/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, TableFilter } from 'core/model/interface';
import { Box, useTheme } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortDirection,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CustomGridView from './CustomGridView';
import CustomIconButton from '../buttons/IconButton';
import { DropdownOptionProps } from '../Dropdown';
import ExportButton from '../buttons/ExportButton';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import MobileView from './MobileView';
import { MobileViewConfig } from 'core/PageBuilder/CareGoTable';
import RefreshIcon from '@mui/icons-material/Refresh';
import RegularButton from '../buttons/RegularButton';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { customTableStyle } from './customTableStyle';
import { tokens } from 'theme/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

// import TableFilterModal from './modals/TableFilterModal';

type CustomTableProps = {
  rows: GridRowModel[];
  rowCount: number;
  columns: GridColDef[];
  filterColumns?: TableFilter[];
  checkboxSelection?: boolean;
  loading?: boolean;
  rowId: string;
  getData: (query: ApiQuery) => void;
  exportRecords?: (query: ApiQuery) => void;
  selectableRow?: boolean;
  handleSelectRow?: (selectionModel: GridRowSelectionModel) => void;
  disableSelectionOnClick?: boolean;
  keepNonExistentRowsSelected?: boolean;
  mainHeaderComponent?: React.ReactNode;
  headerComponent?: React.ReactNode;
  hideSearch?: boolean;
  tableHeight?: string | number;
  rowHeight?: number;
  forceRefresh?: number;
  clearSelected?: number;
  showRefreshButton?: boolean;

  initialPage?: number;
  initialPageSize?: number;
  initialOrder?: GridSortDirection;
  initialOrderBy?: string;
  otherFilter?: any;
  customSelectableRow?: (params: GridRowParams) => boolean;
  columnVisibilityModel?: GridColumnVisibilityModel;

  stackHeaderComponents?: boolean;
  updateRow?: (row: GridRowModel) => void;
  hideActionColumn?: boolean;
  hideViews?: boolean;
  showHeaderBelowTable?: boolean;
  footerComponent?: React.ReactNode;
  onGridItemClick?: (data: any) => void;
  rowActions?: DropdownOptionProps[];
  mobileViewDetailsDisplay?: (data: any, actions?: any) => React.ReactNode;
  mobileViewConfig?: (data: any) => MobileViewConfig;
};

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  rowCount,
  columns,
  columnVisibilityModel,
  filterColumns,
  hideViews,
  checkboxSelection,
  loading,
  rowId,
  getData,
  exportRecords,
  selectableRow = true,
  handleSelectRow,
  disableSelectionOnClick,
  keepNonExistentRowsSelected,
  mainHeaderComponent,
  headerComponent,
  hideSearch,
  tableHeight,
  rowHeight,
  forceRefresh,
  clearSelected,
  initialPage,
  initialPageSize,
  initialOrder,
  initialOrderBy,
  showRefreshButton,
  otherFilter,
  customSelectableRow,
  stackHeaderComponents,
  updateRow,
  hideActionColumn,
  showHeaderBelowTable,
  footerComponent,
  onGridItemClick,
  rowActions,
  mobileViewDetailsDisplay,
  mobileViewConfig,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(initialPage ?? 0);
  const savedPageSize = localStorage.getItem('pageSize');
  const defaultPageSize = savedPageSize ? Number(savedPageSize) : 10;
  const [pageSize, setPageSize] = useState<number>(initialPageSize ?? defaultPageSize);
  const [order, setOrder] = useState<GridSortDirection>(initialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy ?? '');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<GridRowModel[]>(rows);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('list');

  const processRowUpdate = (newRow: GridRowModel) => {
    updateRow && updateRow(newRow);
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const updatePageOrRefresh = () => {
    if (page === 0) refreshData();
    else setPage(0);
  };

  const handleClickRow = (newSelectionModel: GridRowSelectionModel, details: any) => {
    if (JSON.stringify(newSelectionModel) === JSON.stringify(selectionModel)) {
      setSelectionModel([]);
      handleSelectRow && handleSelectRow([]);
    } else {
      setSelectionModel(newSelectionModel);
      handleSelectRow && handleSelectRow(newSelectionModel);
    }
  };

  const handleExport = () => {
    setLoadingExport(true);
    setTimeout(() => {
      setLoadingExport(false);
    }, 1000);
    exportRecords &&
      exportRecords({
        order_by: orderBy,
        order: order,
        search: search,
      });
  };

  const fetch = useCallback(
    () =>
      getData({
        page: page + 1,
        length: pageSize,
        order_by: orderBy,
        order: order,
        search: search,
        ...otherFilter,
      }),
    [getData, page, pageSize, orderBy, order, search, otherFilter]
  );

  const refreshData = () => {
    fetch();
    setTimeout(() => {
      setFirstLoad(false);
    }, 200);
  };

  const getTableHeight = () => {
    if (tableHeight) return tableHeight;

    const row = rowHeight ? rowHeight : 40;
    const multiplier = Math.min(Math.max(5, rowCount), 10);
    return row * multiplier + (85 - multiplier * 3);
  };

  useEffect(() => {
    refreshData();
  }, [page]);

  useEffect(() => {
    if (!firstLoad) {
      updatePageOrRefresh();
    }
  }, [pageSize, search, order, orderBy, otherFilter]);

  useEffect(() => {
    if (!firstLoad) {
      refreshData();
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (clearSelected) setSelectionModel([]);
  }, [clearSelected]);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  //save pageSize in localStorage if it changes
  useEffect(() => {
    localStorage.setItem('pageSize', String(pageSize));
  }, [pageSize]);

  //load saved pageSize from local storage
  useEffect(() => {
    const savedPageSize = localStorage.getItem('pageSize');
    if (savedPageSize) {
      setPageSize(Number(savedPageSize));
    }
  }, []);

  const styles = useMemo(() => {
    return customTableStyle(colors, theme.palette.mode, selectableRow);
  }, [colors, theme.palette.mode, selectableRow]);

  const displayTableContent = () => {
    if (isMobilePhone) {
      return (
        <MobileView
          columns={columns}
          tableRows={tableRows}
          rowActions={
            rowActions ? rowActions.filter((action) => action.label !== 'divider' && !action.parent) : undefined
          }
          mobileViewConfig={mobileViewConfig}
          mobileViewDetailsDisplay={mobileViewDetailsDisplay}
        />
      );
    }

    if (viewMode === 'grid') {
      return (
        <CustomGridView
          onItemClick={onGridItemClick}
          columns={columns}
          tableRows={tableRows}
          rowActions={
            rowActions ? rowActions.filter((action) => action.label !== 'divider' && !action.parent) : undefined
          }
          mobileViewConfig={mobileViewConfig}
        />
      );
    }

    return (
      <Box height={getTableHeight()} sx={styles}>
        <DataGrid
          rows={tableRows}
          rowBuffer={10}
          rowCount={rowCount}
          loading={loading}
          pageSizeOptions={[10, 25, 50]}
          pagination
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          sortModel={[{ field: orderBy, sort: order }]}
          checkboxSelection={checkboxSelection}
          isRowSelectable={customSelectableRow ? customSelectableRow : () => !!selectableRow}
          onRowSelectionModelChange={handleClickRow}
          rowSelectionModel={selectionModel}
          columns={hideActionColumn ? columns.filter((col) => col.field !== 'action') : columns}
          density="compact"
          showColumnVerticalBorder={false}
          showCellVerticalBorder={false}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          onSortModelChange={(model) => {
            if (model.length) {
              setOrder(model[0].sort);
              setOrderBy(model[0].field);
            }
          }}
          getRowId={(row: any) => row[rowId]}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick={disableSelectionOnClick}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          columnVisibilityModel={columnVisibilityModel}
          editMode="row"
          processRowUpdate={processRowUpdate}
        />

        {showHeaderBelowTable && (
          <Box
            display="flex"
            gap="10px"
            alignItems={'right'}
            justifyContent={'right'}
            flexDirection={isMobilePhone || stackHeaderComponents ? 'column' : 'row'}
          >
            {footerComponent}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      {mainHeaderComponent}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ mb: '10px' }}
        alignItems={isMobilePhone || stackHeaderComponents ? 'flex-start' : 'flex-end'}
        flexDirection={isMobilePhone || stackHeaderComponents ? 'column' : 'row'}
        gap="10px"
      >
        <Box
          display="flex"
          gap="10px"
          alignItems={isMobilePhone ? 'flex-start' : stackHeaderComponents ? 'center' : 'flex-end'}
          flexDirection={isMobilePhone || stackHeaderComponents ? 'column' : 'row'}
        >
          {headerComponent}
          {exportRecords && <ExportButton onClick={handleExport} loading={loadingExport} />}
          {showRefreshButton && (
            <RegularButton
              onClick={() => {
                updatePageOrRefresh();
              }}
              label="Refresh"
              startIcon={<RefreshIcon />}
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
          }}
        >
          {!hideSearch && <SearchBar handleSearch={(searchKey) => setSearch(searchKey)} />}
          {!isMobilePhone && !hideViews && !stackHeaderComponents && (
            <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'end' }}>
              <CustomIconButton
                icon={<ViewListRoundedIcon />}
                onClick={() => setViewMode('list')}
                tooltip="List View"
                className={viewMode === 'list' ? 'active' : ''}
                sx={{
                  color: colors.primary,
                  '&.active': {
                    backgroundColor: colors.primary,
                    color: colors.light_blue_background,
                    '&:hover': {
                      backgroundColor: colors.lightBackground,
                      color: colors.primary,
                    },
                  },
                  borderRadius: 1,
                }}
              />
              <CustomIconButton
                icon={<GridViewRoundedIcon />}
                onClick={() => setViewMode('grid')}
                tooltip="Grid View"
                className={viewMode === 'grid' ? 'active' : ''}
                sx={{
                  color: colors.primary,
                  '&.active': {
                    backgroundColor: colors.primary,
                    color: colors.light_blue_background,
                    '&:hover': {
                      backgroundColor: colors.lightBackground,
                      color: colors.primary,
                    },
                  },
                  borderRadius: 1,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {displayTableContent()}
    </Box>
  );
};

export default CustomTable;
