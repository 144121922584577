import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Chart from './Chart';
import { CustomTabs } from 'core/components';
import Notes from '.';
import { TabProps } from 'core/components/CustomTabs';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TimelineIcon from '@mui/icons-material/Timeline';

const TemplatePage = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([{ label: 'Templates' }]);
  }, [setBreadcrumb]);

  const tabs: TabProps[] = [
    {
      label: 'Note Templates',
      icon: <TextSnippetIcon />,
      content: <Notes />,
    },
    {
      label: 'Charts',
      icon: <TimelineIcon />,
      content: <Chart />,
    },
  ];

  return <CustomTabs tabs={tabs} />;
};

export default TemplatePage;
