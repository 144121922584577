import { AccountType } from 'core/model/Entities';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { UserContext } from 'core/context/user.context';
import { updateCompanyPassword } from 'company/api/company';
import { updatePassword } from 'lgu/api/users';
import { update_staff_schema } from 'lgu/model/schema';
import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UserInput = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const EmployeeCredentials = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const initialValues: UserInput = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  };

  // Form Fields
  const staffFields: UserFieldInput[] = [
    { field_name: 'current_password', display_name: 'Current Password', type: 'password', span: 4 },
    { field_name: 'new_password', display_name: 'New Password', type: 'password', span: 4 },
    { field_name: 'confirm_new_password', display_name: 'Confirm New Password', type: 'password', span: 4 },
  ];

  const updateUserPassword = (data: any) => {
    setLoading(true);

    const userPassword = {
      old_password: data.current_password,
      new_password: data.new_password,
    };

    updatePassword(user.id, userPassword)
      .then((res) => {
        enqueueSnackbar(`Password successfully updated!`, { variant: 'success' });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(`Password does not match!`, { variant: 'error' });
        setLoading(false);
      });
  };

  const companyPassword = (data: any) => {
    setLoading(true);

    const userPassword = {
      old_password: data.current_password,
      new_password: data.new_password,
    };

    updateCompanyPassword(user.id, userPassword)
      .then((res) => {
        enqueueSnackbar(`Password successfully updated!`, { variant: 'success' });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(`Password does not match!`, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <CustomForm
      onSubmit={user.account_type === AccountType.LGU ? updateUserPassword : companyPassword}
      fields={staffFields}
      schema={update_staff_schema}
      initialValues={initialValues}
      loading={loading}
      submitButtonText={'Update'}
    />
  );
};

export default EmployeeCredentials;
