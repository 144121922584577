import { CustomModal, CustomTable } from 'core/components';

import { ApiQuery } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import { LguModel } from 'carego-admin/model/Entities';
import { getBarangays } from 'carego-admin/api/barangay';
import { useState } from 'react';

type BarangayListProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lgu: LguModel;
};

const BarangayList: React.FC<BarangayListProps> = ({ lgu, open, setOpen }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const barangayColumns: GridColDef[] = [
    { field: 'barangay_name', headerName: 'Barangay Name', flex: 1 },
    { field: 'barangay_code', headerName: 'Barangay Geo Code', flex: 1 },
  ];

  const getBarangayList = (query: ApiQuery) => {
    setLoading(true);
    getBarangays(lgu.lgu_code, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {lgu && (
        <CustomModal header={lgu.lgu_name + ' Barangays'} open={open} setOpen={setOpen}>
          <CustomTable
            columns={barangayColumns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'barangay_code'}
            getData={getBarangayList}
          />
        </CustomModal>
      )}
    </>
  );
};

export default BarangayList;
