import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';
import { formatNumber } from 'core/utils';
import moment from 'moment';

export const EXPENSE_ENTITY_NAME = 'Expense';

export interface ExpenseModel {
  id: number;
  company_id: number;
  facility_id: number;
  account_id: number;
  payment_date: string;
  total_amount: number;
}

export const createExpenseModelSchema = yup.object().shape({
  payment_date: yup
    .date()
    .required('Payment Date is required')
    .typeError('Invalid Date')
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

export interface ExpenseModel {
  id: number;
  company_id: number;
  facility_id: number;
  account_id: number;
  payment_date: string;
  total_amount: number;
}

export const expenseEntityFields: EntityFields[] = [
  {
    displayName: 'Payment Date',
    fieldName: 'payment_date',
    type: 'date',
    valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY'),
    span: 4,
  },
  { displayName: 'Total Amount', fieldName: 'total_amount', valueFormatter: (params) => formatNumber(params.value) },
  { fieldName: 'divider_1', type: 'divider' },
  {
    displayName: 'Expenses',
    type: 'table',
    fieldName: 'line_items',
    columns: [
      { fieldName: 'expense_type', displayName: 'Expense Type', type: DROPDOWN_FIELD.EXPENSE_TYPE },
      { fieldName: 'description', displayName: 'Description' },
      { fieldName: 'amount', displayName: 'Amount', type: 'number' },
    ],
  },
];

export const visibleExpenseColumns = ['payment_date', 'total_amount'];
export const expenseFormFields = ['payment_date', 'divider_1', 'line_items'];
export const expenseInitialValues = {
  payment_date: '',
  line_items: [
    {
      expense_type: '',
      description: '',
      amount: 0,
    },
  ],
};
