import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Button } from '@mui/material';
import {
  ConfirmationDialog,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
} from 'core/components';
import { changeNullToBlank, tranformFormErrors } from 'core/utils';
import { createFixedReceiver, deleteFixedReceiver, getFixedReceivers, updateFixedReceiver } from 'lgu/api/eod-fixed';
import { create_eod_receiver_schema, update_eod_receiver_schema } from 'lgu/model/schema';

import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UserInput = {
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  title: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
};

type EndOfDayReceiversModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const EndOfDayReceiversModal: React.FC<EndOfDayReceiversModalProps> = ({ open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openReceiverModal, setOpenReceiverModal] = useState<boolean>(false);
  const [receiverSchema, setReceiverSchema] = useState<any>(create_eod_receiver_schema);
  const [receiverModalHeader, setReceiverModalHeader] = useState('Add EOD Report Receiver');
  const [receiverInitialValues, setReceiverInitialValues] = useState<UserInput>(initialValues);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [receiverID, setReceiverID] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Recipient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>{params.row.title ? params.row.full_name_with_title : params.row.full_name}</CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              const data = changeNullToBlank(params.row);
              showReceiverModal(true, data);
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              showAlertDialog('Are you sure you want to delete ' + params.row.full_name + '?');
              setReceiverID(params.row.id);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  // Form Fields
  const receiverFields: UserFieldInput[] = [
    { field_name: 'title', display_name: 'Title (e.g. Dr.)', type: 'text' },
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
  ];

  const getReceiversList = (query: ApiQuery) => {
    setLoading(true);
    getFixedReceivers(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showReceiverModal = (from_edit?: boolean, data?: UserInput) => {
    if (from_edit && data) {
      setReceiverInitialValues(data);
      setReceiverModalHeader('Update EOD Report Receiver');
      setReceiverSchema(update_eod_receiver_schema);
    } else {
      setReceiverModalHeader('Add EOD Report Receiver');
      setReceiverInitialValues(initialValues);
      setReceiverSchema(create_eod_receiver_schema);
    }

    setOpenReceiverModal(true);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>): void => {
    setButtonLoading(true);

    if (data.id) {
      updateFixedReceiver(data.id, data)
        .then((res: any) => {
          successFormSubmit(true, res, formikHelpers);
        })
        .catch((error) => console.error(error));
      return;
    }

    createFixedReceiver(data)
      .then((res: any) => {
        successFormSubmit(false, res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (from_edit: boolean, res: any, { resetForm, setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`EOD Report Receiver successfully ${from_edit ? 'updated' : 'added'}!`, { variant: 'success' });
      getReceiversList({ page: 1, length: 10 });
      if (!from_edit) {
        resetForm();
        setOpenReceiverModal(false);
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const showAlertDialog = (content: string) => {
    setOpenAlertDialog(true);
    setAlertContent(content);
  };

  const handleReceiverDelete = () => {
    setLoading(true);
    deleteFixedReceiver(receiverID!)
      .then((res: any) => {
        enqueueSnackbar('EOD Report Receiver successfully deleted!', { variant: 'success' });
        getReceiversList({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
    return;
  };

  return (
    <CustomModal header={'End of Day Report Receivers'} open={open} setOpen={setOpen} width={900}>
      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getReceiversList}
        headerComponent={<PrimaryButton onClick={() => showReceiverModal()} label="Add EOD Report Receiver" />}
      />

      <CustomModal header={receiverModalHeader} open={openReceiverModal} setOpen={setOpenReceiverModal}>
        <CustomForm
          initialValues={receiverInitialValues}
          onSubmit={handleSubmit}
          fields={receiverFields}
          schema={receiverSchema}
          loading={buttonLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
        content={alertContent}
        onConfirm={handleReceiverDelete}
      />
    </CustomModal>
  );
};

export default EndOfDayReceiversModal;
