import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getCompanyTag, getCompanyTags } from 'company/api/company-tags';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const PatientGroupSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'Patient Group'}
        getData={(query: ApiQuery) => getCompanyTags(facility.id, query)}
        getById={(id: number) => getCompanyTag(facility.id, id)}
        processOption={(record) => ({ key: record.tag_name, value: record.id })}
        processNewRecord={(record) => record.tag}
      />
    </WaitForFacility>
  );
};

export default PatientGroupSearchDropdown;
