import { Box, Typography } from '@mui/material';
import { Dropdown } from 'core/components';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HealthInfoFieldForm from './HealthInfoFieldForm';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

type HealthInfoFieldListProps = {
  title: string;
  droppableId: string;
  fieldList: any[];
  handleDragEnd: (result: DropResult) => void;
  handleSaveEditedOption: (index: number, data: any, options: string[]) => void;
  handleHideShowOption: (index: number, data: any) => void;
  handleDeleteOption: (index: number, id: any) => void;
};

const HealthInfoFieldList: React.FC<HealthInfoFieldListProps> = ({
  title,
  droppableId,
  fieldList,
  handleDragEnd,
  handleSaveEditedOption,
  handleHideShowOption,
  handleDeleteOption,
}) => {
  const [editingIndex, setEditingIndex] = useState(-1);

  const handleCancelEditOption = (hidden: boolean) => {
    setEditingIndex(-1);
  };

  return (
    <DraggableList
      title={title}
      droppableId={droppableId}
      fromModal
      handleDragEnd={handleDragEnd}
      list={fieldList}
      style={{ padding: '5px 10px' }}
      displayItem={(item, index) => (
        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
          {editingIndex === index ? (
            <HealthInfoFieldForm
              initialValue={item}
              onSubmit={async (data, options) => {
                await handleSaveEditedOption(index, data, options);
                setEditingIndex(-1);
              }}
              onCancel={() => handleCancelEditOption(true)}
            />
          ) : (
            <>
              <Box>
                <Typography fontWeight="bold">
                  {item.display_name} {item.field_unit ? ` (${item.field_unit})` : ''}
                </Typography>
                <Typography fontSize="10px">{item.field_type}</Typography>
              </Box>
              <Dropdown
                isIcon
                icon={<MoreVertOutlinedIcon />}
                optionList={[
                  {
                    label: 'Edit',
                    icon: <EditOutlinedIcon />,
                    action: () => setEditingIndex(index),
                  },
                  {
                    label: item.hidden ? 'Show' : 'Hide',
                    icon: item.hidden ? <VisibilityIcon /> : <VisibilityOffIcon />,
                    action: () => handleHideShowOption(index, item),
                  },
                  {
                    label: 'Delete',
                    icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
                    action: () => handleDeleteOption(index, item.field_name),
                  },
                ]}
              />
            </>
          )}
        </Box>
      )}
    />
  );
};

export default HealthInfoFieldList;
