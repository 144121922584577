import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'clients';

export const getClients = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getFacilityClients = (faciliy_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facility/${faciliy_id}`, { params: query });
};

export const getClient = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createClient = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateClient = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteClient = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getPatientsUnderCorporateClient = (id: number) => {
  return axios.get(`${baseURL}/${path}/patients/${id}`);
};

export const getClinicsAdded = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/added/${id}`);
};

export const getClinicsNotAdded = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/not-added/${id}`);
};

export const removeAssignedClinic = (data: any) => {
  return axios.post(`${baseURL}/${path}/remove-clinic`, data);
};

export const assignClinic = (data: any) => {
  return axios.post(`${baseURL}/${path}/add-clinic`, data);
};
