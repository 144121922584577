import { CorporateClientDepartmentModel, departmentFields, visibleTableFields } from './CorporateClientDepartmentModel';
import { deleteOne, getAllClientDepartment, getManyByCorporateClientId } from 'company/api/corporate-client-department';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import CorporateClientDepartmentForm from './CorporateClientDepartmentForm';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { useMediaQuery, useTheme } from '@mui/material';
import { tokens } from 'theme/theme';

type Props = {
  companyId?: number;
  corporateClientId?: number;
  externalSelectedDepartment?: CorporateClientDepartmentModel;
  setExternalSelectedDepartment?: (department: CorporateClientDepartmentModel) => void;
};

const CorporateClientDepartmentTable = forwardRef((props: Props, ref) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const { companyId, corporateClientId, externalSelectedDepartment, setExternalSelectedDepartment } = props;

  const [selectedDepartment, setSelectedDepartment] = useState<CorporateClientDepartmentModel>();
  const tableRef: any = useRef();

  const handleGetData = (query: ApiQuery) => {
    if (corporateClientId) return getManyByCorporateClientId(corporateClientId);
    return getAllClientDepartment(query);
  };

  const handleDelete = (id: number) => {
    return deleteOne(id);
  };

  const callbackAfterSubmit = () => {
    tableRef.current.refreshTable();
    tableRef.current.closeFormModal();
  };

  useEffect(() => {
    tableRef.current.refreshTable();
  }, [corporateClientId]);

  return (
    <TemplateTable
      ref={tableRef}
      entityName={'Department'}
      fields={departmentFields}
      selectedRow={selectedDepartment}
      drawerSelectedRow={externalSelectedDepartment}
      getData={handleGetData}
      handleDelete={handleDelete}
      setSelectedRow={setSelectedDepartment}
      setDrawerSelectedRow={setExternalSelectedDepartment}
      visibleColumns={visibleTableFields}
      templateForm={
        <CorporateClientDepartmentForm
          companyId={companyId}
          corporateClientId={corporateClientId}
          callbackAfterSubmit={callbackAfterSubmit}
          corporateClientDepartment={selectedDepartment}
        />
      }
      tableAction={{
        addMobileLabel: isMobile ? 'Add Department' : '',
        addHeaderContainerStyles: isMobile
          ? {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              boxSizing: 'border-box',
              marginBottom: '10px',
              color: colors.primary,
            }
          : {},
        addButtonLabel: isMobile ? '' : 'Add Department',
        addButtonStyles: isMobile
          ? {
              minWidth: '40px',
              boxShadow: 'none',
              color: 'white',
              backgroundColor: colors.blueAccent,
              justifyContent: 'center',
              paddingLeft: '12px',
            }
          : {},
      }}
      tableComponent={{
        mobileViewConfig: (data) => {
          return {
            title_fields: ['department_name'],
            subtitle_fields: ['company_name', 'corporate_client_company_name'],
          };
        },
      }}
    />
  );
});

export default CorporateClientDepartmentTable;
