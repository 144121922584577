import { CustomGridCell, CustomTable, Header } from 'core/components';

import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from 'core/utils';
import { getBillingHistory } from 'lgu/api/billing-history';
import { useState } from 'react';

const BillingHistory = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const billingColumns: GridColDef[] = [
    { field: 'full_name', headerName: 'Purchased by', flex: 1 },
    {
      field: 'cost',
      headerName: 'Amount in PHP',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      renderCell: (params) => (
        <CustomGridCell>
          {parseFloat(params.row.cost).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </CustomGridCell>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Date Created',
      type: 'Date',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.created_at)}</CustomGridCell>,
    },
  ];

  const getBillingHistoryData = () => {
    setLoading(true);
    getBillingHistory()
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      <Header title="Billing History" />

      <CustomTable
        columns={billingColumns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getBillingHistoryData}
      />
    </>
  );
};

export default BillingHistory;
