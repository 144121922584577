import { CustomModal, RegularButton } from 'core/components';
import {
  PRESCRIPTION_ENTITY_NAME,
  PrescriptionModel,
  prescriptionFields,
  prescriptionVisibleColumns,
} from './PrescriptionModel';
import { deletePrescription, getPrescriptions } from 'company/api/prescriptions';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { FacilityContext } from 'core/context/facility.context';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PrintablePrescription from 'company/screens/RegistrationBoard/components/PrintablePrescription';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { generateInstruction } from 'core/utils';
import { getDoctor } from 'company/api/doctors';
import { getPatient } from 'company/api/patient';
import { getPrescriptionMedicines } from 'company/api/prescription-medicines';

type Props = {
  filterDateRange: DateRangeValue;
};
const PrescriptionTable = forwardRef((props: Props, ref) => {
  const { filterDateRange } = props as any;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<PrescriptionModel>();
  const [medicines, setMedicines] = useState<any[]>([]);
  const [doctor, setDoctor] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const backupRef: any = useRef();
  const mainRef: any = backupRef;

  const handleGetData = (query: ApiQuery) => {
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];
    if (initialLoad) {
      setInitialLoad(false);
      return Promise.resolve({
        data: {
          data: [],
          meta: { total: 0 },
        },
      });
    }

    return getPrescriptions(facility.id, -1, { ...query, start_date, end_date });
  };

  useEffect(() => {
    mainRef.current?.refreshTable();
  }, [filterDateRange, initialLoad]);

  const handleDelete = (id: number) => {
    return deletePrescription(facility.id, id);
  };

  const getMedicines = async (data: any) => {
    try {
      const doctor_res = await getDoctor(data.doctor_id);
      setDoctor(doctor_res.data);
      const patient_res = await getPatient(facility.id, data.patient_id);
      setPatient(patient_res.data);

      const response = await getPrescriptionMedicines(facility.id, data.id, { length: 50 });
      const formattedMedicines = response.data.data.map((medicine: any) => ({
        generic_name: medicine.generic_name ? medicine.generic_name : 'N/A',
        brand_name: medicine.brand_name ? medicine.brand_name : 'N/A',
        quantity: medicine.quantity ? medicine.quantity : 'N/A',
        instruction: generateInstruction(medicine),
      }));
      setMedicines(formattedMedicines);
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching prescription medicines:', error);
      setMedicines([]);
    }
  };

  const getPrescription = (data: any) => {
    if (data) {
      getMedicines(data);
    }
  };

  return (
    <>
      <TemplateTable
        tableComponent={{
          mobileViewConfig: () => ({
            title_fields: ['patient_name'],
            subtitle_fields: ['created_at', 'doctor_name'],
          }),
          hideSearch: true,
        }}
        ref={mainRef}
        entityName={PRESCRIPTION_ENTITY_NAME}
        fields={prescriptionFields}
        visibleColumns={prescriptionVisibleColumns}
        getData={handleGetData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        hideAddUpdateDeleteAction
        customColumns={[
          {
            fieldName: 'preview',
            displayName: 'Action',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
              <RegularButton
                label="View"
                variant="text"
                size="small"
                startIcon={<PrintOutlinedIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRow(params.row);
                  getPrescription(params.row);
                }}
              />
            ),
          },
        ]}
      />

      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        width={800}
        header="Preview Prescription"
        onClose={() => setSelectedRow(undefined)}
      >
        <PrintablePrescription
          prescription={selectedRow ?? {}}
          medicines={medicines ?? []}
          patient={patient ?? {}}
          doctor={doctor ?? {}}
          viewOnly
        />
      </CustomModal>
    </>
  );
});

export default PrescriptionTable;
