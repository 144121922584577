import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notes-template-filter';

export const getFilters = (template_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/${template_id}`, {
    params: query,
  });
};

export const getFilter = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createFilters = (template_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${template_id}`, data);
};

export const updateFilters = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteFilters = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
