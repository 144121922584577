/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Typography, useTheme } from '@mui/material';
import { CustomContainer, CustomForm, Header, HideOrShowComponent } from 'core/components';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { PatientForm, ServiceForm } from 'company/entities/forms';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import ClinicForm from 'company/screens/ClinicManagement/components/Clinic/ClinicForm';
import { DROPDOWN_FIELD } from 'core/model/interface';
import { Done } from '@mui/icons-material';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import SmsPreview from 'company/screens/Appointments/components/modal/SmsPreview';
import { appointment_schema } from 'company/model/schema';
import { getLanguages } from 'company/api/languages';
import { handleOnboardingDone } from 'company/api/company';
import { scheduleAppointment } from 'company/api/appointments';
import { tokens } from 'theme/theme';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

const Onboarding = () => {
  // localStorage.clear(); //for testing to clear localStorage upon refreshing page -- uncomment to reset during testing

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const navigate = useNavigate();

  const [languages, setLanguages] = useState<any>();
  const [selected, setSelected] = useState<string>('clinic_details');
  const [screen, setScreen] = useState<any>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [isClinicDetailsDone, setIsClinicDetailsDone] = useState(() => {
    const isClinicDetailsDone = localStorage.getItem('isClinicDetailsDone');
    return isClinicDetailsDone === 'true';
  });

  const [isClientsDone, setIsClientsDone] = useState(() => {
    const isClientsDone = localStorage.getItem('isClientsDone');
    return isClientsDone === 'true';
  });

  const [isServicesDone, setIsServicesDone] = useState(() => {
    const isServicesDone = localStorage.getItem('isServicesDone');
    return isServicesDone === 'true';
  });

  const [isAppointmentsDone, setIsAppointmentsDone] = useState(() => {
    const isAppointmentsDone = localStorage.getItem('isAppointmentsDone');
    return isAppointmentsDone === 'true';
  });

  const handleSkip = () => {
    if (selected === 'clinic_details') {
      setIsClinicDetailsDone(true);
      setSelected('clients');
    } else if (selected === 'clients') {
      setIsClientsDone(true);
      setSelected('services');
    } else if (selected === 'services') {
      setIsServicesDone(true);
      setSelected('appointments');
    } else if (selected === 'appointments') {
      setIsAppointmentsDone(true);
    }
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    try {
      const final = { ...data };

      final.patients = JSON.stringify(data.patient_id.map((patient: any) => patient.value));
      final.services = JSON.stringify(data.service_id.map((service: any) => service.value));

      const res = await scheduleAppointment(facility.id, final);
      successFormSubmit(res, formikHelpers);
    } catch (error: any) {
      if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
      enqueueSnackbar('Error occured! Please try again.', { variant: 'error' });
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      setIsAppointmentsDone(true);
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
    } else if (res.response.status === 400) {
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Onboarding' }]);
  }, []);

  useEffect(() => {
    localStorage.setItem('isClinicDetailsDone', isClinicDetailsDone.toString());
    checkIfOnboardingDone();
  }, [isClinicDetailsDone]);

  useEffect(() => {
    localStorage.setItem('isClientsDone', isClientsDone.toString());
    checkIfOnboardingDone();
  }, [isClientsDone]);

  useEffect(() => {
    localStorage.setItem('isServicesDone', isServicesDone.toString());
    checkIfOnboardingDone();
  }, [isServicesDone]);

  useEffect(() => {
    localStorage.setItem('isAppointmentsDone', isAppointmentsDone.toString());
    checkIfOnboardingDone();
  }, [isAppointmentsDone]);

  useEffect(() => {
    checkIfOnboardingDone();
  }, [isClinicDetailsDone, isClientsDone, isServicesDone, isAppointmentsDone]);

  const checkIfOnboardingDone = () => {
    const isOnboardingDone = menuItems.every((item) => item.isDone);

    if (isOnboardingDone) {
      handleOnboardingDone().then(() => {
        navigate('/company');
        window.location.reload();
      });
    }
  };

  const appointmentsFormFields: FieldInput[] = [
    {
      field_name: 'schedule',
      display_name: 'Appointment Schedule',
      type: 'datetime',
      minutesStep: 5,
      span: 4,
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue(value, 'note');
      },
    },
    { field_name: 'patient_id', type: DROPDOWN_FIELD.PATIENT, span: 4, multiple: true },
    { field_name: 'service_id', type: DROPDOWN_FIELD.SERVICE, span: 4, multiple: true },
    {
      field_name: 'consultation_purpose',
      display_name: 'Purpose',
      type: 'text',
      multiline: true,
      rows: 3,
      span: 4,
    },
  ];

  const menuItems: any[] = [
    {
      title: 'Review your clinic details',
      ref: 'clinic_details',
      isDone: isClinicDetailsDone,
      isSelected: selected === 'clinic_details',
      content: <ClinicForm clinic={facility} languages={languages} setIsClinicDetailsDone={setIsClinicDetailsDone} />,
    },
    {
      title: 'Add your clients',
      ref: 'clients',
      isDone: isClientsDone,
      isSelected: selected === 'clients',
      content: (
        <Box width={'150%'}>
          <PatientForm facility={facility} callbackAfterSubmit={() => setIsClientsDone(true)} />
        </Box>
      ),
    },
    {
      title: 'Add your services',
      ref: 'services',
      isDone: isServicesDone,
      isSelected: selected === 'services',
      content: <ServiceForm callbackAfterSubmit={() => setIsServicesDone(true)} />,
    },
    {
      title: 'Create a sample appointment',
      ref: 'appointments',
      isDone: isAppointmentsDone,
      isSelected: selected === 'appointments',
      content: (
        <CustomForm
          initialValues={{
            schedule: '',
            service_id: [],
            consultation_purpose: '',
            walk_in: false,
            patient_id: [],
          }}
          onSubmit={handleSubmit}
          fields={appointmentsFormFields}
          schema={appointment_schema}
          loading={buttonLoading}
          footerComponent={<SmsPreview templateCode="appointment_reminder" facility={facility} mt="20px" />}
        />
      ),
    },
  ];

  const getLanguageList = () => {
    getLanguages()
      .then((res) => {
        setLanguages(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    getLanguageList();
    checkIfOnboardingDone();
  }, []);

  useEffect(() => {
    facility && languages && menuItems.map((item) => item.isSelected && setScreen(item.content));
  }, [facility, languages, selected]);

  return (
    <CustomContainer gap="1">
      <Header title="Onboarding CareGo Health Suite" />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
          <Menu>
            {menuItems.map((subitem: any) => (
              <MenuItem
                key={subitem.ref}
                active={false}
                style={{
                  color: colors.text,
                  listStyleType: 'none',
                }}
                onClick={() => {
                  setSelected(subitem.ref);
                  setScreen(subitem.content);
                }}
              >
                <Box
                  display={'flex'}
                  width={'15rem'}
                  justifyContent={'space-between'}
                  p={'1rem'}
                  border={'1px black solid'}
                  bgcolor={subitem.isSelected ? colors.lightBackground : 'none'}
                >
                  <Typography>{subitem.title}</Typography>
                  {subitem.isDone ? (
                    <Done
                      className="check-icon"
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '24px',
                        height: '24px',
                        border: '2px solid #00033',
                        color: 'white',
                        borderRadius: '50%',
                        backgroundColor: 'green',
                      }}
                    />
                  ) : (
                    <div className="circle-icon">
                      <Box
                        className="check-icon"
                        style={{
                          border: '2px solid #000',
                          borderRadius: '50%',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    </div>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box p={'2rem'}>
          {((selected === 'services' && isServicesDone) || (selected === 'clients' && isClientsDone)) && (
            <Box mb={'2rem'}>
              {selected === 'services' && isServicesDone && (
                <Link to={'/company/clinic-management?tab=2'}> Click here to see added services </Link>
              )}
              {selected === 'clients' && isClientsDone && (
                <Link to={'/company/patient-management'}> Click here to see added clients </Link>
              )}
            </Box>
          )}
          {/* SKIP BUTTON HERE */}
        </Box>
        <Box p={'1rem 2rem'}>
          <HideOrShowComponent
            hidden={
              (selected === 'clinic_details' && isClinicDetailsDone) ||
              (selected === 'clients' && isClientsDone) ||
              (selected === 'services' && isServicesDone) ||
              (selected === 'appointments' && isAppointmentsDone)
            }
          >
            <Button
              sx={{
                backgroundColor: colors.secondary,
                color: colors.text,
                fontWeight: 'bold',
                marginBottom: '2rem',
              }}
              onClick={handleSkip}
            >
              Skip This Step
            </Button>
          </HideOrShowComponent>

          {screen}
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default Onboarding;
