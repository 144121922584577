import Joyride, { CallBackProps } from 'react-joyride';

import { TOUR_ID } from './steps';
import { TourContext } from 'core/context/tour.context';
import { useContext } from 'react';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';

export const displayFormGuidedTour = (
  tourActive: boolean,
  currentStepIndex: number,
  triggerStepIndex: number,
  mainRef: any,
  setTourState: any
) => {
  if (tourActive) {
    if (currentStepIndex === triggerStepIndex) {
      mainRef.current.handleAddButtonClick();
      setTimeout(() => {
        setTourState((prev: any) => {
          return { ...prev, run: true, tourActive: true };
        });
      }, 300);
    } else {
      mainRef.current.closeFormModal();
    }
  }
};

const GuidedTours = () => {
  const navigate = useNavigate();
  const {
    tourState: { run, stepIndex, steps, tourId },
    setTourState,
  } = useContext(TourContext);

  useMount(() => {
    setTourState((prev: any) => {
      return {
        ...prev,
        steps: [],
      };
    });
  });

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type } = data;

    if (type === 'step:after') {
      switch (action) {
        case 'next':
          if (index === 1) {
            if (tourId === TOUR_ID.PATIENT_ADD_STEPS) navigate('/company/patient-management');
            else if (tourId === TOUR_ID.SERVICE_ADD_STEPS) navigate('/company/clinic-management?tab=2');
            else if (tourId === TOUR_ID.CORPORATE_CLIENT_STEPS)
              navigate('/company/patient-management/corporate-clients');
          }
          setTourState((prev: any) => {
            return { ...prev, stepIndex: index + 1 };
          });
          break;
        case 'prev':
          setTourState((prev: any) => {
            return { ...prev, stepIndex: index - 1 };
          });
          break;
        case 'close':
          if (index === 0) {
            setTourState((prev: any) => {
              return { ...prev, run: false, stepIndex: 0, tourActive: false };
            });
          }
          break;
      }
    } else if (type === 'tour:end') {
      setTourState((prev: any) => {
        return { ...prev, run: false, stepIndex: 0, tourActive: false };
      });
    }
  };

  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      scrollToFirstStep
      stepIndex={stepIndex}
      steps={steps}
      hideCloseButton
      styles={{
        options: {
          zIndex: 100000,
          width: 300,
        },
      }}
      // styles={{
      //   options: {
      //     arrowColor: theme.black,
      //     backgroundColor: theme.black,
      //     primaryColor: theme.colors.purple,
      //     textColor: theme.white,
      //   },
      // }}
    />
  );
};

export default GuidedTours;
