import { UserGroups } from "company/model/Entities";

import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'user-groups';

export const getUserGroups = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const getUserGroupById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createUserGroup = (data: UserGroups) => {
  return axios.post(`${baseURL}/${path}`, data);
};

// to make sure that there's a parameter for id
export const updateUserGroup = (id: number, data: UserGroups) => {
  data.id = id;
  return axios.put(`${baseURL}/${path}`, data);
};

export const deleteUserGroup = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};