/* eslint-disable react-hooks/exhaustive-deps */
import 'core/styles/Login.css';

import { Box, IconButton, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ColorModeContext, tokens } from 'theme/theme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { googleLoginCallback, login } from 'core/api/user';

import { AccountType } from 'core/model/Entities';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'js-cookie';
import { FacilityContext } from 'core/context/facility.context';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { useGoogleLogin } from '@react-oauth/google';

interface Props {
  isAdmin?: boolean;
}

const LoginScreen: React.FC<Props> = ({ isAdmin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountType, setAccountType] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [loading, setLoading] = useState(false);

  const { setFacility } = useContext(FacilityContext);

  const validateForm = () => {
    if (!accountType && !isAdmin) {
      setError('Select account type');
      return false;
    }

    if (!username) {
      setError('Username is required.');
      return false;
    }

    if (!password) {
      setError('Password is required.');
      return false;
    }

    return true;
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobilePhone = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      colorMode.toggleColorMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await googleLoginCallback(codeResponse.access_token);
      if (response.data.token) {
        Cookies.set('access_token', response.data.token);

        if (locationState) {
          const { redirectTo } = locationState as any;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else {
          navigate('/company');
          localStorage.removeItem('orders');
          localStorage.removeItem('selectedPatient');
        }
      } else {
        setError('Login Failed');
      }
    },
    onError: (error) => console.error('Login Failed:', error),
  });

  const loginUser = () => {
    setLoading(true);
    setError('');
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    let account = isAdmin ? AccountType.CAREGO : accountType === 'lgu' ? AccountType.LGU : AccountType.COMPANY;
    login({ username, password }, account)
      .then(function (response) {
        if (response.data.token) {
          Cookies.set('access_token', response.data.token);

          if (locationState) {
            const { redirectTo } = locationState as any;
            navigate(`${redirectTo.pathname}${redirectTo.search}`);
          } else {
            if (isAdmin) navigate('/admin');
            else accountType === 'lgu' ? navigate('/lgu') : navigate('/company');
            localStorage.removeItem('orders');
            localStorage.removeItem('selectedPatient');
          }
        } else {
          setError('Login Failed');
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.status === 401) {
          setError('Invalid credentials provided. Please check your username and password and try again');
        } else setError(error.response.data.message);
      });
  };

  const navigateToSignUp = () => {
    navigate('/signup');
  };
  const handleAccountTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedAccountType = e.target.value;

    localStorage.setItem('accountType', selectedAccountType);
    setAccountType(selectedAccountType);
  };
  useEffect(() => {
    setFacility(undefined);
    const storedAccountType = localStorage.getItem('accountType');

    if (storedAccountType) {
      setAccountType(storedAccountType);
    }
    if (Cookies.get('access_token')) {
      if (storedAccountType === 'lgu') navigate('/lgu');
      if (storedAccountType === 'company') navigate('/company');
      localStorage.removeItem('orders');
      localStorage.removeItem('selectedPatient');
    }
    if (locationState) {
      const { redirectTo } = locationState as any;
      if (redirectTo) {
        setLoading(false);
        setError('User is not signed in.');
      }
    }
  }, []);

  return (
    <Container>
      {window.innerWidth <= 768 && (
        <Hero>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="80%"
            sx={{
              position: 'absolute',
              top: 170,
              '@media screen and (max-width: 1800px)': {
                top: 110,
              },
            }}
          ></Box>
        </Hero>
      )}
      <div className="login-container">
        <Link
          to={`/`}
          style={{
            zIndex: 1,
            width: isMobilePhone ? '80%' : '33%',
            alignItems: 'center',
            gap: '10px',
            margin: '10px',
            display: 'flex',
            textDecoration: 'none',
          }}
        >
          <ArrowBackIcon style={{ color: colors.white_text, fontSize: '13px' }} />
          <Typography style={{ color: colors.white_text, fontSize: '13px' }}>Back to Home</Typography>
        </Link>

        <Box
          sx={{
            width: isMobilePhone ? '80%' : '50%',
            maxWidth: '500px',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '25px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '50px',
            justifyContent: 'center',
            gap: !isMobilePhone ? '15px' : 0,
            // overflow: 'hidden', // Ensures content stays inside
            // '@media screen and (max-width: 1800px)': {
            //   height: '75%',
            // },
            // '@media screen and (max-width: 768px)': {
            //   height: '70%',
            //   width: '80%',
            //   alignItems: 'center',
            // },
          }}
        >
          <img
            className="logo"
            src={
              process.env.PUBLIC_URL +
              (theme.palette.mode === 'dark'
                ? '/assets/landingassets/NewLogo.png'
                : '/assets/landingassets/NewLogo.png')
            }
            alt="Logo"
            style={{ margin: '30px 0 20px 0' }}
          />

          <Box className="login-title" display="flex" flexDirection="column" alignItems="center" gap="7px">
            <Typography style={{ color: colors.primary, fontSize: '28px', fontWeight: 'bold' }}>
              {isAdmin ? 'Admin' : ''} Login
            </Typography>
            <Typography style={{ color: colors.text, fontSize: '13px', fontFamily: 'Roboto' }}>
              Login to access your CareGo Health Suite account.
            </Typography>
          </Box>

          <Box className="error-msg">
            <span> {error} </span>
          </Box>

          {!isAdmin && (
            <Box
              sx={{
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'row',
                gap: '30px',
                color: 'black',
                '@media screen and (max-width: 768px)': {
                  fontSize: '13px', // Example change for smaller screens
                  // Add any other styles for smaller screens here
                },
              }}
            >
              <div>
                <input
                  required
                  type="radio"
                  name="account-type"
                  id="lgu"
                  value="lgu"
                  onChange={handleAccountTypeChange}
                  checked={accountType === 'lgu'}
                />
                <label
                  htmlFor="lgu"
                  style={{ color: colors.text, marginLeft: '10px', fontFamily: 'Roboto', fontSize: '14px' }}
                >
                  LGU
                </label>
              </div>
              <div>
                <input
                  required
                  type="radio"
                  name="account-type"
                  id="private-company"
                  value="company"
                  onChange={handleAccountTypeChange}
                  checked={accountType === 'company'}
                />
                <label
                  htmlFor="private-company"
                  style={{ color: colors.text, marginLeft: '10px', fontFamily: 'Roboto', fontSize: '14px' }}
                >
                  Company
                </label>
              </div>
            </Box>
          )}

          <div className="login-form" style={{ width: '100%' }}>
            <br />

            <div className="input-group" style={{ width: '100%' }}>
              <input
                required
                type="username"
                name="username"
                className="input"
                id="username"
                style={{ width: '100%' }} // Ensure input fits within the parent
                onChange={(e) => setUsername(e.target.value)}
              />
              <label className="user-label" style={{ fontFamily: 'Roboto', fontSize: '13px' }}>
                Username
              </label>
            </div>

            <div className="input-group" style={{ width: '100%' }}>
              <input
                required
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="input"
                id="password"
                style={{ width: '100%' }} // Ensure input fits within the parent
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className="user-label" style={{ fontFamily: 'Roboto', fontSize: '13px' }}>
                Password
              </label>

              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>
            <Box width="100%" display="flex" justifyContent="center">
              <LoadingButton
                onClick={loginUser}
                loading={loading}
                loadingIndicator="Logging in.."
                className="login-button"
                disableRipple
                sx={{
                  color: 'white',
                  background: 'linear-gradient(to right, #239bd7, #0463ae)',
                  textTransform: 'none',
                  borderRadius: 2,
                  fontSize: '12px',
                }}
              >
                <span>Log in</span>
              </LoadingButton>
            </Box>

            {/* <button onClick={() => googleLogin()}>Sign in with Google 🚀 </button> */}

            {!isAdmin && (
              <Box
                textAlign="center"
                gap="5px"
                mt="1.5rem"
                sx={{ color: colors.text, fontFamily: 'Roboto' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection={isMobilePhone ? 'column' : 'row'}
                mb="20px"
              >
                Don't have an account yet?
                <Typography
                  onClick={navigateToSignUp}
                  className="signup-link"
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: colors.primary,
                    fontFamily: 'Roboto',
                    textDecoration: 'underline',
                  }}
                >
                  Sign up
                </Typography>
              </Box>
            )}
          </div>
        </Box>
      </div>
      {window.innerWidth > 768 && (
        <Hero>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="80%"
            sx={{
              position: 'absolute',
              top: 170,
              '@media screen and (max-width: 1800px)': {
                top: 130,
              },
            }}
          ></Box>
        </Hero>
      )}
    </Container>
  );
};

export default LoginScreen;
const Hero = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-image: url(${process.env.PUBLIC_URL}/assets/Signup/login-bg.png);

  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-image: url(${process.env.PUBLIC_URL}/assets/Signup/BACKGROUND.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
  }
  @media (max-width: 600px) {
    background-size: 120%;
  }
  @media (max-width: 768px) {
    background-size: 130%;
  }
`;

const Container = styled.div`
  width: 100dvw;
  height: 100dvh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   height: 150vh;
  // }
`;
