import { ApiQuery } from 'core/model/interface';
import { ProgramEodReceiverModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'eod-receivers';

export const getReceivers = (program_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program/${program_id}`, {
    params: query,
  });
};

export const getReceiver = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createReceiver = (program_id: number, receiver: ProgramEodReceiverModel) => {
  return axios.post(`${baseURL}/${path}/${program_id}`, receiver);
};

export const updateReceiver = (id: number, receiver: ProgramEodReceiverModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, receiver);
};

export const deleteReceiver = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
