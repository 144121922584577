/* eslint-disable react-hooks/exhaustive-deps */
import { createCompanyTemplate, updateCompanyTemplate } from 'carego-admin/api/sms-templates';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { SMSTemplatesModel } from 'lgu/model/Entities';
import { template_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type TemplateFormProps = {
  companyID: number;
  template?: SMSTemplatesModel;
  setRefreshTable: (refresh: any) => void;
  setOpen: (openModal: boolean) => void;
};

const TemplateForm: React.FC<TemplateFormProps> = ({ companyID, template, setRefreshTable, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const templateFields: FieldInput[] = [
    { field_name: 'template_name', display_name: 'Template Name', type: 'text' },
    { field_name: 'template_code', display_name: 'Template Code', type: 'text', disabled: template ? true : false },
  ];

  const onSubmit = (data: any) => {
    setLoading(true);

    if (template) {
      updateCompanyTemplate(companyID, template.id, data)
        .then(() => {
          enqueueSnackbar('Template successfully updated!', { variant: 'success' });
          setRefreshTable((prev: number) => prev + 1);
          setLoading(false);
          setOpen(true);
        })
        .catch((error) => console.error(error));
    } else {
      createCompanyTemplate(companyID, data)
        .then(() => {
          enqueueSnackbar('Template successfully added!', { variant: 'success' });
          setRefreshTable((prev: number) => prev + 1);
          setLoading(false);
          setOpen(false);
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <CustomForm
      onSubmit={onSubmit}
      fields={templateFields}
      initialValues={
        template
          ? { template_name: template.template_name, template_code: template.template_code }
          : { template_name: '', template_code: '' }
      }
      schema={template_schema}
      loading={loading}
    />
  );
};

export default TemplateForm;
