import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { LguModel } from 'carego-admin/model/Entities';
import { createUserAccount } from 'carego-admin/api/lgu-user';
import { lgu_user_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type AddLguUserProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  lgu: LguModel;
};

export type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  username: string;
  password: string;
  user_group_id: number | string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const AddLguUser: React.FC<AddLguUserProps> = ({ open, setOpen, refreshTable, lgu }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    username: '',
    password: '',
    user_group_id: '',
  };

  const form_fields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (Optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    {
      field_name: 'user_group_id',
      display_name: 'User Type',
      type: 'select',
      options: [
        { key: 'LGU Admin', value: '1' },
        { key: 'LGU Healthworker', value: '2' },
      ],
    },
    { field_name: 'username', display_name: 'Username', type: 'text' },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'password_generator',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('password', value);
      },
    },
  ];

  const add = (data: any) => {
    setLoading(true);
    data['lgu_code'] = lgu.lgu_code;

    createUserAccount(data)
      .then((res) => {
        enqueueSnackbar('LGU User successfully added!', { variant: 'success' });
        setLoading(false);
        refreshTable();
        setOpen(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <CustomModal header={'Create LGU User Account'} open={open} setOpen={setOpen}>
      <CustomForm
        onSubmit={add}
        fields={form_fields}
        schema={lgu_user_schema}
        initialValues={initialValues}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AddLguUser;
