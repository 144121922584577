import AnnualPhysicalExam from 'company/screens/HealthAssessments/Summarized/AnnualPhysicalExam';
import { Box } from '@mui/material';
import CustomLocationSearchGroupDropdown from 'company/components/dropdown/CustomLocationSearchGroupDropdown';
import CustomTableForm from 'core/components/forms/CustomTableForm';

const Sandbox = () => {
  return (
    <Box>
      <CustomTableForm
        fixedRows
        columns={[
          { fieldName: 'service', displayName: 'Service', type: 'fixed_text' },
          { fieldName: 'left', displayName: 'Left' },
          { fieldName: 'right', displayName: 'Right' },
          {
            fieldName: 'yes',
            displayName: 'Right',
            type: 'select',
            options: [
              { key: 'YES', value: 'YES' },
              { key: 'NO', value: 'NO' },
            ],
          },
        ]}
        field={{}}
        initialValues={[
          {
            service: 'Service 1',
            left: 1,
            right: 1,
            yes: 'NO',
          },
        ]}
        handleChange={(rows: any[]) => undefined}
      />

      <CustomLocationSearchGroupDropdown />
    </Box>
  );
};

export default Sandbox;
