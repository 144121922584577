/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { SmsScheduleModal, WaitForFacility } from 'company/components';
import { calculateAge, changeNullToBlank, format24HourTime, formatDate } from 'core/utils';
import { getPatientsEnrolledInProgram, unenroll } from 'company/api/program-patients';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import BiotechIcon from '@mui/icons-material/Biotech';
import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EnrollToProgramReminderModal from '../../components/EnrollToProgramReminderModal';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { ProgramPatientModel } from 'company/model/Entities';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import TbEnrollModal from './components/TbEnrollModal';
import TbUpdateSputum from './components/TbUpdateSputum';
import { tb_dots_stages } from 'lgu/data/select-options';
import { tb_dots_steps_filter } from './data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const TbDotsReminders = () => {
  const program_code = window.location.pathname.split('/').pop() as string;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openEnrollModal, setOpenEnrollModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openSputumFormModal, setOpenSputumFormModal] = useState(false);

  const [alertContent, setAlertContent] = useState<string>('');
  const [patientID, setPatientID] = useState<number>();
  const [selectedPatient, setSelectedPatient] = useState<ProgramPatientModel>();
  const [selectedPatients, setSelectedPatients] = useState<ProgramPatientModel[]>([]);
  const [refreshUnenrolled, setRefreshUnenrolled] = useState(0);
  const [updateInitialValues, setUpdateInitialValues] = useState<any>();
  const [header, setHeader] = useState('Enroll');
  const [type, setType] = useState<'create' | 'update'>('create');

  //unenroll
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //stepper
  const [selectedStage, setSelectedStage] = useState<number>(0);
  const [refreshEnrolled, setRefreshEnrolled] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/company/patient-management/${params.row.patient_id}`} className="emphasis">
            {params.value}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'age',
      headerName: 'Age',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'start_datetime',
      headerName: 'Date Started',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
    },
    {
      field: 'sending_time',
      headerName: 'SMS Sending Time',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
    },
    {
      field: 'tb_status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => (params.row.tb_status === 'Intensive' ? 'Active' : params.row.tb_status),
    },
    {
      field: 'action',
      flex: 0.5,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const handleUpdatePatient = (data: ProgramPatientModel) => {
    const patient = changeNullToBlank(data);
    setHeader('Update');
    setType('update');
    setSelectedPatient(patient);
    setUpdateInitialValues({
      id: patient.id,
      start_datetime: patient.start_datetime,
      tb_status: patient.tb_status,
      sending_time: patient.sending_time?.slice(0, 5),
    });
    setOpenFormModal(true);
  };

  const showPatientSms = (data: any) => {
    setPatientID(data.id);
    setOpenSmsModal(true);
  };

  const showUnenrollPatient = (data: any) => {
    showUnenrollPatientDialog('Are you sure you want to unenroll ' + data.full_name + '?');
    setPatientID(data.id);
  };

  const showUnenrollPatientDialog = (content: string) => {
    setOpenPatientUnenrollDialog(true);
    setAlertContent(content);
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify([patientID]);
    const data = { patients: patientList };
    unenroll(facility.id, program_code, data)
      .then((res) => {
        enqueueSnackbar('Patient successfully unenrolled!', { variant: 'success' });
        getPatientsEnrolled({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const handleUpdatePatientSputum = (patient: ProgramPatientModel) => {
    setSelectedPatient(patient);
    setOpenSputumFormModal(true);
  };

  const actions = [
    { label: 'Update Patient', action: handleUpdatePatient, icon: <EditOutlinedIcon /> },
    { label: 'Update Sputum Schedules', action: handleUpdatePatientSputum, icon: <BiotechIcon /> },
    { label: 'View SMS Schedules', action: showPatientSms, icon: <SmsOutlinedIcon /> },
    { label: 'Unenroll Patient', action: showUnenrollPatient, icon: <PersonRemoveIcon /> },
  ];

  const getPatientsEnrolled = (query: ApiQuery) => {
    setLoading(true);
    query.stage = tb_dots_stages[selectedStage];
    getPatientsEnrolledInProgram(facility.id, program_code, { page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showEnrollmentModal = () => {
    setHeader('Enroll');
    setUpdateInitialValues(null);
    setOpenEnrollModal(true);
  };

  const refreshTable = () => {
    setRefreshEnrolled((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (!firstLoad) {
      setRefreshEnrolled((prev: number) => prev + 1);
    }
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (facility) {
      refreshTable();
    }
  }, [selectedStage]);

  return (
    <Box>
      <CustomContainer>
        <Typography variant="h4" component="span" color={colors.text} fontWeight="bold" sx={{ marginRight: '10px' }}>
          TB-DOTS
        </Typography>
        <Box width="80%" alignSelf="center">
          <CustomStepperNonLinear
            steps={tb_dots_steps_filter}
            activeStep={selectedStage}
            setActiveStep={setSelectedStage}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton onClick={() => showEnrollmentModal()} label="Enroll Patients" />

          <RegularButton
            onClick={() => {
              setOpenSmsModal(true);
              setPatientID(undefined);
            }}
            label="View SMS Schedules"
          />
          <SmsScheduleModal
            open={openSmsModal}
            setOpen={setOpenSmsModal}
            programCode={program_code}
            patientID={patientID}
          />
        </Box>
        <WaitForFacility facility={facility}>
          <CustomTable
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getPatientsEnrolled}
            forceRefresh={refreshEnrolled}
            showRefreshButton
          />
        </WaitForFacility>
      </CustomContainer>

      <CustomModal header={'Enroll Patients'} open={openEnrollModal} setOpen={setOpenEnrollModal} width={900}>
        <EnrollToProgramReminderModal
          programCode={program_code}
          setPatientToEnroll={setSelectedPatient}
          forceRefresh={refreshUnenrolled}
          setOpenEnrollmentFormModal={setOpenFormModal}
          getAllPatients
          setPatientsToEnroll={setSelectedPatients}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openPatientUnenrollDialog}
        setOpen={setOpenPatientUnenrollDialog}
        content={alertContent}
        onConfirm={handlePatientUnenroll}
      />

      <TbEnrollModal
        header={header}
        open={openFormModal}
        setOpen={setOpenFormModal}
        programCode={program_code}
        setRefreshUnenrolled={setRefreshUnenrolled}
        refreshTable={refreshTable}
        updateInitialValues={updateInitialValues}
        selectedPatient={selectedPatient}
        selectedPatients={selectedPatients}
        type={type}
      />

      <TbUpdateSputum
        open={openSputumFormModal}
        setOpen={setOpenSputumFormModal}
        refreshTable={refreshTable}
        selectedPatient={selectedPatient}
      />
    </Box>
  );
};

export default TbDotsReminders;
