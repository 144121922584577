/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { ProgramPatientModel, TbAcfCompanyPatientModel } from 'company/model/Entities';
import { SmsScheduleModal, WaitForFacility } from 'company/components';
import { calculateAge, changeNullToBlank } from 'core/utils';
import { getPatientsEnrolledInTbAcf, unenroll } from 'company/api/tb-acf';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EnrollToProgramReminderModal from '../../components/EnrollToProgramReminderModal';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import TbAcfEnrollModal from './components/TbAcfEnrollModal';
import UpdatePatientModal from './components/TBACF/UpdatePatientModal';
import { steps_filter } from './data/list';
import { tb_acf_stages_filter } from './data/steps';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const TbAcfReminders = () => {
  const program_code = 'tb-acf';
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openEnrollModal, setOpenEnrollModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);

  const [alertContent, setAlertContent] = useState<string>('');
  const [patientID, setPatientID] = useState<number>();
  const [selectedPatient, setSelectedPatient] = useState<ProgramPatientModel>();
  const [selectedPatients, setSelectedPatients] = useState<ProgramPatientModel[]>([]);
  const [refreshUnenrolled, setRefreshUnenrolled] = useState(0);
  const [updateInitialValues, setUpdateInitialValues] = useState<any>();
  const [header, setHeader] = useState('Enroll');
  const [type, setType] = useState<'create' | 'update'>('create');

  //unenroll
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //stepper
  const [selectedStage, setSelectedStage] = useState<number>(0);
  const [refreshEnrolled, setRefreshEnrolled] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  //update
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [selectedTbAcfPatient, setSelectedTbAcfPatient] = useState<TbAcfCompanyPatientModel>();

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/company/patient-management/${params.row.patient_id}`} className="emphasis">
            {params.value}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'age',
      headerName: 'Age',
      headerAlign: 'left',
      align: 'left',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'stage', headerName: 'Stage', flex: 1 },
    {
      field: 'patient_status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) =>
        params.row.enrolled_tb_dots ? <CustomGridCell> Enrolled </CustomGridCell> : params.row.patient_status,
    },
    {
      field: 'action',
      flex: 0.5,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const handleUpdatePatient = (patient: TbAcfCompanyPatientModel) => {
    setHeader('Update');
    setType('update');
    setSelectedTbAcfPatient(changeNullToBlank(patient));
    setOpenUpdateModal(true);
  };

  const showPatientSms = (data: any) => {
    setPatientID(data.company_program_patient_id);
    setOpenSmsModal(true);
  };

  const showUnenrollPatient = (data: any) => {
    showUnenrollPatientDialog('Are you sure you want to unenroll ' + data.full_name + '?');
    setPatientID(data.id);
  };

  const showUnenrollPatientDialog = (content: string) => {
    setOpenPatientUnenrollDialog(true);
    setAlertContent(content);
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify([patientID]);
    const data = { patients: patientList };
    unenroll(facility.id, data)
      .then((res) => {
        enqueueSnackbar('Patient successfully unenrolled!', { variant: 'success' });
        getPatientsEnrolled({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const actions = [
    { label: 'Update Patient', action: handleUpdatePatient, icon: <EditOutlinedIcon /> },
    { label: 'View SMS Schedules', action: showPatientSms, icon: <SmsOutlinedIcon /> },
    { label: 'Unenroll Patient', action: showUnenrollPatient, icon: <PersonRemoveIcon /> },
  ];

  const getPatientsEnrolled = (query: ApiQuery) => {
    setLoading(true);
    query.stage = tb_acf_stages_filter[selectedStage];
    getPatientsEnrolledInTbAcf(facility.id, { page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showEnrollmentModal = () => {
    setHeader('Enroll');
    setUpdateInitialValues(null);
    setOpenEnrollModal(true);
  };

  const refreshTable = () => {
    setRefreshEnrolled((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (!firstLoad) setRefreshEnrolled((prev: number) => prev + 1);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (facility) refreshTable();
  }, [selectedStage]);

  useEffect(() => {
    if (!openUpdateModal) setSelectedTbAcfPatient(undefined);
  }, [openUpdateModal]);

  return (
    <Box>
      <CustomContainer>
        {/* <Header title="TB-ACF Reminders" /> */}
        <Typography variant="h4" component="span" color={colors.text} fontWeight="bold" sx={{ marginRight: '10px' }}>
          TB ACF
        </Typography>
        <Box width="80%" alignSelf="center">
          <CustomStepperNonLinear steps={steps_filter} activeStep={selectedStage} setActiveStep={setSelectedStage} />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton onClick={() => showEnrollmentModal()} label="Enroll Patients" />

          <RegularButton
            onClick={() => {
              setOpenSmsModal(true);
              setPatientID(undefined);
            }}
            label="View SMS Schedules"
          />
          <SmsScheduleModal
            open={openSmsModal}
            setOpen={setOpenSmsModal}
            programCode={program_code}
            patientID={patientID}
          />
        </Box>
        <WaitForFacility facility={facility}>
          <CustomTable
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getPatientsEnrolled}
            forceRefresh={refreshEnrolled}
            showRefreshButton
          />
        </WaitForFacility>
      </CustomContainer>

      <CustomModal header={'Enroll Patients'} open={openEnrollModal} setOpen={setOpenEnrollModal} width={900}>
        <EnrollToProgramReminderModal
          programCode={program_code}
          setPatientToEnroll={setSelectedPatient}
          forceRefresh={refreshUnenrolled}
          setOpenEnrollmentFormModal={setOpenFormModal}
          setPatientsToEnroll={setSelectedPatients}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openPatientUnenrollDialog}
        setOpen={setOpenPatientUnenrollDialog}
        content={alertContent}
        onConfirm={handlePatientUnenroll}
      />

      <TbAcfEnrollModal
        header={header}
        open={openFormModal}
        setOpen={setOpenFormModal}
        programCode={program_code}
        setRefreshUnenrolled={setRefreshUnenrolled}
        refreshTable={refreshTable}
        updateInitialValues={updateInitialValues}
        selectedPatient={selectedPatient}
        selectedPatients={selectedPatients}
        type={type}
      />

      {selectedTbAcfPatient && (
        <UpdatePatientModal
          patient={selectedTbAcfPatient}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
          refreshTable={refreshTable}
        />
      )}
    </Box>
  );
};

export default TbAcfReminders;
