import { Box, Typography } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { tb_dots_schema, update_sputum_test_schedules_schema } from 'lgu/model/schema';
import { useEffect, useState } from 'react';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import { tranformFormErrors } from 'core/utils';
import { updateProgramRemindersPatient } from 'lgu/api/program-reminders-patients';
import { useSnackbar } from 'notistack';

type TbUpdateModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  patient: any;
  updateType: string;
  selectedPatientIDs?: any[];
  refreshTable: () => void;
};

export type UserInput = {
  date_started: string;
  sending_time: string;
  tb_status: string;
  tb_refill_interval: number;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

type SputumSchedules = {
  sputum_test_schedule1: string;
  sputum_test_schedule2: string;
  sputum_test_schedule3: string;
  enable_sputum_2: boolean;
  enable_sputum_3: boolean;
};

interface SputumForm extends FieldInput {
  field_name: keyof SputumSchedules;
}

const TbUpdateModal: React.FC<TbUpdateModalProps> = ({
  header,
  open,
  setOpen,
  patient,
  updateType,
  selectedPatientIDs,
  refreshTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [enableSputum2, setEnableSputum2] = useState<boolean>(true);
  const [enableSputum3, setEnableSputum3] = useState<boolean>(true);

  const sputumInitialValues: SputumSchedules = {
    sputum_test_schedule1: patient ? patient.tb_sputum_schedule1 : '',
    sputum_test_schedule2: patient ? patient.tb_sputum_schedule2 : '',
    sputum_test_schedule3: patient ? patient.tb_sputum_schedule3 : '',
    enable_sputum_2: patient && patient.tb_sputum_schedule2 ? true : false,
    enable_sputum_3: patient && patient.tb_sputum_schedule3 ? true : false,
  };

  const initialValues: UserInput = {
    tb_status: patient ? patient.tb_status : '',
    date_started: patient ? patient.date_started : '',
    sending_time: patient ? patient.sending_time.slice(0, 5) : '',
    tb_refill_interval: patient ? patient.tb_refill_interval : '',
  };

  const tbDotsFields: UserFieldInput[] = [
    {
      field_name: 'tb_status',
      display_name: 'Status',
      type: 'select',
      options: [
        { key: 'Active', value: 'Intensive' },
        { key: 'Treated', value: 'Treated' },
        { key: 'Rifampicin-resistant', value: 'Rifampicin-resistant' },
      ],
    },
    { field_name: 'date_started', display_name: 'Patient Start Date', type: 'date' },
    { field_name: 'sending_time', display_name: 'SMS Reminder Time', type: 'time', minutesStep: 5 },
    { field_name: 'tb_refill_interval', display_name: 'Refill Interval (Days)', type: 'number' },
  ];

  const tbDotsSputumFields: SputumForm[] = [
    { field_name: 'sputum_test_schedule1', display_name: 'Week 8', type: 'datetime', minutesStep: 5, span: 2 },
    {
      field_name: 'enable_sputum_2',
      type: 'component',
      component: <Box> </Box>,
    },
    {
      field_name: 'sputum_test_schedule2',
      display_name: 'Week 20',
      type: 'datetime',
      minutesStep: 5,
      disabled: !enableSputum2,
      span: 2,
    },
    {
      field_name: 'enable_sputum_2',
      display_name: 'Enable Week 20',
      type: 'checkbox',
      span: 4,
      onChange: () => {
        setEnableSputum2(!enableSputum2);
      },
    },
    {
      field_name: 'sputum_test_schedule3',
      display_name: 'Week 24',
      type: 'datetime',
      minutesStep: 5,
      disabled: !enableSputum3,
      span: 2,
    },
    {
      field_name: 'enable_sputum_3',
      display_name: 'Enable Week 24',
      type: 'checkbox',
      span: 4,
      onChange: () => {
        setEnableSputum3(!enableSputum3);
      },
    },
  ];

  const handleUpdatePatientSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const patientList = JSON.stringify(patient ? [patient.id] : selectedPatientIDs);

    const date_started = dayjs(data.date_started);

    const patientData = {
      patients: patientList,
      date_started: data.date_started,
      sending_time: data.sending_time,
      tb_status: data.tb_status,
      tb_refill_interval: data.tb_refill_interval,
      tb_sputum_schedule1: dayjs(date_started.add(8, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule2: dayjs(date_started.add(20, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule3: dayjs(date_started.add(24, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
    };

    updateProgramRemindersPatient('tb_dots', patientData)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const handleUpdateSputumSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const patientList = JSON.stringify(patient ? [patient.id] : selectedPatientIDs);

    const patientData = {
      patients: patientList,
      tb_status: patient.tb_status,
      tb_refill_interval: patient.tb_refill_interval,
      date_started: patient.date_started,
      sending_time: patient.sending_time.slice(0, 5),
      tb_sputum_schedule1: data.sputum_test_schedule1,
      tb_sputum_schedule2: enableSputum2 ? data.sputum_test_schedule2 : null,
      tb_sputum_schedule3: enableSputum3 ? data.sputum_test_schedule3 : null,
    };

    updateProgramRemindersPatient('tb_dots', patientData)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(
        updateType === 'update-patient' ? `Patient successfully updated!` : `Sputum Schedules successfully updated!`,
        { variant: 'success' }
      );
      refreshTable();
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (patient) {
      if (patient.tb_sputum_schedule2) {
        setEnableSputum2(true);
      } else {
        setEnableSputum2(false);
      }

      if (patient.tb_sputum_schedule3) {
        setEnableSputum3(true);
      } else {
        setEnableSputum3(false);
      }
    }
  }, [patient]);

  return (
    <>
      <CustomModal
        header={updateType === 'update-patient' ? header : 'Update Sputum Schedules'}
        open={open}
        setOpen={setOpen}
        width={500}
      >
        <Box marginBottom={'20px'}>
          <Typography variant="h4">{patient ? patient.full_name : ''}</Typography>
        </Box>
        {updateType === 'update-patient' ? (
          <CustomForm
            initialValues={initialValues}
            onSubmit={handleUpdatePatientSubmit}
            fields={tbDotsFields}
            schema={tb_dots_schema}
            loading={buttonLoading}
          />
        ) : (
          <CustomForm
            initialValues={sputumInitialValues}
            onSubmit={handleUpdateSputumSubmit}
            fields={tbDotsSputumFields}
            schema={update_sputum_test_schedules_schema}
            loading={buttonLoading}
          />
        )}
      </CustomModal>
    </>
  );
};

export default TbUpdateModal;
