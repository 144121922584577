/* eslint-disable react-hooks/exhaustive-deps */

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import { CustomModal } from 'core/components';
import { FacilityModel } from 'core/model/Entities';
import PatientNoteTemplatesTable from 'company/entities/modules/ClinicManagement/PatientNoteTemplate/PatientNoteTemplateTable';
import { createServiceNote } from 'company/api/service-notes';
import { getTemplatesNotAdded } from 'company/api/note-templates';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';

type ServiceNoteModalProps = {
  open: boolean;
  serviceId: number;
  facility: FacilityModel;
  setOpen: (open: boolean) => void;
  drawerRefModal: any;
};

const ServiceNoteModal: React.FC<ServiceNoteModalProps> = ({ open, serviceId, facility, setOpen, drawerRefModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const tableRef: any = useRef();

  const getTemplatesData = async (query?: ApiQuery) => {
    if (serviceId) {
      query = {
        ...query,
        service_id: serviceId,
      };
    }
    return getTemplatesNotAdded(query);
  };

  const addServiceNote = async (note: number) => {
    try {
      await createServiceNote({ service_id: serviceId, note_template_id: note });
      enqueueSnackbar(`Note successfully added!`, {
        variant: 'success',
      });
      drawerRefModal.current.refreshTable();
      tableRef.current.refreshTable();
    } catch {
      enqueueSnackbar(`Error occured! Unable to add note.`, {
        variant: 'error',
      });
    }
  };

  return (
    <CustomModal header={`Add Patient Note Template`} open={open} setOpen={setOpen} width={800}>
      <PatientNoteTemplatesTable
        tableRef={tableRef}
        hideAddUpdateDeleteAction
        overrideGetData={(query) => getTemplatesData(query)}
        tableComponent={{ rowHeight: 45 }}
        tableAction={{
          rowActions: [
            {
              label: 'Add Note',
              action: (data) => addServiceNote(data.id),
              icon: <AddIcon />,
            },
          ],
        }}
      />
    </CustomModal>
  );
};

export default ServiceNoteModal;
