/* eslint-disable react-hooks/exhaustive-deps */

import 'react-pro-sidebar/dist/css/styles.css';

import CustomSideBar, { APP_MODULE, MenuItemProps } from './CustomSideBar';
import { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LaptopIcon from '@mui/icons-material/Laptop';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import { UserContext } from 'core/context/user.context';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { isDevMode } from 'core/components/HideOrShowComponent';

const lgu_menu_items: MenuItemProps[] = [
  {
    section_name: '',
    subitems: [
      {
        title: 'Home',
        link: '/lgu',
        icon: <HomeOutlinedIcon />,
      },
      {
        title: 'Staff Management',
        link: '/lgu/user-management',
        icon: <PeopleOutlinedIcon />,
      },
      {
        title: 'Patient Management',
        link: '/lgu/patient-management',
        icon: <PostAddIcon />,
      },
    ],
  },
  {
    section_name: 'Programs',
    subitems: [
      {
        title: 'General Health Programs',
        link: '/lgu/programs',
        icon: <HealthAndSafetyOutlinedIcon />,
      },
      {
        title: 'OCP',
        link: '/lgu/ocp',
        icon: <Diversity1OutlinedIcon />,
      },
      {
        title: 'TB-DOTS',
        link: '/lgu/tb-dots',
        icon: <MedicationOutlinedIcon />,
      },
    ],
  },
];

const carego_menu_items: MenuItemProps[] = [
  {
    section_name: '',
    subitems: [
      {
        title: 'Home',
        link: '/admin',
        icon: <HomeOutlinedIcon />,
      },
    ],
  },
  {
    section_name: 'Company',
    subitems: [
      {
        title: 'CareGo SMS Templates',
        link: '/admin/carego-sms',
        icon: <SmsOutlinedIcon />,
      },
      {
        title: 'CareGo Email Templates',
        link: '/admin/carego-email',
        icon: <MailOutlineIcon />,
      },
      {
        title: 'Company Management',
        link: '/admin/client',
        icon: <PeopleOutlinedIcon />,
      },
      {
        title: 'SMS Credits',
        link: '/admin/sms-credits',
        icon: <SmsOutlinedIcon />,
      },
      {
        title: 'Note Templates',
        link: '/admin/templates',
        icon: <NotesOutlinedIcon />,
      },
    ],
  },
  {
    section_name: 'LGU',
    subitems: [
      {
        title: 'LGU Management',
        link: '/admin/lgu',
        icon: <PeopleOutlinedIcon />,
      },
      {
        title: 'LGU Health Programs',
        link: '/admin/programs',
        icon: <HealthAndSafetyOutlinedIcon />,
      },

      {
        title: 'LGU SMS',
        link: '/admin/lgu-sms',
        icon: <SmsOutlinedIcon />,
      },
    ],
  },
  {
    section_name: 'Billing',
    subitems: [
      {
        title: 'Products / Services',
        link: '/admin/billing-services',
        icon: <MiscellaneousServicesOutlinedIcon />,
      },
      {
        title: 'Invoices',
        link: '/admin/billing-invoices',
        icon: <ReceiptOutlinedIcon />,
      },
    ],
  },
  {
    section_name: 'Security',
    subitems: [
      {
        title: 'Security Dashboard',
        link: '/admin/security/dashboard',
        icon: <PrivacyTipIcon />,
      },
      {
        title: 'System Logs',
        link: '/admin/security/logs',
        icon: <StorageIcon />,
      },
      {
        title: 'Flagged Requests',
        link: '/admin/security/flagged-requests',
        icon: <FlagIcon />,
      },
      {
        title: 'Blocked IPs',
        link: '/admin/security/blocked-ips',
        icon: <VpnLockIcon />,
      },
    ],
  },
];

const Sidebar = () => {
  const { user } = useContext(UserContext);

  const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);

  const company_menu_items: MenuItemProps[] = [
    {
      section_name: '',
      subitems: [
        {
          title: 'Quick Start Guide',
          link: '/company/onboarding',
          icon: <AutoStoriesOutlinedIcon />,
          // hidden: user?.company?.onboarding_done,
          hidden: true,
        },
        {
          title: 'Home',
          link: '/company',
          icon: <HomeOutlinedIcon />,
          hidden: user?.user_group.group_name !== 'COMPANY_ADMIN' && user?.user_group.group_name !== 'COMPANY_DOCTOR',
        },
        {
          title: 'Dashboard',
          link: '/company/dashboard',
          icon: <EqualizerOutlinedIcon />,
          hidden: !isDevMode(),
          // hidden: user?.user_group.group_name !== 'COMPANY_ADMIN' && user?.user_group.group_name !== 'COMPANY_DOCTOR',
        },

        {
          title: 'Patient Management',
          link: '/company/patient-management',
          icon: <PostAddIcon />,
          id: 'sidebar-client-registration',
        },
        {
          title: 'Patient Records',
          link: '/company/patient-records',
          icon: <FolderSharedOutlinedIcon />,
        },
        {
          title: 'Appointments',
          link: '/company/appointments',
          icon: <CalendarMonthOutlinedIcon />,
          id: 'sidebar-appointments',
        },
        {
          title: 'Health Assessments',
          link: '/company/health-assessments',
          icon: <MedicalInformationOutlinedIcon />,
        },
      ],
    },
    {
      section_name: 'Transactions',
      hidden: user?.user_group.group_name === 'COMPANY_DOCTOR',
      subitems: [
        {
          title: 'Patient Transactions',
          link: '/company/transactions',
          icon: <ReceiptOutlinedIcon />,
          id: 'sidebar-client-billing',
          hidden: user?.user_group.group_name === 'COMPANY_DOCTOR',
        },
        {
          title: 'POS',
          link: '/company/pos',
          icon: <PointOfSaleOutlinedIcon />,
          hidden: !user?.authorities?.includes('USE_POS'),
        },
      ],
    },
    {
      section_name: 'Reminders',
      hidden: !user?.company?.with_ocp_reminders || !user?.company?.with_injectable_reminders,
      subitems: [
        {
          title: 'OCP Reminders',
          link: '/company/program/ocp',
          icon: <NotificationsActiveOutlinedIcon />,
          id: 'sidebar-client-billing',
          hidden: !user?.company?.with_ocp_reminders,
        },
        {
          title: 'Injectables',
          link: '/company/program/injectables',
          icon: <VaccinesOutlinedIcon />,
          hidden: !user?.company?.with_injectable_reminders,
        },
      ],
    },
    {
      section_name: 'Dev Only',
      hidden: !isDevMode(),
      subitems: [
        {
          title: 'Campaigns',
          link: '/company/campaigns',
          icon: <CampaignOutlinedIcon />,
          id: 'sidebar-campaigns',
        },
        {
          title: 'Bookings',
          link: '/company/bookings',
          icon: <MedicalServicesOutlinedIcon />,
          id: 'sidebar-package',
        },
        {
          title: 'Sandbox',
          link: '/company/sandbox',
          icon: <LaptopIcon />,
        },
      ],
    },
    {
      section_name: '',
      subitems: [
        {
          title: 'Clinic Settings',
          link: '/company/clinic-management',
          icon: <SettingsOutlinedIcon />,
          id: 'sidebar-clinic-management',
          hidden: !user?.company?.is_active || user?.user_group.group_name !== 'COMPANY_ADMIN',
        },
      ],
    },
  ];

  // {
  //   title: 'Corporate Clients',
  //   link: '/company/corporate-clients',
  //   icon: <CorporateFareIcon />,
  //   id: 'sidebar-client-corporate',
  // },

  // {
  //   section_name: 'Booking Platform',
  //   hidden: !user?.company?.with_booking_platform,
  //   subitems: [
  //     {
  //       title: 'Promotions',
  //       link: '/company/promos',
  //       icon: <DiscountOutlinedIcon />,
  //       hidden: user?.user_group.group_name === 'COMPANY_DOCTOR',
  //     },
  //     {
  //       title: 'Bookings',
  //       link: '/company/bookings',
  //       icon: <InsertInvitationIcon />,
  //       id: 'sidebar-bookings',
  //     },
  //     {
  //       title: 'Patient Feedback',
  //       link: '/company/client-feedback',
  //       icon: <FeedbackOutlinedIcon />,
  //       hidden: user?.user_group.group_name === 'COMPANY_DOCTOR',
  //     },
  //   ],
  // },

  useEffect(() => {
    if (user) {
      setMenuItems(
        user.account_type === AccountType.CAREGO
          ? carego_menu_items
          : user.account_type === AccountType.COMPANY
          ? company_menu_items
          : lgu_menu_items
      );
    }
  }, [user]);

  return (
    <CustomSideBar
      menuItems={menuItems}
      module={APP_MODULE.CLINIC_MANAGEMENT}
      isCaregoAdmin={user?.account_type === AccountType.CAREGO}
    />
  );
};

export default Sidebar;
