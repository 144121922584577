/* eslint-disable react-hooks/exhaustive-deps */

import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { useEffect, useState } from 'react';

import AddTable from '../AddModal';
import { ApiQuery } from 'core/model/interface';
import { Box, useMediaQuery } from '@mui/material';
import { CompanyFacilityModel } from 'company/model/Entities';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import WeeklySchedule from '../WeeklySchedule';
import { getDoctorsAssigned } from 'company/api/doctors';
import { removeUsersFromClinic } from 'company/api/users';
import { useSnackbar } from 'notistack';

type DoctorsAssignedProps = {
  clinic: CompanyFacilityModel;
};

const DoctorsAssigned: React.FC<DoctorsAssignedProps> = ({ clinic }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<any>();
  const [disabled, setDisabled] = useState<any>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>{params.row.title ? params.row.full_name_with_title : params.row.full_name}</CustomGridCell>
      ),
    },
    {
      field: 'contact_numbers',
      headerName: 'Contact Numbers',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.mobile_number_1}
          {params.row.mobile_number_2 ? '・' + params.row.mobile_number_2 : ''}
        </CustomGridCell>
      ),
    },
    { field: 'email', headerName: 'Email', flex: 1 },
  ];

  const getDoctorList = (query: ApiQuery) => {
    return getDoctorsAssigned(clinic.id, query);
  };

  const handleDoctorDelete = (data: any) => {
    const doctorList = JSON.stringify(data ? [data] : selectedRows);
    const doctors = { users: doctorList };

    return removeUsersFromClinic(clinic.id, doctors).then(() => {
      setForceRefresh((prev: number) => prev + 1);
    });
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const setCustomSchedule = async (data: any) => {
    setOpenScheduleModal(true);
    setDoctor(data);
    setDisabled(!!!data.schedule);
  };

  useEffect(() => {
    refreshTable();
  }, [clinic]);

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Doctor',
    useColoredTable: isMobilePhone,
    tableComponent: {
      columns: columns,
      getData: getDoctorList,
      forceRefresh: forceRefresh,
      checkboxSelection: true,
      hideAddSelectedButton: isMobilePhone,
      selectableRow: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          {!isMobilePhone && (
            <PrimaryButton
              onClick={() => {
                setOpenAddModal(true);
              }}
              label="Add Service Provider"
            />
          )}
          {!isMobilePhone && (
            <RegularButton
              onClick={() => {
                if (selectedRows.length) {
                  setOpenDeleteDialog(true);
                  setAlertContent(
                    'Are you sure you want to remove ' +
                      selectedRows.length +
                      ' service provider/s from ' +
                      clinic?.facility_name +
                      '?'
                  );
                } else {
                  enqueueSnackbar('Invalid action. Select service providers first!', { variant: 'error' });
                }
              }}
              label="Remove Service Providers"
              disabled={!selectedRows.length}
              startIcon={<RemoveIcon />}
              color="error"
            />
          )}
        </Box>
      ),
    },
    tableAction: !isMobilePhone
      ? {
          includeDeleteAction: true,
          deleteButtonLabel: 'Remove',
          deleteApiFunction: handleDoctorDelete,
          alertCustomObjectColumn: 'full_name',
          getRow: setDoctor,
          rowActions: [{ label: 'Update Schedule', action: setCustomSchedule, icon: <DateRangeIcon /> }],
        }
      : {},
  } as PageBuilderTableType;

  return (
    <Box width="100%">
      <PageBuilder content={content} />

      <CustomModal width={900} header={'Add Service Providers'} open={openAddModal} setOpen={setOpenAddModal}>
        <AddTable entity={'Service Provider'} clinic={clinic} setRefreshMainTable={setForceRefresh} />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={handleDoctorDelete}
        confirmButtonColor={false}
      />

      <CustomModal
        header={'Update Schedule'}
        subHeader={doctor?.full_name_with_title ? doctor?.full_name_with_title : doctor?.full_name}
        open={openScheduleModal}
        setOpen={setOpenScheduleModal}
        width={800}
      >
        <Box>
          {doctor && (
            <Box display={'flex'} gap="2px" alignItems={'center'}>
              <CustomCheckbox
                label={'Use Clinic Schedule'}
                value={disabled}
                handleChange={() => {
                  setDisabled((prev: boolean) => !prev);
                }}
                fieldName={''}
              />
            </Box>
          )}
        </Box>
        {doctor && (
          <WeeklySchedule
            schedule={doctor.schedule}
            id={doctor.assignment_id}
            type="doctor"
            refreshTable={refreshTable}
            disabled={disabled}
            clinic_id={doctor.facility_id}
          />
        )}
      </CustomModal>
    </Box>
  );
};

export default DoctorsAssigned;
