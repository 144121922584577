import { PG_ENTITY_NAME, patientGroupFields, patientGroupTableColumns } from './PatientGroupModel';
import React, { forwardRef, useContext, useRef, useState } from 'react';
import { deleteCompanyTag, getCompanyTags } from 'company/api/company-tags';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import { FacilityContext } from 'core/context/facility.context';
import PatientGroupForm from './PatientGroupForm';
import PatientTable from '../Patient/PatientTable';
import TemplateTable from '../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  hideAddButton?: boolean;
  tableRef?: any;
};

const PatientGroupTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef, hideAddButton = false } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const getData = (query: ApiQuery) => {
    if (facility) return getCompanyTags(facility.id, query);
    return getCompanyTags(1, query);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleDelete = (data: any) => {
    const tagList = JSON.stringify([data]);
    const tags = { tags: tagList };
    if (facility) return deleteCompanyTag(facility.id, tags);
  };

  return (
    <React.Fragment>
      <TemplateTable
        ref={mainRef}
        entityName={PG_ENTITY_NAME}
        fields={patientGroupFields}
        visibleColumns={visibleColumns ?? patientGroupTableColumns}
        templateForm={
          <WaitForFacility facility={facility}>
            <PatientGroupForm pGroup={selectedRow} facility={facility!} callbackAfterSubmit={callbackAfterSubmit} />
          </WaitForFacility>
        }
        getData={getData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        hideAddButton={hideAddButton}
        tableComponent={{
          mobileViewConfig: () => ({
            title_fields: ['tag_name'],
          }),
          mobileViewDetailsDisplay: (data, actions) => {
            return (
              <PatientTable
                groupId={data && data.id}
                visibleColumns={['full_name', 'mobile_number', 'birthday', 'age', 'sex', 'address', 'created_at']}
                hideActions
              />
            );
          },
        }}
      />
      {/* Render PatientTable if showPatientTable state is true */}
    </React.Fragment>
  );
});

export default PatientGroupTable;
