import { CustomGridCell, CustomSwitch, Header } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { chat, send } from 'core/icons';
import { formatDateTime, formatNumber } from 'core/utils';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import ViewContent from 'core/components/modals/ViewContent';
import { WaitForFacility } from 'company/components';
import { getAllProgramSms } from 'company/api/sms';
import { previewSms } from 'company/api/sms-template';
import { useState } from 'react';

type ClientSmsSchedulesProps = {
  facility: any;
};

const ClientSmsSchedules: React.FC<ClientSmsSchedulesProps> = ({ facility }) => {
  const [viewSent, setViewSent] = useState<boolean>(false);
  const [otherFilter, setOtherFilter] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openSmsPreview, setOpenSmsPreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [clearSelected, setClearSelected] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewSent(event.target.checked);
    setOtherFilter({ ...otherFilter, get_completed: event.target.checked ? 1 : undefined });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Appt/Cycle ID',
      flex: 0.7,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.program_patient_id ? params.row.program_patient_id : params.row.appointment_id}
        </CustomGridCell>
      ),
    },
    {
      field: facility?.sensitive_data ? 'uic' : 'full_name',
      headerName: facility?.sensitive_data ? 'UIC' : 'Patient',
      flex: facility?.sensitive_data ? 1 : 1.2,
      sortable: false,
    },
    {
      field: facility?.sensitive_data ? 'encrypted_mobile_number' : 'mobile_number',
      headerName: 'Mobile Number',
      flex: 1,
      sortable: false,
    },
    { field: 'sms_template', headerName: 'SMS Template', flex: 1.2, sortable: false },
    {
      field: 'send_date_time',
      headerName: 'Sending Schedule',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.send_date_time)}</CustomGridCell>,
    },
    { field: 'program_name', headerName: 'Program', flex: 1 },
    {
      field: 'credits_deducted',
      headerName: 'Credits Used',
      flex: 0.7,
      sortable: false,
      renderCell: (params) => <CustomGridCell>{formatNumber(params.row.credits_deducted)}</CustomGridCell>,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: (params) => (
        <CustomGridCell>{params.row.status === 'Completed' ? 'Sent' : params.row.status}</CustomGridCell>
      ),
    },
  ];

  const getSmsSchedules = async (query: ApiQuery) => {
    if (facility) {
      if (viewSent) query.get_completed = 1;
      return getAllProgramSms(facility?.id, query);
    }
  };

  const handleSelectRow = async (selected: any) => {
    setSelectedRow(selected);
    if (selected) {
      const preview = await previewSms(facility?.id, selected.id, null);
      setPreviewContent(preview.data.template_body);
      setOpenSmsPreview(true);
    }
  };

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: [
      {
        type: BLOCK_TYPE.TABLE,
        tableComponent: {
          columns: columns,
          getData: getSmsSchedules,
          selectableRow: true,
          onSelectRow: handleSelectRow,
          otherFilter: otherFilter,
          clearSelected: clearSelected,
          rowHeight: 35,
          headerComponent: (
            <Box display="block">
              <Header title="Patient SMS Schedules" mb="15px" />
              <CustomSwitch
                label1="Pending"
                label2="Sent"
                image1={send}
                image2={chat}
                value={viewSent}
                handleChange={handleChange}
              />
            </Box>
          ),
        },
      } as PageBuilderTableType,
    ],
  } as PageBuilderPageType;

  return (
    <WaitForFacility facility={facility}>
      <PageBuilder content={content} />

      <ViewContent
        content={previewContent}
        open={openSmsPreview}
        setOpen={setOpenSmsPreview}
        schedule={selectedRow}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      />
    </WaitForFacility>
  );
};

export default ClientSmsSchedules;
