import { EXPENSE_ENTITY_NAME, ExpenseModel } from './ExpenseModel';
import { ReactNode, forwardRef, useRef, useState } from 'react';

import ExpenseLineItem from '../ExpenseLineItem/ExpenseLineItem';
import ExpenseLineItemTable from '../ExpenseLineItem/ExpenseLineItemTable';
import TemplateDrawer from 'company/entities/Template/TemplateDrawer';
import { formatNumber } from 'core/utils';

type Props = {
  childComponent?: ReactNode;
  setDrawerSelectedRow?: any;
  hideHeader?: boolean;
};

const ExpenseDrawer = forwardRef((props: Props, ref) => {
  const { childComponent, setDrawerSelectedRow, hideHeader } = props;
  const [selectedRow, setSelectedRow] = useState<ExpenseModel>();
  const tableRef: any = useRef();

  const handleDisplayChildSubHeader = (data: ExpenseModel) => {
    return "Total Amount: Php " + formatNumber(data.total_amount).toString();
  };
  
  return (
    <TemplateDrawer
      entityName={EXPENSE_ENTITY_NAME}
      internalSetDrawerSelectedRow={setSelectedRow}
      externalSetDrawerSelectedRow={setDrawerSelectedRow}
      mainColumn="payment_date"
      displayChildSubHeader={handleDisplayChildSubHeader}
      formatMainColumnAsDate
      tableRef={tableRef}
      childComponent={
        childComponent ?? (
          selectedRow && <ExpenseLineItemTable expense={selectedRow}/>
        )
      }
      hideHeader={hideHeader}
    />
  );
});

export default ExpenseDrawer;
