import { ApiQuery } from 'core/model/interface';
import { ProgramModel } from 'carego-admin/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-programs';

export const getPrograms = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createProgram = (program: ProgramModel) => {
  return axios.post(`${baseURL}/${path}`, program);
};

export const updateProgram = (program_id: number, program: ProgramModel) => {
  return axios.put(`${baseURL}/${path}/${program_id}`, program);
};

export const deleteProgram = (program_id: number) => {
  return axios.delete(`${baseURL}/${path}/${program_id}`);
};

export const getFrequency = () => {
  return axios.get(`${baseURL}/${path}/sms/frequency`);
};

export const getTemplates = () => {
  return axios.get(`${baseURL}/${path}/sms/templates`);
};

export const getProgramCategories = () => {
  return axios.get(`${baseURL}/${path}/programs/categories`);
};
