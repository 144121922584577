import {
  MEDICINE_ENTITY_NAME,
  MedicineModel,
  medicineFields,
  medicineFormFields,
  medicineFormInitialValues,
  medicineFormUpper,
  medicineSchema,
  updateMedicineSchema,
} from './MedicineModel';
import { createMedicine, updateMedicine } from 'company/api/medicines';

import TemplateForm from '../../../Template/TemplateForm';

type Props = {
  readonly?: boolean;
  medicine?: MedicineModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const MedicineForm: React.FC<Props> = (props) => {
  const { readonly, medicine, showSubmitAtTop, callbackAfterSubmit } = props;

  // const afterSuccessSubmit = async () => {
  //   if (instruction) await createMedicineInstruction(response.data.medicine.medicine_id, { instruction, duration });
  // }

  const handleSubmitApi = (data: any) => {
    return medicine ? updateMedicine(data.id, data) : createMedicine(data);
  };

  return (
    <TemplateForm
      entityName={MEDICINE_ENTITY_NAME}
      fields={medicineFields}
      schema={medicine ? updateMedicineSchema : medicineSchema}
      formSubmitApiFunction={handleSubmitApi}
      visibleFields={medicine ? medicineFormUpper : medicineFormFields}
      initialValues={medicine ? medicine : medicineFormInitialValues}
      callbackAfterSubmit={callbackAfterSubmit}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readonly}
      submitButtonId={'create_medicine_submit_button'}
    />
  );
};

export default MedicineForm;
