import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RegularButton } from 'core/components';

type NoActionDialogProps = {
  content: string;
  open: boolean;
  setOpen: (openDialog: boolean) => void;
  title?: string;
};

const NoActionDialog: React.FC<NoActionDialogProps> = ({ content, setOpen, open, title }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle sx={{ fontSize: '20px' }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '15px' }}>{content}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: '5px 10px' }}>
          <RegularButton onClick={handleClose} label="OK" />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NoActionDialog;
