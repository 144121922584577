import { ApiQuery } from 'core/model/interface';
import { FixedEodReceiverModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'eod-receivers-fixed';

export const getFixedReceivers = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getFixedReceiver = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createFixedReceiver = (receiver: FixedEodReceiverModel) => {
  return axios.post(`${baseURL}/${path}`, receiver);
};

export const updateFixedReceiver = (id: number, receiver: FixedEodReceiverModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, receiver);
};

export const deleteFixedReceiver = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
