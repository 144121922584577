import TemplateForm from 'company/entities/Template/TemplateForm';
import { create, update } from 'company/api/corporate-client-department';
import {
  CorporateClientDepartmentModel,
  corporateClientDepartmentInitialValues,
  departmentFields,
  visibleFormFields,
} from './CorporateClientDepartmentModel';
import { useContext } from 'react';
import { UserContext } from 'core/context/user.context';

type Props = {
  source?: 'dropdown';
  companyId?: number;
  corporateClientId?: number;
  corporateClientDepartment?: CorporateClientDepartmentModel;
  callbackAfterSubmit?: (data: CorporateClientDepartmentModel) => void;
};

const CorporateClientDepartmentForm: React.FC<Props> = ({
  source,
  companyId,
  corporateClientId,
  corporateClientDepartment,
  callbackAfterSubmit,
}) => {
  const { user } = useContext(UserContext);

  const handleFormSubmit = (data: CorporateClientDepartmentModel) => {
    return !corporateClientDepartment ? create(data) : update(corporateClientDepartment.id, data);
  };

  // first logic is for table selection
  // second logic is for custom form assigning
  const initialValues =
    corporateClientDepartment ?? (companyId && corporateClientId)
      ? { ...corporateClientDepartmentInitialValues, company_id: companyId, corporate_client_id: corporateClientId }
      : corporateClientDepartmentInitialValues;

  return (
    <TemplateForm
      entityName="Corporate Client Department"
      fields={departmentFields}
      entity={corporateClientDepartment}
      formSubmitApiFunction={handleFormSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      visibleFields={source === 'dropdown' ? visibleFormFields : ['department_name']}
      initialValues={
        source === 'dropdown' && user.company ? { ...initialValues, company_id: user.company.id } : initialValues
      }
    />
  );
};

export default CorporateClientDepartmentForm;
