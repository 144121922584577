import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import { CompanyModel } from 'company/model/Entities';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { company_user_schema } from 'carego-admin/model/schema';
import { createUserAccount } from 'carego-admin/api/company-user';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { userGroups } from '../data/list';

type AddCompanyUserProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  company: CompanyModel;
  facilities: any;
};

export type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  username: string;
  password: string;
  user_group_id: number | string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const AddCompanyUser: React.FC<AddCompanyUserProps> = ({ open, setOpen, refreshTable, company, facilities }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedFacilities, setSelectedFacilities] = useState<any[]>([]);

  const initialValues: UserInput = {
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    username: '',
    password: '',
    user_group_id: '',
  };

  const form_fields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (Optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    {
      field_name: 'user_group_id',
      display_name: 'User Type',
      type: 'select',
      // type: DROPDOWN_FIELD.USER_GROUP,
      //TODO: create api for carego admin
      options: userGroups.map((u: any) => {
        return { key: u.group_name, value: u.id };
      }),
    },
    { field_name: 'username', display_name: 'Username', type: 'text' },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'password_generator',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('password', value);
      },
    },
  ];

  const add = (data: any, formikHelpers: FormikHelpers<any>) => {
    setLoading(true);

    var assigned_facilities = selectedFacilities.map((facility) => facility.value.toString());

    if (assigned_facilities.length > 0) {
      data['company_id'] = company.id;
      data['assigned_facilities'] = JSON.stringify(assigned_facilities);

      createUserAccount(data)
        .then((res) => {
          enqueueSnackbar('Company User successfully added!', { variant: 'success' });

          refreshTable();
          setOpen(false);
        })
        .catch((error) => {
          if (error.response?.data.errors) {
            formikHelpers.setErrors(tranformFormErrors(error.response?.data.errors));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      enqueueSnackbar('No facilities found.', { variant: 'error' });
    }
  };

  const handleSelectFacility = (event: any, value: React.SetStateAction<any[]>) => {
    setSelectedFacilities(value);
  };

  const options = facilities.map((u: { facility_name: any; id: any }) => {
    return {
      key: u.facility_name,
      value: u.id,
    };
  });

  return (
    <CustomModal header={'Create Company User Account'} open={open} setOpen={setOpen}>
      <Box marginBottom={'20px'}>
        <CustomAutoCompleteMultipleSelect
          options={options}
          label={'Facilities'}
          fieldName={'Facilities'}
          handleChange={handleSelectFacility}
          value={selectedFacilities}
        />
      </Box>

      <CustomForm
        onSubmit={add}
        fields={form_fields}
        schema={company_user_schema}
        initialValues={initialValues}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AddCompanyUser;
