import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Health4: React.FC = () => {
  return (
    <Box>
      <Step>1. Go to the “Health Session” tab in sidebar </Step>
      <Step>2. Click “Enroll Patients” button </Step>

      <Image src="/assets/FaqAssets/healthpackage/4/1-2.png" alt="Step 1-2 Image" />

      <Step>3. Select the patient/s you want to enroll and click the “Enroll” button </Step>
      <Image src="/assets/FaqAssets/healthpackage/4/3.png" alt="Image" />
      <Step>4. Fill Out the form </Step>
      <Step>5.Click “Submit” button </Step>

      <Image src="/assets/FaqAssets/healthpackage/4/4-5.png" alt="Image" />
    </Box>
  );
};

export default Health4;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
