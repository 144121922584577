import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { ContainerRow } from '../containers';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

const SavingUpdates = forwardRef((props, ref) => {
  const [isSaving, setIsSaving] = useState(false);
  useImperativeHandle(ref, () => ({ setIsSaving }));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: isSaving ? 'block' : 'none',
        position: 'fixed',
        right: SIZES.padding,
        bottom: SIZES.padding,
        transition: 'display 0.5s',
        backgroundColor: colors.background,
        padding: '10px 20px',
        borderRadius: SIZES.borderRadiusS,
      }}
    >
      <ContainerRow gap="10px">
        <CircularProgress color="inherit" size="1.3rem" />
        <Typography fontWeight="500">Saving Updates ...</Typography>
      </ContainerRow>
    </Box>
  );
});

export default SavingUpdates;
