import {
  PC_ENTITY_NAME,
  productCategoryFields,
  productCategoryFormFields,
  productCategoryinitialValues,
  product_category_schema,
} from './ProductCategoryModel';
import { createProductCategory, updateProductCategory } from 'company/api/product-categories';

import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  productCategory?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const ProductCategoryForm = (props: Props) => {
  const { productCategory, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    return productCategory ? updateProductCategory(data.id, data) : createProductCategory(data);
  };

  return (
    <TemplateForm
      entityName={PC_ENTITY_NAME}
      fields={productCategoryFields}
      visibleFields={productCategoryFormFields}
      entity={productCategory}
      initialValues={productCategory ? changeNullToBlank(productCategory) : productCategoryinitialValues}
      schema={product_category_schema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ProductCategoryForm;
