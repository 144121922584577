//
import AppoinArticle1 from './Appointments/AppoinArticle1';
import AppoinArticle2 from './Appointments/AppoinArticle2';
import AppoinArticle3 from './Appointments/AppoinArticle3';
import AppoinArticle4 from './Appointments/AppoinArticle4';
import AppoinArticle5 from './Appointments/AppoinArticle5';
import AppoinArticle6 from './Appointments/AppoinArticle6';
import Article1 from './OCP/Article1';
import Article2 from './OCP/Article2';
import Article3 from './OCP/Article3';
import Article4 from './OCP/Article4';
import Article5 from './OCP/Article5';
import Article6 from './OCP/Article6';
import CampaignArticle1 from './Campaigns/CampaignArticle1';
import CampaignArticle2 from './Campaigns/CampaignArticle2';
import CampaignArticle3 from './Campaigns/CampaignArticle3';
import Health1 from './HealthPackage/Health1';
import Health2 from './HealthPackage/Health2';
import Health3 from './HealthPackage/Health3';
import Health4 from './HealthPackage/Health4';
import Health5 from './HealthPackage/Health5';
import IArticle1 from './Injectables/IArticle1';
import IArticle2 from './Injectables/IArticle2';
import IArticle3 from './Injectables/IArticle3';
import IArticle4 from './Injectables/IArticle4';
import IArticle5 from './Injectables/IArticle5';
import IArticle6 from './Injectables/IArticle6';
import Reg1 from './ClientRegister/Reg1';
import Reg2 from './ClientRegister/Reg2';
import Reg3 from './ClientRegister/Reg3';
import Reg4 from './ClientRegister/Reg4';

export const categories = [
  {
    category_name: 'Patient Management',
    category_code: 'registration',
    articles: [
      {
        article_title: 'How to Add patient',
        article_code: 'reg-add',
        content: <Reg1 />,
        prev: 'null',
        next: 'reg-update',
      },
      {
        article_title: 'How to Update patient',
        article_code: 'reg-update',
        content: <Reg2 />,
        prev: 'reg-add',
        next: 'reg-delete',
      },
      {
        article_title: 'How to Delete patient',
        article_code: 'reg-delete',
        content: <Reg3 />,
        prev: 'reg-update',
        next: 'reg-view',
      },
      {
        article_title: 'How to view the patient programs',
        article_code: 'reg-view',
        content: <Reg4 />,
        prev: 'reg-delete',
        next: 'null',
      },
    ],
  },
  {
    category_name: 'Campaigns',
    category_code: 'campaigns',
    articles: [
      {
        article_title: 'How to create campaigns?',
        article_code: 'create-campaign',
        content: <CampaignArticle1 />,
        prev: 'null',
        next: 'unenroll-patient',
      },
      {
        article_title: 'How to unenroll patients?',
        article_code: 'unenroll-patient',
        content: <CampaignArticle2 />,
        prev: 'create-campaign',
        next: 'edit-remove',
      },
      {
        article_title: 'How to edit/remove SMS Schedules?',
        article_code: 'edit-remove',
        content: <CampaignArticle3 />,
        prev: 'unenroll-patient',
        next: 'edit-remove',
      },
    ],
  },

  {
    category_name: 'Appointments',
    category_code: 'appointments',
    articles: [
      {
        article_title: 'How to schedule a registered patient?',
        article_code: 'schedule-registered',
        content: <AppoinArticle1 />,
        prev: 'null',
        next: 'schedule-not-registered',
      },
      {
        article_title: 'How to schedule a patient who has not yet registered?',
        article_code: 'schedule-not-registered',
        content: <AppoinArticle2 />,
        prev: 'schedule-registered',
        next: 'reschedule',
      },
      {
        article_title: 'How to reschedule a patient?',
        article_code: 'reschedule-patient',
        content: <AppoinArticle3 />,
        prev: 'schedule-not-registered',
        next: 'cancel-appointment',
      },
      {
        article_title: 'How to cancel appointment?',
        article_code: 'cancel-appointment',
        content: <AppoinArticle4 />,
        prev: 'schedule-not-registered',
        next: 'cancel-appointment',
      },
      {
        article_title: 'How to update  appointment status of patient? (Attended, No Show)',
        article_code: 'update-appointment',
        content: <AppoinArticle5 />,
        prev: 'cancel-appointment',
        next: 'send-result',
      },
      {
        article_title: 'How to send result notification or follow-up reminder via SMS?',
        article_code: 'send-result',
        content: <AppoinArticle6 />,
        prev: 'update-appointment',
        next: 'null',
      },
    ],
  },

  {
    category_name: 'OCP Reminders',
    category_code: 'ocp',
    articles: [
      {
        article_title: 'How to enroll patients',
        article_code: 'ocp-enroll',
        content: <Article1 />,
        prev: 'null',
        next: 'ocp-add-new',
      },
      {
        article_title: 'How to ADD NEW Cycles?',
        article_code: 'ocp-add-new',
        content: <Article2 />,
        prev: 'ocp-enroll',
        next: 'ocp-add',
      },
      {
        article_title: 'How to add Cycle?',
        article_code: 'ocp-add',
        content: <Article3 />,
        prev: 'ocp-add-new',
        next: 'ocp-update',
      },
      {
        article_title: 'How to update Cycle?',
        article_code: 'ocp-update',
        content: <Article4 />,
        prev: 'ocp-add',
        next: 'ocp-delete',
      },
      {
        article_title: 'How to delete Cycle?',
        article_code: 'ocp-delete',
        content: <Article5 />,
        prev: 'ocp-update',
        next: 'ocp-view',
      },
      {
        article_title: 'How to view SMS Schedules?',
        article_code: 'ocp-view',
        content: <Article6 />,
        prev: 'ocp-delete',
        next: 'null',
      },
    ],
  },
  {
    category_name: 'Injectables Reminders',
    category_code: 'injectables',
    articles: [
      {
        article_title: 'How to enroll patients',
        article_code: 'inj-enroll',
        content: <IArticle1 />,
        prev: 'null',
        next: 'inj-add-new',
      },
      {
        article_title: 'How to ADD NEW Cycles?',
        article_code: 'inj-add-new',
        content: <IArticle2 />,
        prev: 'inj-enroll',
        next: 'inj-add',
      },
      {
        article_title: 'How to add Cycle?',
        article_code: 'inj-add',
        content: <IArticle3 />,
        prev: 'inj-add-new',
        next: 'inj-update',
      },
      {
        article_title: 'How to update Cycle?',
        article_code: 'inj-update',
        content: <IArticle4 />,
        prev: 'inj-add',
        next: 'inj-update',
      },
      {
        article_title: 'How to delete Cycle?',
        article_code: 'inj-delete',
        content: <IArticle5 />,
        prev: 'inj-update',
        next: 'inj-view',
      },
      {
        article_title: 'How to view SMS Schedules?',
        article_code: 'inj-view',
        content: <IArticle6 />,
        prev: 'inj-delete',
        next: 'null',
      },
    ],
  },
  {
    category_name: 'Health Session',
    category_code: 'health_package',
    articles: [
      {
        article_title: 'How to create a Health Session Plan?',
        article_code: 'create-health-package',
        content: <Health1 />,
        prev: 'null',
        next: 'add-stage',
      },
      {
        article_title: 'How to add a new session to a Health Session Plan?',
        article_code: 'add-stage',
        content: <Health2 />,
        prev: 'create-health-package',
        next: 'add-services',
      },
      {
        article_title: 'How to add services to a session?',
        article_code: 'add-services',
        content: <Health3 />,
        prev: 'add-stage',
        next: 'enroll-patients',
      },
      {
        article_title: 'How to enroll patients in a Health Session?',
        article_code: 'enroll-patients',
        content: <Health4 />,
        prev: 'add-services',
        next: 'delete-stage',
      },
      {
        article_title: 'How to delete a session in a Health Session Plan?',
        article_code: 'delete-stage',
        content: <Health5 />,
        prev: 'enroll-patients',
        next: 'null',
      },
    ],
  },
];
