import React, { useMemo } from 'react';

import { ColoredTable } from 'core/components';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { GridColDef } from '@mui/x-data-grid';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material/styles';

interface DeckingTableProps {
  events: CompanyAppointmentModel[];
}

const DeckingTable: React.FC<DeckingTableProps> = ({ events }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const providerStats = useMemo(() => {
    const stats: {
      [key: string]: {
        actual: number;
        scheduled: number;
        cancellations: number;
        noShow: number;
        rowColor?: string;
        bold?: boolean;
      };
    } = {};

    events.forEach((event) => {
      const serviceProvider = event.service_provider || 'Unassigned';

      if (!stats[serviceProvider]) {
        stats[serviceProvider] = { actual: 0, scheduled: 0, cancellations: 0, noShow: 0 };
      }

      switch (event.status) {
        case 'done':
          stats[serviceProvider].actual++;
          break;
        case 'pending':
        case 'rescheduled':
          stats[serviceProvider].scheduled++;
          break;
        case 'cancelled':
          stats[serviceProvider].cancellations++;
          break;
        case 'no-show':
          stats[serviceProvider].noShow++;
          break;
      }
    });

    const sortedStats = Object.entries(stats)
      .map(([serviceProvider, data]) => ({
        serviceProvider,
        ...data,
      }))
      .sort((a, b) => (a.serviceProvider === 'Unassigned' ? 1 : b.serviceProvider === 'Unassigned' ? -1 : 0));

    return sortedStats;
  }, [events]);

  const totals = useMemo(() => {
    return providerStats.reduce(
      (acc, curr) => {
        acc.actual += curr.actual;
        acc.scheduled += curr.scheduled;
        acc.cancellations += curr.cancellations;
        acc.noShow += curr.noShow;
        return acc;
      },
      {
        serviceProvider: 'Total',
        actual: 0,
        scheduled: 0,
        cancellations: 0,
        noShow: 0,
        rowColor: colors.light_blue_background_2,
        bold: true,
      }
    );
  }, [colors.light_blue_background_2, providerStats]);

  const columns: GridColDef[] = [
    {
      field: 'serviceProvider',
      headerName: 'Service Provider',
      sortable: false,
      flex: 2,
    },
    {
      field: 'actual',
      headerName: 'Attended',
      sortable: false,
      flex: 1,
    },
    {
      field: 'scheduled',
      headerName: 'Scheduled',
      sortable: false,
      flex: 1,
    },
    {
      field: 'cancellations',
      headerName: 'Cancellations',
      sortable: false,
      flex: 1,
    },
    {
      field: 'noShow',
      headerName: 'No Show',
      sortable: false,
      flex: 1,
    },
  ];

  return <ColoredTable columns={columns} dataRows={[...providerStats, totals]} rowHeight={50} />;
};

export default DeckingTable;
