import { ApiQuery, FieldInput } from 'core/model/interface';
/* eslint-disable react-hooks/exhaustive-deps */
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { deleteCompanyHealthProgram, getCompanyHealthPrograms, getProgramCount, updateCompanyHealthProgram } from 'carego-admin/api/company-program';
import { useEffect, useState } from 'react';

import AddHealthProgram from '../components/AddHealthProgram';
import { Box } from '@mui/material';
import { CompanyModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import { PrimaryButton } from 'core/components';
import { update_health_programs_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';

type HealthProgramsProps = {
  company: CompanyModel;
};

type UserInput = {
  order: number;
  visible: boolean;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const HealthPrograms: React.FC<HealthProgramsProps> = ({ company }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [program, setProgram] = useState<any>();
  const [refreshTable, setRefreshTable] = useState(0);
  const [openAddHealthProgramsModal, setOpenAddHealthProgramsModal] = useState<boolean>(false);
  const [programCount, setProgramCount] = useState<number>(0);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'program_name', headerName: 'Program Name', flex: 1 },
    { field: 'program_code', headerName: 'Program Code', flex: 1 },
    { field: 'order', headerName: 'Order', flex: 0.5 },
  ];

  //Initial Values
  const updateInitialValues: UserInput = program && {
    order: program.order,
    visible: program.visible,
  };

  const orderOptions = Array.from({ length: programCount+1 }, (_, index) => ({
    key: String(index),
    value: index,
  }));

  //Fields
  const fields: UserFieldInput[] = [
    {
      field_name: 'order',
      display_name: 'Order',
      type: 'select',
      options: orderOptions
    },
    {
      field_name: 'visible',
      display_name: 'Visibility',
      type: 'select',
      options: [
        { key: 'Show', value: 1 },
        { key: 'Hide', value: 0 },
      ],
    },
  ];

  // Functions
  const getCompanyPrograms = (query: ApiQuery) => {
    return getCompanyHealthPrograms(company.id, query);
  };

  const handleDelete = (data: any) => {
    return deleteCompanyHealthProgram(data);
  };

  const refreshHealthPrograms = () => {
    setRefreshTable && setRefreshTable((prev: number) => prev + 1);
  };

  const handleSubmit = async (data: any) => {
    if (program.id) {
      return updateCompanyHealthProgram(program.id, data).then(() => {
        enqueueSnackbar('Health Program successfully updated!', { variant: 'success' });
        refreshHealthPrograms();
      })
    }
  }

  const getCountOfPrograms = () => {
    getProgramCount().then((res: any) => setProgramCount(res.data));
  }

  useEffect(() => {
    if (company.id) {
      refreshHealthPrograms();
    }
  }, [company.id])

  useEffect(() => {
    getCountOfPrograms();
  }, [])
  

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Health Program',
    doNotWaitForFacility: true,
    tableComponent: {
      columns: columns,
      getData: getCompanyPrograms,
      forceRefresh: refreshTable,
      headerComponent: 
        <Box display="flex" gap="10px">
          <PrimaryButton
            onClick={() => {
              setOpenAddHealthProgramsModal(true);
            }}
            label="Add Health Programs"
          />
        </Box>
    },
    tableForm: {
      formFields: fields,
      updateFormFields: fields,
      updateFormInitialValues: updateInitialValues,
      updateFormSchema: update_health_programs_schema,
      formSubmitApiFunction: handleSubmit,
    },
    tableAction: {
      includeAddButton: false,
      includeUpdateAction: true,
      includeDeleteAction: true,
      deleteApiFunction: handleDelete,
      alertCustomObjectColumn: "program_name",
      actionFlex: 0.5,
      getRow: setProgram,
    },
  } as PageBuilderTableType;

  return (
    <>
      <PageBuilder content={content} />

      <AddHealthProgram
        company={company}
        open={openAddHealthProgramsModal}
        setOpen={setOpenAddHealthProgramsModal}
        header={'Add Health Programs'}
        refreshMainTable={refreshHealthPrograms}
      />
    </>
  );
};

export default HealthPrograms;
