import { ApiQuery } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-clinic-access';


export const getClinicAccessOfAPatient = (facility_id: number, patient_id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`);
};

export const removePatientClinicAccess = (facility_id: number, id: number) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createAndDestroy = (facility_id: number, data:any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};