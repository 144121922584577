/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomGridCell, PrimaryButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { assignUserToClinic, getUsersNotAssigned } from 'company/api/users';

import { ApiQuery } from 'core/model/interface';
import { CompanyFacilityModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { userGroups } from 'core/data/list';

type AddUserProps = {
  entity: string;
  clinic: CompanyFacilityModel;
  setRefreshMainTable: (refreshMainTable: any) => void;
};

const AddUser: React.FC<AddUserProps> = ({ entity, clinic, setRefreshMainTable }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [alertContent, setAlertContent] = useState<string>('');
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'full_name', headerName: 'Full Name', flex: 1 },
    {
      field: 'user_group_id',
      headerName: 'User Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {userGroups?.map((u: any) => {
            if (u.id === params.row.user_group_id) return u.group_name;
            return '';
          })}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Registered',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.full_name + '?');
              setSelectedRow(params.row);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const getUserList = (query: ApiQuery) => {
    return getUsersNotAssigned(clinic.id, query);
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const refreshTable = () => {
    setForceRefreshTable((prev: number) => prev + 1);
  };

  const refreshMain = () => {
    setRefreshMainTable && setRefreshMainTable((refreshMainTable: number) => refreshMainTable + 1);
  };

  const showAddDialog = (content: string) => {
    if ((selectedRows.length && !selectedRow) || selectedRow) {
      setOpenAddDialog(true);
      setAlertContent(content);
    }
  };

  const handleAdd = () => {
    const dataList = JSON.stringify(selectedRow ? [selectedRow.id] : selectedRows);
    const data = { data: dataList };

    if (clinic) {
      return assignUserToClinic(clinic.id, data)
        .then(() => {
          const pluralizedProduct = selectedRows.length > 1 ? 'Users' : 'User';
          enqueueSnackbar(`${pluralizedProduct} successfully added!`, { variant: 'success' });
          refreshTable();
          refreshMain();
          setSelectedRow(0);
          setSelectedRows([]);
        })
        .catch((err) => console.error(err));
    }
  };

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: entity,
    tableComponent: {
      columns: columns,
      getData: getUserList,
      forceRefresh: forceRefreshTable,
      checkboxSelection: true,
      selectableRow: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          <PrimaryButton
            onClick={() => {
              if (selectedRows.length) {
                const itemCount = selectedRows.length;
                const pluralizedItem = itemCount > 1 ? 'users' : 'user';

                showAddDialog(
                  `Are you sure you want to add ${itemCount} ${pluralizedItem} to ${clinic.facility_name}?`
                );
              } else {
                enqueueSnackbar('Invalid action. Select ' + entity + ' first!', { variant: 'error' });
              }
            }}
            label={'Add Selected Users'}
            disabled={!selectedRows.length}
          />
        </Box>
      ),
    },
  } as PageBuilderTableType;

  return (
    <>
      <PageBuilder content={content} />

      <ConfirmationDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        content={alertContent}
        onConfirm={handleAdd}
        confirmButtonColor
      />
    </>
  );
};

export default AddUser;
