const userGroups = [
  {
    group_name: 'Company Admin',
    id: 4,
  },
  {
    group_name: 'Company Staff',
    id: 5,
  },
  {
    group_name: 'Company Doctor',
    id: 8,
  },
  {
    group_name: 'Facility Admin',
    id: 6,
  },
  {
    group_name: 'Facility Staff',
    id: 7,
  },
];


const updatePlanList = [
  {
    "key": "Starter Plan",
    "value": "starter",
  },
  {
    "key": "Standard Plan",
    "value": "standard",
  }
];

const cancelFreeTrialList = [
  {
    "key": "Deactivate",
    "value": "deactivate",
  },
  {
    "key": "Starter Plan",
    "value": "starter",
  },
  {
    "key": "Standard Plan",
    "value": "standard",
  }
];

export { userGroups, updatePlanList, cancelFreeTrialList };
