/* eslint-disable react-hooks/exhaustive-deps */

import { CustomForm, CustomModal } from 'core/components';
import { DROPDOWN_FIELD } from 'core/model/interface';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { addMultipleStages } from 'company/api/health-packages';
import { add_multiple_stages_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UserInput = {
  number_of_stages: number;
  stage_name: string;
  days_between_stages: number;
  service_id: number[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  number_of_stages: 0,
  stage_name: '',
  days_between_stages: 0,
  service_id: [],
};

type AddMultipleStagesProps = {
  healthPackage: any;
  openAddMultipleStagesModal: boolean;
  setOpenAddMultipleStagesModal: (openAddMultipleStagesModal: boolean) => void;
  refreshStages: () => void;
  previousStage: any;
};

const AddMultipleStages: React.FC<AddMultipleStagesProps> = ({
  healthPackage,
  openAddMultipleStagesModal,
  setOpenAddMultipleStagesModal,
  refreshStages,
  previousStage,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const formFields: UserFieldInput[] = [
    { field_name: 'number_of_stages', display_name: 'Number of Sessions', type: 'number', span: 2 },
    { field_name: 'stage_name', display_name: 'Session Name', type: 'text', span: 2 },
    { field_name: 'days_between_stages', display_name: 'Days Between Sessions', type: 'number', span: 2 },
    {
      field_name: 'service_id',
      type: DROPDOWN_FIELD.SERVICE,
      span: 2,
      multiple: true,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setLoading(true);
    data.previous_stage = previousStage ? previousStage.id : null;
    data.services = data.service_id ? JSON.stringify(data.service_id.map((service: any) => service.value)) : '';

    if (data) {
      addMultipleStages(healthPackage.id, data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar('Sessions successfully created!', { variant: 'success' });
      setOpenAddMultipleStagesModal(false);
      refreshStages();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setLoading(false);
  };

  return (
    <CustomModal
      header={'Create Multiple Sessions'}
      open={openAddMultipleStagesModal}
      setOpen={setOpenAddMultipleStagesModal}
    >
      <CustomForm
        onSubmit={handleSubmit}
        fields={formFields}
        initialValues={initialValues}
        schema={add_multiple_stages_schema}
        loading={loading}
        submitButtonId="multiple-stages-form-submit-button"
      />
    </CustomModal>
  );
};

export default AddMultipleStages;
