import {
  Box,
  Button,
  Menu,
  MenuItem,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ForbiddenScreen } from 'core/screens';
import { SIZES } from 'theme/constants';
import TabPanel from './CustomTabPanels';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export type TabProps = {
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  label: string;
  content?: JSX.Element;
  hidden?: boolean;
  id?: string;
  requiredAuth?: string[];
};

export type CustomTabsProps = {
  tabs: TabProps[];
  selected?: number;
  setSelected?: (value: number) => void | undefined;
  tabBackgroundColor?: any;
  isSubTab?: boolean;
  tabGroupId?: string;
  tabMinWidth?: string;
  tabStyles?: SxProps<Theme>;
  otherTabs?: any[];
  scrollable?: boolean;
  tabsButtonStyles?: SxProps<Theme>;
  stopParamChange?: boolean;
};

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  selected = 0,
  setSelected,
  tabBackgroundColor,
  isSubTab,
  tabGroupId = 'tab',
  tabMinWidth = '200px',
  tabStyles,
  tabsButtonStyles,
  stopParamChange = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDarkMode = theme.palette.mode === 'dark';
  const { user } = useContext(UserContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [searchParams, setSearchParams] = useSearchParams();
  const urlTab = searchParams.get(tabGroupId);
  const [value, setValue] = useState<number>(urlTab ? parseInt(urlTab) : selected);
  const [prevSelectedTab, setPrevSelectedTab] = useState<number>(0);
  const [allTabs, setAllTabs] = useState<TabProps[]>([]);
  const [filteredTabs, setFilteredTabs] = useState<TabProps[]>([]);
  const [otherTabs, setOtherTabs] = useState<TabProps[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [limit, setLimit] = useState(0);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([]);
  const webStyle = {
    // position: 'sticky',
    // top: 0,
    // zIndex: 10,
    marginBottom: '20px',
    backgroundColor: isSubTab ? colors.hoverBackground : tabBackgroundColor ?? colors.light_blue_background,
    borderRadius: '8px',

    '& .MuiButtonBase-root.MuiTab-root': {
      minHeight: '50px',
      width: isMobilePhone ? '45%' : undefined,
      minWidth: isMobilePhone ? '100px' : isSubTab ? '180px' : tabMinWidth,
      fontSize: '14px',
      textTransform: 'none',
      justifyContent: isMobilePhone ? 'center' : 'left',
      padding: isMobilePhone ? '0px 15px' : '0px 30px',
    },
    '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
      color: isMobilePhone ? '#FFF' : colors.accent,
      backgroundColor: isMobilePhone ? colors.primary : 'inherit',
    },
    '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      right: '0',
      height: '50%',
      top: '25%',
      marginRight: '30px',
    },
    '& .MuiTabs-indicator': { backgroundColor: colors.accent },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: 0.3,
    },
    ...tabStyles,
  };

  const mobileStyles = {
    // position: 'sticky',
    // top: 0,
    // zIndex: 10,
    marginBottom: '15px',
    backgroundColor: 'none',
    borderRadius: '8px',
    '& .MuiTabs-flexContainer': {
      gap: '5px',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      minHeight: '45px',
      width: '45%',
      minWidth: '100px',
      fontSize: '12px',
      textTransform: 'none',
      justifyContent: 'center',
      padding: '0px 10px',
      borderRadius: '8px',
      backgroundColor: colors.light_blue_background,
      color: colors.primary,
      fontWeight: '600',
      ...tabsButtonStyles,
    },
    '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
      color: '#FFF',
      backgroundColor: colors.primary,
      fontWeight: '600',
      ...tabsButtonStyles,
    },
    '& .MuiTabs-indicator': {
      height: 0,
    },
    '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      right: '0',
      height: '50%',
      top: '25%',
      marginRight: '20px',
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: 0.3,
    },
    ...tabStyles,
  };
  useEffect(() => {
    if (!selectedTabs.includes(value)) {
      setSelectedTabs((prev) => [...prev, value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (item: TabProps, value: number) => {
    setAnchorEl(null);
    setValue(value);
    setPrevSelectedTab(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelected && setSelected(newValue);
  };

  useEffect(() => {
    if (tabs.length && value >= tabs.length) setValue(0);
    if (limit && value > limit) setPrevSelectedTab(value);
  }, [value, tabs, limit]);

  useEffect(() => {
    const _tabs: TabProps[] = tabs.filter((tab) => !tab.hidden);
    let limit = 4;
    limit += limit + 1 === _tabs.length ? 1 : 0;
    setLimit(limit);
    setAllTabs(_tabs);
    setFilteredTabs(_tabs.slice(0, limit));
    setOtherTabs(_tabs.slice(limit));
  }, [isSubTab, tabs]);

  useEffect(() => {
    if (!firstLoad && !stopParamChange) {
      setSearchParams((searchParams) => {
        searchParams.set(tabGroupId, value.toString());
        return searchParams;
      });
    }
    setFirstLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box>
      <Tabs value={value} variant="scrollable" onChange={handleChange} sx={isMobilePhone ? mobileStyles : webStyle}>
        {filteredTabs.map((tab, index) => (
          <Tab
            key={index}
            icon={
              tab.icon
                ? React.cloneElement(tab.icon as React.ReactElement<any>, {
                    style: { marginRight: isMobilePhone ? '10px' : '20px', fontSize: '18px' },
                  })
                : undefined
            }
            label={tab.label}
            iconPosition="start"
            wrapped
            id={tab.id}
          />
        ))}
        {otherTabs.map((tab, index) => (
          <Tab
            key={index}
            sx={{
              display:
                prevSelectedTab === filteredTabs.length + index || (prevSelectedTab === 0 && index === 0)
                  ? undefined
                  : 'none',
            }}
            icon={
              tab.icon
                ? React.cloneElement(tab.icon as React.ReactElement<any>, {
                    style: { marginRight: '20px', fontSize: '18px' },
                  })
                : undefined
            }
            label={tab.label}
            iconPosition="start"
            wrapped
            id={tab.id}
            value={filteredTabs.length + index}
            hidden
          />
        ))}

        {!!otherTabs.length && (
          <Typography
            component={Button}
            color={isDarkMode ? 'white' : 'primary'}
            value={-1}
            endIcon={<ExpandMoreIcon />}
            onClick={handleDropdownClick}
            variant="body1"
            fontSize="13px"
            sx={{
              minWidth: tabMinWidth,
              padding: '0px 30px',
              textTransform: 'none',
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Others
          </Typography>
        )}
      </Tabs>
      {!!otherTabs.length && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ '& .MuiPaper-root': { padding: '6px 16px', borderRadius: '18px' } }}
        >
          {otherTabs?.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(menuItem, filteredTabs.length + index)}
              sx={{
                borderRadius: '8px',
                display:
                  prevSelectedTab === filteredTabs.length + index || (prevSelectedTab === 0 && index === 0)
                    ? 'none'
                    : undefined,
              }}
            >
              <Box display="flex" gap="15px" alignItems="center">
                {menuItem.icon && (
                  <Typography sx={{ '& .MuiSvgIcon-root': { fontSize: '18px' } }}>{menuItem.icon}</Typography>
                )}
                <Typography>{menuItem.label}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      )}
      {allTabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {selectedTabs.includes(index) && (
            <Box paddingInline={SIZES.padding}>
              {!tab.requiredAuth || tab.requiredAuth.filter((r) => user.authorities?.includes(r)).length > 0 ? (
                tab.content
              ) : (
                <ForbiddenScreen hideButton />
              )}
            </Box>
          )}
        </TabPanel>
      ))}
    </Box>
  );
};

export default CustomTabs;
