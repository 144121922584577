import React, { useContext, useEffect } from 'react';

import { ColorModeContext } from 'theme/theme';
import HideOrShowComponent from 'core/components/HideOrShowComponent';
import Partners from './Partner';
import Scroll1 from './1Home';
import Scroll2 from './2Features';
import Scroll3 from './3Result';
import Scroll4 from './4Feedback';
import Scroll5 from './5Start';
import Scroll6 from './6Trial';
import Scroll7 from './7Community';
import Scroll8 from './8Footer';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@mui/material';

const Landing = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      colorMode.toggleColorMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return (
    <Container>
      <Scroll1 />
      {!isMobile && <Partners />}
      <Scroll2 id="features" />

      <Scroll3 />
      <Scroll4 />
      {!isMobile && <Scroll5 />}

      <Scroll6 />

      {!isMobile && <Scroll7 />}
      <HideOrShowComponent hidden>{<Scroll7 />}</HideOrShowComponent>
      <Scroll8 />
    </Container>
  );
};

export default Landing;

const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  overflow-x: hidden;
`;
