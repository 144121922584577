import ChartOfAccount from 'company/screens/InventorySystem/ChartOfAccount';
import Expenses from 'company/screens/InventorySystem/Expenses/Expenses';
import PatientBilling from 'company/screens/RegistrationBoard/components/PatientBilling';
import Payments from 'company/screens/InventorySystem/Payments';
import { ProtectedRoute } from 'core/components';
import Reports from 'company/screens/InventorySystem/Reports';
import { Route } from 'react-router-dom';

const AccountingRoutes = (
  <Route path="/company/accounting" element={<ProtectedRoute parent />}>
    <Route index element={<ChartOfAccount />} />
    <Route path="coa" element={<ChartOfAccount />} />
    <Route path="payments" element={<Payments />} />
    <Route path="expenses" element={<Expenses />} />
    <Route path="reports" element={<Reports />} />
    <Route path="invoices" element={<PatientBilling fromAccounting />} />
  </Route>
);

export default AccountingRoutes;
