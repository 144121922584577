import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomCheckbox, CustomModal, RegularButton } from 'core/components';
import { forwardRef, useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FacilityContext } from 'core/context/facility.context';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import ScheduleTable from './ScheduleTable';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import TodayIcon from '@mui/icons-material/Today';
import WeeklySchedule from 'company/screens/ClinicManagement/components/WeeklySchedule';
import { getAppointments } from 'company/api/appointments';
import { tokens } from 'theme/theme';

// import { FacilityContext } from 'core/context/facility.context';

type Props = {
  providerId: number;
  providerDetails: ServiceProviderModel;
  setOpenServiceProviderDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Hour {
  start: string;
  end: string;
}

interface DaySchedule {
  day: string;
  week_day: string;
  hours: Hour[];
}

const ServiceProviderDetails = forwardRef(
  ({ setOpenServiceProviderDetails, setOpenServiceProviders, providerDetails }: Props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { facility } = useContext(FacilityContext);
    const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [scheduleFormat, setScheduleFormat] = useState<string[]>([]);
    const isMobile = useMediaQuery('(max-width:768px)');

    const setCustomSchedule = async (data: any) => {
      setOpenScheduleModal(true);
    };

    const formatTime = (time: string) => {
      const [hours, minutes] = time.split(':');
      let hour = parseInt(hours, 10);

      return `${hour}:${minutes}`;
    };

    const formatSchedule = (schedule: DaySchedule[]) => {
      if (schedule) {
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const formattedSchedule = daysOfWeek.map((day) => {
          const daySchedule = schedule.find((s) => s.week_day === day);

          if (!daySchedule || daySchedule.hours.length === 0) {
            return `${day} - No Schedule`;
          }

          const hours = daySchedule.hours
            .map((hour) => `${formatTime(hour.start)} to ${formatTime(hour.end)}`)
            .join(' and ');

          return `${day} - ${hours}`;
        });

        setScheduleFormat(formattedSchedule);
      }
    };

    const getProviderAppointments = (query: ApiQuery = {}) => {
      query.provider_id = providerDetails.id;

      return getAppointments(facility.id, query).then((response) => {
        return response;
      });
    };

    useEffect(() => {
      getProviderAppointments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      formatSchedule(providerDetails.schedule);
      setDisabled(!providerDetails.schedule);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerDetails]);

    const handleBackToServiceProviders = () => {
      setOpenServiceProviderDetails(false);
      setOpenServiceProviders(true);
    };

    // if (!providerDetails) {
    //   return <Typography>No provider details found.</Typography>;
    // }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobile ? 'center' : 'start',
          justifyContent: 'space-between',
          columnGap: '12px',
          rowGap: '24px',
          ml: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            columnGap: '12px',
            marginTop: '-56px',
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            color={colors.primary}
            onClick={handleBackToServiceProviders}
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '8px',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <ArrowBackIcon sx={{ color: colors.primary }} />
            Back to Service Providers
          </Typography>
        </Box>

        {isMobile ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
              rowGap: '10px',
            }}
          >
            <Typography fontWeight={700} fontSize={'20px'} color={colors.primary}>
              {providerDetails.full_name}
            </Typography>

            <Avatar alt={providerDetails.full_name} src={providerDetails.picture} sx={{ width: 56, height: 56 }} />

            <Typography fontSize={'12px'} fontWeight={500} color={colors.grey_text} mt={0}>
              {providerDetails.title ?? 'Health Staff'} at {facility.facility_name}
            </Typography>
            {/* <Box
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    columnGap: '8px',
                  }}
                >
                  <CircleIcon sx={{ fontSize: '12px', color: colors.primary }} />

                  <Typography fontSize={'12px'} fontWeight={500} color={colors.primary}>
                    Ongoing Appointment from 1:00 PM to 3:00 PM
                  </Typography>
                </Box> */}

            {/* 
          <EditIcon /> */}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: '16px',
              marginTop: '20px',
            }}
          >
            <Avatar alt={providerDetails.full_name} src={providerDetails.picture} sx={{ width: 56, height: 56 }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                rowGap: '2px',
                marginRight: '20px',
              }}
            >
              <Typography fontWeight={600} fontSize={'28px'} color={colors.primary}>
                {providerDetails.full_name}
              </Typography>
              <Typography fontSize={'12px'} fontWeight={500} color={colors.grey_text} mt={0}>
                {providerDetails.title ?? 'Health Staff'} at {facility.facility_name}
              </Typography>
              {/* <Box
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    columnGap: '8px',
                  }}
                >
                  <CircleIcon sx={{ fontSize: '12px', color: colors.primary }} />

                  <Typography fontSize={'12px'} fontWeight={500} color={colors.primary}>
                    Ongoing Appointment from 1:00 PM to 3:00 PM
                  </Typography>
                </Box> */}
            </Box>
            {/* 
          <EditIcon /> */}
          </Box>
        )}

        {/* <CustomDashboardWidget
              count={'8'}
              label="No. of Total Served"
              iconBackgroundColor="#6999B3"
              icon={SentimentVerySatisfiedIcon}
              sx={{ width: '300px', backgroundColor: '#337497' }}
            /> */}

        {isMobile ? (
          <Box width={'100%'}>
            <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: '12px' }}>
              <BusinessCenterIcon color="primary" />
              <h3>Provider Information</h3>
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr " gap="20px">
              <Box display={'flex'} flexDirection={'column'} alignItems={'start'} gap={'20px'}>
                <PatientField
                  label="First Name"
                  value={providerDetails.first_name}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />
                <PatientField
                  label="Last Name"
                  value={providerDetails.last_name}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />
                <PatientField
                  label="License No."
                  value={providerDetails.license_number}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />
              </Box>
              <Box display={'flex'} flexDirection={'column'} alignItems={'start'} gap={'20px'}>
                <PatientField
                  label="Middle Name"
                  value={providerDetails.middle_name}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />

                <PatientField
                  label="Designation"
                  value={providerDetails.title}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />

                <PatientField
                  label="Contact No."
                  value={providerDetails.mobile_number_1}
                  sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: '12px' }}>
              <BusinessCenterIcon color="primary" />
              <h2>Provider Information</h2>
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr " gap="20px">
              <PatientField
                label="First Name"
                value={providerDetails.first_name}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
              <PatientField
                label="Middle Name"
                value={providerDetails.middle_name}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
              <PatientField
                label="Last Name"
                value={providerDetails.last_name}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
              <PatientField
                label="Designation"
                value={providerDetails.title}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
              <PatientField
                label="License No."
                value={providerDetails.license_number}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
              <PatientField
                label="Contact No."
                value={providerDetails.mobile_number_1}
                sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
              />
            </Box>
          </Box>
        )}
        {isMobile ? (
          <Box width={'100%'}>
            <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: '12px' }}>
              <CalendarMonthIcon color="primary" />
              <h3>Schedule</h3>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'start', rowGap: '5px', flexDirection: 'column', mb: '12px' }}>
              {scheduleFormat.map((line: any, index: any) => (
                <Typography key={index} sx={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>
                  {line}
                </Typography>
              ))}
            </Box>
            <ScheduleTable schedule={providerDetails.schedule || []}></ScheduleTable>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: '12px' }}>
              <CalendarMonthIcon color="primary" />
              <h2>Schedule</h2>
            </Typography>
            <Box>
              {scheduleFormat.map((line: any, index: any) => (
                <Typography key={index} sx={{ whiteSpace: 'pre-wrap' }}>
                  {line}
                </Typography>
              ))}
            </Box>
            <ScheduleTable schedule={providerDetails.schedule || []}></ScheduleTable>
          </Box>
        )}
        <RegularButton
          label="Update Schedule"
          startIcon={<TodayIcon sx={{ fontSize: '12px' }} />}
          styles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 32px',
          }}
          fullWidth={isMobile ? true : false}
          onClick={() => setCustomSchedule(true)}
        />

        <CustomModal
          header={'Update Schedule'}
          subHeader={
            providerDetails?.full_name_with_title ? providerDetails?.full_name_with_title : providerDetails?.full_name
          }
          open={openScheduleModal}
          setOpen={setOpenScheduleModal}
          width={800}
        >
          <Box>
            {providerDetails && (
              <Box display={'flex'} gap="2px" alignItems={'center'}>
                <CustomCheckbox
                  label={'Unavailable'}
                  value={disabled}
                  handleChange={() => {
                    setDisabled((prev: boolean) => !prev);
                  }}
                  fieldName={''}
                />
              </Box>
            )}
          </Box>
          {providerDetails && (
            <WeeklySchedule
              schedule={providerDetails.schedule}
              id={providerDetails.assignment_id}
              type="doctor"
              disabled={disabled}
              setSchedule={(schedule) => {
                providerDetails.schedule = JSON.parse(schedule);
                setOpenScheduleModal(false);
                formatSchedule(providerDetails.schedule);
              }}
              clinic_id={providerDetails.facility_id}
            />
          )}
        </CustomModal>
      </Box>
    );
  }
);

export default ServiceProviderDetails;
