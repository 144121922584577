import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'bookings';

export const getBookings = (facility_id: number, query: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getBookingDetails = (booking_id: number) => {
  return axios.get(`${baseURL}/public/${path}/${booking_id}`);
};

export const createBooking = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/public/${path}/${facility_id}`, data);
};

export const confirmBooking = (facility_id: number, id: number) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const addAppointment = (facility_id: number, patientId: number, bookingId: number) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/add-appointment/${patientId}/${bookingId}`);
};

export const cancelBooking = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};
