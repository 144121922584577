import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { CustomCheckbox, CustomSelectField, SubmitButton } from 'core/components';
import { Formik, FormikHelpers } from 'formik';

import CustomDatePicker from './CustomDatePicker';
import CustomDateTimePicker from './CustomDatetimePicker';
import CustomPasswordField from './CustomPasswordField';
import CustomRadioGroup from './CustomRadioGroup';
import CustomTextField from './CustomTextField';
import CustomTimePicker from './CustomTimePicker';
import { FieldInput } from 'core/model/interface';
import React from 'react';
import moment from 'moment';

type CustomInlineFormProps = {
  onSubmit: (data: any, formikHelpers: FormikHelpers<any>) => void;
  children?: React.ReactNode;
  fields: FieldInput[];
  schema?: any;
  initialValues: any;
  submitButtonText?: string;
  disabled?: boolean;
  loading?: boolean;
  buttonWidth?: string;
  readOnly?: boolean;
  hideSubmitButton?: boolean;
  submitStartIcon?: React.ReactNode;
  submitEndIcon?: React.ReactNode;
  submitButtonSize?: 'small' | 'medium' | 'large';
  submitButtonStyles?: SxProps<Theme>;
  handlerOnFocus?: () => void;
  enableRowButtonMobile?: boolean;
};

const CustomInlineForm: React.FC<CustomInlineFormProps> = ({
  onSubmit,
  fields,
  schema,
  initialValues,
  submitButtonText,
  disabled,
  loading,
  buttonWidth,
  readOnly,
  hideSubmitButton,
  submitStartIcon,
  submitEndIcon,
  submitButtonSize,
  submitButtonStyles,
  handlerOnFocus,
  enableRowButtonMobile = false,
}) => {
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const date_fields = fields.filter((field) => field.type === 'date' || field.type === 'datetime');
    for (let index = 0; index < date_fields.length; index++) {
      const field_name = date_fields[index].field_name;

      if (values[field_name]) {
        const field_type = date_fields[index].type;
        const date_value = new Date(values[field_name]);

        if (field_type === 'date') values[field_name] = moment(date_value).format('YYYY-MM-DD');
        else if (field_type === 'datetime') values[field_name] = moment(date_value).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    onSubmit(values, formikHelpers);
  };

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={schema} enableReinitialize>
      {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection={isMobile && !enableRowButtonMobile ? 'column' : 'row'}
            gap="10px"
            width="100%"
            alignItems={isMobile ? 'flex-end' : 'center'}
            justifyContent="center"
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap="15px" width={'100%'}>
              {fields.map((field, index) => {
                if (field.hidden) {
                  return false;
                }
                if (field.type === 'select') {
                  return (
                    <Box flex={field.flex ?? 1} key={index}>
                      <CustomSelectField
                        label={field.display_name!}
                        disabled={field.disabled}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values[field.field_name]}
                        fieldName={field.field_name}
                        error={errors[field.field_name] as string}
                        touched={touched[field.field_name] as boolean}
                        span={field.span ?? 2}
                        options={field.options}
                        handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                        size={field.size}
                      />
                    </Box>
                  );
                } else if (field.type === 'datetime') {
                  return (
                    <Box
                      flex={field.flex ?? 1}
                      key={index}
                      width="100%"
                      sx={{ '& .MuiTextField-root': { width: '100%' } }}
                    >
                      <CustomDateTimePicker
                        key={index}
                        fieldName={field.field_name}
                        label={field.display_name!}
                        disabled={field.disabled}
                        value={values[field.field_name]}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        error={errors[field.field_name]}
                        touched={touched[field.field_name] as boolean}
                        span={field.span ?? 2}
                        disablePast={field.disablePast}
                        disableFuture={field.disableFuture}
                        size={field.size}
                        minutesStep={field.minutesStep}
                        readOnly={readOnly}
                        handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                      />
                    </Box>
                  );
                } else if (field.type === 'date') {
                  return (
                    <Box flex={field.flex ?? 1} key={index}>
                      <CustomDatePicker
                        fieldName={field.field_name}
                        label={field.display_name!}
                        disabled={field.disabled}
                        value={values[field.field_name]}
                        setFieldValue={setFieldValue}
                        error={errors[field.field_name]}
                        touched={touched[field.field_name] as boolean}
                        span={field.span ?? 2}
                        disablePast={field.disablePast}
                        disableFuture={field.disableFuture}
                        size={field.size}
                        readOnly={readOnly}
                      />
                    </Box>
                  );
                } else if (field.type === 'time') {
                  return (
                    <Box flex={field.flex ?? 1} key={index}>
                      <CustomTimePicker
                        key={field.field_name}
                        fieldName={field.field_name}
                        label={field.display_name!}
                        disabled={field.disabled}
                        value={values[field.field_name]}
                        setFieldValue={setFieldValue}
                        error={errors[field.field_name]}
                        touched={touched[field.field_name] as boolean}
                        span={field.span ?? 2}
                        size={field.size}
                        minutesStep={field.minutesStep}
                        maxTime={field.maxTime}
                        minTime={field.minTime}
                        format={field.format}
                        optional={field.optional}
                        readOnly={readOnly}
                      />
                    </Box>
                  );
                } else if (field.type === 'radiogroup') {
                  return (
                    <CustomRadioGroup
                      key={index}
                      label={field.display_name!}
                      handleChange={handleChange}
                      disabled={field.disabled}
                      fieldName={field.field_name}
                      value={values[field.field_name]}
                      span={field.span ?? 2}
                      handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                      options={field.options!}
                      setFieldValue={setFieldValue}
                      readOnly={readOnly}
                    />
                  );
                } else if (field.type === 'password') {
                  return (
                    <CustomPasswordField
                      key={index}
                      label={field.display_name!}
                      disabled={field.disabled}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values[field.field_name]}
                      fieldName={field.field_name}
                      error={errors[field.field_name]}
                      touched={touched[field.field_name] as boolean}
                      span={field.span ?? 2}
                      size={field.size}
                    />
                  );
                } else if (field.type === 'checkbox') {
                  return (
                    <CustomCheckbox
                      key={index}
                      label={field.display_name!}
                      handleChange={handleChange}
                      disabled={field.disabled}
                      fieldName={field.field_name}
                      value={values[field.field_name]}
                      span={field.span ?? 2}
                    />
                  );
                }
                return (
                  <Box flex={1} key={index}>
                    <CustomTextField
                      handlerOnFocus={handlerOnFocus}
                      type={field.type}
                      key={index}
                      label={field.display_name!}
                      disabled={field.disabled}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values[field.field_name]}
                      fieldName={field.field_name}
                      error={errors[field.field_name]}
                      touched={touched[field.field_name] as boolean}
                      size={field.size}
                      multiline={field.multiline}
                      rows={field.rows}
                      handleChangeCallback={(value) => field.onChange && field.onChange(value, setFieldValue)}
                      noLabel={field.noLabel}
                      readOnly={readOnly}
                    />
                  </Box>
                );
              })}
            </Box>
            {!hideSubmitButton && (
              <SubmitButton
                label={submitButtonText}
                loading={loading}
                disabled={disabled}
                width={buttonWidth ? buttonWidth : 'auto'}
                startIcon={submitStartIcon}
                endIcon={submitEndIcon}
                size={submitButtonSize}
                submitButtonStyles={submitButtonStyles}
              />
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CustomInlineForm;
