/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import { PatientModel } from 'company/model/Entities';
import SuccessModal from 'core/components/modals/SuccessModal';
import { Typography } from '@mui/material';
import { formatCurrency } from 'core/utils';
import { getPatient } from 'company/api/patient';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type POSPaymentSuccessModalProps = {
  data: any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setViewDetailsClick?: () => void;
};

const POSPaymentSuccessModal: React.FC<POSPaymentSuccessModalProps> = ({
  data,
  openModal,
  setOpenModal,
  setViewDetailsClick,
}) => {
  const [patient, setPatient] = useState<PatientModel>();
  const { facility } = useContext(FacilityContext);
  const navigate = useNavigate();
  const handleBack = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (data) {
      getPatient(facility.id, data.patient_id).then((res) => {
        setPatient(res.data);
      });
    }
  }, [data]);

  const messageComponent = (
    <>
      <MainLine> Payment is successfully recorded! </MainLine>
      <SecondaryLine> {patient?.full_name}'s Remaining Balance: </SecondaryLine>
      <Typography sx={{ color: '#0666AF', fontWeight: 'bold', display: 'inline' }}>
        {formatCurrency(data?.total - data?.payment)}
      </Typography>
    </>
  );

  const subMessageComponent = (
    <>
      <SecondaryLine> {patient?.full_name}'s Payment of </SecondaryLine>
      <Typography sx={{ color: '#0666AF', fontWeight: 'bold', display: 'inline' }}>
        {formatCurrency(data?.payment)}
      </Typography>
      <SecondaryLine> has been successfully processed. </SecondaryLine>
    </>
  );

  return (
    <SuccessModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      successMessageComponent={messageComponent}
      subSuccessMessageComponent={subMessageComponent}
      handleViewDetailsCallback={setViewDetailsClick}
      handleOkayCallback={handleBack}
      fromPos
    />
  );
};

export default POSPaymentSuccessModal;

const MainLine = styled(Typography)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SecondaryLine = styled(Typography)`
  font-weight: regular;
  font-size: 14px;
  display: inline;
`;
