import { RequestLogQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'firewall/loggers';

export const getAll = (query: RequestLogQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getOne = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const block = (id: number, blockByCfId?: boolean) => {
  return axios.patch(`${baseURL}/${path}/block/${id}?block_by_cf_id=${blockByCfId ? 'true' : ''}`);
};
