import { CustomForm, CustomModal } from 'core/components';

import { Box, useMediaQuery } from '@mui/material';
import { FieldInput } from 'core/model/interface';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Scroll6 = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState<boolean>(false);
  const freeTrialField: FieldInput[] = [
    { field_name: 'name', display_name: ' Name', type: 'text' },
    { field_name: 'clinic_name', display_name: 'Clinic Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'email', display_name: 'Email', type: 'text' },
    { field_name: 'message', display_name: 'Message', type: 'text' },
  ];
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const tryFunc = () => {
    navigate('/demo');
  };
  const handleDemoFormSubmit = (formData: Record<string, any>) => {
    setOpenFreeTrialModal(false);
    setButtonLoading(false);
  };
  return (
    <>
      <MainContainer>
        <Container>
          <Content>
            <Div>
              <Header>Start your free trial today</Header>
              <Paragraph>
                We understand that experiencing the impact of CareGo Health Suite firsthand is essential before making a
                decision. That's why we offer a 14-day free trial period, no credit card required!
              </Paragraph>
              <Paragraph>
                During this trial, you'll have the opportunity to explore all features, witness real-time results, and
                experience the benefits of our app!
              </Paragraph>
            </Div>
            <Image
              src="./assets//LandingMobile/device.png"
              alt="Logo"
              style={{ height: isMobilePhone ? '250px' : '350px', marginBottom: '20px' }}
            />
          </Content>
        </Container>
        <CustomModal header={'Get Started'} open={openFreeTrialModal} setOpen={setOpenFreeTrialModal} width={800}>
          <Box>
            <CustomForm
              initialValues={{}}
              onSubmit={handleDemoFormSubmit}
              fields={freeTrialField}
              loading={buttonLoading}
            />
          </Box>
        </CustomModal>
      </MainContainer>
    </>
  );
};

export default Scroll6;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url('./assets/Landing2/p2/des.png');
  background-size: cover;
  background-position: center;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 100vh;
    background-size: 100% 100%;
    background-image: none;
  }
`;
const Container = styled.div`
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Content = styled.div`
  width: 100vw;
  background-image: url('./assets/landingassets/FreeTrialBackground.png');
  background-size: cover;
  height: 600px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 70px;
  @media (max-width: 768px) {
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-image: none;
    background-color: #f2f9ff;
    paddingy: 80px;
  }
`;
const Div = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
`;
const Image = styled.img`
  width: 50%;
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;
const Header = styled.div`
  color: white;
  font-size: 40px;
  font-weight: bold;
  font-family: Fredoka;
  @media (max-width: 1500px) {
    font-size: 35px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    color: #0192d5;
  }
`;
const Paragraph = styled.p`
  font-size: 18px;
  word-spacing: 2px;
  letter-spacing: 1px;
  line-height: 2rem;
  color: white;
  font-family: Nunito;
  padding-right: 10px;
  @media (max-width: 1500px) {
    font-size: 15px;
    word-spacing: 1.5px;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    word-spacing: 1.5px;
    letter-spacing: 1px;
    line-height: 1.5rem;
    color: black;
    text-align: center;
  }
`;

const Button = styled.button`
  width: 120px;
  padding: 15px;
  border-radius: 25px;
  font-family: Nunito;
  background: transparent;
  border: 1px solid white;
  font-weight: bold;
  text-align: center;

  color: white;
  @media (max-width: 768px) {
    width: 100px;
    padding: 12px;
    font-size: 10px;
  }
`;
