/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CustomTabs } from 'core/components';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import HealthProgramReminder from './HealthProgramReminder';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { TabProps } from 'core/components/CustomTabs';
import ViewAllReminders from './components/ViewAllReminders';
import { getCompanyTags } from 'company/api/company-tags';

const HealthProgramsTabs = () => {
  const { facility } = useContext(FacilityContext);
  const [selected, setSelected] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [tags, setTags] = useState<any[]>([]);

  const getTags = () => {
    if (facility) {
      getCompanyTags(facility.id).then((res) => {
        setTags(
          res.data.data.map((u: any) => {
            return { key: u.tag_name, value: u.id };
          })
        );
      });
    }
  };

  const tabs: TabProps[] = [
    {
      label: 'Schedule View',
      content: <ViewAllReminders refreshTable={refresh} setRefreshTable={setRefresh} tags={tags} />,
      icon: <EventNoteOutlinedIcon />,
    },
    {
      label: 'Patient View',
      content: <HealthProgramReminder refreshTable={refresh} setRefreshTable={setRefresh} tags={tags} />,
      icon: <PersonOutlineOutlinedIcon />,
    },
  ];

  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    getTags();
  }, [facility]);

  return (
    <Box>
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default HealthProgramsTabs;
