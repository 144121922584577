import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Article2: React.FC = () => {
  return (
    <Box>
      <Step>
        1. In “OCP Reminders” - "Patient View” tab, select patient you want to Add new cycle. Click the dropdown button
      </Step>

      <Image src="/assets/FaqAssets/ocp/2/1.png" alt="Step Image" />

      <Step>2. Click on the “Add New Cycle” in dropdown</Step>
      <Image src="/assets/FaqAssets/ocp/2/2.png" alt="  Image" />
      <Step>3. Fill out the form and click “Submit” button</Step>
      <Image src="/assets/FaqAssets/ocp/2/3.png" alt=" Image" />
    </Box>
  );
};

export default Article2;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
