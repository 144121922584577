/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, useRef, useState } from 'react';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { tokens } from 'theme/theme';

type CustomImageUploadProps = {
  value?: any;
  fieldName: string;
  span?: number;
  stringToColor?: (string: string) => string;
  isBanner?: boolean;
  label?: string;
  name?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  optional?: boolean;
};

const CustomImageUpload: React.FC<CustomImageUploadProps> = ({
  label,
  value,
  stringToColor,
  isBanner,
  fieldName,
  name,
  setFieldValue,
  span,
  optional,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isHovering, setIsHovering] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>(value);

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        setImage(e.target.result);
        setFieldValue(fieldName, e.target.result);
      }
    };
    reader.readAsDataURL(file!);
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor && stringToColor(name),
        width: '90px',
        height: '90px',
        margin: '0 10px 0 0',
        cursor: 'pointer',
        marginRight: '1rem',
        opacity: isHovering ? 0.7 : 1,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  return (
    <Box
      gridColumn={'span ' + span}
      display={'flex'}
      sx={{ gap: '20px', flexDirection: 'column', position: 'relative' }}
    >
      <Typography mb="0.5em" sx={{ padding: '5px 15px' }}>
        {label + (optional ? ' (optional)' : '')} ⇩
      </Typography>
      <div
        style={{ position: 'relative', display: 'inline-block' }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {image ? (
          isBanner ? (
            <Box sx={{ width: '100%' }}>
              <img
                src={value}
                width="100%"
                max-height="300px"
                min-height="100px"
                style={{
                  display: 'block',
                  cursor: 'pointer',
                  margin: '0 auto',
                  opacity: isHovering ? 0.7 : 1,
                  border: '2px dashed ' + colors.primary,
                  borderRadius: '10px',
                }}
                onClick={() => fileInputRef.current?.click()}
              />
            </Box>
          ) : (
            <Avatar
              alt={name}
              src={value}
              sx={{
                width: '90px',
                height: '90px',
                margin: '0 10px 0 0',
                cursor: 'pointer',
                marginRight: '1rem',
                opacity: isHovering ? 0.7 : 1,
              }}
              onClick={() => fileInputRef.current?.click()}
            />
          )
        ) : (
          name && <Avatar {...stringAvatar(name)} />
        )}

        {isHovering && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
              zIndex: 100,
            }}
            onClick={() => fileInputRef.current?.click()}
          >
            <CameraAltIcon fontSize="large" />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </div>
    </Box>
  );
};

export default CustomImageUpload;
