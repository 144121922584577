import { Box, Drawer, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

type CustomAnimatedDrawerProps = {
  children: ReactNode;
  customHeader?: ReactNode;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  onClose?: () => void;
  header?: string;
  subHeader?: string;
  id?: string;
  width?: number;
};

const CustomAnimatedDrawer: React.FC<CustomAnimatedDrawerProps> = ({
  children,
  customHeader,
  open,
  setOpen,
  onClose,
  header,
  subHeader,
  id,
  width = 400,
}) => {
  const closeDrawer = () => {
    setOpen(false);
    onClose && onClose();
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <AnimatedDrawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      keepMounted
      sx={{
        height: '100vh',
        '& .MuiPaper-elevation': { backgroundColor: colors.secondary_background, backgroundImage: 'none' },
      }}
    >
      <Box id={id} width={isMobile ? '100%' : width} position="relative">
        <Box
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: colors.background, padding: '20px' }}
          gap="7px"
        >
          {customHeader ?? (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="h3" fontWeight="bold" color={colors.primary}>
                  {header}
                </Typography>

                <Typography variant="h5" component="h4">
                  {subHeader}
                </Typography>
              </Box>
              <Tooltip title="Close">
                <IconButton onClick={closeDrawer} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box padding="20px" sx={{ backgroundColor: colors.secondary_background }}>
          {children}
        </Box>
      </Box>
    </AnimatedDrawer>
  );
};

export default CustomAnimatedDrawer;

const AnimatedDrawer = styled(Drawer)`
  animation: all 1s ease-in-out;
`;
