import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const CC_ENTITY_NAME = 'Expense Type';

export const createExpenseTypeSchema = yup.object().shape({
  expense_type: yup.string().required('Expense Type is required'),
});

export interface ExpenseTypeModel {
  id: number;
  company_id: string;
  expense_type: string;
  is_active: boolean;
}

export interface ExpenseTypeInput {
  expense_type: string;
}

export interface ExpenseTypeFieldInput extends FieldInput {
  field_name?: keyof ExpenseTypeInput;
}

export const expenseTypeInitialValues: ExpenseTypeInput = {
  expense_type: '',
};

export const expenseTypeFields: EntityFields[] = [
  { fieldName: 'expense_type', displayName: 'Expense Type' },
];

// Forms
export const expenseTypeFormFields: string[] = ['expense_type'];
