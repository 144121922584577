import Cookies from 'js-cookie';
import axios from 'axios';

axios.interceptors.request.use((config) => {
  const token = Cookies.get('access_token');
  if (token && config.headers) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');
      if (!window.location.href.includes('login')) {
        window.location.href = '/login';
      }
    }

    throw error;
  }
);

export default axios;
