import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, RegularButton } from 'core/components';
import { Dispatch, ReactNode, SetStateAction, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import {
  INVOICE_ENTITY_NAME,
  PatientBillingModel,
  patientBilingTableColumns,
  patientBilingTableColumnsWithPatient,
  patientBillingFields,
} from './PatientBillingModel';
import {
  deleteInvoice,
  exportHmoInvoices,
  exportInvoices,
  exportInvoicesByPatient,
  exportPatientInvoices,
  getInvoices,
  getPatientInvoices,
  postInvoice,
  reverseInvoice,
} from 'company/api/patient-billing';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import PatientBillingForm from './PatientBillingForm';
import PaymentForm from '../Payment/PaymentForm';
import PrintableInvoice from 'company/screens/RegistrationBoard/components/PrintableInvoice';
import TemplateTable from '../../../Template/TemplateTable';
import ViewInvoiceClick from 'company/screens/RegistrationBoard/components/ViewInvoiceClick';
import { WaitForFacility } from 'company/components';
import { reverse_invoice_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  hideActions?: boolean;
  tableRef?: any;
  useColoredTable?: boolean;
  headerComponent?: ReactNode;
  otherFilter?: any;
  refreshWidgets?: () => void;
  patient?: any;
  hmo?: any;
  afterSearchbarComponent?: ReactNode;
};

const PatientBillingTable = forwardRef((props: Props, ref) => {
  const {
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    tableRef,
    useColoredTable,
    headerComponent,
    otherFilter,
    refreshWidgets,
    patient,
    hmo,
    afterSearchbarComponent,
  } = props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const theme = useTheme();
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<PatientBillingModel>();
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState<boolean>(false);

  const [openReverseInvoiceDialog, setOpenReverseInvoiceDialog] = useState<boolean>(false);
  const [openViewInvoice, setOpenViewInvoice] = useState<boolean>(false);
  const [openConfirmDiag, setOpenConfirmDiag] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>();
  const printableInvoiceRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const refreshTable = () => {
    mainRef.current?.refreshTable();
    refreshWidgets && refreshWidgets();
  };

  const patientId = patient && patient.patient_id;
  const hmoId = hmo && hmo.id;

  const isMobile = useMediaQuery('(max-width:768px)');

  const getData = async (query: ApiQuery) => {
    if (patientId) return getPatientInvoices(facility.id, patientId, query);
    if (hmoId) return getInvoices(facility.id, { ...query, hmo_filter: [hmoId] });

    return getInvoices(facility.id, query);
  };

  const exportInvoice = (query: ApiQuery) => {
    if (patientId) {
      return exportPatientInvoices(facility.id, patientId, patient.full_name + ' - ', query);
    } else if (hmoId) {
      return exportHmoInvoices(facility.id, hmoId, hmo.hmo_name + ' - ', query);
    }
    return exportInvoices(facility.id, facility.facility_name + ' - ', query);
  };

  const exportByPatient = (query: ApiQuery) => {
    return exportInvoicesByPatient(facility.id, facility.facility_name + ' - ', query);
  };

  const handleDelete = (data: any) => {
    return deleteInvoice(facility!.id, data);
  };

  const handleAddPayment = async (data: any) => {
    setSelectedRow(data);
    setOpenAddPaymentModal(true);
  };

  // useEffect(() => {
  //   if (showPrintableInvoice) {
  //     handlePrintInvoice();
  //   }
  // }, [showPrintableInvoice]);

  // const callbackAfterSubmit = () => {
  //   mainRef.current.refreshTable();
  //   mainRef.current.closeFormModal();
  // };

  const confirmInvoice = async (payNow?: boolean) => {
    if (facility && selectedRow) {
      await postInvoice(facility.id, selectedRow.id);
      setOpenConfirmDiag(false);
      refreshTable();
      enqueueSnackbar(`Invoice successfully confirmed!`, { variant: 'success' });

      if (payNow) {
        handleAddPayment(selectedRow);
      }
    }
  };

  useEffect(() => {
    if (patientId || hmoId) refreshTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, hmoId]);

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        hideAddUpdateDeleteAction={true}
        // hideAddButton={hideActions || facility.is_admin}
        // hideDeleteAction={hideActions || isMobile || facility.is_admin}
        // hideUpdateAction={hideActions || facility.is_admin}
        useColoredTable={useColoredTable}
        ref={mainRef}
        entityName={INVOICE_ENTITY_NAME}
        fields={patientBillingFields(theme.palette.mode, facility.is_admin)}
        visibleColumns={
          visibleColumns ?? (patientId ? patientBilingTableColumns : patientBilingTableColumnsWithPatient)
        }
        templateForm={
          <PatientBillingForm
            facility={facility}
            patientId={patientId}
            callbackAfterSubmit={() => {
              refreshTable();
              mainRef.current?.closeFormModal();
            }}
          />
        }
        // rowId="id"
        getData={getData}
        handleDelete={handleDelete}
        exportRecords={exportInvoice}
        customExportRecords={exportByPatient}
        tableAction={{
          includeAddButton: false,
          addButtonLabel: 'Create Invoice',
          actionFlex: 0.8,
          hideRowActionCallback: (data) =>
            isMobile ||
            data.status === 'void' ||
            !(
              data.is_posted &&
              (data.status === 'unpaid' || data.status === 'paid' || data.status === 'partial') &&
              data.reversal_id === null
            ),
          hideDeleteCallback: (data) => !!data.is_posted,
          hideUpdateCallback: (data) => data.status !== 'unpaid',
          handleRowActionsClick: (selectedRow, action) => {
            setSelectedRow(selectedRow);
          },
        }}
        tableComponent={{
          headerComponent,
          otherFilter,

          stickyTop: patientId ? 95 : -1,
          initialOrderBy: 'issue_date',
          initialOrder: 'desc',
          mobileViewConfig: (data: any) => ({
            title_fields: ['invoice_number'],
            subtitle_fields: ['issue_date', 'full_name'],
          }),
        }}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        stackHeaderComponents={isMobile ? true : false}
        afterSearchbarComponent={afterSearchbarComponent}
        customColumns={[
          {
            fieldName: 'invoice_number',
            displayName: 'Invoice #',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) =>
              params.stringOnly ? (
                params.value
              ) : (
                <RegularButton
                  label={params.value}
                  variant="text"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    // getPrescription(params.row, false);
                    setSelectedInvoiceId(params.row.id);
                    setOpenViewInvoice(true);
                  }}
                />
              ),
          },
        ]}
      />
      {selectedRow && (
        <>
          <CustomModal
            open={openAddPaymentModal}
            setOpen={setOpenAddPaymentModal}
            header={'Add Payment'}
            subHeader={selectedRow.invoice_number}
          >
            <PaymentForm
              invoice={selectedRow}
              callbackAfterSubmit={() => {
                setOpenAddPaymentModal(false);
                refreshTable();
              }}
            />
          </CustomModal>
        </>
      )}

      <CustomModal
        width={550}
        header={'Confirmation Message '}
        open={openReverseInvoiceDialog}
        setOpen={setOpenReverseInvoiceDialog}
      >
        <Typography textAlign={'center'} px={'3rem'} pb={'3rem'} fontWeight={500}>
          Would you like to reverse the action made in {selectedRow?.invoice_number}?
        </Typography>

        <Typography pb={'1rem'}>Please state your purpose here:</Typography>
        <CustomForm
          initialValues={{ reason: '', return: true }}
          onSubmit={(data: any) => {
            setSubmitLoading(true);
            if (selectedRow) {
              return reverseInvoice(facility.id, selectedRow.id, data)
                .then((res) => {
                  setOpenReverseInvoiceDialog(false);
                  enqueueSnackbar(`Invoice successfully reversed!`, { variant: 'success' });

                  refreshTable();
                })
                .finally(() => setSubmitLoading(false));
            }
          }}
          fields={[
            {
              field_name: 'reason',
              display_name: 'Purpose',
              type: 'string',
              span: 4,
            },
            {
              field_name: 'return',
              display_name: 'Return products to inventory',
              type: 'checkbox',
              span: 4,
            },
          ]}
          schema={reverse_invoice_schema}
          loading={submitLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        title="Confirm Transaction"
        setOpen={setOpenConfirmDiag}
        open={openConfirmDiag}
        cancelButtonLabel="Pay Later"
        confirmButtonLabel={'Pay Now'}
        content={`Would you like to confirm Invoice #${selectedRow?.invoice_number} and proceed to payment?`}
        onConfirm={() => confirmInvoice(true)}
        onCancel={confirmInvoice} // Pay Later
      />

      <ViewInvoiceClick
        invoiceId={selectedInvoiceId}
        openModal={openViewInvoice}
        setOpenModal={setOpenViewInvoice}
        onInvoiceUpdate={refreshTable}
        onClose={() => setSelectedInvoiceId(undefined)}
      />

      {selectedRow && (
        <Box display="none">
          <PrintableInvoice ref={printableInvoiceRef} invoice={selectedRow} />
        </Box>
      )}
    </WaitForFacility>
  );
});

export default PatientBillingTable;
