/* eslint-disable no-unused-vars */
import { Box, Button, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { Header } from 'core/components';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import StatBox from 'core/components/StatBox';
import TrafficIcon from '@mui/icons-material/Traffic';
import { tokens } from 'theme/theme';

const AdminDashboard = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [patientCount, setPatientCount] = useState(0);
  const [programCount, setProgramCount] = useState(0);
  const [pendingSMSCount, setPendingSMSCount] = useState(0);
  const [smsCredits, setSMSCredits] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setBreadcrumb([]);
    // getDashboardStat().then((res) => {
    //   setPatientCount(res.data.patient_count);
    //   setProgramCount(res.data.program_count);
    //   setPendingSMSCount(res.data.pending_sms);
    //   setSMSCredits(res.data.sms_credits);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.secondary,
              color: colors.text,
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: '10px' }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
        {/* ROW 1 */}
        <Box gridColumn="span 3" backgroundColor={colors.secondary_background} borderRadius={2} display="flex">
          <StatBox
            title={patientCount?.toLocaleString()}
            subtitle="Patients Enrolled"
            progress="0.7"
            icon={<EmailIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.secondary_background} borderRadius={2} display="flex">
          <StatBox
            title={programCount?.toLocaleString()}
            subtitle="Health Programs"
            progress="0.50"
            icon={<PointOfSaleIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.secondary_background} borderRadius={2} display="flex">
          <StatBox
            title={pendingSMSCount?.toLocaleString()}
            subtitle="Pending SMS"
            progress="0.30"
            icon={<PersonAddIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.secondary_background} borderRadius={2} display="flex">
          <StatBox
            title={smsCredits?.toLocaleString()}
            subtitle="SMS Credit"
            progress="0.80"
            icon={<TrafficIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
