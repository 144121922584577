/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from '@mui/material';
import { ColoredTable, CustomGridCell, RegularButton } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { enrollPatients, getPatients, getPatientsNotYetAdded, removePatients } from 'company/api/campaign-patients';
import { useContext, useEffect, useRef, useState } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ApiQuery } from 'core/model/interface';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import { FacilityContext } from 'core/context/facility.context';
import RemoveIcon from '@mui/icons-material/Remove';
import { SIZES } from 'theme/constants';
import { calculateAge } from 'core/utils';
import { getCompanyTags } from 'company/api/company-tags';
import { useSnackbar } from 'notistack';

type SelectPatientsProps = {
  outreachID: any;
  campaignID: number;
  onUpdatePatientList?: () => void;
  refreshMainTable: () => void;
};

const SelectPatients: React.FC<SelectPatientsProps> = ({
  outreachID,
  campaignID,
  onUpdatePatientList,
  refreshMainTable,
}) => {
  const { facility } = useContext(FacilityContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState<any[]>([]);

  //Patient List
  const [selectedClientIDs, setSelectedClientIDs] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  //Selected Patients
  const [selectedClientIDsTable2, setSelectedClientIDsTable2] = useState<any[]>([]);
  const [selectedTagsTable2, setSelectedTagsTable2] = useState<any[]>([]);

  const patientsTableRef: any = useRef();
  const selectedPatientsTableRef: any = useRef();

  const patient_list_columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 0.3,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex', flex: 0.5 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      renderCell: (params) => (
        <RegularButton
          label="Add"
          size="small"
          color="success"
          startIcon={<AddOutlinedIcon fontSize="small" />}
          onClick={() => {
            handlePatientEnroll([], params.row.patient_id);
          }}
        />
      ),
    },
  ];

  const selected_patients_columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex', flex: 0.5 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      renderCell: (params) => (
        <RegularButton
          label="Remove"
          size="small"
          color="error"
          startIcon={<RemoveIcon fontSize="small" />}
          onClick={() => {
            handlePatientRemove([], params.row.id);
          }}
        />
      ),
    },
  ];

  const handleSelectRows = (selected: GridRowSelectionModel) => {
    if (selected) {
      handlePatientEnroll(selected);
    } else {
      enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
    }
  };

  const handleSelectRowsTable2 = (selected: GridRowSelectionModel) => {
    if (selected) {
      handlePatientRemove(selected);
    } else {
      enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
    }
  };

  const getPatientsList = async (query: ApiQuery) => {
    const tags = Array.isArray(selectedTags) ? selectedTags.map((tag) => tag.value) : [];
    const data = { tags: JSON.stringify(tags) };

    return getPatientsNotYetAdded(facility.id, outreachID, query, data);
  };

  const getPatientCampaigns = async (query: ApiQuery) => {
    if (outreachID) {
      return getPatients(facility.id, outreachID, query);
    }
  };

  const handleTagsChange = (event: any, newTags: any[]) => {
    setSelectedTags(newTags);
    refreshTable();
  };

  const handleTagsChangeTable2 = (event: any, newTags: any[]) => {
    setSelectedTagsTable2(newTags);
    refreshTable();
  };

  const getTags = () => {
    if (facility) {
      getCompanyTags(facility.id).then((res) => {
        setTags(
          res.data.data.map((u: any) => {
            return { key: u.tag_name, value: u.id };
          })
        );
      });
    }
  };

  useEffect(() => {
    getTags();
  }, [facility]);

  useEffect(() => {
    refreshTable();
  }, [selectedTags, selectedTagsTable2]);

  const handlePatientEnroll = (selected?: any[], selectedClientID?: number) => {
    const patientList = JSON.stringify(selectedClientID ? [selectedClientID] : selected);
    const data = { patients: patientList, campaign_id: campaignID };

    enrollPatients(facility.id, outreachID, data)
      .then((res) => {
        enqueueSnackbar(`${selectedClientID ? 'Patient' : 'Patients'} successfully enrolled!`, { variant: 'success' });
        refreshTable();
        refreshMainTable();
        setSelectedClientIDs([]);
        onUpdatePatientList && onUpdatePatientList();
      })
      .catch(() => setLoading(false));
  };

  const handlePatientRemove = (selected?: any[], selectedClientIDTable2?: number) => {
    const patientList = JSON.stringify(selectedClientIDTable2 ? [selectedClientIDTable2] : selected);
    const patients = { patients: patientList };

    removePatients(facility.id, patients)
      .then((res) => {
        enqueueSnackbar(`${selectedClientIDTable2 ? 'Patient' : 'Patients'} successfully removed!`, {
          variant: 'success',
        });
        refreshTable();
        refreshMainTable();
        setSelectedClientIDsTable2([]);
        onUpdatePatientList && onUpdatePatientList();
      })
      .catch(() => setLoading(false));
  };

  const refreshTable = () => {
    patientsTableRef.current.refreshTable();
    selectedPatientsTableRef.current.refreshTable();
  };

  return (
    <Box display="flex" gap={SIZES.paddingS}>
      <Box display="flex" flexDirection="column" width={'50%'}>
        <Typography variant="subtitle1" fontWeight={'bold'} m="10px">
          Patient List
        </Typography>
        <Box height={'40vh'} sx={{ overflowY: 'scroll' }}>
          <ColoredTable
            ref={patientsTableRef}
            rowId={'patient_id'}
            columns={patient_list_columns}
            getData={getPatientsList}
            rowHeight={8}
            showHeaderBelowTable
            checkboxSelection
            checkboxSelectionButtonTitle={'Add Patients'}
            onRowsSelected={handleSelectRows}
            // showCheckboxButtonBelowTable
            filterComponent={
              <Box width="250px">
                <CustomAutoCompleteMultipleSelect
                  options={tags}
                  label={'Patient Groups'}
                  fieldName={'tags'}
                  value={selectedTags}
                  handleChange={handleTagsChange}
                />
              </Box>
            }
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" width={'50%'}>
        <Typography variant="subtitle1" fontWeight={'bold'} m="10px">
          Selected Patients
        </Typography>
        <Box height={'40vh'} sx={{ overflowY: 'scroll' }}>
          <ColoredTable
            ref={selectedPatientsTableRef}
            rowId={'id'}
            columns={selected_patients_columns}
            getData={getPatientCampaigns}
            rowHeight={8}
            showHeaderBelowTable
            checkboxSelection
            checkboxSelectionButtonTitle={'Remove Patients'}
            onRowsSelected={handleSelectRowsTable2}
            filterComponent={
              <Box width="250px">
                <CustomAutoCompleteMultipleSelect
                  options={tags}
                  label={'Patient Groups'}
                  fieldName={'tags'}
                  value={selectedTagsTable2}
                  handleChange={handleTagsChangeTable2}
                />
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectPatients;
