import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { tokens } from 'theme/theme';

type CustomAutoCompleteMultipleSelectProps = {
  options: any[];
  label: string;
  value?: any[];
  fieldName: string;
  span?: number;
  touched?: boolean;
  error?: any;
  handleChange?: (event: any, value: any[]) => void;
  handleChangeCallback?: (value: any[]) => void;
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  width?: string;
  keyLabel?: string;
  optional?: boolean;
};

const CustomAutoCompleteMultipleSelect: React.FC<CustomAutoCompleteMultipleSelectProps> = ({
  options,
  label,
  value = [],
  fieldName,
  span,
  handleChange,
  handleChangeCallback,
  disabled,
  touched,
  error,
  required,
  size = 'small',
  setFieldValue,
  width,
  optional,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedOptions, setSelectedOptions] = useState<any[]>(value);

  const handleSelectChange = (_event: any, value: any[]) => {
    setSelectedOptions(value);
    handleChange && handleChange(_event, value);
    setFieldValue && setFieldValue(fieldName, value);
    handleChangeCallback && handleChangeCallback(value);
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const textFieldId = value.length > 0 ? `custom-autocomplete-${value[0].value}` : `custom-autocomplete-empty`;

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      getOptionLabel={(option) => option.key}
      onChange={handleSelectChange}
      filterSelectedOptions
      disabled={disabled}
      limitTags={3}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label + (optional ? ' (optional)' : '')}
          placeholder={label + (optional ? ' (optional)' : '')}
          error={!!touched && !!error}
          helperText={touched && error}
          required={required}
          size={size}
          id={textFieldId}
          name={textFieldId}
          sx={{
            '.MuiFormLabel-root.Mui-focused': { color: colors.light },
            '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
            '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
            '.MuiFilledInput-root:before': { border: 'none' },
            '.Mui-disabled:before': { border: 'none !important' },
            '.MuiFilledInput-root:after': {
              borderBottom: `1px solid ${colors.primary}`,
              borderRadius: 2,
              height: '100%',
            },
            '.MuiFilledInput-input': { height: '19px' },
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
            gridColumn: span ? 'span ' + span : undefined,
            width: width && width,
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{
        gridColumn: span ? 'span ' + span : undefined,
        '& .MuiAutocomplete-tag': {
          height: '17.5px',
          fontSize: '12px',
        },
        '& .MuiChip-deleteIcon': {
          fontSize: '13px',
        },
        '& .MuiChip-label': {
          padding: '8px 10px',
        },
      }}
    />
  );
};

export default CustomAutoCompleteMultipleSelect;
