import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { ChangeEvent } from 'react';

type CustomMultiSelectCheckboxProps = {
  label: string;
  options: any[];
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  optional?: boolean;
  value?: string[];
  span?: number;
  handleChangeCallback?: (value: string[]) => void;
  setFieldValue?: (field: string, updater: (prevValue: string[]) => string[]) => void;
  fieldName: string;
};

const CustomMultiSelectCheckbox: React.FC<CustomMultiSelectCheckboxProps> = ({
  label,
  options,
  handleChange,
  disabled,
  optional,
  value = [],
  span,
  handleChangeCallback,
  setFieldValue,
  fieldName,
}) => {
  const handleCheckboxChange = (key: string) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedValues = checked ? [...value, key] : value.filter((val) => val !== key);

    setFieldValue?.(fieldName, () => updatedValues);
    handleChange?.(event);
    handleChangeCallback?.(updatedValues);
  };

  return (
    <FormGroup>
      <FormLabel component="legend">{label + (optional ? ' (optional)' : '')}</FormLabel>
      {options.map(({ key }, index) => (
        <FormControlLabel
          key={index}
          sx={{ '& .MuiCheckbox-root': { padding: '0px 9px' } }}
          control={
            <Checkbox
              disabled={disabled}
              checked={value?.includes(key) || false}
              onChange={handleCheckboxChange(key)}
              color="primary"
              name={key}
            />
          }
          label={key}
        />
      ))}
    </FormGroup>
  );
};

export default CustomMultiSelectCheckbox;
