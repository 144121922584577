import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'suppliers';

export const getSupplier = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getSuppliers = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createSupplier = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateSupplier = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteSupplier = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
