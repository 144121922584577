/* eslint-disable react-hooks/exhaustive-deps */

import { CareGoPage } from 'core/PageBuilder';
import GenerateReportDropDown from './GenerateReportDropdown';
import { useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import GeneralLedgerTable from 'company/entities/modules/InventorySystem/ChartOfAccount/Ledger/GeneralLedgerReport';
import JournalEntries from 'company/entities/modules/InventorySystem/ChartOfAccount/Ledger/JournalEntries';
import { useReactToPrint } from 'react-to-print';

type ReportsProps = {};

const Reports: React.FC<ReportsProps> = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportType, setReportType] = useState<string>('');
  const [reportData, setReportData] = useState<any>();

  const printableRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
    pageStyle: '',
  });

  return (
    <CareGoPage header="Reports" breadcrumb={[{ label: 'Reports' }]}>
      <GenerateReportDropDown
        endDate={endDate}
        startDate={startDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setReportData={setReportData}
        setReportTypeCopy={setReportType}
        onPrintHandle={handlePrint}
      />
      <Divider sx={{ marginTop: '2vh', marginBottom: '2vh' }} />
      <Box ref={printableRef} display={reportData ? '' : 'none'}>
        {reportData && reportType !== '' ? (
          reportType === 'ledger' ? (
            <GeneralLedgerTable startDate={startDate} endDate={endDate} ledgerReportData={reportData} />
          ) : reportType === 'journal' ? (
            <JournalEntries startDate={startDate} endDate={endDate} reportData={reportData} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Box>
    </CareGoPage>
  );
};

export default Reports;
