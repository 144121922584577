import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'print-settings';



export const getPrintSettings = () => {
    return axios.get(`${baseURL}/${path}`);
};

export const updatePrintSettings = (id: number, data:any) => {
    return axios.patch(`${baseURL}/${path}/${id}`, data);
}