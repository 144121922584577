import { deleteNoteSummaryTemplate, getPatientNotesSummaryTemplates } from 'company/api/patient-note-summary-template';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { ApiQuery } from 'core/model/interface';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  patientNoteSummaryTemplateFields,
  PatientNoteSummaryTemplateModel,
  patientNoteSummaryTemplateTableColumns,
  PNST_ENTITY_NAME,
} from './PatientNoteSummaryTemplateModel';
import PatientNoteSummaryTemplateForm from './PatientNoteSummaryTemplateForm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
};

const PatientNoteSummaryTemplateTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction } = props;
  const [selectedRow, setSelectedRow] = useState<PatientNoteSummaryTemplateModel>();
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const getData = (query: ApiQuery) => {
    return getPatientNotesSummaryTemplates(query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  function callBackAfterSubmit() {
    refreshTable();
    mainRef.current?.closeFormModal();
  }

  const navigate = useNavigate();
  const update = (data: any) => {
    navigate(`/company/notes-summary/${data.id}`);
  };
  const handleDeleteNoteTemplateSummary = (data: any) => {
    return deleteNoteSummaryTemplate(data);
  };

  const rowActions = [
    {
      label: 'Update',
      action: update,
      icon: <EditOutlinedIcon />,
    },
  ];

  return (
    <TemplateTable
      tableAction={{
        rowActions,
      }}
      // hideAddUpdateDeleteAction
      handleDelete={(data) => handleDeleteNoteTemplateSummary(data)}
      hideUpdateAction
      // hideDeleteAction
      useColoredTable
      ref={mainRef}
      entityName={PNST_ENTITY_NAME}
      fields={patientNoteSummaryTemplateFields}
      getData={getData}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      visibleColumns={visibleColumns ?? patientNoteSummaryTemplateTableColumns}
      templateForm={<PatientNoteSummaryTemplateForm callbackAfterSubmit={callBackAfterSubmit} />}
    />
  );
});

export default PatientNoteSummaryTemplateTable;
