import { CLINIC_ENTITY_NAME, clinicFields, clinicFormFields, clinicInitialValues } from './ClinicModel';
import { useContext, useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';
import { changeNullToBlank } from 'core/utils';
import { createFacility } from 'carego-admin/api/facility';
import { getLanguages } from 'company/api/languages';
import { updateClinic } from 'company/api/facility';
import { update_clinic_schema } from 'company/model/schema';

type Props = {
  clinic?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const ClinicForm = (props: Props) => {
  const { clinic, readOnly, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [languages, setLanguages] = useState<any[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const handleSubmitUpdate = async (data: any) => {
    return clinic ? updateClinic(clinic.id, data) : createFacility(data, user?.company?.id!);
  };

  const getLanguageList = () => {
    getLanguages().then((res) => {
      setLanguages(res.data.data);
    });
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  useEffect(() => {
    setDropdownOptions({
      primary_language: languages.map((u: any) => {
        return { key: u.language, value: u.id };
      }),
    });
  }, [languages]);
  return (
    <TemplateForm
      entityName={CLINIC_ENTITY_NAME}
      fields={clinicFields}
      dropdownOptions={dropdownOptions}
      formSubmitApiFunction={handleSubmitUpdate}
      visibleFields={clinicFormFields}
      initialValues={clinic ? changeNullToBlank(clinic) : clinicInitialValues}
      schema={update_clinic_schema}
      readOnly={readOnly}
      showSubmitAtTop
      entity={clinic}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ClinicForm;
