import CompanyTemplates from 'company/screens/Templates/CompanyTemplates';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';

type CreatePatientNoteModalProps = {
  patient_id?: number;
  forceRefresh?: number;
  patient?: PatientModel;
  setForceRefresh?: (forceRefresh: any) => void;
  setRefreshForNotes?: (refreshForNotes: any) => void;
  appointment?: any;
  onCreate?: (data: any) => void;
};

const CreatePatientNoteModal: React.FC<CreatePatientNoteModalProps> = ({
  patient_id,
  patient,
  forceRefresh,
  setForceRefresh,
  setRefreshForNotes,
  appointment,
  onCreate,
}) => {
  return (
    <>
      <CompanyTemplates
        isFromPatientManagement
        appointment={appointment}
        patient_id={patient_id}
        refreshFromCopy={forceRefresh}
        setRefreshForNotes={setRefreshForNotes}
        onCreate={onCreate}
      />
    </>
  );
};

export default CreatePatientNoteModal;
