import { APPOINTMENT_ACTION, DROPDOWN_FIELD } from 'core/model/interface';
import { Box, Typography, useTheme } from '@mui/material';
import {
  CompanyAppointmentModel,
  WarningNoNumberComponent,
} from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { CustomForm, CustomModal } from 'core/components';
import { Dispatch, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  deleteAppointment,
  getAppointmentProducts,
  getAppointmentServices,
  rescheduleAppointments,
  sendFollowUpReminder,
  sendResultAvailable,
  updateAppointment,
} from 'company/api/appointments';
import { formatDateTimeWithDay, tranformFormErrors } from 'core/utils';
import { reschedule_schema, updateservice_schema } from 'company/model/schema';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CreateInvoice from 'company/screens/Transactions/components/CreateInvoice';
import CreatePatientNoteModal from 'company/screens/RegistrationBoard/components/CreatePatientNoteModal';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { SIZES } from 'theme/constants';
import SmsPreview from './SmsPreview';
import { createNote } from 'company/api/patient-notes';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type AppointmentModalHeaderProps = {
  appointment: CompanyAppointmentModel;
  hideSchedule?: boolean;
};

export const AppointmentModalHeader: React.FC<AppointmentModalHeaderProps> = ({ appointment, hideSchedule }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="grid" gridTemplateColumns="120px 1fr" rowGap={SIZES.paddingS}>
      {!hideSchedule && (
        <>
          <Box display={'flex'} alignItems={'center'} gap={SIZES.paddingS}>
            <AccessTimeIcon sx={{ fontSize: '15px', color: colors.accent }} />
            <Typography variant="body1" color={colors.text}>
              Schedule
            </Typography>
          </Box>
          <Typography variant="body1" color={colors.text}>
            {formatDateTimeWithDay(appointment.schedule)}
          </Typography>
        </>
      )}
      <Box display={'flex'} alignItems={'center'} gap={SIZES.paddingS}>
        <PersonOutlineIcon sx={{ fontSize: '15px', color: colors.accent }} />
        <Typography variant="body1" color={colors.text}>
          Patient
        </Typography>
      </Box>
      <Typography variant="body1" color={colors.text}>
        {appointment.full_name}
      </Typography>
    </Box>
  );
};

type AppointmentModalProps = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  appointment?: CompanyAppointmentModel;
  appointments?: string[];
  facility: FacilityModel;
  updateAppointmentData: () => void;
  action: APPOINTMENT_ACTION;
  setSelectedAppointment?: Dispatch<any>;
  navigateToEditor?: boolean;
  onCreateNote?: (note: any) => void;
  onCreateInvoice?: (invoiceId: number) => void;
};

const AppointmentSettingsModal = forwardRef((props: AppointmentModalProps, ref) => {
  const {
    openModal,
    appointment,
    appointments,
    facility,
    action,
    setOpenModal,
    updateAppointmentData,
    setSelectedAppointment,
    onCreateInvoice,
    navigateToEditor,
    onCreateNote,
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useImperativeHandle(ref, () => ({ refreshProductsTable }));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [smsTemplate, setSmsTemplate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [submitButtonText, setSubmitButtonText] = useState<string>();

  const [header, setHeader] = useState<string>('');
  const [beforeFormComponent, setBeforeFormComponent] = useState<React.ReactNode>();

  const [services, setServices] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  const serviceField: FieldInput = {
    field_name: 'service_id',
    type: DROPDOWN_FIELD.SERVICE,
    span: 4,
    multiple: true,
  };

  const serviceProviderField: FieldInput = {
    field_name: 'provider_id',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    span: 4,
    multiple: false,
    optional: true,
  };

  const scheduleField: FieldInput = {
    field_name: 'schedule',
    display_name: 'Schedule',
    type: 'datetime',
    minutesStep: 5,
    span: 4,
    hidden: action !== APPOINTMENT_ACTION.RESCHEDULE,
  };

  useEffect(() => {
    switch (action) {
      case APPOINTMENT_ACTION.CANCEL:
        setHeader('Cancel Appointment');
        setSmsTemplate('cancelled_appointment');
        setSuccessMessage('Appointment successfully canceled!');
        setSubmitButtonText('Yes');
        setBeforeFormComponent(
          <Typography variant="body1" color={colors.text} fontStyle="italic" style={{ textIndent: '20px' }}>
            Are you sure you want to cancel this appointment? The appointment will be canceled after clicking the 'Yes'
            button.
          </Typography>
        );
        break;

      case APPOINTMENT_ACTION.RESCHEDULE:
        setHeader('Reschedule');
        setSmsTemplate('rescheduled_appointment');
        setSuccessMessage('Appointment successfully rescheduled!');
        setSubmitButtonText(undefined);
        setBeforeFormComponent(undefined);

        break;

      case APPOINTMENT_ACTION.UPDATE_SERVICE:
        setHeader('Update Details');
        setSmsTemplate('');
        setSuccessMessage('Appointment successfully updated!');
        setSubmitButtonText(undefined);
        setBeforeFormComponent(undefined);
        break;

      case APPOINTMENT_ACTION.UPDATE_BATCH:
        setHeader('Update Batch');
        setSmsTemplate('');
        setSuccessMessage('Appointment successfully updated!');
        setSubmitButtonText(undefined);
        setBeforeFormComponent(undefined);
        break;

      case APPOINTMENT_ACTION.FOLLOW_UP_REMINDER:
        setHeader('Send Follow-up Reminder');
        setSmsTemplate('follow_up_reminder_appointment');
        setSuccessMessage('Follow-up reminder will be sent shortly!');
        setSubmitButtonText('Send');
        setBeforeFormComponent(undefined);
        break;

      case APPOINTMENT_ACTION.RESULT_NOTIFICATION:
        setHeader('Send Result Notification');
        setSmsTemplate('appointment_result_notification');
        setSuccessMessage('Result notification will be sent shortly!');
        setSubmitButtonText('Send');
        setBeforeFormComponent(undefined);
        break;

      case APPOINTMENT_ACTION.CREATE_NOTE:
        setHeader('Create Note');
        setSmsTemplate('');
        setSuccessMessage('Note successfully created!');
        setSubmitButtonText(undefined);
        setBeforeFormComponent(undefined);
        break;

      case APPOINTMENT_ACTION.CREATE_INVOICE:
        setHeader('Create Invoice');
        setBeforeFormComponent(undefined);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const callApi = async (data: any) => {
    let final: any = {};
    switch (action) {
      case APPOINTMENT_ACTION.CANCEL:
        await deleteAppointment(facility.id, 'appointments', data);
        break;
      case APPOINTMENT_ACTION.FOLLOW_UP_REMINDER:
        await sendFollowUpReminder(facility.id, 'appointments', data);
        break;
      case APPOINTMENT_ACTION.RESULT_NOTIFICATION:
        await sendResultAvailable(facility.id, 'appointments', data);
        break;
      case APPOINTMENT_ACTION.RESCHEDULE:
        await rescheduleAppointments(facility.id, data);
        break;
      case APPOINTMENT_ACTION.UPDATE_SERVICE:
      case APPOINTMENT_ACTION.UPDATE_BATCH:
        await updateAppointment(facility.id, appointment!.id, data);
        break;
      case APPOINTMENT_ACTION.CREATE_NOTE:
        final = { ...data, source: 'carego', appointment_id: appointment!.id };
        await createNote(facility.id, appointment!.patient_id, final);
        break;
    }
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    try {
      const appointment_ids = JSON.stringify(appointment ? [appointment!.id] : appointments);
      data.appointments = appointment_ids;
      if (data.service_id) data.services = JSON.stringify(data.service_id.map((service: any) => service.value));

      await callApi(data);
      enqueueSnackbar(successMessage, { variant: 'success' });

      if (action !== APPOINTMENT_ACTION.FOLLOW_UP_REMINDER) updateAppointmentData();
      if (action === APPOINTMENT_ACTION.UPDATE_SERVICE) fetchServices();
      setOpenModal(false);
    } catch (error: any) {
      if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
      enqueueSnackbar('Error occured! Please try again.', { variant: 'error' });
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchServices = async () => {
    if ((action === APPOINTMENT_ACTION.UPDATE_SERVICE || action === APPOINTMENT_ACTION.CREATE_INVOICE) && appointment) {
      try {
        const response = await getAppointmentServices(facility.id, appointment.id);
        response.data.forEach((service: any) => (service.original_price = service.unit_price));
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
  };

  const fetchProducts = async () => {
    if (action === APPOINTMENT_ACTION.CREATE_INVOICE) {
      refreshProductsTable();
    }
  };

  const refreshProductsTable = async () => {
    if (appointment) {
      try {
        const response = await getAppointmentProducts(facility.id, appointment.id);
        response.data.forEach((product: any) => (product.original_price = product.unit_price));
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
  };

  useEffect(() => {
    fetchServices();
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, appointment, openModal]);

  const width = action === APPOINTMENT_ACTION.CREATE_NOTE ? 1000 : 500;

  const selectedServices = useMemo(() => {
    return services.length > 0
      ? services.map((u: any) => {
          return { key: u.service_name, value: u.service_id };
        })
      : [];
  }, [services, appointment]);

  return (
    <>
      {action !== APPOINTMENT_ACTION.CREATE_INVOICE && (
        <CustomModal width={width} header={header} open={openModal} setOpen={setOpenModal}>
          {appointment && (
            <Box width="100%" display="flex" flexDirection="column" gap="30px">
              {appointment ? (
                <AppointmentModalHeader appointment={appointment} />
              ) : (
                <Typography variant="body1" color={colors.text}>
                  You selected {appointments!.length} appointment{appointments!.length > 1 ? 's' : ''}
                </Typography>
              )}
              {beforeFormComponent}

              {[APPOINTMENT_ACTION.RESULT_NOTIFICATION, APPOINTMENT_ACTION.FOLLOW_UP_REMINDER].includes(action) && (
                <CustomForm
                  initialValues={{}}
                  onSubmit={handleSubmit}
                  fields={[]}
                  loading={buttonLoading}
                  submitButtonText={submitButtonText}
                  submitButtonMarginTop={2}
                  footerComponent={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {!appointment.mobile_number && <WarningNoNumberComponent />}
                      <SmsPreview templateCode={smsTemplate} facility={facility} appointment={appointment} />
                    </Box>
                  }
                />
              )}

              {[APPOINTMENT_ACTION.RESCHEDULE].includes(action) && (
                <CustomForm
                  initialValues={{ send_sms: false, schedule: appointment?.schedule ?? '' }}
                  onSubmit={handleSubmit}
                  fields={[
                    scheduleField,
                    {
                      field_name: 'send_sms',
                      display_name: 'Send Reschedule SMS to patient',

                      type: 'checkbox',
                      span: 4,
                    },
                    {
                      field_name: 'no_mobile_number',
                      hiddenBasedOnOtherField: (data) => !data.send_sms || (data.send_sms && appointment.mobile_number),
                      type: 'component',
                      component: <WarningNoNumberComponent />,
                    },
                  ]}
                  loading={buttonLoading}
                  submitButtonText={submitButtonText}
                  submitButtonMarginTop={2}
                  footerComponent={
                    <SmsPreview mt="20px" templateCode={smsTemplate} facility={facility} appointment={appointment} />
                  }
                  schema={reschedule_schema}
                />
              )}

              {[APPOINTMENT_ACTION.CANCEL].includes(action) && (
                <CustomForm
                  cancelBtnText="No"
                  showCancelButton
                  onCancel={() => setOpenModal(false)}
                  initialValues={{ send_sms: false, cancel_type: 1, schedule: appointment?.schedule ?? '' }}
                  onSubmit={handleSubmit}
                  fields={[
                    scheduleField,
                    {
                      field_name: 'cancel_type',
                      display_name: 'Cancel',
                      type: 'radiogroup',
                      span: 4,
                      optionsColumn: 1,
                      options: [
                        { key: 'This appointment only', value: 1 },
                        { key: 'This and following appointments', value: 2 },
                      ],
                      hidden: !appointment?.package_patient_id,
                    },
                    {
                      field_name: 'send_sms',
                      display_name: 'Send SMS to patient',
                      type: 'checkbox',
                      span: 4,
                    },
                    {
                      field_name: 'no_mobile_number',
                      hiddenBasedOnOtherField: (data) => !data.send_sms || (data.send_sms && appointment.mobile_number),
                      type: 'component',
                      component: <WarningNoNumberComponent />,
                    },
                  ]}
                  loading={buttonLoading}
                  submitButtonText={submitButtonText}
                  submitButtonStyles={{ backgroundColor: colors.redAccent }}
                  submitButtonMarginTop={2}
                  footerComponent={
                    <SmsPreview mt="20px" templateCode={smsTemplate} facility={facility} appointment={appointment} />
                  }
                />
              )}

              {[APPOINTMENT_ACTION.UPDATE_SERVICE].includes(action) && services && (
                <CustomForm
                  initialValues={{
                    schedule: appointment!.schedule,
                    service_id: selectedServices,
                    consultation_purpose: '',
                    provider_id: appointment!.provider_id ? appointment!.provider_id : '',
                    appointment_tag_id: appointment.appointment_tag_id ?? '',
                  }}
                  onSubmit={handleSubmit}
                  fields={[
                    scheduleField,
                    serviceField,
                    serviceProviderField,
                    { field_name: 'appointment_tag_id', type: DROPDOWN_FIELD.APPOINTMENT_LEGEND, span: 4 },
                  ]}
                  loading={buttonLoading}
                  submitButtonText={submitButtonText}
                  submitButtonMarginTop={2}
                  schema={updateservice_schema}
                />
              )}

              {[APPOINTMENT_ACTION.CREATE_NOTE].includes(action) && (
                <Box width="100%">
                  <CreatePatientNoteModal
                    patient_id={appointment!.patient_id}
                    appointment={appointment}
                    onCreate={(data) => {
                      // setSelectedPrescription && setSelectedPrescription(undefined);
                      // setSelectedPatientNote && setSelectedPatientNote(data);
                      // setRefreshAppointment && setRefreshAppointment((data: any) => data + 1);
                      onCreateNote && onCreateNote(data);
                      setOpenModal(false);
                      if (navigateToEditor) {
                        navigate(`/company/patient-notes/respond/${data.id}`);
                      }
                    }}
                  />
                </Box>
              )}

              {[APPOINTMENT_ACTION.UPDATE_BATCH].includes(action) && (
                <CustomForm
                  initialValues={{ batch_id: appointment?.batch_id ?? '' }}
                  onSubmit={handleSubmit}
                  fields={[
                    {
                      field_name: 'batch_id',
                      display_name: 'Batch',
                      type: DROPDOWN_FIELD.HEALTH_ASSESSMENT_BATCH,
                      span: 4,
                      optional: true,
                    },
                  ]}
                  loading={buttonLoading}
                  submitButtonText={submitButtonText}
                  submitButtonMarginTop={2}
                />
              )}
            </Box>
          )}
        </CustomModal>
      )}
      {action === APPOINTMENT_ACTION.CREATE_INVOICE && appointment && (
        <CreateInvoice
          appointment={appointment}
          orders={[...services, ...products]}
          openModal={openModal}
          setOpenModal={setOpenModal}
          refreshPatientSchedules={updateAppointmentData}
          setSelectedAppointment={setSelectedAppointment && setSelectedAppointment}
          onCreateInvoice={onCreateInvoice}
        />
      )}
    </>
  );
});

export default AppointmentSettingsModal;
