import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-logs';

export const getCompanyLogs = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getCompanyLogValues = (facility_id: number, id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/values/${id}`, { params: query });
};

