import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'campaign-sms';

export const getSms = (facility_id: number, outreach_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${outreach_id}`, {
    params: query,
  });
};

export const createSms = (facility_id: number, outreach_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${outreach_id}`, data);
};

export const updateSms = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteSms = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getAllCampaignSms = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, {
    params: query,
  });
};
