import { Box, Typography } from '@mui/material';

import PatientField from 'company/screens/PatientManagement/components/PatientField';
import RichTextEditor from 'core/components/RichTextEditor';
import { ServiceResponse } from '../Services';

type Props = {
  services?: ServiceResponse;
};

const ServiceDetails: React.FC<Props> = ({ services }) => {
  return (
    <Box>
      {services && (
        <>
          <Typography fontWeight={700} variant="h4">
            Service Details
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
            <PatientField label="Service Name" value={services.service_name} />
            <PatientField label="SMS Name" value={services.sms_name} />
            <PatientField label="Duration" value={services.duration + ' min'} />
            <PatientField label="Tax" value={services.tax ?? ''} />
            <PatientField
              label="Description"
              valueIsComponent
              value={
                <Box>
                  <RichTextEditor content={services.description ?? ''} readOnly />
                </Box>
              }
              sx={{ gridColumn: 'span 2' }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ServiceDetails;
