/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { health_package_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { updateHealthPackage } from 'company/api/health-packages';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UserInput = {
  package_name: string;
  description: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

type UpdateHealthPackageModalProps = {
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  healthPackage: any;
  setUpdatedPackage: any;
  setChildHeader?: any;
  updateInitialValues: any;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const UpdateHealthPackageModal: React.FC<UpdateHealthPackageModalProps> = ({
  setRefresh,
  healthPackage,
  setChildHeader,
  updateInitialValues,
  open,
  setOpen,
  setUpdatedPackage,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const updateFormFields: UserFieldInput[] = [
    { field_name: 'package_name', display_name: 'Session Plan Name', type: 'text', span: 6 },
    {
      field_name: 'description',
      type: 'rich_text',
      display_name: 'Description',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('description', value);
      },
    },
  ];

  const handleSubmitUpdate = (data: any, formikHelpers: FormikHelpers<any>) => {
    if (healthPackage) {
      setLoading(true);
      updateHealthPackage(healthPackage.id, data)
        .then((res) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((err) => console.error(err));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      setChildHeader && setChildHeader(res.data.package.package_name);
      setUpdatedPackage(res.data.package);
      enqueueSnackbar('Health Session successfully updated!', { variant: 'success' });
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setLoading(false);
    setRefresh((prev: number) => prev + 1);
  };

  return (
    healthPackage && (
      <CustomModal open={open} setOpen={setOpen} header={'Update Health Session'}>
        <CustomForm
          onSubmit={handleSubmitUpdate}
          fields={updateFormFields}
          initialValues={updateInitialValues}
          schema={health_package_schema}
          loading={loading}
          span={6}
        />
      </CustomModal>
    )
  );
};

export default UpdateHealthPackageModal;
