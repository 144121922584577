import { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import { CareGoPage } from 'core/PageBuilder';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { LoadingScreen } from 'core/screens';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { TabProps } from 'core/components/CustomTabs';
import User from './components/User';
import { UserContext } from 'core/context/user.context';
import UserGroup from './components/UserGroup';
// import UserGroup from './components/UserGroupBackup';
import UserGroupPermission from './components/UserGroupPermission';

const CompanyUserManagement = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user) setIsLoading(false);
  }, [user]);

  const tabs: TabProps[] = [
    {
      label: 'User',
      icon: <PersonOutlineOutlinedIcon />,
      content: user?.account_type === AccountType.COMPANY ? <User /> : <></>,
    },
    {
      label: 'User Groups',
      icon: <GroupOutlinedIcon />,
      content: <UserGroup />,
    },
    {
      label: 'User Group Permissions',
      icon: <LockPersonOutlinedIcon />,
      content: <UserGroupPermission />,
    },
  ];

  return (
    <LoadingScreen loading={isLoading}>
      <CareGoPage
        header="User Management"
        tabs={tabs}
        tabMinWidth="160px"
        // tabStyles={{
        //   paddingInline: '0',
        // }}
      />
    </LoadingScreen>
  );
};

export default CompanyUserManagement;
