import { Box, Collapse, Typography } from '@mui/material';
import { CustomIconButton, CustomModal, RegularButton } from 'core/components';
import { DentalChart, DentalChartCircles } from 'company/components/MedicalCharts';
import React, { useState } from 'react';

import { Components } from '..';
import CustomForm from 'core/components/forms/CustomForm';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FORM_MODE } from 'core/model/interface';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

type DocumentChartProps = {
  component: Components;
  mode?: FORM_MODE;
  forceSubmit: boolean;
  setIsDirty?: (isDirty: boolean) => void;
};

const DocumentChart: React.FC<DocumentChartProps> = ({ mode, component, forceSubmit, setIsDirty }) => {
  const [open, setOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSaveChanges = (data: any) => {
    component.title = data.title;
    component.description = data.description;
    component.chart_type = data.chart_type;
    setIsModalOpen(false);
    setIsDirty && setIsDirty(true);
  };

  return (
    <Box
      sx={{
        borderRadius: '10px',
        padding: mode !== FORM_MODE.RESPOND ? '12px 12px' : '24px 12px',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box mb="20px">
          {(component.title || component.description) && (
            <>
              <Typography variant="h4" fontWeight="bold">
                {component.title}
              </Typography>
              <Typography>{component.description}</Typography>
            </>
          )}
        </Box>

        {mode === FORM_MODE.EDIT && (
          <CustomIconButton icon={<SettingsOutlinedIcon />} onClick={() => setIsModalOpen(true)} tooltip="Edit Chart" />
        )}
      </Box>

      {mode === FORM_MODE.EDIT && (
        <RegularButton
          startIcon={open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          label={open ? 'Hide' : 'Show'}
          onClick={() => setOpen(!open)}
          variant="text"
          color="inherit"
        />
      )}
      <Collapse in={open || mode === FORM_MODE.RESPOND} timeout="auto">
        {component.chart_type === 'dental_chart_circles' ? (
          <DentalChartCircles
            mode={mode}
            initialData={component.responses}
            forceSubmit={forceSubmit}
            handleSubmit={(data) => (component.responses = data)}
            setIsDirty={setIsDirty}
          />
        ) : (
          <DentalChart
            mode={mode}
            initialData={component.responses}
            forceSubmit={forceSubmit}
            handleSubmit={(data) => (component.responses = data)}
            setIsDirty={setIsDirty}
          />
        )}
      </Collapse>

      <CustomModal
        header="Edit Chart"
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <CustomForm
          onSubmit={handleSaveChanges}
          fields={[
            { field_name: 'title', display_name: 'Title', type: 'text', span: 4 },
            { field_name: 'description', display_name: 'Description', type: 'text', span: 4, multiline: true, rows: 3 },
            {
              field_name: 'chart_type',
              display_name: 'Chart Type',
              type: 'select',
              span: 4,
              options: [
                { key: 'Dental Chart - Version 1', value: 'dental_chart_1' },
                { key: 'Dental Chart - Version 2 (Circles)', value: 'dental_chart_circles' },
              ],
            },
          ]}
          initialValues={{
            title: component.title,
            description: component.description,
            chart_type: component.chart_type ?? '',
          }}
          loading={false}
        />
      </CustomModal>
    </Box>
  );
};

export default DocumentChart;
