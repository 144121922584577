import { Box, Typography, useTheme } from '@mui/material';

import { ContainerColumn } from 'core/components/containers';
import { RegularButton } from 'core/components';
import { SIZES } from 'theme/constants';
import { createOneMedicationReminder } from 'company/api/medication-reminder';
import { generateInstruction } from 'core/utils';
import { tokens } from 'theme/theme';

type Props = {
  patientDetails: any;
  prescription?: any;
  callbackAfterSubmit?: (response: any) => void;
};

type RowProp = {
  detail: string;
  value: string;
};

type InstructionProp = {
  instruction: string;
};

const Detail = ({ detail, value }: RowProp) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '200px 1fr', columnGap: SIZES.padding }}>
      <Typography fontWeight={'500'} variant="h5">
        {detail}
      </Typography>
      <Typography variant="h5">{value}</Typography>
    </Box>
  );
};

const Instruction = ({ instruction }: InstructionProp) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ p: 2, borderRadius: '10px', border: `1px solid ${colors.primary}` }}>
      <Typography variant="h4" fontWeight={'600'} marginBottom={1}>
        Instruction:
      </Typography>
      <Typography variant="h5">{instruction}</Typography>
    </Box>
  );
};

// Main component
const SendReminders = (props: Props) => {
  const { callbackAfterSubmit, patientDetails, prescription } = props;
  const boxDesign = {
    display: 'flex',
    rowGap: SIZES.padding,
    flexDirection: 'column',
  };

  return (
    <Box sx={boxDesign}>
      <ContainerColumn gap={'5px'}>
        <Detail detail="Patient Name:" value={patientDetails.full_name} />
        <Detail detail="Mobile Number:" value={patientDetails.mobile_number} />
        <Detail detail="Medicine (Generic Name):" value={prescription.generic_name} />
      </ContainerColumn>
      <Instruction instruction={generateInstruction(prescription)} />
      {!prescription.frequency && (
        <Typography>Details are incomplete for sending reminders. Please update the instructions.</Typography>
      )}
      <Box display="flex" justifyContent="flex-end" columnGap={1}>
        <RegularButton
          label="Confirm"
          disabled={!prescription.frequency}
          onClick={() => {
            createOneMedicationReminder({
              patient_id: patientDetails.patient_id,
              prescription_line_id: prescription.id,
            }).then((response) => {
              callbackAfterSubmit && callbackAfterSubmit(response);
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default SendReminders;
