import { Box, useTheme } from '@mui/material';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { tokens } from 'theme/theme';

type Props = {
  visible: boolean;
  parentRef: any;
  position?: { bottom: number; right: number };
};

function BackToTopButton({ visible, parentRef, position }: Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function goBackTop() {
    const parentElement = parentRef.current as any;
    parentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (visible)
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: position?.bottom ? `${position.bottom}px` : '100px',
          right: position?.right ? `${position.right}px` : '30px',
          height: '44px',
          borderRadius: '50%',
          width: '44px',
          zIndex: 3000,
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.8,
          cursor: 'pointer',
        }}
      >
        <ArrowUpwardIcon sx={{ fontSize: '24px', color: colors.white_text }} onClick={goBackTop} />
      </Box>
    );
  return <></>;
}

export default BackToTopButton;
