import { UserAuthority } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-user-authority';

export const caregoGetUserAuthorities = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const caregoGetUserAuthority = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const caregoCreateAuthority = (data: UserAuthority) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const caregoUpdateGroupAuthority = (data: UserAuthority) => {
  return axios.put(`${baseURL}/${path}`, data);
};

export const caregoDeleteGroupAuthority = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const caregoDeleteGroupAuthorityByName = (groupName: string) => {
  return axios.delete(`${baseURL}/${path}/many/${groupName}`);
};