import { ApiQuery } from 'core/model/interface';
import { SMSTemplatesModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-sms-templates';

export const getPendingSmsTemplates = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getPendingSmsTemplate = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getCommonSmsTemplates = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/common`, { params: query });
};

export const getAdditionalSmsTemplates = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/additional`, { params: query });
};

export const declineAdditionalSmsTemplate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const approveAdditionalSmsTemplate = (id: number) => {
  return axios.put(`${baseURL}/${path}/${id}`);
};

export const getCompanySMSTemplates = (company_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/company/${company_id}`, { params: query });
};

export const getLocalizedTemplates = (template_id: number, company_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${company_id}/localized/${template_id}`, {
    params: query,
  });
};

export const updateLocalizedTemplates = (template_id: number, template_body: string) => {
  return axios.put(`${baseURL}/${path}/company/localized/${template_id}`, {
    template_body,
  });
};

export const createLocalizedTemplates = (template_id: number, template: SMSTemplatesModel) => {
  return axios.post(`${baseURL}/${path}/company/localized/${template_id}`, template);
};

export const getAvailableLanguages = (template_id: number, company_id: number) => {
  return axios.get(`${baseURL}/${path}/${company_id}/company-languages/${template_id}`);
};

export const createCompanyTemplate = (company_id: number, template: SMSTemplatesModel) => {
  return axios.post(`${baseURL}/${path}/${company_id}`, template);
};

export const updateCompanyTemplate = (company_id: number, template_id: number, template: SMSTemplatesModel) => {
  return axios.put(`${baseURL}/${path}/${company_id}/${template_id}`, template);
};
