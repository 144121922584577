import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import RegularButton from './buttons/RegularButton';
import SignatureCanvas from 'react-signature-canvas';

type CustomSignatureProps = {
  label: string;
  handleChange?: (fieldName: string, imageURL: string) => void;
  span?: number;
  value?: string;
  optional?: boolean;
  fieldName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const CustomSignature: React.FC<CustomSignatureProps> = ({
  label,
  handleChange,
  span,
  value,
  optional,
  fieldName,
  setFieldValue,
}) => {
  const signatureCanvasRef = useRef<any>(null);

  const clearSignature = () => {
    setFieldValue(fieldName, '');
    signatureCanvasRef.current?.clear();
  };

  const handleCanvasEnd = () => {
    if (!signatureCanvasRef.current?.isEmpty()) {
      const imageURL = signatureCanvasRef.current?.toDataURL();
      setFieldValue(fieldName, imageURL);

      if (handleChange) {
        handleChange(fieldName, imageURL || '');
      }
    }
  };

  const getSignatureImage = () => {
    const imageURL = signatureCanvasRef.current?.toDataURL();

    const downloadLink = document.createElement('a');
    downloadLink.href = imageURL || '';
    downloadLink.download = 'signature.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    if (handleChange) {
      handleChange(fieldName, imageURL || '');
    }
  };

  useEffect(() => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
      if (value) {
        signatureCanvasRef.current.fromDataURL(value);
      }
    }
  }, [value]);

  return (
    <Box width="100%" gridColumn={`span ${span}`} display="flex" flexDirection="column" gap="10px">
      <Typography variant="h6" gutterBottom>
        {label + (optional ? ' (optional)' : '')}
      </Typography>

      <Box sx={{ border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
        <SignatureCanvas
          ref={signatureCanvasRef as React.MutableRefObject<any>}
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          onEnd={handleCanvasEnd}
        />
      </Box>

      <Box display="flex" flexDirection="row" gap="10px" width="100%">
        <RegularButton variant="outlined" onClick={clearSignature} label="Clear Signature" />
        <RegularButton variant="outlined" onClick={getSignatureImage} label="Download Signature" />
      </Box>
    </Box>
  );
};

export default CustomSignature;
