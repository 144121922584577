import React from 'react';
import { Box } from '@mui/material';
import CustomFilter from 'core/components/CustomFilter';

interface CustomClinicFilterProps {
  facilities: Array<{
    id: number;
    facility_name: string;
  }>;
  clinicFilters: string[];
  setClinicFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomClinicFilter: React.FC<CustomClinicFilterProps> = ({ facilities, clinicFilters, setClinicFilters }) => {
  const facilityOptions = facilities.map((facility) => ({
    label: facility.facility_name,
    id: facility.id.toString(),
  }));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {facilityOptions.length > 0 && (
        <CustomFilter
          filterLabel="Filter by Clinic"
          optionList={facilityOptions}
          selected={clinicFilters}
          setSelected={setClinicFilters}
          span={2}
          selectAllOption={true}
        />
      )}
    </Box>
  );
};

export default CustomClinicFilter;
