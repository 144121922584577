/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { tranformFormErrors } from 'core/utils';
import { updateClinic } from 'company/api/facility';
import { update_clinic_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type ClinicFormProps = {
  refreshTable?: (refresh: any) => void;
  clinic: FacilityModel;
  languages: any[];
  setChildHeader?: any;
  setIsClinicDetailsDone?: (done: any) => void;
  callbackOnSuccess?: (data: any) => void;
};

type UserInput = {
  facility_name: string;
  primary_language: number;
  region_name: string;
  province_name: string;
  municipality_name: string;
  address: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  banner_image: string;
  description: string;
  email: string;
};

const ClinicForm: React.FC<ClinicFormProps> = ({
  refreshTable,
  clinic,
  languages,
  setChildHeader,
  setIsClinicDetailsDone,
  callbackOnSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    facility_name: clinic.facility_name,
    primary_language: clinic.primary_language,
    region_name: clinic.region_name,
    province_name: clinic.province_name,
    municipality_name: clinic.municipality_name,
    address: clinic.address,
    smart_mobile_number: clinic.smart_mobile_number,
    globe_mobile_number: clinic.globe_mobile_number,
    banner_image: clinic.banner_image ? clinic.banner_image : '../../assets/blank_banner.png',
    description: clinic.description ? clinic.description : '',
    email: clinic.email ? clinic.email : '',
  };

  // Form Fields
  const clinicFields: FieldInput[] = [
    { field_name: 'facility_name', display_name: 'Clinic Name', type: 'text', span: 3 },
    {
      field_name: 'primary_language',
      display_name: 'Language',
      type: 'select',
      options:
        languages &&
        languages.map((u: any) => {
          return { key: u.language, value: u.id };
        }),
      span: 3,
    },
    { field_name: 'region_name', display_name: 'Region', type: 'text', span: 3 },
    { field_name: 'province_name', display_name: 'Province', type: 'text', span: 3 },
    { field_name: 'municipality_name', display_name: 'Municipality', type: 'text', span: 3 },
    { field_name: 'address', display_name: 'Address', type: 'text', span: 3 },
    { field_name: 'smart_mobile_number', display_name: 'Smart Mobile Number (Optional)', type: 'text', span: 3 },
    { field_name: 'globe_mobile_number', display_name: 'Globe Mobile Number (Optional)', type: 'text', span: 3 },
    { field_name: 'email', display_name: 'Email (Optional)', type: 'text', span: 3 },
    {
      field_name: 'banner_image',
      display_name: 'Banner Image',
      type: 'image_upload',
      isBanner: true,
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('banner_image', value);
      },
    },
    {
      field_name: 'description',
      display_name: 'Introduce your clinic',
      type: 'rich_text',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('description', value);
      },
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    updateClinic(clinic.id, data)
      .then((res) => {
        successFormSubmit(res, formikHelpers);
        callbackOnSuccess && callbackOnSuccess(res.data);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      setChildHeader && setChildHeader(res.data.clinic.facility_name);
      enqueueSnackbar(`Clinic successfully updated!`, { variant: 'success' });
      setIsClinicDetailsDone && setIsClinicDetailsDone(true);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable && refreshTable((prev: number) => prev + 1);
  };

  return (
    clinic && (
      <Box width="100%">
        <CustomForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          fields={clinicFields}
          schema={update_clinic_schema}
          loading={buttonLoading}
          span={6}
          submitButtonText="Save"
          // showSubmitAtTop
          submitButtonId={'patient_form_submit_button'}
        />
      </Box>
    )
  );
};

export default ClinicForm;
