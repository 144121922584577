import { CustomContainer, Header, ProtectedComponent } from 'core/components';
import { useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import HealthWorkers from './components/HealthWorkers';
import MemberAccounts from './components/MemberAccounts';

const RegistrationBoard = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([
      {
        label: 'Staff Management',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Header title="Staff Management" />
      <ProtectedComponent requiredAuth={['LGU_ADMIN_ACTIONS']}>
        <CustomContainer>
          <MemberAccounts />
        </CustomContainer>
      </ProtectedComponent>

      <CustomContainer m="20px 0 0 0">
        <HealthWorkers />
      </CustomContainer>
    </Box>
  );
};

export default RegistrationBoard;
