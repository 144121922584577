/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Grid } from '@mui/material';
import { CustomGridCell, CustomTable, PrimaryButton } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { calculateAge, formatDateTime } from 'core/utils';
import { getPatients, getPatientsNotEnrolledInProgram } from 'company/api/patient';
import { useContext, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { ProgramPatientModel } from 'company/model/Entities';
import { useSnackbar } from 'notistack';

type EnrollToProgramReminderModalProps = {
  programCode: string;
  setPatientToEnroll: (patient: ProgramPatientModel) => void;
  setPatientsToEnroll: (patients: ProgramPatientModel[]) => void;
  setOpenEnrollmentFormModal: (open: boolean) => void;
  forceRefresh?: number;
  enrollButtonLabel?: string;
  getAllPatients?: boolean;
  inHealthPrograms?: boolean;
  clearSelected?: number;
  sensitive?: boolean;
};

const EnrollToProgramReminderModal: React.FC<EnrollToProgramReminderModalProps> = ({
  programCode,
  setPatientToEnroll,
  setPatientsToEnroll,
  setOpenEnrollmentFormModal,
  forceRefresh,
  enrollButtonLabel,
  getAllPatients,
  inHealthPrograms,
  clearSelected,
  sensitive,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClientIDs, setSelectedClientIDs] = useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'age',
      headerName: 'Age',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex Assigned at Birth', flex: 0.5 },
    {
      field: 'created_at',
      headerName: 'Date Registered',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setPatientToEnroll(params.row);
              setPatientsToEnroll([]);
              setOpenEnrollmentFormModal(true);
              event.stopPropagation();
            }}
          >
            {inHealthPrograms ? 'Add' : enrollButtonLabel ?? 'Enroll'}
          </Button>
        </Box>
      ),
    },
  ];

  const sensitive_columns: GridColDef[] = [
    {
      field: 'uic',
      headerName: 'UIC',
      flex: 1.5,
    },
    { field: 'encrypted_mobile_number', headerName: 'Mobile Number', flex: 1 },
    columns[2],
    columns[3],
    columns[4],
    columns[5],
  ];

  const getPatientsList = async (query: ApiQuery) => {
    if (facility) {
      setLoading(true);
      try {
        let res: any;
        if (getAllPatients) {
          res = await getPatients(facility.id, query);
        } else {
          res = await getPatientsNotEnrolledInProgram(facility.id, programCode, query);
        }
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSelectClients = (selected: GridRowSelectionModel) => {
    setSelectedClientIDs(selected);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CustomTable
          columns={sensitive ? sensitive_columns : columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'patient_id'}
          getData={getPatientsList}
          forceRefresh={forceRefresh}
          rowHeight={45}
          selectableRow
          checkboxSelection
          handleSelectRow={handleSelectClients}
          clearSelected={clearSelected}
          initialOrderBy="created_at"
          initialOrder="desc"
          headerComponent={
            <Box display="flex" gap="10px">
              <PrimaryButton
                onClick={() => {
                  if (selectedClientIDs.length) {
                    setOpenEnrollmentFormModal(true);
                    setPatientsToEnroll(selectedClientIDs);
                  } else {
                    enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
                  }
                }}
                label={inHealthPrograms ? 'Add Cycle to Selected Patients' : 'Add Selected Patients'}
                disabled={!selectedClientIDs.length}
              />
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
};

export default EnrollToProgramReminderModal;
