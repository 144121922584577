import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';

export const CHART_OF_ACCOUNT_ENTITY_NAME = 'Chart Of Accounts';

export const chartOfAccountFields: EntityFields[] = [
  { fieldName: 'account_type_code', displayName: 'Account Type Code', type: DROPDOWN_FIELD.ACCOUNT_TYPE },
  { fieldName: 'account_name', displayName: 'Account Name', type: 'text' },
];

export const chartOfAccountVisibleColumns = ['account_name', 'account_type_code'];
export const chartOfAccountInitialValues = {
  account_name: '',
  account_type_code: '',
};
