import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export enum ENTITY {
  PATIENT = 'patient',
  SERVICE = 'service',
}

export enum ACTIONS {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

export enum RESPONSE {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const showSnackbar = (
  response: RESPONSE,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  message: { entityName?: string; action?: string; customMessage?: string }
) => {
  if (response === RESPONSE.SUCCESS) {
    enqueueSnackbar(message.customMessage ?? `${message.entityName} successfully ${message.action}!`, {
      variant: 'success',
    });
  } else {
    enqueueSnackbar(message.customMessage ?? `${message.entityName} is not successfully ${message.action}!`, {
      variant: 'error',
    });
  }
};
