import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'prescription-line';

export const getPrescriptionLines = (facility_id: number, prescription_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${prescription_id}`, {
    params: query,
  });
};