const chat = require('./chat.png');
const send = require('./send.png');

const upcoming = require('./upcoming.png');
const all =  require('./all.png');
const past = require('./past.png');

const unlock = require('./unlocked.png');
const lock = require('./locked.png');

const unpaid = require('./unpaid.png');

export { chat, send, upcoming, past, unlock, lock, all, unpaid };
