import { ApiQuery } from 'core/model/interface';
import { CompanyCampaignModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'campaigns';

export const getCampaigns = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getCampaign = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getPatientsForCampaign = (facility_id: number, id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patients/${id}`, { params: query });
};

export const createCampaign = (facility_id: number, data: CompanyCampaignModel) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const duplicateCampaign = (facility_id: number, id: number, data: CompanyCampaignModel) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/duplicate/${id}`, data);
};

export const updateCampaign = (facility_id: number, id: number, data: CompanyCampaignModel) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteCampaign = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateCampaignStatus = (facility_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/status/${id}`, data);
};
