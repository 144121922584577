import * as yup from 'yup';

import { EntityFields } from 'company/entities/utils';
import { DROPDOWN_FIELD } from 'core/model/interface';

export const ATTRIBUTE_ENTITY_NAME = 'Attribute';

export interface VariantAttributeModel {
  id: number;
  attribute_name: string;
  category_id?: { key: string, value: number }[];
  company_id: number;
  attribute_value_1?: string;
  attribute_value_2?: string;
  attribute_value_3?: string;
  attribute_value_4?: string;
  attribute_value_5?: string;
}

export const attributeModelSchema = yup.object().shape({
  attribute_name: yup.string().required('Attribute Category is required.').max(30, 'Attribute Category must be at most 30 characters.'),
  attribute_value_1: yup.string().max(30, 'Attribute Value must be at most 30 characters.'),
  attribute_value_2: yup.string().max(30, 'Attribute Value must be at most 30 characters.'),
  attribute_value_3: yup.string().max(30, 'Attribute Value must be at most 30 characters.'),
  attribute_value_4: yup.string().max(30, 'Attribute Value must be at most 30 characters.'),
  attribute_value_5: yup.string().max(30, 'Attribute Value must be at most 30 characters.'),
});


export const attributeFields: EntityFields[] = [
  { fieldName: 'attribute_name', displayName: 'Attribute Category' },
  {
    fieldName: 'category_id',
    type: DROPDOWN_FIELD.PRODUCT_CATEGORY,
    optional: true,
    multiple: true,
  },
  { fieldName: 'divider_1', type: 'divider' },
  { fieldName: 'attribute_value_1', displayName: 'Attribute Value 1 (Optional)' },
  { fieldName: 'attribute_value_2', displayName: 'Attribute Value 2 (Optional)' },
  { fieldName: 'attribute_value_3', displayName: 'Attribute Value 3 (Optional)' },
  { fieldName: 'attribute_value_4', displayName: 'Attribute Value 4 (Optional)' },
  { fieldName: 'attribute_value_5', displayName: 'Attribute Value 5 (Optional)' },
];

export const visibleAttributeColumns = ['attribute_name'];
export const addAttributeFormFields = [
  'attribute_name',
  'category_id',
  'divider_1',
  'attribute_value_1',
  'attribute_value_2',
  'attribute_value_3',
  'attribute_value_4',
  'attribute_value_5',
];
export const attributeInitialValues: Partial<VariantAttributeModel> = {
  attribute_name: '',
  attribute_value_1: '',
  attribute_value_2: '',
  attribute_value_3: '',
  attribute_value_4: '',
  attribute_value_5: '',
  category_id: []
};
