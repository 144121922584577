/* eslint-disable react-hooks/exhaustive-deps */
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomTable,
  PrimaryButton,
  RowAction,
} from 'core/components';
import { calculateAge, changeNullToBlank, formatDate } from 'core/utils';
import { exportPatientsEnrolledInProgramReminder, getPatientsEnrolledInProgramReminders } from 'lgu/api/patient';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EnrollToProgramReminder from '../components/common/EnrollToProgramReminder';
import FpUpdateModal from '../components/FP/FpUpdateModal';
import { GridColDef } from '@mui/x-data-grid';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { SelectOption } from 'core/model/interface';
import { UserContext } from 'core/context/user.context';
import { unenrollProgramRemindersPatients } from 'lgu/api/program-reminders-patients';
import { useSnackbar } from 'notistack';

const FpPatientEnrollment = () => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openEnrollModal, setOpenEnrollModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [patient, setPatient] = useState<any>();
  const [refreshEnrolled, setRefreshEnrolled] = useState(0);
  const [type, setType] = useState<'update' | 'new cycle'>('update');
  const options: SelectOption[] = [
    {
      key: 'OCP - 28 Pills',
      value: 28,
    },
    {
      key: 'OCP - 21 Pills',
      value: 21,
    },
  ];

  //unenroll
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //representatives
  // const [openRepresentativeModal, setOpenRepresentativeModal] =
  //   useState<boolean>(false);

  //update
  const [openFamilyPlanningUpdateForm, setOpenFamilyPlanningUpdateForm] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: 'full_name', headerName: 'Patient Name', flex: 1.5 },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1, sortable: false },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    { field: 'ocp_count', headerName: 'Pill Count', flex: 0.5, headerAlign: 'right', align: 'right' },
    {
      field: 'next_restock',
      headerName: 'Restock Deadline',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.next_restock)}</CustomGridCell>,
    },
    {
      field: 'action',
      flex: 0.5,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const showUnenrollPatient = (data: any) => {
    showUnenrollPatientDialog('Are you sure you want to unenroll ' + data.full_name + '?');
    setPatient(data);
  };

  const showUnenrollPatientDialog = (content: string) => {
    setOpenPatientUnenrollDialog(true);
    setAlertContent(content);
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify([patient.id]);
    const data = { patients: patientList };
    unenrollProgramRemindersPatients('ocp', data)
      .then((res) => {
        enqueueSnackbar('Patient successfully unenrolled!', { variant: 'success' });
        getPatientsEnrolled({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const updateFpPatient = (data: any) => {
    setPatient(changeNullToBlank(data));
    setType('update');
    setOpenFamilyPlanningUpdateForm(true);
  };

  const startNewCycle = (data: any) => {
    setPatient(changeNullToBlank(data));
    setType('new cycle');
    setOpenFamilyPlanningUpdateForm(true);
  };

  const actions = [
    { label: 'Update Current Cycle', action: updateFpPatient, icon: <EditOutlinedIcon /> },
    { label: 'Add New Cycle', action: startNewCycle, icon: <AddIcon /> },
    { label: 'Unenroll Patient', action: showUnenrollPatient, icon: <PersonRemoveIcon /> },
  ];

  const getPatientsEnrolled = (query: ApiQuery) => {
    setLoading(true);
    getPatientsEnrolledInProgramReminders('ocp', query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const exportRecords = async (query: ApiQuery) => {
    exportPatientsEnrolledInProgramReminder('ocp', query, user.lgu?.lgu_name);
  };

  const showEnrollmentModal = () => {
    setOpenEnrollModal(true);
  };

  // const showRepresentativeModal = () => {
  //   setOpenRepresentativeModal(true);
  // };

  const refreshTable = () => {
    setRefreshEnrolled((prev: number) => prev + 1);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'OCP' }]);
  }, []);

  return (
    <Box>
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton onClick={() => showEnrollmentModal()} label="Enroll Patients" />
          {/* <Box display="flex" gap="10px">
            <RegularButton
              onClick={() => showRepresentativeModal()}
              label="View Representatives"
            />
            <RegularButton
              onClick={() => showRepresentativeModal()}
              label="View SMS Schedules"
            />
          </Box> */}
        </Box>

        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getPatientsEnrolled}
          forceRefresh={refreshEnrolled}
          exportRecords={exportRecords}
        />
      </CustomContainer>

      <EnrollToProgramReminder
        programCode={'ocp'}
        options={options}
        refreshEnrolledTable={refreshTable}
        open={openEnrollModal}
        setOpen={setOpenEnrollModal}
      />

      <ConfirmationDialog
        open={openPatientUnenrollDialog}
        setOpen={setOpenPatientUnenrollDialog}
        content={alertContent}
        onConfirm={handlePatientUnenroll}
      />

      {/* <Representative
        programCategoryCode={"ocp"}
        open={openRepresentativeModal}
        setOpen={setOpenRepresentativeModal}
      /> */}

      <FpUpdateModal
        header={type === 'update' ? 'Update Current Cycle' : 'Add New Cycle'}
        open={openFamilyPlanningUpdateForm}
        setOpen={setOpenFamilyPlanningUpdateForm}
        type={type}
        patient={patient}
        refreshTable={refreshTable}
      />
    </Box>
  );
};

export default FpPatientEnrollment;
