import React from 'react';
import {
  SELF_REGISTRATION_ENTITY_NAME,
  selfRegistrationFields,
  selfRegistrationFormFields,
  selfRegistrationInitialValues,
  selfRegistrationSchema,
} from './SelfRegistrationModel';

import TemplateForm from '../../../Template/TemplateForm';
import { createSelfRegistration } from 'company/api/self-registration';

type Props = {
  facilityId: number;
  callbackAfterSubmit?: (data?: any) => void;
};

const SelfRegistrationForm: React.FC<Props> = ({ facilityId, callbackAfterSubmit }) => {
  const handleSubmit = async (data: any) => {
    const result = await createSelfRegistration(facilityId, data);
    if (callbackAfterSubmit) {
      callbackAfterSubmit(result);
    }
    return result;
  };

  return (
    <TemplateForm
      entityName={SELF_REGISTRATION_ENTITY_NAME}
      fields={selfRegistrationFields}
      visibleFields={selfRegistrationFormFields}
      initialValues={selfRegistrationInitialValues}
      schema={selfRegistrationSchema}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      submitButtonText="Submit Registration"
      useInitialValue={true}
      resetOnSubmit={true}
    />
  );
};

export default SelfRegistrationForm;
