import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderTableType } from 'core/PageBuilder';
import React, { useContext, useEffect, useState } from 'react';
import { createSms, deleteSms, getSms, updateSms } from 'company/api/campaign-sms';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { formatDateTime } from 'core/utils';
import { outreach_sms_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

export type SmsScheduleInput = {
  send_date_time: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof SmsScheduleInput;
}

type CampaignSmsProps = {
  outreachID: number;
  campaignID: number;
  outreachTemplate: string;
};

const CampaignSms: React.FC<CampaignSmsProps> = ({ outreachID, campaignID, outreachTemplate }) => {
  const { facility } = useContext(FacilityContext);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const [schedule, setSchedule] = useState<any>();
  const [editRowsModel, setEditRowsModel] = useState<Record<string, boolean>>({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  // Form Fields
  const fields: UserFieldInput[] = [
    {
      field_name: 'send_date_time',
      display_name: 'Schedule',
      type: 'datetime',
      minutesStep: 5,
      span: 4,
    },
  ];

  const initialValues: SmsScheduleInput = {
    send_date_time: schedule ? schedule.send_date_time : '',
  };

  const columns: GridColDef[] = [
    {
      field: 'send_date_time',
      headerName: 'Schedule',
      flex: 1,
      renderCell: (params) => <Typography>{formatDateTime(params.value)}</Typography>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      renderCell: (params) => (
        <Box display="flex" gap="10px">
          <RegularButton
            label={editRowsModel[params.row.id] ? 'Save' : 'Edit'}
            size="small"
            color={editRowsModel[params.id] ? 'primary' : 'success'}
            startIcon={
              editRowsModel[params.row.id] ? (
                <SaveOutlinedIcon fontSize="small" />
              ) : (
                <EditOutlinedIcon fontSize="small" />
              )
            }
            onClick={() => {
              // handleEditButtonClick(params.row.id);
              setOpenUpdateModal(true);
              setSchedule(params.row);
            }}
          />

          <RegularButton
            label="Remove"
            size="small"
            color="error"
            startIcon={<DeleteOutlinedIcon fontSize="small" />}
            onClick={() => {
              setSchedule(params.row);
              setOpenConfirmationDialog(true);
            }}
          />
        </Box>
      ),
    },
  ];

  const getAllSchedules = async (query: ApiQuery) => {
    return getSms(facility.id, outreachID, { ...query });
  };

  const handleSubmit = async (data: any) => {
    if (schedule) {
      // setEditRowsModel((prevModel) => ({
      //   ...prevModel,
      //   [data.id]: false,
      // }));
      setLoading(true);
      return updateSms(facility.id, schedule.id, data)
        .then((res) => {
          enqueueSnackbar('SMS Schedule successfully updated!', { variant: 'success' });
          refreshTable();
        })
        .finally(() => {
          setLoading(false);
        });
    }

    data.campaign_id = campaignID;
    data.sms_template = outreachTemplate;

    return createSms(facility.id, outreachID, data).then((res) => {
      enqueueSnackbar('SMS Schedule successfully created!', { variant: 'success' });
      refreshTable();
    });
  };

  const handleDelete = () => {
    return deleteSms(facility.id, schedule.id).then((res) => {
      refreshTable();
      enqueueSnackbar('SMS Schedule successfully removed!', { variant: 'success' });
    });
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (!openUpdateModal) {
      setSchedule(undefined);
    }
  }, [openUpdateModal]);

  const content = {
    type: BLOCK_TYPE.CONTAINER,
    block: {
      type: BLOCK_TYPE.TABLE,
      entityName: 'Schedule',
      useColoredTable: true,
      tableComponent: {
        columns: columns,
        getData: getAllSchedules,
        forceRefresh: forceRefresh,
        hideSearch: true,
        editRowsModel: editRowsModel,
        onEditRowsModelChange: (model: any) => setEditRowsModel(model),
      },
      tableForm: {
        formFields: fields,
        formInitialValues: initialValues,
        formSchema: outreach_sms_schema,
        formSubmitApiFunction: handleSubmit,
        buttonPosition: 'center',
        formModalWidth: 500,
      },
      tableAction: {
        includeAddButton: true,
        includeDeleteAction: false,
        includeUpdateAction: false,
      },
    } as PageBuilderTableType,
  } as PageBuilderContainerType;

  return (
    <Box sx={{ width: '80%', margin: '0 auto' }}>
      <PageBuilder content={content} />

      <ConfirmationDialog
        title="Confirmation"
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        content={'Are you sure you want to remove this SMS schedule?'}
        onConfirm={handleDelete}
      />

      <CustomModal open={openUpdateModal} setOpen={setOpenUpdateModal} header={'Update Schedule'} width={500}>
        <CustomForm
          onSubmit={handleSubmit}
          fields={fields}
          initialValues={initialValues}
          loading={loading}
          schema={outreach_sms_schema}
          submitButtonText={'Save Schedule'}
          buttonPosition={'center'}
        />
      </CustomModal>
    </Box>
  );
};

export default CampaignSms;
