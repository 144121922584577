import { ConfirmationDialog, CustomModal, CustomSwitch, CustomTable, RegularButton } from 'core/components';
/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import {
  createServiceDiscount,
  deleteServiceDiscount,
  deleteServiceDiscounts,
  getServiceDiscounts,
  getServicesNotAssigned,
} from 'company/api/service-discount';
import { lock, unlock } from 'core/icons';
import { useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/system';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { FacilityContext } from 'core/context/facility.context';
import { LoadingScreen } from 'core/screens';
import { PageBuilderTableType } from 'core/PageBuilder';
import { PromoDrawer } from 'company/entities/drawer';
import RemoveIcon from '@mui/icons-material/Remove';
import { UserContext } from 'core/context/user.context';
import { updatePromotionStatus } from 'company/api/promotions';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// import PromotionDetails from './PromotionDetails';
const Promotions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [refreshChild, setRefreshChild] = useState<number>(0);
  const [refreshAvailableServices, setRefreshAvailableServices] = useState<number>(0);

  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedService, setSelectedService] = useState<any>();
  const [serviceRows, setServiceRows] = useState<any[]>([]);
  const [serviceRowCount, setServiceRowCount] = useState(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [openCampaignStatusDialog, setOpenCampaignStatusDialog] = useState<boolean>(false);
  const [selectedClientIDs, setSelectedClientIDs] = useState<any[]>([]);
  const [openRemoveServicesDialog, setOpenRemoveServicesDialog] = useState<boolean>(false);
  const drawerRef: any = useRef();

  const [published, setPublished] = useState<boolean>(true);

  const serviceColumns: GridColDef[] = [
    {
      field: 'service_name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
          {params.row.service_name}
        </Box>
      ),
    },
    // {
    //   field: 'price',
    //   headerName: 'Price (Php)',
    //   flex: 1,
    //   renderCell: (params) =>
    //     formatNumber(parseFloat(params.row.price)) ? formatNumber(parseFloat(params.row.price)) : '0',
    // },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            setOpenRegisterModal(true);
            setSelectedService(params.row);
          }}
        >
          Select
        </Button>
      ),
    },
  ];

  const handleSelectServiceRow = (selected: GridRowSelectionModel) => {
    setSelectedClientIDs(selected);
  };

  const getServiceDiscountData = async (query: ApiQuery) => {
    return getServiceDiscounts(facility.id, selectedRow.id, query);
  };

  const getServiceData = async (query: ApiQuery) => {
    setLoading(true);
    try {
      let res = await getServicesNotAssigned(facility.id, selectedRow.id, query);
      setServiceRows(res.data.data);
      setServiceRowCount(res.data.meta.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!published) {
      setOpenCampaignStatusDialog(true);
    } else {
      updateStatus(false);
    }
  };

  const handleAddService = async () => {
    const data = {
      discount_id: selectedRow.id,
      service_id: selectedService.id,
    };
    createServiceDiscount(facility.id, data)
      .then((res: any) => {
        enqueueSnackbar('Service successfully added!', { variant: 'success' });
        refreshChildTable();
        refreshServicesTable();
      })
      .catch((error) => console.error(error));
  };

  const updateStatus = async (toPublish: boolean) => {
    const data = { toPublish: toPublish ? true : false };

    if (selectedRow) {
      updatePromotionStatus(facility.id, selectedRow.id, data)
        .then(() => {
          if (toPublish) {
            enqueueSnackbar(`Promo successfully published!`, { variant: 'success' });
            setPublished(true);
          } else {
            enqueueSnackbar(`Promo reverted back to draft!`, { variant: 'success' });
            setPublished(false);
          }
          refreshTable();
        })
        .catch((err) => console.error(err));
    }
  };

  const handleRemoveService = async (data: any) => {
    return deleteServiceDiscount(facility.id, data);
  };

  const removeServicesFromList = () => {
    const services = JSON.stringify(selectedClientIDs);
    const data = { services: services };
    deleteServiceDiscounts(facility.id, data, selectedRow.id)
      .then((res) => {
        enqueueSnackbar(`Service/s successfully removed!`, { variant: 'success' });
        refreshChildTable();
        setSelectedClientIDs([]);
      })
      .catch(() => setLoading(false));
  };

  const showRemoveServicesDialog = () => {
    if (selectedClientIDs.length > 0) {
      setOpenRemoveServicesDialog(true);
    }
  };

  const refreshTable = () => {
    drawerRef.current.refreshTable();
  };

  const refreshChildTable = () => {
    setRefreshChild((prev: number) => prev + 1);
  };

  const refreshServicesTable = () => {
    setRefreshAvailableServices((prev: number) => prev + 1);
  };

  const childComponent = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Service Discount',
    tableComponent: {
      selectableRow: !published,
      hideTable: !selectedRow,
      onSelectRow: handleSelectServiceRow,
      columns: [
        {
          field: 'service_name',
          headerName: 'Name',
          flex: 2,
          renderCell: (params) => (
            <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
              {params.row.service_name}
            </Box>
          ),
        },
      ],
      getData: selectedRow && getServiceDiscountData,
      checkboxSelection: true,
      forceRefresh: refreshChild,
      headerComponent: (
        <Box display="flex" gap="10px">
          <RegularButton
            label="Remove Services"
            disabled={!selectedClientIDs.length || published}
            startIcon={<RemoveIcon />}
            color="error"
            onClick={() => {
              if (selectedClientIDs.length) {
                showRemoveServicesDialog();
              } else {
                enqueueSnackbar('Invalid action. Select services first!', { variant: 'error' });
              }
            }}
          />

          <CustomSwitch
            label1="Draft"
            label2="Published"
            image1={unlock}
            image2={lock}
            value={published}
            handleChange={handleChange}
          />
        </Box>
      ),
    },
    tableAction: {
      includeAddButton: true,
      includeDeleteAction: true,
      disableAddButton: published,
      disableDeleteButton: published,
      addButtonLabel: 'Add Services',
      deleteButtonLabel: 'Remove',
      alertCustomObjectColumn: 'service_name',
      deleteApiFunction: handleRemoveService,
      handleAddButtonClick: () => setOpenAddModal(true),
    },
  } as PageBuilderTableType;

  useEffect(() => {
    if (user) {
      if (!user?.company?.with_booking_platform) {
        navigate('/forbidden');
      }
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Promotions' }]);
  }, []);

  return (
    <LoadingScreen loading={isLoading}>
      <PromoDrawer
        ref={drawerRef}
        setDrawerSelectedRow={setSelectedRow}
        childComponent={<PageBuilder content={childComponent} />}
      />
      <CustomModal width={600} header={'Add Services'} open={openAddModal} setOpen={setOpenAddModal}>
        <CustomTable
          // hideSearch={true}
          rowId={'id'}
          rows={serviceRows}
          rowCount={serviceRowCount}
          columns={serviceColumns}
          loading={loading}
          getData={getServiceData}
          forceRefresh={refreshAvailableServices}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        content={'Are you sure you want to add this service?'}
        onConfirm={handleAddService}
      />
      <ConfirmationDialog
        open={openCampaignStatusDialog}
        setOpen={setOpenCampaignStatusDialog}
        content={"To modify a published promo, change mode to 'Draft'."}
        title={"Published promos can't be modified."}
        onConfirm={() => updateStatus(true)}
        confirmButtonColor={true}
        icon={<ErrorIcon sx={{ color: 'orange', fontSize: '30px' }} />}
      />
      <ConfirmationDialog
        open={openRemoveServicesDialog}
        setOpen={setOpenRemoveServicesDialog}
        content={`Are you sure you want to remove ${selectedClientIDs.length} service${
          selectedClientIDs.length > 1 ? 's' : ''
        }`}
        onConfirm={removeServicesFromList}
      />
    </LoadingScreen>
  );
};

export default Promotions;
