/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { updateCheckupDetails, updateStage } from 'company/api/tb-acf';
import { useContext, useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import InputContainer from '../common/InputContainer';
import { TbAcfCompanyPatientModel } from 'company/model/Entities';
import { tokens } from 'theme/theme';
import { update_checkup_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

type DoctorsAppointmentProps = {
  patient: TbAcfCompanyPatientModel;
  result: string;
  refreshTable: () => void;
  setNextStage: (step: number) => void;
  setResult: (result: string) => void;
  setPatientData: (patient: any) => void;
  setStatus: (status: string) => void;
};

type UserInput = {
  check_up_schedule: string;
  check_up_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  check_up_schedule: '',
  check_up_venue: '',
};

const DoctorsAppointment: React.FC<DoctorsAppointmentProps> = ({
  patient,
  refreshTable,
  setNextStage,
  setResult,
  result,
  setPatientData,
  setStatus,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const savedValues: UserInput = {
    check_up_schedule: patient.check_up_schedule,
    check_up_venue: patient.check_up_venue,
  };

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [displayResult, setDisplayResult] = useState(false);
  const [checkUpResult, setCheckUpResult] = useState<any>(result ? result : '');

  const [disableSubmit, setDisableSubmit] = useState<boolean>(
    patient.check_up_schedule && patient.check_up_venue ? false : true
  );

  const checkupFormFields: UserFieldInput[] = [
    {
      field_name: 'check_up_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
    {
      field_name: 'check_up_venue',
      display_name: 'Venue',
      type: 'text',
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
  ];

  const skipSchedule = () => {
    setDisplayResult(true);
  };

  const handleCheckupResult = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult(event.target.value);
    setCheckUpResult(event.target.value);

    if (event.target.value === 'Negative') {
      const patientList = JSON.stringify([patient.id]);
      const patientData = {
        patients: patientList,
        stage: 'Doctor Appointment',
        patient_status: event.target.value,
      };
      updateStage(facility.id, patientData)
        .then((res) => {
          enqueueSnackbar('Check-up Result successfully saved!', { variant: 'success' });
          refreshTable();
          setStatus(event.target.value);
        })
        .catch((error) => console.error(error));
    } else {
      setNextStage(3);
    }
  };

  const saveCheckupSchedule = (data: any) => {
    setButtonLoading(true);

    const patientList = JSON.stringify([patient.id]);
    const patientData = {
      patients: patientList,
      check_up_schedule: data.check_up_schedule,
      check_up_venue: data.check_up_venue,
    };

    updateCheckupDetails(facility.id, patientData)
      .then((res) => {
        setButtonLoading(false);
        enqueueSnackbar('Check-up Details successfully saved!', { variant: 'success' });
        setDisplayResult(true);
        refreshTable();
        setPatientData((prev: any) => {
          return {
            ...prev,
            check_up_schedule: data.check_up_schedule,
            check_up_venue: data.check_up_venue,
          };
        });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setStatus(patient.patient_status ? patient.patient_status : 'Presumptive');

    if (
      (patient.check_up_schedule && patient.check_up_venue) ||
      patient.patient_status === 'Negative' ||
      patient.patient_status === 'Positive'
    ) {
      setDisplayResult(true);
    } else {
      setDisplayResult(false);
    }

    if (patient.patient_status === 'Negative') {
      setCheckUpResult('Negative');
    } else if (patient.patient_status === 'Positive') {
      setCheckUpResult('Positive');
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px" marginY="20px">
        <Typography variant="h5" color={colors.text} fontWeight="bold">
          Check-up Schedule and Venue
        </Typography>

        {!displayResult && (
          <Typography
            onClick={skipSchedule}
            sx={{
              color: colors.accent,
              '&:hover': {
                cursor: 'pointer',
                fontWeight: '900',
              },
            }}
          >
            Skip {'>>'}
          </Typography>
        )}
      </Box>

      <InputContainer>
        <CustomForm
          onSubmit={saveCheckupSchedule}
          schema={update_checkup_schema}
          initialValues={savedValues ? savedValues : initialValues}
          loading={buttonLoading}
          fields={checkupFormFields}
          submitButtonText={'Save and Send SMS'}
          buttonWidth={'150px'}
          disabled={disableSubmit}
        />
      </InputContainer>

      {displayResult && (
        <Box>
          <Divider sx={{ margin: '20px 0' }} />
          <Box marginLeft={'250px'}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Check-up Result</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checkUpResult}
                onChange={handleCheckupResult}
              >
                <FormControlLabel
                  value="Positive"
                  control={<Radio />}
                  label="Start TB Treatment"
                  checked={checkUpResult === 'Positive'}
                />
                <FormControlLabel
                  value="Negative"
                  control={<Radio />}
                  label="Do not enroll to TB-DOTS"
                  checked={checkUpResult === 'Negative'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DoctorsAppointment;
