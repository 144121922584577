import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'icd-code';

export const getCodeById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getCodeByCode = (code: number) => {
  return axios.get(`${baseURL}/${path}/code/${code}`);
};

export const getCodesNotYetAdded = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/code-not-added`, {params:  query });
};

export const getCodes = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/all`, {params:  query });
};

export const createCode = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const deleteCode = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}` );
};

export const getCodeOfPatientNote = (patient_note_id: number) => {
  return axios.get(`${baseURL}/${path}/note/${patient_note_id}`);
};
