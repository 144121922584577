import {
  HP_ENTITY_NAME,
  HealthPackageField,
  HealthPackageModel,
  healthPackageFormField,
  healthPackageInitialValues,
  health_session_schema,
} from './HealthPackageModel';
import { createHealthPackage, updateHealthPackage } from 'company/api/health-packages';
import { useContext, useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';

type Props = {
  healthPackage?: HealthPackageModel;
  readonly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const HealthPackageForm: React.FC<Props> = (props) => {
  const { healthPackage, readonly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities: user
          ? user?.facilities?.map((facility) => ({
              key: facility.facility_name,
              value: facility.id,
            }))
          : [],
      });
    }
  }, [user]);

  const formSubmitApiFunction = (data: any) => {
    var assigned_facilities = data.facilities.map((facility: { value: { toString: () => any } }) =>
      facility.value.toString()
    );
    data.assigned_facilities = JSON.stringify(assigned_facilities);
    return healthPackage ? updateHealthPackage(healthPackage.id, data) : createHealthPackage(data);
  };

  useEffect(() => {
    if (healthPackage) {
      const facilitiesArray = healthPackage.assigned_facilities?.split(', ') ?? [];
      const idsArray = healthPackage.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: number) => ({
        key,
        value: idsArray[index],
      }));
      healthPackage.facilities = healthPackage.assigned_facilities?.length > 0 ? selectedClinics : [];
    }
  }, [healthPackage]);

  return (
    <TemplateForm
      entityName={HP_ENTITY_NAME}
      fields={HealthPackageField}
      entity={healthPackage}
      initialValues={healthPackage ? healthPackage : healthPackageInitialValues}
      visibleFields={healthPackageFormField}
      formSubmitApiFunction={formSubmitApiFunction}
      callbackAfterSubmit={callbackAfterSubmit}
      dropdownOptions={dropdownOptions}
      showSubmitAtTop={showSubmitAtTop}
      schema={health_session_schema}
      readOnly={readonly}
    />
  );
};

export default HealthPackageForm;
