import GroupsIcon from "@mui/icons-material/Groups";

const steps_filter = [
  {
    label: "All Patients",
    icon: <GroupsIcon />,
  },
  {
    label: "Chest X-ray",
    icon: 1,
    headerTitle: "Stage 1: Chest X-ray",
  },
  {
    label: "Sputum Test",
    icon: 2,
    headerTitle: "Stage 2: Sputum Test",
  },
  {
    label: "Doctor Appointment",
    icon: 3,
    headerTitle: "Stage 3: Doctor Appointment",
  },
  {
    label: "TB-DOTS Enrollment",
    icon: 4,
    headerTitle: "Stage 4: TB-DOTS Enrollment",
  },
];

const acf_steps = [
  {
    label: "Chest X-ray",
    icon: 1,
    headerTitle: "Stage 1: Chest X-ray",
  },
  {
    label: "Sputum Test",
    icon: 2,
    headerTitle: "Stage 2: Sputum Test",
  },
  {
    label: "Doctor Appointment",
    icon: 3,
    headerTitle: "Stage 3: Doctor Appointment",
  },
  {
    label: "TB-DOTS Enrollment",
    icon: 4,
    headerTitle: "Stage 4: TB-DOTS Enrollment",
  },
];

export { steps_filter, acf_steps };
