import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { enrollProgramRemindersPatients } from 'lgu/api/program-reminders-patients';
import { family_planning_schema } from 'lgu/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type FpEnrollModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  programCode: string;
  selectedProgram: string;
  selectedPatientIDs: any[];
  selectedPatientID: number;
  type: 'create' | 'update';
  updateInitialValues?: UserInput;
  ocpCount: number;
  refreshNotEnrolledTable: () => void;
  refreshEnrolledTable: () => void;
};

export type UserInput = {
  date_started: string;
  sending_time: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  date_started: '',
  sending_time: '',
};

const FpEnrollModal: React.FC<FpEnrollModalProps> = ({
  header,
  open,
  setOpen,
  selectedProgram,
  selectedPatientIDs,
  updateInitialValues,
  selectedPatientID,
  ocpCount,
  refreshNotEnrolledTable,
  refreshEnrolledTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const scheduleFields: UserFieldInput[] = [
    { field_name: 'date_started', display_name: 'Patient Start Date', type: 'date' },
    { field_name: 'sending_time', display_name: 'SMS Reminder Time', type: 'time', minutesStep: 5 },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const patient = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);
    const patientData = {
      patients: patient,
      date_started: data.date_started,
      sending_time: (data.sending_time + '').slice(0, 5),
      ocp_count: ocpCount,
    };

    enrollProgramRemindersPatients(selectedProgram, patientData)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => setButtonLoading(false));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      refreshNotEnrolledTable();
      refreshEnrolledTable();
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  return (
    <>
      <CustomModal header={header} open={open} setOpen={setOpen}>
        <CustomForm
          initialValues={updateInitialValues ? updateInitialValues : initialValues}
          onSubmit={handleSubmit}
          fields={scheduleFields}
          schema={family_planning_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default FpEnrollModal;
