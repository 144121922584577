import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';

import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import React from 'react';
import { formatDateTimeWithDay } from 'core/utils'; // Ensure you import the correct path
import { tokens } from 'theme/theme'; // Ensure you import the correct path

type Props = {
  transaction: any;
  children?: React.ReactNode;
  expanded: boolean;
  onChange: () => void;
};

const TransactionContainer: React.FC<Props> = ({ transaction, children, expanded, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Accordion
      sx={{
        background: colors.light_blue_background,
        '& .MuiAccordionSummary-root': { minHeight: 'unset', margin: '5px 0', padding: '5px' },
        '& .MuiAccordionSummary-content': { margin: '0' },
        '& .Mui-expanded': { margin: '0px !important' },
        '&:before': {
          display: 'none',
        },
        borderRadius: '10px'
      }}
      disableGutters
      square
      elevation={0}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px 16px',
          background: expanded ? colors.light_blue_background_2 : 'transparent',
          borderRadius: '10px 10px 0 0',
          '&:hover': {
            backgroundColor: colors.light_blue_background_2,
            borderRadius: '10px 10px 0 0',
          },
        }}
        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '25px' }} />}
      >
        <Box padding="10px 10px" display="flex" width="100%" flexDirection="column" gap="10px">
          <Typography fontWeight={'bold'} sx={{ color: colors.dark_grey_text }}>
            {transaction.product_name}
          </Typography>
          <Typography fontSize={'12px'} sx={{ color: colors.grey_text }}>
            Last Transaction: {formatDateTimeWithDay(transaction.transaction_date)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default TransactionContainer;
