import * as yup from 'yup';

import { forwardRef, useEffect, useState } from 'react';
import { rotateArray, shuffle } from 'core/utils';

import AddIcon from '@mui/icons-material/Add';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Box } from '@mui/material';
import { ContainerColumn } from 'core/components/containers';
import CustomBarChart from './CustomBarChart';
import CustomPieChart from './CustomPieChart';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { Dropdown } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { SIZES } from 'theme/constants';
import { getGraphs } from 'company/api/graph';

export const chart_schema = yup.object().shape({
  source: yup.string().required('Source is required.'),
  graph_name: yup.string().required('Chart Name is required.'),
});

type ChartType = 'bar' | 'pie';

export type ChartProps = {
  id?: number;
  graph_name: string;
  graph_type: ChartType;
  x_axis?: any;
  y_axis?: any;
  configuration?: any;
  row_span?: number;
  col_span?: number;
};

export const chart_colors = [
  '#327497',
  '#0285a0',
  '#03959a',
  '#00a283',
  '#35ad60',
  '#7cb237',
  '#bdb100',
  '#ffa601',
  '#e18632',
  '#f5ac91',
];

type Props = {
  template_id: number;
  fields: FieldInput[];
  patientRecords: any[];
};

const ChartEditor = forwardRef((props: Props, ref) => {
  const { template_id, fields, patientRecords } = props;
  const [graphs, setGraphs] = useState<ChartProps[]>([]);
  const [responses, setResponses] = useState<any[]>([]);

  const addChart = (type: ChartType) => {
    setGraphs((prev) => [
      ...prev,
      {
        graph_name: 'Chart Name',
        graph_type: type,
        configuration: { colors: type === 'pie' ? shuffle(chart_colors) : rotateArray(chart_colors) },
      },
    ]);
  };

  const removeChart = (index: number) => {
    graphs.splice(index, 1);
    setGraphs([...graphs]);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newReorderedFields = [...graphs];
    const [movedField] = newReorderedFields.splice(result.source.index, 1);
    newReorderedFields.splice(result.destination.index, 0, movedField);
    setGraphs(newReorderedFields);
  };

  useEffect(() => {
    setResponses(patientRecords.map((record) => record.body.responses));
  }, [patientRecords]);

  useEffect(() => {
    getGraphs(template_id).then((res) => setGraphs(res.data.data));
  }, [template_id]);

  return (
    <Box>
      <ContainerColumn>
        <DraggableList
          fromModal
          droppableId="charts"
          handleDragEnd={handleDragEnd}
          list={graphs}
          style={{ padding: SIZES.paddingS }}
          displayItem={(graph, index) => (
            <Box>
              {graph.graph_type === 'bar' ? (
                <CustomBarChart
                  template_id={template_id}
                  responses={responses}
                  fields={fields}
                  graph={graph}
                  removeChart={() => removeChart(index)}
                />
              ) : graph.graph_type === 'pie' ? (
                <CustomPieChart
                  template_id={template_id}
                  responses={responses}
                  fields={fields}
                  graph={graph}
                  removeChart={() => removeChart(index)}
                />
              ) : (
                <></>
              )}
            </Box>
          )}
        />
      </ContainerColumn>
      <Dropdown
        buttonLabel={'Add Chart'}
        optionList={[
          { label: 'Bar Chart', action: () => addChart('bar'), icon: <BarChartOutlinedIcon /> },
          { label: 'Pie Chart', action: () => addChart('pie'), icon: <DonutLargeOutlinedIcon /> },
        ]}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        startIcon={<AddIcon />}
      />
      {/* <PrimaryButton variant="outlined" label="Add Graph" onClick={() => undefined} /> */}
    </Box>
  );
});

export default ChartEditor;
