import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'barangays';

export const getBarangays = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const exportBarangayCodes = (query: ApiQuery, lgu_name?: string) => {
  return axios
    .post(
      `${baseURL}/${path}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', '[' + lgu_name + '] - Barangay Codes.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

