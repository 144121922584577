/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Chip } from '@mui/material';
import { BreadcrumbContext, BreadcrumbTabProps } from 'core/context/breadcrumb.context';
import {
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTabs,
  Header,
  RowAction,
} from 'core/components';
import { CustomTable, PrimaryButton } from 'core/components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { changeNullToBlank, formatDate, tranformFormErrors } from 'core/utils';
import {
  cloneProgramSchedule,
  createProgramSchedule,
  getProgramScheduleHistory,
  getProgramSchedules,
  updateProgramSchedule,
} from 'lgu/api/program-schedule';
import { clone_type, clone_type_past } from 'lgu/data/select-options';
import { create_program_schedule_schema, update_program_schedule_schema } from 'lgu/model/schema';
import { useContext, useEffect, useState } from 'react';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import Moment from 'react-moment';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ProgramModel } from 'lgu/model/Entities';
import SettingsDropdown from '../components/SettingsDropdown';
import { TabProps } from 'core/components/CustomTabs';
import { getBarangays } from 'lgu/api/barangay';
import { getProgram } from 'lgu/api/program';
import { useSnackbar } from 'notistack';

export type UserInput = {
  schedule_name: string;
  venue: string;
  barangay_code: string;
  program_date_time: Date;
  clone_type: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  schedule_name: '',
  venue: '',
  barangay_code: '',
  program_date_time: new Date(),
  clone_type: '',
};

const ProgramSchedules = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { id } = useParams();

  const [program, setProgram] = useState<ProgramModel>();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [scheduleSchema, setScheduleSchema] = useState<any>(create_program_schedule_schema);
  const [scheduleModalHeader, setScheduleModalHeader] = useState('Create Schedule');
  const [scheduleInitialValues, setScheduleInitialValues] = useState<UserInput>(initialValues);
  const [barangays, setBarangays] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pastScheduleRows, setPastScheduleRows] = useState<any[]>([]);
  const [pastScheduleRowCount, setPastScheduleRowCount] = useState<number>(0);
  const [pastScheduleLoading, setPastScheduleLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [toClone, setToClone] = useState<boolean>(false);
  const [isPast, setIsPast] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);

  const showScheduleModal = (from_edit?: boolean, data?: UserInput) => {
    if (from_edit && data) {
      setScheduleInitialValues(data);
      setScheduleModalHeader('Edit Schedule');
      setScheduleSchema(update_program_schedule_schema);
    } else {
      setScheduleModalHeader('Create Schedule');
      setScheduleInitialValues(initialValues);
      setScheduleSchema(create_program_schedule_schema);
    }
    setToClone(false);
    setOpenScheduleModal(true);
  };

  const editSchedule = (data: any) => {
    const to_update = changeNullToBlank(data); //pass data here
    showScheduleModal(true, to_update);
  };

  const cloneSchedule = (data: any) => {
    setScheduleModalHeader('Clone Schedule');
    const clonedSchedule = Object.assign({}, data);
    clonedSchedule.schedule_name = clonedSchedule.schedule_name + ' - Copy';
    setScheduleInitialValues(clonedSchedule);
    setToClone(true);

    setOpenScheduleModal(true);
  };

  const getSchedules = (query: ApiQuery) => {
    setIsPast(false);
    setLoading(true);
    getProgramSchedules(parseInt(id as string), query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    const program_id = parseInt(id as string);
    if (data.id) {
      if (toClone) {
        cloneProgramSchedule(data.id, data)
          .then((res: any) => {
            successFormSubmit(true, false, res, formikHelpers);
          })
          .catch((error) => console.error(error));
        return;
      } else {
        updateProgramSchedule(data.id, data)
          .then((res: any) => {
            successFormSubmit(false, true, res, formikHelpers);
          })
          .catch((error) => console.error(error));
        return;
      }
    }

    createProgramSchedule(program_id, data)
      .then((res: any) => {
        successFormSubmit(false, false, res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (
    clone: boolean,
    from_edit: boolean,
    res: any,
    { resetForm, setErrors }: FormikHelpers<any>
  ) => {
    if (res.status === 200) {
      if (clone) {
        enqueueSnackbar(`Program Schedule successfully cloned!`, { variant: 'success' });
        navigate('/program-schedules/' + res.data.program_schedule.id);
      } else {
        enqueueSnackbar(`Program Schedule successfully ${from_edit ? 'updated' : 'created'}!`, { variant: 'success' });
        getSchedules({ page: 1, length: 10 });
      }

      if (!from_edit) {
        resetForm();
        setOpenScheduleModal(false);
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const getPastSchedules = (query: ApiQuery) => {
    setIsPast(true);
    setPastScheduleLoading(true);
    getProgramScheduleHistory(parseInt(id as string), query)
      .then((res) => {
        setPastScheduleRows(res.data.data);
        setPastScheduleRowCount(res.data.meta.total);
        setPastScheduleLoading(false);
      })
      .catch(() => setPastScheduleLoading(false));
  };

  const actions = [
    { label: 'Clone Schedule', action: cloneSchedule, icon: <EventRepeatIcon /> },
    { label: 'Export Participants', icon: <FileDownloadIcon /> },
  ];

  const columns: GridColDef[] = [
    {
      field: 'schedule_name',
      headerName: 'Schedule Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/programs/program-schedules/details/${params.row.id}`} className="emphasis">
            {params.row.schedule_name}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'program_date_time',
      headerName: 'Program Schedule',
      flex: 1.5,
      type: 'string',
      renderCell: (params) => (
        <CustomGridCell>
          <Moment date={params.row.program_date_time} format="LLL" />
        </CustomGridCell>
      ),
    },
    {
      field: 'venue',
      headerName: 'Venue',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.venue}, {params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    { field: 'participants', headerName: 'Participants', flex: 1, type: 'number' },
    { field: 'creator', headerName: 'Created By', flex: 1 },
    {
      field: 'date_created',
      headerName: 'Date Created',
      flex: 0.7,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      renderCell: (params) => (
        <Box display="flex">
          {program && (
            <SettingsDropdown
              schedule={params.row}
              program={program}
              icon={<MoreHorizOutlinedIcon />}
              has_icon
              editSchedule={editSchedule}
              cloneSchedule={cloneSchedule}
              getSchedules={getSchedules}
            />
          )}
        </Box>
      ),
    },
  ];

  // Form Fields
  const scheduleFields: UserFieldInput[] = [
    { field_name: 'schedule_name', display_name: 'Schedule Name', type: 'text' },
    {
      field_name: 'program_date_time',
      display_name: 'Date & Time',
      type: 'datetime',
      disablePast: true,
      minutesStep: 5,
    },
    { field_name: 'venue', display_name: 'Venue', type: 'text' },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay',
      type: 'select',
      options: barangays.map((u) => {
        return { key: u.barangay_name, value: u.barangay_code };
      }),
    },
    {
      field_name: 'clone_type',
      display_name: 'Clone Patients',
      type: 'select',
      hidden: toClone ? false : true,
      options: isPast
        ? clone_type_past.map((u) => {
            return { key: u.key, value: u.value };
          })
        : clone_type.map((u) => {
            return { key: u.key, value: u.value };
          }),
    },
  ];

  //PAST-PROGRAM SCHEDULES
  const pastScheduleColumns: GridColDef[] = [
    {
      field: 'schedule_name',
      headerName: 'Schedule Name',
      flex: 1.5,
      renderCell: (params) =>
        params.row.is_cancelled ? (
          <CustomGridCell>{params.row.schedule_name}</CustomGridCell>
        ) : (
          <Link to={`/programs/program-schedules/past/${params.row.id}`} className="emphasis">
            {params.row.schedule_name}
          </Link>
        ),
    },
    {
      field: 'program_date_time',
      headerName: 'Program Schedule',
      flex: 1.5,
      type: 'string',
      renderCell: (params) =>
        params.row.is_cancelled ? (
          <Chip label="Canceled" variant="outlined" color="error" size="small" />
        ) : (
          <CustomGridCell>
            <Moment date={params.row.program_date_time} format="LLL" />
          </CustomGridCell>
        ),
    },
    {
      field: 'venue',
      headerName: 'Venue',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.venue}, {params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    { field: 'participants', headerName: 'Participants', flex: 1, type: 'number' },
    { field: 'attended', headerName: 'Attendance', flex: 1, type: 'number' },
    { field: 'creator', headerName: 'Created By', flex: 1 },
    {
      field: 'date_created',
      headerName: 'Date Created',
      flex: 0.7,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.date_created)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const tabs: TabProps[] = [
    {
      icon: <EventAvailableIcon />,
      label: 'Upcoming Schedules',
      content: (
        <CustomContainer>
          <CustomTable
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getSchedules}
            headerComponent={<PrimaryButton onClick={() => showScheduleModal()} label="Create Schedule" />}
          />
        </CustomContainer>
      ),
    },
    {
      icon: <EventBusyIcon />,
      label: 'Past Schedules',
      content: (
        <CustomContainer>
          <CustomTable
            columns={pastScheduleColumns}
            rows={pastScheduleRows}
            rowCount={pastScheduleRowCount}
            loading={pastScheduleLoading}
            rowId={'id'}
            getData={getPastSchedules}
          />
        </CustomContainer>
      ),
    },
  ];

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const getHealthProgram = () => {
    getProgram(parseInt(id as string))
      .then((res) => {
        setProgram(res.data);
      })
      .catch();
  };

  useEffect(() => {
    getHealthProgram();
    getBarangayList();
  }, []);

  useEffect(() => {
    const breadcrumbs: BreadcrumbTabProps[] = [{ label: 'Health Programs', link: '/programs' }];

    if (program) {
      breadcrumbs.push({ label: program.program_name });
    }

    setBreadcrumb(breadcrumbs);
  }, [program]);

  return (
    <Box>
      <Header title={program?.program_name ?? ''} subtitle="Program Schedules" />
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
      <CustomModal header={scheduleModalHeader} open={openScheduleModal} setOpen={setOpenScheduleModal}>
        <CustomForm
          initialValues={scheduleInitialValues}
          onSubmit={handleSubmit}
          fields={scheduleFields}
          schema={scheduleSchema}
          loading={buttonLoading}
        />
      </CustomModal>
    </Box>
  );
};

export default ProgramSchedules;
