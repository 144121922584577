import {
  SERVICE_GROUP_ENTITY_NAME,
  ServiceGroupModel,
  serviceGroupFields,
  serviceGroupFormFields,
  serviceGroupInitialValues,
  serviceGroupSchema,
  serviceGroupUpdateFormFields,
} from './ServiceGroupModel';
import { createServiceGroup, updateServiceGroup } from 'company/api/service-groups';

import TemplateForm from 'company/entities/Template/TemplateForm';
import { UserContext } from 'core/context/user.context';
import { changeNullToBlank } from 'core/utils';
import { useContext, useEffect, useState } from 'react';

type Props = {
  readonly?: boolean;
  serviceGroup?: ServiceGroupModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const ServiceGroupForm = (props: Props) => {
  const { serviceGroup, callbackAfterSubmit, readonly, showSubmitAtTop } = props;
  const { user } = useContext(UserContext);
  const [dropdownOptions, setDropdownOptions] = useState<any>({});

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities:
          user?.facilities?.map((facility) => ({
            key: facility.facility_name,
            value: facility.id,
          })) || [],
      });
    }
  }, [user]);

  const handleSubmit = async (data: any) => {
    if (data.facilities) {
      const assigned_facilities = data.facilities.map((facility: { value: { toString: () => any } }) =>
        facility.value.toString()
      );
      data.assigned_facilities = JSON.stringify(assigned_facilities);
    }
    return serviceGroup ? updateServiceGroup(serviceGroup.id, data) : createServiceGroup(data);
  };

  useEffect(() => {
    if (serviceGroup) {
      const facilitiesArray = serviceGroup.assigned_facilities?.split(', ') ?? [];
      const idsArray = serviceGroup.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: number) => ({
        key,
        value: idsArray[index],
      }));
      serviceGroup.facilities = serviceGroup.assigned_facilities?.length > 0 ? selectedClinics : [];
    }
  }, [serviceGroup]);

  return (
    <TemplateForm
      submitButtonId={'create_service_group_submit_button'}
      entityName={SERVICE_GROUP_ENTITY_NAME}
      fields={serviceGroupFields}
      visibleFields={serviceGroup ? serviceGroupUpdateFormFields : serviceGroupFormFields}
      dropdownOptions={dropdownOptions}
      entity={serviceGroup}
      initialValues={serviceGroup ? changeNullToBlank(serviceGroup) : serviceGroupInitialValues}
      schema={serviceGroupSchema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readonly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ServiceGroupForm;
