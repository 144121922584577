import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { check_up_result_options } from 'lgu/data/select-options';
import { updateStage } from 'lgu/api/tb-acf';
import { update_checkup_result_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type CheckupResultBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  checkup_test_result: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  checkup_test_result: '',
};

const CheckupResultBatch: React.FC<CheckupResultBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const checkupFormFields: UserFieldInput[] = [
    {
      field_name: 'checkup_test_result',
      display_name: 'Check-up Result:',
      type: 'select',
      options: check_up_result_options.map((option) => {
        return { key: option.key, value: option.value };
      }),
    },
  ];

  const updateCheckupResult = (data: any) => {
    setButtonLoading(true);

    if (data.checkup_test_result === 'Positive') {
      saveCheckupResult('Positive', 'TB-DOTS Enrollment');
    } else {
      saveCheckupResult('Negative', 'Doctor Appointment');
    }
  };

  const saveCheckupResult = (checkup_test_result: string, stage: string) => {
    const patientData = {
      patients: patients,
      stage: stage,
      patient_status: checkup_test_result,
    };

    updateStage(patientData)
      .then(() => {
        enqueueSnackbar('Check-up Result successfully saved!', { variant: 'success' });
        setButtonLoading(false);
        openModal(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateCheckupResult}
          schema={update_checkup_result_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={checkupFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default CheckupResultBatch;
