/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomTable, Header } from 'core/components';
import {
  approveAdditionalSmsTemplate,
  declineAdditionalSmsTemplate,
  getPendingSmsTemplates,
} from 'carego-admin/api/sms-templates';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { pendingTemplatesColumns } from '../../utils/table-columns';
import { useSnackbar } from 'notistack';

const PendingSmsTemplates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<number>(0);

  const [template, setTemplate] = useState<any>();

  //decline or accept template
  const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [approve, setApprove] = useState<boolean>(false);

  const addActionToTable = () => {
    if (!pendingTemplatesColumns.find((col) => col.field === 'action')) {
      pendingTemplatesColumns.push({
        field: 'action',
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={() => {
                showTemplateApproveDialog(
                  'Are you sure you want to approve template ' + params.row.template_name + '?'
                );
                setTemplate(params.row);
                setApprove(true);
              }}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                showTemplateDeclineDialog(
                  'Are you sure you want to decline template ' + params.row.template_name + '?'
                );
                setTemplate(params.row);
                setApprove(false);
              }}
            >
              Decline
            </Button>
          </Box>
        ),
      });
    }
  };

  const showTemplateDeclineDialog = (content: string) => {
    setOpenTemplateDialog(true);
    setAlertContent(content);
  };

  const handleTemplateDecline = () => {
    declineAdditionalSmsTemplate(template.id)
      .then(() => {
        enqueueSnackbar('Template successfully declined!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const showTemplateApproveDialog = (content: string) => {
    setOpenTemplateDialog(true);
    setAlertContent(content);
  };

  const handleTemplateApprove = () => {
    approveAdditionalSmsTemplate(template.id)
      .then(() => {
        enqueueSnackbar('Template successfully approved!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const getTemplates = (query: ApiQuery) => {
    setLoading(true);
    getPendingSmsTemplates(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (firstLoad === 0) {
      addActionToTable();
      setFirstLoad(firstLoad + 1);
    }
  }, [firstLoad]);

  return (
    <Box marginTop={'50px'}>
      <Header title="Requested LGU SMS Templates" />
      <CustomContainer>
        {firstLoad > 0 && (
          <CustomTable
            columns={pendingTemplatesColumns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getTemplates}
            forceRefresh={forceRefresh}
            showRefreshButton
          />
        )}
      </CustomContainer>

      <ConfirmationDialog
        open={openTemplateDialog}
        setOpen={setOpenTemplateDialog}
        content={alertContent}
        onConfirm={approve ? handleTemplateApprove : handleTemplateDecline}
        confirmButtonColor={approve ?? true}
        confirmButtonLabel={approve ? 'Approve' : 'Decline'}
      />
    </Box>
  );
};

export default PendingSmsTemplates;
