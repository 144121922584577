import { Box, Grid, Typography, useTheme } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useMemo } from 'react';

import { DropdownOptionProps } from '../Dropdown';
import InfoItem from 'company/entities/Template/components/InfoItem';
import { MobileViewConfig } from 'core/PageBuilder/CareGoTable';
import RowAction from '../RowAction';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

type CustomGridViewProps = {
  onItemClick?: (item: any) => void;
  columns: GridColDef[];
  tableRows: GridRowModel[];
  rowActions?: DropdownOptionProps[];
  mobileViewConfig?: (data: any) => MobileViewConfig;
};

const CustomGridView: React.FC<CustomGridViewProps> = ({
  onItemClick,
  columns,
  tableRows,
  rowActions,
  mobileViewConfig,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let navigate = useNavigate();

  const displayMobileView = useCallback(
    (row: any) => {
      if (mobileViewConfig) {
        const { title_fields, title_link, subtitle_fields } = mobileViewConfig(row);
        const getValueString = (fields: string[]) => {
          return fields
            .map((field) => {
              const renderCell = columns.find((col) => col.field === field)?.renderCell;
              return renderCell ? renderCell({ row, value: row[field], stringOnly: true } as any) : row[field];
            })
            .join(' | ');
        };

        return (
          <>
            <Typography
              color={colors.accent}
              sx={{ fontSize: '13px', cursor: 'pointer' }}
              onClick={title_link ? () => navigate(title_link) : undefined}
            >
              {getValueString(title_fields)}
            </Typography>
            {subtitle_fields && <Typography sx={{ fontSize: '11px' }}>{getValueString(subtitle_fields)}</Typography>}
          </>
        );
      }

      return (
        <Typography color={colors.accent} sx={{ fontSize: '13px' }}>
          {row[columns[0].field]}
          {columns[1] && row[columns[1].field] ? ' | ' + row[columns[1].field] : ''}
        </Typography>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, mobileViewConfig]
  );

  const handleClick = (data: any) => {
    onItemClick && onItemClick(data);
  };

  const body = useMemo(() => {
    return columns.filter((col) => col.field !== 'action');
  }, [columns]);

  return (
    <>
      <Box sx={{ marginTop: '20px', '& .emphasis': { color: colors.accent } }}>
        <Grid container spacing={2}>
          {tableRows.map((item, index) => (
            <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={index}>
              <Box
                onClick={() => handleClick(item)}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  border: '0.2px solid #e0e0e0',
                  width: '100%',
                  minHeight: '200px',
                  borderRadius: '10px',
                  transition: 'box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 30px"
                  sx={{
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    backgroundColor: colors.light_blue_background,
                    padding: '10px 15px',
                  }}
                >
                  <Box> {displayMobileView(item)}</Box>

                  <Box sx={{ position: 'absolute', top: 8, right: 10 }}>
                    {rowActions && <RowAction actions={rowActions} data={item} />}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="5px" padding="10px 15px">
                  {body.map((column, index) => (
                    <InfoItem
                      key={column.field}
                      label={column.headerName ?? ''}
                      last={index === body.length - 1}
                      value={
                        column.renderCell
                          ? column.renderCell({ row: item, value: item[column.field] } as any)
                          : item[column.field]
                      }
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default CustomGridView;
