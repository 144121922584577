import * as yup from 'yup';

import { CustomForm, CustomModal } from 'core/components';
import { useContext, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { ProductVariantModel } from '../Product/ProductVariant/ProductVariantModel';
import { updateInventoryQuantity } from 'company/api/inventories';
import { useSnackbar } from 'notistack';
import { formatAttributeValues } from 'core/utils';

const addQuantitySchema = yup.object().shape({
  quantity: yup
    .number()
    .required('Quantity is required.')
    .typeError('Invalid Value.')
    .min(1, 'Quantity must be greater than 0'),
  reason: yup.string().required('Purpose is required'),
});

const reasonList = [
  {
    key: 'Correction',
    value: 'correction',
  },
  {
    key: 'Damaged',
    value: 'damaged',
  },
  {
    key: 'Loss',
    value: 'loss',
  },
  {
    key: 'Other',
    value: 'other',
  },
];

type Props = {
  variant: ProductVariantModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  initialStock: number;
};

const AdjustStock: React.FC<Props> = ({
  variant,
  initialStock,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const addQuantityFields: FieldInput[] = [
    {
      field_name: 'quantity',
      display_name: 'Quantity',
      type: 'text',
      span: 2,
    },
    { field_name: 'reason', display_name: 'Purpose', type: 'select', span: 2, options: reasonList },
  ];

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const clinicId = facilityDetails ? facilityDetails.id : facility.id;
    updateInventoryQuantity(variant.id, { ...data, facility_id: clinicId })
      .then(() => {
        enqueueSnackbar('Stock successfully adjusted!', { variant: 'success' });
        setOpen(false);
        callbackAfterSubmit && callbackAfterSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Adjust Stock - ${variant.product_name}${formatAttributeValues(variant.attributes, true)}`}
      subHeader={facilityDetails ? facilityDetails.facility_name : facility?.facility_name}
    >
      <CustomForm
        fields={addQuantityFields}
        schema={addQuantitySchema}
        initialValues={{
          quantity: initialStock,
          reason: 'correction',
          product_id: variant.product_id,
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CustomModal>
  );
};

export default AdjustStock;
