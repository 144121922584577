import { CustomGridCell, Header } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import { WaitForFacility } from 'company/components';
import { getAllCampaignSms } from 'company/api/campaign-sms';
import { getCampaign } from 'company/api/campaigns';

type CampaignSmsSchedulesProps = {
  facility: any;
};

const CampaignSmsSchedules: React.FC<CampaignSmsSchedulesProps> = ({ facility }) => {
  const [openCampaignModal, setOpenCampaignModal] = useState<boolean>(false);
  const [selectedCampaignRow, setSelectedCampaignRow] = useState<any>();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [announcement, setAnnouncement] = useState<any | undefined>();

  const campaign_columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Outreach ID',
      flex: 0.5,
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {params.row.name}
            {params.row.status === 'published' && (
              <LockIcon sx={{ color: 'orange', marginLeft: 'auto', marginRight: 8 }} />
            )}
          </div>
        </CustomGridCell>
      ),
    },
    { field: 'campaign_name', headerName: 'Campaign Name', flex: 1 },
    {
      field: 'patients_enrolled',
      headerName: 'Patients',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.patients_enrolled ?? 0}</CustomGridCell>,
    },
    {
      field: 'sending_schedules',
      headerName: 'Sending Schedule',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          <div
            style={{
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            <span title={params.row.sending_schedules?.replace(/<br>/g, '\n')}>
              {params.row.sending_schedules ?? 'Not yet scheduled'}
            </span>
          </div>
        </CustomGridCell>
      ),
    },
  ];

  const handleSelectCampaignRow = async (selected: any) => {
    if (selected) {
      setSelectedCampaignRow(selected);
      setOpenCampaignModal(true);
    } else {
      setAnnouncement(undefined);
      return;
    }

    if (selected.status === 'published') {
      setAnnouncement(await getCampaign(facility?.id, selected.announcement_id));
    }
  };

  const getCampaignSmsSchedules = async (query: ApiQuery) => {
    if (facility) return getAllCampaignSms(facility.id, query);
  };

  const content: PageBuilderPageType = {
    type: BLOCK_TYPE.PAGE,
    block: [
      {
        type: BLOCK_TYPE.TABLE,
        tableComponent: {
          columns: campaign_columns,
          selectableRow: true,
          getData: getCampaignSmsSchedules,
          onSelectRow: handleSelectCampaignRow,
          // clearSelected: clearSelectedCampaign,
          forceRefresh: forceRefresh,
          rowHeight: 35,
          headerComponent: (
            <Box display="block">
              <Header title="Campaign SMS Schedules" mb="15px" />
              {/* CustomSwitch or other components */}
            </Box>
          ),
        },
      } as PageBuilderTableType,
    ],
  };

  useEffect(() => {
    setForceRefresh((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignRow?.status === 'published']);

  useEffect(() => {
    if (selectedCampaignRow && facility) {
      const fetchData = async () => {
        try {
          const campaign = await getCampaign(facility.id, selectedCampaignRow.announcement_id);
          setAnnouncement(campaign);
        } catch (error) {
          console.error('Error fetching campaign:', error);
          setAnnouncement(undefined);
        }
      };

      fetchData();
    }
  }, [selectedCampaignRow, facility]);

  return (
    <WaitForFacility facility={facility}>
      <PageBuilder content={content} />

      {/* {selectedCampaignRow && announcement && (
        <ViewOutreach
          outreach={selectedCampaignRow}
          open={openCampaignModal}
          setOpen={setOpenCampaignModal}
          refreshMainTable={refreshTable}
          setClearSelected={setClearSelectedCampaign}
        />
      )} */}
    </WaitForFacility>
  );
};

export default CampaignSmsSchedules;
