import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'sms-reminder-frequency';

export const getFrequencies = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const createFrequency = (data: any) => {
    return axios.post(`${baseURL}/${path}`, data);
};

export const deleteFrequency = (data: any) => {
    return axios.delete(`${baseURL}/${path}`, { params: data });
};

export const getCompanyFrequencyList = () => {
    return axios.get(`${baseURL}/${path}/company-frequency`);
  };
  
