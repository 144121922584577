import { Box, Divider, IconButton, Input, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { formatDate, formatNumber, formatTime } from 'core/utils';
import { previewSms, updateLocalizedTemplates } from 'company/api/sms-template';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import InfoIcon from '@mui/icons-material/Info';
import ReactMarkdown from 'react-markdown';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { UserContext } from 'core/context/user.context';
import { sms_template_schema } from 'company/model/schema';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type SmsPreviewProps = {
  templateCode: string;
  facility: FacilityModel;
  appointment?: CompanyAppointmentModel;
  mt?: string;
};

const SmsPreview: React.FC<SmsPreviewProps> = ({ templateCode, facility, appointment, mt }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const [preview, setPreview] = useState('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [templateBody, setTemplateBody] = useState('');
  const [previewStage, setPreviewStage] = useState<string>(''); // New state to hold the preview

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const getSmsPreview = async () => {
    const preview = await previewSms(facility.id, null, templateCode);
    setSelectedTemplate(preview.data);
    setTemplateBody(preview.data.template_body);
    setPreviewStage(replacePreview(preview.data.template_body));
  };

  const replacePreview = (template: string) => {
    if (!template || template.trim() === '') {
      setTemplateBody('');
      return '';
    }

    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);

    let updated = template
      .replaceAll('[Facility_Type]', `\`${facility.facility_type}\``)
      .replaceAll('[Facility_Contact]', `\`${contacts.join(' / ')}\``);

    if (appointment) {
      const facility = user.facilities?.find((facility) => facility.id === appointment.facility_id);
      
      updated = updated
        .replaceAll('[Facility_Name]', `\`${facility?.facility_short_name}\``)
        .replaceAll('[Name]', `\`${appointment.full_name}\``)
        .replaceAll('[First_Name]', `\`${appointment.first_name}\``)
        .replaceAll('[Health_Program]', `\`${appointment.service_names}\``)
        .replaceAll('[Date]', `\`${formatDate(appointment.schedule)}\``)
        .replaceAll('[Time]', `\`${formatTime(appointment.schedule)}\``)
        .replaceAll('[Total_Price]', `\`₱${formatNumber(appointment.total_price ?? '0.00', 2)}\``);
    } else {
      updated = updated.replaceAll('[Facility_Name]', `\`${facility.facility_short_name}\``)
      .replaceAll('[Total_Price]', '`₱0.00`');
    }

    return updated ?? '';
  };

  const smsFormFields: FieldInput[] = [
    {
      type: 'component',
      component: (
        <Box
          sx={{
            p: '20px',
            borderRadius: 2,
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            backgroundColor: colors.hoverBackground,
          }}
        >
          <Typography variant="h6" color={colors.text}>
            Special tokens
          </Typography>
          <Box display="flex" gap="10px" flexWrap="wrap">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  sx={{
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    color: '#FFF',
                    fontWeight: '400', // Removed flex property
                  }}
                  onClick={() => handleTokenClick(token.token)}
                >
                  <span
                    style={{
                      backgroundColor: colors.accent,
                      borderRadius: '5px',
                      padding: '2.5px',
                    }}
                  >
                    {token.token}
                  </span>
                </Typography>
              </Fragment>
            ))}
          </Box>
          <Divider></Divider>
          <Box width="100%">
            <Input
              aria-label="Demo input"
              multiline
              value={templateBody}
              disableUnderline
              placeholder={templateBody === '' ? 'Write here..' : ''}
              sx={{
                width: '100%',
                textDecoration: 'none',
                padding: 0, // Remove padding
                border: 'none', // Remove border
                ':focus': {
                  outline: 'none', // Remove outline on focus
                },
                '&.Mui-focused': {
                  border: 'none', // Ensure no border on focus
                  boxShadow: 'none', // Ensure no shadow on focus either
                },
              }}
              onChange={(e) => setTemplateBody(e.target.value)}
            />
            {/* <Typography></Typography> */}
          </Box>
        </Box>
      ),
      span: 4,
    },

    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px' }} />
            <Typography color={colors.text} sx={{ fontWeight: '500' }}>
              Preview ({templateBody.length} characters)
            </Typography>
          </Box>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              fontSize: '1rem',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>
      ),
      span: 4,
    },
  ];

  const handleTokenClick = (clickedToken: string) => {
    const updatedTemplateBody = templateBody + ' ' + clickedToken;
    setTemplateBody(updatedTemplateBody);
    setPreview(replacePreview(updatedTemplateBody));
  };

  const handleSmsEdit = (data: any) => {
    setButtonLoading(true);
    updateLocalizedTemplates(selectedTemplate.id, data.sms_template)
      .then(() => {
        enqueueSnackbar(`Template successfully updated!`, { variant: 'success' });
        setPreviewStage(replacePreview(data.sms_template)); // Update the preview stage
        setButtonLoading(false);
        setOpenTemplateModal(false);
        setSelectedTemplate((prev: any) => {
          return { ...prev, template_body: data.sms_template };
        });
      })
      .catch(() => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    getSmsPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateCode]);

  useEffect(() => {
    setPreview(replacePreview(templateBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateBody]);

  return (
    <Box>
      <Box
        sx={
          isMobilePhone
            ? { mt, p: '20px', borderRadius: 2, backgroundColor: colors.hoverBackground }
            : { mt, p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }
        }
      >
        <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center" paddingBottom="5px">
          <Typography color={colors.text} sx={{ fontWeight: '500' }}>
            SMS Preview
          </Typography>
          <Tooltip title="Edit Template">
            <IconButton
              sx={
                isMobilePhone
                  ? {
                      width: '15px',
                      height: '15px',
                      color: colors.accent,
                    }
                  : {
                      width: '15px',
                      height: '15px',
                      '&:hover': {
                        color: colors.accent,
                      },
                    }
              }
              onClick={() => {
                setTemplateBody(selectedTemplate?.template_body);
                setOpenTemplateModal(true);
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <ReactMarkdown children={previewStage ?? 'Loading ...'} />
      </Box>

      {/* {selectedTemplate && !isMobilePhone && (
        <CustomModal width={950} header="Edit Template" open={openTemplateModal} setOpen={setOpenTemplateModal}>
          <Box sx={{ display: 'flex', gap: '5px', width: '100%', mb: '12px' }}>
            <InfoIcon sx={{ fontSize: '1.2rem', color: colors.accent }} />
            <Typography sx={{ fontSize: '0.8rem' }}>
              To update the SMS template, you can type and follow the format of the special tokens when creating an SMS
              template, or simply click on the special tokens to add specific terms to your SMS template.
            </Typography>
          </Box>
          <CustomForm
            initialValues={{
              sms_template: templateBody,
            }}
            onSubmit={handleSmsEdit}
            fields={smsFormFields}
            schema={sms_template_schema}
            loading={buttonLoading}
          />
        </CustomModal>
      )} */}

      {selectedTemplate && (
        <CustomModal header="Update SMS Template" open={openTemplateModal} setOpen={setOpenTemplateModal}>
          <Box sx={{ display: 'flex', gap: '5px', width: '100%', mb: '12px' }}>
            <InfoIcon sx={{ fontSize: '1.2rem', color: colors.accent }} />
            <Typography sx={{ fontSize: '0.8rem' }}>
              To update the SMS template, you can type and follow the format of the special tokens when creating an SMS
              template, or simply click on the special tokens to add specific terms to your SMS template.
            </Typography>
          </Box>
          <CustomForm
            initialValues={{
              sms_template: templateBody,
            }}
            onSubmit={handleSmsEdit}
            fields={smsFormFields}
            schema={sms_template_schema}
            loading={buttonLoading}
            submitButtonStyles={{ width: '100%', flex: 2, backgroundColor: '#388E3C' }}
            submitButtonText="Save"
          />
        </CustomModal>
      )}
    </Box>
  );
};

export default SmsPreview;
