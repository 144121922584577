import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAttributeValueById, getAttributeValues } from 'company/api/attribute-values';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { VariantAttributeModel } from 'company/entities/modules/InventorySystem/VariantAttribute/VariantAttributeModel';
import WaitForFacility from '../WaitForFacility';
import { getVariantAttributeById } from 'company/api/variant-attributes';

interface VariantAttributeSearchProps extends TemplateDropdownProps {
  attribute_id: number;
}

const VariantAttributeValueSearchDropdown: React.FC<VariantAttributeSearchProps> = (props) => {
  const [attribute, setAttribute] = useState<VariantAttributeModel>();

  useEffect(() => {
    if (props.attribute_id) getVariantAttributeById(props.attribute_id).then((res) => setAttribute(res.data));
  }, [props.attribute_id]);

  return (
    <WaitForFacility facility={!!attribute}>
      <TemplateDropdown
        {...props}
        entity={'Variant Attribute Value'}
        displayName={attribute?.attribute_name}
        limit={1}
        getData={(query: ApiQuery) => getAttributeValues(props.attribute_id, query)}
        getById={(id: number) => getAttributeValueById(id)}
        processOption={(attribute) => {
          return { key: attribute.value, value: attribute.id, data: attribute };
        }}
        processNewRecord={(record) => record.value}
        data={{ attribute_id: props.attribute_id }}
      />
    </WaitForFacility>
  );
};

export default VariantAttributeValueSearchDropdown;
