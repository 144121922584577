import { ApiQuery, FieldInput } from 'core/model/interface';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { createChart, deleteChart, getCharts } from 'carego-admin/api/carego-charts';

import { CustomGridCell } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { create_chart_schema } from 'carego-admin/model/schema';
import { formatDate } from 'core/utils';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const Chart = () => {
  const [refreshTable, setRefreshTable] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const addFormFields: FieldInput[] = [
    {
      field_name: 'name',
      display_name: 'Chart Name',
      type: 'text',
      span: 6,
    },
    {
      field_name: 'chart_code',
      display_name: 'Chart Code',
      type: 'text',
      span: 6,
    },
  ];

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Chart Name', flex: 1 },
    { field: 'chart_code', headerName: 'Chart Code', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getCharts(query);
  };

  const handleDelete = (data: any) => {
    return deleteChart(data);
  };

  const handleSubmit = async (data: any) => {
    try {
      await createChart(data);
      enqueueSnackbar(`Chart successfully created!`, { variant: 'success' });
      setRefreshTable((prev: number) => prev + 1);
    } catch (err) {
      return console.error(err);
    }
  };

  // setRefreshTable((prev: number) => prev + 1);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        tableName: 'Charts',
        entityName: 'Chart',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
        },
        tableForm: {
          formFields: addFormFields,
          formInitialValues: { name: '', chart_code: '' },
          formSchema: create_chart_schema,
          formSubmitApiFunction: handleSubmit,
        },
        tableAction: {
          includeUpdateAction: true,
          includeDeleteAction: true,
          includeAddButton: true,
          updateButtonLabel: 'Update',
          deleteButtonLabel: 'Delete',
          deleteApiFunction: handleDelete,
          handleRowActionsClick(selectedRow, action) {
            if (action === 'update') {
              navigate(`/admin/charts/${selectedRow.id}`);
            }
          },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return <PageBuilder content={content} />;
};

export default Chart;
