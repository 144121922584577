import { SUPPLIER_ENTITY_NAME, SupplierModel, supplierEntityFields, supplierTableColumn } from './SupplierModel';
import { deleteSupplier, getSuppliers } from 'company/api/suppliers';
import { forwardRef, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { CustomModal } from 'core/components';
import ProductVariantForm from '../Product/ProductVariant/ProductVariantForm';
import SupplierForm from './SupplierForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  visibleColumns?: string[];
  withContainer?: boolean;
  tableAction?: TableActionProps;
  tableRef?: any;
};

const SupplierTable = forwardRef((props: Props, ref) => {
  const { setDrawerSelectedRow, drawerSelectedRow, visibleColumns, withContainer, tableAction, tableRef } = props;

  const [selectedRow, setSelectedRow] = useState<SupplierModel>();
  const [openExclusiveProductModal, setOpenExclusiveProductModal] = useState<boolean>(false);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleDelete = async (rowid: number) => {
    await deleteSupplier(rowid);
  };

  const addExclusiveProduct = async (data: SupplierModel) => {
    setSelectedRow(data);
    setOpenExclusiveProductModal(true);
  };

  const variantFormCallbackAfterSubmit = () => {
    setOpenExclusiveProductModal(false);
    callbackAfterSubmit();
  };

  return (
    <>
      <TemplateTable
        ref={mainRef}
        rowId={'id'}
        entityName={SUPPLIER_ENTITY_NAME}
        fields={supplierEntityFields}
        visibleColumns={visibleColumns ?? supplierTableColumn}
        templateForm={<SupplierForm supplier={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
        handleDelete={handleDelete}
        getData={getSuppliers}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        withContainer={withContainer}
        tableComponent={{ hideSearch: !!selectedRow }}
        tableAction={{
          ...tableAction,
          rowActions: [{ label: 'Add Exclusive Product', action: addExclusiveProduct, icon: <AddIcon /> }],
          rowActionRequiredAuth: ['MANAGE_SUPPLIERS'],
        }}
      />
      <CustomModal
        header={'Add Exclusive Product' + (selectedRow ? ' - ' + selectedRow.supplier_name : '')}
        open={openExclusiveProductModal}
        setOpen={setOpenExclusiveProductModal}
        width={800}
      >
        <ProductVariantForm supplierId={selectedRow?.id} callbackAfterSubmit={variantFormCallbackAfterSubmit} />
      </CustomModal>
    </>
  );
});

export default SupplierTable;
