/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { Tooltip } from '@mui/material';
import { UserContext } from 'core/context/user.context';
import { isDevMode } from './HideOrShowComponent';

type CompanyProtectedComponentProps = {
  requiredAuth?: string[];
  children: React.ReactNode;
  showNoAccess?: boolean;
};

const CompanyProtectedComponent: React.FC<CompanyProtectedComponentProps> = ({
  requiredAuth,
  children,
  showNoAccess,
}) => {
  const { user } = useContext(UserContext);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    checkUserAuthority();
  }, [user, requiredAuth]);

  const checkUserAuthority = () => {
    if (user) {
      if (requiredAuth && requiredAuth.length && isDevMode()) {
        const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));

        setVerified(!!verified_auths.length);
      } else {
        setVerified(true);
      }
    }
  };

  return verified ? (
    <>{children}</>
  ) : (
    <>
      {showNoAccess ? (
        <Tooltip title="No access">
          <BlockOutlinedIcon sx={{ color: 'grey' }} />
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyProtectedComponent;
