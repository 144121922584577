export const medical_history = [
  'Tuberculosis',
  'Diabetes Mellitus',
  'Cancer or Tumor',
  'Asthma',
  'Thyroid Disorders',
  'Gastritis /Ulcer',
  'Pnuemonia',
  'Eye and Ear Disorders',
  'Kidney & Liver Disease',
  'Hypertension',
  'Nose and Throat Disorders',
  'Seizure Disorders',
  'Heart Disease',
  'Skin Disease',
];
export const medical_history2 = [
  'Present Symptoms',
  'Other Past Medical History',
  'Family Medical History',
  'Operations and Accidents',
  'Allergies',
  'OB & Menstrual History',
  'OB Score',
  'Smoking History',
  'If "Smoker" is selected, indicate duration',
  'Alcohol Consumption',
];
export const vital_signs = [
  "Height (ft' in)",
  'Height (m)',
  'Weight (lbs)',
  'BMI',
  'BMI Class',
  'Blood Pressure (mmHg)',
  'Pulse Rate (per min)',
  'Respiratory Rate (per min)',
  'Temperature (°C)',
  'Blood Pressure Class',
  'Weight (kg)',
];
export const eye_test = ['Eye Test', 'OD', 'OS', 'OU', 'Color Vision (Ishihara)'];
export const dental_exam = [
  'For Extraction',
  'For Restoration',
  'For Oral Prophylaxis',
  'Missing',
  'Dentures',
  'Others',
];
export const physical_exam = [
  'Head/Scalp',
  'Eyes',
  'Ears',
  'Nose/Sinuses',
  'Mouth/Throat',
  'Neck/Thyroid',
  'Chest/Breast',
  'Lungs',
  'Heart',
  'Abdomen',
  'Anus',
  'Genitals',
  'Extremities',
  'Skin',
];

export const hematology = [
  'Hemoglobin',
  'Hematocrit',
  'RBC Count',
  'WBC Count',
  'Platelet Count',
  // 'Segmenters',
  // 'Lymphocytes',
  // 'Monocytes',
  // 'Eosinophils',
  // 'Basophils',
];

export const differential_count = [
  'Segmenters',
  'Lymphocytes',
  'Monocytes',
  'Eosinophils',
  'Basophils',
];

export const hematology_normal_values = [
  '(M) 135 - 180 g|L (F) 120 - 157 g|L',
  '(M) 0.40 - 0.54% (F) 0.36 - 0.47%',
  '3.8 - 5.8 x 10^12/L',
  '5 - 10 x 10^9/L',
  '150 - 450 / cumm',
  // '0.35 - 0.65',
  // '0.25 - 0.45',
  // '0.0 - 0.10',
  // '0.0 - 0.03',
  // '0.0 - 0.01',
];

export const differential_count_normal_values = [
  '0.35 - 0.65',
  '0.25 - 0.45',
  '0.0 - 0.10',
  '0.0 - 0.03',
  '0.0 - 0.01',
];

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial Cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals'
];

export const fecalysis = ['Color', 'Consistency', 'WBC', 'RBC', 'Parasites', 'Parasite Seen', 'Remarks'];
export const xray = ['RESULT', 'IMPRESSION'];
export const other_tests = ['Drug Test', 'Blood Chemistry', 'ECG Findings'];
export const blood_typing = ['Blood Type', 'RH Typing'];

export const medical_history_index = [1, 6, 11, 2, 7, 12, 3, 8, 13, 4, 9, 14, 5, 10, 15];
export const medical_history_mapping = medical_history.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Past Medical History',
    table_column_name_answer: 'Yes or No',
    more_info: '',
  };
  return acc;
}, {});

export const medical_history_mapping2 = medical_history2.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
  };
  return acc;
}, {});

export const vital_signs_mapping = vital_signs.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Vitals Signs',
  };
  return acc;
}, {});

export const eye_test_mapping = eye_test.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Eye Test',
  };
  return acc;
}, {});

export const dental_exam_mapping = dental_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Dental Exam',
  };
  return acc;
}, {});

export const fecalysis_mapping = fecalysis.reduce((acc: any, condition) => {
  acc['F_' + condition] = {
    template_name: 'Fecalysis',
  };
  return acc;
}, {});

export const physical_exam_mapping = physical_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Physical Exam',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Physical Exam',
    table_column_name_answer: 'Findings',
  };
  return acc;
}, {});

export const hematology_mapping = hematology.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Test',
    table_column_name_answer: 'Result',
  };
  return acc;
}, {});

export const differential_count_mapping = differential_count.reduce((acc: any, condition) => {
  acc['DC_' + condition] = {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: 'Differential Count',
    table_column_name: 'Test',
    table_column_name_answer: 'Result',
  };
  return acc;
}, {});

export const urinalysis_mapping = urinalysis.reduce((acc: any, condition) => {
  acc['U_' + condition] = {
    template_name: 'Urinalysis',
  };
  acc['Remarks (Significant Findings based on Result)'] = {
    template_name: 'Urinalysis',
  };
  return acc;
}, {});

// export const urinalysis_mapping = urinalysis.reduce((acc: any, condition, index) => {
//   acc[condition] = {
//     template_name: 'Urinalysis',
//     source_type: 'table',
//     table_name: '',
//     table_column_name: '',
//     table_column_name_answer: 'Result',
//   };
//   if (index + 1 === urinalysis.length) {
//     acc['Remarks (Significant Findings based on Result)'] = {
//       template_name: 'Urinalysis',
//     };
//   }
//   return acc;
// }, {});

// export const fecalysis_mapping = fecalysis.reduce((acc: any, condition, index) => {
//   acc['F_' + condition] = {
//     template_name: 'Fecalysis',
//     source_type: 'table',
//     table_name: '',
//     table_column_name: '',
//     table_column_name_answer: 'Result',
//   };
//   if (index + 1 === fecalysis.length) {
//     acc['Remarks'] = {
//       template_name: 'Fecalysis',
//     };
//   }
//   return acc;
// }, {});

export const other_tests_mapping = other_tests.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Other Tests Findings',
  };
  return acc;
}, {});

export const xray_mapping = xray.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Radiologic Findings',
  };
  return acc;
}, {});

export const blood_type_mapping = blood_typing.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Blood Type',
  };
  return acc;
}, {});

export const summary_mapping = {
  ...medical_history_mapping,
  ...medical_history_mapping2,
  ...vital_signs_mapping,
  ...eye_test_mapping,
  ...dental_exam_mapping,
  ...physical_exam_mapping,
  ...hematology_mapping,
  ...differential_count_mapping,
  ...urinalysis_mapping,
  ...fecalysis_mapping,
  ...other_tests_mapping,
  ...xray_mapping,
  ...blood_type_mapping,
  'Blood Typing': {
    template_name: 'Hematology',
  },
  'RH Typing': {
    template_name: 'Hematology',
  },
  'Hepatitis B Result': {
    template_name: 'Hepatitis B Ag',
  },
};
