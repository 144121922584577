import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'prescriptions';

export const getPrescriptions = (facility_id: number, patient_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/many/${patient_id}`, {
    params: query,
  });
};

export const getPrescription = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createPrescription = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const createPrescriptionWithMedicines = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/with-medicines`, data);
};

export const updatePrescription = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deletePrescription = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const duplicatePrescription = (facility_id: number, id: number) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/duplicate/${id}`);
};

export const sendPrescription = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/send`, data);
};
