import { Box, Drawer, Typography, useTheme } from '@mui/material';

import { tokens } from 'theme/theme';

type BottomDrawerProps = {
  title: string;
  children: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  height?: string;
};

export default function CustomBottomPopover({ title, children, open, setOpen, height }: BottomDrawerProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Drawer
      open={open}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderRadius: '30px 30px 0 0',
          zIndex: 2200,
        },
      }}
      sx={{ zIndex: 2000 }}
      onClose={() => setOpen(false)} // this is for when you click outside the modal
    >
      <Box sx={{ minHeight: '200px' }} onClick={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: colors.light_blue_background,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '1rem 0',
          }}
        >
          <Typography sx={{ fontSize: '1.25rem', color: colors.accent, fontWeight: '600' }}>{title}</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Box sx={{ width: '95%' }}>{children}</Box>
        </Box>
      </Box>
    </Drawer>
  );
}
