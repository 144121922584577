import {
  ATTRIBUTE_VALUE_ENTITY_NAME,
  AttributeValueModel,
  addAttributeValueFormFields,
  attributeValueFields,
  attributeValueInitialValues,
  attributeValueModelSchema,
} from './AttributeValueModel';
import { createAttributeValue, updateAttributeValue } from 'company/api/attribute-values';

import TemplateForm from 'company/entities/Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  attribute_id: number;
  value?: AttributeValueModel;
  callbackAfterSubmit?: (data?: any) => void;
};

const AttributeValueForm: React.FC<Props> = (props) => {
  const { attribute_id, value, callbackAfterSubmit } = props;

  const handleFormSubmit = (data: AttributeValueModel) => {
    return value && value.id ? updateAttributeValue(value.id, data) : createAttributeValue(attribute_id, data);
  };

  return (
    <TemplateForm
      submitButtonId={'create_-_submit_button'}
      entity={value}
      
      entityName={ATTRIBUTE_VALUE_ENTITY_NAME}
      fields={attributeValueFields}
      visibleFields={addAttributeValueFormFields}
      schema={attributeValueModelSchema}
      initialValues={value ? changeNullToBlank(value) : attributeValueInitialValues}
      formSubmitApiFunction={handleFormSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default AttributeValueForm;
