import { Box, Divider, Typography } from '@mui/material';
import { CustomContainer, Header } from 'core/components';
import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';

const TermsOfUse = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  useEffect(() => {
    setBreadcrumb([{ label: 'Terms of Use' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CustomContainer>
      <Box mt={'20px'}>
        <Typography variant="h2" display="block" fontWeight="bold" sx={{ paddingBottom: '20px' }}>
          TERMS OF USE
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the CareGo Health Suite web application
          operated by the SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          The access to and use of the PLATFORM for performing services is conditioned on your acceptance of and compliance
          with these Terms. These Terms apply to all stakeholders of both the SERVICE PROVIDER and the USER. Disagreement
          with any part of the terms described herein will not entitle the USER to any refunds from any payments made to the
          SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER understands that the PLATFORM is a web application that allows its representatives, employees, staff, and affiliates
          to manage their health facilities efficiently, send health reminders, and collectively view patients. USER accepts that the
          PLATFORM is only limited to sending health reminders only to the consenting clients of the USER. USER recognizes that
          the SERVICE PROVIDER, its representatives, employees, and staff are not always available for any urgent needs that
          can be due to direct or indirect, accidental or consequential, events related to the PLATFORM. USER understands that the
          SERVICE PROVIDER operates online customer support within office hours of 9:00 AM to 6:00 PM during weekdays
          excluding national and Muntinlupa City non-working holidays.
        </Typography>

        <Header title="ACCESS TO THE PLATFORM" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          By encoding consenting patient/client data, or sending health reminders through the PLATFORM, USER verifies that its
          representatives, employees, staffs, and affiliates voluntarily registered to the PLATFORM without coercion or any forms of
          intimidation or compulsion from anyone and have submitted all the needed information for the ethical, moral use of the
          PLATFORM with full consent and understanding of its impact to beneficiaries and stakeholders. By continuing through the
          service, USER authenticates that registered users to the platform are at least of legal age and/or possess the legal right
          and authority from the USER to access, obtain, process, personally identifiable and sensitive data of its clients.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER acknowledges that to maintain integrity and health data security on the use of the PLATFORM, it shall direct its
          employees, representatives, and agents to conduct best practices and settings as recommended by SERVICE PROVIDER.
          USER understands that to access the PLATFORM it shall have to supply its own necessary equipment to its
          employees, representatives, agents, and staff, which are not of responsibility by the SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER comprehends that malwares, computer viruses, and other malicious software found or undetected in the USER’s
          software and system are beyond the responsibility of the SERVICE PROVIDER. USER recognizes that such malicious
          software that could potentially disrupt SERVICE PROVIDER’s services to the USER will not lead to suspension of
          subscription, and that any legal liability, harm, or obligations arising from such occurrence will not be associated with the
          SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER agrees that the SERVICE PROVIDER, although it owns and has bounded control to the PLATFORM, does not
          have control over and shall have no responsibility for network gateway, acts or omissions of recipients from networks, and
          guarantee of intended recipients to receive SMS set by USER for sending.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER will designate accounts who will access the PLATFORM and that personally identifiable data required for account
          creation, except for the USER’s password, will be shared to the SERVICE PROVIDER.
        </Typography>

        <Header title="HEALTH REMINDERS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER understands that to use the PLATFORM, USER staff will need to supply correct consented personal information
          and patient information relevant to achieving successful personalized or general health reminders, without limitation to
          patient’s name, patient’s contact number, patient’s assigned sex at birth, patient’s birthday and patient’s address.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER understands that it is its responsibility to seek consent from patients for data to be processed to the PLATFORM of
          the SERVICE PROVIDER. Any data uploaded by the USER to the PLATFORM will be recognized by the SERVICE
          PROVIDER to be data consented by USER’s patients/clients for processing personally identifiable information.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER understands that the data accuracy of personalized or general health reminders is dependent on the accuracy of
          data supplied to the PLATFORM by the USER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER acknowledges that the PLATFORM provided by the SERVICE PROVIDER is only limited to clinic management,
          health reminders and its efficient scheduling of patients/clients of the USER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER agrees that content of each health reminder to be sent will require approval from SERVICE PROVIDER. However,
          USER recognizes that approval from SERVICE PROVIDER does not constitute responsibility to the content of the health
          reminder sent and on how it will be used, perceived, by the recipients of such health reminder.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER comprehends that health reminders can only be sent to mobile numbers belonging to networks registered within the
          Philippines and mobile numbers that are within the territory of the Republic of the Philippines. USER understands that 1
          SMS will only be composed of 160 characters including SMS ID. USER understands that it cannot hold the SERVICE
          PROVIDER liable for discrepancies in estimation of character length per SMS per recipient.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER acknowledges that the PLATFORM shall not be used for SPAMMING, SCAMMING, SABOTAGING, HACKING, or
          any acts that will cause disruption of SERVICE PROVIDER operations, or that will cause social unrest, upheaval, or
          rebellion among its recipients.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          By sending a health reminder through the PLATFORM by any of the representatives, employees, staff, and affiliates of the
          USER, the USER holds the SERVICE PROVIDER harmless from any forms of liabilities that may arise from the sent
          health reminders through the PLATFORM and to any liabilities that may arise on the use of the SERVICE PROVIDER of
          the third-party SMS API providers, and data storage and processing from third-party providers.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          By using the PLATFORM, the USER understands that the successful implementation of health appointments, operations,
          procedures, and engagement of patients are not within the scope of service provided by the SERVICE PROVIDER. The
          USER agrees to indemnify and hold CP HEALTH INNOVATIONS INC., its employees, officers, contributors, volunteers,
          stockholders, affiliates, and co-founders, without obligation to any form of claims for damages whether direct or indirect,
          related or consequential to the use of the PLATFORM.
        </Typography>

        <Header title="INTELLECTUAL PROPERTY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER agrees to respect any copyright, trademark, and intellectual products made by the SERVICE PROVIDER. USER
          understands that the use of the PLATFORM is limited to non-sectarian, non-political purposes and that it should not
          infringe the CP HEALTH INNOVATIONS INC.'s rights to the PLATFORM. USER understands that any forms of
          reproduction, publication, and distribution of CP HEALTH INNOVATIONS INC.’s intellectual property, documentation,
          product manual, and agreements without written consent of the CP HEALTH INNOVATIONS INC. assigned representative
          is liable for prosecution under the fullest extent of the law of the Republic of the Philippines.
        </Typography>

        <Header title="CONTINUOUS SERVICE" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER accepts that the SERVICE PROVIDER and its representatives, employees, staff, and affiliates reserve the right to
          deny the USER service in case of events that include but are not limited to, on the grounds of misuse of the PLATFORM,
          breach on the terms of use of the PLATFORM, abuse of the PLATFORM, unethical and immoral use of the PLATFORM,
          infringement of rights of the SERVICE PROVIDER to the PLATFORM, intentional sabotage of the operations of the
          PLATFORM, hacking of the PLATFORM, cases that are considered hazards on the health of SERVICE PROVIDER
          personnel, cases that are considered to put the SERVICE PROVIDER representatives, employees, staff, and affiliates to
          life-threatening danger, and other reasons that are not presented in this agreement based on consideration by the
          management of SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER fully comprehends that the PLATFORM is meant for informational purposes only and is not meant to serve as exact
          directions of availability on time and locations of health appointments, programs, for the USER’s representatives,
          employees, staff, patients/clients, and beneficiaries. USER acknowledges that it does not hold the SERVICE PROVIDER,
          its owners, officers, employees, and affiliates, accountable for any discrepancies on the expected services of
          patients/clients to the USER, services of the SERVICE PROVIDER, functionalities, features, and interface available on the
          CareGo Health Suite web application and to any claims of stakeholders of both parties for damage, direct or indirect,
          random or consequential to the use of the PLATFORM.
        </Typography>

        <Header title="THIRD-PARTY WEBSITES AND LINKS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Communication between SERVICE PROVIDER and USER through third-party websites or services (such as
          communications through Google, Facebook, Messenger, or Yahoo, and etc.) that are not owned or controlled by
          the CP HEALTH INNOVATIONS INC. will be considered as services which SERVICE PROVIDER has no control over and
          assumes no responsibility for the data security, content, privacy policies, or practices of any third-party
          websites or services used to transmit, receive, process data and/or as modes of communication between the two
          parties. USER acknowledges reports generated from the PLATFORM opened, and read through third-party software
          (such as Microsoft Excel, Adobe, and etc.) will not be of responsibility, or of liability, directly or
          indirectly, for any damage or loss caused or alleged to be caused by or in connection to the SERVICE PROVIDER
          and its representatives, employees, staff, and affiliates.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER further acknowledges and agrees that the SERVICE PROVIDER and representatives, employees, staff, and
          affiliates shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on any such content, goods, or services
          available on or through the PLATFORM.
        </Typography>

        <Header title="TERMINATION" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER fully comprehends and accepts that the SERVICE PROVIDER may terminate or suspend access to the PLATFORM
          immediately, without prior notice or liability, for any reason whatsoever, including without limitation if in
          case the USER breach the Terms, Service Subscription Agreement, Non-settlement of financial obligations and
          other reasons whatsoever that are deemed as sabotaging the PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          All provisions of the Terms which by their nature should survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </Typography>

        <Header title="CHANGES" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. reserves the right, at its sole discretion, to modify or replace these Terms at any
          time. If a revision is material, CP HEALTH INNOVATIONS INC. will try to provide notice prior to any new terms
          taking effect. What constitutes a material change will be determined at the SERVICE PROVIDER’s sole
          discretion.
        </Typography>

        <Divider />
        <Typography variant="h2" display="block" fontWeight="bold" sx={{ paddingTop: '60px', paddingBottom: '20px' }}>
          PRIVACY POLICY
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. operates www.caregosuite.health (the PLATFORM). This page informs the USER of the
          SERVICE PROVIDER’s policies regarding the collection, use, processing, and disclosure of Personal Information
          received from USER representatives, employees, staff, and affiliates who are users of the PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          SERVICE PROVIDER uses the Personal Information of USER staff only for providing excellent services and
          completion of the required information for the operation of the PLATFORM and for its improvement. By using the
          PLATFORM, USER agrees to the collection, and use of information of its representatives, employees, staff, and
          affiliates, and aggregate anonymous data of patients/clients of the USER in accordance with this policy.
        </Typography>

        <Header title="USER REPRESENTATIVE INFORMATION COLLECTION AND USE" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          While using the PLATFORM, SERVICE PROVIDER may ask the USER to provide with certain personally identifiable
          information of its representatives, employees, staff, and affiliates that can be used to contact or identify
          the USER. Personally identifiable information may include but is not limited to USER representative or
          employee name, address, e-mail address, and contact number. PII received by SERVICE PROVIDER from USERs, saved
          in the application will be considered as strictly confidential.
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          In accordance with the Philippine Data Privacy Act, USER or the Patient has the right to
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 25px', lineHeight: '28px' }}>
          (1) know the specifics about the PII and why it's being processed, the extent of its use, how long it will be kept, and
          where it's stored,
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 25px', lineHeight: '28px' }}>
          (2) know the details of processed PII and its origins upon request,
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 25px', lineHeight: '28px' }}>
          (3) correct inaccuracies or errors in personal data,
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 25px', lineHeight: '28px' }}>
          (4) oppose the processing of PII, except when it's required by law (such as through a court order), required for
        service delivery of USER and/or SERVICE PROVIDER, or due to a legal obligation,
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 25px', lineHeight: '28px' }}>
          (5) request the erasure or blocking of PII with the added option to suspend, withdraw, or demand its removal or
        destruction from the SERVICE PROVIDER’s system, in accordance with legal conditions, and
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 40px 60px', lineHeight: '28px' }}>
          (6) seek reparation from damages in case of improper, false, or unauthorized utilization of PII by SERVICE
        PROVIDER from the National Privacy Commission .
        </Typography>

        <Header title="LOG DATA" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER acknowledges that SERVICE PROVIDER may collect information from the browser whenever a user visits the
          PLATFORM. USER understands that Log Data may include information such as the user's computer's Internet Protocol
          ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time
          spent on those pages, and other statistics. In addition, USER understands that SERVICE PROVIDER may use necessary
          third-party services such as but not limited to, Amazon Web Services, ADSPARK m360 platform, and other statistical tools
          to collect, monitor, analyze the trends, browsing in our web application, requests, maintenance, security, and other
          necessities to be provided for continued usage. The PLATFORM’s Privacy Policy also includes the Amazon Web Services
          and ADSPARK INC.’s Privacy Policy and Terms of Use.
        </Typography>

        <Header title="COMMUNICATIONS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER understands that the SERVICE PROVIDER may use USER employees’ Personal Information to contact with
          newsletters, promotional materials, updates, and other information that the SERVICE PROVIDER sees would be of
          the USER's interest or benefit, from which any time the USER employees or staff may opt out.
        </Typography>

        <Header title="SECURITY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          The security of USER employees’ and representatives' Personal Information is important to CP HEALTH INNOVATIONS
          INC., but as no method of transmission over the Internet, or method of electronic storage is 100% secure, the USER
          recognizes that by using the PLATFORM, it holds the SERVICE PROVIDER free from liability or damages, direct or
          indirect, that may arise from using the PLATFORM. While SERVICE PROVIDER strives to use industrially acceptable
          means to protect Personal Information, we cannot guarantee its absolute security. By using our Site, USER holds the
          Company harmless from any liabilities that may arise from any forms of damage including but not limited to a leak or
          hacking of the Site.
        </Typography>

        <Header title="PATIENT/CLIENT ENROLLMENT TO SMS REMINDERS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER understands that enrollment of patients/clients to receive health reminders or notifications through the
          PLATFORM needs to be with consent of corresponding individuals for data processing of SERVICE PROVIDER. Any
          data uploaded by USER will be treated by SERVICE PROVIDER to be consented for processing.
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          USER acknowledges and holds the SERVICE PROVIDER harmless from any forms of liabilities that may arise from
          processing unconsented data. USER understands that as an entity focused on delivering quality healthcare to
          its patients/clients, its responsibility includes health data transparency to its served populations on to
          where it is being deposited. USER recognizes that data collected by SERVICE PROVIDER in the web application is
          only limited to non-identifiable information that may be processed in the SERVICE PROVIDER’s electronic
          infrastructure, third-party storage and/or SMS providers, and that aggregated anonymous data being processed
          including but not limited to purposes for academic, service improvement, development, and research with
          partner organizations, and relevant government agencies. USER recognizes that CP HEALTH INNOVATIONS INC. is a
          Filipino company that serves the USER through its PLATFORM, and related health informatics services, which
          only uses non-personally identifiable information.
        </Typography>

        <Header title="DATA PROCESSING" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          The agreement for data processing will commence on the date of use of the PLATFORM. SERVICE PROVIDER warrants
          to maintain the USER's information security and data privacy policies limited only in the PLATFORM provided,
          and agree that this agreement may be suspended if, despite due diligence, the USER is exposed to a security
          breach, personnel and patient data breach, and/or if undergoing or will undergo an investigation by the
          National Privacy Commission.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          The USER authorizes SERVICE PROVIDER to process USER and Patient PII and sensitive data for as long as necessary
          to fulfill the purpose for which it was collected and to securely store in electronic cloud servers that may be not within the
          Republic of the Philippines for 10 years from last update of the Patient data. USER understands that successful delivery of services
          by the SERVICE PROVIDER, may need foreign third-party services availed by the PARTIES. Patients and USER
          in accordance with the Data Privacy Act may request for erasure of PII from all forms of records of SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          Uploaded data of patients/clients of USER are considered as data consented for processing and analysis given that it is
          anonymized, and aggregated, utilized for but not limited to improvement of the services, service development, and public
          health research of the SERVICE PROVIDER. USER understands that Data Processing of PII and sensitive information will
          be of its sole responsibility and that the SERVICE PROVIDER will NOT disclose any PII uploaded in the PLATFORM to
          third-parties, unless authorized in specific instances by the USER or required under legal court order.
        </Typography>

        <Header title="DATA BREACH REPORT" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          USER understands that any health data breach accessed outside the PLATFORM during the time of breach are out of
          liability, and responsibility of the SERVICE PROVIDER. USER comprehends that breached access to USER health data
          storage either outside or inside the PLATFORM that involves at least 100 individuals obliges the USER representatives,
          employees, and staff, to notify CP HEALTH INNOVATIONS INC. Delay in notifying SERVICE PROVIDER of more than
          480 minutes from its discovery by the USER of the data breach inside the PLATFORM holds CP HEALTH INNOVATIONS
          INC. free from any liability to the USER In any situations outside the USER’s responsibility and obligations on health data
          security,
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. will notify the USER for any unauthorized data breach detected in the PLATFORM. CP
          HEALTH INNOVATIONS INC. enforces data security seriously and uses industry-standard practices to ensure safety,
          accuracy, intact integrity of data, from the health staff to USER representatives, USERs, and patients. In case that despite
          the enforcement of such best practices that the PLATFORM was penetrated by hacking and other malicious intents,
          resulting to unauthorized access to health data of at least 100 people, CP HEALTH INNOVATIONS INC. will follow strictly
          the guidelines and provisions set by the DATA PRIVACY ACT OF 2012 and notify within 72 hours of knowledge the USER
          affected, National Privacy Commission (NPC), and other relevant government agencies and stakeholders. After
          notification, an internal investigation will be pursued by CP HEALTH INNOVATIONS INC. which may also include
          suspension of PLATFORM services, depending on the recommendations of the National Privacy Commission and
          SERVICE PROVIDER technology team.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          Any services of SERVICE PROVIDER paid and confirmed prior to the declared date of suspension of services to the
          USER will be computed and to be returned to the affected USER on a prorated basis.
        </Typography>

        <Header title="CHANGES TO PRIVACY POLICY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          This Privacy Policy is effective as of August 1, 2024, and will remain in effect except with respect to any changes in its
          provisions in the future, which will be in effect immediately after posting.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          SERVICE PROVIDER reserves the right to update or change the Privacy Policy for the PLATFORM at any time. USER
          representatives, employees, and affiliates are encouraged to check this Privacy Policy periodically. The USER’s continued
          use of the PLATFORM after SERVICE PROVIDER post any modifications to the Privacy Policy, will constitute USER’s
          acknowledgment of the modifications and consent to abide by and be bound by the modified Privacy Policy.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          If SERVICE PROVIDER makes any material changes to this Privacy Policy, USER representative/s will be notified either
          through the email address provided or by placing a prominent notice on our websites www.caregohealthsuite.com or
          www.caregosuite.health.
        </Typography>

        <Header title="CONTACT US" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          For any questions about this Privacy Policy, please contact CP HEALTH INNOVATIONS INC. Data Protection Officer
          at privacy@cphealthinnovations.com or +63 917 328 1202.
        </Typography>
      </Box>
    </CustomContainer>
  );
};

export default TermsOfUse;
