import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'email-template-category';

export const getAllCategories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/public/${path}`, { params: query });
};

export const createCategory = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateCategory = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

export const deleteCategory = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
