import { Box, IconButton, List, ListItem, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhotoIcon from '@mui/icons-material/Photo';
import { tokens } from 'theme/theme';

type Props = {
  files: any[];
  closeUploadPreview: (close: boolean) => void;
  setAllSuccess: (close: boolean) => void;
};
export const FileUploadPreview: React.FC<Props> = ({ files = [], closeUploadPreview, setAllSuccess }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filesSuccessful, setFilesSuccessful] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<number>(0);
  const [uploadDone, setUploadDone] = useState<boolean>(false);
  useEffect(() => {
    const success = files.filter((file) => file.status === 'success').length ?? 0;
    const uploadedFiles =
      files.filter((file) => file.isUploading !== undefined && file.isUploading === false).length ?? 0;
    const uploadDone = uploadedFiles === files.length ?? 0;
    setFilesSuccessful(success);
    setUploadedFiles(uploadedFiles);
    setUploadDone(uploadDone);
  }, [files]);

  const handleClose = () => {
    closeUploadPreview(false);
    setAllSuccess(filesSuccessful === files.length);
  };

  return (
    <Box
      sx={{
        border: '1px solid ' + colors.primary,
        backgroundColor: colors.light_blue_background,
        paddingTop: '20px',
        paddingBottom: '10px',
        borderRadius: '10px',
        width: '400px',
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        zIndex: 20,
      }}
    >
      <Box display="flex" paddingRight="20px">
        <Box
          sx={{
            backgroundColor: colors.light_blue_background,
            paddingBottom: '10px',
            paddingLeft: '15px',
            width: '100%',
          }}
        >
          <Typography fontWeight="bold">
            {`Uploaded ${uploadedFiles}/${files.length} file ${files.length > 1 ? 's' : ''}`}
            {uploadDone && filesSuccessful < files.length ? ` (${files.length - filesSuccessful} failed)` : ''}
          </Typography>
        </Box>
        <IconButton
          disableRipple
          disabled={!uploadDone}
          onClick={() => handleClose()}
          sx={{
            padding: '0',
            paddingBottom: '16px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.light_blue_background,
          padding: '10px',
          paddingRight: '18px',
          width: '100%',
        }}
      >
        {files.map((file, index) => {
          return (
            <List key={index}>
              <ListItem disablePadding>
                <Box display="flex" justifyContent="space-between" width="inherit">
                  <Box display="flex">
                    <PhotoIcon color="primary" />
                    <Typography variant="h5" sx={{ marginLeft: '10px' }}>
                      {file.name.substring(0, 30)}...
                    </Typography>
                  </Box>
                  <Box sx={{ marginRight: '10px' }}>
                    {file.isUploading ? (
                      <CircularProgress size={15} />
                    ) : file.status === 'success' ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorOutlineIcon color="error" />
                    )}
                  </Box>
                </Box>
              </ListItem>
            </List>
          );
        })}
      </Box>
    </Box>
  );
};

export default FileUploadPreview;
