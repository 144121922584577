import { Box, useMediaQuery } from '@mui/material';

import { FC } from 'react';

type CustomDrawerProps = {
  parentComponent: JSX.Element;
  childComponent: JSX.Element;
  showChild: boolean;
  parentWidth?: number;
};

const CustomDrawer: FC<CustomDrawerProps> = ({ parentComponent, childComponent, showChild, parentWidth = 0 }) => {
  const isMobilePhone = useMediaQuery('(max-width: 600px)');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        width: '100%',
        position: 'relative',
        flexDirection: isMobilePhone ? 'column' : 'row',
        '& .parent-container': { width: showChild && !isMobilePhone ? parentWidth : '100%' },
        '& .child-container': { width: isMobilePhone ? '100%' : `calc(100% - ${parentWidth + 20}px)` },
      }}
      className={showChild ? 'show-drawer' : ''}
    >
      <Box
        className="parent-container"
        width="100%"
        sx={{ transition: 'all ease-in-out 0.2s' }}
        height={'calc(100vh - 200px)'}
        paddingRight="10px"
        overflow="auto"
      >
        {parentComponent}
      </Box>

      {showChild && <Box className="child-container">{childComponent}</Box>}
    </Box>
  );
};

export default CustomDrawer;
