import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'product-categories';

export const getProductCategory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getProductCategories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const createProductCategory = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateProductCategory = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteProductCategory = (categories: any) => {
  return axios.delete(`${baseURL}/${path}`, { data: categories });
};

export const getProductsInCategory = (category_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/products/${category_id}`, {
    params: query,
  });
};

