import { CustomModal, PrimaryButton, RegularButton } from 'core/components';
import { addCompanyHealthProgram, getAvailablePrograms } from 'carego-admin/api/company-program';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { CareGoTable } from 'core/PageBuilder';
import { CompanyModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

type AddHealthProgramsProps = {
  company: CompanyModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  header: string;
  refreshMainTable: () => void;
};

const AddHealthPrograms: React.FC<AddHealthProgramsProps> = ({ company, open, setOpen, header, refreshMainTable }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [refreshTable, setRefreshTable] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedProgramIds, setSelectedProgramIds] = useState<any[]>([]);
  const [disableMultipleSelect, setDisableMultipleSelect] = useState<boolean>(true);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'program_name', headerName: 'Program Name', flex: 1 },
    { field: 'program_code', headerName: 'Program Code', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <RegularButton
          label="Add"
          variant="outlined"
          size="small"
          color="success"
          disabled={disabled}
          onClick={() => {
            handleSubmit(params.row.id);
            setDisabled(true);
            setSelectedProgramIds([]);
          }}
        />
      ),
    },
  ];

  // Functions
  const getAvailableHealthPrograms = (query: ApiQuery) => {
    return getAvailablePrograms(company.id, query);
  };

  const handleSelectRows = (selected: any) => {
    setDisableMultipleSelect(false);
    setSelectedProgramIds(selected);
  };

  const handleSubmit = (data: any) => {
    setDisabled(true);
    const programs = { programs: JSON.stringify(data) };

    addCompanyHealthProgram(company.id, programs).then(() => {
      enqueueSnackbar('Health Program/s successfully added!', { variant: 'success' });
      refreshHealthPrograms();
      refreshMainTable();
      getAvailableHealthPrograms({});
      setDisabled(false);
    });
  };

  const refreshHealthPrograms = () => {
    setRefreshTable && setRefreshTable((prev: number) => prev + 1);
  };

  useEffect(() => {
    setDisabled(false);
    refreshHealthPrograms();
  }, []);

  return (
    <CustomModal open={open} setOpen={setOpen} width={1000} header={header}>
      <CareGoTable
        entityName="Health Programs"
        doNotWaitForFacility
        tableComponent={{
          columns: columns,
          getData: getAvailableHealthPrograms,
          forceRefresh: refreshTable,
          checkboxSelection: true,
          selectableRow: true,
          onSelectRow: handleSelectRows,
          disableSelectionOnClick: true,
          rowHeight: 35,
          tableHeight: 320,
          headerComponent: (
            <Box display="flex" gap="10px">
              <PrimaryButton
                onClick={() => {
                  setDisableMultipleSelect(true);
                  if (selectedProgramIds.length) {
                    handleSubmit(selectedProgramIds);
                  } else {
                    enqueueSnackbar('Invalid action. Select programs first!', { variant: 'error' });
                  }
                }}
                label={'Add Selected Programs'}
                disabled={!selectedProgramIds.length || disableMultipleSelect}
              />
            </Box>
          ),
        }}
        tableAction={{
          includeAddButton: false,
          includeDeleteAction: false,
          includeUpdateAction: false,
          actionFlex: 0.5,
        }}
      />
    </CustomModal>
  );
};

export default AddHealthPrograms;
