/* eslint-disable react-hooks/exhaustive-deps */

import { CustomTabs, Header } from 'core/components';
import { useContext, useEffect, useState } from 'react';

import Billing from './screens/Billing';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Plans from './screens/Plans';
import { TabProps } from 'core/components/CustomTabs';
import { isDevMode } from 'core/components/HideOrShowComponent';

const CompanySettings = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    // { label: 'SMS Credits', content: <Billing /> },
    { label: 'Plans', content: <Plans />, hidden: !isDevMode() },
  ];

  useEffect(() => {
    setBreadcrumb([{ label: 'Settings' }]);
  }, []);

  return (
    <Box>
      <Header title="My Account" />
      <Plans />
    </Box>
  );
};

export default CompanySettings;
