import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Article6: React.FC = () => {
  return (
    <Box>
      <Title>Schedule View</Title>
      <Step>1. Go to the “Family Planning” - “OCP Reminders” - "Schedule View” tab in application.</Step>
      <Step>2. Select the patient you want to View Sms Schedules and click the dropdown button.</Step>
      <Image src="/assets/FaqAssets/ocp/6/schedule/1-2.png" alt="Step Image" />
      <Step>3. Click “View SMS Schedules” in the dropdown option. </Step>
      <Image src="/assets/FaqAssets/ocp/6/schedule/3.png" alt="Step Image" />
      <Step>4. In the Table, you can click the message slider to view the Pending and Sent messages.</Step>
      <Image src="/assets/FaqAssets/ocp/6/schedule/4.png" alt="Step Image" />
      <Image src="/assets/FaqAssets/ocp/6/schedule/4.1.png" alt="Step Image" />

      <Title>Patient View</Title>
      <Step>1. Go to the “Family Planning” - “OCP Reminders” - "Patient View” tab in application.</Step>
      <Step>2. Select a Patient and click the patient row.</Step>
      <Image src="/assets/FaqAssets/ocp/5/patient/1-2.png" alt="Step Image" />
      <Step>3. Click Dropdown button</Step>
      <Image src="/assets/FaqAssets/ocp/5/patient/3.png" alt="Step Image" />
      <Step>4. Click “View SMS Schedules” in the dropdown option</Step>
      <Image src="/assets/FaqAssets/ocp/6/patient/4.png" alt="Step Image" />
      <Step>5. In the Table, you can click the message slider to view the Pending and Sent messages.</Step>
      <Image src="/assets/FaqAssets/ocp/6/patient/5.png" alt="Step Image" />
      <Image src="/assets/FaqAssets/ocp/6/patient/5.1.png" alt="Step Image" />
    </Box>
  );
};

export default Article6;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;
const Title = styled.p`
  margin-top: 8px;
  font-size: 17px;
  font-weight: bold;
`;
const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
