import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
  RowAction,
} from 'core/components';
import { LguModel, LguUserModel } from 'carego-admin/model/Entities';
import { changeNullToBlank, formatDate } from 'core/utils';
import { deactivate, exportUserAccounts, getUserAccounts, reactivate } from 'carego-admin/api/lgu-user';

import AddLguUser from './AddLguUser';
import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { GridColDef } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PasswordIcon from '@mui/icons-material/Password';
import UpdatePassword from './UpdatePassword';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { userGroups } from '../data/list';

type UserAccessProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lgu: LguModel;
};

const UserAccess: React.FC<UserAccessProps> = ({ lgu, open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const [openAddUserAccountModal, setOpenAddUserAccountModal] = useState<boolean>(false);

  const [lguUser, setLguUser] = useState<LguUserModel>();

  //deactivate or reactivate
  const [openConfirmationLguUserModal, setOpenConfirmationLguUserModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [userStatus, setUserStatus] = useState<number>(1);

  //update password
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'full_name', headerName: 'Full Name', flex: 1 },
    {
      field: 'user_group_id',
      headerName: 'User Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {userGroups?.map((u: any) => {
            if (u.id === params.row.user_group_id) return u.group_name;
            return '';
          })}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Registered',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <div onClick={() => setUserStatus(params.row.is_active)}>
          <RowAction actions={actions} data={params.row} />
        </div>
      ),
    },
  ];

  const updateLguUserPassword = (data: any) => {
    setLguUser(changeNullToBlank(data));
    setOpenUpdatePasswordModal(true);
  };

  const updateUserStatus = (data: any) => {
    if (data.is_active === 1) {
      showAlertDialog('Are you sure you want to deactivate ' + data.full_name + '?');
    } else {
      showAlertDialog('Are you sure you want to reactivate ' + data.full_name + '?');
    }
    setLguUser(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenConfirmationLguUserModal(true);
    setAlertContent(content);
  };

  const actions = [
    { label: 'Update Password', action: updateLguUserPassword, icon: <PasswordIcon /> },
    {
      label: userStatus === 1 ? 'Deactivate' : 'Reactivate',
      action: updateUserStatus,
      icon: userStatus === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
  ];

  const getUsers = (query: ApiQuery) => {
    setLoading(true);
    getUserAccounts(lgu.lgu_code, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleLguUserDeactivation = () => {
    deactivate(lguUser!.id)
      .then(() => {
        enqueueSnackbar('LGU User successfully deactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const handleLguUserReactivation = () => {
    reactivate(lguUser!.id)
      .then(() => {
        enqueueSnackbar('LGU User successfully reactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  return (
    lgu && (
      <CustomModal header={'User Access in ' + lgu.lgu_name + ' LGU'} open={open} setOpen={setOpen} width={1000}>
        <Box display="flex" gap="10px" width="100%">
          <PrimaryButton onClick={() => setOpenAddUserAccountModal(true)} label="Create User Account" />
        </Box>

        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getUsers}
          exportRecords={() => exportUserAccounts({ page: 1, length: 10 }, lgu.lgu_code)}
          forceRefresh={forceRefresh}
        />

        <AddLguUser
          open={openAddUserAccountModal}
          setOpen={setOpenAddUserAccountModal}
          refreshTable={refreshTable}
          lgu={lgu!}
        />

        <ConfirmationDialog
          open={openConfirmationLguUserModal}
          setOpen={setOpenConfirmationLguUserModal}
          content={alertContent}
          onConfirm={userStatus === 1 ? handleLguUserDeactivation : handleLguUserReactivation}
          confirmButtonColor={userStatus === 1 ? false : true}
        />

        <UpdatePassword
          open={openUpdatePasswordModal}
          setOpen={setOpenUpdatePasswordModal}
          lguUser={lguUser!}
          refreshTable={refreshTable}
        />
      </CustomModal>
    )
  );
};

export default UserAccess;
