import { ApiQuery } from 'core/model/interface';
import { CompanyTagsModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'tags';

export const getCompanyTags = (facility_id: number, query?: ApiQuery) => {
  // TEMPORARY SOLUTION
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: null });
};

export const getCompanyTag = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createCompanyTag = (facility_id: number, data: CompanyTagsModel) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const updateCompanyTag = (facility_id: number, id: number, data: CompanyTagsModel) => {
    return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteCompanyTag = (facility_id: number, tags: any) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}`, { params: tags });
};
  