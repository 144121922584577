import { Box, Button } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomModal,
  CustomTable,
  Header,
  ProtectedComponent,
  RegularButton,
} from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { deleteLguSMSTemplates, getLguSMSTemplates } from 'lgu/api/sms';
import { useEffect, useState } from 'react';

import CommonTemplates from './SmsTemplates/CommonTemplates';
import NewMessageTemplateModal from './components/NewMessageTemplateModal';
import ViewContent from 'core/components/modals/ViewContent';
import { useSnackbar } from 'notistack';

const MessageTemplates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [openNewMessageTemplateModal, setOpenNewMessageTemplateModal] = useState<boolean>(false);
  const [refreshTemplates, setRefreshTemplates] = useState(0);
  const [openTemplateDeleteDialog, setOpenTemplateDeleteDialog] = useState<boolean>(false);
  const [templateID, setTemplateID] = useState<number>(0);

  const [selectedRow, setSelectedRow] = useState<any>();
  const [openSmsPreview, setOpenSmsPreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [clearSelected, setClearSelected] = useState(0);

  const additionalTemplatesColumns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 1 },
    { field: 'template_body', headerName: 'Message Template', flex: 2 },
    { field: 'status', headerName: 'Status', flex: 0.5 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <ProtectedComponent requiredAuth={['LGU_ADMIN_ACTIONS']}>
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                setTemplateID(params.row.id);
                setOpenTemplateDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </Box>
        </ProtectedComponent>
      ),
    },
  ];

  const getAdditionalTemplates = () => {
    setLoading(true);
    getLguSMSTemplates().then((res) => {
      setRows(res.data.data);
      setRowCount(res.data.meta.total);
      setLoading(false);
    });
  };

  const showNewMessageTemplateModal = () => {
    setOpenNewMessageTemplateModal(true);
  };

  const handleTemplateDelete = () => {
    deleteLguSMSTemplates(templateID).then((res) => {
      enqueueSnackbar('SMS Template successfully deleted!', { variant: 'success' });
      setRefreshTemplates((prev: number) => prev + 1);
    });
  };

  const viewTemplateBody = async (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const selectedTemplate = rows.find((localizedTemplate) => localizedTemplate.id === selected[0]);
      setSelectedRow(selectedTemplate);
      setPreviewContent(selectedTemplate.template_body);
      setOpenSmsPreview(true);
    } else {
      setSelectedRow(undefined);
    }
  };

  useEffect(() => {
    setRefreshTemplates((prev: number) => prev + 1);
  }, [openNewMessageTemplateModal]);

  return (
    <>
      <CommonTemplates />

      <CustomContainer m={'20px 0 0 0'}>
        <Box display="block" width="100%">
          <Header title="Additional Message Templates" />
          <CustomTable
            rows={rows}
            rowCount={rowCount}
            columns={additionalTemplatesColumns}
            rowId={'id'}
            selectableRow
            clearSelected={clearSelected}
            handleSelectRow={(data: any) => viewTemplateBody(data)}
            getData={getAdditionalTemplates}
            forceRefresh={refreshTemplates}
            loading={loading}
            headerComponent={
              <ProtectedComponent>
                <RegularButton label="Request New Message Template" onClick={showNewMessageTemplateModal} />
              </ProtectedComponent>
            }
          />
        </Box>
        <CustomModal
          header={'Request New Message Template'}
          open={openNewMessageTemplateModal}
          setOpen={setOpenNewMessageTemplateModal}
        >
          <NewMessageTemplateModal setOpen={setOpenNewMessageTemplateModal} />
        </CustomModal>
      </CustomContainer>

      <ConfirmationDialog
        open={openTemplateDeleteDialog}
        setOpen={setOpenTemplateDeleteDialog}
        content={'Are you sure you want to delete this template?'}
        onConfirm={handleTemplateDelete}
      />

      <ViewContent
        content={previewContent}
        open={openSmsPreview}
        setOpen={setOpenSmsPreview}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      />
    </>
  );
};

export default MessageTemplates;
