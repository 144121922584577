import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { LguModel } from 'carego-admin/model/Entities';
import { updateLgu } from 'carego-admin/api/lgu';
import { update_lgu_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UpdateLguProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lgu: LguModel;
  refreshTable: () => void;
  languages: any;
};

export type UserInput = {
  office: string;
  language_id: string | number;
  region_name: string;
  province_name: string;
  lgu_name: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const UpdateLgu: React.FC<UpdateLguProps> = ({ open, setOpen, lgu, refreshTable, languages }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    office: lgu ? lgu.office : '',
    language_id: lgu ? lgu.language_id : '',
    region_name: lgu ? lgu.region_name : '',
    province_name: lgu ? lgu.province_name : '',
    lgu_name: lgu ? lgu.lgu_name : '',
  };

  const form_fields: UserFieldInput[] = [
    { field_name: 'region_name', display_name: 'Region', type: 'text' },
    { field_name: 'province_name', display_name: 'Province', type: 'text' },
    { field_name: 'lgu_name', display_name: 'LGU Name', type: 'text' },
    {
      field_name: 'office',
      display_name: 'Office',
      type: 'select',
      options: [
        { key: 'CHO', value: 'CHO' },
        { key: 'RHU', value: 'RHU' },
      ],
    },
    {
      field_name: 'language_id',
      display_name: 'Language',
      type: 'select',
      options: languages?.map((u: any) => {
        return { key: u.language, value: u.id };
      }),
    },
  ];

  const update = (data: any) => {
    setLoading(true);

    updateLgu(lgu.lgu_code, data)
      .then((res) => {
        enqueueSnackbar('LGU successfully updated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    lgu && (
      <CustomModal header={'Update ' + lgu.lgu_name} open={open} setOpen={setOpen}>
        <CustomForm
          onSubmit={update}
          fields={form_fields}
          schema={update_lgu_schema}
          initialValues={initialValues}
          loading={loading}
        />
      </CustomModal>
    )
  );
};

export default UpdateLgu;
