import * as yup from 'yup';

import { createHMO, updateHMO } from 'company/api/company-hmos';

import { EntityFields } from 'company/entities/utils';
import TemplateForm from 'company/entities/Template/TemplateForm';

export const HMO_ENTITY_NAME = 'HMO';

const updateHMOSchema = yup.object().shape({
  hmo_name: yup.string().required('Company Name is required').max(50, 'Must not exceed 50 characters'),
});

const hmoFields: EntityFields[] = [{ fieldName: 'hmo_name', displayName: 'HMO Name' }];

interface hmoInput {
  hmo_name: string;
}

const HmoInitialValues: hmoInput = {
  hmo_name: '',
};

export const HMOFormFields: string[] = ['hmo_name'];

type HMOProps = {
  callbackAfterSubmit?: (data?: any) => void;
  hmo?: any;
};

export function HmoForm(props: HMOProps) {
  const { callbackAfterSubmit, hmo } = props;

  const handleSubmit = (data: any) => {
    return hmo ? updateHMO(hmo.id, data) : createHMO(data);
  };

  return (
    <TemplateForm
      entityName={HMO_ENTITY_NAME}
      hideDefaultSnackbar
      fields={hmoFields}
      visibleFields={HMOFormFields}
      initialValues={{ ...HmoInitialValues, ...hmo }}
      schema={updateHMOSchema}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
}
