import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'sms-template-local';

export const getAllLocal = (query: ApiQuery, template_id: number) => {
  return axios.get(`${baseURL}/${path}/many/${template_id}`, { params: query });
};

export const createLocal = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateLocal = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

export const deleteLocal = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
