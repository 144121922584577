import { Typography } from '@mui/material';

export enum TOUR_ID {
  PATIENT_ADD_STEPS,
  SERVICE_ADD_STEPS,
  CORPORATE_CLIENT_STEPS,
}

export const SERVICE_ADD_STEPS = [
  {
    content: <h2>How to Add Clinic Services?</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Clinic Management',
    target: '#sidebar-clinic-management',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>In sidebar menu, click the "Clinic Management" tab</Typography>
      </>
    ),
  },
  {
    title: 'Services',
    target: '#services-tab',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>
          Click "Services" <br /> located in the upper part of the tabs
        </Typography>
      </>
    ),
  },
  {
    title: 'Add Service',
    target: '#Service_add_button',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>Click the "Add Service" button</Typography>
      </>
    ),
  },
  {
    title: 'Fill Service Form',
    target: '#Service_modal',
    disableOverlayClose: false,
    spotlightClicks: true,
    placement: 'center',
    content: (
      <>
        <Typography>
          Complete the form by providing all the required informations then click the "Submit" button
        </Typography>
      </>
    ),
  },
];

export const PATIENT_ADD_STEPS = [
  {
    content: <h2>How to Register Patients?</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Patient Management',
    target: '#sidebar-client-registration',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>In sidebar menu, click the "Patient Management" tab</Typography>
      </>
    ),
  },
  {
    title: 'Add Patient',
    target: '#Patient_add_button',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>Click the "Add Patient" button</Typography>
      </>
    ),
  },
  {
    title: 'Fill Patient Form',
    target: '#Patient_modal',
    disableOverlayClose: false,
    spotlightClicks: true,
    placement: 'center',
    content: (
      <>
        <Typography>
          Complete the form by providing all the required informations then click the "Submit" button
        </Typography>
      </>
    ),
  },
];

export const CORPORATE_CLIENT_STEPS = [
  {
    content: <h2>How to Add Corporate Clients?</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Corporate Client',
    target: '#sidebar-client-corporate',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>In sidebar menu, click the "Corporate Client" tab</Typography>
      </>
    ),
  },
  {
    title: 'Add Corporate Client',
    target: '#CorporateClient_add_button',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <>
        <Typography>Click the "Add Corporate Client" button</Typography>
      </>
    ),
  },
  {
    title: 'Fill Corporate Client Form',
    target: '#CorporateClient_modal',
    disableOverlayClose: false,
    spotlightClicks: true,
    placement: 'center',
    content: (
      <>
        <Typography>
          Complete the form by providing all the required informations then click the "Submit" button
        </Typography>
      </>
    ),
  },
];
