import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from 'material-ui';
import RegularButton from './RegularButton';
import { useMediaQuery } from '@mui/material';

type ExportButtonProps = {
  onClick: () => void;
  loading?: boolean;
  label?: string;
};

const ExportButton: React.FC<ExportButtonProps> = ({ onClick, loading, label }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  if (isMobile) {
    return (
      <RegularButton
        label=""
        onClick={onClick}
        loading={loading}
        startIcon={<DownloadIcon style={{ marginRight: '-12px' }} />}
        variant="outlined"
        styles={{ minWidth: '40px' }}
      />
    );
  }

  return (
    <RegularButton
      label={label ?? 'Export'}
      onClick={onClick}
      loading={loading}
      startIcon={<DownloadIcon />}
      variant="outlined"
    />
  );
};

export default ExportButton;
