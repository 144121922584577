/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomForm, CustomSelectField } from 'core/components';
import { updateScreeningDetails, updateScreeningResult } from 'company/api/tb-acf';

import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import InputContainer from '../common/InputContainer';
import { TbAcfCompanyPatientModel } from 'company/model/Entities';
import { screening_result_options } from 'lgu/data/select-options';
import { tokens } from 'theme/theme';
import { update_screening_schedule_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

type ScreeningProps = {
  patient: TbAcfCompanyPatientModel;
  result: string;
  refreshTable: () => void;
  setNextStage: (step: number) => void;
  setResult: (result: string) => void;
  setPatientData: (patient: any) => void;
  setStatus: (status: string) => void;
};

type UserInput = {
  screening_test_schedule: string;
  screening_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  screening_test_schedule: '',
  screening_test_venue: '',
};

const Screening: React.FC<ScreeningProps> = ({
  patient,
  refreshTable,
  setNextStage,
  setResult,
  result,
  setPatientData,
  setStatus,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const savedValues: UserInput = {
    screening_test_schedule: patient.screening_test_schedule,
    screening_test_venue: patient.screening_test_venue,
  };

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [displayResult, setDisplayResult] = useState<boolean>(false);
  const [isNegative, setIsNegative] = useState<boolean>(patient.screening_result === 'Negative' ? true : false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(
    patient.screening_test_schedule && patient.screening_test_venue ? false : true
  );

  const screeningFormFields: UserFieldInput[] = [
    {
      field_name: 'screening_test_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
    {
      field_name: 'screening_test_venue',
      display_name: 'Venue',
      type: 'text',
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
  ];

  const screening_options = screening_result_options.filter(function (option) {
    return option.value !== 'Negative';
  });

  const skipSchedule = () => {
    setDisplayResult(true);
  };

  const handleCheckboxChange = (negative: boolean) => {
    setIsNegative(negative);

    if (negative) {
      setResult('Negative');
      const patientList = JSON.stringify([patient.id]);
      const patientScreeningResult = {
        patients: patientList,
        screening_result: 'Negative',
      };
      updateScreeningResult(facility.id, patientScreeningResult)
        .then(() => {
          enqueueSnackbar('Screening Result successfully saved!', { variant: 'success' });
          refreshTable();
          setStatus('Negative');
        })
        .catch((error) => console.error(error));
    }
  };

  const updateScreening = (data: any) => {
    setButtonLoading(true);

    const patientList = JSON.stringify([patient.id]);
    const patientData = {
      patients: patientList,
      screening_test_schedule: data.screening_test_schedule,
      screening_test_venue: data.screening_test_venue,
    };

    updateScreeningDetails(facility.id, patientData)
      .then((res) => {
        setButtonLoading(false);
        enqueueSnackbar('Screening Details successfully saved!', { variant: 'success' });
        refreshTable();
        setDisplayResult(true);
        setPatientData((prev: any) => {
          return {
            ...prev,
            screening_test_schedule: data.screening_test_schedule,
            screening_test_venue: data.screening_test_venue,
          };
        });
      })
      .catch((error) => console.error(error));
  };

  const handleSelectResult = (event: ChangeEvent<HTMLInputElement>) => {
    const result = event.target.value;
    setResult(result);
    if (result === 'Positive') {
      setNextStage(3);
      setStatus(result);
    } else {
      const nextStage = result.split('-').pop();
      if (nextStage === 'Checkup') setNextStage(2);
      if (nextStage === 'Sputum') setNextStage(1);
      setStatus('Presumptive');
    }
  };

  useEffect(() => {
    if ((patient.screening_test_schedule && patient.screening_test_venue) || patient.screening_result) {
      setDisplayResult(true);
    } else {
      setDisplayResult(false);
    }
  }, []);

  useEffect(() => {
    setStatus(patient.patient_status);
  }, [patient]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px" marginY="20px">
        <Typography variant="h5" color={colors.text} fontWeight="bold">
          Screening Schedule and Venue
        </Typography>
        {!displayResult && (
          <Typography
            onClick={skipSchedule}
            sx={{
              color: colors.accent,
              '&:hover': {
                cursor: 'pointer',
                fontWeight: '900',
              },
            }}
          >
            Skip {'>>'}
          </Typography>
        )}
      </Box>
      <InputContainer>
        <CustomForm
          onSubmit={updateScreening}
          schema={update_screening_schedule_schema}
          initialValues={savedValues ? savedValues : initialValues}
          loading={buttonLoading}
          fields={screeningFormFields}
          submitButtonText={'Save and Send SMS'}
          buttonWidth={'150px'}
          submitButtonMarginTop={2}
          disabled={disableSubmit}
        />
      </InputContainer>

      {displayResult && (
        <>
          <Divider sx={{ margin: '20px 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px">
            <Typography variant="h5" color={colors.text} fontWeight="bold">
              Screening Result
            </Typography>
          </Box>

          <InputContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '30px',
              }}
            >
              <Box width="calc(50% - 20px)">
                <CustomSelectField
                  label={'Result:'}
                  handleChange={handleSelectResult}
                  fieldName={'Result:'}
                  disabled={isNegative}
                  value={result}
                  options={screening_options.map((u) => {
                    return { key: u.key, value: u.value };
                  })}
                />
              </Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleCheckboxChange(!isNegative)}
                      checked={isNegative}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="If the result is negative, check the box."
                />
              </FormGroup>
            </Box>

            {isNegative && (
              <Box
                sx={{
                  padding: '20px',
                  border: '1px solid gray',
                  borderRadius: '10px',
                  marginTop: '20px',
                }}
              >
                <Typography>
                  Note: By checking <b> Negative </b> as the result, the patient will not be able to proceed and will
                  receive an SMS regarding the result.
                </Typography>
              </Box>
            )}
          </InputContainer>
        </>
      )}
    </>
  );
};

export default Screening;
