/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme } from '@mui/material';
import { CustomModal, RegularButton } from 'core/components';
import { unenrollToTbDots, updateScreeningResult, updateSputumResult, updateStage } from 'lgu/api/tb-acf';
import { useEffect, useState } from 'react';

import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import DoctorsAppointment from './DoctorsAppointment';
import Screening from './Screening';
import SputumTest from './SputumTest';
import { TbAcfPatientModel } from 'lgu/model/Entities';
import TbDotsEnrollment from './TbDotsEnrollment';
import { acf_steps } from '../../data/list';
import { formatDateTime } from 'core/utils';
import { tb_acf_stages } from 'lgu/data/select-options';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type TbUpdateModalProps = {
  patient: TbAcfPatientModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
};

const UpdatePatientModal: React.FC<TbUpdateModalProps> = ({ patient, open, setOpen, refreshTable }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [currentStage, setCurrentStage] = useState<number>(0);
  const [nextStage, setNextStage] = useState<number>(0);
  const [screeningResult, setScreeningResult] = useState<string>('');
  const [sputumResult, setSputumResult] = useState<string>('');
  const [checkUpResult, setCheckUpResult] = useState<string>('');
  const [tbDotsResult, setTbDotsResult] = useState<number>(patient.enrolled_tb_dots);
  const [currentContent, setCurrentContent] = useState<any>();

  const [patientData, setPatientData] = useState<TbAcfPatientModel>(patient);
  const [status, setStatus] = useState<string>('');

  const [nextButtonLoading, setNextButtonLoading] = useState<boolean>(false);
  const [backButtonLoading, setBackButtonLoading] = useState<boolean>(false);

  const handleBack = async () => {
    setBackButtonLoading(true);

    if (currentStage === 3 && tbDotsResult) {
      handlePatientUnenroll();
    } else {
      updatePatientStage(currentStage - 1);
    }
  };

  const handleNext = async () => {
    setNextButtonLoading(true);
    const patientList = JSON.stringify([patient.id]);
    if (currentStage === 0) {
      await updateScreeningResult({
        patients: patientList,
        screening_result: screeningResult.split('-').shift()!,
      });
    } else if (currentStage === 1) {
      const sputum = sputumResult.split('-');
      if (sputum[1] !== 'Checkup') {
        await updateSputumResult({
          patients: patientList,
          sputum_test_result: sputumResult.split('-').shift()!,
        });
      }
    }

    updatePatientStage(nextStage);
  };

  const isNextButtonDisabled = () => {
    if (currentStage === 0) {
      if (screeningResult !== 'Negative') {
        return false;
      }
      return screeningResult === 'Negative' || screeningResult === '';
    } else if (currentStage === 1) {
      return sputumResult === 'Negative' || sputumResult === 'Rifampicin-resistant' || sputumResult === '';
    } else if (currentStage === 2) {
      return checkUpResult === 'Negative' || checkUpResult === '';
    }

    return true;
  };

  const updatePatientStage = (stage: number) => {
    const patientList = JSON.stringify([patient.id]);
    const patientData = {
      patients: patientList,
      stage: tb_acf_stages[stage],
    };

    updateStage(patientData)
      .then(() => {
        enqueueSnackbar('Result successfully saved!', { variant: 'success' });
        refreshTable();
        setCurrentStage(stage);
        renderStageForms(stage);
        setNextButtonLoading(false);
        setBackButtonLoading(false);

        if (stage === 0) {
          setStatus('');
          setPatientData((prev: any) => {
            return {
              ...prev,
              sputum_test_schedule: null,
              sputum_test_venue: null,
              check_up_schedule: null,
              check_up_venue: null,
            };
          });
        } else if (stage === 1) {
          setPatientData((prev: any) => {
            return {
              ...prev,
              check_up_schedule: null,
              check_up_venue: null,
            };
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify([patient.id]);
    const data = {
      patients: patientList,
    };

    unenrollToTbDots(data)
      .then((res) => {
        enqueueSnackbar(`Patient successfully unenrolled!`, { variant: 'success' });

        refreshTable();
        setBackButtonLoading(false);
        setTbDotsResult(0);
      })
      .catch((error) => console.error(error));
  };

  const renderStageForms = (stage: number) => {
    if (stage === 0) {
      setCurrentContent(
        <Screening
          patient={patient}
          result={screeningResult}
          refreshTable={refreshTable}
          setNextStage={setNextStage}
          setResult={setScreeningResult}
          setPatientData={setPatientData}
          setStatus={setStatus}
        />
      );
    } else if (stage === 1) {
      setCurrentContent(
        <SputumTest
          patient={patient}
          result={sputumResult}
          refreshTable={refreshTable}
          setNextStage={setNextStage}
          setResult={setSputumResult}
          setPatientData={setPatientData}
          setStatus={setStatus}
        />
      );
    } else if (stage === 2) {
      setCurrentContent(
        <DoctorsAppointment
          patient={patient}
          result={checkUpResult}
          refreshTable={refreshTable}
          setNextStage={setNextStage}
          setResult={setCheckUpResult}
          setPatientData={setPatientData}
          setStatus={setStatus}
        />
      );
    } else if (stage === 3) {
      setCurrentContent(
        <TbDotsEnrollment
          patient={patient}
          refreshTable={refreshTable}
          result={tbDotsResult}
          setResult={setTbDotsResult}
          setStatus={setStatus}
        />
      );
    }
  };

  useEffect(() => {
    if (patient.enrolled_tb_dots) {
      setStatus('Enrolled');
    } else {
      setStatus(patient.patient_status);
    }

    switch (patient.stage) {
      case tb_acf_stages[0]:
        setCurrentStage(0);
        renderStageForms(0);
        break;

      case tb_acf_stages[1]:
        setCurrentStage(1);
        renderStageForms(1);
        break;

      case tb_acf_stages[2]:
        setCurrentStage(2);
        renderStageForms(2);
        break;

      case tb_acf_stages[3]:
        setCurrentStage(3);
        renderStageForms(3);
        break;
    }
  }, [patient]);

  return (
    <CustomModal header={'Update ' + patient.full_name} open={open} setOpen={setOpen} width={900}>
      <Box
        sx={{
          backgroundColor: colors.secondary_background,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
        paddingY="20px"
      >
        {(status || patientData.screening_test_schedule) && (
          <Box
            width="90%"
            margin="0 auto"
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            columnGap="10px"
            sx={{
              backgroundColor: colors.hoverBackground,
              padding: '20px',
              borderRadius: 2,
            }}
          >
            {status && (
              <>
                <Typography variant="h6">Status:</Typography>
                <Typography variant="h6" gridColumn={2} fontWeight="bold">
                  {status}
                </Typography>
                <Box></Box>
                <Box></Box>
              </>
            )}

            {patientData.screening_test_schedule && (
              <>
                <Typography variant="h6">Screening Test Schedule:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {formatDateTime(patientData.screening_test_schedule)}
                </Typography>
                <Typography variant="h6">Screening Test Venue:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {patientData.screening_test_venue}
                </Typography>
              </>
            )}
            {patientData.sputum_test_schedule && (
              <>
                <Typography variant="h6">Sputum Test Schedule:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {formatDateTime(patientData.sputum_test_schedule)}
                </Typography>
                <Typography variant="h6">Sputum Test Venue:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {patientData.sputum_test_venue}
                </Typography>
              </>
            )}
            {patientData.check_up_schedule && (
              <>
                <Typography variant="h6">Check-up Schedule:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {formatDateTime(patientData.check_up_schedule)}
                </Typography>
                <Typography variant="h6">Check-up Schedule:</Typography>
                <Typography variant="h6" fontWeight="bold">
                  {patientData.check_up_venue}
                </Typography>
              </>
            )}
          </Box>
        )}

        <Box m="30px auto 0" width="80%" alignSelf="center">
          <CustomStepperNonLinear steps={acf_steps} activeStep={currentStage} setActiveStep={setCurrentStage} strict />
        </Box>
      </Box>

      {currentContent}

      <Box
        sx={{
          backgroundColor: colors.secondary_background,
          marginTop: '35px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <Box width="80%" margin="0 auto" paddingY="20px">
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {currentStage >= 1 && (
              <RegularButton
                disabled={currentStage === 0}
                loading={backButtonLoading}
                onClick={handleBack}
                styles={{
                  mr: 1,
                  width: '33px',
                  display: tbDotsResult === 1 || patient.enrolled_tb_dots ? 'none' : 'flex',
                }}
                label={'Back'}
              />
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {currentStage <= tb_acf_stages.length && (
              <RegularButton
                onClick={handleNext}
                disabled={isNextButtonDisabled()}
                loading={nextButtonLoading}
                styles={{
                  display: currentStage === 3 ? 'none' : 'flex',
                  width: '125px',
                }}
                label="Save then Proceed"
              />
            )}
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default UpdatePatientModal;
