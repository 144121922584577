import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { updateCheckupDetails } from 'lgu/api/tb-acf';
import { update_checkup_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type CheckupDetailsBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  check_up_schedule: string;
  check_up_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  check_up_schedule: '',
  check_up_venue: '',
};

const CheckupDetailsBatch: React.FC<CheckupDetailsBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const checkupFormFields: UserFieldInput[] = [
    {
      field_name: 'check_up_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
    },
    { field_name: 'check_up_venue', display_name: 'Venue', type: 'text' },
  ];

  const updateCheckup = (data: any) => {
    setButtonLoading(true);

    const patientData = {
      patients: patients,
      check_up_schedule: data.check_up_schedule,
      check_up_venue: data.check_up_venue,
    };

    updateCheckupDetails(patientData)
      .then((res) => {
        setButtonLoading(false);
        enqueueSnackbar('Check-up Details successfully saved!', { variant: 'success' });
        openModal(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateCheckup}
          schema={update_checkup_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={checkupFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default CheckupDetailsBatch;
