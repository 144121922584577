//NEW
import { Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from '../containers';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Box } from '@mui/system';
import CustomFilter from '../CustomFilter';
import CustomIconButton from '../buttons/IconButton';
import CustomModal from '../modals/CustomModal';
import DownloadIcon from '@mui/icons-material/Download';
import { DropdownOptionProps } from '../Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RegularButton from '../buttons/RegularButton';
import RowAction from '../RowAction';
import { SIZES } from 'theme/constants';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import { tokens } from 'theme/theme';
import { useSearchParams } from 'react-router-dom';

type Props = {
  title: string;
  titleComponents?: ReactNode;
  headerComponents?: ReactNode;
  underSearchComponents?: ReactNode;
  selected: any;
  setSelected: (selected: any) => void;
  addedNewItem?: number;
  updatedItem?: any;
  deleteId?: number;
  children?: ReactNode | ReactNode[];
  getData: (query: ApiQuery) => Promise<any>;
  getSingleData?: (id: number) => Promise<any>;
  itemIdName?: string;
  renderItem: (item: any) => ReactNode | ReactNode[];
  additionalItems?: () => ReactNode | ReactNode[];
  urlKeyId: string;
  filter?: any;
  onChange?: (selected?: any) => void;
  containerHeight?: string;
  displaySelectedTitle?: () => ReactNode;
  actions?: DropdownOptionProps[];
  hideActions?: boolean;
  hideContainer?: boolean;
  openActions?: boolean;
  setOpenActions?: (open: boolean) => void;
  exportData?: (facility_id: number, clinic_name: string, query: ApiQuery) => Promise<any>;
};

const InfiniteScrollContainer: React.FC<Props> = ({
  title,
  titleComponents,
  headerComponents,
  underSearchComponents,
  addedNewItem,
  updatedItem,
  deleteId,
  selected,
  setSelected,
  children,
  getSingleData,
  getData,
  itemIdName = 'id',
  renderItem,
  additionalItems,
  urlKeyId,
  filter,
  onChange,
  containerHeight = 'calc(100vh - 113px)',
  displaySelectedTitle,
  actions,
  hideActions,
  hideContainer,
  openActions,
  setOpenActions,
  exportData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlKey = searchParams.get(urlKeyId);
  const [value, setValue] = useState<number>(urlKey ? parseInt(urlKey) : selected);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [items, setItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 10;
  const parentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [hideContent, setHideContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [itemModalOpen, setItemModalOpen] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const savedCollapse = localStorage.getItem('sidebarCollapsed');
    return savedCollapse ? JSON.parse(savedCollapse) : false;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (!firstLoad) {
      setValue(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (!firstLoad && value) {
      setSearchParams((searchParams) => {
        searchParams.set(urlKeyId, value.toString());
        return searchParams;
      });
    }
    setFirstLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, addedNewItem]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, facility?.id, hasMore, searchQuery, totalCount]);

  useEffect(() => {
    if (isMobilePhone && value) setItemModalOpen(true);
  }, [isMobilePhone, value]);

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  useEffect(() => {
    if (deleteId) {
      // for getting the next id
      const prevIndex = items.findIndex((item) => item[itemIdName] === deleteId);
      if (prevIndex >= 0) {
        // maunal checking
        if (items[prevIndex + 1]) {
          setSelected(items[prevIndex + 1][itemIdName]);
          setValue(items[prevIndex + 1][itemIdName]);
        } else if (items[prevIndex - 1]) {
          setSelected(items[prevIndex - 1][itemIdName]);
          setValue(items[prevIndex - 1][itemIdName]);
        } else {
          setSelected(undefined);
          setValue(0);
        }

        setItems((prev) =>
          prev.filter((item, index) => {
            return item[itemIdName] !== deleteId;
          })
        );
        setTotalCount((prev) => prev - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  useEffect(() => {
    addedNewItem &&
      getSingleData &&
      getSingleData(addedNewItem).then((response) => {
        setItems([response.data, ...items]);
        setSelected(response.data[itemIdName]);
        setValue(response.data[itemIdName]);
        setTotalCount((prev) => prev + 1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedNewItem]);

  useEffect(() => {
    if (updatedItem) {
      const newItems = items.map((item) => (item[itemIdName] === selected ? updatedItem : item));
      setItems(newItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedItem]);

  const fetchData = async () => {
    if (hasMore && facility) {
      setIsLoading(true);
      try {
        const response = await getData({ page, length: itemsPerPage, search: searchQuery });
        const newItems: any[] = response.data.data;

        const total = response.data.meta.total;
        setTotalCount(total);
        if (!isMobilePhone && !selected && newItems.length) {
          value ? setSelected(value) : setSelected(newItems[0][itemIdName]);
        }

        if (page === 1) {
          setItems(newItems);
        } else {
          setItems((prevItems) => {
            const existing = prevItems.map((item) => item[itemIdName]);
            const toBeAdded = newItems.filter((item) => !existing.includes(item[itemIdName]));
            return [...prevItems, ...toBeAdded];
          });
        }

        setHasMore(newItems.length === itemsPerPage);
        const parentElement = parentRef.current as any;
        if (parentElement && parentElement.scrollHeight === parentElement.clientHeight && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  const handleScroll = () => {
    const parentElement = parentRef.current as any;
    if (parentElement && parentElement.scrollHeight - parentElement.scrollTop - parentElement.clientHeight < 10) {
      setTimeout(() => {
        if (hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 1000);
    }
  };

  const handleSearch = (searchKey: string) => {
    setItems([]);
    setHasMore(true);
    setPage(1);
    setSearchQuery(searchKey);
  };

  useEffect(() => {
    if (isCollapsed) {
      setHideContent(true);
    } else {
      setTimeout(() => {
        setHideContent(false);
      }, 300);
    }
  }, [isCollapsed]);

  const handleExport = async () => {
    if (facility && exportData) {
      try {
        await exportData(facility.id, facility.facility_name, {
          page: 1,
          length: totalCount,
          search: searchQuery,
        });
      } catch (error) {
        console.error('Export failed:', error);
      }
    }
  };

  return (
    <Box display="flex" gap={isCollapsed ? '10px' : '20px'} alignItems="flex-start">
      {isCollapsed && !isMobilePhone && (
        <CustomIconButton
          color="primary"
          sx={{ marginRight: '-32px', zIndex: 12, marginTop: '20px' }}
          iconSize="30px"
          icon={
            <ArrowCircleLeftOutlinedIcon
              sx={{
                transition: 'transform 0.5s',
                transform: 'rotate(180deg)',
              }}
            />
          }
          onClick={() => setIsCollapsed((prev) => !prev)}
        />
      )}
      <Box
        ref={parentRef}
        sx={{
          transition: 'width 0.5s',
          width: isCollapsed ? '3px' : isMobilePhone ? '100%' : '350px',
          maxHeight: containerHeight,
          overflowY: isCollapsed ? 'hidden' : 'auto',
          borderRight: isCollapsed ? '3px solid ' + colors.light_blue_background_2 : undefined,
        }}
        onScroll={handleScroll}
      >
        <ContainerColumn
          gap="5px"
          sx={{
            backgroundColor: isMobilePhone ? undefined : colors.light_blue_background,
            borderRadius: SIZES.borderRadiusS,
            display: 'flex',
            position: 'relative',
            minHeight: 'calc(100vh - 240px)',
          }}
        >
          <Box sx={{ visibility: hideContent ? 'hidden' : 'visible' }}>
            <ContainerColumn
              gap={SIZES.paddingS}
              sx={{
                paddingInline: SIZES.padding,
                position: isMobilePhone ? undefined : 'sticky',
                top: 0,
                zIndex: 4,
                backgroundColor: isMobilePhone ? undefined : colors.light_blue_background,
                padding: SIZES.padding,
                borderRadius: SIZES.borderRadiusS,
              }}
            >
              <ContainerRow sx={{ alignItems: 'flex-start' }}>
                <Typography variant="h4" fontWeight="bold" width="100%">
                  {title}
                </Typography>
                {!isMobilePhone && (
                  <Box display="flex" marginRight="-20px">
                    {titleComponents}
                    <CustomIconButton
                      color="primary"
                      icon={
                        <ArrowCircleLeftOutlinedIcon
                          sx={{
                            fontSize: '25px',
                            transition: 'transform 0.5s',
                            transform: isCollapsed ? 'rotate(180deg)' : undefined,
                          }}
                        />
                      }
                      onClick={() => setIsCollapsed((prev) => !prev)}
                    />
                  </Box>
                )}
              </ContainerRow>
              <Box display="flex" flexDirection={isCollapsed ? 'column' : 'row'} gap="10px">
                {headerComponents}
              </Box>
              <ContainerRow gap="5px" sx={{ alignItems: 'stretch' }}>
                <SearchBar
                  handleSearch={(searchKey) => handleSearch(searchKey)}
                  backgroundColor={isMobilePhone ? undefined : colors.background}
                  fullWidth={isMobilePhone}
                />
                {filter && <CustomFilter optionList={[]} />}
              </ContainerRow>
            </ContainerColumn>

            <Box sx={{ paddingInline: SIZES.padding, width: '100%' }}>
              <ContainerColumn gap="6px">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h5" fontWeight="600" width="100%">
                    Total: {totalCount}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" gap={SIZES.paddingS}>
                    {underSearchComponents}

                    {exportData && (
                      <RegularButton
                        startIcon={<DownloadIcon />}
                        label="Export"
                        variant="outlined"
                        size="small"
                        onClick={handleExport}
                        // styles={{ paddingInline: SIZES.paddingS, fontSize: '11px', height: '100%' }}
                      />
                    )}
                  </Box>
                </Box>

                {additionalItems && additionalItems()}

                {items.map((item, index) => (
                  <Button
                    variant="text"
                    key={item[itemIdName]}
                    color={'primary'}
                    sx={{
                      textAlign: 'left',
                      backgroundColor:
                        selected === item[itemIdName] && !isMobilePhone ? colors.primary : colors.background,
                      borderRadius: isMobilePhone ? '5px' : '10px',
                      borderBottom: isMobilePhone ? `2px solid ${colors.input_background}` : undefined,
                      padding: '15px',
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                      textTransform: 'none',
                      color: '#FFFFFF',
                      '&.Mui-active': {
                        color: 'white !important',
                      },
                      '&:active': {
                        color: 'white !important',
                      },
                      '&:hover': {
                        backgroundColor:
                          selected === item[itemIdName] && !isMobilePhone
                            ? colors.primary_hovered
                            : 'rgba(0, 146, 213, 0.1)',
                      },
                    }}
                    onClick={() => {
                      setSelected(item[itemIdName]);
                      setValue(item[itemIdName]);
                      onChange && onChange(item);
                    }}
                  >
                    {renderItem(item)}
                  </Button>
                ))}
              </ContainerColumn>
              <Box height="50px">
                {isLoading ? (
                  <Box textAlign="center" padding="10px">
                    <CircularProgress color="inherit" size="1.3rem" />
                  </Box>
                ) : (
                  <>
                    {!hasMore && items.length > itemsPerPage && (
                      <Typography textAlign="center" padding="10px">
                        No more data
                      </Typography>
                    )}
                    {!items.length && (
                      <Typography textAlign="center" padding="10px">
                        No data
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </ContainerColumn>
      </Box>
      {!hideContainer && !isMobilePhone && (
        <ContainerColumn
          className="hide-scrollbar"
          sx={{ maxHeight: containerHeight, overflowY: 'auto', flex: 1, position: 'relative' }}
        >
          {displaySelectedTitle && (
            <Box pb="5px" sx={{ position: 'sticky', top: 0, zIndex: 11, backgroundColor: colors.background }}>
              <ContainerRow
                sx={{
                  backgroundColor: colors.light_blue_background,
                  padding: '15px 20px',
                  borderRadius: '15px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {displaySelectedTitle()}

                {!hideActions && actions ? <RowAction actions={actions} data={selected} iconFontSize="30px" /> : ''}
              </ContainerRow>
            </Box>
          )}
          <Box padding="0 20px" mt="-20px">
            {children}
          </Box>
        </ContainerColumn>
      )}
      <CustomModal
        open={itemModalOpen}
        setOpen={setItemModalOpen}
        header={displaySelectedTitle ? displaySelectedTitle() : undefined}
        onClose={() => {
          setSelected(0);
          setValue(0);
          searchParams.delete(urlKeyId);
          setSearchParams(searchParams);
        }}
      >
        {actions ? (
          <Box sx={{ position: 'absolute', top: 60, right: 45, zIndex: 1 }}>
            <IconButton
              onClick={() => {
                if (setOpenActions) {
                  setOpenActions(true);
                }
              }}
            >
              {!isMobilePhone && <MoreHorizIcon style={{ fontSize: '20px', color: colors.primary }} />}
            </IconButton>

            {/* <RowAction actions={actions} data={selected} iconFontSize="20px" /> */}
          </Box>
        ) : (
          ''
        )}

        {children}
      </CustomModal>
    </Box>
  );
};

export default InfiniteScrollContainer;
