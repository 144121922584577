/* eslint-disable react-hooks/exhaustive-deps */

import { CC_ENTITY_NAME, corporateClientFields, corporateClientTableColumns } from './CorporateClientModel';
import { deleteClient, getClients } from 'company/api/corporate-clients';
import { forwardRef, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { CompanyServiceModel } from 'company/model/Entities';
import CorporateClientForm from './CorporateClientForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (row: any) => void;
  tableAction?: TableActionProps;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const CorporateClientTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, tableAction, drawerSelectedRow, tableRef } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const [selectedRow, setSelectedRow] = useState<CompanyServiceModel>();

  const getData = (query: ApiQuery) => {
    return getClients(query);
  };

  const handleDelete = (data: any) => {
    return deleteClient(data);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };
  return (
    <TemplateTable
      ref={mainRef}
      tourTriggerIndex={3}
      entityName={CC_ENTITY_NAME}
      fields={corporateClientFields}
      visibleColumns={visibleColumns ?? corporateClientTableColumns}
      getData={getData}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      templateForm={<CorporateClientForm corporateClient={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      tableAction={{
        ...tableAction,
        rowActionRequiredAuth: ['MANAGE_CORPORATE_CLIENTS'],
        addActionRequiredAuth: ['MANAGE_CORPORATE_CLIENTS'],
      }}
    />
  );
});

export default CorporateClientTable;
