/* eslint-disable no-useless-escape */
import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';
import { Typography } from '@mui/material';

export const ENTITIY_NAME = 'Patient Emergency Contact';

export const schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    }),
    // .required('Mobile Number is required.'),
  relationship: yup.string().required('Relationship is required.'),
  email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter a valid email address'),
});

export interface PatientEmergencyContactModel {
  id: number;
  patient_id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  relationship: string;
  email: string;
  created_at: string;
  updated_at: string;
}

export interface PatientEmergencyContactInput {
  first_name: string;
  last_name: string;
  mobile_number: string;
  relationship: string;
  email: string;
}

export interface PatientEmergencyContactFieldInput extends FieldInput {
  field_name?: keyof PatientEmergencyContactInput;
}

export const contactInitialValues: PatientEmergencyContactInput = {
  first_name: '',
  last_name: '',
  mobile_number: '',
  relationship: '',
  email: '',
};

// fieldName should be unique
export const contactFields: EntityFields[] = [
  { fieldName: 'first_name', displayName: 'First Name' },
  { fieldName: 'last_name', displayName: 'Last Name' },
  { fieldName: 'mobile_number', displayName: 'Mobile Number' },
  { fieldName: 'email', displayName: 'Email', type: 'email', optional: true },
  { fieldName: 'relationship', displayName: 'Relationship' },

  {
    fieldName: 'full_name',
    displayName: 'Name',
    renderCell: (params) => (
      <Typography>
        {params.row.first_name} {params.row.last_name}
      </Typography>
    ),
    flex: 1.5,
  },
];

// Table
export const patientEmergencyContactTableColumns: string[] = ['full_name', 'relationship', 'mobile_number', 'email'];

// Forms
export const patientEmergencyContactFormFields: string[] = [
  'first_name',
  'last_name',
  'mobile_number',
  'email',
  'relationship',
];
