import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'firewall/archive';

export const getArchivedFiles = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const getArchivedDownloadLink = (filename: string) => {
  return `${baseURL}/${path}/download/${filename}`;
};

export const deleteArchivedFile = (filename: string) => {
  return axios.delete(`${baseURL}/${path}/${filename}`);
};

export const firewallArchiveAllLogs = () => {
  return axios.post(`${baseURL}/${path}/all`);
};
