import { Box, Divider, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { DISPLAY_ONLY_FIELDS, FORM_MODE, FieldInput } from 'core/model/interface';
import { Fragment, Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { calculateAge, formatArray, formatDate } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { Components } from '..';
import { ContainerColumn } from 'core/components/containers';
import CustomRichTextEditor from 'core/components/CustomRichTextEditor';
import CustomTableForm from 'core/components/forms/CustomTableForm';
import DocumentRichText from './DocumentRichText';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { HideOrShowComponent } from 'core/components';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { SIZES } from 'theme/constants';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { UserContext } from 'core/context/user.context';
import { getDoctor } from 'company/api/doctors';
import { tokens } from 'theme/theme';

type Props = {
  note: PatientNoteModel;
  components: Components[];
  patient: PatientModel;
  appointment: CompanyAppointmentModel;
  hideEmptyFields?: boolean;
  compact?: boolean;
  formView?: boolean;
};

type FacilityDetailsProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  compact?: boolean;
};

export const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon, compact }) => {
  if (label) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
        <Typography textAlign="center" sx={{ fontSize: compact ? '12px' : '13px' }}>
          <Icon color="primary" sx={{ fontSize: compact ? '16px' : '20px', marginBottom: '-5px' }} /> {label}
        </Typography>
      </Box>
    );
  }
  return <></>;
};

const PrintablePatientNote = forwardRef(
  (
    { note, patient, components, appointment, hideEmptyFields = false, compact, formView }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const span = 12;
    const defaultSpan = 'span ' + span / 3;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);

    const [fields, setFields] = useState<FieldInput[]>([]);
    const [responses, setResponses] = useState<any>({});
    const [provider, setProvider] = useState<any>();

    useEffect(() => {
      if (components[0]) {
        const responses: any = components[0].responses;
        const fields: any[] = components[0].fields ?? [];

        setResponses(responses);
        setFields(fields);
      }
    }, [components]);

    useEffect(() => {
      if (appointment?.provider_id) {
        getDoctor(appointment.provider_id).then((res) => setProvider(res.data));
      }
    }, [appointment]);

    return (
      <Box position="relative">
        {!formView && <style>{styles}</style>}
        <Box ref={ref} className="page-break">
          <Box>
            {!!user && (
              // <ContainerColumn sx={{ position: 'fixed' }}>
              <ContainerColumn sx={{ position: 'relative' }}>
                <Box>
                  <Box alignSelf="flex-start" position="absolute" top="0">
                    <img
                      alt="cphi-logo"
                      width={compact ? '70px' : '80px'}
                      height={compact ? '70px' : '80px'}
                      id="logo"
                      src={process.env.PUBLIC_URL + `${user.company?.company_logo}`}
                    />
                  </Box>
                  <Box
                    width={compact ? '85%' : '75%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={compact ? '1px' : '4px'}
                    margin="0 auto"
                  >
                    <Typography
                      variant={compact ? 'h2' : 'h2'}
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ color: colors.accent }}
                    >
                      {facility.facility_name}
                    </Typography>

                    <Box sx={compact ? { display: 'flex', gap: '10px' } : undefined}>
                      <FacilityDetails
                        Icon={CallOutlinedIcon}
                        label={formatArray([facility?.smart_mobile_number, facility?.globe_mobile_number], ' | ')}
                        compact={compact}
                      />
                      <FacilityDetails Icon={EmailOutlinedIcon} label={facility.email} compact={compact} />
                    </Box>
                    <FacilityDetails
                      Icon={LocationOnOutlinedIcon}
                      label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
                      compact={compact}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: compact ? '0px' : '5px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      m: compact ? '10px 0' : '20px 0',
                    }}
                  >
                    <Typography variant={compact ? 'h4' : 'h3'} sx={{ fontWeight: '600' }}>
                      {note.header}
                    </Typography>
                    <Typography fontSize={compact ? '11px' : '13px'}>{formatDate(note.date_recorded)}</Typography>
                  </Box>
                </Box>
              </ContainerColumn>
            )}
          </Box>
          <Box>
            <Box>
              {!compact && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <PersonPinOutlinedIcon color="primary" />
                  <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                    Patient Information
                  </Typography>
                </Box>
              )}
              <Box
                display="grid"
                gridTemplateColumns="repeat(4, 1fr)"
                gap={SIZES.paddingS}
                mt={compact ? SIZES.paddingS : SIZES.padding}
              >
                <PatientField label="Full Name" value={patient.full_name} />
                <PatientField label="Sex" value={patient.sex} />
                <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                <PatientField label="Age" value={calculateAge(patient.birthday)} />
              </Box>
              <Divider sx={{ marginBlock: SIZES.padding }} />
              <Box
                display="grid"
                gridTemplateColumns={`repeat(${span}, 1fr)`}
                columnGap={compact ? '6px' : '15px'}
                rowGap={compact ? '10px' : '15px'}
                mt={SIZES.padding}
              >
                {fields.map((field, index) => {
                  if (DISPLAY_ONLY_FIELDS.includes(field.type as string)) {
                    if (field.type === 'section_header' || field.type === 'subsection_header')
                      return (
                        <Typography
                          key={index}
                          variant={
                            field.type === 'subsection_header'
                              ? 'h5'
                              : field.type === 'section_header'
                              ? 'h4'
                              : undefined
                          }
                          fontWeight={'bold'}
                          sx={{
                            gridColumn: 'span ' + (field.span ?? span),
                            mb: compact ? '0.5em' : '1em',
                          }}
                        >
                          {field.subsection_header ?? field.section_header ?? field.body_text}
                        </Typography>
                      );

                    if (field.type === 'table') {
                      return (
                        <Box key={index} sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                          <CustomTableForm
                            displayName={field.display_name}
                            columns={field.columns!}
                            formMode={FORM_MODE.VIEW}
                            initialValues={JSON.stringify(responses[field.field_name])}
                            fixedRows
                            compact={compact}
                          />
                        </Box>
                      );
                    }

                    if (field.type === 'body_text') {
                      return (
                        <Box key={index} sx={{ gridColumn: 'span ' + (field.span ?? span) }}>
                          <DocumentRichText
                            isBodyText
                            component={{ id: '', type: 'rich_text', content: field.body_text }}
                            mode={FORM_MODE.VIEW}
                            patient={patient}
                            appointment={appointment}
                            provider={provider}
                          />
                        </Box>
                      );
                    }

                    if (field.type === 'divider') {
                      return (
                        <Box sx={{ gridColumn: 'span ' + (field.span ?? defaultSpan) }}>
                          <Divider />
                        </Box>
                      );
                    }

                    return <Fragment key={index} />;
                  }

                  if (field.type === 'rich_text') {
                    return (
                      <PatientField
                        key={index}
                        label={field.display_name}
                        valueIsComponent
                        value={
                          <CustomRichTextEditor
                            label=""
                            fieldName={field.field_name}
                            value={responses[field.field_name]}
                            readOnly
                          />
                        }
                        sx={{ gridColumn: 'span ' + (field.span ?? span) }}
                      />
                    );
                  }

                  return (
                    <HideOrShowComponent
                      key={index}
                      hidden={hideEmptyFields && !responses[field.field_name] && !formView}
                    >
                      <PatientField
                        label={field.display_name}
                        value={
                          field.type === 'checkbox'
                            ? responses[field.field_name]
                              ? 'Yes'
                              : 'No'
                            : field.type === 'date'
                            ? formatDate(responses[field.field_name])
                            : responses[field.field_name]
                        }
                        sx={{ gridColumn: 'span ' + (field.span ?? defaultSpan) }}
                      />
                    </HideOrShowComponent>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box width="100%" position="fixed" bottom={0}>
          <hr
            style={{
              border: 'none',
              borderTop: '0.5px solid #0192D5',
              width: '100%',
              transform: 'scaleY(0.5)',
            }}
          />

          <Box width="100%">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              <img
                alt="cphi-logo"
                width="35px"
                height="35px"
                id="logo"
                src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
              />

              <Box sx={{ textAlign: 'left', gap: '4px' }}>
                <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                  Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>
    );
  }
);

const styles = `
 .page-break:not(:first-child) {
    page-break-before: always;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;    
  }

`;

export default PrintablePatientNote;
