import { EntityFields } from '../../../utils';
import { Typography } from '@mui/material';
import { formatNumber } from 'core/utils';

export const PATIENT_ENTITY_NAME = 'Patient File';

export interface PatientFileModel {
  id: number;
  company_id: number;
  facility_id: number;
  patient_id: number;
  appointment_id: number;
  file_name: string;
  file_type: string;
  file_size: string;
  file_path: string;
  base_url: string;
}

// fieldName should be unique
export const patientFields: EntityFields[] = [
  {
    fieldName: 'file_name',
    displayName: 'File Name',
    flex: 2,
    generateLink: (data) => `${data.base_url}${data.file_path}`,
  },
  { fieldName: 'file_type', displayName: 'File Type' },
  {
    fieldName: 'file_size',
    displayName: 'File Size',
    renderCell: ({ value }) => {
      return (
        <Typography>
          {value / 1000 >= 1000
            ? `${formatNumber(value / (1000 * 1000), 2)} MB`
            : `${formatNumber(value / 1000, 2)} KB`}
        </Typography>
      );
    },
  },
];

// Table
export const patientFilesTableColumns: string[] = ['file_name', 'file_size'];
