import { Box, Typography } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { useEffect, useState } from 'react';

import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import { BarChart } from '@mui/x-charts/BarChart';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import CustomFilter from 'core/components/CustomFilter';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MasksIcon from '@mui/icons-material/Masks';
import MedicationIcon from '@mui/icons-material/Medication';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import PageHeader from 'core/components/PageHeader';
import { PieChart } from '@mui/x-charts/PieChart';
import { RegularButton } from 'core/components';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';


const MockDashboard: React.FC = () => {
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [genderFilters, setGenderFilters] = useState<string[]>([]);
  const [multipleExportFilters, setmultipleExportFilters] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [bestSelling, setBestSelling] = useState<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   async function getBestSellingProduct() {
  //     try {
  //       const data = await getBestSeller();
  //       setBestSelling(data.data.best_seller);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   getBestSellingProduct();
  // }, []);


  // const quantities = bestSelling?.map((item: { quantity: any }) => item.quantity);
  // const names = bestSelling?.map(
  //   (item: { product_name: string; variant_name: string }) => `${item.product_name} \n (${item.variant_name})`
  // );

  return (
    <>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems={'top'}>
        <PageHeader title=" " />
        <Box
          minWidth="650px"
          display="flex"
          justifyContent="space-between"
          gap={'12px'}
          alignItems={'center'}
          marginBottom={'20px'}
        >
          <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
          <CustomFilter
            filterLabel="Filter by Sex"
            optionList={[
              { label: 'Male', id: 'male' },
              { label: 'Female', id: 'female' },
            ]}
            selected={genderFilters}
            setSelected={setGenderFilters}
          />

          <CustomFilter
            buttonLabel="Export"
            filterLabel="Multiple Export"
            selectAllOption={true}
            customIcon={<FileUploadIcon />}
            optionList={[
              { label: 'Top Diagnosis', id: 'top_diagnosis' },
              { label: 'Top Chief Complaint', id: 'top_chief_complaint' },
              { label: 'Top Medicine Dispensed', id: 'top_medicine_dispensed' },
              { label: 'Top Reasons for ER', id: 'top_reasons_for_er' },
              { label: 'Top Reasons for Sent Home', id: 'top_reasons_for_sent_home' },
              { label: 'Referrals to Specialist', id: 'referrals_to_specialist' },
            ]}
            selected={multipleExportFilters}
            setSelected={setmultipleExportFilters}
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns={'repeat(12, 1fr)'} gap="20px" marginBottom={'20px'}>
        {/* ROW 1 */}
        <CustomDashboardWidget
          count={'1'}
          label="No. of Total Encounters"
          iconBackgroundColor="#6999B3"
          icon={Diversity3OutlinedIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#337497' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of Sent Home"
          iconBackgroundColor="#2496AD"
          icon={NightShelterIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#0085A0' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of Sent to ER"
          iconBackgroundColor="#24A895"
          icon={MasksIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#00A283' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of Specialist Referral"
          iconBackgroundColor="#9EC66A"
          icon={AssistWalkerIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#7CB236' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of Consultation"
          iconBackgroundColor="#C6BC24"
          icon={EditNoteIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#BDB100' }}
        />
        <CustomDashboardWidget
          count={'1'}
          label="No. of Medicine Dispensing"
          icon={MedicationIcon}
          sx={{ gridColumn: 'span 2', backgroundColor: '#0192D5' }}
        />
      </Box>

      <Box display="grid" gridTemplateColumns={'repeat(12, 1fr)'} gap="20px">
    

        <Box
          sx={{
            gridColumn: 'span 6',
            border: '1px solid #CCCCCC',
            borderRadius: '8px',
            minHeight: '400px',
            padding: '12px 24px',
          }}
        >
          <Box>
            <Box
              sx={{
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <Typography variant="h3" fontWeight="700">
                  Top Chief Complaint
                </Typography>
                <CreateOutlinedIcon color="primary" />
              </Box>

              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'Export'}
                />
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'View Table'}
                />
              </Box>
            </Box>

            <BarChart
              grid={{ horizontal: true }}
              series={[{ data: [9, 8, 10, 4, 3, 6, 7] }]}
              height={290}
              borderRadius={10}
              xAxis={[
                {
                  data: ['Tension', 'Cold', 'GERD', 'URTI', 'Dyspepsia', 'Dysmenorrhea', 'Wound'],
                  scaleType: 'band',
                  label: 'Chief Complaints',
                },
              ]}
              yAxis={[
                {
                  label: 'No. of Reported Cases',

                  colorMap: {
                    type: 'ordinal',
                    values: [3, 4, 5, 6, 7, 8, 9],
                    colors: ['#35AD60', '#00A283', '#00959A', '#7CB236', '#337497', '#0192D5', '#BDB100'],
                  },
                },
              ]}
              margin={{ top: 10, bottom: 45, left: 40, right: 10 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: 'span 6',
            border: '1px solid #CCCCCC',
            borderRadius: '8px',
            minHeight: '400px',
            padding: '12px 24px',
          }}
        >
          <Box>
            <Box
              sx={{
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <Typography variant="h3" fontWeight="700">
                  Top Medicine Dispensed
                </Typography>
                <CreateOutlinedIcon color="primary" />
              </Box>

              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'Export'}
                />
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'View Table'}
                />
              </Box>
            </Box>

            <BarChart
              grid={{ horizontal: true }}
              series={[{ data: [7, 8, 10, 3, 5, 6, 9] }]}
              height={290}
              borderRadius={10}
              xAxis={[
                {
                  data: ['Biogesic', 'Bioflu', 'Sinecod', 'Kremil S', 'Diatabs', 'Buscopan', 'Alaxan'],
                  scaleType: 'band',
                  label: 'Medicines',
                },
              ]}
              yAxis={[
                {
                  label: 'No. of Prescriptions',

                  colorMap: {
                    type: 'ordinal',
                    values: [3, 4, 5, 6, 7, 8, 9],
                    colors: ['#35AD60', '#00A283', '#00959A', '#7CB236', '#337497', '#0192D5', '#BDB100'],
                  },
                },
              ]}
              margin={{ top: 10, bottom: 45, left: 40, right: 10 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: 'span 6',
            border: '1px solid #CCCCCC',
            borderRadius: '8px',
            minHeight: '400px',
            padding: '12px 24px',
          }}
        >
          <Box>
            <Box
              sx={{
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <Typography variant="h3" fontWeight="700">
                  Top Reasons for ER
                </Typography>
                <CreateOutlinedIcon color="primary" />
              </Box>

              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'Export'}
                />
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'View Table'}
                />
              </Box>
            </Box>

            <BarChart
              grid={{ horizontal: true }}
              series={[{ data: [7, 8, 10, 3, 10] }]}
              height={290}
              borderRadius={10}
              xAxis={[
                {
                  data: ['Abdominal Pain', 'High Fever', 'Vomiting', 'Chest Pain', 'Shortness of Breath'],
                  scaleType: 'band',
                  label: 'Reasons for ER',
                },
              ]}
              yAxis={[
                {
                  label: 'No. of Reported Cases',

                  colorMap: {
                    type: 'ordinal',
                    values: [3, 4, 5, 6, 7, 8, 9],
                    colors: ['#35AD60', '#00A283', '#00959A', '#7CB236', '#337497', '#0192D5', '#BDB100'],
                  },
                },
              ]}
              margin={{ top: 10, bottom: 45, left: 40, right: 10 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: 'span 6',
            border: '1px solid #CCCCCC',
            borderRadius: '8px',
            minHeight: '400px',
            padding: '12px 24px',
          }}
        >
          <Box>
            <Box
              sx={{
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <Typography variant="h3" fontWeight="700">
                  Top Reasons for Sent Home
                </Typography>
                <CreateOutlinedIcon color="primary" />
              </Box>

              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'Export'}
                />
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'View Table'}
                />
              </Box>
            </Box>

            <BarChart
              grid={{ horizontal: true }}
              series={[{ data: [7, 8, 10, 3, 5, 6, 4, 9] }]}
              height={290}
              borderRadius={10}
              xAxis={[
                {
                  data: [
                    'Cold Sore',
                    'High Fever',
                    'Diarrhea',
                    'Wound Infection',
                    'GERD',
                    'URTI',
                    'Tension',
                    'Hypertension',
                  ],
                  scaleType: 'band',
                  label: 'Reasons for Sent Home',
                },
              ]}
              yAxis={[
                {
                  label: 'No. of Reported Cases',

                  colorMap: {
                    type: 'ordinal',
                    values: [3, 4, 5, 6, 7, 8, 9],
                    colors: ['#35AD60', '#00A283', '#00959A', '#7CB236', '#337497', '#0192D5', '#BDB100', '#E18R71'],
                  },
                },
              ]}
              margin={{ top: 10, bottom: 45, left: 40, right: 10 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: 'span 6',
            border: '1px solid #CCCCCC',
            borderRadius: '8px',
            minHeight: '400px',
            padding: '12px 24px',
          }}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Box
              sx={{
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <Typography variant="h3" fontWeight="700">
                  Top Reasons for Sent Home
                </Typography>
                <CreateOutlinedIcon color="primary" />
              </Box>

              <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'Export'}
                />
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  label={'View Table'}
                />
              </Box>
            </Box>

            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 20, label: 'series A', color: '#FFA600' },
                    { id: 1, value: 50, label: 'series B', color: '#35ad60' },
                    { id: 2, value: 30, label: 'series C', color: '#337497' },
                  ],
                  innerRadius: 30,
                  outerRadius: 100,
                  paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 360,
                  cx: 150,
                  cy: 150,
                },
              ]}
              width={450}
              height={300}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MockDashboard;
