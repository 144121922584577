import { ICD_CODE_ENTITY_NAME, icdCodeFields, icdCodeFormFields, icdCodeFormInitialValues, icdCodeSchema } from './ICDCodeModel';
import { createCode, getCodesNotYetAdded } from 'company/api/icdcode';
import { useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';

type Props = {
  readonly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const ICDCodeForm: React.FC<Props> = (props) => {
  const { readonly, showSubmitAtTop, callbackAfterSubmit } = props;
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const [codeOptions, setCodeOptions] = useState<any[]>([]);

  
  const handleSubmitApi = (data: any) => {
    return createCode(data);
  };

  useEffect(() => {
    if (codeOptions) {
      setDropdownOptions({
        code:
          codeOptions &&
          codeOptions.map((u: any) => {
            return { key: u.code, value: u.code };
          }),
      });
    }
  }, [codeOptions]);

  const fetchCodes = () => {
    getCodesNotYetAdded().then((code) => {
      setCodeOptions(code.data.data);
    });
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  
  return (
    <TemplateForm
      entityName={ICD_CODE_ENTITY_NAME}
      fields={icdCodeFields}
      schema={icdCodeSchema}
      formSubmitApiFunction={handleSubmitApi}
      visibleFields={icdCodeFormFields}
      initialValues={icdCodeFormInitialValues}
      callbackAfterSubmit={callbackAfterSubmit}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readonly}
      dropdownOptions={dropdownOptions}
    />
  );
};

export default ICDCodeForm;
