import { EntityFields } from '../../../utils';

export interface SupplierModel {
  id?: number;
  company_id: number;
  created_by: number;
  supplier_name: string;
  contact_person: string;
  contact_number: string;
  email: string;
  is_active: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface SupplierInput {
  supplier_name: string;
  contact_number: string;
  contact_person: string;
  email: string;
}

export const SUPPLIER_ENTITY_NAME = 'Supplier';
export const supplierEntityFields: EntityFields[] = [
  { displayName: 'Supplier Name', fieldName: 'supplier_name' },
  { displayName: 'Contact Number', fieldName: 'contact_number', optional: true },
  { displayName: 'Email', fieldName: 'email', optional: true },
  { displayName: 'Company', fieldName: 'company_id' },
  { displayName: 'Contact Person', fieldName: 'contact_person' },
];

export const supplierTableColumn = ['supplier_name', 'contact_number', 'email', 'contact_person'];
export const supplierFormInitialValues: SupplierInput = {
  contact_number: '',
  contact_person: '',
  email: '',
  supplier_name: '',
};

export const supplierFormFields = ['supplier_name', 'contact_number', 'email', 'contact_person'];
