import { createContext, useMemo, useState } from 'react';

import { FacilityModel } from 'core/model/Entities';

export const FacilityContext = createContext({
  facility: {} as FacilityModel,
  setFacility: (facility?: FacilityModel) => {},
});

export const useFacility = () => {
  const [facility, setFacility] = useState<FacilityModel | undefined>(undefined);

  const facilityMemo = useMemo(() => ({ facility, setFacility }), [facility]);
  return [facilityMemo, setFacility];
};
