import { USER_GROUP_ENTITY_NAME, UserGroupFields, UserGroupFormFields, UserGroupModel } from './UserGroupModel';
import { caregoDeleteUserGroup, caregoGetUserGroups } from 'carego-admin/api/carego-user-groups';
import { deleteUserGroup, getUserGroups } from 'company/api/user-groups';
import { forwardRef, useContext, useRef, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { UserContext } from 'core/context/user.context';
import UserGroupForm from './UserGroupForm';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const UserGroupTable = forwardRef((props: Props, ref) => {
  const { drawerSelectedRow, visibleColumns, setDrawerSelectedRow, tableRef } = props;
  const { user } = useContext(UserContext);

  const [selectedRow, setSelectedRow] = useState<UserGroupModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <TemplateTable
      ref={mainRef}
      entityName={USER_GROUP_ENTITY_NAME}
      fields={UserGroupFields}
      selectedRow={selectedRow}
      drawerSelectedRow={drawerSelectedRow}
      visibleColumns={visibleColumns ?? UserGroupFormFields}
      getData={user?.account_type === AccountType.CAREGO ? caregoGetUserGroups : getUserGroups}
      handleDelete={user?.account_type === AccountType.CAREGO ? caregoDeleteUserGroup : deleteUserGroup}
      setDrawerSelectedRow={setDrawerSelectedRow}
      setSelectedRow={setSelectedRow}
      templateForm={<UserGroupForm userGroup={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
    />
  );
});

export default UserGroupTable;
