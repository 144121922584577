const mode = process.env.REACT_APP_MODE;

export const isDevMode = () => {
  return mode === 'dev';
};

type HideOrShowComponentProps = {
  children: React.ReactNode;
  hidden: boolean;
};

const HideOrShowComponent: React.FC<HideOrShowComponentProps> = ({ children, hidden }) => {
  return !hidden ? <>{children}</> : <></>;
};

export default HideOrShowComponent;
