import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { CustomTabsProps, TabProps } from './CustomTabs';
import React, { useContext, useEffect, useState } from 'react';

import CustomModal from './modals/CustomModal';
import { ForbiddenScreen } from 'core/screens';
import { SIZES } from 'theme/constants';
import TabPanel from './CustomTabPanels';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useSearchParams } from 'react-router-dom';

const CustomVerticalTabs: React.FC<CustomTabsProps> = ({
  tabs,
  selected = 0,
  setSelected,
  tabBackgroundColor,
  isSubTab,
  tabGroupId = 'tab',
  tabMinWidth = '200px',
  tabStyles,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlTab = searchParams.get(tabGroupId);
  const [value, setValue] = useState<number>(urlTab ? parseInt(urlTab) : selected);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([]);
  const [filteredTabs, setFilteredTabs] = useState<TabProps[]>([]);

  const [openModalTab, setOpenModalTab] = useState<boolean>(false);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    const _tabs: TabProps[] = tabs.filter((tab) => !tab.hidden);
    setFilteredTabs(_tabs);
  }, [tabs]);

  useEffect(() => {
    if (!selectedTabs.includes(value)) {
      setSelectedTabs((prev) => [...prev, value]);
    }
    if (!isMobilePhone) {
      setSearchParams((searchParams) => {
        searchParams.set(tabGroupId, value.toString());
        return searchParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelected && setSelected(newValue);
  };

  useEffect(() => {
    if (urlTab && !isMobilePhone) {
      setValue(parseInt(urlTab));
      setOpenModalTab(true);
    }
  }, [urlTab, isMobilePhone]);

  function handleOpenTab(event: React.SyntheticEvent, newValue: number) {
    setOpenModalTab(true);
    setValue(newValue);
    if (newValue >= 0 && setSelected) {
      setSelected(newValue);
    }
  }

  function handleExistingSelected(index: number) {
    if (selected === index) {
      setValue(index);
      setOpenModalTab(true);
    }
  }

  if (isMobilePhone)
    return (
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleOpenTab}
          sx={{
            width: '100%',
            minWidth: '100%',
            marginBottom: '20px',

            // backgroundColor: isSubTab ? colors.hoverBackground : tabBackgroundColor ?? colors.light_blue_background,
            borderRadius: '8px',
            '& .MuiTabs-flexContainer': {
              gap: '5px',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              gap: '20px',
              textTransform: 'none',
              border: '1px solid ' + colors.border,
              borderRadius: '10px',
              // marginRight: '15px',
            },
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              // borderTopRightRadius: 0,
              // borderBottomRightRadius: 0,
              border: 'none',
              marginRight: 0,
              fontWeight: 'bold',
              backgroundColor: colors.light_blue_background,
            },
            '&.MuiTabs-root.MuiTabs-vertical': {
              marginRight: '0px !important',
            },
            '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
              content: `""`,
              display: 'block',
              position: 'absolute',
              right: '0',
              height: '50%',
              top: '25%',
            },
            '& .MuiTabs-indicator': { display: 'none' },
            ...tabStyles,
          }}
        >
          {filteredTabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                onClick={() => handleExistingSelected(index)}
                icon={
                  tab.icon
                    ? React.cloneElement(tab.icon as React.ReactElement<any>, {
                        style: { fontSize: '22px', color: colors.primary },
                      })
                    : undefined
                }
                label={tab.label}
                iconPosition="start"
                wrapped
                id={tab.id}
                sx={{
                  textAlign: 'left',
                  gap: '20px',
                  minWidth: '100%',
                  padding: 0,
                  '& .MuiButtonBase-root': { minWidth: '100%', padding: 0 },
                }}
              />
            );
          })}
        </Tabs>

        {filteredTabs.map((tab, index) => {
          const open = index === value && openModalTab;
          return (
            <CustomModal
              header={tab.label}
              open={open}
              setOpen={setOpenModalTab}
              key={index}
              onClose={() => {
                setSearchParams((searchParams) => {
                  searchParams.delete(tabGroupId);
                  return searchParams;
                });
              }}
            >
              {!tab.requiredAuth || tab.requiredAuth.filter((r) => user.authorities?.includes(r)).length > 0 ? (
                tab.content
              ) : (
                <ForbiddenScreen hideButton />
              )}
            </CustomModal>
          );
        })}
      </Box>
    );

  return (
    <Box display="flex" width="100%" position="relative">
      <Box width="15%" marginRight="-10px">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            marginBottom: '20px',
            // backgroundColor: isSubTab ? colors.hoverBackground : tabBackgroundColor ?? colors.light_blue_background,
            borderRadius: '8px',
            '& .MuiTabs-flexContainer': {
              gap: '5px',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              minHeight: '40px',
              minWidth: isSubTab ? '180px' : tabMinWidth,
              padding: '15px 20px',
              fontSize: '13px',
              textTransform: 'none',
              justifyContent: 'left',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: SIZES.paddingS,
              border: '1px solid ' + colors.border,
              borderRadius: '10px',
              marginRight: '15px',
            },
            '& .MuiButtonBase-root.MuiTab-root:hover ': {
              color: colors.primary,
            },
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: 'none',
              marginRight: 0,
              fontWeight: 'bold',
              backgroundColor: colors.light_blue_background,
            },
            '&.MuiTabs-root.MuiTabs-vertical': {
              marginRight: '0px !important',
            },
            '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
              content: `""`,
              display: 'block',
              position: 'absolute',
              right: '0',
              height: '50%',
              top: '25%',
            },
            '& .MuiTabs-indicator': { display: 'none' },
            ...tabStyles,
          }}
        >
          {filteredTabs.map((tab, index) => (
            <Tab
              key={index}
              icon={
                tab.icon
                  ? React.cloneElement(tab.icon as React.ReactElement<any>, {
                      style: { fontSize: '18px' },
                    })
                  : undefined
              }
              label={tab.label}
              iconPosition="start"
              wrapped
              id={tab.id}
              sx={{ textAlign: 'left' }}
            />
          ))}
        </Tabs>
      </Box>
      <Box width="calc(85% + 10px)">
        <Box
          sx={{
            position: 'sticky',
            top: '50px',
          }}
        >
          {filteredTabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              {selectedTabs.includes(index) && (
                <Box mt="10px">
                  {!tab.requiredAuth || tab.requiredAuth.filter((r) => user.authorities?.includes(r)).length > 0 ? (
                    tab.content
                  ) : (
                    <ForbiddenScreen hideButton />
                  )}
                </Box>
              )}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomVerticalTabs;
