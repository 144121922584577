import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getManyByCorporateClientId, getOne } from 'company/api/corporate-client-department';
import { useContext, useEffect, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';

interface DepartSearchProps extends TemplateDropdownProps {
  corporate_client_id?: number;
}

const DepartSearchDropdown: React.FC<DepartSearchProps> = (props) => {
  const { facility } = useContext(FacilityContext);
  const [refresh, setRefresh] = useState(0);
  const [corporateClientId, setCorporateClientId] = useState(props.corporate_client_id);

  const handleProcessOption = (option: any) => {
    return { key: option.department_name, value: option.id };
  };

  const handleGetData = () => {
    return getManyByCorporateClientId(corporateClientId as number);
  };

  const handleGetOneData = (id: number) => {
    return getOne(id);
  };

  useEffect(() => {
    setCorporateClientId(props.corporate_client_id);
    setTimeout(() => setRefresh(refresh + 1), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.corporate_client_id]);

  return (
    <WaitForFacility facility={facility && corporateClientId}>
      <TemplateDropdown
        {...props}
        entity="Corporate Client Department"
        getData={handleGetData}
        getById={handleGetOneData}
        processOption={handleProcessOption}
        refresh={refresh}
      />
    </WaitForFacility>
  );
};

export default DepartSearchDropdown;
