import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'expense-line-items';

export const getExpenseLineItems = (facility_id: number, expense_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${expense_id}`, {
    params: query,
  });
};

export const updateExpenseLineItems = (facility_id: number, expense_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${expense_id}`, data);
};



