import { ApiQuery } from 'core/model/interface';
import { Users } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'user-accounts';
export const getUserAccount = (id: number) => {
  return axios.get(`${baseURL}/${path}/user/${id}`);
};

export const getUserAccounts = (company_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${company_id}`, {
    params: query,
  });
};

export const getUserFacilities = (user_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facilities/${user_id}`, { params: query });
};

export const getUsersAssigned = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/assigned/${facility_id}`, {
    params: query,
  });
};

export const getUsersNotAssigned = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-assigned/${facility_id}`, {
    params: query,
  });
};

export const deactivate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updateUserAccount = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const reactivate = (id: number) => {
  return axios.patch(`${baseURL}/${path}/reactivate/${id}`);
};

export const exportUserAccounts = (query: ApiQuery, company_name: string, company_id: number) => {
  return axios
    .post(
      `${baseURL}/${path}/export/${company_id}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', company_name + ' - User Accounts.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const updatePassword = (id: number, password: string) => {
  return axios.patch(`${baseURL}/${path}/update-password/${id}`, password);
};

export const removeUsersFromClinic = (clinic_id: number, users: Users) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, { data: users });
};

export const assignUserToClinic = (clinic_id: number, services: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, services);
};
