import { ApiQuery } from 'core/model/interface';
import { PatientEnrollment } from 'lgu/model/Entities';
import { ProgramPatientModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-program-patients';

export const getPatientsEnrolledInProgram = (facility_id: number, program_code: string, query: ApiQuery, tags?: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${program_code}`,  { params: {...tags, ...query} });
};

export const enrollPatient = (facility_id: number, program_code: string, patient_data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${program_code}`, patient_data);
};

export const updatePatient = (facility_id: number, id: number, patient_data: ProgramPatientModel) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, patient_data);
};

export const unenroll = (facility_id: number, program_code: string, patients: PatientEnrollment) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${program_code}`, {
    params: patients,
  });
};

export const updateSputum = (facility_id: number, id: number, patient: ProgramPatientModel) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`, patient);
};
