import { ApiQuery } from 'core/model/interface';
import { ProgramModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'programs';

export const getProgram = (program_id: number) => {
  return axios.get(`${baseURL}/${path}/${program_id}`);
};

export const getPrograms = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createProgram = (program: ProgramModel) => {
  return axios.post(`${baseURL}/${path}`, program);
};

export const updateProgram = (program_id: number, program: ProgramModel) => {
  return axios.put(`${baseURL}/${path}/${program_id}`, program);
};

export const deleteProgram = (program_id: number) => {
  return axios.delete(`${baseURL}/${path}/${program_id}`);
};
