import AdminAppSidebar from './components/AdminAppSidebar';
import AppLayout from './components/AppLayout';
import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
};

const AdminAppLayout: React.FC<LayoutProps> = ({ children }) => {
  return <AppLayout sidebar={<AdminAppSidebar />}>{children}</AppLayout>;
};

export default AdminAppLayout;
