/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, RegularButton } from 'core/components';
import { getOutreach, updateOutreachStatus } from 'company/api/outreaches';
import { useContext, useEffect, useState } from 'react';

import { ContainerColumn } from 'core/components/containers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LockIcon from '@mui/icons-material/Lock';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { SIZES } from 'theme/constants';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { formatDateTimeWithDay } from 'core/utils';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ConfirmOutreachProps = {
  outreachID: any;
  refreshMainTable: () => void;
  status: string;
  setStatus: (status: string) => void;
  smsTemplate: string;
};

const ConfirmOutreach: React.FC<ConfirmOutreachProps> = ({
  outreachID,
  status,
  setStatus,
  smsTemplate,
  refreshMainTable,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [totalPatients, setTotalPatients] = useState<number>(0);
  const [totalSms, setTotalSms] = useState<number>(0);
  const [schedules, setSchedules] = useState<any>();

  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);
  const [updatedStatus, setUpdatedStatus] = useState<string>(status);

  const handlePublish = (data: any) => {
    return updateOutreachStatus(facility.id, outreachID, { status: 'published' }).then((res) => {
      enqueueSnackbar(`Outreach successfully published!`, { variant: 'success' });
      setStatus('published');
      setUpdatedStatus('published');
      refreshMainTable();
    });
  };

  const getOutreachDetails = () => {
    if (outreachID) {
      return getOutreach(facility.id, outreachID).then((res) => {
        setTotalPatients(res.data.total_patients);
        setSchedules(res.data.schedules);
        setTotalSms(Math.ceil(res.data.sms_template.length / 160) * res.data.total_patients * res.data.total_schedules);
      });
    }
  };

  useEffect(() => {
    if (outreachID) {
      getOutreachDetails();
    }
  }, [outreachID]);

  return (
    <>
      <Box
        sx={{
          width: '80%',
          margin: '0 auto',
          backgroundColor: colors.light_blue_background,
          borderRadius: SIZES.borderRadiusS,
          padding: SIZES.paddingL,
        }}
      >
        <ContainerColumn>
          <Box display="flex" flexDirection={'column'} width={'100%'} gap="20px">
            <Typography variant="h5" fontWeight={'bold'} marginBottom="20px">
              Outreach Summary
            </Typography>

            <Box display="grid" marginLeft="10px" gridTemplateColumns="1fr 2fr" gap="10px">
              {/* Total Patients Section */}
              <Box display="flex" flexDirection="row" gap="20px" justifyItems="center" alignItems="center">
                <Typography color={colors.accent}>{<PeopleOutlineOutlinedIcon />}</Typography>
                <Typography variant="h6" fontWeight="bold">
                  Total Patients Selected:
                </Typography>
              </Box>
              <Typography marginTop="3px">{totalPatients ? totalPatients : '0'}</Typography>

              {/* SMS Template */}
              <Box display="flex" flexDirection="row" gap="20px" justifyItems="center" alignItems="center">
                <Typography color={colors.accent}>{<EmailOutlinedIcon />}</Typography>
                <Typography variant="h6" fontWeight="bold">
                  SMS Template:
                </Typography>
              </Box>
              <Typography marginTop="3px">{smsTemplate}</Typography>

              {/* SMS Delivery Schedules Section */}
              <Box display="flex" flexDirection="row" gap="20px" justifyItems="center" alignItems="center">
                <Typography color={colors.accent}>{<SmsOutlinedIcon />}</Typography>
                <Typography variant="h6" fontWeight="bold">
                  SMS Delivery Schedules:
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr">
                {!schedules || schedules.length === 0 ? (
                  <Typography>No schedules found.</Typography>
                ) : (
                  JSON.parse(schedules).map((smsSchedule: any, index: number) => (
                    <Typography key={index}>{formatDateTimeWithDay(smsSchedule)}</Typography>
                  ))
                )}
              </Box>

              {/* SMS to be Sent Section */}
              <Box display="flex" flexDirection="row" gap="20px" justifyItems="center" alignItems="center">
                <Typography color={colors.accent}>{<ScheduleSendOutlinedIcon />}</Typography>
                <Typography variant="h6" fontWeight="bold">
                  SMS to be Sent:
                </Typography>
              </Box>
              <Typography marginTop="3px"> {totalSms} </Typography>
            </Box>
          </Box>

          <Box display="flex" gap="20px" justifyContent={'center'}>
            <RegularButton
              label={'Publish Outreach'}
              onClick={() => setOpenPublishDialog(true)}
              startIcon={<LockIcon fontSize="small" />}
              disabled={updatedStatus === 'draft' ? false : true}
              styles={{ backgroundColor: colors.secondary }}
            />
          </Box>
        </ContainerColumn>
      </Box>

      {outreachID && (
        <ConfirmationDialog
          title={'Confirmation'}
          open={openPublishDialog}
          setOpen={setOpenPublishDialog}
          content={'You are about to publish the outreach. Are you sure you want to proceed?'}
          onConfirm={handlePublish}
        />
      )}
    </>
  );
};

export default ConfirmOutreach;
