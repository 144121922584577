import { ApiQuery } from 'core/model/interface';
import { LguModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'lgu-management';

export const getLgus = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const exportLGUs = (query: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'lgus.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const getLanguages = () => {
  return axios.get(`${baseURL}/${path}/languages`);
};

export const updateLgu = (lgu_code: string, lgu: LguModel) => {
  return axios.put(`${baseURL}/${path}/${lgu_code}`, lgu);
};

export const reactivateLgu = (lgu_code: string) => {
  return axios.patch(`${baseURL}/${path}/reactivate/${lgu_code}`);
};

export const deactivateLgu = (lgu_code: string) => {
  return axios.delete(`${baseURL}/${path}/${lgu_code}`);
};

export const addLgu = (lgu: LguModel) => {
  return axios.post(`${baseURL}/${path}`, lgu);
};
