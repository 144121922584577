import { BorderBottom, BorderLeft } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { tokens } from 'theme/theme';
import CircleIcon from '@mui/icons-material/Circle';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&:not(:first-child):not(:last-child)': {
    // borderTop: 'none',
    // borderBottom: 'none',
  },
  '&.with-schedule': {
    backgroundColor: '#F2FAFD',
    borderRight: '1px solid #0192D5',
    borderLeft: '1px solid #0192D5',
    borderBottom: '1px solid #F2FAFD',
    borderTop: '1px solid #F2FAFD',
    color: '#0192D5',
    paddingRight: '2px',
    paddingLeft: '2px',
  },
  '&.blocked': {
    backgroundColor: '#E0E0E0',
    // borderRight: '1px solid #E0E0E0',
    // borderLeft: '1px solid #E0E0E0',
    color: 'red',
  },
  fontSize: '12px',
}));

interface Hour {
  start: string;
  end: string;
}

interface DaySchedule {
  day: string;
  week_day: string;
  hours: Hour[];
}

interface ScheduleTableProps {
  schedule: DaySchedule[];
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({ schedule }) => {
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const rows = 8;

  const getTimeSlots = (daySchedule: DaySchedule) => {
    if (daySchedule.hours.length === 0) {
      return ['Blocked'];
    }
    const startTime = daySchedule.hours[0].start;
    const endTime = daySchedule.hours[daySchedule.hours.length - 1].end;
    return [startTime, ...Array(rows - 2).fill(''), endTime];
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {days.map((day) => (
              <StyledTableCell key={day} align="center">
                {day}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rows)
            .fill(null)
            .map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {days.map((day) => {
                  const daySchedule = schedule.find((s) => s.day === day);
                  const timeSlots = daySchedule ? getTimeSlots(daySchedule) : ['Blocked'];
                  const hasSchedule = daySchedule && daySchedule.hours.length > 0;
                  return (
                    <StyledTableCell
                      key={`${day}-${rowIndex}`}
                      align="center"
                      className={hasSchedule ? 'with-schedule' : 'blocked'}
                    >
                      {timeSlots[rowIndex] ? `● ${timeSlots[rowIndex]}` : ''}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTable;
