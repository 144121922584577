import React, { useState } from 'react';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { company_user_schema } from 'carego-admin/model/schema';

type UserAccessFormProps = {
  formData: {
    first_name: string;
    middle_name: string;
    last_name: string;
    mobile_number: string;
    user_group_id: number;
    username: string;
    password: string;
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      first_name: string;
      middle_name: string;
      last_name: string;
      mobile_number: string;
      user_group_id: number;
      username: string;
      password: string;
    }>
  >;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  user_group_id: string | number;
  username: string;
  password: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const UserAccessForm: React.FC<UserAccessFormProps> = ({ formData, setFormData, setIsFormValid }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // Form Fields
  const fields: UserFieldInput[] = [
    {
      field_name: 'first_name',
      display_name: 'First Name',
      type: 'text',
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'middle_name',
      display_name: 'Middle Name (Optional)',
      type: 'text',
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'last_name',
      display_name: 'Last Name',
      type: 'text',
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'mobile_number',
      display_name: 'Mobile Number',
      type: 'text',
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'username',
      display_name: 'Username',
      type: 'text',
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'password_generator',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('password', value);
        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   password: value,
        // }));
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
  ];

  const handleSubmit = async (data: any) => {
    setFormData(data);
    setIsFormValid(true);
    setIsSuccess(true);
  };

  return (
    <CustomForm
      initialValues={formData}
      fields={fields}
      schema={company_user_schema}
      loading={false}
      onSubmit={handleSubmit}
      submitButtonText="Done"
      buttonWidth="150px"
      background="linear-gradient(to right, #239bd7, #0463ae);"
      isSuccess={isSuccess}
      submitButtonMarginTop={4}
    />
  );
};

export default UserAccessForm;
