import React, { useState } from 'react';

import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { company_schema } from 'core/model/schema';

type CompanyDetailsFormProps = {
  formData: {
    company_name: string;
    primary_language: number;
    company_logo: string;
    company_logo_dark: string;
    plan: string;
    email: string;
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      company_name: string;
      primary_language: number;
      company_logo: string;
      company_logo_dark: string;
      plan: string;
      email: string;
    }>
  >;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserInput = {
  company_name: string;
  primary_language: number | string;
  plan: string;
  email: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({ formData, setFormData, setIsFormValid }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const isMobile = window.innerWidth < 768;

  const buttonWidth = isMobile ? '80px' : '150px';
  // Form Fields
  const fields: UserFieldInput[] = [
    {
      field_name: 'company_name',
      display_name: 'Company Name',
      type: 'text',
      span: 4,
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
    {
      field_name: 'email',
      display_name: 'Email',
      type: 'text',
      span: 4,
      onChange: () => {
        setIsSuccess(false);
      },
    },
  ];

  const handleSubmit = async (data: any) => {
    setFormData(data);
    setIsFormValid(true);
    setIsSuccess(true);
  };

  return (
    <Box display="block" marginBottom={'2rem'}>
      <Box>
        <CustomForm
          initialValues={formData}
          fields={fields}
          schema={company_schema}
          loading={false}
          onSubmit={handleSubmit}
          submitButtonText="Done"
          buttonWidth={buttonWidth}
          isSuccess={isSuccess}
          submitButtonMarginTop={4}
          background="linear-gradient(to right, #239bd7, #0463ae);"
        />
      </Box>
    </Box>
  );
};

export default CompanyDetailsForm;
