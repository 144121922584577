import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'corporate-client-department';

export const getOne = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAllClientDepartment = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getManyByCorporateClientId = (corporate_client_id: number) => {
  return axios.get(`${baseURL}/${path}/client/${corporate_client_id}`);
};

export const create = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const update = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteOne = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
