import { Box } from '@mui/material';

type InputContainerProps = {
  children: React.ReactNode;
};

const InputContainer: React.FC<InputContainerProps> = ({ children }) => {
  return (
    <Box width="80%" margin="0 auto" paddingTop="20px">
      {children}
    </Box>
  );
};

export default InputContainer;
