import { Box, Typography, useTheme } from '@mui/material';

import { CompanyFacilityModel } from 'company/model/Entities';
import { ContainerColumn } from 'core/components/containers';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import RichTextEditor from 'core/components/RichTextEditor';
import { tokens } from 'theme/theme';

type Props = {
  clinic?: CompanyFacilityModel;
};

const ClinicDetails: React.FC<Props> = ({ clinic }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ContainerColumn>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <LocalHospitalIcon sx={{ color: colors.primary, fontSize: '18px' }} />
        <Typography variant="h5" fontWeight="bold">
          Clinic Details
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px">
        <PatientField label="Clinic Name" value={clinic?.facility_name} sx={{ gridColumn: 'span 3' }} />

        <PatientField label="Contact No. 1" value={clinic?.smart_mobile_number} />
        <PatientField label="Contact No. 2" value={clinic?.globe_mobile_number} />
        <PatientField label="Email" value={clinic?.email} />
        {/* <PatientField label="Language" value={clinic?.primary_language} /> */}

        <PatientField label="Region" value={clinic?.region_name} />
        <PatientField label="Province" value={clinic?.province_name} />
        <PatientField label="Municipality" value={clinic?.municipality_name} />
        <PatientField label="Address" value={clinic?.address} sx={{ gridColumn: 'span 3' }} />
        <PatientField
          label="Clinic Introduction"
          valueIsComponent
          value={<Box>{clinic?.description && <RichTextEditor content={clinic.description} readOnly />}</Box>}
          sx={{ gridColumn: 'span 3' }}
        />
      </Box>
    </ContainerColumn>
  );
};

export default ClinicDetails;
