import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import { enrollProgramRemindersPatients } from 'lgu/api/program-reminders-patients';
import { tb_dots_schema } from 'lgu/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type TbEnrollModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  selectedProgram: string;
  selectedPatientIDs: any[];
  selectedPatientID: number;
  type: 'create' | 'update';
  refreshNotEnrolledTable: () => void;
  refreshEnrolledTable: () => void;
};

export type UserInput = {
  date_started: string;
  sending_time: string;
  tb_status: string;
  tb_refill_interval: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  tb_status: 'Intensive',
  tb_refill_interval: '',
  date_started: '',
  sending_time: '',
};

const TbEnrollModal: React.FC<TbEnrollModalProps> = ({
  header,
  open,
  setOpen,
  selectedProgram,
  selectedPatientIDs,
  selectedPatientID,
  refreshNotEnrolledTable,
  refreshEnrolledTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const tbDotsFields: UserFieldInput[] = [
    {
      field_name: 'tb_status',
      display_name: 'Status',
      type: 'select',
      options: [{ key: 'Active', value: 'Intensive' }],
    },
    { field_name: 'date_started', display_name: 'Patient Start Date', type: 'date' },
    { field_name: 'sending_time', display_name: 'SMS Reminder Time', type: 'time', minutesStep: 5 },
    { field_name: 'tb_refill_interval', display_name: 'Refill Interval (Days)', type: 'number' },
  ];

  const handleTbFormSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const date_started = dayjs(data.date_started);

    const patient = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);

    const patientData = {
      patients: patient,
      date_started: data.date_started,
      sending_time: (data.sending_time + '').slice(0, 5),
      tb_status: 'Intensive', //to fix
      tb_refill_interval: data.tb_refill_interval,
      tb_sputum_schedule1: dayjs(date_started.add(8, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule2: dayjs(date_started.add(20, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule3: dayjs(date_started.add(24, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
    };

    enrollProgramRemindersPatients(selectedProgram, patientData)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => setButtonLoading(false));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      refreshNotEnrolledTable();
      refreshEnrolledTable();
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  return (
    <>
      <CustomModal header={header} open={open} setOpen={setOpen}>
        <CustomForm
          initialValues={initialValues}
          onSubmit={handleTbFormSubmit}
          fields={tbDotsFields}
          schema={tb_dots_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default TbEnrollModal;
