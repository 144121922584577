import UserAccess from 'company/screens/ClinicManagement/components/UserAccess/UserAccess';
import { useState } from 'react';

const User = () => {
  const [selected, setSelected] = useState(0);
  const [refresh, setRefresh] = useState(0);

  return <UserAccess selectedTab={selected} forceRefresh={refresh} setForceRefresh={setRefresh} />;
};

export default User;
