import { CustomContainer, CustomTable, Header } from 'core/components';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { commonTemplatesColumns } from '../../utils/table-columns';
import { getCommonSmsTemplates } from 'carego-admin/api/sms-templates';
import { useState } from 'react';

const CommonSmsTemplates = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getTemplates = (query: ApiQuery) => {
    setLoading(true);
    getCommonSmsTemplates(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Box marginTop={'50px'}>
      <Header title="LGU SMS Templates" />
      <CustomContainer>
        <CustomTable
          columns={commonTemplatesColumns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getTemplates}
          showRefreshButton
        />
      </CustomContainer>
    </Box>
  );
};

export default CommonSmsTemplates;
