import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'basic-health-info';

export const getBasicHealthInformationByPatient = (facility_id: number, patient_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${patient_id}`);
};

export const createBasicHealthInformation = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};
