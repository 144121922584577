/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import InputContainer from '../common/InputContainer';
import { TbAcfPatientModel } from 'lgu/model/Entities';
import dayjs from 'dayjs';
import { enrollToTbDots } from 'lgu/api/tb-acf';
import { enroll_to_tbdots_schema } from 'lgu/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type TbDotsEnrollmentProps = {
  patient: TbAcfPatientModel;
  refreshTable: () => void;
  result: number;
  setResult: (result: number) => void;
  setStatus: (status: string) => void;
};

type UserInput = {
  date_started: string;
  sending_time: string;
  tb_refill_interval: string | number;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  date_started: '',
  sending_time: '09:00',
  tb_refill_interval: '',
};

const TbDotsEnrollment: React.FC<TbDotsEnrollmentProps> = ({ patient, refreshTable, setResult, result, setStatus }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const savedValues: UserInput = {
    date_started: patient.medication_day_one ? patient.medication_day_one.split(' ')[0] : '',
    sending_time: patient.medication_day_one ? patient.medication_day_one.split(' ')[1].slice(0, 5) : '',
    tb_refill_interval: patient.tb_refill_interval ?? '',
  };

  const [medicationInitialValues, setMedicationInitialValues] = useState<UserInput>(
    patient.medication_day_one && patient.medication_day_one !== '0000-00-00 00:00:00' ? savedValues : initialValues
  );
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [submitButtonText, setSubmitButtonText] = useState<string>(result ? 'Enrolled' : 'Enroll');
  const [disableSubmit, setDisableSubmit] = useState<boolean>(patient.enrolled_tb_dots === 1 ? true : false);

  const medicationFormFields: UserFieldInput[] = [
    {
      field_name: 'date_started',
      display_name: 'Start Date of Medication',
      type: 'date',
      disablePast: true,
      format: 'YYYY-MM-DD',
      minutesStep: 5,
      disabled: patient.enrolled_tb_dots ? true : false,
    },
    {
      field_name: 'sending_time',
      display_name: 'SMS Reminder Time',
      type: 'time',
      minutesStep: 5,
      disabled: patient.enrolled_tb_dots ? true : false,
    },
    {
      field_name: 'tb_refill_interval',
      display_name: 'Refill Interval (Days)',
      type: 'number',
      disabled: patient.enrolled_tb_dots ? true : false,
    },
  ];

  const handleSubmit = (data: any) => {
    setButtonLoading(true);

    const date_started = dayjs(data.date_started);
    const patientList = JSON.stringify([patient.id]);
    const patientIDList = JSON.stringify([patient.patient_id]);
    const patientData = {
      patients: patientList,
      rp_patients: patientIDList,
      date_started: data.date_started,
      sending_time: data.sending_time + ':00',
      tb_status: 'Intensive',
      tb_refill_interval: data.tb_refill_interval,
      tb_sputum_schedule1: dayjs(date_started.add(8, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule2: dayjs(date_started.add(20, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
      tb_sputum_schedule3: dayjs(date_started.add(24, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time,
    };

    if (!result) {
      enrollToTbDots(patientData)
        .then((res) => {
          enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
          setButtonLoading(false);
          setSubmitButtonText('Enrolled');
          setResult(1);
          refreshTable();
          setDisableSubmit(true);
          setStatus('Enrolled');
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    setStatus('Positive');

    if (patient.medication_day_one && patient.medication_day_one !== '0000-00-00 00:00:00') {
      setMedicationInitialValues(savedValues);
    }
  }, []);

  return (
    <>
      <Divider sx={{ margin: '20px 0' }} />

      <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px">
        <Typography variant="h5" color={colors.text} fontWeight="bold">
          TB-DOTS Details
        </Typography>
      </Box>

      <InputContainer>
        <CustomForm
          onSubmit={handleSubmit}
          schema={enroll_to_tbdots_schema}
          initialValues={medicationInitialValues}
          loading={buttonLoading}
          fields={medicationFormFields}
          submitButtonText={submitButtonText}
          disabled={disableSubmit}
        />
      </InputContainer>
    </>
  );
};

export default TbDotsEnrollment;
