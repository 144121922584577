import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAccountTypeByAccountCode, getAccountTypes } from 'company/api/chart-of-account';
//TODO: need to fix
const AccountTypeDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Account Types'}
      getData={getAccountTypes}
      getById={getAccountTypeByAccountCode}
      processOption={(data) => ({ key: data.account_type, value: data.account_code })}
    />
  );
};

export default AccountTypeDropdown;
