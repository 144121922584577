import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Button, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  RegularButton,
} from 'core/components';
import { createUser, deactivateUser, getUsers } from 'lgu/api/users';

import { GridColDef } from '@mui/x-data-grid';
import { UserModel } from 'core/model/Entities';
import { create_staff_schema } from 'lgu/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  username: string;
  role: string;
  password: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  first_name: '',
  middle_name: '',
  last_name: '',
  username: '',
  role: '',
  password: '',
};

const MemberAccounts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [openEnrollStaffModal, setOpenEnrollStaffModal] = useState<boolean>(false);
  const [staff, setStaff] = useState<UserModel>();
  const [openDeactivateStaffModal, setOpenDeactivateStaffModal] = useState<boolean>(false);

  const memberColumns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Staff Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setStaff(params.row);
              setOpenDeactivateStaffModal(true);
            }}
          >
            Deactivate
          </Button>
        </Box>
      ),
    },
  ];

  //form fields
  const enrollFields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    {
      field_name: 'role',
      display_name: 'Role',
      type: 'select',
      options: [
        { key: 'Health Worker', value: 'HealthWorker' },
        { key: 'Admin', value: 'Lgu' },
      ],
    },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    { field_name: 'username', display_name: 'Username', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'password', display_name: 'Password', type: 'password' },
  ];

  const getMemberAccounts = (query: ApiQuery) => {
    setLoading(true);
    getUsers(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const viewEnrollStaffModal = () => {
    setOpenEnrollStaffModal(true);
  };

  const handleEnroll = (data: any) => {
    setButtonLoading(true);
    setButtonDisabled(true);
    const staff = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      username: data.username,
      password: data.password,
      role: data.role,
      user_group_id: data.role === 'Lgu' ? 1 : 2,
      main_admin: 0,
    };

    createUser(staff)
      .then(() => {
        enqueueSnackbar('Staff successfully enrolled!', { variant: 'success' });
        setButtonLoading(false);
        setButtonDisabled(false);
        setOpenEnrollStaffModal(false);
        getMemberAccounts({ page: 1, length: 10 });
      })
      .catch((err) => console.error(err));
    setButtonLoading(false);
    setButtonDisabled(false);
  };

  const handleStaffDeactivation = () => {
    if (staff) {
      deactivateUser(staff.id)
        .then(() => {
          enqueueSnackbar('Staff successfully deactivated!', { variant: 'success' });
          setButtonLoading(false);
          setButtonDisabled(false);
          setOpenDeactivateStaffModal(false);
          getMemberAccounts({ page: 1, length: 10 });
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <>
      {/* //to be updated */}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4" component="h3" color={colors.text} fontWeight="bold">
          User Management
        </Typography>
      </Box>

      {/* <Box display="flex" justifyContent="end" gap={2} width="100%"></Box> */}
      <CustomTable
        columns={memberColumns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getMemberAccounts}
        rowHeight={45}
        headerComponent={<RegularButton label="Enroll Staff" onClick={viewEnrollStaffModal} />}
      />

      <CustomModal header={'Enroll Staff'} open={openEnrollStaffModal} setOpen={setOpenEnrollStaffModal}>
        <CustomForm
          onSubmit={handleEnroll}
          fields={enrollFields}
          schema={create_staff_schema}
          initialValues={initialValues}
          disabled={buttonDisabled}
          loading={buttonLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeactivateStaffModal}
        setOpen={setOpenDeactivateStaffModal}
        content={'Are you sure you want to deactivate ' + staff?.first_name + ' ' + staff?.last_name + '?'}
        onConfirm={handleStaffDeactivation}
      />
    </>
  );
};

export default MemberAccounts;
