import { EntityFields } from 'company/entities/utils';

export const USER_GROUP_PERMISSION_ENTITY_NAME = 'User Group Permission';
export interface UserGroupAuthorityModel {
  group_name: string;
  id: number;
  authority: string;
  description: string;
}

export const userGroupAuthorityFields: EntityFields[] = [
  {
    fieldName: 'group_name',
    displayName: 'Group Name',
    hiddenBasedOnOtherField: (data) => {
      return data.type === 'update';
    },
  },
  { fieldName: 'authority', displayName: 'Authority' },
  { fieldName: 'description', displayName: 'Description' },
];

export const userGroupAuthorityVisibleFields = ['group_name', 'authority', 'description'];
export const userGroupAuthorityVisibleFieldsII = ['authority', 'description'];
export const userGroupAuthorityInitialValues = {
  group_name: '',
  authority: '',
  description: '',
};
