/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomTable, Header } from 'core/components';
import { approveSmsCredit, declineSmsCredit, getPendingSmsCredits } from 'carego-admin/api/sms-credits';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { pendingSmsCreditsColumns } from '../../utils/table-columns';
import { useSnackbar } from 'notistack';

const PendingSmsCreditRequests = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<number>(0);

  const [creditRequest, setCreditRequest] = useState<any>();

  //decline or accept credit requests
  const [openCreditRequestDialog, setOpenCreditRequestDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [approve, setApprove] = useState<boolean>(false);

  const addActionToTable = () => {
    if (!pendingSmsCreditsColumns.find((col) => col.field === 'action')) {
      pendingSmsCreditsColumns.push({
        field: 'action',
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={() => {
                showCreditApproveDialog(
                  'Are you sure you want to approve credit request of ' +
                    params.row.lgu_name +
                    ' worth ' +
                    params.row.credits +
                    ' SMS?'
                );
                setCreditRequest(params.row);
                setApprove(true);
              }}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                showCreditDeclineDialog(
                  'Are you sure you want to decline credit request of ' +
                    params.row.lgu_name +
                    ' worth ' +
                    params.row.credits +
                    ' SMS?'
                );
                setCreditRequest(params.row);
                setApprove(false);
              }}
            >
              Decline
            </Button>
          </Box>
        ),
      });
    }
  };

  const getCredits = (query: ApiQuery) => {
    setLoading(true);
    getPendingSmsCredits(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showCreditDeclineDialog = (content: string) => {
    setOpenCreditRequestDialog(true);
    setAlertContent(content);
  };

  const handleCreditDecline = () => {
    declineSmsCredit(creditRequest.id)
      .then(() => {
        enqueueSnackbar('Credit Request successfully declined!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const showCreditApproveDialog = (content: string) => {
    setOpenCreditRequestDialog(true);
    setAlertContent(content);
  };

  const handleCreditApprove = () => {
    approveSmsCredit(creditRequest.id)
      .then(() => {
        enqueueSnackbar('Credit Request successfully approved!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (firstLoad === 0) {
      addActionToTable();
      setFirstLoad(firstLoad + 1);
    }
  }, [firstLoad]);

  return (
    <Box marginTop={'30px'}>
      <Header title="Requested SMS Credits" />
      <CustomContainer>
        {firstLoad > 0 && (
          <CustomTable
            columns={pendingSmsCreditsColumns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getCredits}
            forceRefresh={forceRefresh}
          />
        )}
      </CustomContainer>

      <ConfirmationDialog
        open={openCreditRequestDialog}
        setOpen={setOpenCreditRequestDialog}
        content={alertContent}
        onConfirm={approve ? handleCreditApprove : handleCreditDecline}
        confirmButtonColor={approve ?? true}
        confirmButtonLabel={approve ? 'Approve' : 'Decline'}
      />
    </Box>
  );
};

export default PendingSmsCreditRequests;
