/* eslint-disable react-hooks/exhaustive-deps */

import { CareGoPage } from 'core/PageBuilder';
import ExpenseDrawer from 'company/entities/modules/AccountingSystem/Expense/ExpenseDrawer';

type ExpensesProps = {};

const Expenses: React.FC<ExpensesProps> = () => {
  
  return (
    <CareGoPage header="Expenses" breadcrumb={[{ label: 'Expenses' }]}>
      <ExpenseDrawer />
    </CareGoPage>
  );
};

export default Expenses;
