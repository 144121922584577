/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { capitalizeWord, formatArray, formatDate, formatTime } from 'core/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getBookingDetails } from 'company/api/bookings';
import styled from 'styled-components';

interface ConfirmProps {
  status: string; // Assuming data.status is of type string
}
interface ButtonProps {
  isReturnButton?: boolean;
}
const Booking = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await getBookingDetails(Number(id));
        setData(response.data);
      } catch (err) {
        console.error('An error occurred while fetching data:', err);
      }
    };

    fetchBookings();
  }, [id]);

  return (
    <Containers>
      <ClinicContainer>
        <Headers />

        <Box width="calc(100% - 40px)" maxWidth="800px" margin="0 auto">
          <Details>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ConfirmationTitle>Appointment Booked</ConfirmationTitle>
                <Confirm status={data.status}>{capitalizeWord(data.status)}</Confirm>
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  marginTop: '20px',
                }}
              >
                {data.status === 'pending' &&
                  `Thank you for choosing ${
                    data.facility_name
                  }. We have received your booking details, and we will send you a confirmation for your appointment within the next 24-48 hours. If you have any questions or concerns, please do not hesitate to contact us at ${formatArray(
                    [data.smart_mobile_number, data.globe_mobile_number],
                    ' / '
                  )}  ${data.email ? 'or via email at ' + data.email : ''}.`}
                {data.status === 'confirmed' &&
                  `Thank you for choosing ${data.facility_name}. Your appointment is now confirmed. We look forward to seeing you soon!`}
                {data.status === 'cancelled' &&
                  `You have successfully cancelled your appointment. If you have any other questions or concerns, please do not hesitate to contact us at ${formatArray(
                    [data.smart_mobile_number, data.globe_mobile_number],
                    ' / '
                  )}  ${data.email ? 'or via email at ' + data.email : ''}.`}
              </Typography>
            </Box>
          </Details>
        </Box>
      </ClinicContainer>

      {data.status !== 'cancelled' && (
        <Box
          width="calc(100% - 40px)"
          maxWidth="700px"
          margin="40px auto"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Container>
            <Box>
              <Box sx={{ padding: '10px 20px', display: 'flex', gap: '25px' }}>
                <Icon alt="img" src="/assets/landingassets/CalendarIcon.png" />
                <Info>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '10px' }}>
                    Date & Time
                  </Typography>
                  <Typography>{formatDate(data.schedule)}</Typography>
                  <Typography>{formatTime(data.schedule)}</Typography>
                </Info>
              </Box>
            </Box>
            <Box sx={{ borderTop: '1px solid grey', paddingTop: '10px' }}>
              <Box sx={{ padding: '10px 20px', display: 'flex', gap: '25px' }}>
                <Icon alt="img" src="/assets/landingassets/AppointmentIcon.png" />
                <Info>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '10px' }}>
                    Appointment
                  </Typography>
                  <Typography>Service: {data.service_name}</Typography>
                  <Typography>Clinic: {data.facility_name}</Typography>
                  <Typography>
                    Contact #: {formatArray([data.smart_mobile_number, data.globe_mobile_number], ' / ')}
                  </Typography>
                  <Typography>
                    Address: {formatArray([data.address, data.municipality_name, data.province_name], ', ')}
                  </Typography>
                </Info>
              </Box>
            </Box>
          </Container>

          <Box>
            <ConfirmationTitle>Appointment Confirmation</ConfirmationTitle>
            <Box display="grid" gridTemplateColumns="150px 1fr" rowGap="5px" mt="15px">
              <Typography fontWeight="bold">Name</Typography>
              <Typography>{data.full_name}</Typography>

              <Typography fontWeight="bold">Birthday</Typography>
              <Typography>{data.birthday}</Typography>

              <Typography fontWeight="bold">Sex Assigned at Birth</Typography>
              <Typography>{data.sex}</Typography>

              <Typography fontWeight="bold">Mobile Number</Typography>
              <Typography>{data.mobile_number}</Typography>

              <Typography fontWeight="bold">Email</Typography>
              <Typography>{data.email}</Typography>
            </Box>
          </Box>

          <StepsCon>
            <ConfirmationTitle>Instructions for Your Appointment</ConfirmationTitle>
            <InstructionCon>
              <Icon src="/assets/Booking/Icon1.png" />
              <Box display="flex" flexDirection="column" gap="5px">
                <InstrucTit> Prepare Relevant Documents: </InstrucTit>
                <InstrucP>
                  Please ensure you have any necessary medical records, test results, or prescriptions related to your
                  health condition ready for the doctor's review.
                </InstrucP>
              </Box>
            </InstructionCon>
            <InstructionCon>
              <Icon src="/assets/Booking/Icon2.png" />
              <Box display="flex" flexDirection="column" gap="5px">
                <InstrucTit> Arrival Time: </InstrucTit>
                <InstrucP>
                  We kindly request you to arrive at least 15 minutes before your scheduled appointment time. This will
                  allow us to complete any administrative procedures and ensure a timely consultation.
                </InstrucP>
              </Box>
            </InstructionCon>
            <InstructionCon>
              <Icon src="/assets/Booking/Icon3.png" />
              <Box display="flex" flexDirection="column" gap="5px">
                <InstrucTit> Cancellation or Rescheduling: </InstrucTit>
                <InstrucP>
                  If, for any reason, you need to cancel or reschedule your appointment, please do so at least 24 hours
                  in advance. You can contact us through{' '}
                  {formatArray([data.smart_mobile_number, data.globe_mobile_number], ' / ')}.
                </InstrucP>
              </Box>
            </InstructionCon>
            <InstructionCon>
              <Icon src="/assets/Booking/Icon4.png" />
              <Box display="flex" flexDirection="column" gap="5px">
                <InstrucTit> Online Consultation: </InstrucTit>
                <InstrucP>
                  If you have opted for an online consultation, please make sure you are in a quiet and private space
                  with a stable internet connection at the scheduled appointment time.
                </InstrucP>
              </Box>
            </InstructionCon>
            <InstructionCon>
              <Icon src="/assets/Booking/Icon5.png" />
              <Box display="flex" flexDirection="column" gap="5px">
                <InstrucTit>Payment and Insurance: </InstrucTit>
                <InstrucP>
                  If applicable, ensure that you have your payment method ready. For insured patients, please bring your
                  insurance details and card for verification.
                </InstrucP>
              </Box>
            </InstructionCon>
          </StepsCon>

          <Box margin="10px 0 30px 0">
            <Button isReturnButton onClick={() => navigate('/book/appointment/' + data.facility_code)}>
              Return to Booking Page
            </Button>
          </Box>
        </Box>
      )}
    </Containers>
  );
};

export default Booking;

const Info = styled.div``;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 20px 15px;
`;

const ConfirmationTitle = styled.p`
  font-size: 24px;
  font-family: Fredoka;
  font-weight: bold;
  margin: 0;
`;
const Confirm = styled.div<ConfirmProps>`
  padding: 4px 10px;
  border-radius: 20px;
  color: black;
  font-size: 12px;
  font-family: Fredoka;
  background-color: ${(props) =>
    props.status === 'pending'
      ? 'rgba(239, 122, 53, 0.5)'
      : props.status === 'confirmed'
      ? '#028DCE'
      : props.status === 'cancelled'
      ? '#F1F1F1'
      : '#028DCE'}; // Default to '#028DCE' if status is not recognized
`;

const Button = styled.button<ButtonProps>`
  font-weight: bold;
  font-family: Fredoka;
  margin-top: 7px;
  width: 100%;
  padding: 15px;
  border-radius: 25px;

  border: none;
  color: ${(props) => (props.isReturnButton ? 'white' : 'black')};
  background-color: ${(props) => (props.isReturnButton ? '#0768B0' : 'white')};
  border: 2px solid ${(props) => (props.isReturnButton ? 'white' : '#0768B0')};

  &:hover {
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 50px;
`;
const ClinicContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;

  align-items: center;
  flex-direction: column;
`;
const Headers = styled.div`
  min-height: 35vh;
  background-image: url('/assets/Booking/confirmBG.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 100%;
  position: absolute;
  z-index: -1;
`;
const Details = styled.div`
  margin-top: 100px;
  padding: 50px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  margin-bottom: 25px;
  border-radius: 20px;
`;

const Containers = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;

  overflow-x: hidden;
  @media (min-width: 768px) {
  }
`;

const StepsCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0px;
`;
const InstructionCon = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;
const InstrucTit = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-family: Fredoka;
`;
const InstrucP = styled.div`
  font-size: 13px;
`;
