import {
  MEDICINE_INSTRUCTION_ENTITY_NAME,
  MedicineInstructionModel,
  medicineInstructionFields,
  medicineInstructionTableColumn,
} from './MedicineInstructionModel';
import { deleteMedicineInstruction, getMedicineInstructions } from 'company/api/medicine-instructions';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import MedicineInstructionForm from './MedicineInstructionForm';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { useMediaQuery } from '@mui/material';

type Props = {
  visibleColumns?: string[];
  medicineId: number;
};

const MedicineInstructionTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, medicineId } = props;
  const [selectedRow, setSelectedRow] = useState<MedicineInstructionModel>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useImperativeHandle(ref, () => ({ refreshTable }));
  const tableRef: any = useRef();

  const refreshTable = () => {
    tableRef.current.refreshTable();
  };

  const callbackAfterSubmit = () => {
    refreshTable();
    tableRef.current.closeFormModal();
  };

  const getMedicineInstruction = () => {
    return getMedicineInstructions(medicineId).then((response) => {
      const responseData = response.data.data.map((instruction: any) => {
        const { days_of_week, hours_interval, time_to_take, frequency_per_day } = instruction;
        return {
          ...instruction,
          days_of_week: days_of_week
            ? days_of_week
                .split(',')
                .map((day: string) => ({ key: day.charAt(0).toUpperCase() + day.slice(1), value: day }))
            : [],
          frequency_per_day: frequency_per_day ? frequency_per_day : 1,
          hours_interval: hours_interval ? hours_interval : 0,
          time_to_take: time_to_take ? time_to_take : '00:00:00',
        };
      });

      response.data.data = responseData;
      return response;
    });
  };

  useEffect(() => {
    refreshTable();
  }, [medicineId]);

  return (
    <TemplateTable
      ref={tableRef}
      entityName={MEDICINE_INSTRUCTION_ENTITY_NAME}
      fields={medicineInstructionFields}
      visibleColumns={visibleColumns ?? medicineInstructionTableColumn}
      getData={getMedicineInstruction}
      handleDelete={deleteMedicineInstruction}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      templateForm={
        <MedicineInstructionForm
          medicineId={medicineId}
          medicineInstruction={selectedRow}
          callbackAfterSubmit={callbackAfterSubmit}
        />
      }
      hideAddUpdateDeleteAction={isMobilePhone}
    />
  );
});

export default MedicineInstructionTable;
