import {
  HAB_ENTITY_NAME,
  HealthAssessmentBatchModel,
  batchFields,
  batchFormFields,
  batchInitialValues,
  batchSchema,
} from './HealthAssessmentBatchModel';
import { createNewHealthAssessmentBatch, updateBatch } from 'company/api/health-assessment-batches';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { WaitForFacility } from 'company/components';
import { changeNullToBlank } from 'core/utils';
import { useContext } from 'react';

type Props = {
  readonly?: boolean;
  batch?: HealthAssessmentBatchModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const HealthAssessmentBatchForm = (props: Props) => {
  const { batch, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);

  async function handleSubmit(data: any) {
    return batch ? updateBatch(facility.id, batch.id, data) : createNewHealthAssessmentBatch(facility.id, data);
  }

  return (
    <WaitForFacility facility={facility}>
      <TemplateForm
        schema={batchSchema}
        callbackAfterSubmit={callbackAfterSubmit}
        entityName={HAB_ENTITY_NAME}
        fields={batchFields}
        initialValues={batch ? changeNullToBlank(batch) : batchInitialValues}
        visibleFields={batchFormFields}
        formSubmitApiFunction={handleSubmit}
      />
    </WaitForFacility>
  );
};

export default HealthAssessmentBatchForm;
