import { Box, Typography, useTheme } from '@mui/material';
import { ColoredTable, ConfirmationDialog, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import { deleteAttributeValue, getAttributeValues } from 'company/api/attribute-values';
import { deleteVariantAttribute, getVariantAttributes } from 'company/api/variant-attributes';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttributeValueForm from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueForm';
import { AttributeValueModel } from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueModel';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerRow } from 'core/components/containers';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DeleteButton from 'core/components/buttons/DeleteButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { SIZES } from 'theme/constants';
import UpdateButton from 'core/components/buttons/UpdateButton';
import { UserContext } from 'core/context/user.context';
import VariantAttributeForm from 'company/entities/modules/InventorySystem/VariantAttribute/VariantAttributeForm';
import { VariantAttributeModel } from 'company/entities/modules/InventorySystem/VariantAttribute/VariantAttributeModel';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const VariantAttributePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  const [openAddAttribute, setOpenAddAttribute] = useState(false);
  const [openDeleteAttribute, setOpenDeleteAttribute] = useState(false);

  const [openAddAttributeValue, setOpenAddAttributeValue] = useState(false);
  const [openDeleteAttributeValue, setOpenDeleteAttributeValue] = useState(false);

  const [selectedAttribute, setSelectedAttribute] = useState<VariantAttributeModel>();
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<AttributeValueModel>();

  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();

  // const [attributeValues, setAttributeValues] = useState<AttributeValueModel[]>([]);

  const [fetchingValues, setFetchingValues] = useState<boolean>(false);

  const [rowAttributeValues, setRowAttributeValues] = useState<{ [key: string]: AttributeValueModel[] }>({});

  const tableRef: any = useRef();

  const refreshTable = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  // const fetchAttributeValues = () => {
  //   setFetchingValues(true);
  //   if (selectedAttribute) {
  //     getAttributeValues(selectedAttribute.id, { length: 1000 }).then((response) => {
  //       setAttributeValues(response.data.data);
  //     }).catch(err => {
  //       console.error(err)
  //     }).finally(()=> {
  //       setFetchingValues(false);
  //     });
  //   }
  // };

  const fetchAttributeValues = () => {
    setFetchingValues(true);
    if (selectedAttribute) {
      getAttributeValues(selectedAttribute.id, { length: 1000 })
        .then((response) => {
          setRowAttributeValues((prev) => ({
            ...prev,
            [selectedAttribute.id]: response.data.data,
          }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setFetchingValues(false);
        });
    }
  };

  // const handleSelectRow = (selected: any) => {
  //   if (selected) {
  //     setSelectedAttribute(selected);
  //   }
  // };

  const handleSelectRow = (selected: any) => {
    if (selected) {
      setSelectedAttribute(selected);
      if (!rowAttributeValues[selected.id]) {
        fetchAttributeValues();
      }
    }
  };

  const handleDelete = () => {
    if (selectedAttribute) {
      deleteVariantAttribute(selectedAttribute.id)
        .then((res) => {
          enqueueSnackbar('Attribute successfully deleted!', { variant: 'success' });
          refreshTable();
        })
        .catch((error) => {
          enqueueSnackbar('Error occurred! Unable to delete attribute.', { variant: 'error' });
        });
    }
  };

  // const handleDeleteValue = () => {
  //   if (selectedAttributeValue) {
  //     deleteAttributeValue(selectedAttributeValue.id)
  //       .then((res) => {
  //         enqueueSnackbar('Attribute Value successfully deleted!', { variant: 'success' });
  //         fetchAttributeValues();
  //         refreshTable();
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar('Error occurred! Unable to delete attribute value.', { variant: 'error' });
  //       });
  //   }
  // };

  const handleDeleteValue = () => {
    if (selectedAttributeValue && selectedAttribute) {
      deleteAttributeValue(selectedAttributeValue.id)
        .then((res) => {
          enqueueSnackbar('Attribute Value successfully deleted!', { variant: 'success' });
          fetchAttributeValues();
          refreshTable();
        })
        .catch((error) => {
          enqueueSnackbar('Error occurred! Unable to delete attribute value.', { variant: 'error' });
        });
    }
  };

  // useEffect(() => {
  //   fetchAttributeValues();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [facility, selectedAttribute]);

  useEffect(() => {
    if (selectedAttribute && !rowAttributeValues[selectedAttribute.id]) {
      fetchAttributeValues();
    }
  }, [facility, selectedAttribute]);

  return (
    <>
      <CareGoPage loading={!user}>
        <Box display="grid">
          <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none' }}>
            <ColoredTable
              ref={tableRef}
              getData={getVariantAttributes}
              searchOnLeft
              orderBy={'attribute_name'}
              order={'asc'}
              handleSelectRow={handleSelectRow}
              headerComponent={
                <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
                  <RegularButton
                    startIcon={<AddIcon />}
                    label="Add Attribute"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSelectedAttribute(undefined);
                      setOpenAddAttribute(true);
                    }}
                  />
                </ContainerRow>
              }
              columns={[
                {
                  field: 'attribute_name',
                  headerName: 'Attribute Category',
                  flex: 1,
                },
                {
                  field: 'attribute_values',
                  headerName: 'Attribute Values',
                  flex: 2,
                  sortable: false,
                  renderCell: (params) => <Typography marginRight={'15px'}>{params.row.attribute_values}</Typography>,
                },
                {
                  field: 'action',
                  sortable: false,
                  headerName: 'Actions',
                  headerAlign: 'center',
                  align: 'center',
                  flex: 1,
                  renderCell: (params) => (
                    <Box ml="50px" mr="50px" display="flex" gap="10px">
                      <UpdateButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAttribute(params.row);
                          setOpenAddAttribute(true);
                        }}
                      />
                      <DeleteButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAttribute(params.row);
                          setOpenDeleteAttribute(true);
                        }}
                      />
                    </Box>
                  ),
                },
              ]}
              displayChildComponent={(row) => (
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: SIZES.paddingS, paddingRight: SIZES.paddingS }}
                  >
                    <Typography variant="h4" align="center" fontWeight="bold">
                      Attribute Values for {row.attribute_name}
                    </Typography>

                    <RegularButton
                      startIcon={<AddIcon />}
                      label="Add Attribute Value"
                      color="primary"
                      variant="contained"
                      onClick={() => setOpenAddAttributeValue(true)}
                    />
                  </Box>

                  <Box
                    sx={{
                      flexGrow: 1,
                      overflowY: 'auto',
                      padding: SIZES.paddingS,
                      paddingRight: SIZES.paddingS,
                    }}
                  >
                    {!fetchingValues && rowAttributeValues[row.id] && rowAttributeValues[row.id].length > 0 && (
                      <Box display="flex" flexWrap="wrap" sx={{ gap: '15px', padding: '20px 0' }}>
                        {rowAttributeValues[row.id].map((value) => (
                          <Box
                            key={value.id}
                            display="flex"
                            gap="10px"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              width: 'calc(25% - 11.25px)',
                              padding: '20px',
                              borderRadius: '10px',
                              border: '1px solid #E0E0E0',
                              '&:hover': {
                                backgroundColor: colors.hoverTableRow,
                              },
                            }}
                          >
                            <Typography variant="h5" mb="5px" fontWeight="regular" sx={{ wordBreak: 'break-word' }}>
                              {value.value}
                            </Typography>

                            <Box display="flex" gap="10px" flexShrink={0}>
                              <CustomIconButton
                                icon={<EditOutlinedIcon />}
                                onClick={() => {
                                  setSelectedAttributeValue(value);
                                  setOpenAddAttributeValue(true);
                                }}
                                sx={{
                                  backgroundColor: colors.greenAccent,
                                  borderRadius: '5px',
                                  color: 'white',
                                  padding: '7px',
                                  '&:hover': {
                                    backgroundColor: colors.green_hovered,
                                  },
                                }}
                              />

                              <CustomIconButton
                                icon={<DeleteOutlinedIcon />}
                                onClick={() => {
                                  setSelectedAttributeValue(value);
                                  setOpenDeleteAttributeValue(true);
                                }}
                                sx={{
                                  backgroundColor: colors.redAccent,
                                  borderRadius: '5px',
                                  color: 'white',
                                  padding: '7px',
                                  '&:hover': {
                                    backgroundColor: colors.red_hovered,
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                    {fetchingValues && <CustomLoadingIndicator />}{' '}
                    {!fetchingValues && rowAttributeValues[row.id] && rowAttributeValues[row.id].length < 1 && (
                      <Typography variant="h6" color="textSecondary" align="center">
                        No values found.
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            />
          </Box>
        </Box>
      </CareGoPage>

      <CustomModal
        header={selectedAttribute ? 'Update Variant Attribute' : 'Add Variant Attribute'}
        open={openAddAttribute}
        setOpen={setOpenAddAttribute}
      >
        <VariantAttributeForm
          attribute={selectedAttribute}
          callbackAfterSubmit={(data: any) => {
            setOpenAddAttribute(false);
            setSelectedAttribute(undefined);
            refreshTable();
          }}
          add={!selectedAttribute}
        />
      </CustomModal>

      {selectedAttribute && (
        <CustomModal
          header={selectedAttributeValue ? 'Update Attribute Value' : 'Add Attribute Value'}
          open={openAddAttributeValue}
          setOpen={setOpenAddAttributeValue}
        >
          <AttributeValueForm
            attribute_id={selectedAttribute.id}
            value={selectedAttributeValue}
            callbackAfterSubmit={(data: any) => {
              setOpenAddAttributeValue(false);
              setSelectedAttributeValue(undefined);
              fetchAttributeValues();
              refreshTable();
            }}
          />
        </CustomModal>
      )}

      {selectedAttribute && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedAttribute?.attribute_name + '?'}
          open={openDeleteAttribute}
          setOpen={setOpenDeleteAttribute}
          onConfirm={handleDelete}
        />
      )}

      {selectedAttributeValue && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedAttributeValue.value + '?'}
          open={openDeleteAttributeValue}
          setOpen={setOpenDeleteAttributeValue}
          onConfirm={handleDeleteValue}
        />
      )}
    </>
  );
};

export default VariantAttributePage;
