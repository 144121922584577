import PendingSmsCreditRequests from './components/credits/PendingSmsCreditRequests';
import SmsCreditRequests from './components/credits/SmsCreditRequests';

const SmsCredits = () => {
  return (
    <>
      <PendingSmsCreditRequests />
      <SmsCreditRequests />
    </>
  );
};

export default SmsCredits;
