import CustomSideBar, { APP_MODULE, MenuItemProps } from './CustomSideBar';

import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const prefix = '/company/admin'

const AdminAppSidebar = () => {
  const company_menu_items: MenuItemProps[] = [
    {
      section_name: '',
      subitems: [
        {
          title: 'Dashboard',
          link: `${prefix}`,
          icon: <BarChartOutlinedIcon />,
        },
        {
          title: 'Appointments',
          link: `${prefix}/appointments`,
          icon: <CalendarMonthOutlinedIcon />,
        },
        {
          title: 'Patient Transactions',
          link: `${prefix}/transactions`,
          icon: <ReceiptOutlinedIcon />,
        },
      ],
    },
    {
      section_name: '',
      subitems: [
        {
          title: 'Clinic Settings',
          link: `${prefix}/clinic-management`,
          icon: <SettingsOutlinedIcon />,
        },
      ],
    },
  ];

  return <CustomSideBar menuItems={company_menu_items} module={APP_MODULE.COMPANY_ADMIN} />;
};

export default AdminAppSidebar;
