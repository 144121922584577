/* eslint-disable react-hooks/exhaustive-deps */
import { CustomTabs, Header } from 'core/components';
import { useContext, useEffect, useState } from 'react';
import TermsOfUse from './TermsOfUse';
import About from './SmsTemplates/About';
import AccountSettings from './AccountSettings';
import ActivityLogs from './ActivityLogs';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import MessageTemplates from './MessageTemplates';
import { TabProps } from 'core/components/CustomTabs';

const Settings = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      label: 'Account Settings',
      content: <AccountSettings />,
    },
    {
      label: 'Message Templates',
      content: <MessageTemplates />,
    },
    {
      label: 'Activity Logs',
      content: <ActivityLogs />,
    },
    {
      label: 'About',
      content: <About />,
    },
    {
      label: 'Terms of Use & Privacy Policy',
      content: <TermsOfUse />,
    },
    {
      label: 'Terms of Use & Privacy Policy',
      content: <TermsOfUse />,
    },
  ];

  useEffect(() => {
    setBreadcrumb([{ label: 'Settings' }]);
  }, []);

  return (
    <Box>
      <Header title="Settings" />
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default Settings;
