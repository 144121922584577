export const getColorByStatus = (status: string, mode: any) => {
  switch (status) {
    case 'pending':
      return mode === 'light'? '#f59014' : '#FFD700' ;
    case 'rescheduled':
      return mode === 'light'? '#4169E1' : '#93adfa';
    case 'cancelled':
      return mode === 'light' ? '#a88e03' : '#db4f4a';
    case 'no-show':
      return mode === 'light'? '#525252' : '#a8a5a5';
    default:
      return mode === 'light'? '#008000' : '#14f532';
  }
};