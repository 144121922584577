import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import CustomAutoCompleteMultipleSelect from 'core/components/CustomAutoCompleteMultipleSelect';
import { FieldInput } from 'core/model/interface';
import { createBarangayHealthworker } from 'lgu/api/health-workers';
import { create_bhw_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type HealthworkerFormProps = {
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setRefreshAssigned: React.Dispatch<React.SetStateAction<number>>;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  barangays: any[];
};

type UserInput = {
  barangays: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  sex: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  barangays: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  sex: '',
};

const HealthworkerForm: React.FC<HealthworkerFormProps> = ({
  setRefresh,
  setRefreshAssigned,
  open,
  setOpen,
  barangays,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBarangays, setSelectedBarangays] = useState<any[]>([]);

  const healthworkFields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    {
      field_name: 'sex',
      display_name: 'Sex Assigned At Birth',
      type: 'select',
      options: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
      ],
    },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
  ];

  const handleAdd = (data: any) => {
    setLoading(true);

    let barangayCodes = selectedBarangays.map((brgy) => brgy.value.toString());
    const healthworker = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      mobile_number: data.mobile_number,
      sex: data.sex,
      barangay_code: JSON.stringify(barangayCodes),
    };

    createBarangayHealthworker(healthworker)
      .then((res) => {
        enqueueSnackbar('Barangay Health Worker successfully created!', { variant: 'success' });
        setRefresh((prev: number) => prev + 1);
        setRefreshAssigned((prev: number) => prev + 1);
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleSelectBarangay = (event: any, value: React.SetStateAction<any[]>) => {
    setSelectedBarangays(value);
  };

  return (
    <CustomModal header="Add Barangay Health Worker" open={open} setOpen={setOpen}>
      <Box marginBottom={'20px'}>
        <CustomAutoCompleteMultipleSelect
          options={barangays.map((u) => {
            return {
              key: u.barangay_name,
              value: u.barangay_code,
            };
          })}
          label={'Barangays'}
          fieldName={'Barangays (optional)'}
          handleChange={handleSelectBarangay}
        />
      </Box>

      <CustomForm
        onSubmit={handleAdd}
        fields={healthworkFields}
        schema={create_bhw_schema}
        initialValues={initialValues}
        loading={loading}
      />
    </CustomModal>
  );
};

export default HealthworkerForm;
