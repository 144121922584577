import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'prescription-medicines';

export const getPrescriptionMedicines = (facility_id: number, prescription_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/many/${prescription_id}`, {
    params: query,
  });
};

export const getNotAssignedMedicines = (facility_id: number, prescription_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/not-assigned/${prescription_id}`, {
    params: query,
  });
};

export const addMedicinesToPrescription = (facility_id: number, prescription_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/assign/${prescription_id}`, data);
};

export const getPrescriptionMedicine = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createPrescriptionMedicine = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const updatePrescriptionMedicine = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deletePrescriptionMedicine = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateInstructionAndQuantity = (facility_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/update-instruction/${id}`, data);
};
