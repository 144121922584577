import { updateScreeningResult, updateStage } from 'lgu/api/tb-acf';

import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { screening_result_options } from 'lgu/data/select-options';
import { update_screening_result_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type ScreeningResultBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  screening_result: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  screening_result: '',
};

const ScreeningResultBatch: React.FC<ScreeningResultBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const screeningFormFields: UserFieldInput[] = [
    {
      field_name: 'screening_result',
      display_name: 'Proceed to:',
      type: 'select',
      options: screening_result_options.map((option) => {
        return { key: option.key, value: option.value };
      }),
    },
  ];

  const updateScreening = (data: any) => {
    setButtonLoading(true);

    switch (data.screening_result) {
      case 'Presumptive-Sputum':
        saveScreeningResult('Presumptive', 'Sputum Test');
        break;
      case 'Presumptive-Checkup':
        saveScreeningResult('Presumptive', 'Doctor Appointment');
        break;
      case 'Positive':
        saveScreeningResult('Positive', 'TB-DOTS Enrollment');
        break;
      case 'Negative':
        saveScreeningResult('Negative', 'Screening');
        break;
    }
  };

  const saveScreeningResult = (screening_result: string, stage: string) => {
    const patientData = {
      patients: patients,
      stage: stage,
      patient_status: screening_result,
    };

    const patientScreeningResult = {
      patients: patients,
      screening_result: screening_result,
    };

    updateScreeningResult(patientScreeningResult)
      .then(() => {
        if (screening_result !== 'Negative') {
          updateStage(patientData)
            .then(() => {
              enqueueSnackbar('Screening Result successfully saved!', { variant: 'success' });
              setButtonLoading(false);
              openModal(false);
              refreshTable();
            })
            .catch((error) => console.error(error));
        } else {
          enqueueSnackbar('Screening Result successfully saved!', { variant: 'success' });
          setButtonLoading(false);
          openModal(false);
          refreshTable();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateScreening}
          schema={update_screening_result_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={screeningFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default ScreeningResultBatch;
