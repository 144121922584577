import { UserContext } from 'core/context/user.context';
import { useContext } from 'react';

type HideForInactiveCompanyProps = {
  children: React.ReactNode;
};

const HideForInactiveCompany: React.FC<HideForInactiveCompanyProps> = ({ children }) => {
  const { user } = useContext(UserContext);
  if (!user) return <></>;
  return user.company?.is_active ? <>{children}</> : <></>;
};

export default HideForInactiveCompany;
