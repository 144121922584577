import React, { ReactNode } from 'react';

import { FacilityModel } from 'core/model/Entities';

type WaitForFacilityProps = {
  facility?: FacilityModel | number | boolean;
  children: ReactNode;
  skip?: boolean;
};

const WaitForFacility: React.FC<WaitForFacilityProps> = ({ facility, children, skip }) => {
  return <>{(facility || skip) && children}</>;
};

export default WaitForFacility;
