import * as yup from 'yup';

import { CustomForm, CustomModal } from 'core/components';
import { useContext, useEffect, useMemo, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { ProductVariantModel } from '../Product/ProductVariant/ProductVariantModel';
import { UserContext } from 'core/context/user.context';
import { createTransfer } from 'company/api/transfers';
import { formatAttributeValues } from 'core/utils';
import { transferProduct } from 'company/api/inventories';
import { useSnackbar } from 'notistack';

type Props = {
  variant: ProductVariantModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  currentStock: number;
};

const TransferStock: React.FC<Props> = ({
  variant,
  currentStock,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
}) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [clinics, setClinics] = useState<any[]>([]);
  const _facilityDetails = facilityDetails ?? facility;

  const transferSchema = useMemo(
    () =>
      yup.object().shape({
        transfer_quantity: yup
          .number()
          .required('Quantity is required')
          .typeError('Invalid Value.')
          .min(0, 'Quantity must be greater than 0')
          .max(currentStock, 'Curent stock is only ' + currentStock),
        dest_facility_id: yup.number().required('Clinic is required'),
      }),
    [currentStock]
  );

  useEffect(() => {
    if (user && user.facilities) {
      setClinics(user.facilities);
    }
  }, [user]);

  const transferFields: FieldInput[] = [
    {
      field_name: 'transfer_quantity',
      display_name: 'Quantity',
      type: 'text',
      span: 2,
    },
    {
      field_name: 'dest_facility_id',
      display_name: 'Clinic',
      type: 'select',
      span: 2,
      disabled: clinics.length === 1, //only clinic is the curent clinic
      options: clinics
        ?.filter((u) => u.id !== _facilityDetails.id)
        .map((u) => {
          return { key: u.facility_name, value: u.id };
        }),
    },
  ];

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const clinicId = _facilityDetails.id;

    data.origin_facility = clinicId;
    data.destination_facility = data.dest_facility_id;
    data.transaction_date = new Date();
    data.status = 'accepted';
    data.quantity = data.transfer_quantity;
    data.product_id = variant.product_id;
    data.variant_id = variant.id;

    createTransfer(data)
      .then(() => {
        enqueueSnackbar('Stock successfully transferred!', { variant: 'success' });
        setOpen(false);
        callbackAfterSubmit && callbackAfterSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Transfer Stock - ${variant.product_name}${formatAttributeValues(variant.attributes, true)}`}
      subHeader={_facilityDetails.facility_name}
    >
      <CustomForm
        fields={transferFields}
        schema={transferSchema}
        initialValues={{
          transfer_quantity: '',
          dest_facility_id: '',
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CustomModal>
  );
};

export default TransferStock;
