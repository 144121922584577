import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'expense-types';

export const getExpenseTypes = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const getExpenseType = (id: number) => {
  return axios.get(`${baseURL}/${path}/one/${id}`);
};


export const createExpenseType = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateExpenseType = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteExpenseType = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};


