import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const CampaignArticle1: React.FC = () => {
  return (
    <Box>
      <Step>1. Go to the "Campaigns" tab in the application.</Step>
      <Step>2. Click on the "Create Campaign" button to start a new campaign.</Step>
      <Image src="/assets/FaqAssets/2.png" alt="Step 2 Image" />

      <Step>
        3. Fill out the campaign form. The only required field is "Event Name," but you can add more details as needed.
      </Step>
      <Image src="/assets/FaqAssets/3.png" alt="Step 3 Image" />

      <Step>
        4. By default, the campaign status will be set to "Draft," which means the SMS will not be sent until the
        campaign is published.
      </Step>

      <Step>
        5. To enroll patients, click the campaign first then on "Add Patients." You can add single patients one by one
        or add multiple patients at once.
      </Step>
      <Image src="/assets/FaqAssets/5.1.png" alt="Step 5 Image 1" />
      <Image src="/assets/FaqAssets/5.2.png" alt="Step 5 Image 2" />

      <Step>
        6. After enrolling patients, you can create SMS schedules. To do this, click on "Schedule SMS" and then click
        "Add Schedule."
      </Step>
      <Image src="/assets/FaqAssets/6.1.png" alt="Step 6 Image 1" />
      <Image src="/assets/FaqAssets/6.2.png" alt="Step 6 Image 2" />

      <Step>7. Select the date and time for the desired SMS schedule. </Step>

      <Step>
        8. Add the message content you want to send in the SMS. Please keep in mind that 160 characters equals 1 SMS
        credit.
      </Step>
      <Image src="/assets/FaqAssets/8.png" alt="Step 8 Image" />

      <Step>
        9. Once you've finalized the enrolled patients and SMS schedules, you may change the campaign status to
        "Published." Simply click the switch button to toggle the status to "Published." then confirm.
      </Step>
      <Image src="/assets/FaqAssets/9.1.png" alt="Step 9 Image 1" />
      <Image src="/assets/FaqAssets/9.2.png" alt="Step 9 Image 2" />

      <Step>
        10. After publishing the campaign, it will be locked, and the SMS will be sent according to the schedule you
        set. If you need to make updates to the campaign, change the status back to "Draft," make the necessary changes,
        and then set the status to "Published" again once you're ready.
      </Step>
      <Image src="/assets/FaqAssets/10.png" alt="Step 10 Image" />
    </Box>
  );
};

export default CampaignArticle1;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
