import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-program-sms';

export const getProgramSms = (facility_id: number, program_code: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${program_code}`, {
    params: query,
  });
};

export const getAllProgramSms = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, {
    params: query,
  });
};
