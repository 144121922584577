import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { addServices, deleteGroup, getAllGroups, getGroup, removeService } from 'company/api/service-groups';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Chip from '@mui/material/Chip';
import ClinicsTable from '../../ClinicsTable';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupServiceTable from 'company/entities/modules/ClinicManagement/Service/GroupServiceTable';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import ServiceGroupForm from 'company/entities/modules/ClinicManagement/Service/ServiceGroupForm';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import { formatCurrency } from 'core/utils';
import { getServicePackageByGroupId } from 'company/api/service-packages';
import { tokens } from 'theme/theme';

export default function ServiceGroups({ setOpenServiceGroups }: any) {
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const [selectedGroupDetails, setSelectedGroupDetails] = useState<any>();
  const [selectedServicePackageDetails, setSelectedServicePackageDetails] = useState<any>();

  const [addFormOpen, setAddFormOpen] = useState<boolean>(false);

  const [openAddServicesForm, setOpenAddServicesForm] = useState<boolean>(false);
  const [addServicesLoading, setAddServicesLoading] = useState<boolean>(false);
  const [selectedServicesToAdd, setSelectedServicesToAdd] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
  const [newlyAddedGroupID, setNewlyAddedGroupID] = useState<number>();
  const [updatedGroup, setUpdatedGroup] = useState<number | null>(null);

  const [deletedID, setDeletedID] = useState<number>();
  const [toDeleteID, setToDeleteID] = useState<number>();
  const [loadingDeleteGroup, setLoadingDeleteGroup] = useState<boolean>(false);
  const tableRef: any = useRef();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    async function getGroupDetails() {
      if (!selectedGroup) return;
      const res = await getGroup(selectedGroup);
      setSelectedGroupDetails(res.data);
    }
    getGroupDetails();
    if (selectedGroup) tableRef.current?.refreshTable();
  }, [selectedGroup]);

  async function addMultipleServices() {
    const data = {
      group_id: selectedGroup,
      services: selectedServicesToAdd,
    };
    setAddServicesLoading(true);
    await addServices(data);
    setAddServicesLoading(false);
    setOpenAddServicesForm(false);
    tableRef.current?.refreshTable();
  }

  function getData(query: ApiQuery) {
    return getAllGroups(facility.id, query);
  }

  function getSingleData(id: number) {
    return getGroup(id);
  }

  async function handleDeleteGroup(id: number) {
    setLoadingDeleteGroup(true);
    await deleteGroup(id);
    setDeletedID(id);
    setLoadingDeleteGroup(false);
    tableRef.current?.refreshTable();
  }

  useEffect(() => {
    tableRef.current?.refreshTable();
  }, [selectedGroup]);

  const displayItem = (item: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedGroup ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.group_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleDelete = async (data: any) => {
    const dataToDelete = {
      service_id: data,
      group_id: selectedGroup,
    };
    try {
      await removeService(facility.id, dataToDelete);
      tableRef.current.refreshTable();
    } catch (error) {
      console.error('Error deleting the item:', error);
    }
  };

  const getPackageDetails = async () => {
    if (selectedGroup) {
      const response = await getServicePackageByGroupId(selectedGroup);
      setSelectedServicePackageDetails(response.data);
    } else {
      console.error('Error fetching package details:');
    }
  };

  useEffect(() => {
    getPackageDetails();
  }, [selectedGroup]);

  return (
    <InfiniteScrollContainer
      title="Service Groups"
      getData={getData}
      deleteId={deletedID}
      updatedItem={updatedGroup}
      addedNewItem={newlyAddedGroupID}
      getSingleData={(id) => getSingleData(id)}
      selected={selectedGroup}
      setSelected={setSelectedGroup}
      onChange={(selected) => {
        tableRef.current?.refreshTable();
      }}
      actions={[
        {
          icon: <AddIcon />,
          label: 'Add Services',
          action: (data) => {
            setOpenAddServicesForm(true);
          },
        },
        {
          icon: <EditOutlinedIcon />,
          label: 'Update Group',
          action: async (data) => {
            setIsUpdate(true);
            setAddFormOpen(true);
          },
        },
        {
          icon: <DeleteOutlinedIcon />,
          label: 'Delete',
          color: 'error',
          action: (data) => {
            setToDeleteID(data);
            setDeleteGroupDialogOpen(true);
          },
        },
      ]}
      renderItem={displayItem}
      urlKeyId="sgid"
      headerComponents={
        !isMobilePhone && (
          <RegularButton
            onClick={() => {
              setAddFormOpen(true);
              setIsUpdate(false);
            }}
            label="Add"
            startIcon={<AddIcon />}
            variant="outlined"
            fullWidth
          />
        )
      }
      displaySelectedTitle={() => (
        <ContainerRow sx={{ display: 'flex', justifyContent: 'start', gap: '5px' }}>
          {setOpenServiceGroups && (
            <RegularButton
              variant="text"
              size="small"
              startIcon={<ArrowBackIosOutlinedIcon />}
              label="Back"
              onClick={() => setOpenServiceGroups(false)}
              styles={{ padding: '2px 5px 2px 20px', marginLeft: '-20px' }}
            />
          )}
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {selectedGroupDetails?.group_name}
          </Typography>
        </ContainerRow>
      )}
    >
      {selectedGroup && (
        <>
          {!!selectedGroupDetails?.is_package && (
            <Box display={'flex'} alignItems={'center'} justifyContent={'start'} gap={'10px'} mt={'20px'}>
              <Chip
                label={`Service Package Price: ${formatCurrency(selectedGroupDetails?.price)}`}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '14px' }}
                size="small"
              />
              {/* <Chip
                label={`Price: ${formatCurrency(selectedGroupDetails.price)}`}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '12px' }}
                size="small"
              /> */}
              {/* if want separate chip just uncomment^^ */}
            </Box>
          )}

          <GroupServiceTable
            tableRef={tableRef}
            selectedGroup={selectedGroup}
            tableAction={{
              includeDeleteAction: true,
              includeUpdateAction: false,
              deleteButtonLabel: 'Remove',
              deleteApiFunction: handleDelete,
              alertCustomObjectColumn: 'service_name',
              customDeleteSnackBarMessageOnSuccess: 'removed from group',
            }}
          ></GroupServiceTable>

          {!!selectedGroupDetails?.is_package && (
            <ClinicsTable source="Service Packages" selectedServicePackage={selectedServicePackageDetails} />
          )}
        </>
      )}

      {/*  FORM FOR CREATING NEW GROUP */}
      <CustomModal
        open={addFormOpen}
        setOpen={setAddFormOpen}
        header={!isUpdate ? `Create New Service Group` : 'Update Service Group'}
        onClose={() => setIsUpdate(false)}
      >
        <ServiceGroupForm
          serviceGroup={isUpdate ? selectedGroupDetails : undefined}
          callbackAfterSubmit={(data) => {
            if (isUpdate) {
              setUpdatedGroup(data.data);
              setSelectedGroupDetails(data.data);
            } else setNewlyAddedGroupID(data.data.id);

            setAddFormOpen(false);
          }}
        />
      </CustomModal>

      {/* FORM FOR ADDING SERVICES TO A GROUP */}
      <CustomModal
        open={openAddServicesForm}
        setOpen={setOpenAddServicesForm}
        header={`Add services to group ${selectedGroupDetails?.group_name}`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'space-between' }}>
          <ServiceSearchDropdown
            useGetNotAddedServiceAPI
            groupId={selectedGroup}
            limit={20}
            handleChangeCallback={(record) => {
              setSelectedServicesToAdd(record);
            }}
            multiple
            fieldName="service_id"
          ></ServiceSearchDropdown>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <RegularButton onClick={() => addMultipleServices()} loading={addServicesLoading}></RegularButton>
          </Box>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        loadingConfirm={loadingDeleteGroup}
        setOpen={setDeleteGroupDialogOpen}
        open={deleteGroupDialogOpen}
        title="Confirm Delete Group"
        content={`Are you sure you want to delete this group`}
        onConfirm={() => {
          if (!toDeleteID) return;
          handleDeleteGroup(toDeleteID);
        }}
      ></ConfirmationDialog>
    </InfiniteScrollContainer>
  );
}
