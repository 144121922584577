import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const CC_ENTITY_NAME = 'Corporate Client';

export const createCorporateClientSchema = yup.object().shape({
  company_name: yup.string().required('Company Name is required'),
  contact_person: yup.string(),
  contact_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Contact Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  email: yup.string(),
  facilities: yup.array().min(1, 'At least one clinic must be selected'),
});

export interface CorporateClientModel {
  id: number;
  company_id: string;
  company_name: string;
  contact_person: string;
  contact_number: string;
  email: string;
  is_active: boolean;
  created_by: number;
}

export interface CorporateClientInput {
  company_name: string;
  email: string;
  contact_person: string;
  contact_number: string;
  facilities: any[];
}

export interface CorporateClientFieldInput extends FieldInput {
  field_name?: keyof CorporateClientInput;
}

export const corporateClientInitialValues: CorporateClientInput = {
  company_name: '',
  email: '',
  contact_person: '',
  contact_number: '',
  facilities: [],
};

// fieldName should be unique
export const corporateClientFields: EntityFields[] = [
  { fieldName: 'company_id', displayName: 'Company ID' },
  { fieldName: 'company_name', displayName: 'Company Name' },
  { fieldName: 'email', displayName: 'Email', type: 'email' },
  { fieldName: 'contact_person', displayName: 'Contact Person' },
  { fieldName: 'contact_number', displayName: 'Contact Number', optional: true },
  {
    fieldName: 'facilities',
    displayName: 'Clinic',
    type: 'multiselect',
    options: [],
    span: 4,
  },
  { fieldName: 'divider_1', type: 'divider' },
];

// Table
export const corporateClientTableColumns: string[] = ['company_name', 'contact_person', 'contact_number', 'email'];

// Forms
export const corporateClientFormFields: string[] = [
  'company_name',
  'contact_person',
  'contact_number',
  'email',
  'facilities',
];
