import 'core/styles/Components.css';

import { IconButton, InputAdornment, TextField, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type CustomPasswordGeneratorFieldProps = {
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChangeCallback?: (value: string) => void;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  span?: number;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  optional?: boolean;
};

const CustomPasswordGeneratorField: React.FC<CustomPasswordGeneratorFieldProps> = ({
  label,
  handleBlur,
  handleChange,
  handleChangeCallback,
  value,
  fieldName,
  disabled,
  touched,
  error,
  span,
  required,
  size = 'small',
  setFieldValue,
  optional,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const colors = tokens(theme.palette.mode);
  const [password, setPassword] = useState<string | undefined>(value as string | undefined);
  const [shrink, setShrink] = useState<boolean>(Boolean(value) || Boolean(password));

  const handleFocus = () => {
    setShrink(true);
  };

  const handleBlurInternal = () => {
    if (!password) {
      setShrink(false);
    }
  };

  const generatePasswordString = () => {
    const length = 16;
    const specialCharacters = '!@#$%^&*(),.?":{}|<>';
    const numbers = '0123456789';
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let password = '';
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];

    const allCharacters = letters + specialCharacters + numbers;
    for (let i = password.length; i < length; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password to ensure random distribution of characters
    password = password
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');

    return password;
  };

  const generatePassword = () => {
    const randomPassword = generatePasswordString();
    setPassword(randomPassword);
    setFieldValue(fieldName, randomPassword);

    // Call the handleChange callback with the new password
    handleChange &&
      handleChange({ target: { name: fieldName, value: randomPassword } } as React.ChangeEvent<HTMLInputElement>);
    handleChangeCallback && handleChangeCallback(randomPassword);
    setShrink(true);
  };

  const handleClickCopyPassword = () => {
    navigator.clipboard.writeText(password || '');
    enqueueSnackbar(`Password copied!`, { variant: 'success' });
  };

  useEffect(() => {
    if (value !== undefined) {
      setPassword(value as string);
    }
  }, [value]);

  return (
    <TextField
      fullWidth
      variant="filled"
      type="text"
      InputProps={{
        // readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Copy">
              <IconButton aria-label="copy password" onClick={handleClickCopyPassword} edge="end">
                {<ContentCopyIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Generate">
              <IconButton aria-label="generate password" onClick={generatePassword} edge="end">
                {<RefreshIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: shrink,
        focused: true,
        disableAnimation: false,
      }}
      label={label + (optional ? ' (optional)' : '')}
      disabled={disabled}
      onBlur={handleBlurInternal}
      onChange={handleChange}
      onFocus={handleFocus}
      value={password || ''}
      name={fieldName}
      error={!!touched && !!error}
      helperText={touched && error}
      required={required}
      size={size}
      sx={{
        '.MuiFormLabel-root.Mui-focused': { color: 'rgba(0, 0, 0, 0.6)' },
        '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none', color: colors.light },
        '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        '.MuiFilledInput-root:before': { border: 'none' },
        '.Mui-disabled:before': { border: 'none !important' },
        '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.primary}`, borderRadius: 2, height: '100%' },
        '.MuiFilledInput-input': { height: '22px' },
        '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        gridColumn: span ? 'span ' + span : undefined,
        '.MuiTextField-root': { color: colors.light },
      }}
    />
  );
};

export default CustomPasswordGeneratorField;
