/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { BreadcrumbContext, BreadcrumbTabProps } from 'core/context/breadcrumb.context';
import {
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  Dropdown,
  Header,
} from 'core/components';
import { ProgramModel, ProgramScheduleModel } from 'lgu/model/Entities';
import { calculateAge, formatDate, formatDateTime, tranformFormErrors } from 'core/utils';
import {
  cloneProgramSchedule,
  exportPatients,
  getProgramSchedule,
  updatePatientAttendance,
} from 'lgu/api/program-schedule';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { clone_type_past } from 'lgu/data/select-options';
import { getBarangays } from 'lgu/api/barangay';
import { getPatientsEnrolledInSchedule } from 'lgu/api/patient';
import { getProgram } from 'lgu/api/program';
import { tokens } from 'theme/theme';
import { update_program_schedule_schema } from 'lgu/model/schema';

export type UserInput = {
  schedule_name: string;
  venue: string;
  barangay_code: string;
  program_date_time: string;
  clone_type: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const PastProgramSchedule = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [barangays, setBarangays] = useState<any[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  //Schedule
  const [schedule, setSchedule] = useState<ProgramScheduleModel>();
  const [program, setProgram] = useState<ProgramModel>();
  const [toClone, setToClone] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  //Clone
  const [scheduleInitialValues, setScheduleInitialValues] = useState<UserInput>();
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);

  //attendance
  const [, setChecked] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, patientID: number, attended: boolean) => {
    if (event.target.checked) {
      const data = { patient: patientID, attended: 1 };
      updatePatientAttendance(parseInt(id as string), data)
        .then((res: any) => {
          setChecked(event.target.checked);
        })
        .catch((error) => console.error(error));
    } else {
      const data = { patient: patientID, attended: 0 };
      updatePatientAttendance(parseInt(id as string), data)
        .then((res: any) => {})
        .catch((error) => console.error(error));
    }
  };

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const getSchedule = () => {
    getProgramSchedule(parseInt(id as string))
      .then((res) => {
        setSchedule(res.data);
        getProgram(res.data.program_id)
          .then((res: any) => {
            setProgram(res.data);
          })
          .catch();
      })
      .catch();
  };

  const getScheduleDetails = () => {
    getProgramSchedule(parseInt(id as string))
      .then((res) => {
        setSchedule(res.data);
      })
      .catch();
  };

  const getEnrolledPatients = (query: ApiQuery) => {
    setLoading(true);
    getPatientsEnrolledInSchedule(parseInt(id as string), query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const cloneSchedule = () => {
    if (schedule) {
      const clonedSchedule = Object.assign({}, schedule);
      clonedSchedule.schedule_name = clonedSchedule.schedule_name + ' - Copy';
      setScheduleInitialValues(clonedSchedule);
      setToClone(true);
    }

    setOpenScheduleModal(true);
  };

  const exportParticipants = () => {
    schedule && exportPatients(schedule.id, schedule.schedule_name);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (schedule) {
      cloneProgramSchedule(schedule.id, data)
        .then((res: any) => {
          successFormSubmit(true, res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (clone: boolean, res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      navigate('/program-schedules/' + res.data.program_schedule.id);
      setOpenScheduleModal(false);
      getScheduleDetails();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex Assigned At Birth', flex: 1 },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Checkbox
            onChange={(e) => {
              handleChange(e, params.row.patient_id, params.row.attended);
            }}
            checked={params.row.attended ? true : false}
            inputProps={{
              'aria-label': 'Attendance Checkbox',
            }}
          />
        </Box>
      ),
    },
  ];

  // Form Fields
  const scheduleFields: UserFieldInput[] = [
    { field_name: 'schedule_name', display_name: 'Schedule Name', type: 'text' },
    {
      field_name: 'program_date_time',
      display_name: 'Date & Time',
      type: 'datetime',
      disablePast: true,
      minutesStep: 5,
    },
    { field_name: 'venue', display_name: 'Venue', type: 'text' },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay',
      type: 'select',
      options: barangays.map((u) => {
        return { key: u.barangay_name, value: u.barangay_code };
      }),
    },
    {
      field_name: 'clone_type',
      display_name: 'Clone Patients',
      type: 'select',
      hidden: toClone ? false : true,
      options: clone_type_past.map((u) => {
        return { key: u.key, value: u.value };
      }),
    },
  ];

  useEffect(() => {
    getSchedule();
    getBarangayList();
  }, []);

  useEffect(() => {
    getScheduleDetails();
  }, [navigate]);

  useEffect(() => {
    setBreadcrumb([]);
    if (program) {
      const breadcrumbs: BreadcrumbTabProps[] = [
        { label: 'Health Programs', link: '/programs' },
        { label: program.program_name, link: '/programs/program-schedules/' + program.id },
      ];

      if (schedule)
        breadcrumbs.push({
          label: schedule.schedule_name,
        });

      setBreadcrumb(breadcrumbs);
    }
  }, [schedule, program]);

  const settings_actions = [
    { label: 'Clone Schedule', action: cloneSchedule, icon: <EventRepeatIcon /> },
    { label: 'Export Participants', action: exportParticipants, icon: <FileDownloadIcon /> },
  ];

  return (
    <>
      <CustomContainer m="0 0 25px 0">
        {schedule && (
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Header title={schedule.schedule_name} />
              <Box>
                <Dropdown optionList={settings_actions} buttonLabel="Settings" />
              </Box>
            </Box>
            <Box display="grid" gridTemplateColumns="100px 1fr 100px 1fr" columnGap="20px" alignItems="center">
              <Typography>Date & Time</Typography>
              <Typography>{formatDateTime(schedule.program_date_time)}</Typography>

              <Typography>Created by</Typography>
              <Typography>{schedule.creator}</Typography>

              <Typography>Venue</Typography>
              <Typography>
                {schedule.venue}, Brgy. {schedule.barangay_name}
              </Typography>

              <Typography>Date Created</Typography>
              <Typography>{formatDate(schedule?.created_at)}</Typography>
            </Box>
          </Box>
        )}
      </CustomContainer>
      <CustomContainer>
        <Typography variant="h4" component="h3" color={colors.text} fontWeight="bold">
          Enrolled Patients
        </Typography>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'patient_id'}
          getData={getEnrolledPatients}
          checkboxSelection={false}
          selectableRow={false}
          disableSelectionOnClick
          keepNonExistentRowsSelected={false}
        />
      </CustomContainer>
      <CustomModal header={'Clone Schedule'} open={openScheduleModal} setOpen={setOpenScheduleModal}>
        <CustomForm
          initialValues={scheduleInitialValues}
          onSubmit={handleSubmit}
          fields={scheduleFields}
          schema={update_program_schedule_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default PastProgramSchedule;
