import { ApiQuery } from 'core/model/interface';
import { CompanyModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'client-management';

export const getCompanies = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getCompany = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const exportCompanies = (query: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'Companies.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const createCompany = (company: CompanyModel) => {
  return axios.post(`${baseURL}/${path}`, company);
};

export const updateCompany = (id: number, company: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, company);
};

export const updateCompanyLogo = (id: number, logo: any) => {
  return axios.patch(`${baseURL}/${path}/update-logo/${id}`, logo);
};

export const deactivate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const reactivate = (id: number) => {
  return axios.patch(`${baseURL}/${path}/reactivate/${id}`);
};

export const setFreeTrial = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/set-free-trial/${id}`, data);
};

export const cancelFreeTrial = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/cancel-free-trial/${id}`, data);
};

export const startFreeTrial = (id: number) => {
  return axios.patch(`${baseURL}/${path}/start-free-trial/${id}`);
};

export const onboardNewClient = (data: any) => {
  return axios.post(`${baseURL}/${path}/onboard-new-client`, data);
};

export const updateSubscriptionPlan = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/plan/${id}`, data);
};