import TransactionDetails, { TransactionProps } from './TransactionDetails';
import TransactionHeader, { INVENTORY_TRANSACTION } from './TransactionHeader';

import InventoryTransactionContainer from './InventoryTransactionContainer';
import { Typography } from '@mui/material';
import { UserContext } from 'core/context/user.context';
import { formatAttributeValues } from 'core/utils';
import { useContext } from 'react';

const ProductInTransaction: React.FC<TransactionProps> = (props) => {
  const { user } = useContext(UserContext);
  return (
    <InventoryTransactionContainer viewAll={props.viewAll}>
      <TransactionHeader transaction={props.transaction} transaction_type={INVENTORY_TRANSACTION.PRODUCT_IN} />
      <TransactionDetails
        {...props}
        fields={[
          {
            display_name: 'Product',
            field_name: 'product_name',
            renderValue: () => (
              <Typography fontSize={'11px'} sx={{ wordBreak: 'break-word' }}>
                {`${props.transaction.product_name}${formatAttributeValues(props.transaction.attributes, true)}`}
              </Typography>
            ),
          },
          { display_name: 'Staff', field_name: 'full_name' },
          // { display_name: 'Variant', field_name: 'variant_name' },
          { display_name: 'Quantity', field_name: 'quantity' },
          {
            display_name: 'Cost',
            field_name: 'product_cost',
            type: 'currency',
            hidden: user.user_group.group_name !== 'COMPANY_ADMIN',
          },
          { display_name: 'Supplier', field_name: 'supplier_name' },
        ]}
      />
    </InventoryTransactionContainer>
  );
};

export default ProductInTransaction;
