import { Box, Typography } from '@mui/material';
import { CustomContainer, Header } from 'core/components';

const TermsOfUse = () => {
  return (
    <CustomContainer>
      <Box mt={'20px'}>
        <Header title="Terms of Use" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the CareGo Health Suite web
          application operated by the SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Access to and use of the CareGo Health Suite platform for performing services is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to all stakeholders of both the SERVICE
          PROVIDER and the RHU.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          By signing of the RHU representative to this SERVICE SUBSCRIPTION AGREEMENT FOR CareGo Health Suite the RHU
          agrees to be bound by these Terms. Disagreement with any part of the terms described herein after signing this
          agreement will not entitle the RHU to any refunds from any payments made to the SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU understands that the PLATFORM is a web application that allows its representatives, employees, staff, and
          affiliates to efficiently send SMS announcements or reminders, and collectively view patients, and attendance
          in health programs. RHU accepts that the PLATFORM is only limited to the health program management through
          grouping target patients, automated scheduling of personalized SMS reminders and efficient SMS announcements
          to patients of the RHU. RHU recognizes that the SERVICE PROVIDER, its representatives, employees, and staff
          are not always available for any urgent needs that can be due to direct or indirect, accidental or
          consequential, events related to the PLATFORM. RHU understands that the SERVICE PROVIDER operates online
          customer support within office hours of 9:00 AM to 5:00 PM during weekdays excluding national and Muntinlupa
          City non-working holidays.
        </Typography>

        <Header title="ACCESS TO THE PLATFORM" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          By sending SMS through the PLATFORM, RHU verifies that its representatives, employees, staffs, and affiliates
          voluntarily registered to the PLATFORM without coercion or any forms of intimidation or compulsion from anyone
          and have submitted all the needed information for the ethical, moral use of the PLATFORM with full consent and
          understanding of its impact to beneficiaries and stakeholders. By continuing through the service, RHU
          authenticates that registered users to the platform are at least of legal age and/or possess the legal right
          and authority from the RHU and relevant government agencies to access, obtain, process, health data of the
          patients of the RHU within the scope of mandate of RHU for public health service delivery.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU acknowledges that to maintain integrity and health data security on the use of the PLATFORM, it shall
          direct its employees, representatives, and agents to conduct best practices and settings as recommended by
          SERVICE PROVIDER. RHU understands that to access the PLATFORM it shall have to supply its own necessary
          equipment to its employees, representatives, agents, and staff, which are not of responsibility by the SERVICE
          PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU comprehends that malwares, computer viruses, and other malicious software found or undetected in the RHU
          software and system are beyond the responsibility of the SERVICE PROVIDER. RHU recognizes that such malicious
          software that could potentially disrupt SERVICE PROVIDER’s services to the RHU will not lead to suspension of
          subscription, and that any legal liability, harm, or obligations arising from such occurrence will not be
          associated to the SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU agrees that the SERVICE PROVIDER, although it owns and has bounded control to the PLATFORM, does not have
          control over and shall have no responsibility for network gateway, acts or omissions of recipients from
          networks, and guarantee of intended recipients to receive SMS programmed by RHU for sending.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU will designate accounts who will access the PLATFORM and that personally identifiable data required for
          account creation except for the password will be shared to the SERVICE PROVIDER.
        </Typography>

        <Header title="SMS ANNOUNCEMENTS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that to use the PLATFORM, RHU staff will need to supply correct consented personal information
          and patient information relevant to achieving successful personalized health program announcements, without
          limitation to patient’s name, patient’s contact number, patient’s sex at birth, patient’s birthday and
          patient’s address.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that it is its responsibility to seek consent from patients for data to be processed to the
          PLATFORM of the SERVICE PROVIDER. Any data uploaded by the RHU to the PLATFORM will be recognized by the
          SERVICE PROVIDER to be data consented to by patients for processing personally non-identifiable information.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that the data accuracy of personalized SMS reminders is directly proportional to the accuracy
          of data supplied to the PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU acknowledges that the PLATFORM provided by the SERVICE PROVIDER is only limited to the automated
          scheduling of personalized SMS reminders and efficient SMS announcements to patients of the RHU.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU agrees that content of each SMS to be sent will require approval from SERVICE PROVIDER. However, RHU
          recognizes that approval from SERVICE PROVIDER does not constitute responsibility to the content of the SMS
          sent on how it will be used, perceived, by the recipients of such SMS.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU comprehends that SMS can only be sent to mobile numbers belonging to the networks of Globe Telecom, Smart
          Telecom, Sun Cellular, and DITO network within the territory of the Republic of the Philippines and that 1 SMS
          will only be composed of 160 characters. RHU understands that it cannot hold the SERVICE PROVIDER liable for
          discrepancies in estimation of character length per SMS per patient.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU acknowledges that the PLATFORM shall not be used for SPAMMING, SABOTAGING, HACKING, or any acts that will
          cause disruption of SERVICE PROVIDER operations, or that will cause social unrest, upheaval, or rebellion
          among its recipients.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          By sending an SMS through the PLATFORM by any of the representatives, employees, staff, and affiliates of the
          RHU, the RHU holds the SERVICE PROVIDER harmless from any forms of liabilities that may arise from the sent
          SMS through the PLATFORM and to any liabilities that may arise on the use of the SERVICE PROVIDER of the
          third-party SMS API providers, and data storage and processing from third-party providers.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          By using the PLATFORM, the RHU understands that the successful implementation of health programs and
          engagement of patients are not within the scope of service provided by the SERVICE PROVIDER. The RHU agrees
          that it holds CP HEALTH INNOVATIONS INC., its employees, officers, contributors, volunteers, stockholders,
          affiliates, and co-founders, without obligation to any form of claims for damages whether direct or indirect,
          related or consequential to the use of the PLATFORM.
        </Typography>

        <Header title="INTELLECTUAL PROPERTY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU agrees to respect any copyright, trademark, and intellectual products made by the SERVICE PROVIDER. RHU
          understands that the use of the PLATFORM is limited to public non-commercial, non-sectarian, non-political
          purposes and that it should not infringe the CP HEALTH INNOVATIONS INC. 's rights to the PLATFORM. RHU
          understands that any forms of reproduction, publication, and distribution of CP HEALTH INNOVATIONS INC.’s
          intellectual property, documentation, product manual, and agreements without written consent of the CP HEALTH
          INNOVATIONS INC. assigned representative is liable for prosecution under the fullest extent of the law of the
          Republic of the Philippines.
        </Typography>

        <Header title="CONTINUOUS SERVICE" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU accepts that the SERVICE PROVIDER and its representatives, employees, staff, and affiliates reserve the
          right to deny the RHU service in case of events that include but are not limited to, on the grounds of misuse
          of the PLATFORM, breach on the terms of use of the PLATFORM, abuse of the PLATFORM, unethical and immoral use
          of the PLATFORM, infringement of rights of the SERVICE PROVIDER to the PLATFORM, intentional sabotage of the
          operations of the PLATFORM, hacking of the PLATFORM, cases that are considered hazards on the health of
          SERVICE PROVIDER personnel, cases that are considered to put the SERVICE PROVIDER representatives, employees,
          staff, and affiliates to life-threatening danger, and other reasons that are not presented in this agreement
          based on judgment by the members and staff of SERVICE PROVIDER.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU fully comprehends that the PLATFORM is meant for informational purposes only and is not meant to serve as
          exact directions of availability on time and locations of health programs for its representatives, employees,
          staff, and beneficiaries.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU acknowledges that it does not hold the SERVICE PROVIDER, its owners, officers, employees, and affiliates,
          accountable for any discrepancies on the expected services of patients to the RHU, services of the SERVICE
          PROVIDER, functionalities, features, and interface available on the CareGo Health Suite web application and to
          any claims of stakeholders of both parties for damage, direct or indirect, random or consequential to the use
          of the PLATFORM.
        </Typography>

        <Header title="THIRD-PARTY WEBSITES AND LINKS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Communication between SERVICE PROVIDER and RHU through third-party websites or services (such as
          communications through Google, Facebook, Messenger, or Yahoo, and etc.) that are not owned or controlled by
          the CP HEALTH INNOVATIONS INC. will be considered as services which SERVICE PROVIDER has no control over and
          assumes no responsibility for the data security, content, privacy policies, or practices of any third-party
          websites or services used to transmit, receive, process data and/or as modes of communication between the two
          parties. RHU acknowledges reports generated from the PLATFORM opened, and read through third-party software
          (such as Microsoft Excel, Adobe, and etc.) will not be of responsibility, or of liability, directly or
          indirectly, for any damage or loss caused or alleged to be caused by or in connection to the SERVICE PROVIDER
          and its representatives, employees, staff, and affiliates.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU further acknowledges and agrees that the SERVICE PROVIDER and representatives, employees, staff, and
          affiliates shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on any such content, goods, or services
          available on or through the PLATFORM.
        </Typography>

        <Header title="TERMINATION" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU fully comprehends and accepts that the SERVICE PROVIDER may terminate or suspend access to the PLATFORM
          immediately, without prior notice or liability, for any reason whatsoever, including without limitation if in
          case the RHU breach the Terms, Service Subscription Agreement, Non-settlement of financial obligations and
          other reasons whatsoever that are deemed as sabotaging the PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          All provisions of the Terms which by their nature should survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </Typography>

        <Header title="CHANGES" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. reserves the right, at its sole discretion, to modify or replace these Terms at any
          time. If a revision is material, CP HEALTH INNOVATIONS INC. will try to provide notice prior to any new terms
          taking effect. What constitutes a material change will be determined at the SERVICE PROVIDER’s sole
          discretion.
        </Typography>

        <Header title="PRIVACY POLICY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. operates www.caregoemrsms.app (the PLATFORM). This page informs the RHU of the
          SERVICE PROVIDER’s policies regarding the collection, use, processing, and disclosure of Personal Information
          received from RHU representatives, employees, staff, and affiliates who are users of the PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          SERVICE PROVIDER uses the Personal Information of RHU staff only for providing excellent services and
          completion of the required information for the operation of the PLATFORM and for its improvement. By using the
          PLATFORM, RHU agrees to the collection, and use of information of its representatives, employees, staff, and
          affiliates, and aggregated data of patients of the RHU in accordance with this policy.
        </Typography>

        <Header title="RHU REPRESENTATIVE INFORMATION COLLECTION AND USE" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          While using the PLATFORM, SERVICE PROVIDER may ask the RHU to provide with certain personally identifiable
          information of its representatives, employees, staff, and affiliates that can be used to contact or identify
          the RHU. Personally identifiable information may include but is not limited to RHU representative or employee
          name, address, e-mail address, and contact number.
        </Typography>

        <Header title="LOG DATA" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU acknowledges that SERVICE PROVIDER may collect information from the browser whenever a user visits the
          PLATFORM.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that Log Data may include information such as the user's computer's Internet Protocol ("IP")
          address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit,
          the time spent on those pages, and other statistics.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          In addition, RHU understands that SERVICE PROVIDER may use necessary third-party services such as but not
          limited to, Amazon Web Services, ADSPARK m360 platform, and other statistical tools to collect, monitor,
          analyze the trends, browsing in our web application, requests, maintenance, security, and other necessities to
          be provided for continued usage. The PLATFORM’s Privacy Policy follows the ADSPARK INC.’s Privacy Policy and
          Terms of Use for the SERVICE PROVIDER’S USE of its API.
        </Typography>

        <Header title="COMMUNICATIONS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU understands that the SERVICE PROVIDER may use RHU employees’ Personal Information to contact with
          newsletters, promotional materials, updates, and other information that the SERVICE PROVIDER sees would be of
          the RHU’s interest or benefit, from which any time the RHU employees or staff may opt out.
        </Typography>

        <Header title="SECURITY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          The security of RHU employees’ and representatives' Personal Information is important to CP HEALTH INNOVATIONS
          INC., but as no method of transmission over the Internet, or method of electronic storage is 100% secure, the
          RHU recognizes that by using the PLATFORM, it holds the SERVICE PROVIDER free from liability or damages,
          direct or indirect, that may arise from using the PLATFORM. While SERVICE PROVIDER strives to use commercially
          acceptable means to protect Personal Information, we cannot guarantee its absolute security. By using our
          Site, you hold the Company harmless from any liabilities that may arise from any forms of damage including but
          not limited to a leak or hacking of the Site.
        </Typography>

        <Header title="PATIENT ENROLLMENT TO SMS REMINDERS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that enrollment of patients to receive SMS reminders or notifications through the PLATFORM
          needs to be with consent of corresponding individuals for data processing of SERVICE PROVIDER. Any data
          uploaded by RHU will be treated by SERVICE PROVIDER to be consented for processing.
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU acknowledges and holds the SERVICE PROVIDER harmless from any forms of liabilities that may arise from
          processing unconsented data. RHU understands that as a government agency that focuses on delivering quality
          healthcare to its beneficiaries, its responsibility includes health data transparency to its served
          populations on to where it is being deposited. RHU recognizes that data collected by SERVICE PROVIDER in the
          web application is only limited to non-identifiable information that may be processed in the SERVICE
          PROVIDER’s electronic infrastructure, third-party storage and/or SMS providers, and data being processed
          including but not limited to purposes for academic, service improvement, development, and research of
          non-profit partner organizations, and relevant government agencies. RHU recognizes that CP HEALTH INNOVATIONS
          INC. is a Filipino company that serves the RHU through its PLATFORM, and related health informatics services,
          which only uses non-personally identifiable information.
        </Typography>

        <Header title="DATA PROCESSING" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          The agreement for data processing will commence on the date as described above for this service subscription
          agreement. SERVICE PROVIDER warrants to maintain the RHU’s information security and data privacy policies
          limited only in the PLATFORM provided, and agree that this agreement may be suspended if, despite due
          diligence, the RHU is exposed to a security breach, personnel and patient data breach, and/or if undergoing or
          will undergo an investigation by the National Privacy Commission.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          The PARTIES agree that Personal and RHU data collection, use, and storage may be not within the Republic of
          the Philippines as successful delivery of services may need foreign third-party services availed by the
          PARTIES.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          Uploaded data of patients of RHU are considered as data consented for processing and analysis of aggregated
          data that may be used for but not limited to improvement of the services, service development, and public
          health studies of the SERVICE PROVIDER and future affiliates or partners. RHU understands that Data Processing
          of personally identifiable information will be of its sole responsibility and that the SERVICE PROVIDER will
          NOT access any personally identifiable information uploaded in the PLATFORM, unless authorized in specific
          instances by the RHU.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU acknowledges that aggregated non-identifiable data uploaded in the PLATFORM can be processed by SERVICE
          PROVIDER to be used for purposes that will advance the improvement of the public healthcare system as defined
          by the representatives, employees, staff, and beneficiaries of the SERVICE PROVIDER.
        </Typography>

        <Header title="DATA SHARING AND ACCESS" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          RHU acknowledges that the details of service to patients, aggregated non-identifiable patient data, and
          including but not limited to usage behavior limited to the PLATFORM of each account and the RHUs may be used
          by the SERVICE PROVIDER.
        </Typography>

        <Header title="DATA BREACH REPORT" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU understands that any health data breach accessed outside the PLATFORM during the time of breach are out of
          liability, and responsibility of the SERVICE PROVIDER. RHU acknowledges that breached access to RHU health
          data storage either outside or inside the PLATFORM that involves at least 100 individuals obliges the RHU
          representatives, employees, and staff, to notify CP HEALTH INNOVATIONS INC. Delay of notification by the RHU
          of the data breach holds CP HEALTH INNOVATIONS INC. free from any liability to the RHU.
        </Typography>

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          RHU recognizes the CP HEALTH INNOVATIONS INC. security breach response team led by its Data Protection Officer
          to be notified within 48 hours of detected security breach on health data. RHU comprehends that breached
          access to RHU health data storage or its local storage system, either physical or electronic that may be
          outside the PLATFORM and involves at least 100 individuals, obliges the RHU representatives, employees, and
          staff, to notify CP HEALTH INNOVATIONS INC. representatives.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          CP HEALTH INNOVATIONS INC. will notify the RHU for any unauthorized data breach detected in the PLATFORM. CP
          HEALTH INNOVATIONS INC. enforces data security seriously and uses industry-standard practices to ensure
          safety, highest accuracy, intact integrity of data, from the health workers to RHU representatives, partners,
          and beneficiaries. In case that despite the enforcement of such best practices that the PLATFORM was
          penetrated by hacking and other malicious intents, resulting to unauthorized access to health data of at least
          100 people, CP HEALTH INNOVATIONS INC. will follow strictly the guidelines and provisions set by the DATA
          PRIVACY ACT OF 2012 and notify within 72 hours of knowledge the RHU affected, National Privacy Commission
          (NPC), and other relevant government agencies and stakeholders. After notification, an internal investigation
          will be pursued by CP HEALTH INNOVATIONS INC. which may also include suspension of PLATFORM services,
          depending on the recommendations of the NPC and SERVICE PROVIDER technology team.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          Any services of SERVICE PROVIDER paid and confirmed prior to the declared date of suspension of services to
          the RHU will be computed to be returned to the affected RHU on a prorated basis.
        </Typography>

        <Header title="CHANGES TO PRIVACY POLICY" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          This Privacy Policy is effective as of July 1, 2022, and will remain in effect except with respect to any
          changes in its provisions in the future, which will be in effect immediately after being posted on this page.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          SERVICE PROVIDER reserves the right to update or change the Privacy Policy for the PLATFORM at any time RHU
          representatives, employees, and affiliates should check this Privacy Policy periodically. The RHU’s continued
          use of the PLATFORM after SERVICE PROVIDER post any modifications to the Privacy Policy will constitute RHU’s
          acknowledgment of the modifications and consent to abide and be bound by the modified Privacy Policy.
        </Typography>
        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 60px', lineHeight: '28px' }}>
          If SERVICE PROVIDER makes any material changes to this Privacy Policy, RHU representative/s will be notified
          either through the email address provided or by placing a prominent notice on our website www.caregoemr.com or
          www.caregoemrsms.app.
        </Typography>

        <Header title="CONTACT US" />

        <Typography variant="h5" display="block" align="justify" sx={{ padding: '0 20px 25px', lineHeight: '28px' }}>
          For any questions about this Privacy Policy, please contact CP HEALTH INNOVATIONS INC. Data Protection Officer
          at tech@cphealthinnovations.com or +63 917 328 1202.
        </Typography>
      </Box>
    </CustomContainer>
  );
};

export default TermsOfUse;
