import { Box } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CustomModal from './CustomModal';
import RegularButton from '../buttons/RegularButton';
import styled from 'styled-components';

type SuccessModalProps = {
  openModal: boolean;
  fromPos?: boolean;
  successMessageComponent?: React.ReactNode;
  subSuccessMessageComponent?: React.ReactNode;
  setOpenModal: (state: boolean) => void;
  handleOkayCallback?: () => void;
  handleViewDetailsCallback?: () => void;
};

const SuccessModal: React.FC<SuccessModalProps> = ({
  openModal,
  fromPos,
  subSuccessMessageComponent,
  successMessageComponent,
  handleOkayCallback,
  handleViewDetailsCallback,
  setOpenModal,
}) => {
  return (
    <CustomModal open={openModal} setOpen={setOpenModal} hideClose={true} noHeader={true} width={450}>
      <Container>
        <CheckCircleRoundedIcon sx={{ fontSize: '80px', color: '#02BA57' }} />
        <SubContainer>{successMessageComponent}</SubContainer>
        <SubContainer>{subSuccessMessageComponent}</SubContainer>
        <Box display="flex" gap="10px" width="100%" alignItems="center" justifyContent="center">
          <RegularButton
            fullWidth
            label="Okay"
            onClick={() => handleOkayCallback && handleOkayCallback()}
            styles={{ width: '100%' }}
          />

          {fromPos && (
            <RegularButton
              fullWidth
              label="View Details"
              onClick={() => handleViewDetailsCallback && handleViewDetailsCallback()}
            />
          )}
        </Box>
      </Container>
    </CustomModal>
  );
};

export default SuccessModal;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 30px;
  width: auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const SubContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
