/* eslint-disable react-hooks/exhaustive-deps */
import { AccountType, FacilityModel } from 'core/model/Entities';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';

import AccountingAppLayout from 'core/layout/AccountingAppLayout';
import AdminAppLayout from 'core/layout/AdminAppLayout';
import Cookies from 'js-cookie';
import { FacilityContext } from 'core/context/facility.context';
import GuidedTours from 'core/screens/Helper/GuidedTour/GuidedTours';
import InventoryAppLayout from 'core/layout/InventoryAppLayout';
import Layout from 'core/layout';
import { LoadingScreen } from 'core/screens';
import { UserContext } from 'core/context/user.context';
import { getCurrentUser } from 'core/api/user';
import { isDevMode } from './HideOrShowComponent';

type ProtectedRouteProps = {
  requiredAuth?: string[];
  requiredRole?: string[];
  element?: JSX.Element;
  accountType?: AccountType;
  inventory?: boolean;
  parent?: boolean;
};

export const updateFacilityServices = (setFacility: any) => {
  getCurrentUser().then((res) => {
    const assigned_facilities: FacilityModel[] = res.data.facilities;
    const activeFacilityIdFromStorage = localStorage.getItem('selectedClinicId');
    let activeFacility;

    if (activeFacilityIdFromStorage === '-1') {
      setFacility({ id: -1, facility_name: 'Admin Mode', facility_short_name: 'Admin Mode', is_admin: true });
      return;
    }

    if (activeFacilityIdFromStorage) {
      activeFacility = assigned_facilities.find((facility) => facility.id === parseInt(activeFacilityIdFromStorage));
    }

    if (!activeFacility) {
      activeFacility = assigned_facilities[0];
    }

    setFacility(activeFacility);
  });
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredAuth,
  requiredRole,
  element,
  accountType,
  inventory,
  parent,
}) => {
  const auth = Cookies.get('access_token');
  const { user, setUser } = useContext(UserContext);
  const { facility, setFacility } = useContext(FacilityContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCurrentUser();
        const currentUser = res.data;
        setUser(currentUser);

        if (currentUser.account_type === AccountType.COMPANY) {
          const assigned_facilities: FacilityModel[] = currentUser.facilities;
          const activeFacilityIdFromStorage = localStorage.getItem('selectedClinicId') as string;
          let activeFacility;

          if (activeFacilityIdFromStorage === '-1') {
            if (currentUser.user_group.group_name === 'COMPANY_ADMIN') {
              setFacility({
                id: -1,
                facility_name: 'Admin Mode',
                facility_short_name: 'Admin Mode',
                is_admin: true,
              } as any);
            } else {
              if (assigned_facilities.length) {
                setFacility(assigned_facilities[0]);
                localStorage.setItem('selectedClinicId', assigned_facilities[0].id.toString());
              }
            }
            return;
          }

          if (activeFacilityIdFromStorage) {
            activeFacility = assigned_facilities.find(
              (facility) => facility.id === parseInt(activeFacilityIdFromStorage)
            );
          }

          if (!activeFacility) {
            activeFacility = assigned_facilities[0];
          }

          localStorage.setItem('selectedClinicId', activeFacility.id.toString());
          setFacility(activeFacility);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (auth && !user) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    checkUserAuthority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, facility, inventory, auth, requiredAuth, requiredRole]);

  const checkUserAuthority = () => {
    if (!requiredAuth && !requiredRole) {
      setLoading(false);
      setVerified(!!auth);
    }

    if (user && facility) {
      if (location.pathname.startsWith('/company') && user.account_type !== AccountType.COMPANY) {
        if (user.account_type === AccountType.CAREGO) navigate('/admin');
        if (user.account_type === AccountType.LGU) navigate('/');
        return;
      }

      if (facility.is_admin && !location.pathname.startsWith('/company/admin')) {
        if (user.user_group.group_name !== 'COMPANY_ADMIN') {
          navigate('/company/forbidden');
          return;
        }
        navigate('/company/admin');
        return;
      }

      if (
        (location.pathname.startsWith('/company/admin') && facility?.id !== -1) ||
        (inventory && !user.company?.with_inventory)
      ) {
        navigate('/company');
        return;
      }

      if (requiredRole) {
        const verified_roles = requiredRole.filter((r) => user.user_group.group_name === r);
        setLoading(false);
        setVerified(!!verified_roles.length);
      } else if (requiredAuth) {
        const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));
        setLoading(false);
        setVerified(!!verified_auths.length || !isDevMode());
      }
    }
  };

  const displayElement = useMemo(() => {
    return (requiredAuth || requiredRole) && !parent ? (
      <>{element ?? <Outlet />}</>
    ) : location.pathname.startsWith('/company/inventory') ? (
      <InventoryAppLayout>
        <Outlet />
      </InventoryAppLayout>
    ) : location.pathname.startsWith('/company/accounting') ? (
      <AccountingAppLayout>
        <Outlet />
      </AccountingAppLayout>
    ) : location.pathname.startsWith('/company/admin') ? (
      <AdminAppLayout>
        <Outlet />
      </AdminAppLayout>
    ) : (
      <Layout>
        <Outlet />
        <GuidedTours />
      </Layout>
    );
  }, [requiredAuth, requiredRole, parent, location]);

  if (!loading && !verified) {
    return (
      <Navigate
        to={
          requiredAuth || requiredRole
            ? !accountType || accountType === AccountType.COMPANY
              ? '/company/forbidden'
              : '/forbidden'
            : accountType === AccountType.CAREGO
            ? '/admin-login'
            : '/login'
        }
        state={{ redirectTo: location }}
      />
    );
  }

  return <LoadingScreen loading={loading}>{displayElement}</LoadingScreen>;
};

export default ProtectedRoute;
