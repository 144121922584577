import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';

export const HAB_ENTITY_NAME = 'Health Assessment Batch';

export const batchSchema = yup.object().shape({
  batch_name: yup.string().required('Batch Name is required').max(100, 'Batch name should not exceed 100 characters'),
});

export const batchFields: EntityFields[] = [
  { fieldName: 'batch_name', displayName: 'Batch Name' },
  {
    fieldName: 'corporate_client_id',
    displayName: 'Corporate Client',
    type: DROPDOWN_FIELD.CORPORATE_CLIENT,
    optional: true,
  },
  {
    fieldName: 'service_group_id',
    displayName: 'Service Group',
    type: DROPDOWN_FIELD.SERVICE_GROUP,
    optional: true,
  },
];

export interface HealthAssessmentBatchInput {
  batch_name: string;
  corporate_client_id: string;
  service_group_id: string;
}

export const batchInitialValues: HealthAssessmentBatchInput = {
  batch_name: '',
  corporate_client_id: '',
  service_group_id: '',
};

export interface HealthAssessmentBatchModel {
  id: number;
  company_id: number;
  batch_name: number | string;
  service_group_id: number;
  corporate_client_id: number;
  company_name: string;
  group_name: string;
  service_names: string;
}

// table
export const batchColumnTable: string[] = ['batch_name', 'corporate_client_id'];

// form
export const batchFormFields: string[] = ['batch_name', 'corporate_client_id', 'service_group_id'];
