import {
  PG_ENTITY_NAME,
  company_tags_schema,
  patientGroupFields,
  patientGroupFormFields,
  patientGroupinitialValues,
} from './PatientGroupModel';
import { createCompanyTag, updateCompanyTag } from 'company/api/company-tags';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  facility: FacilityModel;
  pGroup?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PatientGroupForm = (props: Props) => {
  const { facility, pGroup, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    data.category_name = 'client_tag';
    return pGroup ? updateCompanyTag(facility.id, data.id, data) : createCompanyTag(facility.id, data);
  };

  return (
    <TemplateForm
      submitButtonId={'add_patient_group_submit_button'}
      entityName={PG_ENTITY_NAME}
      fields={patientGroupFields}
      visibleFields={patientGroupFormFields}
      entity={pGroup}
      initialValues={pGroup ? changeNullToBlank(pGroup) : patientGroupinitialValues}
      schema={company_tags_schema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default PatientGroupForm;
