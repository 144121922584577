import { Box } from '@mui/material';
import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { updateScreeningDetails } from 'lgu/api/tb-acf';
import { update_screening_schedule_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type ScreeningDetailsBatchProps = {
  patients: string;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

type UserInput = {
  screening_test_schedule: string;
  screening_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  screening_test_schedule: '',
  screening_test_venue: '',
};

const ScreeningDetailsBatch: React.FC<ScreeningDetailsBatchProps> = ({ patients, openModal, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const screeningFormFields: UserFieldInput[] = [
    {
      field_name: 'screening_test_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
    },
    { field_name: 'screening_test_venue', display_name: 'Venue', type: 'text' },
  ];

  const updateScreening = (data: any) => {
    setButtonLoading(true);

    const patientData = {
      patients: patients,
      screening_test_schedule: data.screening_test_schedule,
      screening_test_venue: data.screening_test_venue,
    };

    updateScreeningDetails(patientData)
      .then((res) => {
        setButtonLoading(false);
        enqueueSnackbar('Screening Details successfully saved!', { variant: 'success' });
        openModal(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomForm
          onSubmit={updateScreening}
          schema={update_screening_schedule_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={screeningFormFields}
          submitButtonText={'Save'}
        />
      </Box>
    </>
  );
};

export default ScreeningDetailsBatch;
