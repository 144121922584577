import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deactivateSignatory, getSignatories, getSignatory } from 'company/api/signatories';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ClinicsTable from '../ClinicManagement/components/ClinicsTable';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { SIZES } from 'theme/constants';
import SignatoryForm from 'company/entities/modules/ClinicManagement/Signatory/SignatoryForm';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export interface SignatoryResponse {
  id: number;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  title: string;
  signature: string;
  is_active: boolean;
  assigned_facilities: string;
  assigned_facilities_ids: string;
  company_id: number;
  created_by: number;
  created_at: string;
  updated_at: string;
  full_name: string;
  full_name_with_title: string;
}

type SignatoriesProps = {
  selectedTab?: number;
  facilities?: any[];
};

const Signatories: React.FC<SignatoriesProps> = ({ selectedTab, facilities }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSignatory, setSelectedSignatory] = useState<number>();
  const [rawSignatory, setRawSignatory] = useState<SignatoryResponse>();
  const [toUpdateSignatory, setToUpdateSignatory] = useState<SignatoryResponse>();

  const [openAddSignatory, setOpenAddSignatory] = useState(false);
  const [openDeleteSignatory, setOpenDeleteSignatory] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);

  const [addedSignatory, setAddedSignatory] = useState<number>();
  const [updatedSignatory, setUpdatedSignatory] = useState<SignatoryResponse>();
  const [deletedSignatory, setDeletedSignatory] = useState<number>();
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const getData = (query: ApiQuery) => {
    return getSignatories(query);
  };

  const handleDelete = () => {
    if (selectedSignatory) {
      deactivateSignatory(selectedSignatory).then((response: any) => {
        setDeletedSignatory(selectedSignatory);
        setOpenDeleteSignatory(false);
        enqueueSnackbar('Signatory successfully deactivated!', { variant: 'success' });
      });
    }
  };

  const refreshSignatoryDetails = () => {
    if (selectedSignatory) {
      getSignatory(selectedSignatory).then((response: any) => {
        setRawSignatory(response.data);
      });
    }
  };

  const handleSignatorySubmit = (data: any) => {
    setOpenAddSignatory(false);
    setToUpdateSignatory(undefined);
    setRefreshTrigger((prev) => prev + 1);

    if (toUpdateSignatory) {
      setUpdatedSignatory(data.company_signatory);
      setRawSignatory(data.company_signatory);
    } else {
      setAddedSignatory(data.company_signatory.id);
      setSelectedSignatory(data.company_signatory.id);
      setRawSignatory(data.company_signatory);
    }
  };

  useEffect(() => {
    refreshSignatoryDetails();
  }, [selectedSignatory, updatedSignatory, refreshTrigger, addedSignatory]);

  useEffect(() => {
    async function getSignatoryDetails() {
      if (!selectedSignatory) return;
      const res = await getSignatory(selectedSignatory);
      setRawSignatory(res.data);
    }
    getSignatoryDetails();
  }, [selectedSignatory]);

  const displayItem = (item: SignatoryResponse) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedSignatory && !isMobilePhone ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.full_name_with_title}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <InfiniteScrollContainer
        key={refreshTrigger}
        urlKeyId="sig_id"
        title="List of Signatories"
        getData={getData}
        getSingleData={getSignatory}
        renderItem={displayItem}
        selected={selectedSignatory}
        setSelected={setSelectedSignatory}
        addedNewItem={addedSignatory}
        updatedItem={updatedSignatory}
        deleteId={deletedSignatory}
        containerHeight="calc(100vh - 170px)"
        headerComponents={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '100%' }}>
            {!isMobilePhone && (
              <RegularButton
                startIcon={<AddIcon />}
                variant="outlined"
                label="Add Signatory"
                onClick={() => setOpenAddSignatory(true)}
                styles={{ width: '100%' }}
              />
            )}
          </Box>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {!isMobilePhone ? rawSignatory?.full_name_with_title : 'Signatory'}
          </Typography>
        )}
        actions={
          !isMobilePhone
            ? [
                {
                  icon: <EditOutlinedIcon />,
                  label: 'Update',
                  action: (data) => {
                    setOpenAddSignatory(true);
                    setToUpdateSignatory(rawSignatory);
                  },
                },
                {
                  icon: <DeleteOutlineIcon />,
                  label: 'Delete',
                  color: 'error',
                  action: () => {
                    setOpenDeleteSignatory(true);
                  },
                },
              ]
            : undefined
        }
      >
        {/* Rest of the JSX remains the same */}
        {!!selectedSignatory && (
          <Box>
            {!isMobilePhone ? (
              <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                <PatientField label="First Name" value={rawSignatory?.first_name} />
                <PatientField label="Middle Name" value={rawSignatory?.middle_name || ''} />
                <PatientField label="Last Name" value={rawSignatory?.last_name} />
                <PatientField label="Title" value={rawSignatory?.title} />
                <PatientField label="Signature" value={rawSignatory?.signature} />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '1.25rem', fontWeight: '700', color: colors.accent }}>
                    {rawSignatory?.full_name_with_title}
                  </Typography>
                  <MoreHorizIcon
                    sx={{ color: colors.primary, fontSize: '2rem' }}
                    onClick={() => {
                      setOpenActionModal(true);
                      setToUpdateSignatory(rawSignatory);
                    }}
                  />
                </Box>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>Signatory Details</Typography>
                <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                  <PatientField label="First Name" value={rawSignatory?.first_name} />
                  <PatientField label="Middle Name" value={rawSignatory?.middle_name || ''} />
                  <PatientField label="Last Name" value={rawSignatory?.last_name} />
                  <PatientField label="Title" value={rawSignatory?.title} />
                  <PatientField label="Signature" value={rawSignatory?.signature} />
                </Box>
              </Box>
            )}
            <Box pt={SIZES.paddingL}>
              <ClinicsTable source="Signatories" selectedSignatory={rawSignatory} />
            </Box>
          </Box>
        )}
      </InfiniteScrollContainer>

      <CustomModal
        header={toUpdateSignatory ? 'Update Signatory' : 'Add Signatory'}
        open={openAddSignatory}
        setOpen={setOpenAddSignatory}
        onClose={() => setToUpdateSignatory(undefined)}
      >
        <SignatoryForm signatory={toUpdateSignatory} callbackAfterSubmit={handleSignatorySubmit} />
      </CustomModal>

      {rawSignatory && (
        <ConfirmationDialog
          content={`Are you sure you want to deactivate ${rawSignatory.full_name_with_title}?`}
          open={openDeleteSignatory}
          setOpen={setOpenDeleteSignatory}
          onConfirm={handleDelete}
          bottomDrawerDialog={isMobilePhone}
        />
      )}

      <CustomBottomPopover title="Actions" open={openActionModal} setOpen={setOpenActionModal}>
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <RegularButton
            onClick={() => setOpenAddSignatory(true)}
            label="Update"
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
            fullWidth
            styles={{ border: 'none', backgroundColor: colors.light_blue_background }}
          />
          <RegularButton
            onClick={() => {
              setOpenDeleteSignatory(true);
            }}
            label="Delete"
            startIcon={<DeleteOutlineOutlinedIcon />}
            variant="outlined"
            fullWidth
            styles={{
              color: colors.redAccent,
              backgroundColor: colors.light_red_background_2,
              border: 'none',
            }}
          />
        </Box>
      </CustomBottomPopover>
    </>
  );
};

export default Signatories;
