const frequency = [
    {
        key: '1 hour before',
        value: '1h'
    },
    {
        key: '2 hours before',
        value: '2h'
    },
    {
        key: '4 hours before',
        value: '4h'
    },
    {
        key: '12 hours before',
        value: '12h'
    },
    {
        key: '1 day before (24 hours)',
        value: '1d'
    },
    {
        key: '2 days before (48 hours)',
        value: '2d'
    },
    {
        key: '3 days before (72 hours)',
        value: '3d'
    },
]

const dental_condition = [
    {
        key: 'P',
        value: 'Present'
    },
    {
        key: 'C',
        value: 'Decayed (Caries indicated for filling)'
    },
    {
        key: 'Ex',
        value: 'Indicated for Extraction'
    },
    {
        key: 'M',
        value: 'Missing (extracted due to caries)'
    },
    {
        key: 'MO',
        value: 'Missing due to Other causes'
    },
    {
        key: 'Im',
        value: 'Impacted tooth'
    },
    {
        key: 'SP',
        value: 'Supernumerary tooth'
    },
    {
        key: 'Rf',
        value: 'Root fragment'
    },
    {
        key: 'Un',
        value: 'Unerupted'
    },
]

const status_options = [
    {
      key: 'All',
      value: 'all',
    },
    {
      key: 'Confirmed',
      value: 'pending',
    },
    {
      key: 'Attended',
      value: 'done',
    },
    {
      key: 'Rescheduled',
      value: 'rescheduled',
    },
    {
      key: 'Cancelled',
      value: 'cancelled',
    },
    {
      key: 'No Show',
      value: 'no-show',
    },
  ];

export { frequency, dental_condition, status_options};