/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { createFrequency, deleteFrequency, getCompanyFrequencyList, getFrequencies } from 'company/api/sms_frequency';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import { filterArrayByValue } from 'core/utils';
import { frequency } from '../data/list';
import { sms_frequency_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

type UserInput = {
  frequencies: any[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

type EditFrequencyModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const EditFrequencyModal: React.FC<EditFrequencyModalProps> = ({ open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [companyFrequency, setCompanyFrequency] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);

  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const initialValues: UserInput = {
    frequencies: [],
  };

  const columns: GridColDef[] = [
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      renderCell: (params) => filterArrayByValue(frequency, 'value', params.row.frequency, 'key'),
    },
  ];

  const formFields: UserFieldInput[] = [
    {
      field_name: 'frequencies',
      display_name: 'Frequency',
      type: 'multiselect',
      span: 6,
      options: frequency.filter((option) => {
        return !companyFrequency.some((frequency) => frequency.frequency === option.value);
      }),
    },
  ];

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const refreshTable = () => {
    setForceRefreshTable((prev: number) => prev + 1);
  };

  const getFrequency = (query: ApiQuery) => {
    return getFrequencies(query);
  };

  const getNotSelectedFrequency = () => {
    getCompanyFrequencyList().then((res) => {
      setCompanyFrequency(res.data);
    });
  };

  const handleSubmit = (data: any) => {
    data.frequencies = data.frequencies.map((item: { value: any }) => item.value);

    return createFrequency(data).then(() => {
      enqueueSnackbar('Frequency successfully added!', { variant: 'success' });
      refreshTable();
      getNotSelectedFrequency();
    });
  };

  const showDeleteDialog = (content: string) => {
    if (selectedRows.length) {
      setOpenDeleteDialog(true);
      setAlertContent(content);
    }
  };

  const handleDelete = (data: any) => {
    const frequencyList = JSON.stringify(data ? [data] : selectedRows);
    const frequencies = { frequencies: frequencyList };

    return deleteFrequency(frequencies).then(() => {
      refreshTable();
      getNotSelectedFrequency();
    });
  };

  useEffect(() => {
    getNotSelectedFrequency();
  }, []);

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Frequency',
    tableComponent: {
      columns: columns,
      getData: getFrequency,
      forceRefresh: forceRefreshTable,
      checkboxSelection: true,
      selectableRow: true,
      hideSearch: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          <RegularButton
            onClick={() => {
              if (selectedRows.length) {
                showDeleteDialog('Are you sure you want to delete ' + selectedRows.length + ' frequencies?');
              } else {
                enqueueSnackbar('Invalid action. Select frequency first!', { variant: 'error' });
              }
            }}
            label={'Remove Frequencies'}
            disabled={!selectedRows.length}
            startIcon={<RemoveIcon />}
            color="error"
          />
        </Box>
      ),
    },
    tableForm: {
      formFields: formFields,
      formSchema: sms_frequency_schema,
      formInitialValues: initialValues,
      formSubmitApiFunction: handleSubmit,
      formModalWidth: 500,
    },
    tableAction: {
      includeAddButton: true,
      includeUpdateAction: false,
      includeDeleteAction: true,
      deleteApiFunction: handleDelete,
    },
  } as PageBuilderTableType;

  return (
    <>
      <CustomModal
        header={'Edit Frequency'}
        subHeader={'The default SMS sending time is 24 hours before the appointment if this table is empty.'}
        open={open}
        setOpen={setOpen}
      >
        <PageBuilder content={content} />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default EditFrequencyModal;
