import { PurchaseSMSRequestModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'request-purchase';

export const getPurchaseRequests = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const createPurchaseRequest = (credit: PurchaseSMSRequestModel) => {
  return axios.post(`${baseURL}/${path}`, credit);
};

export const cancelPurchaseRequest = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
