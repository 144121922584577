import { Box, Typography, useMediaQuery } from '@mui/material';
import { formatArray, formatNumber } from 'core/utils';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ReactMarkdown from 'react-markdown';
import { RegularButton } from 'core/components';
import { getFacilityBookingDetails } from 'company/api/facility';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';

type ScheduleType = {
  day: string;
  hours: Array<{
    start: string;
    end: string;
  }>;
};
export type Service = {
  service_name: string;
  price: string;
  id: number;
  banner_image: string;
  description: string;
};
const ClinicLandingPage = () => {
  const navigate = useNavigate();
  const { facility_code } = useParams();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [clinic, setClinic] = useState<any>({});

  const [sched, setSched] = useState<ScheduleType[]>([]);
  // const [showAllPromos, setShowAllPromos] = useState(false);
  const daysOfTheWeek: any = {
    SUN: 'Sunday',
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
  };

  // const handleSeeAllClick = () => {
  //   setShowAllPromos(!showAllPromos);
  // };

  const handleServiceClick = (id: number) => {
    navigate(`/book/appointment/${facility_code}/${id}`);
  };

  const handleBookNowClick = () => {
    document.getElementById('services')?.scrollIntoView({ behavior: 'smooth' });
  };

  const getClinicDetails = async () => {
    if (!facility_code) return;
    try {
      const { data } = await getFacilityBookingDetails(facility_code);
      setClinic(data);
      setSched(JSON.parse(data.schedule));
    } catch (error) {}
  };

  useEffect(() => {
    getClinicDetails();
  }, [facility_code]);

  return (
    <Box width="100%">
      <Box sx={{ minWidth: '100%', margin: '0 auto' }}>
        <Box sx={{ background: 'rgba(7, 104, 176, 0.25)', height: '35vh' }}>
          <Box padding="20px">
            <img height="40px" alt="img" src="/assets/landingassets/logoblacktext.png" />
          </Box>
        </Box>
        <Box width="calc(100% - 40px)" maxWidth="1000px" margin="0 auto">
          <Box
            width="100%"
            borderRadius="20px"
            boxShadow="7.32px 32.534px 33.348px -4.067px rgba(214, 214, 214, 0.25)"
            padding="40px"
            marginTop="-20vh"
            sx={{ background: 'white' }}
          >
            <Box>
              <DetCon>
                <Banner src={clinic.banner_image} />
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '500px',
                    margin: '0 auto',
                    display: 'grid',
                    gridTemplateColumns: '20px 1fr',
                    color: '#797979',
                    gap: '10px',
                    mt: '20px',
                  }}
                >
                  <PhoneAndroidIcon sx={{ fontSize: '18px;' }} />
                  <Typography>
                    {formatArray([clinic.smart_mobile_number, clinic.globe_mobile_number], ' / ')}
                  </Typography>

                  {clinic.email && (
                    <>
                      <EmailIcon sx={{ fontSize: '18px;' }} />
                      <Typography>{clinic.email}</Typography>
                    </>
                  )}

                  <LocationOnIcon sx={{ fontSize: '18px;' }} />
                  <Typography>
                    {formatArray([clinic.address, clinic.municipality_name, clinic.province_name], ', ')}
                  </Typography>
                </Box>
              </DetCon>
            </Box>
          </Box>

          <Box width="100%" display="flex" justifyContent="center" marginTop="-25px">
            <RegularButton
              styles={{
                padding: '15px 40px',
                backgroundColor: '#ef7a35',
                borderRadius: '25px',
                fontFamily: 'Fredoka',
                fontWeight: 'bold',
                fontSize: '15px',
                '&:hover': { backgroundColor: '#d76d2f' },
              }}
              label="Book Now"
              onClick={() => handleBookNowClick()}
            />
          </Box>

          <Box id="services" mt="40px">
            <Typography fontWeight="bold" variant="h3" fontFamily="Fredoka" paddingBottom="20px">
              Clinic's Available Services
            </Typography>
            {clinic.services?.length === 0 ? (
              <Typography textAlign="center" paddingY="20px">
                There is no available service as of now.
              </Typography>
            ) : (
              <Box
                display="grid"
                gridTemplateColumns={isMobilePhone ? '1fr' : '1fr 1fr'}
                gap={isMobilePhone ? '20px' : '30px 60px'}
              >
                {clinic.services?.map((service: any, i: number) => (
                  <Box
                    key={i}
                    style={{
                      alignItems: 'center',
                      border: '1px solid #E0E0E0',
                    }}
                    display="flex"
                    flexDirection="column"
                    gap="10px"
                    padding="15px"
                    borderRadius="12px"
                    width="100%"
                  >
                    <Box
                      display="grid"
                      gridTemplateColumns="80px 1fr"
                      width="100%"
                      gap="20px"
                      borderBottom="1px solid #E0E0E0"
                      paddingBottom="10px"
                    >
                      <img
                        style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '10px' }}
                        alt="s"
                        src={service.banner_image || clinic.banner_image}
                      />
                      <Box>
                        <Typography variant="h4" fontFamily="Fredoka">
                          {service.service_name}
                        </Typography>
                        <Typography variant="h6" fontFamily="Fredoka">
                          <div className="ql-editor" style={{ padding: 0 }}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{service.description}</ReactMarkdown>
                          </div>
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      <Box>
                        <Typography sx={{ color: '#706f6f' }}>
                          Price: <strong>Php {formatNumber(parseFloat(service.price))}</strong>
                        </Typography>
                      </Box>
                      <RegularButton
                        styles={{
                          backgroundColor: '#ef7a35',
                          borderRadius: '25px',
                          fontFamily: 'Fredoka',
                          fontWeight: 'bold',
                          '&:hover': { backgroundColor: '#d76d2f' },
                        }}
                        label="Book Now"
                        onClick={() => handleServiceClick(service.id)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            <Box paddingY="40px">
              <Typography fontWeight="bold" variant="h3" fontFamily="Fredoka" paddingBottom="20px">
                Plan Your Visit
              </Typography>
              <Box>
                <Box
                  sx={{
                    padding: '5px',
                    '@media (min-width: 768px)': {
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      minWidth: '500px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      gap: '10px',
                      color: '#706f6f',

                      maxWidth: '500px',
                      width: '100%',
                    }}
                  >
                    <Box display="flex" justifyContent="center" alignItems="center" gap="10px" mb="20px">
                      <AccessTimeFilledIcon />
                      <Typography fontFamily="Fredoka" variant="h4">
                        Clinic Hours
                      </Typography>
                    </Box>
                    {sched?.map((item, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" width="100%">
                        <Typography fontSize="18px" fontFamily="Fredoka">
                          {daysOfTheWeek[item.day]}
                        </Typography>
                        {item.hours && item.hours.length > 0 ? (
                          item.hours.map((hour, idx) => (
                            <Typography key={idx} fontSize="17px" fontFamily="Fredoka">
                              {hour.start} - {hour.end}
                            </Typography>
                          ))
                        ) : (
                          <Typography fontSize="17px" fontFamily="Fredoka">
                            Closed
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Loc>
              View Location on Google Maps <NearMeIcon />
            </Loc> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClinicLandingPage;

const Banner = styled.img`
  height: 75%;
  max-width: 80%;
  @media (min-width: 768px) {
    height: 75%;
  }
`;

const DetCon = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 80%;

  width: 100%;
  @media (min-width: 768px) {
    gap: 20px;
  }
`;
