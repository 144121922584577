import styled from 'styled-components';
export const Header = styled.div`
  font-size: 35px;
  font-weight: bold;
  width: 90%;
  font-family: Fredoka;
  color: white;
  @media (max-width: 1500px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const HeaderMobile = styled.div`
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  width: 95%;
  font-family: Fredoka;
  color: white;
  @media (max-width: 1500px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
