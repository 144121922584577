import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const PNT_ENTITY_NAME = 'Patient Note Template';

export const createPatientNoteSchema = yup.object().shape({
  template_name: yup.string().required('Template Name is required.'),
});

export interface PatientNoteTemplateModel {
  id: number;
  carego_template_id: string;
  template_name: string;
  header: string;
  body: string;
  is_active: string;
  collection_id: number;
  table_format?: string[];
}

export interface PatientNoteTemplateInput {
  template_name: string;
  collection_id: string;
  order: string;
}

export interface PatientNoteTemplateFieldInput extends FieldInput {
  field_name?: keyof PatientNoteTemplateInput;
}

export const patientNoteTemplateInitialValues: PatientNoteTemplateInput = {
  template_name: '',
  collection_id: '',
  order: '',
};

// fieldName should be unique
export const patientNoteTemplateFields: EntityFields[] = [
  { fieldName: 'carego_template_id', displayName: 'CareGo Template Name', type: 'select', options: [] },
  { fieldName: 'template_name', displayName: 'Template Name' },
  { fieldName: 'order', displayName: 'Order' },
  { fieldName: 'collection_name', displayName: 'Collection Name' },
  { fieldName: 'header', displayName: 'Header' },
  { fieldName: 'body', displayName: 'Body' },
  { fieldName: 'footer', displayName: 'Footer' },
  { fieldName: 'is_active', displayName: 'Active Status' },
  { fieldName: 'collection_id', displayName: 'Collection Name', type: 'select', options: [] },

  { fieldName: 'divider_1', type: 'divider' },
];

// Table
export const patientNoteTemplateTableColumns: string[] = ['header', 'collection_name', 'order'];

// Forms
export const patientNoteTemplateFormFields: string[] = ['template_name', 'collection_id'];
