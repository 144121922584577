import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { formatDate } from 'core/utils';
import { useTheme } from '@mui/material';

interface Props {
  analytics: any[];
  startDate?: string;
  endDate?: string;
}

const AnalyticsBarChart = ({ analytics, startDate, endDate }: Props) => {
  const theme = useTheme();
  const valueFormatter = (value: number | null) => {
    return `${value} request(s)`;
  };

  return (
    <Box p={2} borderRadius={'10px'} border={`1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`}>
      <Box p={1}>
        <Typography align="center" variant="h3" fontWeight="bold" gutterBottom>
          {`Features used from ${formatDate(startDate)} - ${formatDate(endDate)}`}
        </Typography>
      </Box>
      <BarChart
        dataset={analytics}
        grid={{ horizontal: true }}
        borderRadius={10}
        series={[
          { dataKey: 'Patch update', stack: 'single', label: 'Patch Update', valueFormatter, color: '#cca647' },
          { dataKey: 'Create', stack: 'single', label: 'Create', valueFormatter, color: '#529144' },
          { dataKey: 'Update', stack: 'single', label: 'Update', valueFormatter, color: '#4070ad' },
          { dataKey: 'Delete', stack: 'single', label: 'Delete', valueFormatter, color: '#bd4040' },
          { dataKey: 'View', stack: 'single', label: 'View', valueFormatter, color: '#7b3b94' },
        ]}
        margin={{ top: 10, bottom: 45, left: 45, right: 10 }}
        yAxis={[{ label: 'Number of Requests' }]}
        xAxis={[{ scaleType: 'band', dataKey: 'feature', label: 'API Features' }]}
        slotProps={{ legend: { hidden: true } }}
        width={1500}
        height={400}
      />
    </Box>
  );
};

export default AnalyticsBarChart;
