import TemplateForm from 'company/entities/Template/TemplateForm';

import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

import { createAppointmentLegend, updateAppointmentLegend } from 'company/api/appointment-legends';
import {
  APL_ENTITY_NAME,
  appointmentLegendFields,
  appointmentLegendFormFields,
  AppointmentLegendInitialValues,
  AppointmentLegendModel,
  createAppointmentLegendSchema,
} from './AppointmentLegendModel';

type Props = {
  readonly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  legend?: AppointmentLegendModel;
};

const AppointmentLegendForm = (props: Props) => {
  const { readonly, showSubmitAtTop, callbackAfterSubmit, legend } = props;
  const { facility } = useContext(FacilityContext);

  async function handleSubmit(data: any) {
    return !!legend
      ? updateAppointmentLegend(facility.id, legend.id, data)
      : createAppointmentLegend(facility.id, data);
  }

  return (
    <TemplateForm
      schema={createAppointmentLegendSchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={APL_ENTITY_NAME}
      fields={appointmentLegendFields}
      initialValues={!!legend ? legend : AppointmentLegendInitialValues}
      visibleFields={appointmentLegendFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default AppointmentLegendForm;
