import TemplateDrawer from 'company/entities/Template/TemplateDrawer';
import { ReactNode, useRef } from 'react';
import { CHART_OF_ACCOUNT_ENTITY_NAME } from './ChartOfAccountModel';
import ChartOfAccountForm from './ChartOfAccountForm';

type Props = {
  childComponent?: ReactNode;
  setDrawerSelectedRow?: any;
  drawerSelectedRow?: any;
};

const ChartOfAccountDrawer: React.FC<Props> = (props) => {
  const { childComponent, drawerSelectedRow, setDrawerSelectedRow } = props;

  const tableRef: any = useRef();
  const handleDisplayChildSubHeader = (data: any) => {
    return data.account_name;
  };

  const callbackAfterSubmit = () => {
    tableRef.current.closeFormModal();
    tableRef.current.refreshTable();
  };

  return (
    <TemplateDrawer
      tableRef={tableRef}
      entityName={CHART_OF_ACCOUNT_ENTITY_NAME}
      externalSetDrawerSelectedRow={setDrawerSelectedRow}
      displayChildSubHeader={handleDisplayChildSubHeader}
      childComponent={
        childComponent ?? (
          <ChartOfAccountForm selectedRow={drawerSelectedRow} callbackAfterSubmit={callbackAfterSubmit} />
        )
      }
    />
  );
};

export default ChartOfAccountDrawer;
