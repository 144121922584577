import { USER_ACCESS_ENTITY_NAME, UserAccessFields, UserAccessModel, UserAccessTableColumn } from './UserAccessModel';
import { deactivate, getUserAccounts } from 'company/api/users';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import TemplateTable from '../../../Template/TemplateTable';
import UserAccessForm from './UserAccessForm';
import { WaitForFacility } from 'company/components';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow: (row: UserAccessModel) => void;
  drawerSelectedRow?: UserAccessModel;
  tableRef?: any;
};

const UserAccessTable = forwardRef((props: Props, ref: any) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<UserAccessModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const getData = (apiQuery: ApiQuery) => {
    return getUserAccounts(facility.company_id, apiQuery).then((response) => {
      const responseData = response.data.data;
      const updatedResponseData = responseData.map((resp: any) => {
        resp['facilities'] = [];
        return resp;
      });

      response.data.data = updatedResponseData;

      return response;
    });
  };

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        tableComponent={{
          mobileViewConfig: () => ({
            title_fields: ['full_name'],
            subtitle_fields: ['username', 'group_name'],
          }),
        }}
        ref={mainRef}
        entityName={USER_ACCESS_ENTITY_NAME}
        fields={UserAccessFields}
        getData={getData}
        handleDelete={deactivate}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        visibleColumns={visibleColumns ?? UserAccessTableColumn}
        hideAddButton
        templateForm={<UserAccessForm userAccess={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      />
    </WaitForFacility>
  );
});

export default UserAccessTable;
