import { Box, useMediaQuery } from '@mui/material';

import styled from 'styled-components';

const Scroll3 = () => {
  const isMobile = useMediaQuery('(max-width:768px)');

  return isMobile ? (
    <MainContainer>
      <Container>
        <Content>
          <Div>
            <Box
              fontSize="35px"
              fontWeight="bold"
              sx={{
                '@media screen and (max-width: 768px)': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                },
              }}
            >
              <Test>
                <span
                  style={{
                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    display: 'inline-block',
                    textAlign: 'center',
                    marginTop: '40px',
                  }}
                >
                  Remarkable results for our <br />
                  partner clinics
                </span>
              </Test>
              <Paragraph>
                <Con3>
                  Discover the remarkable results our partner clinics have achieved, showcasing the success and
                  improvements <br />
                  brought about through our collaborative efforts and <br /> innovative solutions.
                </Con3>
                <Box sx={{ display: 'flex ', alignItems: 'start', columnGap: '15px', marginTop: '20px' }}>
                  <Images src="./assets/LandingMobile/1-icon_healthcare.png" alt="Logo" />
                  <Box
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '30px',
                    }}
                  >
                    23%
                  </Box>
                </Box>
                <Box sx={{ fontSize: '16px', marginTop: '20px', color: '#6D6D6D', marginBottom: '20px' }}>
                  Increased availed clinic services
                </Box>
                <Box sx={{ display: 'flex ', alignItems: 'start', columnGap: '15px', marginTop: '20px' }}>
                  <Images src="./assets/LandingMobile/2-icon_patients.png" alt="Logo" />
                  <Box
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '30px',
                    }}
                  >
                    100%
                  </Box>
                </Box>
                <Box sx={{ fontSize: '16px', marginTop: '20px', color: '#6D6D6D', marginBottom: '20px' }}>
                  Increased patient retention{' '}
                </Box>
                <Box sx={{ display: 'flex ', alignItems: 'start', columnGap: '15px', marginTop: '20px' }}>
                  <Images src="./assets/LandingMobile/3-icon_patients.png" alt="Logo" />
                  <Box
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '30px',
                    }}
                  >
                    1,704
                  </Box>
                </Box>
                <Box
                  sx={{
                    fontSize: '16px',
                    marginTop: '20px',
                    color: '#6D6D6D',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Increased number of patients served <br />
                  by CareGo Health Suite
                </Box>
                <Images
                  src="./assets/LandingMobile/PH MAP.png"
                  alt="Logo"
                  style={{ height: '340px', width: '250px', marginLeft: '300px' }}
                />
              </Paragraph>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center"></Box>
          </Div>
        </Content>
      </Container>
    </MainContainer>
  ) : (
    <MainContainer>
      <Container>
        <Content>
          <Div>
            {window.innerWidth <= 768 && <ImagePic src="./assets/Landing2/p3/staff.gif" />}
            <Box
              fontSize="35px"
              fontWeight="bold"
              sx={{
                '@media screen and (max-width: 768px)': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                },
              }}
            >
              <Images src="./assets/Landing2/p2/Waves.png" alt="Logo" />
              <Test>
                <span
                  style={{
                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    display: 'inline-block',
                  }}
                >
                  Remarkable Results
                </span>{' '}
                for our
              </Test>
              partner clinics
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Paragraph>
                <Con3>
                  <ImageContainer>
                    <Image src="./assets/Landing2/p2/icon5.svg" alt="Logo" />
                    <Contain>
                      <TextContainer>
                        <ImageText>Increased availed clinic services by 23%.</ImageText>
                      </TextContainer>
                    </Contain>
                  </ImageContainer>
                </Con3>
                <Con3>
                  <ImageContainer>
                    <Image src="./assets/Landing2/p2/icon6.svg" alt="Logo" />
                    <Contain>
                      <TextContainer>
                        <ImageText>Increased patient retention up to 100%.</ImageText>
                      </TextContainer>
                    </Contain>
                  </ImageContainer>
                </Con3>
                <Con3>
                  <ImageContainer>
                    <Image src="./assets/Landing2/p2/icon7.svg" alt="Logo" />
                    <Contain>
                      <TextContainer>
                        <ImageText>
                          Improved relationships with patients in the clinic experience by reducing waiting times and
                          clinic overcrowding during appointments.
                        </ImageText>
                      </TextContainer>
                    </Contain>
                  </ImageContainer>
                </Con3>
              </Paragraph>
              {window.innerWidth >= 768 && <ImagePic src="./assets/Landing2/p3/staff.gif" />}
            </Box>
          </Div>
        </Content>
      </Container>
    </MainContainer>
  );
};

export default Scroll3;
const Contain = styled.div`
  height: 160px;
  width: 100%;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: 0;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(253, 253, 253, 0.23) 0px 3px 6px;
  @media (max-width: 1900px) {
    height: 120px;
    width: 100%;
    padding: 30px 30px;
  }
  @media (max-width: 768px) {
    width: 300px;
    height: 95px;
    background-color: white;
  }
`;
const ImageContainer = styled.div`
  height: 90%;
  width: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 5%;
    z-index: 5;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  padding: 20px 60px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  @media (max-width: 1900px) {
    gap: 10px;
  }
`;

const Test = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: bold;

  gap: 10px;
  @media (max-width: 1700px) {
    gap: 8px;
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 30px;
  }
`;

const ImageText = styled.p`
  margin: 0;
  font-size: 17px;
  color: #000;
  @media (max-width: 1700px) {
    font-size: 14px;
  } /* Adjust the text color as needed */
  @media (max-width: 768px) {
    font-size: 12px;
    width: 300px;
    z-index: 5;
  }
`;
const MainContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  position: relative;

  justify-content: center;
  position: relative;
  background-image: url('./assets/Landing2/p3/des.png');
  background-size: cover;
  background-position: center;
  background-size: 100% 95%; /* Set your desired height here */
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-image: url('./assets/Landing2/p3/mobile.png');
    z-index: -10;
    height: 105vh;
    background-image: none;
    margin-top: 50px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  gap: 5%;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const Div = styled.div`
  display: flex;

  width: 50%;
  gap: 30px;
  flex: 3;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const Paragraph = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Con3 = styled.div`
  display: flex;

  width: 85%;
  height: 200px;
  position: relative;
  justify-content: flex-start;

  align-items: center;

  @media (max-width: 1900px) {
    height: 150px;
  }
  @media (max-width: 768px) {
    height: 115px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
`;
const Image = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 50px;
  }
  @media (max-width: 1900px) {
    height: 40px;
  }
`;
const ImagePic = styled.img`
  height: 500px;

  border-radius: 20px;

  @media (max-width: 1900px) {
    height: 300px;
  }
  @media (max-width: 768px) {
    height: 170px;
  }
`;
const Images = styled.img`
  height: auto;

  width: 80px;
  height: 10px;
  @media (max-width: 1700px) {
    width: 70px;
    height: 10px;
  }
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;
