import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'transactions';

export const getTransaction = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/public/${path}/${facility_id}/${id}`);
};

export const getTransactions = (facility_id: number, type: 'in' | 'out', query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/all/${type}`, {
    params: query,
  });
};

export const getAllTransactions = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const exportTransactions = (facility_id: number, type: 'in' | 'out', query?: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export/${type}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'Product Transactions.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const getVariantTransactions = (facility_id: number, variant_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/history/${variant_id}`);
};

export const exportProductLogs = (facility_id: number, query?: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export-logs`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'Product Logs.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
