import { LAB_REQUEST_ENTITY_NAME, labRequestFields, lab_request_schema } from './LabRequestModel';
import { useContext, useEffect, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { createPatientLabRequest } from 'company/api/lab-request';
import { formatDateTime } from 'core/utils';
import { getAllPatientAppointments } from 'company/api/appointments';

type Props = {
  patientID: number;
  appointmentID?: number;
  callbackAfterSubmit?: (data?: any) => void;
};
const LabRequestForm: React.FC<Props> = (props) => {
  const { patientID, appointmentID, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);
  const [options, setOptions] = useState({ appointment_id: [] });

  const handleSubmit = (formData: any) => {
    return createPatientLabRequest(facility.id, {
      doctor_id: formData.doctor_id,
      appointment_id: appointmentID,
      patient_id: patientID,
    });
  };

  useEffect(() => {
    getAllPatientAppointments(facility.id, patientID).then((docs) => {
      const filteredAppointments: any = docs.data.filter(
        (doc: any) => doc.status !== 'cancelled' && doc.status !== 'no-show'
      );
      filteredAppointments.sort((a: any, b: any) => new Date(b.schedule).getTime() - new Date(a.schedule).getTime());

      setOptions({
        appointment_id: filteredAppointments.map((u: any) => {
          return { key: `${formatDateTime(u.schedule)} - ${u.service_names}`, value: u.id };
        }),
      });
    });
  }, [facility.id, patientID]);

  return (
    <>
      <TemplateForm
        entityName={LAB_REQUEST_ENTITY_NAME}
        fields={labRequestFields}
        visibleFields={appointmentID ? ['doctor_id'] : ['doctor_id', 'appointment_id']}
        initialValues={{ doctor_id: '', appointment_id: '' }}
        formSubmitApiFunction={handleSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
        schema={lab_request_schema}
        dropdownOptions={options}
      />
    </>
  );
};

export default LabRequestForm;
