/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  Header,
  PrimaryButton,
  RowAction,
} from 'core/components';
import { calculateAge, changeNullToBlank, formatDate, tranformFormErrors } from 'core/utils';
import { createPatient, deletePatient, exportPatients, getPatients, updatePatient } from 'lgu/api/patient';
import { create_patient_schema, update_patient_schema } from 'lgu/model/schema';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import ImportModal from './components/ImportModal';
import { UserContext } from 'core/context/user.context';
import { getBarangays } from 'lgu/api/barangay';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  sex: string;
  birthday: string;
  barangay_code: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  sex: '',
  birthday: '',
  barangay_code: '',
};

const RegistrationBoard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [patientID, setPatientID] = useState<any>();
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [barangays, setBarangays] = useState<any[]>([]);
  const [patientModalHeader, setPatientModalHeader] = useState<string>('Register Patient');
  const [openPatientModal, setOpenPatientModal] = useState<boolean>(false);
  const [patientInitialValues, setPatientInitialValues] = useState<UserInput>(initialValues);
  const [patientSchema, setPatientSchema] = useState<any>(create_patient_schema);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const columns: GridColDef[] = [
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'middle_name', headerName: 'Middle Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>{params.row.birthday ? formatDate(params.row.birthday) : ''}</CustomGridCell>
      ),
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>{params.row.birthday ? calculateAge(params.row.birthday) : ''}</CustomGridCell>
      ),
    },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  // const filter_columns: TableFilter[] = [
  //   {
  //     field_name: "first_name",
  //     filter_type: "numbers",
  //   },
  // ];

  // Form Fields
  const patientFields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'birthday', display_name: 'Birthday', type: 'date', disableFuture: true },
    {
      field_name: 'sex',
      display_name: 'Sex Assigned At Birth',
      type: 'select',
      options: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
      ],
    },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay',
      type: 'select',
      options: barangays.map((u) => {
        return { key: u.barangay_name, value: u.barangay_code };
      }),
    },
  ];

  const showPatientModal = (from_edit?: boolean, data?: UserInput) => {
    if (from_edit && data) {
      setPatientInitialValues(data);
      setPatientModalHeader('Update Patient');
      setPatientSchema(update_patient_schema);
    } else {
      setPatientModalHeader('Register Patient');
      setPatientInitialValues(initialValues);
      setPatientSchema(create_patient_schema);
    }

    setOpenPatientModal(true);
  };

  const updatePatientInfo = (data: any) => {
    const to_update = changeNullToBlank(data); //pass data here

    showPatientModal(true, to_update);
  };

  const deletePatientInfo = (data: any) => {
    showAlertDialog('Are you sure you want to delete ' + data.full_name + '?');
    setPatientID(data.patient_id);
  };

  const viewEnrolledPrograms = (data: any) => {
    navigate('/patient-management/' + data.patient_id);
  };

  const actions = [
    { label: 'Update Patient', action: updatePatientInfo, icon: <EditOutlinedIcon /> },
    { label: 'Delete Patient', action: deletePatientInfo, icon: <DeleteOutlinedIcon style={{ color: 'red' }} /> },
    { label: 'View Enrolled Programs', action: viewEnrolledPrograms, icon: <DateRangeIcon /> },
  ];

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const showAlertDialog = (content: string) => {
    setOpenAlertDialog(true);
    setAlertContent(content);
  };

  const getPatientList = (query: ApiQuery) => {
    setLoading(true);
    getPatients(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (data.patient_id) {
      updatePatient(data.patient_id, data)
        .then((res: any) => {
          successFormSubmit(true, res, formikHelpers);
        })
        .catch((error) => console.error(error));
      return;
    }

    createPatient(data)
      .then((res: any) => {
        successFormSubmit(false, res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (from_edit: boolean, res: any, { resetForm, setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully ${from_edit ? 'updated' : 'registered'}!`, { variant: 'success' });
      getPatientList({ page: 1, length: 10, order: 'asc' });
      if (!from_edit) {
        resetForm();
        setOpenPatientModal(false);
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    getBarangayList();
    setBreadcrumb([{ label: 'Patient Management' }]);
  }, []);

  const handlePatientDelete = () => {
    setLoading(true);
    deletePatient(patientID!)
      .then((res: any) => {
        enqueueSnackbar('Patient successfully deleted!', { variant: 'success' });
        getPatientList({ page: 1, length: 10, order: 'asc' });
      })
      .catch((error) => console.error(error));
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  // const inputRef = useRef<HTMLInputElement | null>(null);

  // const showImportModal = () => {
  //   setOpenImportModal(true);
  // };

  // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files) {
  //     return;
  //   }

  //   setFile(e.target.files[0]);
  // };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const exportRecords = async (query: ApiQuery) => {
    exportPatients(query, user.lgu?.lgu_name);
  };

  return (
    <Box>
      <CustomContainer>
        <Header title="Patient Management" subtitle="Patient" />
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton onClick={() => showPatientModal()} label="Register Patient" />

          {/* <Box display="flex" gap="10px">
            <RegularButton onClick={() => showImportModal()} label="Import Patients" startIcon={<UploadIcon />} />
            <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
          </Box> */}
        </Box>

        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'patient_id'}
          getData={getPatientList}
          exportRecords={exportRecords}
          forceRefresh={forceRefresh}
        />
      </CustomContainer>
      <CustomModal header={patientModalHeader} open={openPatientModal} setOpen={setOpenPatientModal}>
        <CustomForm
          initialValues={patientInitialValues}
          onSubmit={handleSubmit}
          fields={patientFields}
          schema={patientSchema}
          loading={buttonLoading}
        />
      </CustomModal>
      <ConfirmationDialog
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
        content={alertContent}
        onConfirm={handlePatientDelete}
      />
      <ImportModal open={openImportModal} setOpen={setOpenImportModal} refreshTable={refreshTable} />
    </Box>
  );
};

export default RegistrationBoard;
