import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-lab-request';
const pathRequestForm = 'lab-request-form';
const pathLabRequestTests = 'patient-lab-request-test';

export const createPatientLabRequest = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const deletePatientLabRequest = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createPatientLabRequestForm = (data: any) => {
  return axios.post(`${baseURL}/${pathRequestForm}`, data);
};

export const getPatientLabRequestForm = () => {
  return axios.get(`${baseURL}/${pathRequestForm}`);
};

export const addPatientLabRequestTest = (data: any, facility_id: number) => {
  return axios.post(`${baseURL}/${pathLabRequestTests}/${facility_id}`, data);
};

export const togglePatientLabRequestTest = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${pathLabRequestTests}/${facility_id}/toggle`, data);
};

export const getAllPatientLabRequestTest = (facility_id: number, patient_request_id: number) => {
  return axios.get(`${baseURL}/${pathLabRequestTests}/${facility_id}/${patient_request_id}`);
};

export const updatePatientLabRequest = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${pathLabRequestTests}/${facility_id}/${id}`, data);
};
