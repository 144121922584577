import { CustomContainer, CustomDrawer, CustomIconButton, Header } from 'core/components';
import { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type CareGoDrawerProps = {
  parentComponent: ReactNode;
  parentHeader: string;
  parentSubHeader?: string;
  parentWidth: number;

  childComponent: ReactNode;
  childHeader: any;
  childSubHeader?: string;

  showChild: boolean;
  headerIcon?: any;

  handleClose: () => void;
};

const CareGoDrawer: React.FC<CareGoDrawerProps> = ({
  parentComponent,
  parentHeader,
  parentSubHeader,
  childComponent,
  childHeader,
  childSubHeader,
  parentWidth,
  showChild,
  handleClose,
  headerIcon,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setOpenDrawer(showChild);
  }, [showChild]);

  return (
    <Box>
      {parentHeader && <Header title={parentHeader} subtitle={parentSubHeader} />}
      <CustomDrawer
        parentComponent={
          <CustomContainer>
            <Box width="100%">{parentComponent}</Box>
          </CustomContainer>
        }
        childComponent={
          <CustomContainer>
            <Box width="100%">
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                <Box display="inline">
                  {childHeader ? (
                    <Header title={childHeader} subtitle={childSubHeader} mb="10px" icon={headerIcon} />
                  ) : (
                    <></>
                  )}
                </Box>
                <CustomIconButton
                  icon={<CloseIcon />}
                  onClick={() => {
                    setOpenDrawer(false);
                    handleClose && handleClose();
                  }}
                />
              </Box>
              {childComponent}
            </Box>
          </CustomContainer>
        }
        showChild={openDrawer}
        parentWidth={parentWidth}
      />
    </Box>
  );
};

export default CareGoDrawer;
