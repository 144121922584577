import { ThemeTokens } from 'theme/theme';

export const customTableStyle = (colors: ThemeTokens, mode: string, selectableRow: boolean) => ({
  '& .MuiDataGrid-root': { border: 'none' },
  '& .MuiDataGrid-row': { fontSize: 13, cursor: selectableRow ? 'pointer' : undefined },
  '& .emphasis': { color: colors.accent },
  '& .MuiDataGrid-menuIcon': { display: 'none !important' },
  '& .MuiDataGrid-footerContainer': { borderTop: 'none' },
  '& .MuiCheckbox-root': { color: `${colors.secondary} !important`, padding: '1px' },
  '& .MuiCheckbox-root.Mui-disabled': { color: `${colors.dark} !important`, padding: '1px' },

  '& .MuiDataGrid-toolbarContainer .MuiButton-text': { color: `${colors.text} !important` },
  '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': { outline: 'none !important' },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': { outline: 'none !important' },
  '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
    backgroundColor: mode === 'light' ? '#dcf0fa' : '#555a5e',
  },
  '& .MuiDataGrid-root .MuiDataGrid-row:hover': {
    backgroundColor: mode === 'light' ? '#e4f3fa' : '#5f6569',
  },
  '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: mode === 'light' ? '#e4f3fa' : '#5f6569',
  },
  '& .MuiCircularProgress-root': { color: colors.dark },
  '& .MuiDataGrid-cell': {
    paddingBlock: '5px !important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: mode === 'light' ? 'none !important' : undefined,
  },
  '& .MuiDataGrid-cellContent': { overflow: 'hidden', textOverflow: 'ellipsis' },
  '& .MuiDataGrid-columnSeparator': { color: `${colors.dark} !important` },
  '& .MuiDataGrid-columnHeaderTitle': { fontSize: 13, fontWeight: 'bold' },

  '& .MuiTablePagination-selectLabel::before': { 
    content: '"Displayed "',
    textTransform: 'none', 
    fontSize: 12, 
    fontFamily: 'inherit',
    '@media (max-width: 768px)': {
      fontSize: '10px',
    },
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
    fontSize: '12px',
    fontFamily: 'inherit',
    textTransform: 'lowercase', 
    '@media (max-width: 768px)': {
      fontSize: '10px',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: mode === 'light' ? colors.tableHeader : undefined,
    border: mode === 'light' ? 'none !important' : undefined,
    fontSize: 13,
  },
  '& .MuiDataGrid-row:nth-of-type(even)': {
    backgroundColor: mode === 'light' ? colors.tableRow : undefined,
  },
  '& .MuiSelect-select': {
    padding: '5px 10px',
  },
  '& .MuiInputBase-root': {
    borderRadius: 2,
    border: 'none',
  },
  '& .MuiInputBase-input ': {
    padding: '5px 10px',
    border: '1px solid ' + colors.border,
    fontSize: 13,
    height: '20px',
    borderRadius: 2,
  },
  '& .MuiTablePagination-root': {
    '@media (max-width: 768px)': {
      '& .MuiTablePagination-toolbar': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '& .MuiTablePagination-selectLabel': {
        display: 'none',
      },
      '& .MuiTablePagination-select': {
        marginRight: '8px',
      },
      '& .MuiTablePagination-displayedRows': {
        margin: 0,
      },
      '& .MuiTablePagination-actions': {
        marginLeft: '8px',
      },
    },
  },
  '& .MuiTablePagination-actions .MuiIconButton-root': {
    '@media (max-width: 768px)': {
      padding: '4px',
    },
  },
});
