import { ApiQuery } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'self-registration';

export const createSelfRegistration = (facility_id: number, patient: PatientModel) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, patient);
};

export const getSelfRegistration = (facility_id: number, query: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const confirmSelfRegistration = (facility_id: number, id: number) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const cancelSelfRegistration = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};
