import React from 'react';
import { Box } from '@mui/material';
import { SIZES } from 'theme/constants';
import ProductInTransaction from '../Inventory/components/ProductInTransaction';
import ProductOutTransaction from '../Inventory/components/ProductOutTransaction';
import ProductTransferTransaction from '../Inventory/components/ProductTransferTransaction';
import StockAdjustmentTransaction from '../Inventory/components/StockAdjustmentTransaction';

interface Transaction {
  type: 'in' | 'out' | 'transfer' | 'adjustment';
  [key: string]: any;
}

interface TransactionHistoryProps {
  variantHistory: Transaction[];
}

const TransactionHistory: React.FC<TransactionHistoryProps> = React.memo(({ variantHistory }) => {
  return (
    <Box display="flex" flexDirection="column" gap={SIZES.paddingS} paddingRight={'5px'}>
      {variantHistory.map((transaction, index) => {
        switch (transaction.type) {
          case 'in':
            return <ProductInTransaction key={index} transaction={transaction} />;
          case 'out':
            return <ProductOutTransaction key={index} transaction={transaction} />;
          case 'transfer':
            return <ProductTransferTransaction key={index} transaction={transaction} />;
          case 'adjustment':
            return <StockAdjustmentTransaction key={index} transaction={transaction} />;
          default:
            return null;
        }
      })}
    </Box>
  );
});

export default TransactionHistory;
