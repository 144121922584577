import { Packages, Services } from 'company/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-packages';

export const getHealthPackage = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getHealthPackages = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getHealthPackageClinics = (package_id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/${package_id}`);
};

export const createHealthPackage = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const duplicateHealthPackage = (id: number) => {
  return axios.post(`${baseURL}/${path}/duplicate/${id}`);
};

export const updateHealthPackage = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteHealthPackage = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getPackagesAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/assigned/${clinic_id}`, { params: query });
};

export const getPackagesNotAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-assigned/${clinic_id}`, { params: query });
};

export const removePackagesFromClinic = (clinic_id: number, packages: Packages) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, { data: packages });
};

export const assignPackageToClinic = (clinic_id: number, packages: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, packages);
};

export const getHealthPackageWidgetValues = (id: number) => {
  return axios.get(`${baseURL}/${path}/widgets/${id}`);
};

//Health Session Stages

export const getStages = (package_id: number) => {
  return axios.get(`${baseURL}/${path}/stages/${package_id}`);
};

export const addStage = (package_id: number, stage: any) => {
  return axios.post(`${baseURL}/${path}/add-stage/${package_id}`, stage);
};

export const updateStage = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/update-stage/${id}`, data);
};

export const deleteStage = (stage_id: number) => {
  return axios.delete(`${baseURL}/${path}/remove-stage/${stage_id}`);
};

export const addMultipleStages = (package_id: number, stage: any) => {
  return axios.post(`${baseURL}/${path}/add-multiple-stages/${package_id}`, stage);
};


//Health Session Stages' Services

export const getServicesByStage = (facility_id: number, stage_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/stage-services/${facility_id}/${stage_id}`, { params: query });
};

export const getAvailableServicesByStage = (package_id: number, stage_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/available/${package_id}/${stage_id}`, { params: query });
};

export const getStageDetails = (facility_id: number, stage_id: number) => {
  return axios.get(`${baseURL}/${path}/stage-details/${facility_id}/${stage_id}`);
};

export const addServiceToStage = (package_id: number, stage_id: number, services: any) => {
  return axios.post(`${baseURL}/${path}/${package_id}/add-services/${stage_id}`, services);
};

export const removeServiceFromStage = (package_id: number, stage_id: number, services: Services) => {
  return axios.delete(`${baseURL}/${path}/${package_id}/remove-services/${stage_id}`, { data: services });
};

//Health Session Services with Appointments

export const getServicesWithAppointments = (stage_id: number, package_patient_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${stage_id}/services/${package_patient_id}`, { params: query });
};

export const addToNotApplicable = (service: any) => {
  return axios.post(`${baseURL}/${path}/add-not-applicable`, service);
};

export const removeFromNotApplicable = (id: number) => {
  return axios.delete(`${baseURL}/${path}/remove-not-applicable/${id}`);
};
