import {
  MEDICINE_INSTRUCTION_ENTITY_NAME,
  MedicineInstructionModel,
  medicineInstructionFields,
  medicineInstructionInitialValues,
  simplifiedMedicineInstructionFormFields,
} from './MedicineInstructionModel';
import {
  createMedicineInstruction,
  getMedicineInstructions,
  updateMedicineInstruction,
} from 'company/api/medicine-instructions';
import { useEffect, useState } from 'react';

import TemplateForm from 'company/entities/Template/TemplateForm';
import { generateInstruction } from 'core/utils';
import { medicine_instructions_schema } from 'company/model/schema';

type Props = {
  medicineId: number;
  medicineInstruction?: MedicineInstructionModel;
  callbackAfterSubmit?: (data?: any) => void;
};

const MedicineInstructionForm: React.FC<Props> = (props) => {
  const { medicineId, medicineInstruction, callbackAfterSubmit } = props;
  const [medicineInstructionsTemplate, setMedicineInstructionsTemplate] = useState();

  const formSubmitApiFunction = (data: MedicineInstructionModel) => {
    return medicineInstruction
      ? updateMedicineInstruction(medicineInstruction.id, data)
      : createMedicineInstruction(medicineId, data);
  };

  useEffect(() => {
    getMedicineInstructions(medicineId).then((response) => {
      const responseData = response.data.data.map((data: any) => ({
        value: data,
        key: generateInstruction(data),
      }));

      setMedicineInstructionsTemplate(responseData);
    });
  }, [medicineId]);

  return (
    <TemplateForm
      entityName={MEDICINE_INSTRUCTION_ENTITY_NAME}
      fields={medicineInstructionFields}
      visibleFields={simplifiedMedicineInstructionFormFields}
      entity={medicineInstruction}
      dropdownOptions={{ instruction_template: medicineInstructionsTemplate }}
      initialValues={medicineInstruction ? medicineInstruction : medicineInstructionInitialValues}
      formSubmitApiFunction={formSubmitApiFunction}
      callbackAfterSubmit={callbackAfterSubmit}
      schema={medicine_instructions_schema}
    />
  );
};

export default MedicineInstructionForm;
