import { Box, Typography, useTheme } from '@mui/material';
import { ChartProps, chart_colors, chart_schema } from './ChartEditor';
import { ConfirmationDialog, CustomForm, RegularButton } from 'core/components';
import { createGraphs, deleteGraphs, updateGraphs } from 'company/api/graph';
import { debounce, rotateArray } from 'core/utils';
import { useEffect, useMemo, useState } from 'react';

import { BarChart } from '@mui/x-charts/BarChart';
import { ContainerRow } from 'core/components/containers';
import { FieldInput } from 'core/model/interface';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

type Props = {
  responses: any[];
  graph: ChartProps;
  fields: FieldInput[];
  template_id: number;

  removeChart: () => void;
};

const CustomBarChart: React.FC<Props> = ({ responses, graph, fields, template_id, removeChart }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [refresh, setRefresh] = useState(0);
  const [chart, setChart] = useState<any>(graph);
  const [chartDataKeys, setChartDataKeys] = useState<string[]>([]);
  const [chartDataValues, setChartDataValues] = useState<number[]>([]);
  const [configuration, setConfiguration] = useState<any>({
    hide_blank: false,
    hide_others: false,
    source: '',
    limit: 8,
    ...graph.configuration,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const updateDetails = (graph: any, field: string, value: any) => {
    if (['x_axis', 'y_axis'].includes(field)) graph[field] = { ...graph[field], label: value };
    else graph[field] = value;
    setRefresh((prev) => prev + 1);
  };

  const autoUpdate = debounce((graph: any, field: string, value: any) => {
    updateDetails(graph, field, value);
  }, 500);

  const saveChart = () => {
    if (graph.graph_name && configuration.source) {
      graph.configuration = configuration;
      if (chart.id) {
        updateGraphs(chart.id, graph);
      } else {
        createGraphs(template_id, graph).then((res) => setChart(res.data.graph));
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      saveChart();
    }, 2000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, configuration]);

  const tally = useMemo(() => {
    if (configuration.source) {
      return responses.reduce((result, response) => {
        const value = response[configuration.source];
        if (configuration.hide_blank && !value) return result;
        if (result[value]) {
          result[value] = result[value] + 1;
        } else {
          result[value] = 1;
        }
        return result;
      }, {});
    }
    return {};
  }, [responses, configuration]);

  useEffect(() => {
    const entries: any[] = Object.entries(tally);
    entries.sort((a, b) => b[1] - a[1]);

    const topEntries = entries.slice(0, configuration.limit);
    const others = entries.slice(configuration.limit);

    const keys = topEntries.map((entry) => (entry[0] === 'undefined' ? 'Blank' : entry[0]));
    const values = topEntries.map((entry) => entry[1]);

    if (others.length && !configuration.hide_others) {
      const othersValue = others.reduce((acc, entry) => acc + entry[1], 0);
      keys.push('Others');
      values.push(othersValue);
    }

    setChartDataKeys(keys);
    setChartDataValues(values);
  }, [tally, configuration]);

  // useEffect(() => {
  //   setChartDataValues(Object.values(chartData));
  //   setChartDataKeys(Object.keys(chartData).map((key) => (key === 'undefined' ? 'Blank' : key)));
  // }, [chartData]);

  const chartColors = useMemo(() => rotateArray(chart_colors), []);

  const handleDelete = async () => {
    try {
      await deleteGraphs(chart.id);
      removeChart();
    } catch (error) {
      console.error('Failed to delete chart:', error);
    }
  };

  return (
    <Box>
      <ContainerRow>
        <Typography variant="h3" fontWeight={600} color={colors.primary} mb={SIZES.padding}>
          {graph.graph_name}
        </Typography>
        <RegularButton
          size="small"
          variant="outlined"
          color="error"
          label="Delete"
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
        />
      </ContainerRow>
      <Box key={graph.id} display="grid" gridTemplateColumns="1fr 400px" alignItems="flex-start">
        <Box>
          <BarChart
            colors={chartColors}
            grid={{ horizontal: true }}
            sx={
              {
                //   paddingInline: '30px',
              }
            }
            series={[
              {
                data: chartDataValues,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={290}
            borderRadius={15}
            xAxis={[
              {
                label: graph.x_axis?.label ?? '',
                labelStyle: { fontWeight: 'bold', fontSize: '16px' },
                data: chartDataKeys,
                scaleType: 'band',

                colorMap: {
                  type: 'ordinal',
                  colors: graph.configuration.colors ?? chart_colors,
                },

                tickPlacement: 'middle',
                tickLabelPlacement: 'middle',
                tickSize: 10,
                tickLabelStyle: {
                  fontWeight: 'bold',
                  width: '50px',
                  //   textOverflow: 'ellipsis',
                  //   whiteSpace: 'nowrap',
                  //   overflow: 'hidden',
                },
                // valueFormatter: (value, context) => {
                //   return value.length > 15 ? value.substring(0, 15) + '...' : value;
                // },
              },
            ]}
            yAxis={[
              {
                label: graph.y_axis?.label ?? '',
                labelStyle: { fontWeight: 'bold', fontSize: '16px' },
                tickLabelStyle: { fontWeight: 'bold' },
                tickMinStep: 1,
              },
            ]}
            margin={{ top: 10, bottom: 50, left: 50, right: 20 }}
          />
        </Box>
        <Box
          sx={{
            border: '1px solid ' + colors.border,
            borderRadius: SIZES.borderRadiusS,
            padding: '15px',
            width: '400px',
          }}
        >
          <CustomForm
            fields={[
              {
                field_name: 'graph_name',
                display_name: 'Chart Name',
                span: 4,
                onChange: (value) => autoUpdate(graph, 'graph_name', value),
              },
              {
                field_name: 'source',
                display_name: 'Source Field',
                type: 'select',
                options: fields.map((field) => ({
                  key: field.display_name + '',
                  value: field.field_name,
                })),
                span: 4,
                onChange: (value, setFieldValue) => {
                  const displayName = fields.find((field) => field.field_name === value)?.display_name;
                  setFieldValue && setFieldValue('x_axis_label', displayName);
                  autoUpdate(graph, 'x_axis', displayName);
                  setConfiguration((prev: any) => ({ ...prev, source: value }));
                },
              },

              {
                field_name: 'x_axis_label',
                display_name: 'X-Axis Label',
                // span: 4,
                onChange: (value) => autoUpdate(graph, 'x_axis', value),
              },
              {
                field_name: 'y_axis_label',
                display_name: 'Y-Axis Label',
                // span: 4,
                onChange: (value) => autoUpdate(graph, 'y_axis', value),
              },
              {
                field_name: 'include_blank',
                display_name: 'Include no response',
                type: 'checkbox',
                // span: 4,
                onChange: (value) => setConfiguration((prev: any) => ({ ...prev, hide_blank: !value })),
              },
              {
                field_name: 'limit',
                display_name: 'Limit',
                type: 'number',
                // span: 4,
                onChange: (value) => setConfiguration((prev: any) => ({ ...prev, limit: value })),
              },
              {
                field_name: 'include_others',
                display_name: 'Include "Others"',
                type: 'checkbox',
                // span: 4,
                onChange: (value) => setConfiguration((prev: any) => ({ ...prev, hide_others: !value })),
              },
            ]}
            onSubmit={(data) => undefined}
            initialValues={{
              source: configuration.source,
              graph_name: chart.graph_name,
              y_axis_label: chart.y_axis?.label,
              x_axis_label: chart.x_axis?.label,
              include_blank: !configuration.hide_blank,
              include_others: !configuration.hide_others,
              limit: configuration.limit,
            }}
            schema={chart_schema}
            loading={false}
            rowGap={'20px'}
            hideSubmitButton
          />
        </Box>
      </Box>
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title="Delete Patient"
        content={`Are you sure you want to delete this chart?`}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default CustomBarChart;
