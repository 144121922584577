import { CreateSMSTemplatesModel, SMSTemplatesModel, SetSMSReminder } from 'lgu/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'sms';

export const getLguSMSTemplates = () => {
  return axios.get(`${baseURL}/${path}/templates`);
};

export const getLguSMSTemplate = (id: number) => {
  return axios.get(`${baseURL}/${path}/templates/${id}`);
};

export const getProgramScheduleSMS = (schedule_id: number) => {
  return axios.get(`${baseURL}/${path}/program-schedule/${schedule_id}`);
};

export const createLguSMSTemplates = (template: CreateSMSTemplatesModel) => {
  return axios.post(`${baseURL}/${path}/templates`, template);
};

export const updateLguSMSTemplates = (id: number, template: SMSTemplatesModel) => {
  return axios.put(`${baseURL}/${path}/templates/${id}`, template);
};

export const deleteLguSMSTemplates = (id: number) => {
  return axios.delete(`${baseURL}/${path}/templates/${id}`);
};

export const updateProgramScheduleSMSTemplate = (schedule_id: number) => {
  return axios.post(`${baseURL}/${path}/program-schedule/${schedule_id}`);
};

export const deleteProgramScheduleSMSTemplate = (template_id: number) => {
  return axios.delete(`${baseURL}/${path}/program-schedule/${template_id}`);
};

export const getHealthProgramsSMSTemplates = () => {
  return axios.get(`${baseURL}/${path}/templates/program/health-program`);
};

export const getCommonSMSTemplates = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/common-templates`, { params: query });
};

export const getProgramScheduleSMSTemplates = (category: string) => {
  return axios.get(`${baseURL}/${path}/templates/reminders/${category}`);
};

export const getSMSProgramSchedules = (schedule_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program-schedule/${schedule_id}`, {
    params: query,
  });
};

export const getLguProgramSMS = (program_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program/${program_id}`, {
    params: query,
  });
};

export const getLguProgramScheduleSMS = (schedule_id: number) => {
  return axios.get(`${baseURL}/${path}/program-schedule/${schedule_id}`);
};

export const createLguProgramScheduleSMS = (schedule_id: number, reminder: SetSMSReminder) => {
  return axios.post(`${baseURL}/${path}/program-schedule/${schedule_id}`, reminder);
};

export const deleteLguProgramScheduleSMS = (id: number) => {
  return axios.delete(`${baseURL}/${path}/program-schedule/${id}`);
};

export const getTbSMS = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program-reminders/sms/tb`, {
    params: query,
  });
};
