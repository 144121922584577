import { Box } from '@mui/material';
import RegularButton from './buttons/RegularButton';
import autoTable from 'jspdf-autotable';
import { formatDate } from 'core/utils';
import jsPDF from 'jspdf';

type PDFGeneratorProps = {
  headers: string[];
  content: any[];
  title?: string;
  subtitle?: string;
};

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ headers, content, title, subtitle }) => {
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    var width = doc.internal.pageSize.getWidth();

    // Set table rows
    const data = [];
    const element = [];
    for (let i = 0; i < content.length; i++) {
      for (let x = 0; x < content[i].length; x++) {
        element.push([content[i][x]]);
      }
      data.push(element);
      element.pop();
    }

    title && doc.text(title, width / 2, 20, { align: 'center' });

    // Add the table to the PDF
    autoTable(doc, {
      startY: 30,
      head: [headers],
      body: content,
    });

    const today = formatDate(new Date());

    // Save the PDF
    doc.save('[' + today + '] Appointments.pdf');
  };

  return (
    <Box margin="1rem 0">
      <RegularButton label={'Generate PDF'} onClick={generatePDF} />
    </Box>
  );
};

export default PDFGenerator;
