import * as yup from 'yup';

import { EntityFields } from 'company/entities/utils';

export const ATTRIBUTE_VALUE_ENTITY_NAME = 'Attribute Value';

export const attributeValueModelSchema = yup.object().shape({
  value: yup.string().required('Value is required').max(30, 'Value must be at most 30 characters.'),
});

export interface AttributeValueModel {
  id: number;
  attribute_id: number;
  attribute_name: string;
  value: string;
}

export const attributeValueFields: EntityFields[] = [{ fieldName: 'value', displayName: 'Value' }];

export const visibleAttributeValueColumns = ['value'];
export const addAttributeValueFormFields = ['value'];
export const updateAttributeValueFormFields = ['value'];
export const attributeValueInitialValues = { value: '' };
