import { Box, Step, StepIcon, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import {
  HealthPlanSessionModel,
  PatientHealthPlan,
} from 'company/entities/modules/ClinicManagement/HealthPackage/HealthPackageModel';
import ScheduleAppointment, { ACTION } from 'company/screens/HealthPackage/components/ScheduleAppointmentForm';
import { proceedToNextStage, setHealthPackageAsComplete } from 'company/api/health-packages-patients';
import { useEffect, useRef, useState } from 'react';

import { AppointmentTable } from 'company/entities/tables';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { SIZES } from 'theme/constants';
import { formatDate } from 'core/utils';
import { getStages } from 'company/api/health-packages';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';

type Props = {
  //   plan: HealthPackageModel;
  patientPlan: PatientHealthPlan;
};

const HealthPlan: React.FC<Props> = ({ patientPlan }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const stagesContainerRef = useRef<HTMLDivElement>(null);
  const tableRef: any = useRef();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [stages, setStages] = useState<HealthPlanSessionModel[]>([]);
  const [currentStageIndex, setCurrentStageIndex] = useState<number>(0);
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [healthPackageComplete, setHealthPackageComplete] = useState<boolean>(patientPlan.is_completed);
  const [healthPackageCompleteModal, setHealthPackageCompleteModal] = useState<boolean>(false);
  const [openNextStageModal, setOpenNextStageModal] = useState<boolean>(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState<boolean>(false);
  const [loadingNextSession, setLoadingNextSession] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleStepClick = (stage: any, index: number) => {
    setActiveStep(index);
    setSelectedStage(stage);
  };

  const getPackageStages = async () => {
    try {
      const res = await getStages(patientPlan.package_id);
      const stages: HealthPlanSessionModel[] = res.data.data;
      updateVisitDate(stages);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const updateVisitDate = (stages: HealthPlanSessionModel[]) => {
    let totalDays = 0;
    let currentStageFound = false;
    stages.forEach((session, index) => {
      if (session.id === patientPlan.stage_id) {
        setCurrentStageIndex(index);
        setActiveStep(index);
        setSelectedStage(session);
        currentStageFound = true;
      }

      if (currentStageFound) {
        if (session.id !== patientPlan.stage_id) {
          totalDays += session.days_after_prev_stage;
        }
        const calculatedDate = moment(patientPlan.expected_next_stage).add(totalDays, 'days').toString();
        session.visit_date = calculatedDate;
      } else {
        session.visit_date = '';
      }
    });

    setStages([...stages]);
  };

  const setAsComplete = () => {
    setHealthPackageAsComplete(patientPlan.id).then(() => {
      enqueueSnackbar('Health Plan successfully completed!', { variant: 'success' });
      setHealthPackageComplete(true);
    });
  };

  const handleSubmit = async () => {
    try {
      setLoadingNextSession(true);
      await proceedToNextStage(patientPlan.id);
      const nextStage = stages[currentStageIndex + 1];
      setActiveStep(currentStageIndex + 1);
      patientPlan.stage_id = nextStage.id;
      setCurrentStageIndex(currentStageIndex + 1);
      updateVisitDate(stages);
      setLoadingNextSession(false);

      enqueueSnackbar('Patient proceeded to next stage!', { variant: 'success' });
      setOpenNextStageModal(false);
    } catch (error) {
      enqueueSnackbar('Error in proceeding to next stage!', { variant: 'error' });
    }

    // successFormSubmit(res);
  };

  useEffect(() => {
    if (stagesContainerRef.current && stages) {
      const currentStageElement = stagesContainerRef.current.querySelector('.client-current-stage');
      if (currentStageElement) {
        currentStageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
          const isLastStage = activeStep === stages.length - 1;
          if (isLastStage && stagesContainerRef.current) {
            stagesContainerRef.current.scrollTo({ top: stagesContainerRef.current.scrollHeight, behavior: 'smooth' });
          }
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages]);

  useEffect(() => {
    tableRef.current?.refreshTable();
  }, [selectedStage]);

  useEffect(() => {
    if (patientPlan) getPackageStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPlan]);

  const StepperContent = (
    <Box
      sx={{
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: !isMobile ? colors.light_blue_background : '#FFFFF',
        overflowX: 'auto',
        overflowY: 'auto',
        width: isMobile ? '100vw' : '220px',
        marginBottom: isMobile ? SIZES.padding : 0,
        marginRight: !isMobile ? SIZES.padding : 0,
        // display: isMobile ? 'flex' : '',
        // flexDirection: isMobile ? 'column' : '',
        // alignItems: isMobile ? 'center' : '',
      }}
    >
      <Stepper
        activeStep={activeStep}
        nonLinear
        orientation={isMobile ? 'horizontal' : 'vertical'}
        sx={{
          zIndex: 0,
          '& .MuiStepConnector-vertical': {
            marginLeft: '19px',
          },
          '& .MuiStepConnector-horizontal': {
            marginBottom: '60px',
            borderWidth: '2px',
          },
          '& .MuiStepConnector-line': {
            borderColor: colors.blueAccent,
            borderWidth: '2px',
          },
          ...(isMobile && {
            '& .MuiStep-root': {
              padding: isMobile ? '0' : '0 10px',
            },
          }),
        }}
      >
        {stages &&
          stages.map((stage_data, index) => {
            const isStepCompleted = patientPlan.is_completed || index < currentStageIndex || healthPackageComplete;

            return (
              <Step
                key={stage_data.id}
                onClick={() => handleStepClick(stage_data, index)}
                completed={isStepCompleted}
                className={stage_data.id === patientPlan.stage_id ? 'client-current-stage' : ''}
                sx={{
                  marginLeft: '5px',
                  '& .MuiStepLabel-root': {
                    cursor: 'pointer',
                    padding: 0,

                    ...(isMobile && { flexDirection: 'column', alignItems: 'center' }),
                  },
                  '& .MuiStepIcon-root': {
                    height: '35px',
                    width: '35px',
                    border: '2px solid ' + colors.blueAccent,
                    color: 'transparent',
                    borderRadius: '50px',
                  },
                  '& .MuiStepIcon-text': { fill: colors.accent, fontSize: '14px' },
                  '&:hover .MuiStepIcon-root': { backgroundColor: colors.blueAccent, color: colors.accent },
                  '&:hover .MuiStepIcon-text': { fill: 'white' },
                  '& .client-current-stage.MuiStepLabel': { fontWeight: 'bold' },
                  '& .selected-step': { bgcolor: colors.accent, color: 'white' },
                }}
              >
                <StepLabel
                  StepIconComponent={(props) => (
                    <Box
                      onClick={() => handleStepClick(stage_data, index)}
                      sx={{
                        width: isMobile ? '30px' : '40px',
                        height: isMobile ? '30px' : '40px',
                        display: 'flex',
                        zIndex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',

                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: `2px solid ${colors.blueAccent}`,
                        backgroundColor: isStepCompleted ? colors.accent : 'transparent',
                        color: isStepCompleted ? 'white' : colors.accent,
                        '&:hover': {
                          backgroundColor: colors.accent,
                          color: 'white',
                        },
                      }}
                    >
                      {isStepCompleted ? <DoneRoundedIcon /> : <Typography>{index + 1}</Typography>}
                    </Box>
                  )}
                >
                  <Box
                    onClick={() => handleStepClick(stage_data, index)}
                    sx={{
                      cursor: 'pointer',
                      marginTop: isMobile ? '10px' : '0',
                      textAlign: isMobile ? 'center' : 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'black',
                        fontWeight: stage_data.id === patientPlan.stage_id ? '600' : 'regular',
                        fontSize: isMobile ? '0.7rem' : 'inherit',
                        width: '35px',
                        textAlign: 'center',
                      }}
                    >
                      {stage_data.stage_name}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      sx={{
        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
        },
      }}
    >
      {StepperContent}

      <Box
        width={isMobile ? '100%' : 'calc(100% - 220px - 16px)'}
        display="flex"
        flexDirection="column"
        gap="20px"
        paddingBlock={SIZES.padding}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          {stages && stages.length > activeStep ? stages[activeStep].stage_name : ''}
        </Typography>

        <AppointmentTable
          ref={tableRef}
          packageStageId={selectedStage?.id}
          packagePatientId={patientPlan.id}
          visibleColumns={['schedule', 'service_names', 'status', 'invoice_number']}
          fromHealthSession
        />

        {!!patientPlan.is_active && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              right: 0,
              backgroundColor: !isMobile ? colors.light_blue_background : '#FFFFFF',
              mt: SIZES.padding,
            }}
          >
            {activeStep ===
              stages.findIndex((stage) => stage.id === patientPlan.stage_id && stages.length - 1 !== activeStep) && (
              <RegularButton
                variant="outlined"
                onClick={() => setOpenNextStageModal(true)}
                label="Proceed to Next Session"
                size="small"
                fullWidth
                styles={{ padding: SIZES.paddingS }}
              />
            )}
            {!healthPackageComplete && currentStageIndex === activeStep && currentStageIndex === stages.length - 1 && (
              <RegularButton
                label={'Complete'}
                onClick={() => setHealthPackageCompleteModal(true)}
                startIcon={<DoneAllOutlinedIcon />}
                fullWidth={isMobile}
              />
            )}
          </Box>
        )}
      </Box>

      <ConfirmationDialog
        open={healthPackageCompleteModal}
        setOpen={setHealthPackageCompleteModal}
        content={'Mark "' + patientPlan.package_name + '" as complete?'}
        onConfirm={setAsComplete}
      />

      {isMobile ? (
        <CustomBottomPopover open={openNextStageModal} setOpen={setOpenNextStageModal} title="Proceed to Next Session">
          <Box display="flex" flexDirection="column" gap="20px" padding={'20px'}>
            <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
              <Box display="flex" gap="5px">
                <Typography>The next session is scheduled for</Typography>
                <Typography sx={{ color: colors.blueAccent, fontWeight: 'bold' }}>
                  {formatDate(stages[currentStageIndex + 1]?.visit_date)}.
                </Typography>
              </Box>
              <Typography>Would you like to proceed to the next session?</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={SIZES.paddingS}>
              <RegularButton label="Cancel" onClick={() => setOpenNextStageModal(false)} variant="outlined" />
              <RegularButton label="Proceed" onClick={handleSubmit} loading={loadingNextSession} />
            </Box>
          </Box>
        </CustomBottomPopover>
      ) : (
        <CustomModal
          open={openNextStageModal}
          setOpen={setOpenNextStageModal}
          header={`Proceed to Next Session`}
          width={500}
        >
          <Box display="flex" flexDirection="column" gap="30px">
            <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
              <Box display="flex" gap="5px">
                <Typography>The next session is scheduled for</Typography>
                <Typography sx={{ color: colors.blueAccent, fontWeight: 'bold' }}>
                  {formatDate(stages[currentStageIndex + 1]?.visit_date)}.
                </Typography>
              </Box>
              <Typography>Would you like to proceed to the next session?</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={SIZES.paddingS}>
              <RegularButton label="Cancel" onClick={() => setOpenNextStageModal(false)} variant="outlined" />
              <RegularButton label="Proceed" onClick={handleSubmit} loading={loadingNextSession} />
            </Box>
          </Box>
        </CustomModal>
      )}
      <CustomModal
        open={openAppointmentModal}
        setOpen={setOpenAppointmentModal}
        header={'Schedule Appointment'}
        width={500}
      >
        <ScheduleAppointment
          selectedService={{}}
          stageID={selectedStage?.id}
          patient={patientPlan}
          packagePatientID={patientPlan.id}
          setOpen={setOpenAppointmentModal}
          refreshTable={() => {
            tableRef.current?.refreshTable();
          }}
          action={ACTION.APPOINTMENT}
        />
      </CustomModal>
    </Box>
  );
};

export default HealthPlan;
