import {
  SUPPLIER_ENTITY_NAME,
  SupplierInput,
  SupplierModel,
  supplierEntityFields,
  supplierFormFields,
  supplierFormInitialValues,
} from './SupplierModel';
import { createSupplier, updateSupplier } from 'company/api/suppliers';

import TemplateForm from '../../../Template/TemplateForm';
import { supplier_schema } from 'company/model/schema';

type Props = {
  supplier?: SupplierModel;
  callbackAfterSubmit?: (data?: any) => void;
};

const SupplierForm: React.FC<Props> = (props) => {
  const { callbackAfterSubmit, supplier } = props;
  const supplierAPICallback = (data: SupplierModel) => {
    return supplier && supplier.id ? updateSupplier(supplier.id, data) : createSupplier(data);
  };

  return (
    <TemplateForm
      submitButtonId={"create_supplier_submit_button"}
      entityName={SUPPLIER_ENTITY_NAME}
      fields={supplierEntityFields}
      formSubmitApiFunction={supplierAPICallback}
      initialValues={(supplier as SupplierInput) ?? supplierFormInitialValues}
      visibleFields={supplierFormFields}
      callbackAfterSubmit={callbackAfterSubmit}
      schema={supplier_schema}
    />
  );
};

export default SupplierForm;
