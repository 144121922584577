import {
  INVOICE_ITEM_ENTITY_NAME,
  InvoiceItemModel,
  invoiceItemFields,
  invoiceItemTableColumns,
} from './InvoiceItemModel';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { deleteInvoiceItem, getInvoiceItems, getLinkedProducts } from 'company/api/invoice-items';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/system';
import { FacilityContext } from 'core/context/facility.context';
import { SIZES } from 'theme/constants';
import TemplateTable from '../../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';

type Props = {
  visibleColumns?: string[];
  hideActions?: boolean;
  tableRef?: any;
  useColoredTable?: boolean;
  invoiceId: number;
  onGetData?: (data: any) => void;
  setInvoiceItemTableLoading?: any;
};

const InvoiceItemLinkedProductsTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, hideActions, tableRef, useColoredTable, invoiceId, onGetData, setInvoiceItemTableLoading } =
    props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<InvoiceItemModel>();
  const isMobile = useMediaQuery('(max-width:768px)');

  const getData = async (query: ApiQuery) => {
    const response = getLinkedProducts(facility.id, invoiceId, { ...query, length: 1000 });
    return response;
  };

  const [linkedProductsDetails, setLinkedProductsDetails] = useState<any>(null);

  useEffect(() => {
    setInvoiceItemTableLoading?.(true);
    async function fetchData() {
      try {
        if (!invoiceId) return;
        const res = await getData({});
        setLinkedProductsDetails(res.data);
        setInvoiceItemTableLoading?.(false);
      } catch (err) {
        console.error(err);
        setInvoiceItemTableLoading?.(false);
      }
    }
    fetchData();
  }, [invoiceId]);

  if (isMobile && linkedProductsDetails)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          mt: '10px',
        }}
      >
        {linkedProductsDetails.map((item: any) => (
          <Box
            key={item.id}
            sx={{
              backgroundColor: colors.light_blue_background,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px 20px',
              borderRadius: '10px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', minWidth: '100px', maxWidth: '100px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{item.item_name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'end' }}>
              <Typography>₱ {item.unit_price}</Typography>
            </Box>
            <Box>
              <Typography>x {item.quantity}</Typography>
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: colors.primary }}>
              {formatCurrency(item.total_price)}
            </Typography>
          </Box>
        ))}
      </Box>
    );

  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        hideAddButton={true}
        hideDeleteAction={true}
        hideUpdateAction={true}
        useColoredTable={useColoredTable}
        ref={mainRef}
        entityName={INVOICE_ITEM_ENTITY_NAME}
        fields={invoiceItemFields}
        visibleColumns={visibleColumns ?? invoiceItemTableColumns}
        rowId="id"
        getData={getData}
        onGetData={onGetData}
        hideSearch
        hidePagination
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </WaitForFacility>
  );
});

export default InvoiceItemLinkedProductsTable;
