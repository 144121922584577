import { ApiQuery } from 'core/model/interface';
import { UpdateBarangayHealthworkerModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-workers';

export const getBarangayHealthworkers = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getAllBarangayHealthworkers = () => {
  return axios.get(`${baseURL}/${path}/list/all`);
};

export const getBarangayAssignment = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/barangay/assignment`, { params: query });
};

export const updateBarangayAssignment = (hw_id: number, barangay_code: string) => {
  return axios.patch(`${baseURL}/${path}/barangay/assignment/${hw_id}`, {
    barangay_code,
  });
};

export const getBarangayHealthworker = (hw_id: number) => {
  return axios.get(`${baseURL}/${path}/${hw_id}`);
};

export const createBarangayHealthworker = (healthworker: UpdateBarangayHealthworkerModel) => {
  return axios.post(`${baseURL}/${path}`, healthworker);
};

export const updateBarangayHealthworker = (hw_id: number, healthworker: UpdateBarangayHealthworkerModel) => {
  return axios.put(`${baseURL}/${path}/${hw_id}`, healthworker);
};

export const deleteBarangayHealthworker = (hw_id: number) => {
  return axios.delete(`${baseURL}/${path}/${hw_id}`);
};

export const deleteBarangayAssignment = (barangay_code: string) => {
  return axios.delete(`${baseURL}/${path}/barangay/assignment/${barangay_code}`);
};
