import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomModal, RegularButton } from 'core/components';

import { ApiQuery } from 'core/model/interface';
import { UserContext } from 'core/context/user.context';
import { exportBarangayCodes } from 'lgu/api/barangay';
import { importPatients } from 'lgu/api/patient';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ImportModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
};

const ImportModal: React.FC<ImportModalProps> = ({ open, setOpen, refreshTable }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [errorLabel, setErrorLabel] = useState<string>('');

  const handleImportPatients = () => {
    // 👇 We redirect the click event onto the hidden input element
    // inputRef.current?.click();
    setLoading(true);
    importPatients(file)
      .then((res) => {
        enqueueSnackbar(`Patients successfully imported!`, { variant: 'success' });
        setLoading(false);
        setOpen(false);
        refreshTable();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setErrorLabel(
          'An error occurred during the file import process. Kindly ensure that you have followed the template file correctly, and carefully review the provided Barangay Code and Barangay Name to ensure their accuracy.'
        );
      });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setErrorLabel('');
    setFile(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const exportBarangays = async (e: any) => {
    e.preventDefault();
    getBarangayCodes({ page: 1, length: 50 });
  };

  const getBarangayCodes = async (query: ApiQuery) => {
    exportBarangayCodes(query, user.lgu?.lgu_name);
  };

  useEffect(() => {
    if (open) {
      setErrorLabel('');
      setSelectedFile(undefined);
    }
  }, [open]);

  return (
    <>
      <CustomModal header="Import Patients" open={open} setOpen={setOpen}>
        <Box>
          <Typography variant="h5"> Instructions: </Typography>

          <List dense>
            <ListItem>
              <Typography>
                1. Download the Excel template
                <a
                  href={process.env.PUBLIC_URL + '/template/patient_import_template.xlsx'}
                  download="patient_import_template.xlsx"
                  style={{ color: colors.accent, paddingLeft: '5px' }}
                >
                  here
                </a>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>2. First Name, Last Name, Sex, and Barangay Code are required fields. </Typography>
            </ListItem>
            <ListItem>
              <Typography>3. Mobile number format should be 09*********. </Typography>
            </ListItem>
            <ListItem>
              <Typography>4. Barangay Code should be nine digits long. </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                5. Download Barangay Codes
                <span
                  onClick={exportBarangays}
                  style={{ color: colors.accent, paddingLeft: '5px', cursor: 'pointer', textDecoration: 'underline' }}
                >
                  here
                </span>
                .
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box marginTop={'10px'}>
          <Typography variant="h5"> Select file here: </Typography>
          <Box margin={'10px'}>
            <input type="file" onChange={handleFileChange} accept=".xlsx" />
            {errorLabel && (
              <Typography variant="h6" color="error" mt="0.5rem">
                {' '}
                {errorLabel}{' '}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          <RegularButton label={'Import'} onClick={handleImportPatients} loading={loading} disabled={!selectedFile} />
        </Box>
      </CustomModal>
    </>
  );
};

export default ImportModal;
