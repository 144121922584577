import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'barangays';

export const getBarangays = (lgu_code: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${lgu_code}`, {
    params: query,
  });
};
