/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const company_schema = yup.object().shape({
  company_name: yup.string().required('Company Name is required'),
  primary_language: yup.number().required('Language is required'),
  email: yup
    .string()
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter a valid email address')
    .required('Email is required'),
});

export const health_programs_schema = yup.object().shape({
  health_programs: yup.array().min(1, 'At least one program must be selected'),
});

export const request_demo_schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  contact_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    }),
    // .required('Mobile Number is required.'),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please enter a valid email address'
    )
    .required('Email is required.'),
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  address: yup.string().required('Address is required'),
});
