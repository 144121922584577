import { ConfirmationDialog, CustomModal, Dropdown, NoActionDialog } from 'core/components';
import { ProgramModel, ProgramScheduleModel } from 'lgu/model/Entities';
import React, { useState } from 'react';
import { cancelProgramSchedule, exportPatients } from 'lgu/api/program-schedule';

import { ApiQuery } from 'core/model/interface';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PreviewIcon from '@mui/icons-material/Preview';
import QuickEnroll from './QuickEnroll';
import ScheduleSmsReminders from './ScheduleSmsReminders';
import SendIcon from '@mui/icons-material/Send';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { getLguSMSTemplate } from 'lgu/api/sms';
import moment from 'moment';
import { useSnackbar } from 'notistack';

type SettingsDropdownProps = {
  schedule: ProgramScheduleModel;
  program: ProgramModel;
  button_label?: any;
  has_icon?: boolean;
  icon?: any;
  hideQuickEnroll?: boolean;
  editSchedule: (schedule: any) => void;
  cloneSchedule: (schedule: any) => void;
  getSchedules?: (query: ApiQuery) => void;
};

const SettingsDropdown: React.FC<SettingsDropdownProps> = ({
  schedule,
  program,
  button_label,
  has_icon,
  icon,
  hideQuickEnroll,
  editSchedule,
  cloneSchedule,
  getSchedules,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openSmsReminderModal, setOpenSmsReminderModal] = useState<boolean>(false);
  const [openNoActionDialog, setOpenNoActionDialog] = useState<boolean>(false);
  const [template, setTemplate] = useState<string>('');
  const [templateTitle, setTemplateTitle] = useState<string>('');
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openQuickEnrollModal, setOpenQuickEnrollModal] = useState<boolean>(false);

  const quickEnroll = (data: any) => {
    setOpenQuickEnrollModal(true);
  };

  const previewSMS = () => {
    getLguSMSTemplate(parseInt(program.sms_template!))
      .then((template) => {
        const program_date_time = schedule?.program_date_time;
        const template_body = template.data.template_body
          // .replace('[LGU]', lgu_replace)
          .replace('[ProgramDate]', moment(program_date_time).format('LLLL'))
          .replace('[LocationName]', schedule?.venue)
          .replace('[BarangayName]', schedule?.barangay_name)
          .replace('[ProgramName]', program?.program_name)
          .replace('[PatientName]', 'Juan Dela Cruz');

        setOpenNoActionDialog(true);
        setTemplate(template_body);
        setTemplateTitle(template.data.template_name);
      })
      .catch();
  };

  const cancelSchedule = (data: any) => {
    setOpenAlertDialog(true);
    setAlertContent('Are you sure you want to cancel ' + data.schedule_name + '?');
  };

  const handleScheduleCancel = () => {
    cancelProgramSchedule(schedule.id)
      .then((res: any) => {
        enqueueSnackbar('Program Schedule successfully canceled!', { variant: 'success' });
        getSchedules && getSchedules({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const exportParticipants = () => {
    exportPatients(schedule.id, schedule.schedule_name);
  };

  const settings_actions = [
    {
      label: 'Quick Enroll',
      hidden: hideQuickEnroll,
      action: quickEnroll,
      icon: <PersonAddAlt1Icon />,
    },
    {
      label: 'Set SMS Reminders',
      action: () => setOpenSmsReminderModal(true),
      icon: <SmsOutlinedIcon />,
    },
    // {
    //   label: 'Send SMS Now',
    //   icon: <SendIcon />,
    // },
    {
      label: 'Preview SMS',
      action: previewSMS,
      icon: <PreviewIcon />,
    },
    {
      label: 'divider',
    },
    {
      label: 'Edit Schedule',
      action: editSchedule,
      icon: <EditCalendarIcon />,
    },
    {
      label: 'Cancel Schedule',
      action: cancelSchedule,
      icon: <EventBusyIcon />,
    },
    {
      label: 'Clone Schedule',
      action: cloneSchedule,
      icon: <EventRepeatIcon />,
    },
    {
      label: 'Export Participants',
      action: exportParticipants,
      icon: <FileDownloadIcon />,
    },
  ];

  return (
    <>
      <Dropdown
        isIcon={icon ? true : false}
        optionList={settings_actions}
        buttonLabel={button_label}
        icon={icon}
        data={schedule}
        tooltip="View Actions"
        tooltipPlacement="left-end"
      />

      <CustomModal header={'Quick Enroll'} open={openQuickEnrollModal} setOpen={setOpenQuickEnrollModal} width={900}>
        <QuickEnroll scheduleID={schedule.id} />
      </CustomModal>

      <CustomModal
        header={'SMS Sending Schedule'}
        open={openSmsReminderModal}
        setOpen={setOpenSmsReminderModal}
        width={900}
      >
        <ScheduleSmsReminders schedule={schedule} />
      </CustomModal>

      <NoActionDialog
        open={openNoActionDialog}
        setOpen={setOpenNoActionDialog}
        content={template}
        title={templateTitle}
      />

      <ConfirmationDialog
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
        content={alertContent}
        onConfirm={handleScheduleCancel}
      />
    </>
  );
};

export default SettingsDropdown;
