import {
  SERVICE_CATEGORY_ENTITY_NAME,
  ServiceCategoryModel,
  serviceCategoryFields,
  serviceCategoryFormFields,
  serviceCategoryInitialValues,
  serviceCategorySchema,
  serviceCategoryUpdateFormFields,
} from './ServiceCategoryModel';
import { createServiceCategory, updateServiceCategory } from 'company/api/service-categories';

import TemplateForm from 'company/entities/Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  facility_id: number;
  readonly?: boolean;
  serviceCategory?: ServiceCategoryModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const ServiceCategoryForm = (props: Props) => {
  const { serviceCategory, callbackAfterSubmit, facility_id } = props;

  function handleSubmit(data: any) {
    return serviceCategory
      ? updateServiceCategory(facility_id, serviceCategory.id, data)
      : createServiceCategory(facility_id, data);
  }

  return (
    <TemplateForm
      schema={serviceCategorySchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={SERVICE_CATEGORY_ENTITY_NAME}
      fields={serviceCategoryFields}
      initialValues={serviceCategory ? changeNullToBlank(serviceCategory) : serviceCategoryInitialValues}
      visibleFields={serviceCategory ? serviceCategoryUpdateFormFields : serviceCategoryFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default ServiceCategoryForm;
