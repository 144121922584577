import BillingHistory from './components/BillingHistory';
import { Box } from '@mui/material';
import { CustomContainer } from 'core/components';
import PurchaseAdditionalCredits from './components/PurchaseAdditionalCredits';

const AccountSettings = () => {
  return (
    <>
      <CustomContainer>
        <Box display="block" width="100%">
          <BillingHistory />
        </Box>
      </CustomContainer>

      <PurchaseAdditionalCredits />
    </>
  );
};

export default AccountSettings;
