/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { CustomForm, CustomModal, CustomTable, PrimaryButton } from 'core/components';
import { Fragment, useContext, useEffect, useState } from 'react';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { add_sms_template_schema, sms_template_schema } from 'company/model/schema';
import {
  createLocalizedTemplates,
  getAvailableLanguages,
  getLocalizedTemplates,
  updateLocalizedTemplates,
} from 'carego-admin/api/sms-templates';

import { FacilityContext } from 'core/context/facility.context';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ViewContent from 'core/components/modals/ViewContent';
import { replaceCompanySmsTemplate } from 'core/utils';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type LocalizedSmsProps = {
  template: any;
  company: any;
  setRefreshMainTable: (refresh: any) => void;
};

const LocalizedSms: React.FC<LocalizedSmsProps> = ({ template, company, setRefreshMainTable }) => {
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [preview, setPreview] = useState('');
  const [templateBody, setTemplateBody] = useState('');
  const [localizedTemplate, setLocalizedTemplate] = useState<any>();
  const [refreshTemplates, setRefreshTemplates] = useState(0);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [languages, setLanguages] = useState<any>();

  const [openTemplatePreview, setOpenTemplatePreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>();
  const [clearSelected, setClearSelected] = useState(0);

  const [toAdd, setToAdd] = useState<boolean>(false);
  const [openAdd, setOpenAdd] = useState<boolean>(false);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'language', headerName: 'Language', flex: 1 },
    { field: 'template_body', headerName: 'Template Body', flex: 3 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params: any) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={(event) => {
            setToAdd(false);
            setOpenEdit(true);
            setLocalizedTemplate(params.row);
            setTemplateBody(params.row.template_body);
            event.stopPropagation();
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const getCompanyLocalizedTemplates = (query: ApiQuery) => {
    if (template) {
      setLoading(true);
      getLocalizedTemplates(template.id, company.id, query)
        .then((res) => {
          setRows(res.data.data);
          setRowCount(res.data.meta.total);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const handleSubmit = (data: any) => {
    setButtonLoading(true);
    if (toAdd) {
      createLocalizedTemplates(template.id, data)
        .then(() => {
          enqueueSnackbar(`Local Template successfully added!`, { variant: 'success' });
          setRefreshTemplates((prev: number) => prev + 1);
          setButtonLoading(false);
          setRefreshMainTable((prev: number) => prev + 1); //main template table
          getLanguageList(); //get new language list already used language
          setOpenAdd(false);
        })
        .catch(() => {
          setButtonLoading(false);
        });
    } else {
      updateLocalizedTemplates(localizedTemplate.id, data.template_body)
        .then(() => {
          enqueueSnackbar(`Local Template successfully updated!`, { variant: 'success' });
          setRefreshTemplates((prev: number) => prev + 1);
          setButtonLoading(false);
          setRefreshMainTable((prev: number) => prev + 1);
          setOpenEdit(false);
        })
        .catch(() => {
          setButtonLoading(false);
        });
    }
  };

  const viewTemplateBody = async (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const selectedTemplate = rows.find((localizedTemplate) => localizedTemplate.id === selected[0]);
      setSelectedRow(selectedTemplate);
      setPreviewContent(selectedTemplate.template_body);
      setOpenTemplatePreview(true);
    } else {
      setSelectedRow(undefined);
    }
  };

  useEffect(() => {
    setPreview(replaceCompanySmsTemplate(templateBody, facility));
  }, [templateBody]);

  useEffect(() => {
    setRefreshTemplates((prev: number) => prev + 1);
  }, [template]);

  const scheduleFormFields: FieldInput[] = [
    {
      field_name: 'language_id',
      display_name: 'Language',
      type: 'select',
      options: languages?.map((u: any) => {
        return { key: u.language, value: u.id };
      }),
      hidden: toAdd ? false : true,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Preview ({templateBody.length} characters)
          </Typography>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Special tokens
          </Typography>{' '}
          <br></br>
          <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" gap="10px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                >
                  <span style={{ backgroundColor: colors.textBackground, borderRadius: '5px', padding: '6px' }}>
                    {' '}
                    {token.token}{' '}
                  </span>
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {token.description}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary, display: 'flex', gap: '20px' }}>
          <Typography variant="h6" color={colors.text}>
            Special tokens
          </Typography>
          <Box display="flex" gap="5px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    transition: 'color 0.3s',
                    color: colors.text,
                  }}
                  onClick={() => handleTokenClick(token.token)}
                >
                  <span
                    style={{
                      backgroundColor: colors.textBackground,
                      borderRadius: '5px',
                      padding: '6px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondary;
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = colors.textBackground;
                      e.currentTarget.style.color = colors.text;
                    }}
                  >
                    {token.token}
                  </span>
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      field_name: 'template_body',
      display_name: 'SMS Template',
      type: 'text',
      multiline: true,
      rows: 3,
      span: 4,
      onChange: (value) => setTemplateBody(value),
    },
  ];

  const handleTokenClick = (clickedToken: string) => {
    const updatedTemplateBody = templateBody + ' ' + clickedToken;
    setTemplateBody(updatedTemplateBody);
    setPreview(replaceCompanySmsTemplate(updatedTemplateBody, facility));
  };

  const getLanguageList = () => {
    getAvailableLanguages(template.id, company.id)
      .then((res) => {
        setLanguages(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <Box>
      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        getData={getCompanyLocalizedTemplates}
        rowId={'id'}
        forceRefresh={refreshTemplates}
        selectableRow
        handleSelectRow={(data) => viewTemplateBody(data)}
        clearSelected={clearSelected}
        headerComponent={
          <Box display="flex" gap="10px">
            <PrimaryButton
              onClick={() => {
                setToAdd(true);
                setOpenAdd(true);
                setTemplateBody('');
              }}
              label="Add Local Template"
            />
          </Box>
        }
      />

      <CustomModal
        width={800}
        header={toAdd ? 'Add Template' : 'Edit Template'}
        open={toAdd ? openAdd : openEdit}
        setOpen={toAdd ? setOpenAdd : setOpenEdit}
      >
        <Typography variant="h5" color={colors.text} fontWeight="bold" marginBottom={'0.5rem'}>
          Special tokens
        </Typography>
        <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" sx={{ marginBottom: '20px' }}>
          {specialTokens.map((token, index) => (
            <Fragment key={index}>
              <Typography variant="body1" color={colors.text}>
                {token.token}
              </Typography>
              <Typography variant="body1" color={colors.text}>
                {token.description}
              </Typography>
            </Fragment>
          ))}
        </Box>

        <Box sx={{ marginBlock: '20px', p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Preview ({templateBody.length} characters)
          </Typography>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>

        <CustomForm
          initialValues={toAdd ? { language_id: '', template_body: '' } : { template_body: templateBody }}
          onSubmit={handleSubmit}
          fields={scheduleFormFields}
          schema={toAdd ? add_sms_template_schema : sms_template_schema}
          loading={buttonLoading}
        />
      </CustomModal>

      <ViewContent
        content={previewContent}
        open={openTemplatePreview}
        setOpen={setOpenTemplatePreview}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      />
    </Box>
  );
};

export default LocalizedSms;
