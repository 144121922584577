import { Box, Button, Typography } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomTable, ProtectedComponent } from 'core/components';
import { cancelPurchaseRequest, getPurchaseRequests } from 'lgu/api/purchase-request';

import { GridColDef } from '@mui/x-data-grid';
import PurchaseAdditionalCreditsForm from './PurchaseAdditionalCreditsForm';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const PurchaseAdditionalCredits = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshRequest, setRefreshRequest] = useState<number>(0);
  const [request, setRequest] = useState<number>();
  const [openCancelPurchaseRequestModal, setOpenCancelPurchaseRequestModal] = useState<boolean>(false);

  const pendingRequestColumns: GridColDef[] = [
    { field: 'credits', headerName: 'SMS', flex: 1 },
    { field: 'cost', headerName: 'Cost in PHP', flex: 1 },
    { field: 'full_name', headerName: 'Requested by', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <Typography>{formatDate(params.row.created_at)}</Typography>,
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setRequest(params.row.id);
              setOpenCancelPurchaseRequestModal(true);
            }}
          >
            Cancel Request
          </Button>
        </Box>
      ),
    },
  ];

  const getPendingRequests = () => {
    setLoading(true);
    getPurchaseRequests()
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const cancelRequest = () => {
    cancelPurchaseRequest(request!)
      .then((res) => {
        enqueueSnackbar(`Purchase Request successfully canceled!`, { variant: 'success' });
        getPendingRequests();
      })
      .catch((err) => console.error(err));
  };

  return (
    <ProtectedComponent requiredAuth={['LGU_ADMIN_ACTIONS']}>
      <CustomContainer m="20px 0 0 0">
        <PurchaseAdditionalCreditsForm setRefreshRequests={setRefreshRequest} />

        <CustomTable
          columns={pendingRequestColumns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getPendingRequests}
          forceRefresh={refreshRequest}
        />
      </CustomContainer>

      <ConfirmationDialog
        content="Are you sure you want to cancel this request?"
        open={openCancelPurchaseRequestModal}
        setOpen={setOpenCancelPurchaseRequestModal}
        onConfirm={cancelRequest}
      />
    </ProtectedComponent>
  );
};

export default PurchaseAdditionalCredits;
