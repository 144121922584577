/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Step, StepContent, StepLabel, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { getService, getServiceTimeBlocks } from 'company/api/services';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ConfirmationStage from './components/ConfirmationStage';
import CustomForm from '../../../../core/components/forms/CustomForm';
import { CustomModal } from 'core/components';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Link } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactMarkdown from 'react-markdown';
import { Stepper } from '@mui/material';
import { createBooking } from 'company/api/bookings';
import { create_patients_schema } from 'lgu/model/schema';
import { formatNumber } from 'core/utils';
import { getFacilityBookingDetails } from 'company/api/facility';
import moment from 'moment';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

interface BookingResponse {
  booking: {
    id: number;
  };
}
const BookAppointments = () => {
  const navigate = useNavigate();

  const { facility_code, service_id } = useParams();
  const [selectedService, setSelectedService] = useState({
    name: '',
    description: '',
    price: '', // changed from number to string
  });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasAcceptedTandC, setHasAcceptedTandC] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [serviceBanner, setServiceBanner] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    sex: '',
    birthday: '',
    address: '',
    mobileNumber: '',
    email: '',
    selectedDate: null as Dayjs | null,
    selectedTime: '',
    message: '',
  });
  const [facilityId, setFacilityId] = useState(0);
  const [address, setAdress] = useState('');
  const [facName, setFacName] = useState('');
  const [number, setNumber] = useState('');
  const [timeblocks, setTimeblocks] = useState<any[]>();
  const [day, setDay] = useState<any>();
  const currentTime = new Date();
  const [serviceLeadTime, setServiceLeadTime] = useState<number>();
  const minTime = dayjs(currentTime).add(serviceLeadTime ? serviceLeadTime : 0, 'hours');
  const filteredTimeSlots =
    selectedDate && timeblocks && timeblocks[day]
      ? timeblocks[day].filter((slot: string) => dayjs(`${selectedDate.format('YYYY-MM-DD')} ${slot}`).isAfter(minTime))
      : [];

  const handleConfirm = () => {
    setIsModalOpen(false);
    setHasAcceptedTandC(true);
  };

  const toggleCheckbox = () => {
    setHasAcceptedTandC(!hasAcceptedTandC);
  };

  const handleConfirmInfos = () => {
    setIsLoading(true);

    if (!facility_code) return;
    const data = {
      first_name: formData.firstName,
      middle_name: formData.middleName,
      last_name: formData.lastName,
      schedule: moment(formData.selectedDate?.format('YYYY-MM-DD') + ' ' + selectedTimeSlot).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      mobile_number: formData.mobileNumber,
      email: formData.email,
      service_id: service_id,
      sex: formData.sex,
      birthday: formData.birthday,
      message: formData.message,
    };

    createBooking(facilityId, data).then((response) => {
      const data: BookingResponse = response.data;
      const { id } = data.booking;
      navigate(`/booking/${id}`);
    });
  };

  const handleTimeSlotClick = (slot: string) => {
    setSelectedTimeSlot(slot);
    setFormData((prevState) => ({ ...prevState, selectedTime: slot }));
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    setFormData((prevState) => ({ ...prevState, selectedDate: date }));
  };

  const handleFormSubmitAndNext = (data: any) => {
    setFormData({ ...formData, ...data });
    handleNext();
  };

  const handleNext = () => {
    if (activeStep === 1 && !hasAcceptedTandC) {
      alert('Please read and accept the terms and conditions before proceeding.');
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/book/appointment/${facility_code}`);
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  //newly added
  const getTimeBlocks = () => {
    if (selectedDate) {
      const dayjs = require('dayjs');
      const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
      getServiceTimeBlocks(facility_code!, parseInt(service_id!), formattedDate).then((res) => {
        setTimeblocks(res.data);
      });
    }
  };

  const getServiceDetails = async () => {
    try {
      const { data }: any = await getService(facility_code!, parseInt(service_id!));
      setSelectedService({
        name: data.service_name,
        description: data.description,
        price: data.price,
      });
      setServiceLeadTime(data.lead_time_value);
    } catch (error) {}
  };

  const getClinicDetails = async () => {
    try {
      const { data } = await getFacilityBookingDetails(facility_code!);
      setAdress(data.address);
      setFacName(data.facility_name);
      setNumber(data.smart_mobile_number);
      setServiceBanner(data.banner_image);
      setFacilityId(data.id);
    } catch (error) {}
  };

  useEffect(() => {
    getClinicDetails();
  }, []);

  useEffect(() => {
    if (service_id && facility_code) {
      getClinicDetails();
      getTimeBlocks();
      getServiceDetails();
    }
  }, [service_id, facility_code]);

  useEffect(() => {
    if (selectedDate) setDay(selectedDate.format('dddd'));
    getTimeBlocks();
  }, [selectedDate]);

  const stepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <Wrapper> */}

              <CalendarTimeSlotsWrapper>
                <CalendarWrapper>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    // shouldDisableDate={(date) => isWeekend(date) || isPastDate(date)}
                    className="custom-calendar"
                    disablePast
                    sx={{
                      '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                        backgroundColor: 'rgba(7, 104, 176, 0.25)', // Set the day color
                      },

                      '@media (min-width: 768px)': {
                        '& .MuiButtonBase-root.MuiPickersDay-root': {
                          fontSize: '12px',
                          // padding: '18px',
                          // margin: '4px',
                          fontFamily: 'Nunito',
                        },
                        '& .MuiTypography-root.MuiTypography-caption': {
                          fontSize: '15px', // Adjust the font size of the weekday labels
                          // margin: '4px',
                          fontFamily: 'Nunito',
                        },
                      },
                    }}
                  />
                </CalendarWrapper>
                <TimeSlotsWrapper>
                  <CurrentDate>
                    {selectedDate ? selectedDate.format('dddd, MMMM D, YYYY') : 'No date selected'}
                  </CurrentDate>

                  {selectedDate && (
                    <>
                      <GroupLabel>Morning</GroupLabel>
                      <Con>
                        {filteredTimeSlots
                          .filter((slot: any) => dayjs(`${selectedDate.format('YYYY-MM-DD')} ${slot}`).hour() < 12)
                          .map((slot: string) => (
                            <TimeSlotButton
                              key={slot}
                              onClick={() => handleTimeSlotClick(slot)}
                              selected={selectedTimeSlot === slot}
                            >
                              {slot}
                            </TimeSlotButton>
                          ))}
                      </Con>
                      <GroupLabel>Afternoon</GroupLabel>
                      <Con>
                        {filteredTimeSlots
                          .filter((slot: any) => dayjs(`${selectedDate.format('YYYY-MM-DD')} ${slot}`).hour() >= 12)
                          .map((slot: string) => (
                            <TimeSlotButton
                              key={slot}
                              onClick={() => handleTimeSlotClick(slot)}
                              selected={selectedTimeSlot === slot}
                            >
                              {slot}
                            </TimeSlotButton>
                          ))}
                      </Con>
                    </>
                  )}
                </TimeSlotsWrapper>
              </CalendarTimeSlotsWrapper>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#0768B0',
                  textTransform: 'none',
                  width: '90%',
                  margin: '20px 0 0 0',
                  borderRadius: '50px',
                  padding: '8px;',
                  color: 'white',
                  height: '40px',
                  '&:hover': {
                    backgroundColor: '#053e69f4',
                  },
                  '@media (min-width: 768px)': {
                    width: '60%',
                  },
                }}
                onClick={handleNext}
                disabled={!selectedDate || !selectedTimeSlot}
              >
                Next
              </Button>
            </LocalizationProvider>
          </Box>
        );

      case 1:
        return (
          <>
            <Button
              onClick={handleBack}
              sx={{ marginBottom: '10px', color: '#0768b0', textTransform: 'none' }}
              startIcon={<ArrowUpwardIcon style={{ color: '#0768b0' }} />}
            >
              Go back to previous step
            </Button>
            <ButtonWrapper>
              <FormContainer>
                <CustomForm
                  schema={create_patients_schema}
                  onSubmit={(values) => handleFormSubmitAndNext(values)}
                  fields={[
                    { field_name: 'firstName', display_name: 'First Name', type: 'text' },
                    { field_name: 'middleName', display_name: 'Middle Name', type: 'text' },
                    { field_name: 'lastName', display_name: 'Last Name', type: 'text' },
                    {
                      field_name: 'sex',
                      display_name: 'Sex Assigned at Birth',
                      type: 'select',
                      options: [
                        { key: 'Male', value: 'Male' },
                        { key: 'Female', value: 'Female' },
                      ],
                    },
                    {
                      field_name: 'birthday',
                      display_name: 'Birthday',
                      type: 'date',

                      disableFuture: true,
                    },
                    { field_name: 'mobileNumber', display_name: 'Mobile Number', type: 'text' },
                    { field_name: 'email', display_name: 'Email', type: 'text', optional: true },
                    { field_name: 'address', display_name: 'Address', type: 'text' },
                    { field_name: 'message', display_name: 'Message', type: 'text', multiline: true, rows: 5 },
                  ]}
                  initialValues={formData}
                  loading={false}
                  submitButtonText="Continue"
                  disabled={!hasAcceptedTandC}
                  useReCAPTCHA
                  buttonBackgroundColor="#0768B0"
                  color="white"
                  buttonWidth="100%"
                  borderRadius="50px"
                  colorHover="#074472"
                  heightButton="40px"
                  marginButton="15px 0px"
                />
              </FormContainer>

              <CheckWrapper>
                <Checkbox
                  checked={hasAcceptedTandC}
                  onChange={toggleCheckbox}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#0768B0', // Color when checked
                    },
                  }}
                />

                <Link
                  component="button"
                  variant="body2"
                  underline="hover"
                  onClick={() => setIsModalOpen(true)}
                  sx={{ fontSize: '14px', color: '#0768B0' }}
                >
                  I have already read the Terms and Conditions
                </Link>
              </CheckWrapper>
            </ButtonWrapper>

            <CustomModal
              header="Terms & Conditions and Privacy Policy"
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onClose={() => setIsModalOpen(false)}
            >
              <Typography paragraph>
                Welcome to our clinic booking platform! By using our platform, you agree to the following terms and
                conditions:
              </Typography>
              <Typography variant="h5">Terms & Conditions</Typography>
              <Typography paragraph>
                1. Our platform is for booking appointments with clinics only. Any other use of the platform is strictly
                prohibited.
              </Typography>
              <Typography paragraph>
                2. You are responsible for providing accurate information when booking an appointment.
              </Typography>
              <Typography paragraph>
                3. Cancellations and rescheduling of appointments must be done according to the clinic's policies.
              </Typography>

              <Typography variant="h5">Privacy Policy</Typography>
              <Typography paragraph>
                1. We collect personal information from you such as your name, contact information, and appointment
                details in order to provide our services.
              </Typography>
              <Typography paragraph>
                2. We may share your personal information with the clinic you have booked an appointment with.
              </Typography>
              <Typography paragraph>
                3. We will not sell or share your personal information with third parties for marketing purposes.
              </Typography>
              <Typography paragraph>
                4. We take reasonable steps to protect your personal information from unauthorized access or disclosure.
              </Typography>
              <Typography paragraph>
                By clicking the checkbox and continuing to use our platform, you acknowledge that you have read,
                understood, and accept these terms and conditions and privacy policy.
              </Typography>
              <Button variant="contained" color="primary" onClick={handleConfirm}>
                Accept
              </Button>
            </CustomModal>
          </>
        );

      case 2:
        return (
          <ConfirmationStage
            isLoading={isLoading}
            number={number}
            formData={formData}
            serviceBanner={serviceBanner}
            address={address}
            facName={facName}
            selectedService={selectedService}
            handleConfirmInfos={handleConfirmInfos}
            handleBack={handleBack}
            mobileNumber={formData.mobileNumber}
            sexAssignedAtBirth={formData.sex}
            email={formData.email}
            personalAddress={formData.address}
            birthday={formData.birthday}
          />
        );

      default:
        return 'Unknown step';
    }
  };
  const stepLabels = ['Select a Date and Time', 'Fill the Form', 'Confirm Appointment'];

  return (
    <Container>
      <Wrapper>
        <ServiceContainer>
          <Header>
            <ArrowBackIosIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
            <Badge>{`Step ${activeStep + 1} of 3: ${stepLabels[activeStep]}`}</Badge>
          </Header>
          <ServiceTitle>{`Service: ${selectedService.name}`}</ServiceTitle>
          <ServiceDescription rehypePlugins={[rehypeRaw]}>{selectedService.description}</ServiceDescription> <br></br>
          <ServicePrice>{`${formatNumber(parseFloat(selectedService.price))}`}</ServicePrice>
        </ServiceContainer>

        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            '& .MuiStepIcon-root.Mui-active': {
              color: 'rgba(7, 104, 176, 0.25)',
            },
            '& .MuiStepIcon-root.Mui-completed': {
              color: 'rgba(7, 104, 176, 0.25)',
            },
            '& .MuiStepIcon-root': {
              fontWeight: 'bold',
              fontSize: '24px',
            },
            '@media (min-width: 768px)': {
              '& .MuiStepIcon-root': {
                fontWeight: 'bold',
                fontSize: '30px',
              },
            },
            marginTop: '150px',
          }}
        >
          <Step
            sx={{
              '@media (min-width: 768px)': {
                width: '70vw',
              },
            }}
          >
            <StepLabel>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  '@media (min-width: 768px)': {
                    fontSize: '15px',
                  },
                }}
              >
                Select a Date and Time
              </Typography>
            </StepLabel>
            <StepContent>{stepContent(0)}</StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  '@media (min-width: 768px)': {
                    fontSize: '15px',
                  },
                }}
              >
                Input Patient Details
              </Typography>
            </StepLabel>
            <StepContent>{stepContent(1)}</StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  '@media (min-width: 768px)': {
                    fontSize: '15px',
                  },
                }}
              >
                Confirm Details
              </Typography>
            </StepLabel>
            <StepContent>{stepContent(2)}</StepContent>
          </Step>
        </Stepper>
      </Wrapper>
    </Container>
  );
};
export default BookAppointments;
// const ServiceContainer = styled.div`
//   padding: 40px 0 20px 0;
//   @media (min-width: 768px) {
//     width: 70%;
//   }
// `;
const ServiceContainer = styled.div`
  padding: 60px 20px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  background-color: #f8f8f8;
  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ServiceTitle = styled.div`
  font-size: 17px;
  color: #333;
  font-family: Nunito;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const ServiceDescription = styled(ReactMarkdown)`
  font-size: 12px;
  color: #888;
  font-family: Nunito;
  margin: -12px 0;
  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

const ServicePrice = styled.div`
  font-size: 13px;
  font-weight: 700;

  color: #0768b0;
  font-family: Nunito;

  &:before {
    content: 'PHP ';
  }
  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CalendarTimeSlotsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const CalendarWrapper = styled.div`
  flex: 1;

  display: flex;

  @media (min-width: 768px) {
    margin-bottom: 20px;
    width: 90%;
  }
  /* 
  .custom-calendar .MuiPickersDay-day {
    color: rgba(7, 104, 176, 0.25);
  }

  .custom-calendar .MuiPickersDay-daySelected {
    background-color: rgba(7, 104, 176, 0.25);
    &:hover {
      background-color: rgba(7, 104, 176, 0.4);
    }
  }

  .custom-calendar .MuiPickersDay-dayDisabled {
    color: #ccc;
  } */
`;
const TimeSlotsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 12px;
  }
  @media (min-width: 768px) {
    width: 700px;
  }
`;
const TimeSlotButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? '#0768B0' : '#f0f0f0')};
  color: ${(props) => (props.selected ? '#fff' : 'black')};
  border: 1px solid;
  border-radius: 18px;
  padding: 4px 15px;
  margin-bottom: 8px;

  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #0768b0;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    padding: 5px 70px;
    font-size: 15px;
    margin: 0px 5px;
  }
`;

const CurrentDate = styled.div`
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  font-family: Nunito;
  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

const FormContainer = styled.div`
  display: flex;

  flex-direction: column;
  @media (max-width: 768px) {
    width: auto;
  }
  @media (min-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    width: 65vw;

    justify-content: center;
  }
`;
const CheckWrapper = styled.div`
  position: absolute;
  bottom: 55px;
  left: -10px;
`;

const Con = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (min-width: 768px) {
    gap: 20px;
  }
`;
const GroupLabel = styled.div`
  font-size: 12px;
  font-family: Nunito;
  margin: 12px 10px;
  @media (min-width: 768px) {
    font-size: 15px;
    font-weight: bold;
  }
`;
// const Header = styled.div`
//   padding: 0px 5px 30px 5px;
//   align-items: center;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// `;
const Header = styled.div`
  padding: 10px 20px;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  z-index: 1000;
`;
const Badge = styled.div`
  background-color: rgba(7, 104, 176, 0.25);
  font-size: 12px;
  font-weight: bold;
  padding: 2px 12px;
  border-radius: 20px;
  font-family: 'Nunito';
  color: #0768b0;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;
