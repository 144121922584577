import { ApiQuery } from 'core/model/interface';
import { Patients } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'campaign-patients';

export const getPatients = (facility_id: number, outreach_id: number, query: ApiQuery, tags?: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${outreach_id}`, { params: { ...tags, ...query } });
};

export const enrollPatients = (facility_id: number, outreach_id: number, patients: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${outreach_id}`, patients);
};

export const removePatients = (facility_id: number, patients: Patients) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}`, { params: patients });
};

export const getPatientsNotYetAdded = (facility_id: number, outreach_id: number, query: ApiQuery, tags?: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/not-added/${outreach_id}`, { params: { ...tags, ...query } });
};
