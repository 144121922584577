import { CustomForm, CustomModal } from 'core/components';
import { enrollPatient, updatePatient } from 'company/api/program-patients';
import { useContext, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { ProgramPatientModel } from 'company/model/Entities';
import dayjs from 'dayjs';
import { tb_dots_company_schema } from 'lgu/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type TbEnrollModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  programCode: string;
  selectedPatient?: ProgramPatientModel;
  selectedPatients?: ProgramPatientModel[];
  updateInitialValues?: UserInput;
  refreshTable: () => void;
  setRefreshUnenrolled: any;
  type: 'create' | 'update';
};

export type UserInput = {
  id?: number;
  start_datetime: string;
  sending_time: string;
  tb_status: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  tb_status: 'Intensive',
  start_datetime: '',
  sending_time: '',
};

const TbEnrollModal: React.FC<TbEnrollModalProps> = ({
  header,
  open,
  setOpen,
  programCode,
  selectedPatient,
  updateInitialValues,
  refreshTable,
  setRefreshUnenrolled,
  type,
  selectedPatients,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const tbDotsFields: UserFieldInput[] = [
    {
      field_name: 'tb_status',
      display_name: 'Status',
      type: 'select',
      options:
        type === 'create'
          ? [{ key: 'Active', value: 'Intensive' }]
          : [
              { key: 'Active', value: 'Intensive' },
              { key: 'Treated', value: 'Treated' },
              { key: 'Rifampicin-resistant', value: 'Rifampicin-resistant' },
            ],
    },
    { field_name: 'start_datetime', display_name: 'Patient Start Date', type: 'date', minutesStep: 5 },
    { field_name: 'sending_time', display_name: 'SMS Reminder Time', type: 'time', minutesStep: 5 },
  ];

  const handleTbFormSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if ((selectedPatients && selectedPatients.length > 0) || selectedPatient) {
      if (updateInitialValues) {
        updatePatient(facility.id, updateInitialValues.id!, data).then((res: any) => {
          successFormSubmit(res, formikHelpers);
        });
      } else {
        const patients = JSON.stringify(
          selectedPatients && selectedPatients.length > 0 ? selectedPatients : [selectedPatient?.patient_id]
        );
        const start_date_time = dayjs(data.start_datetime);
        data.patients = patients;
        data.tb_sputum_schedule1 =
          dayjs(start_date_time.add(8, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time;
        data.tb_sputum_schedule2 =
          dayjs(start_date_time.add(20, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time;
        data.tb_sputum_schedule3 =
          dayjs(start_date_time.add(24, 'weeks')).format('YYYY-MM-DD') + ' ' + data.sending_time;

        enrollPatient(facility.id, programCode, data)
          .then((res: any) => {
            successFormSubmit(res, formikHelpers);
          })
          .catch((error) => {
            setButtonLoading(false);
          });
      }
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
    setRefreshUnenrolled((prev: number) => prev + 1);
  };

  return (
    <>
      <CustomModal
        header={`${header} ${
          selectedPatients && selectedPatients.length > 0
            ? selectedPatients?.length + ' Patients'
            : selectedPatient?.full_name
        }`}
        open={open}
        setOpen={setOpen}
      >
        <CustomForm
          initialValues={updateInitialValues ? updateInitialValues : initialValues}
          onSubmit={handleTbFormSubmit}
          fields={tbDotsFields}
          schema={tb_dots_company_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default TbEnrollModal;
