import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomJsonPrettier from 'core/components/dataView/CustomJsonPrettier';

export interface DetailsViewerDataFormat {
  summary: any;
  values: any;
  json?: any;
  isMono?: boolean;
  isJSON?: boolean;
  hasJSON?: boolean;
}

export interface DetailsViewerProps {
  data: DetailsViewerDataFormat[];
}

const DetailsViewer: React.FC<DetailsViewerProps> = ({ data }) => {
  return (
    <Box gap={3}>
      {data.length > 0
        ? data.map((requestData) => {
            return (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={'bold'}>{requestData.summary}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    fontFamily: requestData.isMono ? 'monospace' : '',
                    fontSize: requestData.isMono ? '11px' : '',
                    overflowWrap: 'break-word',
                  }}
                >
                  {requestData.hasJSON && requestData.json ? (
                    <>
                      <Typography gutterBottom>{requestData.values}</Typography>
                      <CustomJsonPrettier data={requestData.json} />
                    </>
                  ) : requestData.isJSON ? (
                    // <NestedDetails data={requestData.values} />
                    <CustomJsonPrettier data={requestData.values} />
                  ) : !requestData.values || requestData.values !== '' ? (
                    <Typography>{requestData.values}</Typography>
                  ) : (
                    <Typography>NULL</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })
        : 'No request data to be shown'}
    </Box>
  );
};

export default DetailsViewer;
