import * as yup from 'yup';

import { CustomInlineForm, CustomModal } from 'core/components';
import { useContext, useState } from 'react';

import { ClinicModel } from '../../ClinicManagement/Clinic/ClinicModel';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { ProductVariantModel } from '../Product/ProductVariant/ProductVariantModel';
import { updateInventoryPrice } from 'company/api/inventories';
import { useSnackbar } from 'notistack';
import { formatAttributeValues } from 'core/utils';

const updatePriceSchema = yup.object().shape({
  facility_inventory_price: yup.number().typeError('Invalid Value.'),
});

type Props = {
  variant: ProductVariantModel;
  open: boolean;
  setOpen: (open: boolean) => void;
  facilityDetails?: ClinicModel;
  callbackAfterSubmit?: (data?: any) => void;
  exclusiveSupplier?: number;
};

const UpdatePrice: React.FC<Props> = ({
  variant,
  open,
  setOpen,
  facilityDetails,
  callbackAfterSubmit,
  exclusiveSupplier,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const updatePriceFields: FieldInput[] = [
    {
      field_name: 'facility_inventory_price',
      display_name: 'Price',
      type: 'text',
      span: 1,
    },
  ];

  const handleSubmit = async (data: any) => {
    const updateData = {
      facility_id: facility.id,
      facility_inventory_price: data.facility_inventory_price,
      product_id: variant.product_id,
    };
    setLoading(true);
    updateInventoryPrice(variant!.id, updateData).then((res) => {
      enqueueSnackbar('Price successfully updated!', { variant: 'success' });
      setLoading(false);
      setOpen(false);
      localStorage.removeItem('orders');
      callbackAfterSubmit && callbackAfterSubmit();
    });
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      header={`Update Price - ${variant.product_name}${formatAttributeValues(variant.attributes, true)}`}
      subHeader={facilityDetails ? facilityDetails.facility_name : facility?.facility_name}
      width={500}
    >
      <CustomInlineForm
        fields={updatePriceFields}
        schema={updatePriceSchema}
        initialValues={{
          facility_inventory_price: variant.price,
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CustomModal>
  );
};

export default UpdatePrice;
