import AccountingAppSidebar from './components/AccountingAppSidebar';
import AppLayout from './components/AppLayout';
import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
};

const AccountingAppLayout: React.FC<LayoutProps> = ({ children }) => {
  return <AppLayout sidebar={<AccountingAppSidebar />}>{children}</AppLayout>;
};

export default AccountingAppLayout;
