/* eslint-disable react-hooks/exhaustive-deps */

import { APPOINTMENT_ACTION, PatientRecordType } from 'core/model/interface';
import {
  CompanyAppointmentModel,
  STATUS,
} from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { ConfirmationDialog, CustomModal, HideForInactiveCompany, RowAction } from 'core/components';
import { updateAttendance, updateNoShow } from 'company/api/appointments';
import { useContext, useRef, useState } from 'react';

import AppointmentProductTable from 'company/screens/RegistrationBoard/components/AppointmentProductTable';
import AppointmentSettingsModal from './modal/AppointmentSettingsModal';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { FacilityContext } from 'core/context/facility.context';
import LabRequestForm from 'company/entities/modules/ClinicManagement/LabRequest/LabRequestForm';
import MedicationIcon from '@mui/icons-material/Medication';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import PrescriptionForm from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionForm';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScienceIcon from '@mui/icons-material/Science';
import SendIcon from '@mui/icons-material/Send';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ViewInvoiceClick from 'company/screens/RegistrationBoard/components/ViewInvoiceClick';
import { useSnackbar } from 'notistack';

type Props = {
  appointment: CompanyAppointmentModel;
  showLabel?: boolean;
  updateAppointmentData: () => void;
  navigateToEditor?: boolean;
  onCreateItem?: (data: any, type: PatientRecordType) => void;
  visibleActions?: APPOINTMENT_ACTION[];
};

const AppointmentSettings: React.FC<Props> = ({
  appointment,
  updateAppointmentData,
  showLabel,
  navigateToEditor,
  onCreateItem,
  visibleActions,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const modalRef: any = useRef();

  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);

  // const [openEditDental, setOpenEditDental] = useState(false);
  // const [formValues, setFormValues] = useState<any>({});
  const [action, setAction] = useState<APPOINTMENT_ACTION>(APPOINTMENT_ACTION.NO_ACTION);
  const [opened, setOpened] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openConfirmationDialogLabReq, setOpenConfirmationDialogLabReq] = useState<boolean>(false);
  const [openInvoiceClick, setOpenInvoiceClick] = useState<boolean>(false);
  const [openProductsAddedModal, setOpenProductsAddedModal] = useState<boolean>(false);
  const [openCreatePrescription, setOpenCreatePrescription] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  const settings_actions: DropdownOptionProps[] = [
    {
      label: APPOINTMENT_ACTION.CREATE_INVOICE,
      icon: <ReceiptIcon />,
      hidden:
        (appointment && [STATUS.NO_SHOW, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.CREATE_INVOICE)),
      action: () => {
        setAction(APPOINTMENT_ACTION.CREATE_INVOICE);
        setOpenAppointmentModal(true);
      },
    },
    // {
    //   label: APPOINTMENT_ACTION.VIEW_INVOICE,
    //   icon: <ReceiptIcon />,
    //   hidden: appointment && !appointment.invoice_id,
    //   action: () => {
    //     setOpenInvoiceClick(true);
    //   },
    // },
    {
      label: APPOINTMENT_ACTION.CREATE_PRESCRIPTION,
      icon: <MedicationIcon />,
      hidden:
        (appointment && [STATUS.NO_SHOW, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.CREATE_PRESCRIPTION)),
      action: () => {
        setOpenCreatePrescription(true);
      },
    },
    {
      label: APPOINTMENT_ACTION.CREATE_NOTE,
      icon: <CreateOutlinedIcon />,
      hidden:
        (appointment && [STATUS.NO_SHOW, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.CREATE_NOTE)),
      action: () => {
        setAction(APPOINTMENT_ACTION.CREATE_NOTE);
        setOpenAppointmentModal(true);
      },
    },
    {
      label: APPOINTMENT_ACTION.CREATE_LAB_REQUEST,
      icon: <ScienceIcon />,
      hidden:
        (appointment && [STATUS.NO_SHOW, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.CREATE_LAB_REQUEST)),
      action: () => confirmCreateLabReq(appointment),
    },
    {
      label: APPOINTMENT_ACTION.PRODUCTS_ADDED,
      icon: <PlaylistAddCheckOutlinedIcon />,
      hidden:
        (appointment && [STATUS.NO_SHOW, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.PRODUCTS_ADDED)),
      action: () => {
        setOpenProductsAddedModal(true);
      },
    },
    {
      label: 'divider',
      hidden: (appointment && [STATUS.CANCELLED, STATUS.NO_SHOW].includes(appointment.status)) || !!visibleActions,
    },
    {
      label: APPOINTMENT_ACTION.FOLLOW_UP_REMINDER,
      hidden:
        (appointment && [STATUS.DONE, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.FOLLOW_UP_REMINDER)),
      icon: <SendIcon />,
      action: () => {
        setAction(APPOINTMENT_ACTION.FOLLOW_UP_REMINDER);
        setOpenAppointmentModal(true);
      },
    },
    {
      label: APPOINTMENT_ACTION.RESCHEDULE,
      hidden:
        (appointment && [STATUS.DONE, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.RESCHEDULE)),
      icon: <EventRepeatIcon />,
      action: () => {
        setAction(APPOINTMENT_ACTION.RESCHEDULE);
        setOpenAppointmentModal(true);
      },
    },
    {
      label: APPOINTMENT_ACTION.UPDATE_SERVICE,
      hidden:
        (appointment?.status !== STATUS.PENDING && appointment?.status !== STATUS.RESCHEDULED) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.UPDATE_SERVICE)),
      icon: <VaccinesIcon />,
      action: () => {
        setAction(APPOINTMENT_ACTION.UPDATE_SERVICE);
        setOpenAppointmentModal(true);
      },
    },
    {
      label: APPOINTMENT_ACTION.UPDATE_BATCH,
      hidden: !visibleActions || !visibleActions.includes(APPOINTMENT_ACTION.UPDATE_BATCH),
      icon: <CreateOutlinedIcon />,
      action: () => {
        setAction(APPOINTMENT_ACTION.UPDATE_BATCH);
        setOpenAppointmentModal(true);
      },
    },
    {
      label: 'divider',
      hidden:
        (appointment && [STATUS.DONE, STATUS.CANCELLED, STATUS.NO_SHOW].includes(appointment.status)) ||
        !!visibleActions,
    },
    {
      label: 'Attended',
      hidden:
        (appointment && [STATUS.DONE, STATUS.CANCELLED].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.ATTENDED)),
      icon: <CheckBoxOutlinedIcon />,
      action: () =>
        updateAttendance(facility.id, appointment.id, true).then(() => {
          enqueueSnackbar('Appointment status successfully updated!', { variant: 'success' });
          updateAppointmentData();
        }),
    },
    {
      label: 'No Show',
      hidden:
        (appointment && [STATUS.CANCELLED, STATUS.NO_SHOW].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.NO_SHOW)),
      icon: <PersonOffIcon />,
      action: () => confirmNoShow(appointment),
    },
    {
      label: 'Cancel Appointment',
      hidden:
        (appointment && [STATUS.DONE, STATUS.CANCELLED, STATUS.NO_SHOW].includes(appointment.status)) ||
        (visibleActions && !visibleActions.includes(APPOINTMENT_ACTION.CANCEL)),
      icon: <EventBusyIcon />,
      action: () => {
        setAction(APPOINTMENT_ACTION.CANCEL);
        setOpenAppointmentModal(true);
      },
    },
  ];

  const confirmNoShow = (data: any) => {
    setOpenConfirmationDialog(true);

    if (data) {
      setAlertContent('Are you sure you want to update attendance of ' + data.full_name + ' to "no-show" ?');
    }
  };

  const confirmCreateLabReq = (data: any) => {
    setOpenConfirmationDialogLabReq(true);

    if (data) {
      setAlertContent(
        data.full_name
          ? `Create lab request for patient ${data.full_name}?`
          : `Create lab request for this appointment?`
      );
    }
  };

  const handleNoShow = async () => {
    const appointments = JSON.stringify([appointment!.id]);
    const data = { appointments: appointments };

    return updateNoShow(facility.id, data).then(() => {
      enqueueSnackbar('Appointment status successfully updated!', { variant: 'success' });
      updateAppointmentData();
    });
  };

  return (
    <>
      <HideForInactiveCompany>
        {appointment && ![STATUS.CANCELLED].includes(appointment.status) && (
          <RowAction
            actions={settings_actions}
            label={showLabel ? 'Settings' : undefined}
            notColored={showLabel}
            getRow={() => setOpened(true)}
          />
        )}
      </HideForInactiveCompany>

      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        content={alertContent}
        onConfirm={handleNoShow}
      />

      <CustomModal
        open={openConfirmationDialogLabReq}
        setOpen={setOpenConfirmationDialogLabReq}
        header="Create Lab Request"
        width={500}
      >
        <LabRequestForm
          appointmentID={appointment.id}
          patientID={appointment.patient_id}
          callbackAfterSubmit={(data) => {
            data.patientLabRequest.body = JSON.stringify(data.patientLabRequest.body);
            onCreateItem && onCreateItem(data.patientLabRequest, 'lab_request');
          }}
        />
      </CustomModal>

      {opened && appointment && facility && (
        <AppointmentSettingsModal
          ref={modalRef}
          openModal={openAppointmentModal}
          setOpenModal={setOpenAppointmentModal}
          appointment={appointment}
          facility={facility}
          action={action}
          navigateToEditor={navigateToEditor}
          onCreateNote={(data) => onCreateItem && onCreateItem(data, 'note')}
          onCreateInvoice={(invoiceId) => onCreateItem && onCreateItem(invoiceId, 'invoice')}
          updateAppointmentData={() => {
            updateAppointmentData();
          }}
        />
      )}
      {opened && (
        <ViewInvoiceClick
          invoiceId={appointment.invoice_id}
          openModal={openInvoiceClick}
          setOpenModal={setOpenInvoiceClick}
        />
      )}

      <CustomModal
        width={1000}
        header="Linked Products"
        open={openProductsAddedModal}
        setOpen={setOpenProductsAddedModal}
      >
        <AppointmentProductTable
          appointment={appointment}
          refreshProducts={() => modalRef.current.refreshProductsTable()}
        />
      </CustomModal>

      <CustomModal
        header="Create Prescription"
        open={openCreatePrescription}
        setOpen={setOpenCreatePrescription}
        width={500}
      >
        <PrescriptionForm
          appointmentId={appointment.id}
          patientID={appointment.patient_id}
          callbackAfterSubmit={(data: any) => {
            onCreateItem && onCreateItem(data.medicine, 'prescription');
            setOpenCreatePrescription(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default AppointmentSettings;
