import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'files';

export const getFiles = (facility_id: number, patient_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`, { params: query });
};

export const getFilesInAppointment = (facility_id: number, appointment_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/appointment/${appointment_id}`, { params: query });
};

export const getFile = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const fileUpload = (data: any) => {
  return axios.post(`${baseURL}/upload`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fileUploadPatientNotes = (facility_id: number, data: any, patient_id: number) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const renameFile = (facility_id: number, id: number, note: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`, note);
};

export const deleteFile = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const downloadFile = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/download/${id}`);
};

export const getTotalStorage = (facility_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/storage`);
};


