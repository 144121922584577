import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getClient, getFacilityClients } from 'company/api/corporate-clients';

import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const CorporateClientSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'Corporate Client'}
        getData={(query) => getFacilityClients(facility.id, query)}
        getById={getClient}
        processOption={(record) => ({ key: record.company_name, value: record.id })}
        processNewRecord={(record) => record.client}
      />
    </WaitForFacility>
  );
};

export default CorporateClientSearchDropdown;
