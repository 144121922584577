import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getExpenseType, getExpenseTypes } from 'company/api/expense-types';

import { ApiQuery } from 'core/model/interface';

const CompanyExpenseTypeSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Expense Type'}
      getData={(query: ApiQuery) => getExpenseTypes(query)}
      getById={(id: number) => getExpenseType(id)}
      processOption={(expense_type) => {
        return { key: expense_type.expense_type, value: expense_type.id };
      }}
      processNewRecord={(record) => record.expense_type}
    />
  );
};

export default CompanyExpenseTypeSearchDropdown;
