import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AppoinArticle5: React.FC = () => {
  return (
    <Box>
      <Title>Attended</Title>
      <Step>
        1. To update whether the patient attended the scheduled appointment, simply find the attendance column in the
        table and click the checkbox for the patient who attended.
      </Step>
      <Image src="/assets/FaqAssets/Appointment/5/1attended.png" alt="Attended Image" />
      <Title>No Show</Title>
      <Step>
        1. To update if the patient did not attend in the scheduled appointment, In the appointments tab, select a
        patient and click the drop down icon.
      </Step>
      <Image src="/assets/FaqAssets/Appointment/5/1notattended.png" alt="Not Attended Image" />

      <Step>2. Click “No Show” option.</Step>
      <Image src="/assets/FaqAssets/Appointment/5/2.png" alt="Step 2 Image" />
      <Step>3. Click “Yes” button.</Step>
      <Image src="/assets/FaqAssets/Appointment/5/3.png" alt="Step 3 Image" />
    </Box>
  );
};

export default AppoinArticle5;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;
const Title = styled.p`
  margin-top: 8px;
  font-size: 17px;
  font-weight: bold;
`;
const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
