/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button } from '@mui/material';
import { CompanyFacilityModel, HealthPackageStage } from 'company/model/Entities';
import { ConfirmationDialog, CustomGridCell, PrimaryButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import {
  addServiceToStage,
  assignPackageToClinic,
  getAvailableServicesByStage,
  getPackagesNotAssigned,
} from 'company/api/health-packages';
import { assignProductToClinic, getProductsNotAssigned } from 'company/api/products';
import { assignServiceToClinic, getServicesNotAssigned } from 'company/api/services';
import { useContext, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import { assignUserToClinic } from 'company/api/users';
import { formatNumber } from 'core/utils';
import { getDoctorsNotAssigned } from 'company/api/doctors';
import { updateFacilityServices } from 'core/components/ProtectedRoute';
import { useSnackbar } from 'notistack';

type AddTableProps = {
  entity: string;
  clinic?: CompanyFacilityModel;
  setRefreshMainTable: (refreshMainTable: any) => void;
  isProduct?: boolean;
  isPackage?: boolean;
  isUser?: boolean;
  stage?: HealthPackageStage;
  callbackAfterChanges?: () => void;
};

const AddTable: React.FC<AddTableProps> = ({
  entity,
  clinic,
  setRefreshMainTable,
  isProduct,
  isPackage,
  isUser,
  stage,
  callbackAfterChanges
}) => {
  const { setFacility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [alertContent, setAlertContent] = useState<string>('');
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const doctor_columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>{params.row.title ? params.row.full_name_with_title : params.row.full_name}</CustomGridCell>
      ),
    },
    {
      field: 'contact_numbers',
      headerName: 'Contact Numbers',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.mobile_number_1}
          {params.row.mobile_number_2 ? '・' + params.row.mobile_number_2 : ''}
        </CustomGridCell>
      ),
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.full_name + '?');
              setSelectedRow(params.row);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const service_columns: GridColDef[] = [
    { field: 'service_name', headerName: 'Name', flex: 2 },
    { field: 'sms_name', headerName: 'SMS Name', flex: 2 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.price)) ? formatNumber(parseFloat(params.row.price)) : '0',
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.service_name + '?');
              setSelectedRow(params.row);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const product_columns: GridColDef[] = [
    { field: 'product_name', headerName: 'Name', flex: 2 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.price)) ? formatNumber(parseFloat(params.row.price)) : '0',
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.product_name + '?');
              setSelectedRow(params.row);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const package_columns: GridColDef[] = [
    { field: 'package_name', headerName: 'Name', flex: 2 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              setOpenAddDialog(true);
              setAlertContent('Are you sure you want to add ' + params.row.package_name + '?');
              setSelectedRow(params.row);
              event.stopPropagation();
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const [columns] = useState<GridColDef[]>(
    isProduct
      ? product_columns
      : isPackage
      ? package_columns
      : entity === 'Service Provider'
      ? doctor_columns
      : service_columns
  );

  const getList = (query: ApiQuery) => {
    if (clinic) {
      if (isProduct) {
        return getProductsNotAssigned(clinic.id, query);
      } else if (isPackage) {
        return getPackagesNotAssigned(clinic.id, query);
      } else {
        if (entity === 'Service Provider') {
          return getDoctorsNotAssigned(clinic.id, query);
        }
        return getServicesNotAssigned(clinic.id, query);
      }
    } else {
      if (stage) {
        return getAvailableServicesByStage(stage.package_id, stage.id, query);
      }
    }
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const refreshTable = () => {
    setForceRefreshTable((prev: number) => prev + 1);
  };

  const refreshMain = () => {
    setRefreshMainTable && setRefreshMainTable((refreshMainTable: number) => refreshMainTable + 1);
  };

  const showAddDialog = (content: string) => {
    if ((selectedRows.length && !selectedRow) || selectedRow) {
      setOpenAddDialog(true);
      setAlertContent(content);
    }
  };

  const handleAdd = () => {
    const dataList = JSON.stringify(selectedRow ? [selectedRow.id] : selectedRows);
    const data = { data: dataList };

    if (clinic) {
      if (isProduct) {
        return assignProductToClinic(clinic.id, data)
          .then((res) => {
            const pluralizedProduct = selectedRows.length > 1 ? 'Products' : 'Product';
            enqueueSnackbar(`${pluralizedProduct} successfully added!`, { variant: 'success' });
            refreshTable();
            refreshMain();
            setSelectedRow(0);
            setSelectedRows([]);
          })
          .catch((err) => console.error(err));
      } else if (isPackage) {
        return assignPackageToClinic(clinic.id, data)
          .then((res) => {
            const pluralizedPackage = selectedRows.length > 1 ? 'Sessions' : 'Session';
            enqueueSnackbar(`Health ${pluralizedPackage} successfully added!`, { variant: 'success' });
            refreshTable();
            refreshMain();
            setSelectedRow(0);
            setSelectedRows([]);
          })
          .catch((err) => console.error(err));
      } else {
        if (entity === 'Service Provider') {
          return assignUserToClinic(clinic.id, data)
            .then((res) => {
              const pluralizedDoctor = selectedRows.length > 1 ? 'Doctors' : 'Doctor';
              enqueueSnackbar(`${pluralizedDoctor} successfully added!`, { variant: 'success' });
              refreshTable();
              refreshMain();
              setSelectedRow(0);
              setSelectedRows([]);
            })
            .catch((err) => console.error(err));
        }

        return assignServiceToClinic(clinic.id, data)
          .then((res) => {
            const pluralizedService = selectedRows.length > 1 ? 'Services' : 'Service';
            enqueueSnackbar(`${pluralizedService} successfully added!`, { variant: 'success' });
            refreshTable();
            refreshMain();
            setSelectedRow(0);
            setSelectedRows([]);
            updateFacilityServices(setFacility);
          })
          .catch((err) => console.error(err));
      }
    } else {
      if (stage) {
        return addServiceToStage(stage.package_id, stage.id, data)
          .then((res) => {
            const pluralizedService = selectedRows.length > 1 ? 'Services' : 'Service';
            enqueueSnackbar(`${pluralizedService} successfully added!`, { variant: 'success' });
            refreshTable();
            refreshMain();
            setSelectedRow(0);
            setSelectedRows([]);
            callbackAfterChanges && callbackAfterChanges();
          })
          .catch((err) => console.error(err));
      }
    }
  };

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: entity,
    tableComponent: {
      columns: columns,
      getData: getList,
      forceRefresh: forceRefreshTable,
      checkboxSelection: true,
      selectableRow: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          <PrimaryButton
            onClick={() => {
              if (selectedRows.length) {
                const itemType = isPackage
                  ? 'health session'
                  : isProduct
                  ? 'product'
                  : entity === 'Service Provider'
                  ? 'doctor'
                  : 'service';
                const itemCount = selectedRows.length;
                const pluralizedItem = itemCount > 1 ? `${itemType}s` : itemType;

                const destinationName = clinic ? clinic.facility_name : stage?.stage_name;

                showAddDialog(`Are you sure you want to add ${itemCount} ${pluralizedItem} to ${destinationName}?`);
              } else {
                enqueueSnackbar('Invalid action. Select ' + entity + ' first!', { variant: 'error' });
              }
            }}
            label={'Add Selected ' + entity}
            disabled={!selectedRows.length}
          />
        </Box>
      ),
    },
  } as PageBuilderTableType;

  return (
    <>
      <PageBuilder content={content} />

      <ConfirmationDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        content={alertContent}
        onConfirm={handleAdd}
        confirmButtonColor
      />
    </>
  );
};

export default AddTable;
