/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomForm, CustomInlineForm, CustomSelectField } from 'core/components';
import { TbAcfPatientModel, TbAcfSputumResult } from 'lgu/model/Entities';
import { saveReferredHospital, updateSputumDetails, updateSputumResult, updateStage } from 'lgu/api/tb-acf';
import { update_referred_hospital_schema, update_sputum_schedule_schema } from 'lgu/model/schema';

import { FieldInput } from 'core/model/interface';
import InputContainer from '../common/InputContainer';
import { sputum_result_options } from 'lgu/data/select-options';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ScreeningProps = {
  patient: TbAcfPatientModel;
  result: string;
  refreshTable: () => void;
  setNextStage: (step: number) => void;
  setResult: (result: string) => void;
  setPatientData: (patient: any) => void;
  setStatus: (status: string) => void;
};

type UserInput = {
  sputum_test_schedule: string;
  sputum_test_venue: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValue: UserInput = {
  sputum_test_schedule: '',
  sputum_test_venue: '',
};

type RRFormInput = {
  referred_hospital: string;
};

interface RRForm extends FieldInput {
  field_name: keyof RRFormInput;
}

const rrInitialValue: RRFormInput = {
  referred_hospital: '',
};

const SputumTest: React.FC<ScreeningProps> = ({
  patient,
  refreshTable,
  setNextStage,
  setResult,
  result,
  setPatientData,
  setStatus,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const savedValues: UserInput = {
    sputum_test_schedule: patient.sputum_test_schedule,
    sputum_test_venue: patient.sputum_test_venue,
  };

  const [buttonLoading, setButtonLoading] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
  const [initialValues] = useState<UserInput>(savedValues ? savedValues : initialValue);

  const [displayRR, setDisplayRR] = useState(false);
  const [rrButtonLoading, setRrButtonLoading] = useState<boolean>(false);
  const [rrButtonDisabled, setRrButtonDisabled] = useState<boolean>(false);
  const [rrInitialValues] = useState<RRFormInput>(
    patient.referred_hospital ? { referred_hospital: patient.referred_hospital } : rrInitialValue
  );

  const [disableSubmit, setDisableSubmit] = useState<boolean>(
    patient.sputum_test_schedule && patient.sputum_test_venue ? false : true
  );

  const sputumFormFields: UserFieldInput[] = [
    {
      field_name: 'sputum_test_schedule',
      display_name: 'Date and Time',
      type: 'datetime',
      format: 'YYYY-MM-DD',
      minutesStep: 5,
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
    {
      field_name: 'sputum_test_venue',
      display_name: 'Venue',
      type: 'text',
      onChange: (value: any) => {
        value ? setDisableSubmit(false) : setDisableSubmit(true);
      },
    },
  ];

  const rrFormField: RRForm[] = [
    {
      field_name: 'referred_hospital',
      display_name: 'Referred Hospital',
      type: 'text',
      span: 4,
    },
  ];

  const skipSchedule = () => {
    setDisplayResult(true);
  };

  const updateSputum = (data: any) => {
    setButtonLoading(true);

    const patientList = JSON.stringify([patient.id]);
    const patientData = {
      patients: patientList,
      sputum_test_schedule: data.sputum_test_schedule,
      sputum_test_venue: data.sputum_test_venue,
    };

    updateSputumDetails(patientData)
      .then(() => {
        setButtonLoading(false);
        enqueueSnackbar('Sputum Details successfully saved!', { variant: 'success' });
        refreshTable();
        setDisplayResult(true);
        setPatientData((prev: any) => {
          return {
            ...prev,
            sputum_test_schedule: data.sputum_test_schedule,
            sputum_test_venue: data.sputum_test_venue,
          };
        });
      })
      .catch((error) => console.error(error));
  };

  const handleSelectResult = (event: ChangeEvent<HTMLInputElement>) => {
    const result = event.target.value;
    setDisplayRR(false);

    if (result === 'Rifampicin-resistant') {
      setDisplayRR(true);
      setNextStage(1);
      setResult(result);
    } else if (result === 'Positive') {
      setNextStage(3);
      setResult(result);
    } else {
      const nextStage = result.split('-').pop();
      if (nextStage === 'Checkup') {
        setNextStage(2);
        setResult('Presumptive');
        setStatus('Presumptive');
      } else if (nextStage === 'Negative') {
        setNextStage(1);
        setResult('Negative');
        setStatus('Negative');
      }
    }

    if (result === 'Rifampicin-resistant' || result === 'Negative') {
      const patientList = JSON.stringify([patient.id]);
      const patients = {
        patients: patientList,
        sputum_test_result: result,
      };
      const patientData = {
        patients: patientList,
        stage: 'Sputum Test',
        patient_status: result,
      };
      callUpdateSputumResult(patients, patientData);
    }
  };

  const callUpdateSputumResult = (patientSputumResult: TbAcfSputumResult, patientData: any) => {
    updateSputumResult(patientSputumResult)
      .then(() => {
        if (result === 'Rifampicin-resistant') {
          updateStage(patientData).then(() => {
            enqueueSnackbar('Sputum Result successfully saved!', { variant: 'success' });
            refreshTable();
          });
        } else {
          enqueueSnackbar('Sputum Result successfully saved!', { variant: 'success' });
          refreshTable();
        }
      })
      .catch((error) => console.error(error));
  };

  const saveHospital = (data: any) => {
    setRrButtonDisabled(true);
    setRrButtonLoading(true);

    saveReferredHospital(patient.id, data)
      .then(() => {
        setRrButtonDisabled(false);
        setRrButtonLoading(false);
        enqueueSnackbar('Referred Hospital successfully saved!', { variant: 'success' });
        refreshTable();
        setRrButtonDisabled(false);
        setRrButtonLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setStatus(patient.patient_status ? patient.patient_status : 'Presumptive');
    if ((patient.sputum_test_schedule && patient.sputum_test_schedule) || patient.sputum_test_result) {
      setDisplayResult(true);
    } else {
      setDisplayResult(false);
    }

    if (patient.sputum_test_result === 'Rifampicin-resistant') {
      setDisplayRR(true);
    } else {
      setDisplayRR(false);
    }
  }, []);

  return (
    <>
      <Divider sx={{ margin: '20px 0' }} />

      <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px">
        <Typography variant="h5" color={colors.text} fontWeight="bold">
          Sputum Test Schedule and Venue
        </Typography>
        {!displayResult && (
          <Typography
            onClick={skipSchedule}
            sx={{
              color: colors.accent,
              '&:hover': {
                cursor: 'pointer',
                fontWeight: '900',
              },
            }}
          >
            Skip {'>>'}
          </Typography>
        )}
      </Box>
      <InputContainer>
        <CustomForm
          onSubmit={updateSputum}
          schema={update_sputum_schedule_schema}
          initialValues={initialValues}
          loading={buttonLoading}
          fields={sputumFormFields}
          submitButtonText={'Save and Send SMS'}
          buttonWidth={'150px'}
          submitButtonMarginTop={2}
          disabled={disableSubmit}
        />
      </InputContainer>

      <Divider sx={{ margin: '20px 0' }} />

      {displayResult && (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px">
            <Typography variant="h5" color={colors.text} fontWeight="bold">
              Sputum Test Result
            </Typography>
          </Box>

          <InputContainer>
            <Box width="calc(50% - 20px)">
              <CustomSelectField
                label={'Result:'}
                handleChange={handleSelectResult}
                fieldName={'Result:'}
                value={
                  patient.sputum_test_result
                    ? patient.sputum_test_result === 'Presumptive'
                      ? 'Negative-Checkup'
                      : patient.sputum_test_result
                    : ''
                }
                options={sputum_result_options.map((u) => {
                  return { key: u.key, value: u.value };
                })}
              />
            </Box>
          </InputContainer>
        </Box>
      )}

      {displayRR && (
        <Box>
          <Divider sx={{ margin: '20px 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" paddingX="20px">
            <Typography variant="h5" color={colors.text} fontWeight="bold">
              Referred Hospital
            </Typography>
          </Box>

          <InputContainer>
            <CustomInlineForm
              onSubmit={saveHospital}
              schema={update_referred_hospital_schema}
              initialValues={rrInitialValues}
              disabled={rrButtonDisabled}
              loading={rrButtonLoading}
              fields={rrFormField}
              submitButtonText={'Save'}
            />
          </InputContainer>
        </Box>
      )}
    </>
  );
};

export default SputumTest;
