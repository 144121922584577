import { Box, Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';

const Scroll8 = () => {
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  return isMobilePhone ? (
    <>
      <MainContainer>
        <Container>
          <Content>
            <Div style={{ flex: 0.1 }}>
              <Box display="flex" justifyContent="center">
                <Image src="/assets/LandingMobile/LOGO.png" />
              </Box>
            </Div>
            <Div style={{ flex: 0.2 }}>
              <NewDiv>
                <Title>
                  {' '}
                  <span
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                    }}
                  >
                    Contact Us
                  </span>{' '}
                </Title>
                <Paragraph>
                  <Box paddingLeft={'18%'}>
                    <Box
                      display="flex"
                      gap="10px"
                      alignItems="start"
                      fontSize="20px"
                      sx={{
                        '@media (max-width: 1700px)': {
                          fontSize: '17px',
                        },
                        '@media (max-width: 768px)': {
                          fontSize: '14px',
                          marginBottom: '20px',
                        },
                      }}
                    >
                      {' '}
                      <Logo1 src="./assets//landingassets/mail.svg" /> partnerships@cphealthinnovations.com{' '}
                    </Box>
                    <Box
                      display="flex"
                      gap="10px"
                      alignItems="center"
                      fontSize="20px"
                      sx={{
                        '@media (max-width: 1700px)': {
                          fontSize: '17px',
                        },
                        '@media (max-width: 768px)': {
                          fontSize: '14px',
                          width: '80%',
                        },
                      }}
                    >
                      <Logo1 src="./assets//landingassets/location_on.svg" /> Commerce Avenue cor. East Asia Drive,
                      Filinvest, Alabang, Muntinlupa, Metro Manila 1781, Philippines
                    </Box>
                  </Box>
                </Paragraph>
                <Box display="flex" justifyContent="center" gap="10px" marginBottom="20px">
                  <Logos src="./assets//landingassets/Facebook.svg" />
                  <Logos src="./assets//landingassets/LinkedIn.svg" />
                </Box>
              </NewDiv>
            </Div>
            {/* <HideOrShowComponent hidden> */}
            <Div style={{ flex: 0.1 }}>
              <div>
                <Title>
                  {' '}
                  <span
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                    }}
                  >
                    Quick Links
                  </span>{' '}
                </Title>
                <ul>
                  <List>Home</List>
                  <List>About</List>
                  <List>Features</List>
                  <List>Request A Demo</List>
                  <List>Contact Us</List>
                </ul>
              </div>
            </Div>
            <Div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 0.1 }}>
              <div>
                <Title>
                  {' '}
                  <span
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                    }}
                  >
                    More From Us
                  </span>{' '}
                </Title>
                <Box textAlign={'center'} fontSize={'14px'}>
                  Uncover the latest narratives of innovation, groundbreaking partnerships, and our significant presence
                  in the health tech landscape.
                </Box>
                <Box textAlign={'center'} fontSize={'14px'} mt={'40px'}>
                  <Button
                    style={{ backgroundColor: '#0192d5', color: 'white', padding: '12px 42px', borderRadius: '12px' }}
                  >
                    Learn More
                  </Button>
                </Box>
              </div>
            </Div>
            <Div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', flex: 0.1 }}>
              <ImageCOR src="/assets/LandingMobile/cor.png" />
            </Div>
            {/* </HideOrShowComponent> */}
          </Content>
        </Container>
      </MainContainer>
      <Box
        sx={{
          color: '#0192d5',
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '150px',
          paddingTop: '50px',
        }}
      >
        <Box>Terms and Conditions | Privacy Policy</Box>
        <Box>CP Health Innovations (c) 2024. All rights reserved.</Box>
      </Box>
    </>
  ) : (
    <>
      <MainContainer>
        <Container>
          <Content>
            <Div>
              <Box display="flex" justifyContent="center">
                <Image src="/assets//landingassets/NewLogo.png" />
              </Box>
            </Div>
            <Div>
              <NewDiv>
                <Title>
                  {' '}
                  <span
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                    }}
                  >
                    CONTACT US
                  </span>{' '}
                </Title>
                <Paragraph>
                  <Box
                    display="flex"
                    gap="10px"
                    alignItems="center"
                    fontSize="20px"
                    sx={{
                      '@media (max-width: 1700px)': {
                        fontSize: '17px',
                      },
                      '@media (max-width: 768px)': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    {' '}
                    <Logo1 src="./assets//landingassets/mail.svg" /> partnerships@cphealthinnovations.com{' '}
                  </Box>
                  <Box
                    display="flex"
                    gap="10px"
                    alignItems="center"
                    fontSize="20px"
                    sx={{
                      '@media (max-width: 1700px)': {
                        fontSize: '17px',
                      },
                      '@media (max-width: 768px)': {
                        fontSize: '12px',
                        width: '80%',
                      },
                    }}
                  >
                    <Logo1 src="./assets//landingassets/location_on.svg" /> 214-215 Commercenter, Filinvest Avenue,
                    Alabang, Muntinlupa, Metro Manila, Philippines
                  </Box>
                </Paragraph>
                <Box display="flex" justifyContent="center" gap="10px">
                  <Logos src="./assets//landingassets/Facebook.svg" />
                  <Logos src="./assets//landingassets/LinkedIn.svg" />
                </Box>
              </NewDiv>
            </Div>
            {/* <HideOrShowComponent hidden> */}
            <Div>
              <div>
                <Title>
                  {' '}
                  <span
                    style={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      display: 'inline-block',
                    }}
                  >
                    QUICK LINKS
                  </span>{' '}
                </Title>
                <ul>
                  <List>Home</List>
                  <List>About</List>
                  <List>Features</List>
                  <List>Request A Demo</List>
                  <List>Contact Us</List>
                </ul>
              </div>
            </Div>
            {/* </HideOrShowComponent> */}
          </Content>
        </Container>
      </MainContainer>
      <Footer>
        <Box>CP Health Innovations (c) 2024. All rights reserved.</Box> <Box>Terms and Conditions | Privacy Policy</Box>
      </Footer>
    </>
  );
};
const NewDiv = styled.div``;
export default Scroll8;
const Footer = styled.div`
  width: 100%;
  display: flex;
  color: white;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 70px;
  height: 50px;
  background: linear-gradient(to right, #239bd7, #0463ae);
  @media (max-width: 768px) {
    font-size: 12px;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    height: 200px;
    padding: 20px 70px;
  }
`;
const Title = styled.p`
  color: #239bd7;
  font-size: 30px;
  font-weight: bold;
  @media (max-width: 1800px) {
    font-size: 22px;
  }
  @media (max-width: 1800px) {
    font-size: 18px;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const MainContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  color: black;
  padding: 20px 0px;
  @media (max-width: 768px) {
    height: 1100px;
    justify-content: center;
    align-items: start;
  }
`;
const Container = styled.div`
  width: 85%;

  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: black;
`;
const Image = styled.img`
  height: 50%;
  width: 50%;

  color: black;
  @media (max-width: 768px) {
    width: auto;

    height: 50px;
  }
`;
const ImageCOR = styled.img`
  height: 50%;
  width: 50%;

  color: black;
  @media (max-width: 768px) {
    width: auto;

    height: 300px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 110px;

  font-family: Nunito;
  word-spacing: 0.5px;

  letter-spacing: 1px;
  color: black;

  @media (max-width: 1600px) {
    font-size: 15px;

    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
  @media (max-width: 768px) {
    width: 400px;
    align-items: center;
    letter-spacing: 0px;
    word-spacing: 0px;
  }
`;

const Logo1 = styled.img`
  height: 30px;
  @media (max-width: 768px) {
    height: 20px;
  }
`;

const Logos = styled.img`
  height: 50px;
  @media (max-width: 768px) {
    height: 30px;
    margin-top: 20px;
  }
`;
const List = styled.li`
  font-size: 18px;
  @media (max-width: 1800px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
