import { CustomForm, CustomModal } from 'core/components';
import { createProgram, getFrequency, getProgramCategories, updateProgram } from 'carego-admin/api/program';
import { useEffect, useState } from 'react';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { ProgramModel } from 'carego-admin/model/Entities';
import { program_schema } from 'carego-admin/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type ProgramModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  toEdit?: boolean;
  refreshTable: () => void;
  updateInitialValues?: UserInput;
  selectedProgram?: ProgramModel;
};

type UserInput = {
  program_name: string;
  program_category_code: string;
  program_code: string;
  default_sending_time: string;
  frequency_type: number | string;
  reminder_count: number | string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  program_name: '',
  program_category_code: '',
  program_code: '',
  default_sending_time: '00:00',
  frequency_type: '',
  reminder_count: '',
};

const ProgramModal: React.FC<ProgramModalProps> = ({
  open,
  setOpen,
  toEdit,
  refreshTable,
  updateInitialValues,
  selectedProgram,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  //frequency types
  const [frequency, setFrequency] = useState<any>();

  //program categories
  const [programCategories, setProgramCategories] = useState<any>();

  // Form Fields
  const programFields: UserFieldInput[] = [
    { field_name: 'program_name', display_name: 'Program Name', type: 'text' },
    {
      field_name: 'program_category_code',
      display_name: 'Program Category Code',
      type: 'select',
      options: programCategories?.map((u: any) => {
        return { key: u.program_category, value: u.program_category_code };
      }),
    },
    { field_name: 'program_code', display_name: 'Program Code', type: 'text' },
    { field_name: 'default_sending_time', display_name: 'Default Reminder Time', type: 'time', minutesStep: 5 },
    { field_name: 'reminder_count', display_name: 'Reminder Count', type: 'text' },
    {
      field_name: 'frequency_type',
      display_name: 'Frequency',
      type: 'select',
      options: frequency?.map((u: any) => {
        return { key: u.frequency, value: u.id };
      }),
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (toEdit) {
      updateProgram(selectedProgram!.id, data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => {
          console.error(error);
          setButtonLoading(false);
        });
    } else {
      createProgram(data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => {
          console.error(error);
          setButtonLoading(false);
        });
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      if (toEdit) {
        enqueueSnackbar(`Program successfully updated!`, { variant: 'success' });
        setOpen(true);
      } else {
        enqueueSnackbar(`Program successfully created!`, { variant: 'success' });
        setOpen(false);
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  const getFrequencyTypes = () => {
    getFrequency()
      .then((res) => {
        setFrequency(res.data.data);
      })
      .catch();
  };

  const getCategories = () => {
    getProgramCategories()
      .then((res) => {
        setProgramCategories(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    getCategories();
    getFrequencyTypes();
  }, []);

  return (
    <CustomModal header={toEdit ? 'Update Program' : 'Create Program'} open={open} setOpen={setOpen}>
      <CustomForm
        initialValues={toEdit ? updateInitialValues : initialValues}
        onSubmit={handleSubmit}
        fields={programFields}
        schema={program_schema}
        loading={buttonLoading}
      />
    </CustomModal>
  );
};

export default ProgramModal;
