/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { tranformFormErrors } from 'core/utils';
import { updateFacility } from 'carego-admin/api/facility';
import { update_facility_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UpdateFacilityProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  facility: FacilityModel;
  languages: any;
  initialValues: any;
};

type UserInput = {
  facility_name: string;
  primary_language: number;
  region_name: string;
  province_name: string;
  municipality_name: string;
  address: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  email: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const UpdateFacility: React.FC<UpdateFacilityProps> = ({
  open,
  setOpen,
  refreshTable,
  languages,
  facility,
  initialValues,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  // Form Fields
  const facilityFields: UserFieldInput[] = [
    { field_name: 'facility_name', display_name: 'Facility Name', type: 'text' },
    {
      field_name: 'primary_language',
      display_name: 'Language',
      type: 'select',
      options:
        languages &&
        languages.map((u: any) => {
          return { key: u.language, value: u.id };
        }),
    },
    { field_name: 'region_name', display_name: 'Region', type: 'text' },
    { field_name: 'province_name', display_name: 'Province', type: 'text' },
    { field_name: 'municipality_name', display_name: 'Municipality', type: 'text' },
    { field_name: 'address', display_name: 'Address', type: 'text' },
    { field_name: 'smart_mobile_number', display_name: 'Smart Mobile Number (Optional)', type: 'text' },
    { field_name: 'globe_mobile_number', display_name: 'Globe Mobile Number (Optional)', type: 'text' },
    { field_name: 'email', display_name: 'Email (Optional)', type: 'text' },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    updateFacility(facility.id, data)
      .then((res) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Facility successfully updated!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  return (
    facility && (
      <CustomModal header={'Update ' + facility.facility_name} open={open} setOpen={setOpen}>
        <Box>
          <CustomForm
            submitButtonId="update_facility_submit_button"
            initialValues={initialValues}
            onSubmit={handleSubmit}
            fields={facilityFields}
            schema={update_facility_schema}
            loading={buttonLoading}
          />
        </Box>
      </CustomModal>
    )
  );
};

export default UpdateFacility;
