import { CustomGridCell, CustomSwitch, Header } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { chat, send } from 'core/icons';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from 'core/utils';
import { getAllHealthPackageSms } from 'company/api/health-packages-sms';
import { useState } from 'react';

type HealthPackageSmsSchedulesProps = {
  facility: any;
};

const HealthPackageSmsSchedules: React.FC<HealthPackageSmsSchedulesProps> = ({ facility }) => {
  const [viewSent, setViewSent] = useState<boolean>(false);
  const [otherFilter, setOtherFilter] = useState<any>({});

  const columns: GridColDef[] = [
    {
      field: 'package_patient_id',
      headerName: 'Patient ID',
      flex: 0.5,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
    },
    {
      field: 'package_name',
      headerName: 'Health Session',
      flex: 1,
      sortable: false,
    },
    {
      field: facility?.sensitive_data ? 'uic' : 'full_name',
      headerName: facility?.sensitive_data ? 'UIC' : 'Patient',
      flex: 1.2,
      sortable: false,
    },
    {
      field: facility?.sensitive_data ? 'encrypted_mobile_number' : 'mobile_number',
      headerName: 'Mobile Number',
      flex: 1,
      sortable: false,
    },
    { field: 'sms_template', headerName: 'SMS Template', flex: 1.2, sortable: false },
    {
      field: 'schedule',
      headerName: 'Sending Schedule',
      flex: 1.2,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.send_date_time)}</CustomGridCell>,
    },
    { field: 'credits_deducted', headerName: 'Credits Used', flex: 0.7, sortable: false },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: (params) => (
        <CustomGridCell>{params.row.status === 'Completed' ? 'Sent' : params.row.status}</CustomGridCell>
      ),
    },
  ];
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewSent(event.target.checked);
    setOtherFilter({ ...otherFilter, get_completed: event.target.checked ? 1 : undefined });
  };

  const getSmsSchedules = async (query: ApiQuery) => {
    if (viewSent) query.get_completed = 1;
    return getAllHealthPackageSms(query);
  };

  // const handleSelectRow = async (selected: any) => {
  //   setSelectedRow(selected);
  // };

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: [
      {
        type: BLOCK_TYPE.TABLE,
        tableComponent: {
          columns: columns,
          getData: getSmsSchedules,
          selectableRow: false,
          otherFilter: otherFilter,
          rowHeight: 35,
          headerComponent: (
            <Box display="block">
              <Header title="Health Session SMS Schedules" mb="15px" />
              <CustomSwitch
                label1="Pending"
                label2="Sent"
                image1={send}
                image2={chat}
                value={viewSent}
                handleChange={handleChange}
              />
            </Box>
          ),
        },
      } as PageBuilderTableType,
    ],
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />
    </>
  );
};

export default HealthPackageSmsSchedules;
