import CustomSideBar, { APP_MODULE, MenuItemProps } from './CustomSideBar';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';

const AccountingAppSidebar = () => {
  const company_menu_items: MenuItemProps[] = [
    {
      section_name: 'Accounting',
      subitems: [
        {
          title: 'Dashboard',
          link: '/company/accounting',
          icon: <BarChartOutlinedIcon />,
        },
        {
          title: 'Chart of Accounts',
          link: '/company/accounting/coa',
          icon: <AccountBalanceOutlinedIcon />,
        },
        {
          title: 'Payments',
          link: '/company/accounting/payments',
          icon: <PointOfSaleOutlinedIcon />,
        },
        {
          title: 'Invoices',
          link: '/company/accounting/invoices',
          icon: <ReceiptIcon />,
        },
        {
          title: 'Expenses',
          link: '/company/accounting/expenses',
          icon: <AccountBalanceWalletOutlinedIcon />,
        },
        {
          title: 'Reports',
          link: '/company/accounting/reports',
          icon: <ArticleOutlinedIcon />,
        },
      ],
    },
  ];

  return <CustomSideBar menuItems={company_menu_items} module={APP_MODULE.ACCOUNTING} />;
};

export default AccountingAppSidebar;
