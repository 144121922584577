import AdditionalSmsTemplates from './components/templates/AdditionalSmsTemplates';
import CommonSmsTemplates from './components/templates/CommonSmsTemplates';
import PendingSmsTemplates from './components/templates/PendingSmsTemplates';

const SmsTemplates = () => {
  return (
    <>
      <PendingSmsTemplates />
      <CommonSmsTemplates />
      <AdditionalSmsTemplates />
    </>
  );
};

export default SmsTemplates;
