import { ApiQuery } from 'core/model/interface';
import { FacilityModel } from 'core/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'facility';

export const getFacilities = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getCompanyFacilities = (company_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/company/${company_id}`, {
    params: query,
  });
};

export const getActiveCompanyFacilities = (company_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/company/active/${company_id}`, {
    params: query,
  });
};

export const exportCompanyFacilities = (query: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/export-company-facilities`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'Clinics.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const createFacility = (facility: FacilityModel, company_id: number) => {
  return axios.post(`${baseURL}/${path}/${company_id}`, facility);
};

export const updateFacility = (id: number, facility: FacilityModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, facility);
};

export const deactivate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const reactivate = (id: number) => {
  return axios.patch(`${baseURL}/${path}/reactivate/${id}`);
};
