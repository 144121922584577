import { CustomGridCell } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { formatDate } from 'core/utils';

export const commonTemplatesColumns: GridColDef[] = [
  { field: 'template_name', headerName: 'Template Name', flex: 0.5 },
  { field: 'template_body', headerName: 'Message Template', flex: 2 },
];

export const additionalTemplatesColumns: GridColDef[] = [
  { field: 'template_name', headerName: 'Template Name', flex: 1 },
  { field: 'template_body', headerName: 'SMS Template Body', flex: 2 },
  { field: 'lgu_name', headerName: 'LGU', flex: 0.75 },
  { field: 'full_name', headerName: 'Requested By', flex: 1 },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 0.5,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
  },
];

export const pendingTemplatesColumns: GridColDef[] = [
  { field: 'template_name', headerName: 'Template Name', flex: 1 },
  { field: 'template_body', headerName: 'SMS Template Body', flex: 2 },
  { field: 'lgu_name', headerName: 'LGU', flex: 0.75 },
  { field: 'full_name', headerName: 'Requested By', flex: 0.75 },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 0.5,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
  },
];

export const smsCreditsColumns: GridColDef[] = [
  { field: 'credits', headerName: 'SMS Credits', align: 'right', headerAlign: 'right', flex: 0.5 },
  { field: 'lgu_name', headerName: 'LGU', flex: 1 },
  {
    field: 'full_name',
    headerName: 'Requested by',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>{params.row.full_name ? params.row.full_name : '- Monthly Credits -'}</CustomGridCell>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
  },
  { field: 'status', headerName: 'Status', flex: 1 },
];

export const pendingSmsCreditsColumns: GridColDef[] = [
  { field: 'credits', headerName: 'SMS Credits', align: 'right', headerAlign: 'right', flex: 0.5 },
  { field: 'lgu_name', headerName: 'LGU', flex: 1 },
  {
    field: 'full_name',
    headerName: 'Requested by',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>{params.row.full_name ? params.row.full_name : '- Monthly Credits -'}</CustomGridCell>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date Requested',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
  },
];
