/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'core/context/user.context';

type ProtectedComponentProps = {
  requiredAuth?: string[];
  requiredRole?: string[];
  children: React.ReactNode;
};

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ requiredAuth, requiredRole, children }) => {
  const { user } = useContext(UserContext);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    checkUserAuthority();
  }, [user]);

  const checkUserAuthority = () => {
    if (user) {
      if (requiredRole && requiredRole.length) {
        setVerified(requiredRole.includes(user.user_group.group_name));
      } else if (requiredAuth && requiredAuth.length) {
        const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));

        setVerified(!!verified_auths.length);
      } else {
        setVerified(true);
      }
    }
  };

  return verified ? <>{children}</> : <></>;
};

export default ProtectedComponent;
