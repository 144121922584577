import { CustomInlineForm, Header } from 'core/components';

import { Box } from '@mui/material';
import { FieldInput } from 'core/model/interface';
import { createPurchaseRequest } from 'lgu/api/purchase-request';
import { request_purchase_schema } from 'lgu/model/schema';
import { sms_credits_options } from 'lgu/data/select-options';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type PurchaseAdditionalCreditsFormProps = {
  setRefreshRequests: any;
};

export type UserInput = {
  sms_credits: number;
  cost: number;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  sms_credits: 0,
  cost: 0,
};

const PurchaseAdditionalCreditsForm: React.FC<PurchaseAdditionalCreditsFormProps> = ({ setRefreshRequests }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const purchaseCreditsFields: UserFieldInput[] = [
    {
      field_name: 'sms_credits',
      display_name: 'SMS',
      type: 'select',
      options: sms_credits_options.map((u: any) => {
        return { key: u.key, value: u.value };
      }),
      onChange: (value: number, setFieldValue: any) => {
        setFieldValue('cost', value * 0.8 + '.00');
      },
    },
    { field_name: 'cost', display_name: 'Cost in PHP', type: 'text', disabled: true },
  ];

  const handleRequestPurchase = (data: any) => {
    setButtonLoading(true);

    createPurchaseRequest({ credits: data.sms_credits })
      .then((res) => {
        enqueueSnackbar('Purchase Request successfully created!', { variant: 'success' });
        setRefreshRequests((prev: number) => prev + 1);
        setButtonLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Header title="Purchase of Additional SMS" />

      <Box width="60%">
        <CustomInlineForm
          initialValues={initialValues}
          onSubmit={handleRequestPurchase}
          fields={purchaseCreditsFields}
          loading={buttonLoading}
          submitButtonText="Request Purchase"
          schema={request_purchase_schema}
          buttonWidth="150px"
        />
      </Box>
    </>
  );
};

export default PurchaseAdditionalCreditsForm;
