import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Municipality, Province, Region } from './dropdown/CustomLocationDropdown';

export const region: Region[] = [
  {
    code: '010000000',
    name: 'Ilocos Region',
    regionName: 'Region I',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0100000000',
  },
  {
    code: '020000000',
    name: 'Cagayan Valley',
    regionName: 'Region II',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200000000',
  },
  {
    code: '030000000',
    name: 'Central Luzon',
    regionName: 'Region III',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300000000',
  },
  {
    code: '040000000',
    name: 'CALABARZON',
    regionName: 'Region IV-A',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0400000000',
  },
  {
    code: '170000000',
    name: 'MIMAROPA Region',
    regionName: 'MIMAROPA Region',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1700000000',
  },
  {
    code: '050000000',
    name: 'Bicol Region',
    regionName: 'Region V',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500000000',
  },
  {
    code: '060000000',
    name: 'Western Visayas',
    regionName: 'Region VI',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600000000',
  },
  {
    code: '070000000',
    name: 'Central Visayas',
    regionName: 'Region VII',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0700000000',
  },
  {
    code: '080000000',
    name: 'Eastern Visayas',
    regionName: 'Region VIII',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0800000000',
  },
  {
    code: '090000000',
    name: 'Zamboanga Peninsula',
    regionName: 'Region IX',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0900000000',
  },
  {
    code: '100000000',
    name: 'Northern Mindanao',
    regionName: 'Region X',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1000000000',
  },
  {
    code: '110000000',
    name: 'Davao Region',
    regionName: 'Region XI',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1100000000',
  },
  {
    code: '120000000',
    name: 'SOCCSKSARGEN',
    regionName: 'Region XII',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1200000000',
  },
  {
    code: '130000000',
    name: 'NCR',
    regionName: 'National Capital Region',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1300000000',
  },
  {
    code: '140000000',
    name: 'CAR',
    regionName: 'Cordillera Administrative Region',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400000000',
  },
  {
    code: '160000000',
    name: 'Caraga',
    regionName: 'Region XIII',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600000000',
  },
  {
    code: '150000000',
    name: 'BARMM',
    regionName: 'Bangsamoro Autonomous Region in Muslim Mindanao',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900000000',
  },
];

export const provinces: Province[] = [
  {
    code: '012800000',
    name: 'Ilocos Norte',
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102800000',
  },
  {
    code: '012900000',
    name: 'Ilocos Sur',
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102900000',
  },
  {
    code: '013300000',
    name: 'La Union',
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103300000',
  },
  {
    code: '015500000',
    name: 'Pangasinan',
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105500000',
  },
  {
    code: '020900000',
    name: 'Batanes',
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200900000',
  },
  {
    code: '021500000',
    name: 'Cagayan',
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201500000',
  },
  {
    code: '023100000',
    name: 'Isabela',
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203100000',
  },
  {
    code: '025000000',
    name: 'Nueva Vizcaya',
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205000000',
  },
  {
    code: '025700000',
    name: 'Quirino',
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205700000',
  },
  {
    code: '030800000',
    name: 'Bataan',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300800000',
  },
  {
    code: '031400000',
    name: 'Bulacan',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301400000',
  },
  {
    code: '034900000',
    name: 'Nueva Ecija',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304900000',
  },
  {
    code: '035400000',
    name: 'Pampanga',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305400000',
  },
  {
    code: '036900000',
    name: 'Tarlac',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306900000',
  },
  {
    code: '037100000',
    name: 'Zambales',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307100000',
  },
  {
    code: '037700000',
    name: 'Aurora',
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307700000',
  },
  {
    code: '041000000',
    name: 'Batangas',
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401000000',
  },
  {
    code: '042100000',
    name: 'Cavite',
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402100000',
  },
  {
    code: '043400000',
    name: 'Laguna',
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403400000',
  },
  {
    code: '045600000',
    name: 'Quezon',
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405600000',
  },
  {
    code: '045800000',
    name: 'Rizal',
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405800000',
  },
  {
    code: '174000000',
    name: 'Marinduque',
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704000000',
  },
  {
    code: '175100000',
    name: 'Occidental Mindoro',
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705100000',
  },
  {
    code: '175200000',
    name: 'Oriental Mindoro',
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705200000',
  },
  {
    code: '175300000',
    name: 'Palawan',
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705300000',
  },
  {
    code: '175900000',
    name: 'Romblon',
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705900000',
  },
  {
    code: '050500000',
    name: 'Albay',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500500000',
  },
  {
    code: '051600000',
    name: 'Camarines Norte',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501600000',
  },
  {
    code: '051700000',
    name: 'Camarines Sur',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501700000',
  },
  {
    code: '052000000',
    name: 'Catanduanes',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502000000',
  },
  {
    code: '054100000',
    name: 'Masbate',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504100000',
  },
  {
    code: '056200000',
    name: 'Sorsogon',
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506200000',
  },
  {
    code: '060400000',
    name: 'Aklan',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600400000',
  },
  {
    code: '060600000',
    name: 'Antique',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600600000',
  },
  {
    code: '061900000',
    name: 'Capiz',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601900000',
  },
  {
    code: '063000000',
    name: 'Iloilo',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603000000',
  },
  {
    code: '064500000',
    name: 'Negros Occidental',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604500000',
  },
  {
    code: '067900000',
    name: 'Guimaras',
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607900000',
  },
  {
    code: '071200000',
    name: 'Bohol',
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701200000',
  },
  {
    code: '072200000',
    name: 'Cebu',
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702200000',
  },
  {
    code: '074600000',
    name: 'Negros Oriental',
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704600000',
  },
  {
    code: '076100000',
    name: 'Siquijor',
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706100000',
  },
  {
    code: '082600000',
    name: 'Eastern Samar',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802600000',
  },
  {
    code: '083700000',
    name: 'Leyte',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803700000',
  },
  {
    code: '084800000',
    name: 'Northern Samar',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804800000',
  },
  {
    code: '086000000',
    name: 'Samar',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806000000',
  },
  {
    code: '086400000',
    name: 'Southern Leyte',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806400000',
  },
  {
    code: '087800000',
    name: 'Biliran',
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807800000',
  },
  {
    code: '097200000',
    name: 'Zamboanga Del Norte',
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907200000',
  },
  {
    code: '097300000',
    name: 'Zamboanga Del Sur',
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907300000',
  },
  {
    code: '098300000',
    name: 'Zamboanga Sibugay',
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908300000',
  },
  {
    code: '101300000',
    name: 'Bukidnon',
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001300000',
  },
  {
    code: '101800000',
    name: 'Camiguin',
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001800000',
  },
  {
    code: '103500000',
    name: 'Lanao Del Norte',
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003500000',
  },
  {
    code: '104200000',
    name: 'Misamis Occidental',
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004200000',
  },
  {
    code: '104300000',
    name: 'Misamis Oriental',
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004300000',
  },
  {
    code: '112300000',
    name: 'Davao Del Norte',
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102300000',
  },
  {
    code: '112400000',
    name: 'Davao Del Sur',
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102400000',
  },
  {
    code: '112500000',
    name: 'Davao Oriental',
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102500000',
  },
  {
    code: '118200000',
    name: 'Davao De Oro',
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108200000',
  },
  {
    code: '118600000',
    name: 'Davao Occidental',
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108600000',
  },
  {
    code: '124700000',
    name: 'Cotabato',
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204700000',
  },
  {
    code: '126300000',
    name: 'South Cotabato',
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206300000',
  },
  {
    code: '126500000',
    name: 'Sultan Kudarat',
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206500000',
  },
  {
    code: '128000000',
    name: 'Sarangani',
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208000000',
  },
  {
    code: '140100000',
    name: 'Abra',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400100000',
  },
  {
    code: '141100000',
    name: 'Benguet',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401100000',
  },
  {
    code: '142700000',
    name: 'Ifugao',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402700000',
  },
  {
    code: '143200000',
    name: 'Kalinga',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403200000',
  },
  {
    code: '144400000',
    name: 'Mountain Province',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404400000',
  },
  {
    code: '148100000',
    name: 'Apayao',
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408100000',
  },
  {
    code: '160200000',
    name: 'Agusan Del Norte',
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600200000',
  },
  {
    code: '160300000',
    name: 'Agusan Del Sur',
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600300000',
  },
  {
    code: '166700000',
    name: 'Surigao Del Norte',
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606700000',
  },
  {
    code: '166800000',
    name: 'Surigao Del Sur',
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606800000',
  },
  {
    code: '168500000',
    name: 'Dinagat Islands',
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608500000',
  },
  {
    code: '150700000',
    name: 'Basilan',
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900700000',
  },
  {
    code: '153600000',
    name: 'Lanao Del Sur',
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903600000',
  },
  {
    code: '153800000',
    name: 'Maguindanao',
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903800000',
  },
  {
    code: '156600000',
    name: 'Sulu',
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906600000',
  },
  {
    code: '157000000',
    name: 'Tawi-Tawi',
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907000000',
  },
];

const luzonMunicipalities: Municipality[] = [
  {
    code: '012801000',
    name: 'Adams',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102801000',
  },
  {
    code: '012802000',
    name: 'Bacarra',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102802000',
  },
  {
    code: '012803000',
    name: 'Badoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102803000',
  },
  {
    code: '012804000',
    name: 'Bangui',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102804000',
  },
  {
    code: '012805000',
    name: 'City of Batac',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102805000',
  },
  {
    code: '012806000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102806000',
  },
  {
    code: '012807000',
    name: 'Carasi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102807000',
  },
  {
    code: '012808000',
    name: 'Currimao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102808000',
  },
  {
    code: '012809000',
    name: 'Dingras',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102809000',
  },
  {
    code: '012810000',
    name: 'Dumalneg',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102810000',
  },
  {
    code: '012811000',
    name: 'Banna',
    oldName: 'Espiritu',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102811000',
  },
  {
    code: '012812000',
    name: 'City of Laoag',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102812000',
  },
  {
    code: '012813000',
    name: 'Marcos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102813000',
  },
  {
    code: '012814000',
    name: 'Nueva Era',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102814000',
  },
  {
    code: '012815000',
    name: 'Pagudpud',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102815000',
  },
  {
    code: '012816000',
    name: 'Paoay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102816000',
  },
  {
    code: '012817000',
    name: 'Pasuquin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102817000',
  },
  {
    code: '012818000',
    name: 'Piddig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102818000',
  },
  {
    code: '012819000',
    name: 'Pinili',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102819000',
  },
  {
    code: '012820000',
    name: 'San Nicolas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102820000',
  },
  {
    code: '012821000',
    name: 'Sarrat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102821000',
  },
  {
    code: '012822000',
    name: 'Solsona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102822000',
  },
  {
    code: '012823000',
    name: 'Vintar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012800000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102823000',
  },
  {
    code: '012901000',
    name: 'Alilem',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102901000',
  },
  {
    code: '012902000',
    name: 'Banayoyo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102902000',
  },
  {
    code: '012903000',
    name: 'Bantay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102903000',
  },
  {
    code: '012904000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102904000',
  },
  {
    code: '012905000',
    name: 'Cabugao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102905000',
  },
  {
    code: '012906000',
    name: 'City of Candon',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102906000',
  },
  {
    code: '012907000',
    name: 'Caoayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102907000',
  },
  {
    code: '012908000',
    name: 'Cervantes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102908000',
  },
  {
    code: '012909000',
    name: 'Galimuyod',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102909000',
  },
  {
    code: '012910000',
    name: 'Gregorio Del Pilar',
    oldName: 'Concepcion',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102910000',
  },
  {
    code: '012911000',
    name: 'Lidlidda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102911000',
  },
  {
    code: '012912000',
    name: 'Magsingal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102912000',
  },
  {
    code: '012913000',
    name: 'Nagbukel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102913000',
  },
  {
    code: '012914000',
    name: 'Narvacan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102914000',
  },
  {
    code: '012915000',
    name: 'Quirino',
    oldName: 'Angkaki',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102915000',
  },
  {
    code: '012916000',
    name: 'Salcedo',
    oldName: 'Baugen',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102916000',
  },
  {
    code: '012917000',
    name: 'San Emilio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102917000',
  },
  {
    code: '012918000',
    name: 'San Esteban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102918000',
  },
  {
    code: '012919000',
    name: 'San Ildefonso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102919000',
  },
  {
    code: '012920000',
    name: 'San Juan',
    oldName: 'Lapog',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102920000',
  },
  {
    code: '012921000',
    name: 'San Vicente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102921000',
  },
  {
    code: '012922000',
    name: 'Santa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102922000',
  },
  {
    code: '012923000',
    name: 'Santa Catalina',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102923000',
  },
  {
    code: '012924000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102924000',
  },
  {
    code: '012925000',
    name: 'Santa Lucia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102925000',
  },
  {
    code: '012926000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102926000',
  },
  {
    code: '012927000',
    name: 'Santiago',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102927000',
  },
  {
    code: '012928000',
    name: 'Santo Domingo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102928000',
  },
  {
    code: '012929000',
    name: 'Sigay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102929000',
  },
  {
    code: '012930000',
    name: 'Sinait',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102930000',
  },
  {
    code: '012931000',
    name: 'Sugpon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102931000',
  },
  {
    code: '012932000',
    name: 'Suyo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102932000',
  },
  {
    code: '012933000',
    name: 'Tagudin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102933000',
  },
  {
    code: '012934000',
    name: 'City of Vigan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '012900000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0102934000',
  },
  {
    code: '013301000',
    name: 'Agoo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103301000',
  },
  {
    code: '013302000',
    name: 'Aringay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103302000',
  },
  {
    code: '013303000',
    name: 'Bacnotan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103303000',
  },
  {
    code: '013304000',
    name: 'Bagulin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103304000',
  },
  {
    code: '013305000',
    name: 'Balaoan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103305000',
  },
  {
    code: '013306000',
    name: 'Bangar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103306000',
  },
  {
    code: '013307000',
    name: 'Bauang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103307000',
  },
  {
    code: '013308000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103308000',
  },
  {
    code: '013309000',
    name: 'Caba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103309000',
  },
  {
    code: '013310000',
    name: 'Luna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103310000',
  },
  {
    code: '013311000',
    name: 'Naguilian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103311000',
  },
  {
    code: '013312000',
    name: 'Pugo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103312000',
  },
  {
    code: '013313000',
    name: 'Rosario',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103313000',
  },
  {
    code: '013314000',
    name: 'City of San Fernando',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103314000',
  },
  {
    code: '013315000',
    name: 'San Gabriel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103315000',
  },
  {
    code: '013316000',
    name: 'San Juan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103316000',
  },
  {
    code: '013317000',
    name: 'Santo Tomas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103317000',
  },
  {
    code: '013318000',
    name: 'Santol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103318000',
  },
  {
    code: '013319000',
    name: 'Sudipen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103319000',
  },
  {
    code: '013320000',
    name: 'Tubao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '013300000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0103320000',
  },
  {
    code: '015501000',
    name: 'Agno',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105501000',
  },
  {
    code: '015502000',
    name: 'Aguilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105502000',
  },
  {
    code: '015503000',
    name: 'City of Alaminos',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105503000',
  },
  {
    code: '015504000',
    name: 'Alcala',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105504000',
  },
  {
    code: '015505000',
    name: 'Anda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105505000',
  },
  {
    code: '015506000',
    name: 'Asingan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105506000',
  },
  {
    code: '015507000',
    name: 'Balungao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105507000',
  },
  {
    code: '015508000',
    name: 'Bani',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105508000',
  },
  {
    code: '015509000',
    name: 'Basista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105509000',
  },
  {
    code: '015510000',
    name: 'Bautista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105510000',
  },
  {
    code: '015511000',
    name: 'Bayambang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105511000',
  },
  {
    code: '015512000',
    name: 'Binalonan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105512000',
  },
  {
    code: '015513000',
    name: 'Binmaley',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105513000',
  },
  {
    code: '015514000',
    name: 'Bolinao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105514000',
  },
  {
    code: '015515000',
    name: 'Bugallon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105515000',
  },
  {
    code: '015516000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105516000',
  },
  {
    code: '015517000',
    name: 'Calasiao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105517000',
  },
  {
    code: '015518000',
    name: 'City of Dagupan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105518000',
  },
  {
    code: '015519000',
    name: 'Dasol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105519000',
  },
  {
    code: '015520000',
    name: 'Infanta',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105520000',
  },
  {
    code: '015521000',
    name: 'Labrador',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105521000',
  },
  {
    code: '015522000',
    name: 'Lingayen',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105522000',
  },
  {
    code: '015523000',
    name: 'Mabini',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105523000',
  },
  {
    code: '015524000',
    name: 'Malasiqui',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105524000',
  },
  {
    code: '015525000',
    name: 'Manaoag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105525000',
  },
  {
    code: '015526000',
    name: 'Mangaldan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105526000',
  },
  {
    code: '015527000',
    name: 'Mangatarem',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105527000',
  },
  {
    code: '015528000',
    name: 'Mapandan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105528000',
  },
  {
    code: '015529000',
    name: 'Natividad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105529000',
  },
  {
    code: '015530000',
    name: 'Pozorrubio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105530000',
  },
  {
    code: '015531000',
    name: 'Rosales',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105531000',
  },
  {
    code: '015532000',
    name: 'City of San Carlos',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105532000',
  },
  {
    code: '015533000',
    name: 'San Fabian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105533000',
  },
  {
    code: '015534000',
    name: 'San Jacinto',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105534000',
  },
  {
    code: '015535000',
    name: 'San Manuel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105535000',
  },
  {
    code: '015536000',
    name: 'San Nicolas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105536000',
  },
  {
    code: '015537000',
    name: 'San Quintin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105537000',
  },
  {
    code: '015538000',
    name: 'Santa Barbara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105538000',
  },
  {
    code: '015539000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105539000',
  },
  {
    code: '015540000',
    name: 'Santo Tomas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105540000',
  },
  {
    code: '015541000',
    name: 'Sison',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105541000',
  },
  {
    code: '015542000',
    name: 'Sual',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105542000',
  },
  {
    code: '015543000',
    name: 'Tayug',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105543000',
  },
  {
    code: '015544000',
    name: 'Umingan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105544000',
  },
  {
    code: '015545000',
    name: 'Urbiztondo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105545000',
  },
  {
    code: '015546000',
    name: 'City of Urdaneta',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105546000',
  },
  {
    code: '015547000',
    name: 'Villasis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105547000',
  },
  {
    code: '015548000',
    name: 'Laoac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '015500000',
    districtCode: false,
    regionCode: '010000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0105548000',
  },
  {
    code: '020901000',
    name: 'Basco',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200901000',
  },
  {
    code: '020902000',
    name: 'Itbayat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200902000',
  },
  {
    code: '020903000',
    name: 'Ivana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200903000',
  },
  {
    code: '020904000',
    name: 'Mahatao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200904000',
  },
  {
    code: '020905000',
    name: 'Sabtang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200905000',
  },
  {
    code: '020906000',
    name: 'Uyugan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '020900000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0200906000',
  },
  {
    code: '021501000',
    name: 'Abulug',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201501000',
  },
  {
    code: '021502000',
    name: 'Alcala',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201502000',
  },
  {
    code: '021503000',
    name: 'Allacapan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201503000',
  },
  {
    code: '021504000',
    name: 'Amulung',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201504000',
  },
  {
    code: '021505000',
    name: 'Aparri',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201505000',
  },
  {
    code: '021506000',
    name: 'Baggao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201506000',
  },
  {
    code: '021507000',
    name: 'Ballesteros',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201507000',
  },
  {
    code: '021508000',
    name: 'Buguey',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201508000',
  },
  {
    code: '021509000',
    name: 'Calayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201509000',
  },
  {
    code: '021510000',
    name: 'Camalaniugan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201510000',
  },
  {
    code: '021511000',
    name: 'Claveria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201511000',
  },
  {
    code: '021512000',
    name: 'Enrile',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201512000',
  },
  {
    code: '021513000',
    name: 'Gattaran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201513000',
  },
  {
    code: '021514000',
    name: 'Gonzaga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201514000',
  },
  {
    code: '021515000',
    name: 'Iguig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201515000',
  },
  {
    code: '021516000',
    name: 'Lal-Lo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201516000',
  },
  {
    code: '021517000',
    name: 'Lasam',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201517000',
  },
  {
    code: '021518000',
    name: 'Pamplona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201518000',
  },
  {
    code: '021519000',
    name: 'Peñablanca',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201519000',
  },
  {
    code: '021520000',
    name: 'Piat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201520000',
  },
  {
    code: '021521000',
    name: 'Rizal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201521000',
  },
  {
    code: '021522000',
    name: 'Sanchez-Mira',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201522000',
  },
  {
    code: '021523000',
    name: 'Santa Ana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201523000',
  },
  {
    code: '021524000',
    name: 'Santa Praxedes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201524000',
  },
  {
    code: '021525000',
    name: 'Santa Teresita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201525000',
  },
  {
    code: '021526000',
    name: 'Santo Niño',
    oldName: 'Faire',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201526000',
  },
  {
    code: '021527000',
    name: 'Solana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201527000',
  },
  {
    code: '021528000',
    name: 'Tuao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201528000',
  },
  {
    code: '021529000',
    name: 'Tuguegarao City',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '021500000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0201529000',
  },
  {
    code: '023101000',
    name: 'Alicia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203101000',
  },
  {
    code: '023102000',
    name: 'Angadanan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203102000',
  },
  {
    code: '023103000',
    name: 'Aurora',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203103000',
  },
  {
    code: '023104000',
    name: 'Benito Soliven',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203104000',
  },
  {
    code: '023105000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203105000',
  },
  {
    code: '023106000',
    name: 'Cabagan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203106000',
  },
  {
    code: '023107000',
    name: 'Cabatuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203107000',
  },
  {
    code: '023108000',
    name: 'City of Cauayan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203108000',
  },
  {
    code: '023109000',
    name: 'Cordon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203109000',
  },
  {
    code: '023110000',
    name: 'Dinapigue',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203110000',
  },
  {
    code: '023111000',
    name: 'Divilacan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203111000',
  },
  {
    code: '023112000',
    name: 'Echague',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203112000',
  },
  {
    code: '023113000',
    name: 'Gamu',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203113000',
  },
  {
    code: '023114000',
    name: 'City of Ilagan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203114000',
  },
  {
    code: '023115000',
    name: 'Jones',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203115000',
  },
  {
    code: '023116000',
    name: 'Luna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203116000',
  },
  {
    code: '023117000',
    name: 'Maconacon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203117000',
  },
  {
    code: '023118000',
    name: 'Delfin Albano',
    oldName: 'Magsaysay',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203118000',
  },
  {
    code: '023119000',
    name: 'Mallig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203119000',
  },
  {
    code: '023120000',
    name: 'Naguilian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203120000',
  },
  {
    code: '023121000',
    name: 'Palanan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203121000',
  },
  {
    code: '023122000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203122000',
  },
  {
    code: '023123000',
    name: 'Quirino',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203123000',
  },
  {
    code: '023124000',
    name: 'Ramon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203124000',
  },
  {
    code: '023125000',
    name: 'Reina Mercedes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203125000',
  },
  {
    code: '023126000',
    name: 'Roxas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203126000',
  },
  {
    code: '023127000',
    name: 'San Agustin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203127000',
  },
  {
    code: '023128000',
    name: 'San Guillermo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203128000',
  },
  {
    code: '023129000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203129000',
  },
  {
    code: '023130000',
    name: 'San Manuel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203130000',
  },
  {
    code: '023131000',
    name: 'San Mariano',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203131000',
  },
  {
    code: '023132000',
    name: 'San Mateo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203132000',
  },
  {
    code: '023133000',
    name: 'San Pablo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203133000',
  },
  {
    code: '023134000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203134000',
  },
  {
    code: '023135000',
    name: 'City of Santiago',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203135000',
  },
  {
    code: '023136000',
    name: 'Santo Tomas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203136000',
  },
  {
    code: '023137000',
    name: 'Tumauini',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '023100000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0203137000',
  },
  {
    code: '025001000',
    name: 'Ambaguio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205001000',
  },
  {
    code: '025002000',
    name: 'Aritao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205002000',
  },
  {
    code: '025003000',
    name: 'Bagabag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205003000',
  },
  {
    code: '025004000',
    name: 'Bambang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205004000',
  },
  {
    code: '025005000',
    name: 'Bayombong',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205005000',
  },
  {
    code: '025006000',
    name: 'Diadi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205006000',
  },
  {
    code: '025007000',
    name: 'Dupax Del Norte',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205007000',
  },
  {
    code: '025008000',
    name: 'Dupax Del Sur',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205008000',
  },
  {
    code: '025009000',
    name: 'Kasibu',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205009000',
  },
  {
    code: '025010000',
    name: 'Kayapa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205010000',
  },
  {
    code: '025011000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205011000',
  },
  {
    code: '025012000',
    name: 'Santa Fe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205012000',
  },
  {
    code: '025013000',
    name: 'Solano',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205013000',
  },
  {
    code: '025014000',
    name: 'Villaverde',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205014000',
  },
  {
    code: '025015000',
    name: 'Alfonso Castaneda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025000000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205015000',
  },
  {
    code: '025701000',
    name: 'Aglipay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205701000',
  },
  {
    code: '025702000',
    name: 'Cabarroguis',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205702000',
  },
  {
    code: '025703000',
    name: 'Diffun',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205703000',
  },
  {
    code: '025704000',
    name: 'Maddela',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205704000',
  },
  {
    code: '025705000',
    name: 'Saguday',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205705000',
  },
  {
    code: '025706000',
    name: 'Nagtipunan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '025700000',
    districtCode: false,
    regionCode: '020000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0205706000',
  },
  {
    code: '030801000',
    name: 'Abucay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300801000',
  },
  {
    code: '030802000',
    name: 'Bagac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300802000',
  },
  {
    code: '030803000',
    name: 'City of Balanga',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300803000',
  },
  {
    code: '030804000',
    name: 'Dinalupihan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300804000',
  },
  {
    code: '030805000',
    name: 'Hermosa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300805000',
  },
  {
    code: '030806000',
    name: 'Limay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300806000',
  },
  {
    code: '030807000',
    name: 'Mariveles',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300807000',
  },
  {
    code: '030808000',
    name: 'Morong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300808000',
  },
  {
    code: '030809000',
    name: 'Orani',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300809000',
  },
  {
    code: '030810000',
    name: 'Orion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300810000',
  },
  {
    code: '030811000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300811000',
  },
  {
    code: '030812000',
    name: 'Samal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '030800000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0300812000',
  },
  {
    code: '031401000',
    name: 'Angat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301401000',
  },
  {
    code: '031402000',
    name: 'Balagtas',
    oldName: 'Bigaa',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301402000',
  },
  {
    code: '031403000',
    name: 'Baliuag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301403000',
  },
  {
    code: '031404000',
    name: 'Bocaue',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301404000',
  },
  {
    code: '031405000',
    name: 'Bulacan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301405000',
  },
  {
    code: '031406000',
    name: 'Bustos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301406000',
  },
  {
    code: '031407000',
    name: 'Calumpit',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301407000',
  },
  {
    code: '031408000',
    name: 'Guiguinto',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301408000',
  },
  {
    code: '031409000',
    name: 'Hagonoy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301409000',
  },
  {
    code: '031410000',
    name: 'City of Malolos',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301410000',
  },
  {
    code: '031411000',
    name: 'Marilao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301411000',
  },
  {
    code: '031412000',
    name: 'City of Meycauayan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301412000',
  },
  {
    code: '031413000',
    name: 'Norzagaray',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301413000',
  },
  {
    code: '031414000',
    name: 'Obando',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301414000',
  },
  {
    code: '031415000',
    name: 'Pandi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301415000',
  },
  {
    code: '031416000',
    name: 'Paombong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301416000',
  },
  {
    code: '031417000',
    name: 'Plaridel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301417000',
  },
  {
    code: '031418000',
    name: 'Pulilan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301418000',
  },
  {
    code: '031419000',
    name: 'San Ildefonso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301419000',
  },
  {
    code: '031420000',
    name: 'City of San Jose Del Monte',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301420000',
  },
  {
    code: '031421000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301421000',
  },
  {
    code: '031422000',
    name: 'San Rafael',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301422000',
  },
  {
    code: '031423000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301423000',
  },
  {
    code: '031424000',
    name: 'Doña Remedios Trinidad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '031400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0301424000',
  },
  {
    code: '034901000',
    name: 'Aliaga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304901000',
  },
  {
    code: '034902000',
    name: 'Bongabon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304902000',
  },
  {
    code: '034903000',
    name: 'City of Cabanatuan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304903000',
  },
  {
    code: '034904000',
    name: 'Cabiao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304904000',
  },
  {
    code: '034905000',
    name: 'Carranglan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304905000',
  },
  {
    code: '034906000',
    name: 'Cuyapo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304906000',
  },
  {
    code: '034907000',
    name: 'Gabaldon',
    oldName: 'Bitulok & Sabani',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304907000',
  },
  {
    code: '034908000',
    name: 'City of Gapan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304908000',
  },
  {
    code: '034909000',
    name: 'General Mamerto Natividad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304909000',
  },
  {
    code: '034910000',
    name: 'General Tinio',
    oldName: 'Papaya',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304910000',
  },
  {
    code: '034911000',
    name: 'Guimba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304911000',
  },
  {
    code: '034912000',
    name: 'Jaen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304912000',
  },
  {
    code: '034913000',
    name: 'Laur',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304913000',
  },
  {
    code: '034914000',
    name: 'Licab',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304914000',
  },
  {
    code: '034915000',
    name: 'Llanera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304915000',
  },
  {
    code: '034916000',
    name: 'Lupao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304916000',
  },
  {
    code: '034917000',
    name: 'Science City of Muñoz',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304917000',
  },
  {
    code: '034918000',
    name: 'Nampicuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304918000',
  },
  {
    code: '034919000',
    name: 'City of Palayan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304919000',
  },
  {
    code: '034920000',
    name: 'Pantabangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304920000',
  },
  {
    code: '034921000',
    name: 'Peñaranda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304921000',
  },
  {
    code: '034922000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304922000',
  },
  {
    code: '034923000',
    name: 'Rizal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304923000',
  },
  {
    code: '034924000',
    name: 'San Antonio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304924000',
  },
  {
    code: '034925000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304925000',
  },
  {
    code: '034926000',
    name: 'San Jose City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304926000',
  },
  {
    code: '034927000',
    name: 'San Leonardo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304927000',
  },
  {
    code: '034928000',
    name: 'Santa Rosa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304928000',
  },
  {
    code: '034929000',
    name: 'Santo Domingo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304929000',
  },
  {
    code: '034930000',
    name: 'Talavera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304930000',
  },
  {
    code: '034931000',
    name: 'Talugtug',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304931000',
  },
  {
    code: '034932000',
    name: 'Zaragoza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '034900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0304932000',
  },
  {
    code: '035401000',
    name: 'City of Angeles',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0330100000',
  },
  {
    code: '035402000',
    name: 'Apalit',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305402000',
  },
  {
    code: '035403000',
    name: 'Arayat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305403000',
  },
  {
    code: '035404000',
    name: 'Bacolor',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305404000',
  },
  {
    code: '035405000',
    name: 'Candaba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305405000',
  },
  {
    code: '035406000',
    name: 'Floridablanca',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305406000',
  },
  {
    code: '035407000',
    name: 'Guagua',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305407000',
  },
  {
    code: '035408000',
    name: 'Lubao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305408000',
  },
  {
    code: '035409000',
    name: 'Mabalacat City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305409000',
  },
  {
    code: '035410000',
    name: 'Macabebe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305410000',
  },
  {
    code: '035411000',
    name: 'Magalang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305411000',
  },
  {
    code: '035412000',
    name: 'Masantol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305412000',
  },
  {
    code: '035413000',
    name: 'Mexico',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305413000',
  },
  {
    code: '035414000',
    name: 'Minalin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305414000',
  },
  {
    code: '035415000',
    name: 'Porac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305415000',
  },
  {
    code: '035416000',
    name: 'City of San Fernando',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305416000',
  },
  {
    code: '035417000',
    name: 'San Luis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305417000',
  },
  {
    code: '035418000',
    name: 'San Simon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305418000',
  },
  {
    code: '035419000',
    name: 'Santa Ana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305419000',
  },
  {
    code: '035420000',
    name: 'Santa Rita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305420000',
  },
  {
    code: '035421000',
    name: 'Sto. Tomas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305421000',
  },
  {
    code: '035422000',
    name: 'Sasmuan',
    oldName: 'Sexmoan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '035400000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0305422000',
  },
  {
    code: '036901000',
    name: 'Anao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306901000',
  },
  {
    code: '036902000',
    name: 'Bamban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306902000',
  },
  {
    code: '036903000',
    name: 'Camiling',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306903000',
  },
  {
    code: '036904000',
    name: 'Capas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306904000',
  },
  {
    code: '036905000',
    name: 'Concepcion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306905000',
  },
  {
    code: '036906000',
    name: 'Gerona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306906000',
  },
  {
    code: '036907000',
    name: 'La Paz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306907000',
  },
  {
    code: '036908000',
    name: 'Mayantoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306908000',
  },
  {
    code: '036909000',
    name: 'Moncada',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306909000',
  },
  {
    code: '036910000',
    name: 'Paniqui',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306910000',
  },
  {
    code: '036911000',
    name: 'Pura',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306911000',
  },
  {
    code: '036912000',
    name: 'Ramos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306912000',
  },
  {
    code: '036913000',
    name: 'San Clemente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306913000',
  },
  {
    code: '036914000',
    name: 'San Manuel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306914000',
  },
  {
    code: '036915000',
    name: 'Santa Ignacia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306915000',
  },
  {
    code: '036916000',
    name: 'City of Tarlac',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306916000',
  },
  {
    code: '036917000',
    name: 'Victoria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306917000',
  },
  {
    code: '036918000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '036900000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0306918000',
  },
  {
    code: '037101000',
    name: 'Botolan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307101000',
  },
  {
    code: '037102000',
    name: 'Cabangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307102000',
  },
  {
    code: '037103000',
    name: 'Candelaria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307103000',
  },
  {
    code: '037104000',
    name: 'Castillejos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307104000',
  },
  {
    code: '037105000',
    name: 'Iba',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307105000',
  },
  {
    code: '037106000',
    name: 'Masinloc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307106000',
  },
  {
    code: '037107000',
    name: 'City of Olongapo',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0331400000',
  },
  {
    code: '037108000',
    name: 'Palauig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307108000',
  },
  {
    code: '037109000',
    name: 'San Antonio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307109000',
  },
  {
    code: '037110000',
    name: 'San Felipe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307110000',
  },
  {
    code: '037111000',
    name: 'San Marcelino',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307111000',
  },
  {
    code: '037112000',
    name: 'San Narciso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307112000',
  },
  {
    code: '037113000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307113000',
  },
  {
    code: '037114000',
    name: 'Subic',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037100000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307114000',
  },
  {
    code: '037701000',
    name: 'Baler',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307701000',
  },
  {
    code: '037702000',
    name: 'Casiguran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307702000',
  },
  {
    code: '037703000',
    name: 'Dilasag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307703000',
  },
  {
    code: '037704000',
    name: 'Dinalungan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307704000',
  },
  {
    code: '037705000',
    name: 'Dingalan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307705000',
  },
  {
    code: '037706000',
    name: 'Dipaculao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307706000',
  },
  {
    code: '037707000',
    name: 'Maria Aurora',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307707000',
  },
  {
    code: '037708000',
    name: 'San Luis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '037700000',
    districtCode: false,
    regionCode: '030000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0307708000',
  },
  {
    code: '041001000',
    name: 'Agoncillo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401001000',
  },
  {
    code: '041002000',
    name: 'Alitagtag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401002000',
  },
  {
    code: '041003000',
    name: 'Balayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401003000',
  },
  {
    code: '041004000',
    name: 'Balete',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401004000',
  },
  {
    code: '041005000',
    name: 'Batangas City',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401005000',
  },
  {
    code: '041006000',
    name: 'Bauan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401006000',
  },
  {
    code: '041007000',
    name: 'Calaca',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401007000',
  },
  {
    code: '041008000',
    name: 'Calatagan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401008000',
  },
  {
    code: '041009000',
    name: 'Cuenca',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401009000',
  },
  {
    code: '041010000',
    name: 'Ibaan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401010000',
  },
  {
    code: '041011000',
    name: 'Laurel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401011000',
  },
  {
    code: '041012000',
    name: 'Lemery',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401012000',
  },
  {
    code: '041013000',
    name: 'Lian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401013000',
  },
  {
    code: '041014000',
    name: 'City of Lipa',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401014000',
  },
  {
    code: '041015000',
    name: 'Lobo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401015000',
  },
  {
    code: '041016000',
    name: 'Mabini',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401016000',
  },
  {
    code: '041017000',
    name: 'Malvar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401017000',
  },
  {
    code: '041018000',
    name: 'Mataasnakahoy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401018000',
  },
  {
    code: '041019000',
    name: 'Nasugbu',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401019000',
  },
  {
    code: '041020000',
    name: 'Padre Garcia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401020000',
  },
  {
    code: '041021000',
    name: 'Rosario',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401021000',
  },
  {
    code: '041022000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401022000',
  },
  {
    code: '041023000',
    name: 'San Juan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401023000',
  },
  {
    code: '041024000',
    name: 'San Luis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401024000',
  },
  {
    code: '041025000',
    name: 'San Nicolas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401025000',
  },
  {
    code: '041026000',
    name: 'San Pascual',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401026000',
  },
  {
    code: '041027000',
    name: 'Santa Teresita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401027000',
  },
  {
    code: '041028000',
    name: 'City of Sto. Tomas',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401028000',
  },
  {
    code: '041029000',
    name: 'Taal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401029000',
  },
  {
    code: '041030000',
    name: 'Talisay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401030000',
  },
  {
    code: '041031000',
    name: 'City of Tanauan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401031000',
  },
  {
    code: '041032000',
    name: 'Taysan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401032000',
  },
  {
    code: '041033000',
    name: 'Tingloy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401033000',
  },
  {
    code: '041034000',
    name: 'Tuy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '041000000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0401034000',
  },
  {
    code: '042101000',
    name: 'Alfonso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402101000',
  },
  {
    code: '042102000',
    name: 'Amadeo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402102000',
  },
  {
    code: '042103000',
    name: 'City of Bacoor',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402103000',
  },
  {
    code: '042104000',
    name: 'Carmona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402104000',
  },
  {
    code: '042105000',
    name: 'City of Cavite',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402105000',
  },
  {
    code: '042106000',
    name: 'City of Dasmariñas',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402106000',
  },
  {
    code: '042107000',
    name: 'General Emilio Aguinaldo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402107000',
  },
  {
    code: '042108000',
    name: 'City of General Trias',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402108000',
  },
  {
    code: '042109000',
    name: 'City of Imus',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402109000',
  },
  {
    code: '042110000',
    name: 'Indang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402110000',
  },
  {
    code: '042111000',
    name: 'Kawit',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402111000',
  },
  {
    code: '042112000',
    name: 'Magallanes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402112000',
  },
  {
    code: '042113000',
    name: 'Maragondon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402113000',
  },
  {
    code: '042114000',
    name: 'Mendez',
    oldName: 'Mendez-Nuñez',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402114000',
  },
  {
    code: '042115000',
    name: 'Naic',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402115000',
  },
  {
    code: '042116000',
    name: 'Noveleta',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402116000',
  },
  {
    code: '042117000',
    name: 'Rosario',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402117000',
  },
  {
    code: '042118000',
    name: 'Silang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402118000',
  },
  {
    code: '042119000',
    name: 'City of Tagaytay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402119000',
  },
  {
    code: '042120000',
    name: 'Tanza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402120000',
  },
  {
    code: '042121000',
    name: 'Ternate',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402121000',
  },
  {
    code: '042122000',
    name: 'City of Trece Martires',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402122000',
  },
  {
    code: '042123000',
    name: 'Gen. Mariano Alvarez',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '042100000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0402123000',
  },
  {
    code: '043401000',
    name: 'Alaminos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403401000',
  },
  {
    code: '043402000',
    name: 'Bay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403402000',
  },
  {
    code: '043403000',
    name: 'City of Biñan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403403000',
  },
  {
    code: '043404000',
    name: 'City of Cabuyao',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403404000',
  },
  {
    code: '043405000',
    name: 'City of Calamba',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403405000',
  },
  {
    code: '043406000',
    name: 'Calauan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403406000',
  },
  {
    code: '043407000',
    name: 'Cavinti',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403407000',
  },
  {
    code: '043408000',
    name: 'Famy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403408000',
  },
  {
    code: '043409000',
    name: 'Kalayaan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403409000',
  },
  {
    code: '043410000',
    name: 'Liliw',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403410000',
  },
  {
    code: '043411000',
    name: 'Los Baños',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403411000',
  },
  {
    code: '043412000',
    name: 'Luisiana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403412000',
  },
  {
    code: '043413000',
    name: 'Lumban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403413000',
  },
  {
    code: '043414000',
    name: 'Mabitac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403414000',
  },
  {
    code: '043415000',
    name: 'Magdalena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403415000',
  },
  {
    code: '043416000',
    name: 'Majayjay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403416000',
  },
  {
    code: '043417000',
    name: 'Nagcarlan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403417000',
  },
  {
    code: '043418000',
    name: 'Paete',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403418000',
  },
  {
    code: '043419000',
    name: 'Pagsanjan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403419000',
  },
  {
    code: '043420000',
    name: 'Pakil',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403420000',
  },
  {
    code: '043421000',
    name: 'Pangil',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403421000',
  },
  {
    code: '043422000',
    name: 'Pila',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403422000',
  },
  {
    code: '043423000',
    name: 'Rizal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403423000',
  },
  {
    code: '043424000',
    name: 'City of San Pablo',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403424000',
  },
  {
    code: '043425000',
    name: 'City of San Pedro',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403425000',
  },
  {
    code: '043426000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403426000',
  },
  {
    code: '043427000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403427000',
  },
  {
    code: '043428000',
    name: 'City of Santa Rosa',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403428000',
  },
  {
    code: '043429000',
    name: 'Siniloan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403429000',
  },
  {
    code: '043430000',
    name: 'Victoria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '043400000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0403430000',
  },
  {
    code: '045601000',
    name: 'Agdangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405601000',
  },
  {
    code: '045602000',
    name: 'Alabat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405602000',
  },
  {
    code: '045603000',
    name: 'Atimonan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405603000',
  },
  {
    code: '045605000',
    name: 'Buenavista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405605000',
  },
  {
    code: '045606000',
    name: 'Burdeos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405606000',
  },
  {
    code: '045607000',
    name: 'Calauag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405607000',
  },
  {
    code: '045608000',
    name: 'Candelaria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405608000',
  },
  {
    code: '045610000',
    name: 'Catanauan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405610000',
  },
  {
    code: '045615000',
    name: 'Dolores',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405615000',
  },
  {
    code: '045616000',
    name: 'General Luna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405616000',
  },
  {
    code: '045617000',
    name: 'General Nakar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405617000',
  },
  {
    code: '045618000',
    name: 'Guinayangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405618000',
  },
  {
    code: '045619000',
    name: 'Gumaca',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405619000',
  },
  {
    code: '045620000',
    name: 'Infanta',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405620000',
  },
  {
    code: '045621000',
    name: 'Jomalig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405621000',
  },
  {
    code: '045622000',
    name: 'Lopez',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405622000',
  },
  {
    code: '045623000',
    name: 'Lucban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405623000',
  },
  {
    code: '045624000',
    name: 'City of Lucena',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0431200000',
  },
  {
    code: '045625000',
    name: 'Macalelon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405625000',
  },
  {
    code: '045627000',
    name: 'Mauban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405627000',
  },
  {
    code: '045628000',
    name: 'Mulanay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405628000',
  },
  {
    code: '045629000',
    name: 'Padre Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405629000',
  },
  {
    code: '045630000',
    name: 'Pagbilao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405630000',
  },
  {
    code: '045631000',
    name: 'Panukulan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405631000',
  },
  {
    code: '045632000',
    name: 'Patnanungan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405632000',
  },
  {
    code: '045633000',
    name: 'Perez',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405633000',
  },
  {
    code: '045634000',
    name: 'Pitogo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405634000',
  },
  {
    code: '045635000',
    name: 'Plaridel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405635000',
  },
  {
    code: '045636000',
    name: 'Polillo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405636000',
  },
  {
    code: '045637000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405637000',
  },
  {
    code: '045638000',
    name: 'Real',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405638000',
  },
  {
    code: '045639000',
    name: 'Sampaloc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405639000',
  },
  {
    code: '045640000',
    name: 'San Andres',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405640000',
  },
  {
    code: '045641000',
    name: 'San Antonio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405641000',
  },
  {
    code: '045642000',
    name: 'San Francisco',
    oldName: 'Aurora',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405642000',
  },
  {
    code: '045644000',
    name: 'San Narciso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405644000',
  },
  {
    code: '045645000',
    name: 'Sariaya',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405645000',
  },
  {
    code: '045646000',
    name: 'Tagkawayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405646000',
  },
  {
    code: '045647000',
    name: 'City of Tayabas',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405647000',
  },
  {
    code: '045648000',
    name: 'Tiaong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405648000',
  },
  {
    code: '045649000',
    name: 'Unisan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045600000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405649000',
  },
  {
    code: '045801000',
    name: 'Angono',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405801000',
  },
  {
    code: '045802000',
    name: 'City of Antipolo',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405802000',
  },
  {
    code: '045803000',
    name: 'Baras',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405803000',
  },
  {
    code: '045804000',
    name: 'Binangonan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405804000',
  },
  {
    code: '045805000',
    name: 'Cainta',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405805000',
  },
  {
    code: '045806000',
    name: 'Cardona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405806000',
  },
  {
    code: '045807000',
    name: 'Jala-Jala',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405807000',
  },
  {
    code: '045808000',
    name: 'Rodriguez',
    oldName: 'Montalban',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405808000',
  },
  {
    code: '045809000',
    name: 'Morong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405809000',
  },
  {
    code: '045810000',
    name: 'Pililla',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405810000',
  },
  {
    code: '045811000',
    name: 'San Mateo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405811000',
  },
  {
    code: '045812000',
    name: 'Tanay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405812000',
  },
  {
    code: '045813000',
    name: 'Taytay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405813000',
  },
  {
    code: '045814000',
    name: 'Teresa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '045800000',
    districtCode: false,
    regionCode: '040000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0405814000',
  },
  {
    code: '174001000',
    name: 'Boac',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704001000',
  },
  {
    code: '174002000',
    name: 'Buenavista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704002000',
  },
  {
    code: '174003000',
    name: 'Gasan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704003000',
  },
  {
    code: '174004000',
    name: 'Mogpog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704004000',
  },
  {
    code: '174005000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704005000',
  },
  {
    code: '174006000',
    name: 'Torrijos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '174000000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1704006000',
  },
  {
    code: '175101000',
    name: 'Abra De Ilog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705101000',
  },
  {
    code: '175102000',
    name: 'Calintaan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705102000',
  },
  {
    code: '175103000',
    name: 'Looc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705103000',
  },
  {
    code: '175104000',
    name: 'Lubang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705104000',
  },
  {
    code: '175105000',
    name: 'Magsaysay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705105000',
  },
  {
    code: '175106000',
    name: 'Mamburao',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705106000',
  },
  {
    code: '175107000',
    name: 'Paluan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705107000',
  },
  {
    code: '175108000',
    name: 'Rizal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705108000',
  },
  {
    code: '175109000',
    name: 'Sablayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705109000',
  },
  {
    code: '175110000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705110000',
  },
  {
    code: '175111000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175100000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705111000',
  },
  {
    code: '175201000',
    name: 'Baco',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705201000',
  },
  {
    code: '175202000',
    name: 'Bansud',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705202000',
  },
  {
    code: '175203000',
    name: 'Bongabong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705203000',
  },
  {
    code: '175204000',
    name: 'Bulalacao',
    oldName: 'San Pedro',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705204000',
  },
  {
    code: '175205000',
    name: 'City of Calapan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705205000',
  },
  {
    code: '175206000',
    name: 'Gloria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705206000',
  },
  {
    code: '175207000',
    name: 'Mansalay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705207000',
  },
  {
    code: '175208000',
    name: 'Naujan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705208000',
  },
  {
    code: '175209000',
    name: 'Pinamalayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705209000',
  },
  {
    code: '175210000',
    name: 'Pola',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705210000',
  },
  {
    code: '175211000',
    name: 'Puerto Galera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705211000',
  },
  {
    code: '175212000',
    name: 'Roxas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705212000',
  },
  {
    code: '175213000',
    name: 'San Teodoro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705213000',
  },
  {
    code: '175214000',
    name: 'Socorro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705214000',
  },
  {
    code: '175215000',
    name: 'Victoria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175200000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705215000',
  },
  {
    code: '175301000',
    name: 'Aborlan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705301000',
  },
  {
    code: '175302000',
    name: 'Agutaya',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705302000',
  },
  {
    code: '175303000',
    name: 'Araceli',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705303000',
  },
  {
    code: '175304000',
    name: 'Balabac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705304000',
  },
  {
    code: '175305000',
    name: 'Bataraza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705305000',
  },
  {
    code: '175306000',
    name: "Brooke's Point",
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705306000',
  },
  {
    code: '175307000',
    name: 'Busuanga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705307000',
  },
  {
    code: '175308000',
    name: 'Cagayancillo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705308000',
  },
  {
    code: '175309000',
    name: 'Coron',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705309000',
  },
  {
    code: '175310000',
    name: 'Cuyo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705310000',
  },
  {
    code: '175311000',
    name: 'Dumaran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705311000',
  },
  {
    code: '175312000',
    name: 'El Nido',
    oldName: 'Bacuit',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705312000',
  },
  {
    code: '175313000',
    name: 'Linapacan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705313000',
  },
  {
    code: '175314000',
    name: 'Magsaysay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705314000',
  },
  {
    code: '175315000',
    name: 'Narra',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705315000',
  },
  {
    code: '175316000',
    name: 'City of Puerto Princesa',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1731500000',
  },
  {
    code: '175317000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705317000',
  },
  {
    code: '175318000',
    name: 'Roxas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705318000',
  },
  {
    code: '175319000',
    name: 'San Vicente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705319000',
  },
  {
    code: '175320000',
    name: 'Taytay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705320000',
  },
  {
    code: '175321000',
    name: 'Kalayaan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705321000',
  },
  {
    code: '175322000',
    name: 'Culion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705322000',
  },
  {
    code: '175323000',
    name: 'Rizal',
    oldName: 'Marcos',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705323000',
  },
  {
    code: '175324000',
    name: 'Sofronio Española',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175300000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705324000',
  },
  {
    code: '175901000',
    name: 'Alcantara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705901000',
  },
  {
    code: '175902000',
    name: 'Banton',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705902000',
  },
  {
    code: '175903000',
    name: 'Cajidiocan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705903000',
  },
  {
    code: '175904000',
    name: 'Calatrava',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705904000',
  },
  {
    code: '175905000',
    name: 'Concepcion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705905000',
  },
  {
    code: '175906000',
    name: 'Corcuera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705906000',
  },
  {
    code: '175907000',
    name: 'Looc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705907000',
  },
  {
    code: '175908000',
    name: 'Magdiwang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705908000',
  },
  {
    code: '175909000',
    name: 'Odiongan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705909000',
  },
  {
    code: '175910000',
    name: 'Romblon',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705910000',
  },
  {
    code: '175911000',
    name: 'San Agustin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705911000',
  },
  {
    code: '175912000',
    name: 'San Andres',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705912000',
  },
  {
    code: '175913000',
    name: 'San Fernando',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705913000',
  },
  {
    code: '175914000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705914000',
  },
  {
    code: '175915000',
    name: 'Santa Fe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705915000',
  },
  {
    code: '175916000',
    name: 'Ferrol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705916000',
  },
  {
    code: '175917000',
    name: 'Santa Maria',
    oldName: 'Imelda',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '175900000',
    districtCode: false,
    regionCode: '170000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1705917000',
  },
  {
    code: '050501000',
    name: 'Bacacay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500501000',
  },
  {
    code: '050502000',
    name: 'Camalig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500502000',
  },
  {
    code: '050503000',
    name: 'Daraga',
    oldName: 'Locsin',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500503000',
  },
  {
    code: '050504000',
    name: 'Guinobatan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500504000',
  },
  {
    code: '050505000',
    name: 'Jovellar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500505000',
  },
  {
    code: '050506000',
    name: 'City of Legazpi',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500506000',
  },
  {
    code: '050507000',
    name: 'Libon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500507000',
  },
  {
    code: '050508000',
    name: 'City of Ligao',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500508000',
  },
  {
    code: '050509000',
    name: 'Malilipot',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500509000',
  },
  {
    code: '050510000',
    name: 'Malinao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500510000',
  },
  {
    code: '050511000',
    name: 'Manito',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500511000',
  },
  {
    code: '050512000',
    name: 'Oas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500512000',
  },
  {
    code: '050513000',
    name: 'Pio Duran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500513000',
  },
  {
    code: '050514000',
    name: 'Polangui',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500514000',
  },
  {
    code: '050515000',
    name: 'Rapu-Rapu',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500515000',
  },
  {
    code: '050516000',
    name: 'Santo Domingo',
    oldName: 'Libog',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500516000',
  },
  {
    code: '050517000',
    name: 'City of Tabaco',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500517000',
  },
  {
    code: '050518000',
    name: 'Tiwi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '050500000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0500518000',
  },
  {
    code: '051601000',
    name: 'Basud',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501601000',
  },
  {
    code: '051602000',
    name: 'Capalonga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501602000',
  },
  {
    code: '051603000',
    name: 'Daet',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501603000',
  },
  {
    code: '051604000',
    name: 'San Lorenzo Ruiz',
    oldName: 'Imelda',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501604000',
  },
  {
    code: '051605000',
    name: 'Jose Panganiban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501605000',
  },
  {
    code: '051606000',
    name: 'Labo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501606000',
  },
  {
    code: '051607000',
    name: 'Mercedes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501607000',
  },
  {
    code: '051608000',
    name: 'Paracale',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501608000',
  },
  {
    code: '051609000',
    name: 'San Vicente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501609000',
  },
  {
    code: '051610000',
    name: 'Santa Elena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501610000',
  },
  {
    code: '051611000',
    name: 'Talisay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501611000',
  },
  {
    code: '051612000',
    name: 'Vinzons',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051600000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501612000',
  },
  {
    code: '051701000',
    name: 'Baao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501701000',
  },
  {
    code: '051702000',
    name: 'Balatan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501702000',
  },
  {
    code: '051703000',
    name: 'Bato',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501703000',
  },
  {
    code: '051704000',
    name: 'Bombon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501704000',
  },
  {
    code: '051705000',
    name: 'Buhi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501705000',
  },
  {
    code: '051706000',
    name: 'Bula',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501706000',
  },
  {
    code: '051707000',
    name: 'Cabusao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501707000',
  },
  {
    code: '051708000',
    name: 'Calabanga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501708000',
  },
  {
    code: '051709000',
    name: 'Camaligan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501709000',
  },
  {
    code: '051710000',
    name: 'Canaman',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501710000',
  },
  {
    code: '051711000',
    name: 'Caramoan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501711000',
  },
  {
    code: '051712000',
    name: 'Del Gallego',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501712000',
  },
  {
    code: '051713000',
    name: 'Gainza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501713000',
  },
  {
    code: '051714000',
    name: 'Garchitorena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501714000',
  },
  {
    code: '051715000',
    name: 'Goa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501715000',
  },
  {
    code: '051716000',
    name: 'City of Iriga',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501716000',
  },
  {
    code: '051717000',
    name: 'Lagonoy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501717000',
  },
  {
    code: '051718000',
    name: 'Libmanan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501718000',
  },
  {
    code: '051719000',
    name: 'Lupi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501719000',
  },
  {
    code: '051720000',
    name: 'Magarao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501720000',
  },
  {
    code: '051721000',
    name: 'Milaor',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501721000',
  },
  {
    code: '051722000',
    name: 'Minalabac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501722000',
  },
  {
    code: '051723000',
    name: 'Nabua',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501723000',
  },
  {
    code: '051724000',
    name: 'City of Naga',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501724000',
  },
  {
    code: '051725000',
    name: 'Ocampo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501725000',
  },
  {
    code: '051726000',
    name: 'Pamplona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501726000',
  },
  {
    code: '051727000',
    name: 'Pasacao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501727000',
  },
  {
    code: '051728000',
    name: 'Pili',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501728000',
  },
  {
    code: '051729000',
    name: 'Presentacion',
    oldName: 'Parubcan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501729000',
  },
  {
    code: '051730000',
    name: 'Ragay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501730000',
  },
  {
    code: '051731000',
    name: 'Sagñay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501731000',
  },
  {
    code: '051732000',
    name: 'San Fernando',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501732000',
  },
  {
    code: '051733000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501733000',
  },
  {
    code: '051734000',
    name: 'Sipocot',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501734000',
  },
  {
    code: '051735000',
    name: 'Siruma',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501735000',
  },
  {
    code: '051736000',
    name: 'Tigaon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501736000',
  },
  {
    code: '051737000',
    name: 'Tinambac',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '051700000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0501737000',
  },
  {
    code: '052001000',
    name: 'Bagamanoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502001000',
  },
  {
    code: '052002000',
    name: 'Baras',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502002000',
  },
  {
    code: '052003000',
    name: 'Bato',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502003000',
  },
  {
    code: '052004000',
    name: 'Caramoran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502004000',
  },
  {
    code: '052005000',
    name: 'Gigmoto',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502005000',
  },
  {
    code: '052006000',
    name: 'Pandan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502006000',
  },
  {
    code: '052007000',
    name: 'Panganiban',
    oldName: 'Payo',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502007000',
  },
  {
    code: '052008000',
    name: 'San Andres',
    oldName: 'Calolbon',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502008000',
  },
  {
    code: '052009000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502009000',
  },
  {
    code: '052010000',
    name: 'Viga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502010000',
  },
  {
    code: '052011000',
    name: 'Virac',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '052000000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0502011000',
  },
  {
    code: '054101000',
    name: 'Aroroy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504101000',
  },
  {
    code: '054102000',
    name: 'Baleno',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504102000',
  },
  {
    code: '054103000',
    name: 'Balud',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504103000',
  },
  {
    code: '054104000',
    name: 'Batuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504104000',
  },
  {
    code: '054105000',
    name: 'Cataingan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504105000',
  },
  {
    code: '054106000',
    name: 'Cawayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504106000',
  },
  {
    code: '054107000',
    name: 'Claveria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504107000',
  },
  {
    code: '054108000',
    name: 'Dimasalang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504108000',
  },
  {
    code: '054109000',
    name: 'Esperanza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504109000',
  },
  {
    code: '054110000',
    name: 'Mandaon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504110000',
  },
  {
    code: '054111000',
    name: 'City of Masbate',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504111000',
  },
  {
    code: '054112000',
    name: 'Milagros',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504112000',
  },
  {
    code: '054113000',
    name: 'Mobo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504113000',
  },
  {
    code: '054114000',
    name: 'Monreal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504114000',
  },
  {
    code: '054115000',
    name: 'Palanas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504115000',
  },
  {
    code: '054116000',
    name: 'Pio v. Corpuz',
    oldName: 'Limbuhan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504116000',
  },
  {
    code: '054117000',
    name: 'Placer',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504117000',
  },
  {
    code: '054118000',
    name: 'San Fernando',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504118000',
  },
  {
    code: '054119000',
    name: 'San Jacinto',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504119000',
  },
  {
    code: '054120000',
    name: 'San Pascual',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504120000',
  },
  {
    code: '054121000',
    name: 'Uson',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '054100000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0504121000',
  },
  {
    code: '056202000',
    name: 'Barcelona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506202000',
  },
  {
    code: '056203000',
    name: 'Bulan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506203000',
  },
  {
    code: '056204000',
    name: 'Bulusan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506204000',
  },
  {
    code: '056205000',
    name: 'Casiguran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506205000',
  },
  {
    code: '056206000',
    name: 'Castilla',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506206000',
  },
  {
    code: '056207000',
    name: 'Donsol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506207000',
  },
  {
    code: '056208000',
    name: 'Gubat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506208000',
  },
  {
    code: '056209000',
    name: 'Irosin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506209000',
  },
  {
    code: '056210000',
    name: 'Juban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506210000',
  },
  {
    code: '056211000',
    name: 'Magallanes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506211000',
  },
  {
    code: '056212000',
    name: 'Matnog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506212000',
  },
  {
    code: '056213000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506213000',
  },
  {
    code: '056214000',
    name: 'Prieto Diaz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506214000',
  },
  {
    code: '056215000',
    name: 'Santa Magdalena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506215000',
  },
  {
    code: '056216000',
    name: 'City of Sorsogon',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '056200000',
    districtCode: false,
    regionCode: '050000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '0506216000',
  },
  {
    code: '133900000',
    name: 'City of Manila',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '133900000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380600000',
  },
  {
    code: '137401000',
    name: 'City of Mandaluyong',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380500000',
  },
  {
    code: '137402000',
    name: 'City of Marikina',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380700000',
  },
  {
    code: '137403000',
    name: 'City of Pasig',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381200000',
  },
  {
    code: '137404000',
    name: 'Quezon City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381300000',
  },
  {
    code: '137405000',
    name: 'City of San Juan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137400000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381400000',
  },
  {
    code: '137501000',
    name: 'City of Caloocan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380100000',
  },
  {
    code: '137502000',
    name: 'City of Malabon',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380400000',
  },
  {
    code: '137503000',
    name: 'City of Navotas',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380900000',
  },
  {
    code: '137504000',
    name: 'City of Valenzuela',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137500000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381600000',
  },
  {
    code: '137601000',
    name: 'City of Las Piñas',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380200000',
  },
  {
    code: '137602000',
    name: 'City of Makati',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380300000',
  },
  {
    code: '137603000',
    name: 'City of Muntinlupa',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1380800000',
  },
  {
    code: '137604000',
    name: 'City of Parañaque',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381000000',
  },
  {
    code: '137605000',
    name: 'Pasay City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381100000',
  },
  {
    code: '137606000',
    name: 'Pateros',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381701000',
  },
  {
    code: '137607000',
    name: 'City of Taguig',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: false,
    districtCode: '137600000',
    regionCode: '130000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1381500000',
  },
  {
    code: '140101000',
    name: 'Bangued',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400101000',
  },
  {
    code: '140102000',
    name: 'Boliney',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400102000',
  },
  {
    code: '140103000',
    name: 'Bucay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400103000',
  },
  {
    code: '140104000',
    name: 'Bucloc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400104000',
  },
  {
    code: '140105000',
    name: 'Daguioman',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400105000',
  },
  {
    code: '140106000',
    name: 'Danglas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400106000',
  },
  {
    code: '140107000',
    name: 'Dolores',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400107000',
  },
  {
    code: '140108000',
    name: 'La Paz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400108000',
  },
  {
    code: '140109000',
    name: 'Lacub',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400109000',
  },
  {
    code: '140110000',
    name: 'Lagangilang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400110000',
  },
  {
    code: '140111000',
    name: 'Lagayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400111000',
  },
  {
    code: '140112000',
    name: 'Langiden',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400112000',
  },
  {
    code: '140113000',
    name: 'Licuan-Baay',
    oldName: 'Licuan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400113000',
  },
  {
    code: '140114000',
    name: 'Luba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400114000',
  },
  {
    code: '140115000',
    name: 'Malibcong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400115000',
  },
  {
    code: '140116000',
    name: 'Manabo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400116000',
  },
  {
    code: '140117000',
    name: 'Peñarrubia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400117000',
  },
  {
    code: '140118000',
    name: 'Pidigan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400118000',
  },
  {
    code: '140119000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400119000',
  },
  {
    code: '140120000',
    name: 'Sallapadan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400120000',
  },
  {
    code: '140121000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400121000',
  },
  {
    code: '140122000',
    name: 'San Juan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400122000',
  },
  {
    code: '140123000',
    name: 'San Quintin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400123000',
  },
  {
    code: '140124000',
    name: 'Tayum',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400124000',
  },
  {
    code: '140125000',
    name: 'Tineg',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400125000',
  },
  {
    code: '140126000',
    name: 'Tubo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400126000',
  },
  {
    code: '140127000',
    name: 'Villaviciosa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '140100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1400127000',
  },
  {
    code: '141101000',
    name: 'Atok',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401101000',
  },
  {
    code: '141102000',
    name: 'City of Baguio',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1430300000',
  },
  {
    code: '141103000',
    name: 'Bakun',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401103000',
  },
  {
    code: '141104000',
    name: 'Bokod',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401104000',
  },
  {
    code: '141105000',
    name: 'Buguias',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401105000',
  },
  {
    code: '141106000',
    name: 'Itogon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401106000',
  },
  {
    code: '141107000',
    name: 'Kabayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401107000',
  },
  {
    code: '141108000',
    name: 'Kapangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401108000',
  },
  {
    code: '141109000',
    name: 'Kibungan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401109000',
  },
  {
    code: '141110000',
    name: 'La Trinidad',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401110000',
  },
  {
    code: '141111000',
    name: 'Mankayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401111000',
  },
  {
    code: '141112000',
    name: 'Sablan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401112000',
  },
  {
    code: '141113000',
    name: 'Tuba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401113000',
  },
  {
    code: '141114000',
    name: 'Tublay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '141100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1401114000',
  },
  {
    code: '142701000',
    name: 'Banaue',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402701000',
  },
  {
    code: '142702000',
    name: 'Hungduan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402702000',
  },
  {
    code: '142703000',
    name: 'Kiangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402703000',
  },
  {
    code: '142704000',
    name: 'Lagawe',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402704000',
  },
  {
    code: '142705000',
    name: 'Lamut',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402705000',
  },
  {
    code: '142706000',
    name: 'Mayoyao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402706000',
  },
  {
    code: '142707000',
    name: 'Alfonso Lista',
    oldName: 'Potia',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402707000',
  },
  {
    code: '142708000',
    name: 'Aguinaldo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402708000',
  },
  {
    code: '142709000',
    name: 'Hingyon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402709000',
  },
  {
    code: '142710000',
    name: 'Tinoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402710000',
  },
  {
    code: '142711000',
    name: 'Asipulo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '142700000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1402711000',
  },
  {
    code: '143201000',
    name: 'Balbalan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403201000',
  },
  {
    code: '143206000',
    name: 'Lubuagan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403206000',
  },
  {
    code: '143208000',
    name: 'Pasil',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403208000',
  },
  {
    code: '143209000',
    name: 'Pinukpuk',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403209000',
  },
  {
    code: '143211000',
    name: 'Rizal',
    oldName: 'Liwan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403211000',
  },
  {
    code: '143213000',
    name: 'City of Tabuk',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403213000',
  },
  {
    code: '143214000',
    name: 'Tanudan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403214000',
  },
  {
    code: '143215000',
    name: 'Tinglayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '143200000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1403215000',
  },
  {
    code: '144401000',
    name: 'Barlig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404401000',
  },
  {
    code: '144402000',
    name: 'Bauko',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404402000',
  },
  {
    code: '144403000',
    name: 'Besao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404403000',
  },
  {
    code: '144404000',
    name: 'Bontoc',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404404000',
  },
  {
    code: '144405000',
    name: 'Natonin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404405000',
  },
  {
    code: '144406000',
    name: 'Paracelis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404406000',
  },
  {
    code: '144407000',
    name: 'Sabangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404407000',
  },
  {
    code: '144408000',
    name: 'Sadanga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404408000',
  },
  {
    code: '144409000',
    name: 'Sagada',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404409000',
  },
  {
    code: '144410000',
    name: 'Tadian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '144400000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1404410000',
  },
  {
    code: '148101000',
    name: 'Calanasan',
    oldName: 'Bayag',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408101000',
  },
  {
    code: '148102000',
    name: 'Conner',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408102000',
  },
  {
    code: '148103000',
    name: 'Flora',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408103000',
  },
  {
    code: '148104000',
    name: 'Kabugao',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408104000',
  },
  {
    code: '148105000',
    name: 'Luna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408105000',
  },
  {
    code: '148106000',
    name: 'Pudtol',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408106000',
  },
  {
    code: '148107000',
    name: 'Santa Marcela',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '148100000',
    districtCode: false,
    regionCode: '140000000',
    islandGroupCode: 'luzon',
    psgc10DigitCode: '1408107000',
  },
];

const visayasMunicipalities: Municipality[] = [
  {
    code: '060401000',
    name: 'Altavas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600401000',
  },
  {
    code: '060402000',
    name: 'Balete',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600402000',
  },
  {
    code: '060403000',
    name: 'Banga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600403000',
  },
  {
    code: '060404000',
    name: 'Batan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600404000',
  },
  {
    code: '060405000',
    name: 'Buruanga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600405000',
  },
  {
    code: '060406000',
    name: 'Ibajay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600406000',
  },
  {
    code: '060407000',
    name: 'Kalibo',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600407000',
  },
  {
    code: '060408000',
    name: 'Lezo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600408000',
  },
  {
    code: '060409000',
    name: 'Libacao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600409000',
  },
  {
    code: '060410000',
    name: 'Madalag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600410000',
  },
  {
    code: '060411000',
    name: 'Makato',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600411000',
  },
  {
    code: '060412000',
    name: 'Malay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600412000',
  },
  {
    code: '060413000',
    name: 'Malinao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600413000',
  },
  {
    code: '060414000',
    name: 'Nabas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600414000',
  },
  {
    code: '060415000',
    name: 'New Washington',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600415000',
  },
  {
    code: '060416000',
    name: 'Numancia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600416000',
  },
  {
    code: '060417000',
    name: 'Tangalan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060400000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600417000',
  },
  {
    code: '060601000',
    name: 'Anini-Y',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600601000',
  },
  {
    code: '060602000',
    name: 'Barbaza',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600602000',
  },
  {
    code: '060603000',
    name: 'Belison',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600603000',
  },
  {
    code: '060604000',
    name: 'Bugasong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600604000',
  },
  {
    code: '060605000',
    name: 'Caluya',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600605000',
  },
  {
    code: '060606000',
    name: 'Culasi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600606000',
  },
  {
    code: '060607000',
    name: 'Tobias Fornier',
    oldName: 'Dao',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600607000',
  },
  {
    code: '060608000',
    name: 'Hamtic',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600608000',
  },
  {
    code: '060609000',
    name: 'Laua-An',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600609000',
  },
  {
    code: '060610000',
    name: 'Libertad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600610000',
  },
  {
    code: '060611000',
    name: 'Pandan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600611000',
  },
  {
    code: '060612000',
    name: 'Patnongon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600612000',
  },
  {
    code: '060613000',
    name: 'San Jose',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600613000',
  },
  {
    code: '060614000',
    name: 'San Remigio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600614000',
  },
  {
    code: '060615000',
    name: 'Sebaste',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600615000',
  },
  {
    code: '060616000',
    name: 'Sibalom',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600616000',
  },
  {
    code: '060617000',
    name: 'Tibiao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600617000',
  },
  {
    code: '060618000',
    name: 'Valderrama',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '060600000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0600618000',
  },
  {
    code: '061901000',
    name: 'Cuartero',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601901000',
  },
  {
    code: '061902000',
    name: 'Dao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601902000',
  },
  {
    code: '061903000',
    name: 'Dumalag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601903000',
  },
  {
    code: '061904000',
    name: 'Dumarao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601904000',
  },
  {
    code: '061905000',
    name: 'Ivisan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601905000',
  },
  {
    code: '061906000',
    name: 'Jamindan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601906000',
  },
  {
    code: '061907000',
    name: 'Ma-Ayon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601907000',
  },
  {
    code: '061908000',
    name: 'Mambusao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601908000',
  },
  {
    code: '061909000',
    name: 'Panay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601909000',
  },
  {
    code: '061910000',
    name: 'Panitan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601910000',
  },
  {
    code: '061911000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601911000',
  },
  {
    code: '061912000',
    name: 'Pontevedra',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601912000',
  },
  {
    code: '061913000',
    name: 'President Roxas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601913000',
  },
  {
    code: '061914000',
    name: 'City of Roxas',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601914000',
  },
  {
    code: '061915000',
    name: 'Sapi-An',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601915000',
  },
  {
    code: '061916000',
    name: 'Sigma',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601916000',
  },
  {
    code: '061917000',
    name: 'Tapaz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '061900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0601917000',
  },
  {
    code: '063001000',
    name: 'Ajuy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603001000',
  },
  {
    code: '063002000',
    name: 'Alimodian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603002000',
  },
  {
    code: '063003000',
    name: 'Anilao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603003000',
  },
  {
    code: '063004000',
    name: 'Badiangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603004000',
  },
  {
    code: '063005000',
    name: 'Balasan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603005000',
  },
  {
    code: '063006000',
    name: 'Banate',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603006000',
  },
  {
    code: '063007000',
    name: 'Barotac Nuevo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603007000',
  },
  {
    code: '063008000',
    name: 'Barotac Viejo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603008000',
  },
  {
    code: '063009000',
    name: 'Batad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603009000',
  },
  {
    code: '063010000',
    name: 'Bingawan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603010000',
  },
  {
    code: '063012000',
    name: 'Cabatuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603012000',
  },
  {
    code: '063013000',
    name: 'Calinog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603013000',
  },
  {
    code: '063014000',
    name: 'Carles',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603014000',
  },
  {
    code: '063015000',
    name: 'Concepcion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603015000',
  },
  {
    code: '063016000',
    name: 'Dingle',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603016000',
  },
  {
    code: '063017000',
    name: 'Dueñas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603017000',
  },
  {
    code: '063018000',
    name: 'Dumangas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603018000',
  },
  {
    code: '063019000',
    name: 'Estancia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603019000',
  },
  {
    code: '063020000',
    name: 'Guimbal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603020000',
  },
  {
    code: '063021000',
    name: 'Igbaras',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603021000',
  },
  {
    code: '063022000',
    name: 'City of Iloilo',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0631000000',
  },
  {
    code: '063023000',
    name: 'Janiuay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603023000',
  },
  {
    code: '063025000',
    name: 'Lambunao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603025000',
  },
  {
    code: '063026000',
    name: 'Leganes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603026000',
  },
  {
    code: '063027000',
    name: 'Lemery',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603027000',
  },
  {
    code: '063028000',
    name: 'Leon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603028000',
  },
  {
    code: '063029000',
    name: 'Maasin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603029000',
  },
  {
    code: '063030000',
    name: 'Miagao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603030000',
  },
  {
    code: '063031000',
    name: 'Mina',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603031000',
  },
  {
    code: '063032000',
    name: 'New Lucena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603032000',
  },
  {
    code: '063034000',
    name: 'Oton',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603034000',
  },
  {
    code: '063035000',
    name: 'City of Passi',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603035000',
  },
  {
    code: '063036000',
    name: 'Pavia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603036000',
  },
  {
    code: '063037000',
    name: 'Pototan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603037000',
  },
  {
    code: '063038000',
    name: 'San Dionisio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603038000',
  },
  {
    code: '063039000',
    name: 'San Enrique',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603039000',
  },
  {
    code: '063040000',
    name: 'San Joaquin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603040000',
  },
  {
    code: '063041000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603041000',
  },
  {
    code: '063042000',
    name: 'San Rafael',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603042000',
  },
  {
    code: '063043000',
    name: 'Santa Barbara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603043000',
  },
  {
    code: '063044000',
    name: 'Sara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603044000',
  },
  {
    code: '063045000',
    name: 'Tigbauan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603045000',
  },
  {
    code: '063046000',
    name: 'Tubungan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603046000',
  },
  {
    code: '063047000',
    name: 'Zarraga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '063000000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0603047000',
  },
  {
    code: '064501000',
    name: 'City of Bacolod',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0630200000',
  },
  {
    code: '064502000',
    name: 'City of Bago',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604502000',
  },
  {
    code: '064503000',
    name: 'Binalbagan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604503000',
  },
  {
    code: '064504000',
    name: 'City of Cadiz',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604504000',
  },
  {
    code: '064505000',
    name: 'Calatrava',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604505000',
  },
  {
    code: '064506000',
    name: 'Candoni',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604506000',
  },
  {
    code: '064507000',
    name: 'Cauayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604507000',
  },
  {
    code: '064508000',
    name: 'Enrique B. Magalona',
    oldName: 'Saravia',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604508000',
  },
  {
    code: '064509000',
    name: 'City of Escalante',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604509000',
  },
  {
    code: '064510000',
    name: 'City of Himamaylan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604510000',
  },
  {
    code: '064511000',
    name: 'Hinigaran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604511000',
  },
  {
    code: '064512000',
    name: 'Hinoba-An',
    oldName: 'Asia',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604512000',
  },
  {
    code: '064513000',
    name: 'Ilog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604513000',
  },
  {
    code: '064514000',
    name: 'Isabela',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604514000',
  },
  {
    code: '064515000',
    name: 'City of Kabankalan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604515000',
  },
  {
    code: '064516000',
    name: 'City of La Carlota',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604516000',
  },
  {
    code: '064517000',
    name: 'La Castellana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604517000',
  },
  {
    code: '064518000',
    name: 'Manapla',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604518000',
  },
  {
    code: '064519000',
    name: 'Moises Padilla',
    oldName: 'Magallon',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604519000',
  },
  {
    code: '064520000',
    name: 'Murcia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604520000',
  },
  {
    code: '064521000',
    name: 'Pontevedra',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604521000',
  },
  {
    code: '064522000',
    name: 'Pulupandan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604522000',
  },
  {
    code: '064523000',
    name: 'City of Sagay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604523000',
  },
  {
    code: '064524000',
    name: 'City of San Carlos',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604524000',
  },
  {
    code: '064525000',
    name: 'San Enrique',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604525000',
  },
  {
    code: '064526000',
    name: 'City of Silay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604526000',
  },
  {
    code: '064527000',
    name: 'City of Sipalay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604527000',
  },
  {
    code: '064528000',
    name: 'City of Talisay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604528000',
  },
  {
    code: '064529000',
    name: 'Toboso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604529000',
  },
  {
    code: '064530000',
    name: 'Valladolid',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604530000',
  },
  {
    code: '064531000',
    name: 'City of Victorias',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604531000',
  },
  {
    code: '064532000',
    name: 'Salvador Benedicto',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '064500000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0604532000',
  },
  {
    code: '067901000',
    name: 'Buenavista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '067900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607901000',
  },
  {
    code: '067902000',
    name: 'Jordan',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '067900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607902000',
  },
  {
    code: '067903000',
    name: 'Nueva Valencia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '067900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607903000',
  },
  {
    code: '067904000',
    name: 'San Lorenzo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '067900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607904000',
  },
  {
    code: '067905000',
    name: 'Sibunag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '067900000',
    districtCode: false,
    regionCode: '060000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0607905000',
  },
  {
    code: '071201000',
    name: 'Alburquerque',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701201000',
  },
  {
    code: '071202000',
    name: 'Alicia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701202000',
  },
  {
    code: '071203000',
    name: 'Anda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701203000',
  },
  {
    code: '071204000',
    name: 'Antequera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701204000',
  },
  {
    code: '071205000',
    name: 'Baclayon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701205000',
  },
  {
    code: '071206000',
    name: 'Balilihan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701206000',
  },
  {
    code: '071207000',
    name: 'Batuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701207000',
  },
  {
    code: '071208000',
    name: 'Bilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701208000',
  },
  {
    code: '071209000',
    name: 'Buenavista',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701209000',
  },
  {
    code: '071210000',
    name: 'Calape',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701210000',
  },
  {
    code: '071211000',
    name: 'Candijay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701211000',
  },
  {
    code: '071212000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701212000',
  },
  {
    code: '071213000',
    name: 'Catigbian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701213000',
  },
  {
    code: '071214000',
    name: 'Clarin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701214000',
  },
  {
    code: '071215000',
    name: 'Corella',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701215000',
  },
  {
    code: '071216000',
    name: 'Cortes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701216000',
  },
  {
    code: '071217000',
    name: 'Dagohoy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701217000',
  },
  {
    code: '071218000',
    name: 'Danao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701218000',
  },
  {
    code: '071219000',
    name: 'Dauis',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701219000',
  },
  {
    code: '071220000',
    name: 'Dimiao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701220000',
  },
  {
    code: '071221000',
    name: 'Duero',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701221000',
  },
  {
    code: '071222000',
    name: 'Garcia Hernandez',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701222000',
  },
  {
    code: '071223000',
    name: 'Guindulman',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701223000',
  },
  {
    code: '071224000',
    name: 'Inabanga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701224000',
  },
  {
    code: '071225000',
    name: 'Jagna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701225000',
  },
  {
    code: '071226000',
    name: 'Getafe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701226000',
  },
  {
    code: '071227000',
    name: 'Lila',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701227000',
  },
  {
    code: '071228000',
    name: 'Loay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701228000',
  },
  {
    code: '071229000',
    name: 'Loboc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701229000',
  },
  {
    code: '071230000',
    name: 'Loon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701230000',
  },
  {
    code: '071231000',
    name: 'Mabini',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701231000',
  },
  {
    code: '071232000',
    name: 'Maribojoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701232000',
  },
  {
    code: '071233000',
    name: 'Panglao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701233000',
  },
  {
    code: '071234000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701234000',
  },
  {
    code: '071235000',
    name: 'President Carlos P. Garcia',
    oldName: 'Pitogo',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701235000',
  },
  {
    code: '071236000',
    name: 'Sagbayan',
    oldName: 'Borja',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701236000',
  },
  {
    code: '071237000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701237000',
  },
  {
    code: '071238000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701238000',
  },
  {
    code: '071239000',
    name: 'Sevilla',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701239000',
  },
  {
    code: '071240000',
    name: 'Sierra Bullones',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701240000',
  },
  {
    code: '071241000',
    name: 'Sikatuna',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701241000',
  },
  {
    code: '071242000',
    name: 'City of Tagbilaran',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701242000',
  },
  {
    code: '071243000',
    name: 'Talibon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701243000',
  },
  {
    code: '071244000',
    name: 'Trinidad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701244000',
  },
  {
    code: '071245000',
    name: 'Tubigon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701245000',
  },
  {
    code: '071246000',
    name: 'Ubay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701246000',
  },
  {
    code: '071247000',
    name: 'Valencia',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701247000',
  },
  {
    code: '071248000',
    name: 'Bien Unido',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '071200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0701248000',
  },
  {
    code: '072201000',
    name: 'Alcantara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702201000',
  },
  {
    code: '072202000',
    name: 'Alcoy',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702202000',
  },
  {
    code: '072203000',
    name: 'Alegria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702203000',
  },
  {
    code: '072204000',
    name: 'Aloguinsan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702204000',
  },
  {
    code: '072205000',
    name: 'Argao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702205000',
  },
  {
    code: '072206000',
    name: 'Asturias',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702206000',
  },
  {
    code: '072207000',
    name: 'Badian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702207000',
  },
  {
    code: '072208000',
    name: 'Balamban',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702208000',
  },
  {
    code: '072209000',
    name: 'Bantayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702209000',
  },
  {
    code: '072210000',
    name: 'Barili',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702210000',
  },
  {
    code: '072211000',
    name: 'City of Bogo',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702211000',
  },
  {
    code: '072212000',
    name: 'Boljoon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702212000',
  },
  {
    code: '072213000',
    name: 'Borbon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702213000',
  },
  {
    code: '072214000',
    name: 'City of Carcar',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702214000',
  },
  {
    code: '072215000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702215000',
  },
  {
    code: '072216000',
    name: 'Catmon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702216000',
  },
  {
    code: '072217000',
    name: 'City of Cebu',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0730600000',
  },
  {
    code: '072218000',
    name: 'Compostela',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702218000',
  },
  {
    code: '072219000',
    name: 'Consolacion',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702219000',
  },
  {
    code: '072220000',
    name: 'Cordova',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702220000',
  },
  {
    code: '072221000',
    name: 'Daanbantayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702221000',
  },
  {
    code: '072222000',
    name: 'Dalaguete',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702222000',
  },
  {
    code: '072223000',
    name: 'Danao City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702223000',
  },
  {
    code: '072224000',
    name: 'Dumanjug',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702224000',
  },
  {
    code: '072225000',
    name: 'Ginatilan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702225000',
  },
  {
    code: '072226000',
    name: 'City of Lapu-Lapu',
    oldName: 'Opon',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0731100000',
  },
  {
    code: '072227000',
    name: 'Liloan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702227000',
  },
  {
    code: '072228000',
    name: 'Madridejos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702228000',
  },
  {
    code: '072229000',
    name: 'Malabuyoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702229000',
  },
  {
    code: '072230000',
    name: 'City of Mandaue',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0731300000',
  },
  {
    code: '072231000',
    name: 'Medellin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702231000',
  },
  {
    code: '072232000',
    name: 'Minglanilla',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702232000',
  },
  {
    code: '072233000',
    name: 'Moalboal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702233000',
  },
  {
    code: '072234000',
    name: 'City of Naga',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702234000',
  },
  {
    code: '072235000',
    name: 'Oslob',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702235000',
  },
  {
    code: '072236000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702236000',
  },
  {
    code: '072237000',
    name: 'Pinamungajan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702237000',
  },
  {
    code: '072238000',
    name: 'Poro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702238000',
  },
  {
    code: '072239000',
    name: 'Ronda',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702239000',
  },
  {
    code: '072240000',
    name: 'Samboan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702240000',
  },
  {
    code: '072241000',
    name: 'San Fernando',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702241000',
  },
  {
    code: '072242000',
    name: 'San Francisco',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702242000',
  },
  {
    code: '072243000',
    name: 'San Remigio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702243000',
  },
  {
    code: '072244000',
    name: 'Santa Fe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702244000',
  },
  {
    code: '072245000',
    name: 'Santander',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702245000',
  },
  {
    code: '072246000',
    name: 'Sibonga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702246000',
  },
  {
    code: '072247000',
    name: 'Sogod',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702247000',
  },
  {
    code: '072248000',
    name: 'Tabogon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702248000',
  },
  {
    code: '072249000',
    name: 'Tabuelan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702249000',
  },
  {
    code: '072250000',
    name: 'City of Talisay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702250000',
  },
  {
    code: '072251000',
    name: 'City of Toledo',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702251000',
  },
  {
    code: '072252000',
    name: 'Tuburan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702252000',
  },
  {
    code: '072253000',
    name: 'Tudela',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '072200000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0702253000',
  },
  {
    code: '074601000',
    name: 'Amlan',
    oldName: 'Ayuquitan',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704601000',
  },
  {
    code: '074602000',
    name: 'Ayungon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704602000',
  },
  {
    code: '074603000',
    name: 'Bacong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704603000',
  },
  {
    code: '074604000',
    name: 'City of Bais',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704604000',
  },
  {
    code: '074605000',
    name: 'Basay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704605000',
  },
  {
    code: '074606000',
    name: 'City of Bayawan',
    oldName: 'Tulong',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704606000',
  },
  {
    code: '074607000',
    name: 'Bindoy',
    oldName: 'Payabon',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704607000',
  },
  {
    code: '074608000',
    name: 'City of Canlaon',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704608000',
  },
  {
    code: '074609000',
    name: 'Dauin',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704609000',
  },
  {
    code: '074610000',
    name: 'City of Dumaguete',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704610000',
  },
  {
    code: '074611000',
    name: 'City of Guihulngan',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704611000',
  },
  {
    code: '074612000',
    name: 'Jimalalud',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704612000',
  },
  {
    code: '074613000',
    name: 'La Libertad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704613000',
  },
  {
    code: '074614000',
    name: 'Mabinay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704614000',
  },
  {
    code: '074615000',
    name: 'Manjuyod',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704615000',
  },
  {
    code: '074616000',
    name: 'Pamplona',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704616000',
  },
  {
    code: '074617000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704617000',
  },
  {
    code: '074618000',
    name: 'Santa Catalina',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704618000',
  },
  {
    code: '074619000',
    name: 'Siaton',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704619000',
  },
  {
    code: '074620000',
    name: 'Sibulan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704620000',
  },
  {
    code: '074621000',
    name: 'City of Tanjay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704621000',
  },
  {
    code: '074622000',
    name: 'Tayasan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704622000',
  },
  {
    code: '074623000',
    name: 'Valencia',
    oldName: 'Luzurriaga',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704623000',
  },
  {
    code: '074624000',
    name: 'Vallehermoso',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704624000',
  },
  {
    code: '074625000',
    name: 'Zamboanguita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '074600000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0704625000',
  },
  {
    code: '076101000',
    name: 'Enrique Villanueva',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706101000',
  },
  {
    code: '076102000',
    name: 'Larena',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706102000',
  },
  {
    code: '076103000',
    name: 'Lazi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706103000',
  },
  {
    code: '076104000',
    name: 'Maria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706104000',
  },
  {
    code: '076105000',
    name: 'San Juan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706105000',
  },
  {
    code: '076106000',
    name: 'Siquijor',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '076100000',
    districtCode: false,
    regionCode: '070000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0706106000',
  },
  {
    code: '082601000',
    name: 'Arteche',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802601000',
  },
  {
    code: '082602000',
    name: 'Balangiga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802602000',
  },
  {
    code: '082603000',
    name: 'Balangkayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802603000',
  },
  {
    code: '082604000',
    name: 'City of Borongan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802604000',
  },
  {
    code: '082605000',
    name: 'Can-Avid',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802605000',
  },
  {
    code: '082606000',
    name: 'Dolores',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802606000',
  },
  {
    code: '082607000',
    name: 'General Macarthur',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802607000',
  },
  {
    code: '082608000',
    name: 'Giporlos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802608000',
  },
  {
    code: '082609000',
    name: 'Guiuan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802609000',
  },
  {
    code: '082610000',
    name: 'Hernani',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802610000',
  },
  {
    code: '082611000',
    name: 'Jipapad',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802611000',
  },
  {
    code: '082612000',
    name: 'Lawaan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802612000',
  },
  {
    code: '082613000',
    name: 'Llorente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802613000',
  },
  {
    code: '082614000',
    name: 'Maslog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802614000',
  },
  {
    code: '082615000',
    name: 'Maydolong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802615000',
  },
  {
    code: '082616000',
    name: 'Mercedes',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802616000',
  },
  {
    code: '082617000',
    name: 'Oras',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802617000',
  },
  {
    code: '082618000',
    name: 'Quinapondan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802618000',
  },
  {
    code: '082619000',
    name: 'Salcedo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802619000',
  },
  {
    code: '082620000',
    name: 'San Julian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802620000',
  },
  {
    code: '082621000',
    name: 'San Policarpo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802621000',
  },
  {
    code: '082622000',
    name: 'Sulat',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802622000',
  },
  {
    code: '082623000',
    name: 'Taft',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '082600000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0802623000',
  },
  {
    code: '083701000',
    name: 'Abuyog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803701000',
  },
  {
    code: '083702000',
    name: 'Alangalang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803702000',
  },
  {
    code: '083703000',
    name: 'Albuera',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803703000',
  },
  {
    code: '083705000',
    name: 'Babatngon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803705000',
  },
  {
    code: '083706000',
    name: 'Barugo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803706000',
  },
  {
    code: '083707000',
    name: 'Bato',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803707000',
  },
  {
    code: '083708000',
    name: 'City of Baybay',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803708000',
  },
  {
    code: '083710000',
    name: 'Burauen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803710000',
  },
  {
    code: '083713000',
    name: 'Calubian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803713000',
  },
  {
    code: '083714000',
    name: 'Capoocan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803714000',
  },
  {
    code: '083715000',
    name: 'Carigara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803715000',
  },
  {
    code: '083717000',
    name: 'Dagami',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803717000',
  },
  {
    code: '083718000',
    name: 'Dulag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803718000',
  },
  {
    code: '083719000',
    name: 'Hilongos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803719000',
  },
  {
    code: '083720000',
    name: 'Hindang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803720000',
  },
  {
    code: '083721000',
    name: 'Inopacan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803721000',
  },
  {
    code: '083722000',
    name: 'Isabel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803722000',
  },
  {
    code: '083723000',
    name: 'Jaro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803723000',
  },
  {
    code: '083724000',
    name: 'Javier',
    oldName: 'Bugho',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803724000',
  },
  {
    code: '083725000',
    name: 'Julita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803725000',
  },
  {
    code: '083726000',
    name: 'Kananga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803726000',
  },
  {
    code: '083728000',
    name: 'La Paz',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803728000',
  },
  {
    code: '083729000',
    name: 'Leyte',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803729000',
  },
  {
    code: '083730000',
    name: 'Macarthur',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803730000',
  },
  {
    code: '083731000',
    name: 'Mahaplag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803731000',
  },
  {
    code: '083733000',
    name: 'Matag-Ob',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803733000',
  },
  {
    code: '083734000',
    name: 'Matalom',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803734000',
  },
  {
    code: '083735000',
    name: 'Mayorga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803735000',
  },
  {
    code: '083736000',
    name: 'Merida',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803736000',
  },
  {
    code: '083738000',
    name: 'Ormoc City',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803738000',
  },
  {
    code: '083739000',
    name: 'Palo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803739000',
  },
  {
    code: '083740000',
    name: 'Palompon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803740000',
  },
  {
    code: '083741000',
    name: 'Pastrana',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803741000',
  },
  {
    code: '083742000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803742000',
  },
  {
    code: '083743000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803743000',
  },
  {
    code: '083744000',
    name: 'Santa Fe',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803744000',
  },
  {
    code: '083745000',
    name: 'Tabango',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803745000',
  },
  {
    code: '083746000',
    name: 'Tabontabon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803746000',
  },
  {
    code: '083747000',
    name: 'City of Tacloban',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0831600000',
  },
  {
    code: '083748000',
    name: 'Tanauan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803748000',
  },
  {
    code: '083749000',
    name: 'Tolosa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803749000',
  },
  {
    code: '083750000',
    name: 'Tunga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803750000',
  },
  {
    code: '083751000',
    name: 'Villaba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '083700000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0803751000',
  },
  {
    code: '084801000',
    name: 'Allen',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804801000',
  },
  {
    code: '084802000',
    name: 'Biri',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804802000',
  },
  {
    code: '084803000',
    name: 'Bobon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804803000',
  },
  {
    code: '084804000',
    name: 'Capul',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804804000',
  },
  {
    code: '084805000',
    name: 'Catarman',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804805000',
  },
  {
    code: '084806000',
    name: 'Catubig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804806000',
  },
  {
    code: '084807000',
    name: 'Gamay',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804807000',
  },
  {
    code: '084808000',
    name: 'Laoang',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804808000',
  },
  {
    code: '084809000',
    name: 'Lapinig',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804809000',
  },
  {
    code: '084810000',
    name: 'Las Navas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804810000',
  },
  {
    code: '084811000',
    name: 'Lavezares',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804811000',
  },
  {
    code: '084812000',
    name: 'Mapanas',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804812000',
  },
  {
    code: '084813000',
    name: 'Mondragon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804813000',
  },
  {
    code: '084814000',
    name: 'Palapag',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804814000',
  },
  {
    code: '084815000',
    name: 'Pambujan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804815000',
  },
  {
    code: '084816000',
    name: 'Rosario',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804816000',
  },
  {
    code: '084817000',
    name: 'San Antonio',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804817000',
  },
  {
    code: '084818000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804818000',
  },
  {
    code: '084819000',
    name: 'San Jose',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804819000',
  },
  {
    code: '084820000',
    name: 'San Roque',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804820000',
  },
  {
    code: '084821000',
    name: 'San Vicente',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804821000',
  },
  {
    code: '084822000',
    name: 'Silvino Lobos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804822000',
  },
  {
    code: '084823000',
    name: 'Victoria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804823000',
  },
  {
    code: '084824000',
    name: 'Lope De Vega',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '084800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0804824000',
  },
  {
    code: '086001000',
    name: 'Almagro',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806001000',
  },
  {
    code: '086002000',
    name: 'Basey',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806002000',
  },
  {
    code: '086003000',
    name: 'City of Calbayog',
    oldName: '',
    isCapital: false,
    isCity: true,
    isMunicipality: false,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806003000',
  },
  {
    code: '086004000',
    name: 'Calbiga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806004000',
  },
  {
    code: '086005000',
    name: 'City of Catbalogan',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806005000',
  },
  {
    code: '086006000',
    name: 'Daram',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806006000',
  },
  {
    code: '086007000',
    name: 'Gandara',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806007000',
  },
  {
    code: '086008000',
    name: 'Hinabangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806008000',
  },
  {
    code: '086009000',
    name: 'Jiabong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806009000',
  },
  {
    code: '086010000',
    name: 'Marabut',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806010000',
  },
  {
    code: '086011000',
    name: 'Matuguinao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806011000',
  },
  {
    code: '086012000',
    name: 'Motiong',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806012000',
  },
  {
    code: '086013000',
    name: 'Pinabacdao',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806013000',
  },
  {
    code: '086014000',
    name: 'San Jose De Buan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806014000',
  },
  {
    code: '086015000',
    name: 'San Sebastian',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806015000',
  },
  {
    code: '086016000',
    name: 'Santa Margarita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806016000',
  },
  {
    code: '086017000',
    name: 'Santa Rita',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806017000',
  },
  {
    code: '086018000',
    name: 'Santo Niño',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806018000',
  },
  {
    code: '086019000',
    name: 'Talalora',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806019000',
  },
  {
    code: '086020000',
    name: 'Tarangnan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806020000',
  },
  {
    code: '086021000',
    name: 'Villareal',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806021000',
  },
  {
    code: '086022000',
    name: 'Paranas',
    oldName: 'Wright',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806022000',
  },
  {
    code: '086023000',
    name: 'Zumarraga',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806023000',
  },
  {
    code: '086024000',
    name: 'Tagapul-An',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806024000',
  },
  {
    code: '086025000',
    name: 'San Jorge',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806025000',
  },
  {
    code: '086026000',
    name: 'Pagsanghan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086000000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806026000',
  },
  {
    code: '086401000',
    name: 'Anahawan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806401000',
  },
  {
    code: '086402000',
    name: 'Bontoc',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806402000',
  },
  {
    code: '086403000',
    name: 'Hinunangan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806403000',
  },
  {
    code: '086404000',
    name: 'Hinundayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806404000',
  },
  {
    code: '086405000',
    name: 'Libagon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806405000',
  },
  {
    code: '086406000',
    name: 'Liloan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806406000',
  },
  {
    code: '086407000',
    name: 'City of Maasin',
    oldName: '',
    isCapital: true,
    isCity: true,
    isMunicipality: false,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806407000',
  },
  {
    code: '086408000',
    name: 'Macrohon',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806408000',
  },
  {
    code: '086409000',
    name: 'Malitbog',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806409000',
  },
  {
    code: '086410000',
    name: 'Padre Burgos',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806410000',
  },
  {
    code: '086411000',
    name: 'Pintuyan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806411000',
  },
  {
    code: '086412000',
    name: 'Saint Bernard',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806412000',
  },
  {
    code: '086413000',
    name: 'San Francisco',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806413000',
  },
  {
    code: '086414000',
    name: 'San Juan',
    oldName: 'Cabalian',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806414000',
  },
  {
    code: '086415000',
    name: 'San Ricardo',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806415000',
  },
  {
    code: '086416000',
    name: 'Silago',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806416000',
  },
  {
    code: '086417000',
    name: 'Sogod',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806417000',
  },
  {
    code: '086418000',
    name: 'Tomas Oppus',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806418000',
  },
  {
    code: '086419000',
    name: 'Limasawa',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '086400000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0806419000',
  },
  {
    code: '087801000',
    name: 'Almeria',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807801000',
  },
  {
    code: '087802000',
    name: 'Biliran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807802000',
  },
  {
    code: '087803000',
    name: 'Cabucgayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807803000',
  },
  {
    code: '087804000',
    name: 'Caibiran',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807804000',
  },
  {
    code: '087805000',
    name: 'Culaba',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807805000',
  },
  {
    code: '087806000',
    name: 'Kawayan',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807806000',
  },
  {
    code: '087807000',
    name: 'Maripipi',
    oldName: '',
    isCapital: false,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807807000',
  },
  {
    code: '087808000',
    name: 'Naval',
    oldName: '',
    isCapital: true,
    isCity: false,
    isMunicipality: true,
    provinceCode: '087800000',
    districtCode: false,
    regionCode: '080000000',
    islandGroupCode: 'visayas',
    psgc10DigitCode: '0807808000',
  },
];

const mindanaoMunicipalities: Municipality[] = [
  {
    code: '097203000',
    name: 'Katipunan',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907203000',
  },
  {
    code: '097204000',
    name: 'La Libertad',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907204000',
  },
  {
    code: '097205000',
    name: 'Labason',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907205000',
  },
  {
    code: '097206000',
    name: 'Liloy',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907206000',
  },
  {
    code: '097207000',
    name: 'Manukan',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907207000',
  },
  {
    code: '097208000',
    name: 'Mutia',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907208000',
  },
  {
    code: '097209000',
    name: 'Piñan',
    oldName: 'New Piñan',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907209000',
  },
  {
    code: '097210000',
    name: 'Polanco',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907210000',
  },
  {
    code: '097211000',
    name: 'Pres. Manuel a. Roxas',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907211000',
  },
  {
    code: '097212000',
    name: 'Rizal',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907212000',
  },
  {
    code: '097213000',
    name: 'Salug',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907213000',
  },
  {
    code: '097214000',
    name: 'Sergio osmeña Sr.',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907214000',
  },
  {
    code: '097215000',
    name: 'Siayan',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907215000',
  },
  {
    code: '097216000',
    name: 'Sibuco',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907216000',
  },
  {
    code: '097217000',
    name: 'Sibutad',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907217000',
  },
  {
    code: '097218000',
    name: 'Sindangan',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907218000',
  },
  {
    code: '097219000',
    name: 'Siocon',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907219000',
  },
  {
    code: '097220000',
    name: 'Sirawai',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907220000',
  },
  {
    code: '097221000',
    name: 'Tampilisan',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907221000',
  },
  {
    code: '097222000',
    name: 'Jose Dalman',
    oldName: 'Ponot',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907222000',
  },
  {
    code: '097223000',
    name: 'Gutalac',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907223000',
  },
  {
    code: '097224000',
    name: 'Baliguian',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907224000',
  },
  {
    code: '097225000',
    name: 'Godod',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907225000',
  },
  {
    code: '097226000',
    name: 'Bacungan',
    oldName: 'Leon T. Postigo',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907226000',
  },
  {
    code: '097227000',
    name: 'Kalawit',
    oldName: '',
    isCapital: false,
    provinceCode: '097200000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907227000',
  },
  {
    code: '097302000',
    name: 'Aurora',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907302000',
  },
  {
    code: '097303000',
    name: 'Bayog',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907303000',
  },
  {
    code: '097305000',
    name: 'Dimataling',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907305000',
  },
  {
    code: '097306000',
    name: 'Dinas',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907306000',
  },
  {
    code: '097307000',
    name: 'Dumalinao',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907307000',
  },
  {
    code: '097308000',
    name: 'Dumingag',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907308000',
  },
  {
    code: '097311000',
    name: 'Kumalarang',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907311000',
  },
  {
    code: '097312000',
    name: 'Labangan',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907312000',
  },
  {
    code: '097313000',
    name: 'Lapuyan',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907313000',
  },
  {
    code: '097315000',
    name: 'Mahayag',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907315000',
  },
  {
    code: '097317000',
    name: 'Margosatubig',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907317000',
  },
  {
    code: '097318000',
    name: 'Midsalip',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907318000',
  },
  {
    code: '097319000',
    name: 'Molave',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907319000',
  },
  {
    code: '097323000',
    name: 'Ramon Magsaysay',
    oldName: 'Liargo',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907323000',
  },
  {
    code: '097324000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907324000',
  },
  {
    code: '097325000',
    name: 'San Pablo',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907325000',
  },
  {
    code: '097327000',
    name: 'Tabina',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907327000',
  },
  {
    code: '097328000',
    name: 'Tambulig',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907328000',
  },
  {
    code: '097330000',
    name: 'Tukuran',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907330000',
  },
  {
    code: '097333000',
    name: 'Lakewood',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907333000',
  },
  {
    code: '097337000',
    name: 'Josefina',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907337000',
  },
  {
    code: '097338000',
    name: 'Pitogo',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907338000',
  },
  {
    code: '097340000',
    name: 'Sominot',
    oldName: 'Don Mariano Marcos',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907340000',
  },
  {
    code: '097341000',
    name: 'Vincenzo a. Sagun',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907341000',
  },
  {
    code: '097343000',
    name: 'Guipos',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907343000',
  },
  {
    code: '097344000',
    name: 'Tigbao',
    oldName: '',
    isCapital: false,
    provinceCode: '097300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0907344000',
  },
  {
    code: '098301000',
    name: 'Alicia',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908301000',
  },
  {
    code: '098302000',
    name: 'Buug',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908302000',
  },
  {
    code: '098303000',
    name: 'Diplahan',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908303000',
  },
  {
    code: '098304000',
    name: 'Imelda',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908304000',
  },
  {
    code: '098305000',
    name: 'Ipil',
    oldName: '',
    isCapital: true,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908305000',
  },
  {
    code: '098306000',
    name: 'Kabasalan',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908306000',
  },
  {
    code: '098307000',
    name: 'Mabuhay',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908307000',
  },
  {
    code: '098308000',
    name: 'Malangas',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908308000',
  },
  {
    code: '098309000',
    name: 'Naga',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908309000',
  },
  {
    code: '098310000',
    name: 'Olutanga',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908310000',
  },
  {
    code: '098311000',
    name: 'Payao',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908311000',
  },
  {
    code: '098312000',
    name: 'Roseller Lim',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908312000',
  },
  {
    code: '098313000',
    name: 'Siay',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908313000',
  },
  {
    code: '098314000',
    name: 'Talusan',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908314000',
  },
  {
    code: '098315000',
    name: 'Titay',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908315000',
  },
  {
    code: '098316000',
    name: 'Tungawan',
    oldName: '',
    isCapital: false,
    provinceCode: '098300000',
    districtCode: false,
    regionCode: '090000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '0908316000',
  },
  {
    code: '101301000',
    name: 'Baungon',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001301000',
  },
  {
    code: '101302000',
    name: 'Damulog',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001302000',
  },
  {
    code: '101303000',
    name: 'Dangcagan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001303000',
  },
  {
    code: '101304000',
    name: 'Don Carlos',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001304000',
  },
  {
    code: '101305000',
    name: 'Impasug-Ong',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001305000',
  },
  {
    code: '101306000',
    name: 'Kadingilan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001306000',
  },
  {
    code: '101307000',
    name: 'Kalilangan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001307000',
  },
  {
    code: '101308000',
    name: 'Kibawe',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001308000',
  },
  {
    code: '101309000',
    name: 'Kitaotao',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001309000',
  },
  {
    code: '101310000',
    name: 'Lantapan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001310000',
  },
  {
    code: '101311000',
    name: 'Libona',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001311000',
  },
  {
    code: '101313000',
    name: 'Malitbog',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001313000',
  },
  {
    code: '101314000',
    name: 'Manolo Fortich',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001314000',
  },
  {
    code: '101315000',
    name: 'Maramag',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001315000',
  },
  {
    code: '101316000',
    name: 'Pangantucan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001316000',
  },
  {
    code: '101317000',
    name: 'Quezon',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001317000',
  },
  {
    code: '101318000',
    name: 'San Fernando',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001318000',
  },
  {
    code: '101319000',
    name: 'Sumilao',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001319000',
  },
  {
    code: '101320000',
    name: 'Talakag',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001320000',
  },
  {
    code: '101322000',
    name: 'Cabanglasan',
    oldName: '',
    isCapital: false,
    provinceCode: '101300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001322000',
  },
  {
    code: '101801000',
    name: 'Catarman',
    oldName: '',
    isCapital: false,
    provinceCode: '101800000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001801000',
  },
  {
    code: '101802000',
    name: 'Guinsiliban',
    oldName: '',
    isCapital: false,
    provinceCode: '101800000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001802000',
  },
  {
    code: '101803000',
    name: 'Mahinog',
    oldName: '',
    isCapital: false,
    provinceCode: '101800000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001803000',
  },
  {
    code: '101804000',
    name: 'Mambajao',
    oldName: '',
    isCapital: true,
    provinceCode: '101800000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001804000',
  },
  {
    code: '101805000',
    name: 'Sagay',
    oldName: '',
    isCapital: false,
    provinceCode: '101800000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1001805000',
  },
  {
    code: '103501000',
    name: 'Bacolod',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003501000',
  },
  {
    code: '103502000',
    name: 'Baloi',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003502000',
  },
  {
    code: '103503000',
    name: 'Baroy',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003503000',
  },
  {
    code: '103505000',
    name: 'Kapatagan',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003505000',
  },
  {
    code: '103506000',
    name: 'Sultan Naga Dimaporo',
    oldName: 'Karomatan',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003506000',
  },
  {
    code: '103507000',
    name: 'Kauswagan',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003507000',
  },
  {
    code: '103508000',
    name: 'Kolambugan',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003508000',
  },
  {
    code: '103509000',
    name: 'Lala',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003509000',
  },
  {
    code: '103510000',
    name: 'Linamon',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003510000',
  },
  {
    code: '103511000',
    name: 'Magsaysay',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003511000',
  },
  {
    code: '103512000',
    name: 'Maigo',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003512000',
  },
  {
    code: '103513000',
    name: 'Matungao',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003513000',
  },
  {
    code: '103514000',
    name: 'Munai',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003514000',
  },
  {
    code: '103515000',
    name: 'Nunungan',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003515000',
  },
  {
    code: '103516000',
    name: 'Pantao Ragat',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003516000',
  },
  {
    code: '103517000',
    name: 'Poona Piagapo',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003517000',
  },
  {
    code: '103518000',
    name: 'Salvador',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003518000',
  },
  {
    code: '103519000',
    name: 'Sapad',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003519000',
  },
  {
    code: '103520000',
    name: 'Tagoloan',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003520000',
  },
  {
    code: '103521000',
    name: 'Tangcal',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003521000',
  },
  {
    code: '103522000',
    name: 'Tubod',
    oldName: '',
    isCapital: true,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003522000',
  },
  {
    code: '103523000',
    name: 'Pantar',
    oldName: '',
    isCapital: false,
    provinceCode: '103500000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1003523000',
  },
  {
    code: '104201000',
    name: 'Aloran',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004201000',
  },
  {
    code: '104202000',
    name: 'Baliangao',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004202000',
  },
  {
    code: '104203000',
    name: 'Bonifacio',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004203000',
  },
  {
    code: '104204000',
    name: 'Calamba',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004204000',
  },
  {
    code: '104205000',
    name: 'Clarin',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004205000',
  },
  {
    code: '104206000',
    name: 'Concepcion',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004206000',
  },
  {
    code: '104207000',
    name: 'Jimenez',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004207000',
  },
  {
    code: '104208000',
    name: 'Lopez Jaena',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004208000',
  },
  {
    code: '104211000',
    name: 'Panaon',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004211000',
  },
  {
    code: '104212000',
    name: 'Plaridel',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004212000',
  },
  {
    code: '104213000',
    name: 'Sapang Dalaga',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004213000',
  },
  {
    code: '104214000',
    name: 'Sinacaban',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004214000',
  },
  {
    code: '104216000',
    name: 'Tudela',
    oldName: '',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004216000',
  },
  {
    code: '104217000',
    name: 'Don Victoriano Chiongbian',
    oldName: 'Don Mariano Marcos',
    isCapital: false,
    provinceCode: '104200000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004217000',
  },
  {
    code: '104301000',
    name: 'Alubijid',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004301000',
  },
  {
    code: '104302000',
    name: 'Balingasag',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004302000',
  },
  {
    code: '104303000',
    name: 'Balingoan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004303000',
  },
  {
    code: '104304000',
    name: 'Binuangan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004304000',
  },
  {
    code: '104306000',
    name: 'Claveria',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004306000',
  },
  {
    code: '104309000',
    name: 'Gitagum',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004309000',
  },
  {
    code: '104310000',
    name: 'Initao',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004310000',
  },
  {
    code: '104311000',
    name: 'Jasaan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004311000',
  },
  {
    code: '104312000',
    name: 'Kinoguitan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004312000',
  },
  {
    code: '104313000',
    name: 'Lagonglong',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004313000',
  },
  {
    code: '104314000',
    name: 'Laguindingan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004314000',
  },
  {
    code: '104315000',
    name: 'Libertad',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004315000',
  },
  {
    code: '104316000',
    name: 'Lugait',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004316000',
  },
  {
    code: '104317000',
    name: 'Magsaysay',
    oldName: 'Linugos',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004317000',
  },
  {
    code: '104318000',
    name: 'Manticao',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004318000',
  },
  {
    code: '104319000',
    name: 'Medina',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004319000',
  },
  {
    code: '104320000',
    name: 'Naawan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004320000',
  },
  {
    code: '104321000',
    name: 'Opol',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004321000',
  },
  {
    code: '104322000',
    name: 'Salay',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004322000',
  },
  {
    code: '104323000',
    name: 'Sugbongcogon',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004323000',
  },
  {
    code: '104324000',
    name: 'Tagoloan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004324000',
  },
  {
    code: '104325000',
    name: 'Talisayan',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004325000',
  },
  {
    code: '104326000',
    name: 'Villanueva',
    oldName: '',
    isCapital: false,
    provinceCode: '104300000',
    districtCode: false,
    regionCode: '100000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1004326000',
  },
  {
    code: '112301000',
    name: 'Asuncion',
    oldName: 'Saug',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102301000',
  },
  {
    code: '112303000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102303000',
  },
  {
    code: '112305000',
    name: 'Kapalong',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102305000',
  },
  {
    code: '112314000',
    name: 'New Corella',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102314000',
  },
  {
    code: '112318000',
    name: 'Santo Tomas',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102318000',
  },
  {
    code: '112322000',
    name: 'Talaingod',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102322000',
  },
  {
    code: '112323000',
    name: 'Braulio E. Dujali',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102323000',
  },
  {
    code: '112324000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    provinceCode: '112300000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102324000',
  },
  {
    code: '112401000',
    name: 'Bansalan',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102401000',
  },
  {
    code: '112404000',
    name: 'Hagonoy',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102404000',
  },
  {
    code: '112406000',
    name: 'Kiblawan',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102406000',
  },
  {
    code: '112407000',
    name: 'Magsaysay',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102407000',
  },
  {
    code: '112408000',
    name: 'Malalag',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102408000',
  },
  {
    code: '112410000',
    name: 'Matanao',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102410000',
  },
  {
    code: '112411000',
    name: 'Padada',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102411000',
  },
  {
    code: '112412000',
    name: 'Santa Cruz',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102412000',
  },
  {
    code: '112414000',
    name: 'Sulop',
    oldName: '',
    isCapital: false,
    provinceCode: '112400000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102414000',
  },
  {
    code: '112501000',
    name: 'Baganga',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102501000',
  },
  {
    code: '112502000',
    name: 'Banaybanay',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102502000',
  },
  {
    code: '112503000',
    name: 'Boston',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102503000',
  },
  {
    code: '112504000',
    name: 'Caraga',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102504000',
  },
  {
    code: '112505000',
    name: 'Cateel',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102505000',
  },
  {
    code: '112506000',
    name: 'Governor Generoso',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102506000',
  },
  {
    code: '112507000',
    name: 'Lupon',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102507000',
  },
  {
    code: '112508000',
    name: 'Manay',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102508000',
  },
  {
    code: '112510000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102510000',
  },
  {
    code: '112511000',
    name: 'Tarragona',
    oldName: '',
    isCapital: false,
    provinceCode: '112500000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1102511000',
  },
  {
    code: '118201000',
    name: 'Compostela',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108201000',
  },
  {
    code: '118202000',
    name: 'Laak',
    oldName: 'San Vicente',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108202000',
  },
  {
    code: '118203000',
    name: 'Mabini',
    oldName: 'Doña Alicia',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108203000',
  },
  {
    code: '118204000',
    name: 'Maco',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108204000',
  },
  {
    code: '118205000',
    name: 'Maragusan',
    oldName: 'San Mariano',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108205000',
  },
  {
    code: '118206000',
    name: 'Mawab',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108206000',
  },
  {
    code: '118207000',
    name: 'Monkayo',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108207000',
  },
  {
    code: '118208000',
    name: 'Montevista',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108208000',
  },
  {
    code: '118209000',
    name: 'Nabunturan',
    oldName: '',
    isCapital: true,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108209000',
  },
  {
    code: '118210000',
    name: 'New Bataan',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108210000',
  },
  {
    code: '118211000',
    name: 'Pantukan',
    oldName: '',
    isCapital: false,
    provinceCode: '118200000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108211000',
  },
  {
    code: '118601000',
    name: 'Don Marcelino',
    oldName: '',
    isCapital: false,
    provinceCode: '118600000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108601000',
  },
  {
    code: '118602000',
    name: 'Jose Abad Santos',
    oldName: 'Trinidad',
    isCapital: false,
    provinceCode: '118600000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108602000',
  },
  {
    code: '118603000',
    name: 'Malita',
    oldName: '',
    isCapital: true,
    provinceCode: '118600000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108603000',
  },
  {
    code: '118604000',
    name: 'Santa Maria',
    oldName: '',
    isCapital: false,
    provinceCode: '118600000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108604000',
  },
  {
    code: '118605000',
    name: 'Sarangani',
    oldName: '',
    isCapital: false,
    provinceCode: '118600000',
    districtCode: false,
    regionCode: '110000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1108605000',
  },
  {
    code: '124701000',
    name: 'Alamada',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204701000',
  },
  {
    code: '124702000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204702000',
  },
  {
    code: '124703000',
    name: 'Kabacan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204703000',
  },
  {
    code: '124705000',
    name: 'Libungan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204705000',
  },
  {
    code: '124706000',
    name: 'Magpet',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204706000',
  },
  {
    code: '124707000',
    name: 'Makilala',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204707000',
  },
  {
    code: '124708000',
    name: 'Matalam',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204708000',
  },
  {
    code: '124709000',
    name: 'Midsayap',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204709000',
  },
  {
    code: '124710000',
    name: "M'lang",
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204710000',
  },
  {
    code: '124711000',
    name: 'Pigkawayan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204711000',
  },
  {
    code: '124712000',
    name: 'Pikit',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204712000',
  },
  {
    code: '124713000',
    name: 'President Roxas',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204713000',
  },
  {
    code: '124714000',
    name: 'Tulunan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204714000',
  },
  {
    code: '124715000',
    name: 'Antipas',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204715000',
  },
  {
    code: '124716000',
    name: 'Banisilan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204716000',
  },
  {
    code: '124717000',
    name: 'Aleosan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204717000',
  },
  {
    code: '124718000',
    name: 'Arakan',
    oldName: '',
    isCapital: false,
    provinceCode: '124700000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1204718000',
  },
  {
    code: '126302000',
    name: 'Banga',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206302000',
  },
  {
    code: '126311000',
    name: 'Norala',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206311000',
  },
  {
    code: '126312000',
    name: 'Polomolok',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206312000',
  },
  {
    code: '126313000',
    name: 'Surallah',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206313000',
  },
  {
    code: '126314000',
    name: 'Tampakan',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206314000',
  },
  {
    code: '126315000',
    name: 'Tantangan',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206315000',
  },
  {
    code: '126316000',
    name: "T'boli",
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206316000',
  },
  {
    code: '126317000',
    name: 'Tupi',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206317000',
  },
  {
    code: '126318000',
    name: 'Santo Niño',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206318000',
  },
  {
    code: '126319000',
    name: 'Lake Sebu',
    oldName: '',
    isCapital: false,
    provinceCode: '126300000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206319000',
  },
  {
    code: '126501000',
    name: 'Bagumbayan',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206501000',
  },
  {
    code: '126502000',
    name: 'Columbio',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206502000',
  },
  {
    code: '126503000',
    name: 'Esperanza',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206503000',
  },
  {
    code: '126504000',
    name: 'Isulan',
    oldName: '',
    isCapital: true,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206504000',
  },
  {
    code: '126505000',
    name: 'Kalamansig',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206505000',
  },
  {
    code: '126506000',
    name: 'Lebak',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206506000',
  },
  {
    code: '126507000',
    name: 'Lutayan',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206507000',
  },
  {
    code: '126508000',
    name: 'Lambayong',
    oldName: 'Mariano Marcos',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206508000',
  },
  {
    code: '126509000',
    name: 'Palimbang',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206509000',
  },
  {
    code: '126510000',
    name: 'President Quirino',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206510000',
  },
  {
    code: '126512000',
    name: 'Sen. Ninoy Aquino',
    oldName: '',
    isCapital: false,
    provinceCode: '126500000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1206512000',
  },
  {
    code: '128001000',
    name: 'Alabel',
    oldName: '',
    isCapital: true,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208001000',
  },
  {
    code: '128002000',
    name: 'Glan',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208002000',
  },
  {
    code: '128003000',
    name: 'Kiamba',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208003000',
  },
  {
    code: '128004000',
    name: 'Maasim',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208004000',
  },
  {
    code: '128005000',
    name: 'Maitum',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208005000',
  },
  {
    code: '128006000',
    name: 'Malapatan',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208006000',
  },
  {
    code: '128007000',
    name: 'Malungon',
    oldName: '',
    isCapital: false,
    provinceCode: '128000000',
    districtCode: false,
    regionCode: '120000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1208007000',
  },
  {
    code: '160201000',
    name: 'Buenavista',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600201000',
  },
  {
    code: '160204000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600204000',
  },
  {
    code: '160205000',
    name: 'Jabonga',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600205000',
  },
  {
    code: '160206000',
    name: 'Kitcharao',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600206000',
  },
  {
    code: '160207000',
    name: 'Las Nieves',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600207000',
  },
  {
    code: '160208000',
    name: 'Magallanes',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600208000',
  },
  {
    code: '160209000',
    name: 'Nasipit',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600209000',
  },
  {
    code: '160210000',
    name: 'Santiago',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600210000',
  },
  {
    code: '160211000',
    name: 'Tubay',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600211000',
  },
  {
    code: '160212000',
    name: 'Remedios T. Romualdez',
    oldName: '',
    isCapital: false,
    provinceCode: '160200000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600212000',
  },
  {
    code: '160302000',
    name: 'Bunawan',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600302000',
  },
  {
    code: '160303000',
    name: 'Esperanza',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600303000',
  },
  {
    code: '160304000',
    name: 'La Paz',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600304000',
  },
  {
    code: '160305000',
    name: 'Loreto',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600305000',
  },
  {
    code: '160306000',
    name: 'Prosperidad',
    oldName: '',
    isCapital: true,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600306000',
  },
  {
    code: '160307000',
    name: 'Rosario',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600307000',
  },
  {
    code: '160308000',
    name: 'San Francisco',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600308000',
  },
  {
    code: '160309000',
    name: 'San Luis',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600309000',
  },
  {
    code: '160310000',
    name: 'Santa Josefa',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600310000',
  },
  {
    code: '160311000',
    name: 'Talacogon',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600311000',
  },
  {
    code: '160312000',
    name: 'Trento',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600312000',
  },
  {
    code: '160313000',
    name: 'Veruela',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600313000',
  },
  {
    code: '160314000',
    name: 'Sibagat',
    oldName: '',
    isCapital: false,
    provinceCode: '160300000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1600314000',
  },
  {
    code: '166701000',
    name: 'Alegria',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606701000',
  },
  {
    code: '166702000',
    name: 'Bacuag',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606702000',
  },
  {
    code: '166704000',
    name: 'Burgos',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606704000',
  },
  {
    code: '166706000',
    name: 'Claver',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606706000',
  },
  {
    code: '166707000',
    name: 'Dapa',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606707000',
  },
  {
    code: '166708000',
    name: 'Del Carmen',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606708000',
  },
  {
    code: '166710000',
    name: 'General Luna',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606710000',
  },
  {
    code: '166711000',
    name: 'Gigaquit',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606711000',
  },
  {
    code: '166714000',
    name: 'Mainit',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606714000',
  },
  {
    code: '166715000',
    name: 'Malimono',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606715000',
  },
  {
    code: '166716000',
    name: 'Pilar',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606716000',
  },
  {
    code: '166717000',
    name: 'Placer',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606717000',
  },
  {
    code: '166718000',
    name: 'San Benito',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606718000',
  },
  {
    code: '166719000',
    name: 'San Francisco',
    oldName: 'Anao-aon',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606719000',
  },
  {
    code: '166720000',
    name: 'San Isidro',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606720000',
  },
  {
    code: '166721000',
    name: 'Santa Monica',
    oldName: 'Sapao',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606721000',
  },
  {
    code: '166722000',
    name: 'Sison',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606722000',
  },
  {
    code: '166723000',
    name: 'Socorro',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606723000',
  },
  {
    code: '166725000',
    name: 'Tagana-An',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606725000',
  },
  {
    code: '166727000',
    name: 'Tubod',
    oldName: '',
    isCapital: false,
    provinceCode: '166700000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606727000',
  },
  {
    code: '166801000',
    name: 'Barobo',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606801000',
  },
  {
    code: '166802000',
    name: 'Bayabas',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606802000',
  },
  {
    code: '166804000',
    name: 'Cagwait',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606804000',
  },
  {
    code: '166805000',
    name: 'Cantilan',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606805000',
  },
  {
    code: '166806000',
    name: 'Carmen',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606806000',
  },
  {
    code: '166807000',
    name: 'Carrascal',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606807000',
  },
  {
    code: '166808000',
    name: 'Cortes',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606808000',
  },
  {
    code: '166809000',
    name: 'Hinatuan',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606809000',
  },
  {
    code: '166810000',
    name: 'Lanuza',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606810000',
  },
  {
    code: '166811000',
    name: 'Lianga',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606811000',
  },
  {
    code: '166812000',
    name: 'Lingig',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606812000',
  },
  {
    code: '166813000',
    name: 'Madrid',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606813000',
  },
  {
    code: '166814000',
    name: 'Marihatag',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606814000',
  },
  {
    code: '166815000',
    name: 'San Agustin',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606815000',
  },
  {
    code: '166816000',
    name: 'San Miguel',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606816000',
  },
  {
    code: '166817000',
    name: 'Tagbina',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606817000',
  },
  {
    code: '166818000',
    name: 'Tago',
    oldName: '',
    isCapital: false,
    provinceCode: '166800000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1606818000',
  },
  {
    code: '168501000',
    name: 'Basilisa',
    oldName: 'Rizal',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608501000',
  },
  {
    code: '168502000',
    name: 'Cagdianao',
    oldName: '',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608502000',
  },
  {
    code: '168503000',
    name: 'Dinagat',
    oldName: '',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608503000',
  },
  {
    code: '168504000',
    name: 'Libjo',
    oldName: 'Albor',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608504000',
  },
  {
    code: '168505000',
    name: 'Loreto',
    oldName: '',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608505000',
  },
  {
    code: '168506000',
    name: 'San Jose',
    oldName: '',
    isCapital: true,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608506000',
  },
  {
    code: '168507000',
    name: 'Tubajon',
    oldName: '',
    isCapital: false,
    provinceCode: '168500000',
    districtCode: false,
    regionCode: '160000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1608507000',
  },
  {
    code: '150703000',
    name: 'Lantawan',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900703000',
  },
  {
    code: '150704000',
    name: 'Maluso',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900704000',
  },
  {
    code: '150705000',
    name: 'Sumisip',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900705000',
  },
  {
    code: '150706000',
    name: 'Tipo-Tipo',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900706000',
  },
  {
    code: '150707000',
    name: 'Tuburan',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900707000',
  },
  {
    code: '150708000',
    name: 'Akbar',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900708000',
  },
  {
    code: '150709000',
    name: 'Al-Barka',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900709000',
  },
  {
    code: '150710000',
    name: 'Hadji Mohammad Ajul',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900710000',
  },
  {
    code: '150711000',
    name: 'Ungkaya Pukan',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900711000',
  },
  {
    code: '150712000',
    name: 'Hadji Muhtamad',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900712000',
  },
  {
    code: '150713000',
    name: 'Tabuan-Lasa',
    oldName: '',
    isCapital: false,
    provinceCode: '150700000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1900713000',
  },
  {
    code: '153601000',
    name: 'Bacolod-Kalawi',
    oldName: 'Bacolod Grande',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903601000',
  },
  {
    code: '153602000',
    name: 'Balabagan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903602000',
  },
  {
    code: '153603000',
    name: 'Balindong',
    oldName: 'Watu',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903603000',
  },
  {
    code: '153604000',
    name: 'Bayang',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903604000',
  },
  {
    code: '153605000',
    name: 'Binidayan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903605000',
  },
  {
    code: '153606000',
    name: 'Bubong',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903606000',
  },
  {
    code: '153607000',
    name: 'Butig',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903607000',
  },
  {
    code: '153609000',
    name: 'Ganassi',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903609000',
  },
  {
    code: '153610000',
    name: 'Kapai',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903610000',
  },
  {
    code: '153611000',
    name: 'Lumba-Bayabao',
    oldName: 'Maguing',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903611000',
  },
  {
    code: '153612000',
    name: 'Lumbatan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903612000',
  },
  {
    code: '153613000',
    name: 'Madalum',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903613000',
  },
  {
    code: '153614000',
    name: 'Madamba',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903614000',
  },
  {
    code: '153615000',
    name: 'Malabang',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903615000',
  },
  {
    code: '153616000',
    name: 'Marantao',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903616000',
  },
  {
    code: '153618000',
    name: 'Masiu',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903618000',
  },
  {
    code: '153619000',
    name: 'Mulondo',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903619000',
  },
  {
    code: '153620000',
    name: 'Pagayawan',
    oldName: 'Tatarikan',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903620000',
  },
  {
    code: '153621000',
    name: 'Piagapo',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903621000',
  },
  {
    code: '153622000',
    name: 'Poona Bayabao',
    oldName: 'Gata',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903622000',
  },
  {
    code: '153623000',
    name: 'Pualas',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903623000',
  },
  {
    code: '153624000',
    name: 'Ditsaan-Ramain',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903624000',
  },
  {
    code: '153625000',
    name: 'Saguiaran',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903625000',
  },
  {
    code: '153626000',
    name: 'Tamparan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903626000',
  },
  {
    code: '153627000',
    name: 'Taraka',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903627000',
  },
  {
    code: '153628000',
    name: 'Tubaran',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903628000',
  },
  {
    code: '153629000',
    name: 'Tugaya',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903629000',
  },
  {
    code: '153630000',
    name: 'Wao',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903630000',
  },
  {
    code: '153631000',
    name: 'Marogong',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903631000',
  },
  {
    code: '153632000',
    name: 'Calanogas',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903632000',
  },
  {
    code: '153633000',
    name: 'Buadiposo-Buntong',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903633000',
  },
  {
    code: '153634000',
    name: 'Maguing',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903634000',
  },
  {
    code: '153635000',
    name: 'Picong',
    oldName: 'Sultan Gumander',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903635000',
  },
  {
    code: '153636000',
    name: 'Lumbayanague',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903636000',
  },
  {
    code: '153637000',
    name: 'Amai Manabilang',
    oldName: 'Bumbaran',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903637000',
  },
  {
    code: '153638000',
    name: 'Tagoloan Ii',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903638000',
  },
  {
    code: '153639000',
    name: 'Kapatagan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903639000',
  },
  {
    code: '153640000',
    name: 'Sultan Dumalondong',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903640000',
  },
  {
    code: '153641000',
    name: 'Lumbaca-Unayan',
    oldName: '',
    isCapital: false,
    provinceCode: '153600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903641000',
  },
  {
    code: '153801000',
    name: 'Ampatuan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903801000',
  },
  {
    code: '153802000',
    name: 'Buldon',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903802000',
  },
  {
    code: '153803000',
    name: 'Buluan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903803000',
  },
  {
    code: '153805000',
    name: 'Datu Paglas',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903805000',
  },
  {
    code: '153806000',
    name: 'Datu Piang',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903806000',
  },
  {
    code: '153807000',
    name: 'Datu Odin Sinsuat',
    oldName: 'Dinaig',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903807000',
  },
  {
    code: '153808000',
    name: 'Shariff Aguak',
    oldName: 'Maganoy',
    isCapital: true,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903808000',
  },
  {
    code: '153809000',
    name: 'Matanog',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903809000',
  },
  {
    code: '153810000',
    name: 'Pagalungan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903810000',
  },
  {
    code: '153811000',
    name: 'Parang',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903811000',
  },
  {
    code: '153812000',
    name: 'Sultan Kudarat',
    oldName: 'Nuling',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903812000',
  },
  {
    code: '153813000',
    name: 'Sultan Sa Barongis',
    oldName: 'Lambayong',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903813000',
  },
  {
    code: '153814000',
    name: 'Kabuntalan',
    oldName: 'Tumbao',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903814000',
  },
  {
    code: '153815000',
    name: 'Upi',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903815000',
  },
  {
    code: '153816000',
    name: 'Talayan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903816000',
  },
  {
    code: '153817000',
    name: 'South Upi',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903817000',
  },
  {
    code: '153818000',
    name: 'Barira',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903818000',
  },
  {
    code: '153819000',
    name: 'Gen. s.k. Pendatun',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903819000',
  },
  {
    code: '153820000',
    name: 'Mamasapano',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903820000',
  },
  {
    code: '153821000',
    name: 'Talitay',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903821000',
  },
  {
    code: '153822000',
    name: 'Pagagawan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903822000',
  },
  {
    code: '153823000',
    name: 'Paglat',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903823000',
  },
  {
    code: '153824000',
    name: 'Sultan Mastura',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903824000',
  },
  {
    code: '153825000',
    name: 'Guindulungan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903825000',
  },
  {
    code: '153826000',
    name: 'Datu Saudi-Ampatuan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903826000',
  },
  {
    code: '153827000',
    name: 'Datu Unsay',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903827000',
  },
  {
    code: '153828000',
    name: 'Datu Abdullah Sangki',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903828000',
  },
  {
    code: '153829000',
    name: 'Rajah Buayan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903829000',
  },
  {
    code: '153830000',
    name: 'Datu Blah T. Sinsuat',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903830000',
  },
  {
    code: '153831000',
    name: 'Datu Anggal Midtimbang',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903831000',
  },
  {
    code: '153832000',
    name: 'Mangudadatu',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903832000',
  },
  {
    code: '153833000',
    name: 'Pandag',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903833000',
  },
  {
    code: '153834000',
    name: 'Northern Kabuntalan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903834000',
  },
  {
    code: '153835000',
    name: 'Datu Hoffer Ampatuan',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903835000',
  },
  {
    code: '153836000',
    name: 'Datu Salibo',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903836000',
  },
  {
    code: '153837000',
    name: 'Shariff Saydona Mustapha',
    oldName: '',
    isCapital: false,
    provinceCode: '153800000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1903837000',
  },
  {
    code: '156601000',
    name: 'Indanan',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906601000',
  },
  {
    code: '156602000',
    name: 'Jolo',
    oldName: '',
    isCapital: true,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906602000',
  },
  {
    code: '156603000',
    name: 'Kalingalan Caluang',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906603000',
  },
  {
    code: '156604000',
    name: 'Luuk',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906604000',
  },
  {
    code: '156605000',
    name: 'Maimbung',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906605000',
  },
  {
    code: '156606000',
    name: 'Hadji Panglima Tahil',
    oldName: 'Marunggas',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906606000',
  },
  {
    code: '156607000',
    name: 'Old Panamao',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906607000',
  },
  {
    code: '156608000',
    name: 'Pangutaran',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906608000',
  },
  {
    code: '156609000',
    name: 'Parang',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906609000',
  },
  {
    code: '156610000',
    name: 'Pata',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906610000',
  },
  {
    code: '156611000',
    name: 'Patikul',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906611000',
  },
  {
    code: '156612000',
    name: 'Siasi',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906612000',
  },
  {
    code: '156613000',
    name: 'Talipao',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906613000',
  },
  {
    code: '156614000',
    name: 'Tapul',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906614000',
  },
  {
    code: '156615000',
    name: 'Tongkil',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906615000',
  },
  {
    code: '156616000',
    name: 'Panglima Estino',
    oldName: 'New Panamao',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906616000',
  },
  {
    code: '156617000',
    name: 'Lugus',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906617000',
  },
  {
    code: '156618000',
    name: 'Pandami',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906618000',
  },
  {
    code: '156619000',
    name: 'Omar',
    oldName: '',
    isCapital: false,
    provinceCode: '156600000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1906619000',
  },
  {
    code: '157001000',
    name: 'Panglima Sugala',
    oldName: 'Balimbing',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907001000',
  },
  {
    code: '157002000',
    name: 'Bongao',
    oldName: '',
    isCapital: true,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907002000',
  },
  {
    code: '157003000',
    name: 'Mapun',
    oldName: 'Cagayan de Tawi-Tawi',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907003000',
  },
  {
    code: '157004000',
    name: 'Simunul',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907004000',
  },
  {
    code: '157005000',
    name: 'Sitangkai',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907005000',
  },
  {
    code: '157006000',
    name: 'South Ubian',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907006000',
  },
  {
    code: '157007000',
    name: 'Tandubas',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907007000',
  },
  {
    code: '157008000',
    name: 'Turtle Islands',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907008000',
  },
  {
    code: '157009000',
    name: 'Languyan',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907009000',
  },
  {
    code: '157010000',
    name: 'Sapa-Sapa',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907010000',
  },
  {
    code: '157011000',
    name: 'Sibutu',
    oldName: '',
    isCapital: false,
    provinceCode: '157000000',
    districtCode: false,
    regionCode: '150000000',
    islandGroupCode: 'mindanao',
    psgc10DigitCode: '1907011000',
  },
];

export const municipalityData: Municipality[] = [
  ...luzonMunicipalities,
  ...visayasMunicipalities,
  ...mindanaoMunicipalities,
];
