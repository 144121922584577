import * as yup from 'yup';

import { EntityFields } from '../../../utils';

export const HP_ENTITY_NAME = 'Health Session';
export interface HealthPackageModel {
  id: number;
  package_name: string;
  descripton: string;
  assigned_facilities: string;
  assigned_facilities_ids: string;
  facilities: any[];
}

export interface HealthPlanSessionModel {
  id: number;
  stage_name: string;
  days_after_prev_stage: number;
  previous_stage: number;
  next_stage: number;
  visit_date: string;
}

export interface PatientHealthPlan {
  id: number;
  patient_id: number;
  package_id: number;
  stage_id: number;
  next_stage: number;
  stage_name: string;
  expected_next_stage: string;
  number_of_stages: number;
  date_started: string;
  is_completed: boolean;
  package_name: string;
  is_active: boolean;
}

export const health_session_schema = yup.object().shape({
  package_name: yup.string().required('Session Plan Name is required.'),
  facilities: yup.array().when('apply_to_all_clinics', {
    is: (value: boolean) => !value,
    then: yup.array().min(1, 'At least one clinic must be selected'),
    otherwise: yup.array().nullable(),
  }),
});

export const HealthPackageField: EntityFields[] = [
  { fieldName: 'package_name', displayName: 'Session Plan Name', type: 'text', span: 2 },
  {
    fieldName: 'facilities',
    displayName: 'Clinic',
    type: 'multiselect',
    span: 2,
    options: [],
    hiddenBasedOnOtherField: (data: any) => {
      return data.apply_to_all_clinics;
    },
  },
  { fieldName: 'apply_to_all_clinics', displayName: 'Apply to All Clinics', type: 'checkbox', span: 4 },
  {
    fieldName: 'description',
    type: 'rich_text',
    displayName: 'Description (Optional)',
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue('description', value);
    },
    height: '100px',
    span: 4,
  },
];

export const healthPackageTableColumn: string[] = ['package_name'];
export const healthPackageFormField: string[] = ['package_name', 'facilities', 'description', 'apply_to_all_clinics'];

// to be changeddd
export const healthPackageInitialValues = {
  stage_name: 'Start',
  clinic_id: 1,
  package_name: '',
  description: '',
  facilities: [],
  apply_to_all_clinics: true,
};
