import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  border: 3px solid;
  border-image: linear-gradient(270deg, #0463ae 0%, #239bd7 100%) 1;
  margin: 1rem 0 1.5rem 0;
`;

const CustomDivider = () => {
  return <StyledDivider />;
};

export default CustomDivider;
