/* eslint-disable react-hooks/exhaustive-deps */

import {
  PATIENT_ENTITY_NAME,
  createPatientSchema,
  patientFields,
  patientFormFields,
  patientInitialValues,
} from './PatientModel';
import { createPatient, updatePatient } from 'company/api/patient';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  facility: FacilityModel;
  patient?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PatientForm = (props: Props) => {
  const { facility, patient, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = (data: any) => {
    data.tags_ids = data.tags.map((item: any) => (item.value === null ? item.id : item.value));
    return patient ? updatePatient(facility.id, patient.patient_id, data) : createPatient(facility.id, data);
  };

  const processInitialValues = (setFieldData: React.Dispatch<any>, values: any) => {
    setFieldData((prev: any) => ({ ...prev, department_id: { corporate_client_id: values.corporate_client_id } }));
  };

  return (
    <TemplateForm
      submitButtonId={'patient_form_submit_button'}
      entityName={PATIENT_ENTITY_NAME}
      fields={patientFields}
      visibleFields={patientFormFields}
      entity={patient}
      initialValues={patient ? changeNullToBlank(patient) : patientInitialValues}
      schema={createPatientSchema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      processInitialValues={processInitialValues}
    />
  );
};

export default PatientForm;
