import { Box, Modal, Paper, SxProps, Theme, Typography, useTheme } from '@mui/material';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { tokens } from 'theme/theme';

type FullScreenModalProps = {
  header?: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  noHeader?: boolean;
  sx?: SxProps<Theme>;
};

const FullScreenMobileModal: React.FC<FullScreenModalProps> = ({
  header,
  children,
  open,
  setOpen,
  noHeader = false,
  sx,
}) => {
  return (
    <Modal open={open}>
      <Paper sx={{ width: '100dvw', height: '100dvh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Header header={header} setOpen={setOpen} noHeader={noHeader} sx={sx} />
        <Box
          sx={{
            width: '92%',
            overflowY: 'auto',
            scrollBehavior: 'auto',
            flex: 1,
            padding: '1rem 0',
            '&::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {children}
        </Box>
      </Paper>
    </Modal>
  );
};

type HeaderProps = {
  header?: string;
  headerActionIcon?: any;
  setOpen: (openModal: boolean) => void;
  noHeader?: boolean;
  sx?: SxProps<Theme>;
};

function Header(headerProps: HeaderProps) {
  const { header, headerActionIcon, setOpen, noHeader, sx } = headerProps;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function handleCloseModal() {
    setOpen(false);
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        padding: '2rem 0.8rem 1.2rem 0.8rem',
        ...sx,
      }}
    >
      <KeyboardBackspaceIcon sx={{ color: colors.accent, fontSize: '2rem' }} onClick={handleCloseModal} />
      {!noHeader && (
        <Typography sx={{ color: colors.accent, fontSize: '1.25rem', fontWeight: '600' }}>{header}</Typography>
      )}
      {headerActionIcon ? headerActionIcon : <Typography></Typography>}
    </Box>
  );
}

export default FullScreenMobileModal;
