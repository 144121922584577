import { ApiQuery, FieldInput } from 'core/model/interface';
import {
  Box,
  IconButton,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ColoredTable,
  ConfirmationDialog,
  CustomForm,
  CustomModal,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import {
  addStage,
  deleteStage,
  getServicesByStage,
  getStageDetails,
  getStages,
  removeServiceFromStage,
  updateStage,
} from 'company/api/health-packages';
import { health_package_stage_schema, health_package_stage_start_schema } from 'company/model/schema';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddMultipleStages from './AddMultipleStages';
import AddServices from './AddServices';
import { ContainerRow } from 'core/components/containers';
import DeleteButton from 'core/components/buttons/DeleteButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FacilityContext } from 'core/context/facility.context';
import { LoadingScreen } from 'core/screens';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import UpdateHealthPackageModal from './UpdateHealthPackageModal';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ServiceInput = {
  stage_name: string;
  days_after_prev_stage: number | string;
};

interface ServiceFieldInput extends FieldInput {
  field_name?: keyof ServiceInput;
}

const initialValues: ServiceInput = {
  stage_name: '',
  days_after_prev_stage: '0',
};

type NewViewHealthPackageProps = {
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  healthPackage: any;
  setChildHeader?: any;
  updateInitialValues: any;
  callbackAfterChanges?: () => void;
};

const NewViewHealthPackage: React.FC<NewViewHealthPackageProps> = ({
  setRefresh,
  healthPackage,
  setChildHeader,
  updateInitialValues,
  callbackAfterChanges,
}) => {
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  //Stages
  const [openStageModal, setOpenStageModal] = useState<boolean>(false);
  const [updatedPackage, setUpdatedPackage] = useState<any>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stageHeader, setStageHeader] = useState<string>('Create Session');
  const [toUpdate, setToUpdate] = useState<boolean>(false);
  const [stages, setStages] = useState<any[]>([]);
  const [currentStage, setCurrentStage] = useState<any>(null);
  const [stageToDelete, setStageToDelete] = useState<any>(null);
  const [stageDetails, setStageDetails] = useState<any>(null);

  // Services
  const [openAddServiceModal, setOpenAddServiceModal] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<any>();
  const [refreshServiceTable, setRefreshServiceTable] = useState<number>(0);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddMultipleStagesModal, setOpenAddMultipleStagesModal] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const tableRef: any = useRef();

  const stageFields: ServiceFieldInput[] = [
    { field_name: 'stage_name', display_name: 'Session Name', type: 'text', span: 2 },
    {
      field_name: 'days_after_prev_stage',
      display_name: 'Days After Previous Session',
      type: 'text',
      span: 2,
      hidden: (stages && stages.length < 1) || (toUpdate && activeStep === 0) ? true : false,
    },
  ];

  const handleSelectRow = (selected: any) => {
    setSelectedService(selected);
  };

  const handleDeleteService = async () => {
    const serviceList = JSON.stringify([selectedService.id]);
    const services = { services: serviceList };

    if (currentStage.id) {
      return removeServiceFromStage(healthPackage.id, currentStage.id, services).then(() => {
        setRefreshServiceTable((prev: number) => prev + 1);
        enqueueSnackbar('Service successfully removed!', { variant: 'success' });
        refreshServicesTable();
        getDetailsPerStage(stages[activeStep].id);
        callbackAfterChanges && callbackAfterChanges();
      });
    }
  };

  const getStageServices = async (query?: ApiQuery) => {
    if (stages && currentStage) {
      const stage = stages[activeStep];
      return getServicesByStage(facility.id, stage.id, query);
    }
  };

  const getPackageStages = async () => {
    return getStages(healthPackage.id).then((res) => {
      setStages(res.data.data);
    });
  };

  const handleNewStageSubmit = async (data: any) => {
    setLoading(true);

    if (toUpdate) {
      updateStage(currentStage.id, data)
        .then((res) => {
          enqueueSnackbar('Session successfully updated!', { variant: 'success' });
          getPackageStages();
          setOpenStageModal(false);
        })
        .catch((error) => console.error(error));

      setLoading(false);
    } else {
      const stageNameExists = stages.some((stage) => stage.stage_name === data.stage_name);

      if (stageNameExists) {
        setLoading(false);
        return enqueueSnackbar('Unable to save. Session name already exists!', { variant: 'error' });
      }

      if (stages.length) {
        const previous_stage = stages[stages.length - 1];
        data.previous_stage = previous_stage.id;
      }

      addStage(healthPackage.id, data)
        .then((res) => {
          enqueueSnackbar('Session successfully created!', { variant: 'success' });
          setActiveStep(stages.length);
          setOpenStageModal(false);
          getPackageStages();
          getDetailsPerStage(stages[activeStep].id);
          callbackAfterChanges && callbackAfterChanges();
        })
        .catch((error) => console.error(error));

      setLoading(false);
    }
  };

  const confirmDeleteStage = (stage: any) => {
    setStageToDelete(stage);
    setOpenDeleteDialog(true);
    setAlertContent('Are you sure you want to delete ' + stage.stage_name + '?');
  };

  const handleDeleteStage = async () => {
    if (stageToDelete) {
      deleteStage(stageToDelete.id)
        .then((res) => {
          enqueueSnackbar('Session successfully deleted!', { variant: 'success' });
          getPackageStages().then(() => {
            const index = stages.findIndex((stage) => stage.id === stageToDelete.id);
            const newActiveStep = index > 0 ? index - 1 : 0;
            setActiveStep(newActiveStep);
            callbackAfterChanges && callbackAfterChanges();
          });
        })
        .catch((error) => console.error(error));
    }
  };

  const refreshStages = () => {
    getPackageStages();
    callbackAfterChanges && callbackAfterChanges();
  };

  const refreshServicesTable = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  const getDetailsPerStage = (stage_id: number) => {
    getStageDetails(facility.id, stage_id).then((res) => {
      setStageDetails(res.data);
    });
  };

  const handleAddServiceCallback = () => {
    getDetailsPerStage(stages[activeStep].id);
    refreshServicesTable();
    callbackAfterChanges && callbackAfterChanges();
  };

  useEffect(() => {
    refreshServicesTable();
  }, [refreshServiceTable]);

  useEffect(() => {
    if (healthPackage) {
      setActiveStep(0);
      getPackageStages();
    }
    setRefresh((prev: number) => prev + 1);
    setRefreshServiceTable((prev: number) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthPackage]);

  useEffect(() => {
    if (stages && stages.length > 0) {
      setCurrentStage(stages[activeStep]);
    }
  }, [stages, activeStep]);

  useEffect(() => {
    setRefreshServiceTable((prev: number) => prev + 1);
    if (activeStep || currentStage) {
      getDetailsPerStage(activeStep && stages[activeStep] ? stages[activeStep].id : currentStage.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, currentStage]);

  useEffect(() => {
    refreshStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingScreen loading={!healthPackage}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
          },
        }}
      >
        <LoadingScreen loading={!stages}>
          {/* Stepper */}
          <Box
            sx={{
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: !isMobile ? colors.light_blue_background : '#FFFFFF',
              overflowX: 'auto',
              overflowY: 'auto',
              width: isMobile ? '100vw' : '220px',
              marginBottom: isMobile ? '16px' : 0,
              // marginRight: !isMobile ? '16px' : 0,
            }}
          >
            <Typography variant="h3" sx={{ fontSize: '15px', marginBottom: '20px', fontWeight: '500' }}>
              Sessions
            </Typography>

            <Stepper
              activeStep={activeStep}
              nonLinear
              orientation={isMobile ? 'horizontal' : 'vertical'}
              sx={{
                marginLeft: '10px',
                '& .MuiStepConnector-vertical': {
                  marginLeft: '14px',
                },
                '& .MuiStepConnector-line': {
                  borderLeftColor: colors.blueAccent,
                  borderLeftWidth: '2px',
                  margin: 0,
                  padding: 0,
                  minHeight: '25px',
                },
              }}
            >
              {stages.map((stage_data, index) => (
                <Step
                  key={index}
                  onClick={() => {
                    setActiveStep(index);
                    setCurrentStage(stage_data);
                  }}
                  sx={{
                    position: 'relative',
                    '& .MuiStepLabel-root': { padding: 0 },
                    '& .MuiStepLabel-root .MuiStepIcon-text': {
                      fill: colors.accent,
                      fontSize: '14px',
                      cursor: 'pointer',
                    },
                    '& .MuiStepIcon-root': {
                      height: '35px',
                      width: '35px',
                      border: '2px solid ' + colors.blueAccent,
                      color: 'transparent',
                      borderRadius: '50px',
                    },
                    '& .MuiStepIcon-root.Mui-active': {
                      backgroundColor: colors.blueAccent,
                    },
                    '& .MuiStepLabel-root .Mui-completed': { color: colors.accent },
                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                      color: colors.text,
                      cursor: 'pointer',
                      fontSize: '12px',
                    },
                    '& .MuiStepLabel-root .Mui-active': { color: colors.accent, fontWeight: 'bold' },
                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                      cursor: 'pointer',
                    },
                    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                      cursor: 'pointer',
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' },
                    '& .MuiStepLabel-alternativeLabel': { fontSize: '13px', fontWeight: 'bold' },
                    '& .MuiSvgIcon-root': { height: '30px', width: '30px' },
                    '& .MuiStepLabel-label': {
                      color: colors.accent,
                      cursor: 'pointer',
                      fontSize: '12px',
                    },
                    '& .MuiStepLabel-root .MuiStepLabel-label.Mui-active': {
                      color: colors.text,
                    },
                    '&:hover': {
                      '& .MuiStepIcon-root': {
                        color: colors.accent,
                        backgroundColor: colors.blueAccent,
                      },
                      '& .MuiStepLabel-alternativeLabel': {
                        color: colors.text,
                      },
                      '& .MuiStepIcon-text': {
                        fill: 'white',
                      },
                      '& .MuiStepLabel-label': {
                        // vertical
                        color: colors.text,
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      },
                    },
                    '&.MuiStep-root.MuiStep-vertical': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <StepLabel sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {stage_data.stage_name}

                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => confirmDeleteStage(stage_data)}
                          sx={{ '&:hover': { color: colors.redAccent } }}
                        >
                          <DeleteOutlinedIcon style={{ width: '20px', height: '20px', color: colors.redAccent }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StepLabel>
                </Step>
              ))}
              <Step
                onClick={() => {
                  setOpenStageModal(true);
                  setStageHeader('Create Session');
                  setToUpdate(false);
                }}
                sx={{
                  '& .MuiStepLabel-root': { padding: 0 },
                  '& .MuiStepLabel-root .Mui-completed': { color: colors.accent },
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': { color: colors.accent },
                  '& .MuiStepLabel-root .Mui-active': { color: colors.accent, fontWeight: 'bold' },
                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': { color: colors.accent },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' },
                  '& .MuiStepLabel-alternativeLabel': { fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' },
                  '& .MuiSvgIcon-root': { height: '30px', width: '30px' },
                  '& .MuiStepIcon-root.Mui-active': {
                    backgroundColor: colors.blueAccent,
                  },
                  '& .MuiStepLabel-label': {
                    color: colors.accent,
                    cursor: 'pointer',
                    fontSize: '11px',
                  },
                  '& .MuiStepLabel-root .MuiStepIcon-text': {
                    fill: colors.accent,
                    fontSize: '12px',
                    cursor: 'pointer',
                  },
                  '& .MuiStepIcon-root': {
                    height: '32px',
                    width: '32px',
                    border: '2px solid ' + colors.blueAccent,
                    color: 'transparent',
                    borderRadius: '50px',
                  },
                  '&:hover': {
                    '& .MuiStepIcon-root': {
                      color: colors.accent,
                      backgroundColor: colors.blueAccent,
                    },
                    '& .MuiStepLabel-alternativeLabel': {
                      color: colors.accent,
                    },
                    '& .MuiStepIcon-text': {
                      fill: 'white',
                    },
                    '& .MuiStepLabel-label': {
                      color: colors.text,
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    },
                  },
                  '&.MuiStep-root.MuiStep-vertical': {
                    cursor: 'pointer',
                  },
                }}
              >
                <StepLabel
                  color="inherit"
                  StepIconComponent={(props) => (
                    <StepIcon {...props} icon={'+'} active={activeStep === stages.length} completed={false} />
                  )}
                >
                  Create Session
                </StepLabel>
              </Step>
            </Stepper>

            {!isMobile && (
              <Box
                display="flex"
                sx={{ alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? '30px' : '20px' }}
              >
                <PrimaryButton
                  onClick={() => setOpenAddMultipleStagesModal(true)}
                  label="&nbsp; Create Multiple Sessions"
                  fullWidth={isMobile}
                />
              </Box>
            )}
          </Box>
        </LoadingScreen>

        <Box
          sx={{
            margin: '0 30px 0 10px',
            padding: isMobile ? '0 0 0 10px' : '0 20px 0 10px',
            backgroundColor: 'none',
            overflow: 'auto',
            overflowY: 'auto',
            // borderRadius: '10px',
            width: '100%',
            height: '50vh',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}
        >
          {isMobile && (
            <PrimaryButton
              onClick={() => setOpenAddMultipleStagesModal(true)}
              label="&nbsp; Create Multiple Sessions"
              fullWidth={isMobile}
            />
          )}
          <Box display="flex" width="100%">
            {stages.length && currentStage ? (
              <Box
                sx={{
                  // borderRadius: '10px',
                  overflow: 'hidden',
                  margin: '20px 0px 0px 10px',
                  width: '100%',
                }}
              >
                {/* Render content for each stage */}
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  alignItems="start"
                  justifyContent="start"
                  width="100%"
                >
                  <ContainerRow>
                    <Typography variant="h3" sx={{ fontSize: '15px', marginBottom: '20px', fontWeight: '500' }}>
                      {stages && stages.length > activeStep ? stages[activeStep].stage_name : ''}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Update Session">
                        <IconButton
                          onClick={() => {
                            setOpenStageModal(true);
                            setStageHeader('Update Session');
                            setToUpdate(true);
                            setCurrentStage(stages[activeStep]);
                          }}
                          sx={{
                            color: colors.accent,
                            '&:hover': {
                              color: '#6a9082',
                            },
                          }}
                        >
                          <DriveFileRenameOutlineIcon style={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ContainerRow>
                  {stageDetails && (
                    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" rowGap="15px" columnGap="50px">
                      <PatientField label="No. of Services" value={stageDetails.service_count} />
                      <PatientField
                        label="Total Amount"
                        value={stageDetails?.total_price ? formatCurrency(stageDetails.total_price) : formatCurrency(0)}
                      />
                      {activeStep > 0 && (
                        <PatientField
                          label={`Days From ${stages[activeStep - 1].stage_name}`}
                          value={currentStage.days_after_prev_stage}
                        />
                      )}
                    </Box>
                  )}

                  {/* {stages && activeStep !== 0 && (
                      <>
                        <Typography variant="h3" sx={{ fontSize: '12px', color: colors.primary }}>
                          Days From Last Session
                        </Typography>

                        <Typography variant="h3" sx={{ fontSize: '12px' }}>
                         {stages[activeStep].days_after_prev_stage}
                        </Typography>
                      </>
                    )} */}

                  <Box></Box>
                </Box>
              </Box>
            ) : (
              <> </>
            )}
          </Box>

          <Box marginTop="20px">
            <ColoredTable
              ref={tableRef}
              getData={getStageServices}
              handleSelectRow={handleSelectRow}
              rowHeight={20}
              rowBackgroundColor={'none'}
              afterSearchbarComponent={
                <ContainerRow sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <RegularButton
                    startIcon={<AddIcon />}
                    label="Add Services"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSelectedService(undefined);
                      setOpenAddServiceModal(true);
                    }}
                  />
                </ContainerRow>
              }
              columns={[
                {
                  field: 'service_name',
                  headerName: 'Service Name',
                  flex: 1,
                },
                {
                  field: 'clinic_price',
                  headerName: 'Price',
                  flex: 1.5,
                  renderCell: (params) => (
                    <Typography sx={{ fontWeight: '700', color: colors.blueAccent }}>
                      {formatCurrency(params.row.clinic_price)}
                    </Typography>
                  ),
                },
                {
                  field: 'action',
                  sortable: false,
                  headerName: 'Actions',
                  headerAlign: 'center',
                  align: 'center',
                  flex: 1,
                  renderCell: (params) => (
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setStageToDelete(null);
                        setAlertContent('Are you sure you want to delete ' + params.row.service_name + ' ?');
                        setSelectedService(params.row);
                        setOpenDeleteDialog(true);
                      }}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <UpdateHealthPackageModal
        setRefresh={setRefresh}
        healthPackage={healthPackage}
        setUpdatedPackage={setUpdatedPackage}
        setChildHeader={setChildHeader}
        updateInitialValues={updatedPackage ? updatedPackage : updateInitialValues}
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
      />

      <CustomModal open={openStageModal} setOpen={setOpenStageModal} header={stageHeader}>
        <CustomForm
          onSubmit={handleNewStageSubmit}
          fields={stageFields}
          initialValues={toUpdate ? currentStage : initialValues}
          schema={
            toUpdate
              ? currentStage?.previous_stage === null
                ? health_package_stage_start_schema
                : health_package_stage_schema
              : health_package_stage_schema
          }
          loading={loading}
          submitButtonText={toUpdate ? 'Save' : 'Submit'}
        />
      </CustomModal>

      {currentStage && (
        <AddServices
          stage={currentStage}
          callbackAfterChanges={handleAddServiceCallback}
          openAddServicesModal={openAddServiceModal}
          setOpenAddServicesModal={setOpenAddServiceModal}
        />
      )}

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={stageToDelete ? handleDeleteStage : handleDeleteService}
      />

      <AddMultipleStages
        healthPackage={healthPackage}
        openAddMultipleStagesModal={openAddMultipleStagesModal}
        setOpenAddMultipleStagesModal={setOpenAddMultipleStagesModal}
        refreshStages={refreshStages}
        previousStage={stages.length > 0 ? stages[stages.length - 1] : null}
      />
    </LoadingScreen>
  );
};

export default NewViewHealthPackage;
