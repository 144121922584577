import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';

export interface LabRequestModel {
  id: number;
  company_id: number;
  doctor_id: number;
  appointment_id: number;
}

export const lab_request_schema = yup.object().shape({
  doctor_id: yup.string().required('Doctor is required.'),
});

export const LAB_REQUEST_ENTITY_NAME = 'Lab Request';
export const labRequestFields: EntityFields[] = [
  { fieldName: 'doctor_id', displayName: 'Doctor', type: DROPDOWN_FIELD.SERVICE_PROVIDER, span: 4 },
  { fieldName: 'appointment_id', displayName: 'Appointment', type: 'select', options: [], span: 4 },
];
