/* eslint-disable react-hooks/exhaustive-deps */

import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { useContext, useEffect } from 'react';

import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { GridColDef } from '@mui/x-data-grid';
import { getAll } from 'carego-admin/api/carego-billing-services';

const CareGoBillingServices = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  
  const columns: GridColDef[] = [
    { field: 'product_name', headerName: 'Product Name', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { field: 'price', headerName: 'Price', flex: 2 },
    { field: 'tax_rate', headerName: 'Tax Rate', flex: 2 },
  ];

  const getData = async (query: ApiQuery) => {
    return getAll(query);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Billing Services' }]);
  }, []);


  const content = {
    type: BLOCK_TYPE.PAGE,
    header: "Billing Services"
,    block: {
        type: BLOCK_TYPE.CONTAINER,
        block: {
            type: BLOCK_TYPE.TABLE,
            entityName: "Billing Service",
            doNotWaitForFacility: true,
            tableComponent: {
                columns: columns,
                getData: getData,
            }
        } as PageBuilderTableType,
      } as PageBuilderContainerType,
    } as PageBuilderPageType;

  
  return <PageBuilder content={content} />
};

export default CareGoBillingServices;
