import { ApiQuery } from 'core/model/interface';
import { CompanyFacilityModel } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'facilities';

export const getFacility = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFacilityBookingDetails = (facility_code: string) => {
  return axios.get(`${baseURL}/${path}/booking/${facility_code}`);
};

export const getCompanyFacilities = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const getClinicsWithoutServiceId = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/service/${id}`);
};

export const getClinicsWithoutProviderId = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/provider/${id}`);
};

export const getClinicsWithoutPackageId = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/package/${id}`);
};

export const getClinicsWithoutServicePackageId = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/service-package/${id}`);
};

export const updateClinicSchedules = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/schedules/${id}`, data);
};

export const updateClinic = (id: number, data: CompanyFacilityModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};
