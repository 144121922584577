import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'credit-request';

export const getCreditRequests = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const updateCreditRequests = (id: number, status: string) => {
    return axios.put(`${baseURL}/${path}/${id}/${status}`);
};

export const deleteCreditRequests = (id: number) => {
    return axios.delete(`${baseURL}/${path}/${id}`);
};