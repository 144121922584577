import {
  SERVICE_PROVIDER_ENTITY_NAME,
  ServiceProviderModel,
  serviceProviderFields,
  serviceProviderTableColumns,
} from './ServiceProviderModel';
import { deleteDoctor, getDoctors } from 'company/api/doctors';
import { forwardRef, useRef, useState } from 'react';

import ServiceProviderForm from './ServiceProviderForm';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow?: (data: ServiceProviderModel) => void;
  drawerSelectedRow?: ServiceProviderModel;
  tableRef?: any;
};

const ServiceProviderTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<ServiceProviderModel>();
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleFormInitialValuesProcessing = (data: any) => {
    return data;
  };

  const handleDelete = (id: number) => {
    return deleteDoctor(id);
  };

  return (
    <TemplateTable
      tableComponent={{
        mobileViewConfig: () => ({
          title_fields: ['full_name'],
          subtitle_fields: ['username'],
        }),
      }}
      ref={mainRef}
      entityName={SERVICE_PROVIDER_ENTITY_NAME}
      fields={serviceProviderFields}
      visibleColumns={visibleColumns ?? serviceProviderTableColumns}
      templateForm={<ServiceProviderForm serviceProvider={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
      processFormInitialValues={handleFormInitialValuesProcessing}
      handleDelete={handleDelete}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      drawerSelectedRow={drawerSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      getData={getDoctors}
    />
  );
});

export default ServiceProviderTable;
