/* eslint-disable react-hooks/exhaustive-deps */

import {
  PNT_ENTITY_NAME,
  createPatientNoteSchema,
  patientNoteTemplateFields,
  patientNoteTemplateFormFields,
  patientNoteTemplateInitialValues,
} from './PatientNoteTemplateModel';
import { createTemplate, updateTemplate } from 'company/api/note-templates';
import { useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { getCollections } from 'lgu/api/setup';

type Props = {
  collections?: any[];
  template?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PatientNoteTemplateForm = (props: Props) => {
  const { template, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const [collectionsOptions, setCollectionsOptions] = useState<any[]>([]);

  useEffect(() => {
    if (collectionsOptions) {
      setDropdownOptions({
        collection_id:
          collectionsOptions &&
          collectionsOptions.map((u: any) => {
            return { key: u.name, value: u.id };
          }),
      });
    }
  }, [collectionsOptions]);

  const fetchCollections = () => {
    getCollections().then((collections) => {
      setCollectionsOptions(collections.data);
    });
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    if (template && collectionsOptions) {
      let selectedCollection = collectionsOptions.find((u) => u.name === template.collection_name);

      selectedCollection && (template.collection_id = selectedCollection.id);
    }
  }, [template]);

  const handleSubmit = async (data: any) => {
    return template ? updateTemplate(data, data.id) : createTemplate(data);
  };

  return (
    collectionsOptions && (
      <TemplateForm
        entityName={PNT_ENTITY_NAME}
        fields={patientNoteTemplateFields}
        visibleFields={patientNoteTemplateFormFields}
        dropdownOptions={dropdownOptions}
        entity={template}
        initialValues={template ? changeNullToBlank(template) : patientNoteTemplateInitialValues}
        schema={createPatientNoteSchema}
        showSubmitAtTop={showSubmitAtTop}
        readOnly={readOnly}
        formSubmitApiFunction={handleSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
      />
    )
  );
};

export default PatientNoteTemplateForm;
