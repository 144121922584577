/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomTable, Header } from 'core/components';
import { declineAdditionalSmsTemplate, getAdditionalSmsTemplates } from 'carego-admin/api/sms-templates';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { additionalTemplatesColumns } from '../../utils/table-columns';
import { useSnackbar } from 'notistack';

const AdditionalSmsTemplates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const [template, setTemplate] = useState<any>();
  const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [firstLoad, setFirstLoad] = useState<number>(0);

  const getTemplates = (query: ApiQuery) => {
    setLoading(true);
    getAdditionalSmsTemplates(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const addActionToTable = () => {
    if (!additionalTemplatesColumns.find((col) => col.field === 'action')) {
      additionalTemplatesColumns.push({
        field: 'action',
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                showTemplateDeleteDialog('Are you sure you want to delete template ' + params.row.template_name + '?');
                setTemplate(params.row);
              }}
            >
              Delete
            </Button>
          </Box>
        ),
      });
    }
  };

  const showTemplateDeleteDialog = (content: string) => {
    setOpenTemplateDialog(true);
    setAlertContent(content);
  };

  const handleDeleteTemplate = () => {
    declineAdditionalSmsTemplate(template.id)
      .then(() => {
        enqueueSnackbar('Template successfully deleted!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (firstLoad === 0) {
      addActionToTable();
      setFirstLoad(firstLoad + 1);
    }
  }, [firstLoad]);

  return (
    <Box marginTop={'50px'}>
      <Header title="Additional LGU SMS Templates" />
      <CustomContainer>
        {firstLoad > 0 && (
          <CustomTable
            columns={additionalTemplatesColumns}
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            rowId={'id'}
            getData={getTemplates}
            forceRefresh={forceRefresh}
            showRefreshButton
          />
        )}
      </CustomContainer>

      <ConfirmationDialog
        open={openTemplateDialog}
        setOpen={setOpenTemplateDialog}
        content={alertContent}
        onConfirm={handleDeleteTemplate}
      />
    </Box>
  );
};

export default AdditionalSmsTemplates;
