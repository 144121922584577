import { useContext, useEffect, useState } from 'react';

import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
// import React from 'react'
import { CareGoPage } from 'core/PageBuilder';
import ClinicTracker from '../Clinic Tracker';
import CustomTabs from 'core/components/CustomTabs';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import MockDashboard from '../MockDashboard';
import { TabProps } from 'core/components/CustomTabs';

const tabs: TabProps[] = [
  {
    label: 'Monthly Clinic Report',
    content: <MockDashboard />,
    icon: <BarChartTwoToneIcon />,
  },
  {
    label: 'Clinic Tracker',
    content: <ClinicTracker />,
    icon: <MedicalInformationOutlinedIcon />,
  },
  {
    label: 'DOLE Forms',
    content: <Box>DOLE Forms</Box>,
    icon: <FactCheckOutlinedIcon />,
  },
];

export default function Dashboard() {
  const [selected, setSelected] = useState<number>(0);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([{ label: tabs[selected].label }]);
  }, [selected]);

  return (
    <CareGoPage header="Dashboard">
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </CareGoPage>
  );
}
