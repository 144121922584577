import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import React from 'react';
import { tokens } from 'theme/theme';

interface CustomDashboardWidgetProps {
  icon?: React.ComponentType<any>;
  label: string;
  count: string;
  sx?: SxProps<Theme>;

  iconBackgroundColor?: string; // default is primary.main
}

const CustomDashboardWidget: React.FC<CustomDashboardWidgetProps> = ({
  icon: IconComponent,
  label,
  iconBackgroundColor,
  count,
  sx,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        width: '100%',
        // height: '120px',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '16px',
        color: 'white',
        borderRadius: '12px',
        backgroundColor: colors.accent,
        ...sx,
      }}
    >
      {IconComponent && !isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: iconBackgroundColor ?? colors.secondary,
            height: '45px',
            width: '45px',
            borderRadius: '10px',
          }}
        >
          <IconComponent sx={{ fontSize: '32px' }} />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          marginLeft: '12px',
        }}
      >
        <Typography variant="h6" paddingBlock={'3px'} paddingTop={'0'}>
          {label}
        </Typography>
        <Typography variant="h3" fontWeight="bold">
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomDashboardWidget;
