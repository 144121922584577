/* eslint-disable react-hooks/exhaustive-deps */

import { ConfirmationDialog, CustomGridCell, CustomTable, PrimaryButton, RowAction } from 'core/components';
import { deactivate, exportCompanyFacilities, getCompanyFacilities, reactivate } from 'carego-admin/api/facility';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CompanyModel } from 'company/model/Entities';
import CreateFacility from './CreateFacility';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityModel } from 'core/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UpdateFacility from './UpdateFacility';
import { changeNullToBlank } from 'core/utils';
import { getLanguages } from 'carego-admin/api/lgu';
import { useSnackbar } from 'notistack';

type CompanyFacilityProps = {
  company: CompanyModel;
};

const CompanyFacility: React.FC<CompanyFacilityProps> = ({ company }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [refreshFacilities, setRefreshFacilities] = useState(0);

  const [facility, setFacility] = useState<FacilityModel>();
  const [languages, setLanguages] = useState<any>();

  const [openCreateFacilityModal, setOpenCreateFacilityModal] = useState<boolean>(false);

  //create update
  const [openUpdateFacilityModal, setOpenUpdateFacilityModal] = useState<boolean>(false);

  //update
  const [updateInitialValues, setUpdateInitialValues] = useState<any>();

  //deactivate or reactivate
  const [openUpdateFacilityStatusDialog, setOpenUpdateFacilityStatusDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [facilityStatus, setFacilityStatus] = useState<number>(1);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'facility_name', headerName: 'Facility Name', flex: 1 },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {[params.row.address, params.row.municipality_name, params.row.province_name, params.row.region_name].join(
            ', '
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'enrolled_patients',
      headerName: 'Patients Enrolled',
      flex: 0.5,
      type: 'number',
    },
    {
      field: 'unique_sms',
      headerName: 'Unique SMS',
      flex: 0.5,
      type: 'number',
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <div onClick={() => setFacilityStatus(params.row.is_active)}>
          <RowAction actions={actions} data={params.row} />
        </div>
      ),
    },
  ];

  const updateFacility = (data: any) => {
    const value = changeNullToBlank(data);
    setFacility(value);
    setOpenUpdateFacilityModal(true);
    setUpdateInitialValues({
      facility_name: value.facility_name,
      primary_language: value.primary_language,
      region_name: value.region_name,
      province_name: value.province_name,
      municipality_name: value.municipality_name,
      address: value.address,
      smart_mobile_number: value.smart_mobile_number,
      globe_mobile_number: value.globe_mobile_number,
    });
  };

  const updateFacilityStatus = (data: any) => {
    if (data.is_active === 1) {
      showAlertDialog('Are you sure you want to deactivate ' + data.facility_name + '?');
    } else {
      showAlertDialog('Are you sure you want to reactivate ' + data.facility_name + '?');
    }
    setFacility(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenUpdateFacilityStatusDialog(true);
    setAlertContent(content);
  };

  const actions = [
    {
      label: 'Update Facility',
      action: updateFacility,
      icon: <EditOutlinedIcon />,
    },
    {
      label: facilityStatus === 1 ? 'Deactivate' : 'Reactivate',
      action: updateFacilityStatus,
      icon: facilityStatus === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
  ];

  const handleFacilityDeactivation = () => {
    setLoading(true);
    deactivate(facility!.id)
      .then((res: any) => {
        enqueueSnackbar('Facility successfully deactivated!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const handleFacilityReactivation = () => {
    setLoading(true);
    reactivate(facility!.id)
      .then((res: any) => {
        enqueueSnackbar('Facility successfully reactivated!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const getFacilities = (query: ApiQuery) => {
    setLoading(true);
    getCompanyFacilities(company.id, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const refreshTable = () => {
    setRefreshFacilities((prev: number) => prev + 1);
  };

  const getLanguageList = () => {
    getLanguages()
      .then((res) => {
        setLanguages(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    setRefreshFacilities((prev: number) => prev + 1);
  }, [company.id]);

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <Box>
      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        getData={getFacilities}
        rowId={'id'}
        forceRefresh={refreshFacilities}
        exportRecords={exportCompanyFacilities}
        headerComponent={
          <PrimaryButton
            onClick={() => {
              setOpenCreateFacilityModal(true);
            }}
            label="Create Facility"
          />
        }
      />

      <CreateFacility
        open={openCreateFacilityModal}
        setOpen={setOpenCreateFacilityModal}
        refreshTable={refreshTable}
        company={company}
        languages={languages}
      />

      <UpdateFacility
        open={openUpdateFacilityModal}
        setOpen={setOpenUpdateFacilityModal}
        refreshTable={refreshTable}
        languages={languages}
        facility={facility!}
        initialValues={updateInitialValues}
      />

      <ConfirmationDialog
        open={openUpdateFacilityStatusDialog}
        setOpen={setOpenUpdateFacilityStatusDialog}
        content={alertContent}
        onConfirm={facilityStatus === 1 ? handleFacilityDeactivation : handleFacilityReactivation}
        confirmButtonColor={facilityStatus === 1 ? false : true}
      />
    </Box>
  );
};

export default CompanyFacility;
