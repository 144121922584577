import { Box, Typography } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { formatDate, tranformFormErrors } from 'core/utils';
import { startNewCycle, updateProgramRemindersPatient } from 'lgu/api/program-reminders-patients';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { family_planning_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type FpUpdateModalProps = {
  header: string;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  type: 'update' | 'new cycle';
  patient?: any;
  selectedPatientIDs?: any[];
  refreshTable: () => void;
};

export type UserInput = {
  date_started: string;
  sending_time: string;
  ocp_count: number | string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const FpUpdateModal: React.FC<FpUpdateModalProps> = ({
  header,
  open,
  setOpen,
  patient,
  selectedPatientIDs,
  refreshTable,
  type,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    date_started: patient ? (type === 'update' ? patient.date_started : patient.next_restock) : '',
    sending_time: patient ? patient.sending_time.slice(0, 5) : '',
    ocp_count: patient ? patient.ocp_count : '',
  };

  const scheduleFields: UserFieldInput[] = [
    {
      field_name: 'ocp_count',
      display_name: 'Program',
      type: 'select',
      options: [
        { key: 'OCP-21', value: 21 },
        { key: 'OCP-28', value: 28 },
      ],
    },
    { field_name: 'sending_time', display_name: 'SMS Reminder Time', type: 'time', minutesStep: 5 },
    { field_name: 'date_started', display_name: 'Patient Start Date', type: 'date' },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    const patientList = JSON.stringify(patient ? [patient.id] : selectedPatientIDs);

    const patientData = {
      patients: patientList,
      date_started: data.date_started,
      sending_time: data.sending_time,
      ocp_count: data.ocp_count,
    };

    if (type === 'update') {
      updateProgramRemindersPatient('ocp', patientData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
    } else {
      startNewCycle(patientData)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient schedule successfully updated!`, { variant: 'success' });
      refreshTable();
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  return (
    <>
      <CustomModal header={header} open={open} setOpen={setOpen}>
        <Box marginBottom={'20px'} marginTop={'0'}>
          <Typography variant="h4">{patient ? patient.full_name : ''}</Typography>
          <Typography variant="h6">{patient ? 'Restock Deadline: ' + formatDate(patient.next_restock) : ''}</Typography>
        </Box>
        <CustomForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          fields={scheduleFields}
          schema={family_planning_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default FpUpdateModal;
