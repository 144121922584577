import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { updateBarangayAssignment } from 'lgu/api/health-workers';
import { update_bhw_assigment_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type MultipleAssignProps = {
  open: boolean;
  setRefreshAssigned: React.Dispatch<React.SetStateAction<number>>;
  setOpen: (openModal: boolean) => void;
  barangays: any;
  healthworkers: any;
};

type UserInput = {
  healthworker: string | number;
  barangay_codes: any[];
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  healthworker: '',
  barangay_codes: [],
};

const MultipleAssign: React.FC<MultipleAssignProps> = ({
  setRefreshAssigned,
  open,
  setOpen,
  barangays,
  healthworkers,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const assignmentFields: UserFieldInput[] = [
    {
      field_name: 'barangay_codes',
      display_name: 'Barangays',
      type: 'multiselect',
      options: barangays.map((brgy: any) => {
        return { key: brgy.barangay_name, value: brgy.barangay_code };
      }),
    },
    {
      field_name: 'healthworker',
      display_name: 'Barangay Healthworker',
      type: 'select',
      options: healthworkers.map((healthworker: any) => {
        return { key: healthworker.full_name, value: healthworker.id };
      }),
    },
  ];

  const handleSubmit = (data: any) => {
    setLoading(true);

    var barangayCodes = data.barangay_codes.map((brgy: { value: any }) => brgy.value);

    updateBarangayAssignment(data.healthworker, JSON.stringify(barangayCodes))
      .then((res) => {
        enqueueSnackbar('Barangay Health Worker successfully assigned!', { variant: 'success' });
        setLoading(false);
        setRefreshAssigned((prev: number) => prev + 1);
        setOpen(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <CustomModal width={600} header="Assign Barangays" open={open} setOpen={setOpen}>
      <CustomForm
        onSubmit={handleSubmit}
        fields={assignmentFields}
        schema={update_bhw_assigment_schema}
        initialValues={initialValues}
        loading={loading}
        span={1}
      />
    </CustomModal>
  );
};

export default MultipleAssign;
