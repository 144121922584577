import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Reg2: React.FC = () => {
  return (
    <Box>
      <Step>1. In Patient Management tab, click the dropdown button</Step>

      <Image src="/assets/FaqAssets/clientreg/2/1.png" alt="Image" />

      <Step>2. Click “Update” button in the dropdown options.</Step>
      <Image src="/assets/FaqAssets/clientreg/2/2.png" alt="Image" />
      <Step>3. Fill out the form and click the “Submit” button</Step>
      <Image src="/assets/FaqAssets/clientreg/2/3.png" alt="Image" />
      <Image src="/assets/FaqAssets/clientreg/2/3.1.png" alt="Image" />
    </Box>
  );
};

export default Reg2;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
