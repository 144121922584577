import * as yup from 'yup';

import { Box, Typography } from '@mui/material';
import { DISCOUNT_TYPE, DROPDOWN_FIELD } from 'core/model/interface';

import { EntityFields } from '../../../../utils';
import { SIZES } from 'theme/constants';
import { formatCurrency } from 'core/utils';

export const INVOICE_ITEM_ENTITY_NAME = 'Item';

export const invoiceItemSchema = yup.object().shape({
  patient_id: yup.number().required('Patient is required.'),
  issue_date: yup
    .date()
    .default(() => new Date())
    .required('Date is required.')
    .typeError('Invalid Date'),
});

export interface InvoiceItemModel {
  id: number;
  invoice_id: number;
  service_id?: number;
  variant_id?: number;
  quantity: number;
  unit_price: number;
  total_price: number;
  date_availed: number;
  included_services?: string;
}

// fieldName should be unique
export const invoiceItemFields: EntityFields[] = [
  {
    fieldName: 'item_name',
    displayName: 'Items',
    flex: 1.5,
    renderCell: (params) => (
      <Box display="flex" flexDirection="column">
        <Typography>{params.row.item_name}</Typography>
        {params.row.included_services && (
          <Typography fontSize="12px" color="text.secondary" sx={{}}>
            Includes: {params.row.included_services}
          </Typography>
        )}
      </Box>
    ),
  },
  { fieldName: 'quantity', displayName: 'Quantity', type: 'number' },
  {
    fieldName: 'unit_price',
    displayName: 'Unit Price × Quantity',
    flex: 3,
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => (
      <Box display="flex" gap="5px" justifyContent="flex-end" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box display="flex" gap={SIZES.padding}>
            <Typography>{formatCurrency(params.row.original_price ?? params.row.unit_price)}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="5px">
            {params.row.discount_type === DISCOUNT_TYPE.NEW_UNIT_PRICE && (
              <Typography
                fontWeight="500"
                fontSize="13px"
                sx={{
                  backgroundColor: 'grey',
                  color: 'white',
                  paddingInline: SIZES.paddingS,
                  marginLeft: SIZES.paddingS,
                  borderRadius: '10px',
                }}
              >
                {formatCurrency(params.row.original_price - params.row.unit_price)} OFF
              </Typography>
            )}
            {params.row.discount_type === DISCOUNT_TYPE.PERCENTAGE && (
              <Typography
                fontWeight="500"
                fontSize="13px"
                sx={{
                  backgroundColor: 'grey',
                  color: 'white',
                  paddingInline: SIZES.paddingS,
                  marginLeft: SIZES.paddingS,
                  borderRadius: '10px',
                }}
              >
                {params.row.discount_percentage}% OFF
              </Typography>
            )}
            {!!params.row.sc_pwd_discounted && (
              <Box display="flex" alignItems="center">
                <Typography
                  fontWeight="500"
                  fontSize="13px"
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    paddingInline: SIZES.paddingS,
                    borderRadius: '10px',
                  }}
                >
                  20% OFF - SC/PWD
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Typography>× {params.row.quantity}</Typography>
      </Box>
    ),
  },
  {
    fieldName: 'total_price',
    displayName: 'Total Amount',
    type: 'currency',
  },
  {
    fieldName: 'service_id',
    displayName: 'Service',
    type: DROPDOWN_FIELD.SERVICE,
  },
  {
    fieldName: 'product_id',
    displayName: 'Product',
    type: DROPDOWN_FIELD.PRODUCT_VARIANT,
  },
  {
    type: 'table',
    fieldName: 'services',
    displayName: 'Services',
    columns: [
      {
        fieldName: 'service',
        displayName: 'Service',
        type: DROPDOWN_FIELD.SERVICE,
        onCellChange: (value, row, handleRowEdit) => {
          handleRowEdit(row, 'unit_price', value ? value.unit_price ?? value.data.price : '');
          handleRowEdit(row, 'quantity', value ? '1' : '');
        },
      },
      { fieldName: 'quantity', displayName: 'Quantity' },
      { fieldName: 'unit_price', displayName: 'Unit Price' },

      // {
      //   fieldName: 'discount_type',
      //   displayName: 'Discount Type',
      //   type: 'select',
      //   options: [
      //     { key: 'None', value: 'none' },
      //     { key: 'Discount Amount', value: DISCOUNT_TYPE.NEW_UNIT_PRICE },
      //     { key: 'Discount Percentage', value: DISCOUNT_TYPE.PERCENTAGE },
      //   ],
      // },
      // {
      //   fieldName: 'unit_price',
      //   displayName: 'Discount Amount',
      //   hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.NEW_UNIT_PRICE,
      // },
      // {
      //   fieldName: 'percentage',
      //   displayName: 'Discount Percentage',
      //   hiddenBasedOnOtherField: (data) => data.discount_type !== DISCOUNT_TYPE.PERCENTAGE,
      // },
    ],
  },
  {
    type: 'table',
    fieldName: 'products',
    displayName: 'Products',
    columns: [
      {
        fieldName: 'product',
        displayName: 'Product',
        type: DROPDOWN_FIELD.PRODUCT_VARIANT,
        onCellChange: (value, row, handleRowEdit) => {
          handleRowEdit(row, 'unit_price', value ? value.unit_price ?? value.data.price : '');
          handleRowEdit(row, 'quantity', value ? '1' : '');
        },
      },
      { fieldName: 'quantity', displayName: 'Quantity' },
      { fieldName: 'unit_price', displayName: 'Unit Price' },
    ],
  },
];

// Table
export const invoiceItemTableColumns: string[] = ['item_name', 'unit_price', 'total_price'];

//Form
export const invoiceItemFormFields: string[] = ['services', 'products'];

export const updateInvoiceItemFormFields: string[] = ['unit_price', 'quantity'];

export const updateInvoiceItemInitialValues = {
  unit_price: 0.0,
  quantity: 1,
};

export const invoiceItemInitialValues = {
  services: [
    {
      service: '',
      unit_price: 0.0,
      quantity: 1,
    },
  ],
  products: [
    {
      product: '',
      unit_price: 0.0,
      quantity: 1,
    },
  ],
};
