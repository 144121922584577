import { getAllAppointmentLegends, getLegend } from 'company/api/appointment-legends';
import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { FacilityContext } from 'core/context/facility.context';

import { ApiQuery } from 'core/model/interface';
import { useContext } from 'react';

const AppointmentLegendSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  const getData = (query: ApiQuery) => {
    return getAllAppointmentLegends(facility.id, query);
  };

  function getSingleData(id: number) {
    return getLegend(facility.id, id);
  }
  return (
    <TemplateDropdown
      {...props}
      entity={'Appointment Legend'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      processOption={(record) => ({ key: record.tag_name, value: record.id, data: record })}
      // processNewRecord={(record) => record.appointment_legend}
    />
  );
};

export default AppointmentLegendSearchDropdown;
