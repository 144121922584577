import { calculateAge, formatDate } from 'core/utils';

import { CustomGridCell } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';

export const enrolledColumns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1.5,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.first_name} {params.row.last_name}
      </CustomGridCell>
    ),
  },
  { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
  {
    field: 'birthday',
    headerName: 'Birthday',
    type: 'Date',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
  },
  { field: 'sex', headerName: 'Sex Assigned At Birth', flex: 1 },
  { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
];

export const notEnrolledColumns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1.5,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.first_name} {params.row.last_name}
      </CustomGridCell>
    ),
  },
  { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
  {
    field: 'birthday',
    headerName: 'Birthday',
    type: 'Date',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
  },
  { field: 'sex', headerName: 'Sex Assigned At Birth', flex: 1 },
  { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
];
