import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-programs';

export const getProgram = (program_code: string) => {
  return axios.get(`${baseURL}/${path}/details/${program_code}`);
};

export const getPrograms = (company: number) => {
  return axios.get(`${baseURL}/${path}/${company}`);
};
