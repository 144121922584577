import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-company-programs';

export const getAvailablePrograms = (company: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/available-programs/${company}`, { params: query });
};

export const getCompanyHealthPrograms = (company: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${company}`, { params: query });
};

export const getCompanyHealthProgram = (program_code: string) => {
  return axios.get(`${baseURL}/${path}/details/${program_code}`);
};

export const getProgramCount = () => {
  return axios.get(`${baseURL}/${path}/program-count`);
};

export const addCompanyHealthProgram = (company: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${company}`, data);
};

export const updateCompanyHealthProgram = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteCompanyHealthProgram = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getPrograms = () => {
  return axios.get(`${baseURL}/${path}/programs`);
};
