import { useTheme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

interface Props {
  title: string;
  legendHidden?: boolean;
}

const AnalyticsLineChart: React.FC<Props> = ({ title, legendHidden }) => {
  const theme = useTheme();
  return (
    <Box p={2} borderRadius={'10px'} border={`1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`}>
      <Box p={1}>
        <Typography align="center" variant="h4" gutterBottom>
          {title}
        </Typography>
        <LineChart height={300} width={legendHidden ? 940 : 840} series={[]} />
      </Box>
    </Box>
  );
};

export default AnalyticsLineChart;
