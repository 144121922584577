import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';

export const EXPENSE_LINE_ITEM_ENTITY_NAME = 'Expense Line Item';

export interface ExpenseLineItemModel {
  id: number;
  company_id: number;
  facility_id: number;
  expense_id: number;
  line_number: number;
  expense_type: number;
  description: string;
  amount: number;
  is_active: number;
}

export const createExpenseLineItemModelSchema = yup.object().shape({
  expense_type: yup.number().required('Expense Type is required'),
  amount: yup.number().required('Amount is required'),
});

export const expenseLineItemFields = [
  {
    type: 'table',
    fieldName: 'line_items',
    displayName: 'Expenses',
    columns: [
      { fieldName: 'expense_type', displayName: 'Expense Type', type: DROPDOWN_FIELD.EXPENSE_TYPE },
      { fieldName: 'description', displayName: 'Description' },
      { fieldName: 'amount', displayName: 'Amount' },
    ],
  },
];

export const visibleExpenseLineItemColumns = ['expense_type', 'description', 'amount'];
export const expenseLineItemFormFields = ['expense_type', 'description', 'amount'];
export const expenseLineItemInitialValues = {
  line_items: [
    {
      expense_type: '',
      description: '',
      amount: 0,
    },
  ],
};
