import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AppoinArticle4: React.FC = () => {
  return (
    <Box>
      <Step>
        1. In the appointments tab, select a patient you want to cancel the schedule, click the drop down icon.
      </Step>
      <Image src="/assets/FaqAssets/Appointment/4/1.png" alt="Step 1 Image" />
      <Step>2. Click the “Cancel” option.</Step>
      <Image src="/assets/FaqAssets/Appointment/4/2.png" alt="Step 2 Image" />

      <Step>
        3. Fill out the form, you can also check the checkbox named “Send SMS to client” for the patient to be informed
        about the Appointment cancellation and click “Submit”
      </Step>
      <Image src="/assets/FaqAssets/Appointment/4/3.png" alt="Step 3 Image" />
    </Box>
  );
};

export default AppoinArticle4;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
