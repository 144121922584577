import { Box, TextField, useTheme } from '@mui/material';
import { CustomInlineForm, SubmitButton } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { createLguSMSTemplates } from 'lgu/api/sms';
import { insert_variable_form } from 'lgu/model/schema';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type NewMessageTemplateModalProps = {
  setOpen: (openModal: boolean) => void;
};

type InsertInput = {
  insert_variable: string;
};

interface InsertFieldInput extends FieldInput {
  field_name: keyof InsertInput;
}

const insertVariableValue: InsertInput = {
  insert_variable: '',
};

const NewMessageTemplateModal: React.FC<NewMessageTemplateModalProps> = ({ setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [templateBody, setTemplateBody] = useState<string>('');
  const [templateName, setTemplateName] = useState<string>('');

  const insertVariableField: InsertFieldInput[] = [
    {
      field_name: 'insert_variable',
      display_name: 'Variable to Insert',
      type: 'select',
      options: [
        { key: 'Patient Name', value: '[PatientName]' },
        { key: 'Program Name', value: '[ProgramName]' },
        { key: 'Venue', value: '[LocationName]' },
        { key: 'Date & Time', value: '[ProgramDate]' },
        { key: 'Barangay', value: '[BarangayName]' },
        { key: 'LGU', value: '[LGU]' },
      ],
    },
  ];

  const insertVariable = (data: any) => {
    setTemplateBody(templateBody + data.insert_variable);
  };

  const handleTemplateBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTemplateBody(event.target.value);
  };

  const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTemplateName(event.target.value);
  };

  const createMessageTemplate = (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitLoading(true);
    setSubmitDisabled(true);
    event.preventDefault();

    const template = {
      template_name: templateName,
      template_body: templateBody,
      language_id: 1,
    };

    createLguSMSTemplates(template)
      .then(() => {
        enqueueSnackbar(`New Template successfully created!`, { variant: 'success' });
        setSubmitLoading(false);
        setSubmitDisabled(false);
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setSubmitLoading(false);
        setSubmitDisabled(false);
      });
  };

  return (
    <>
      <Box marginBottom={'30px'}>
        <CustomInlineForm
          onSubmit={insertVariable}
          fields={insertVariableField}
          schema={insert_variable_form}
          initialValues={insertVariableValue}
          disabled={false}
          loading={false}
          submitButtonText={'Insert'}
        ></CustomInlineForm>
      </Box>

      <form onSubmit={createMessageTemplate}>
        <Box marginBottom={'30px'}>
          <TextField
            fullWidth
            variant="filled"
            label={'Template Name'}
            name={'template_name'}
            onChange={handleTemplateNameChange}
            sx={{
              '.MuiFormLabel-root.Mui-focused': { color: colors.light },
              '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.main}` },
              '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
            }}
          />
        </Box>
        <Box marginBottom={'30px'}>
          <TextField
            fullWidth
            variant="filled"
            label={'Template Body'}
            value={templateBody}
            onChange={handleTemplateBodyChange}
            name={'template_body'}
            multiline
            rows={5}
            sx={{
              '.MuiFormLabel-root.Mui-focused': { color: colors.light },
              '.MuiFilledInput-root:after': { borderBottom: `1px solid ${colors.main}` },
              '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
            }}
          />
        </Box>

        <Box display="flex" justifyContent="end" mt={4}>
          <SubmitButton label="Submit" loading={submitLoading} disabled={submitDisabled} />
        </Box>
      </form>
    </>
  );
};

export default NewMessageTemplateModal;
