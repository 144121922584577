import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'medication-reminder';

interface CreateMedicationReminder {
  patient_id: number;
  prescription_line_id: number;
}

export const createOneMedicationReminder = (data: CreateMedicationReminder) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const cancelMedicationReminder = (prescriptionLineId: number) => {
  return axios.delete(`${baseURL}/${path}/${prescriptionLineId}`);
};
