interface Props {
  data: object;
}

const CustomJsonPrettier: React.FC<Props> = ({ data }) => {
  return (
    <pre
      style={{
        textWrap: 'wrap',
        fontFamily: 'monospace',
        fontSize: '12px',
      }}
    >
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};

export default CustomJsonPrettier;
