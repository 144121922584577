import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';
import { ServiceModel } from '../Service/ServiceModel';
import { Typography } from '@mui/material';
import { formatArray } from 'core/utils';

export const CLINIC_ENTITY_NAME = 'Clinic';

export interface CompanyTagsModel {
  id: number;
  company_id: number;
  facility_id: number;
  category_name: string;
  tag_name: string;
  created_by: number;
}

export interface ClinicModel {
  id: number;
  facility_id: number;
  company_id: number;
  facility_name: string;
  facility_short_name: string;
  facility_code: string;
  facility_type: string;
  sensitive_data: boolean;
  region: string;
  region_name: string;
  province: string;
  province_name: string;
  municipality: string;
  municipality_name: string;
  address: string;
  email: string;
  is_active: number;
  schedule: any;
  description: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  primary_language: number;
  services: ServiceModel[];
  banner_image: string;
}

export interface ClinicInput {
  facility_name: string;
  primary_language: string;
  region_name: string;
  province_name: string;
  municipality_name: string;
  address: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  banner_image: string;
  description: string;
  email: string;
}

export interface ClinicFieldInput extends FieldInput {
  field_name?: keyof ClinicInput;
}

export const clinicInitialValues: ClinicInput = {
  facility_name: '',
  primary_language: '',
  region_name: '',
  province_name: '',
  municipality_name: '',
  address: '',
  smart_mobile_number: '',
  globe_mobile_number: '',
  banner_image: process.env.PUBLIC_URL + '/assets/blank_banner.png',
  description: '',
  email: '',
};

// fieldName should be unique
export const clinicFields: EntityFields[] = [
  { fieldName: 'facility_name', displayName: 'Clinic Name' },
  { fieldName: 'facility_short_name', displayName: 'Clinic Short Name' },
  { fieldName: 'facility_code', displayName: 'Clinic Code' },
  { fieldName: 'region_name', displayName: 'Region' },
  { fieldName: 'province_name', displayName: 'Province / City' },
  { fieldName: 'municipality_name', displayName: 'Municipality' },
  {
    fieldName: 'address',
    displayName: 'Address',
    flex: 2,
    renderCell: (params) => {
      const { address, municipality_name, province_name, region_name } = params.row;
      return <Typography>{formatArray([address, municipality_name, province_name, region_name], ', ')}</Typography>;
    },
  },
  { fieldName: 'email', displayName: 'Email', type: 'email' },
  { fieldName: 'description', displayName: 'Introduce your clinic', type: 'rich_text' },
  { fieldName: 'smart_mobile_number', displayName: 'Mobile Number (Smart)' },
  { fieldName: 'globe_mobile_number', displayName: 'Mobile Number (Globe)' },
  {
    fieldName: 'contact_numbers',
    displayName: 'Contact Numbers',
    renderCell: (params) => {
      const { smart_mobile_number, globe_mobile_number } = params.row;
      return <Typography>{formatArray([smart_mobile_number, globe_mobile_number], ' / ')}</Typography>;
    },
  },
  { fieldName: 'primary_language', displayName: 'Primary Language', type: 'select', options: [] },
  { fieldName: 'banner_image', displayName: 'Banner', type: 'image_upload', isBanner: true },

  { fieldName: 'divider_1', type: 'divider' },
  {
    fieldName: 'component_clinic_loc',
    type: 'subsection_header',
    subsection_header: 'Clinic Location',
  },
  {
    fieldName: 'component_contact_details',
    type: 'subsection_header',
    subsection_header: 'Contact Details',
  },
];

// Table
export const clinicTableColumns: string[] = ['facility_name', 'facility_code', 'address', 'contact_numbers'];

// Forms
export const clinicFormFields: string[] = [
  'facility_name',
  'facility_short_name',
  'primary_language',

  'divider_1',
  'component_contact_details',
  'smart_mobile_number',
  'globe_mobile_number',
  'email',

  'divider_1',
  'component_clinic_loc',
  'region_name',
  'province_name',
  'municipality_name',
  'address',

  'divider_1',
  'banner_image',
  'description',
];
