import AppLayout from './components/AppLayout';
import { ReactNode } from 'react';
import Sidebar from './components/Sidebar';

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <AppLayout sidebar={<Sidebar />}>{children}</AppLayout>;
};

export default Layout;
