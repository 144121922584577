import { CustomContainer, Header } from 'core/components';

const About = () => {
  return (
    <CustomContainer>
      <Header title="About CareGo Health Suite" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        <img
          src={process.env.PUBLIC_URL + '/carego_health_suite_logo.png'}
          className="about_logo"
          alt="CareGo Logo"
          style={{ width: '30%', margin: '0px' }}
        />
      </div>

      <div
        className="aboutInfo"
        style={{
          display: 'block',
          fontSize: '16px',
          textAlign: 'justify',
          textJustify: 'inter-word',
        }}
      >
        <p
          style={{
            textIndent: '50px',
            margin: '0 100px',
            lineHeight: '30px',
          }}
        >
          CareGo Health Suite is an online SMS health nudge platform designed to help Rural Health Units to inform
          Filipinos about the availability of health programs.
        </p>

        <p
          style={{
            textIndent: '50px',
            margin: '15px 100px',
            lineHeight: '30px',
          }}
        >
          For questions or details please message us at
          <span style={{ color: 'teal' }}> tech@cphealthinnovations.com </span> .
        </p>
      </div>

      <div
        className="contactInfo"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          paddingTop: '50px',
        }}
      >
        <span style={{}}> CareGo Health Suite </span>
        <span style={{}}> by CP Health Innovations Inc. </span>
        <span style={{}}> U214-215, Commercenter building, </span>
        <span style={{}}>Commerce Avenue,Filinvest, Alabang, Muntinlupa City</span>
      </div>
    </CustomContainer>
  );
};

export default About;
