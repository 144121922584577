import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, HideOrShowComponent, RegularButton } from 'core/components';
import { deactivate, getUserAccount, getUserAccounts, getUserFacilities, reactivate } from 'company/api/users';
import { useContext, useEffect, useState } from 'react';

import { ClinicModel } from 'company/entities/modules/ClinicManagement/Clinic/ClinicModel';
import ClinicsTable from '../ClinicsTable';
import { ContainerRow } from 'core/components/containers';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FacilityContext } from 'core/context/facility.context';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { UserAccessForm } from 'company/entities/forms';
import { UserAccessModel } from 'company/entities/modules/ClinicManagement/UserAccess/UserAccessModel';
import UserGroup from 'company/screens/CompanyUserManagement/components/UserGroup';
import { formatArray } from 'core/utils';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type UserAccessProps = {
  selectedTab: number;
  forceRefresh: number;
  setForceRefresh: (forceRefresh: any) => void;
};

const UserAccess: React.FC<UserAccessProps> = ({ selectedTab, forceRefresh, setForceRefresh }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [selectedUserDetails, setSelectedUserDetails] = useState<UserAccessModel>();
  const [facilities, setFacilities] = useState<ClinicModel[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<'reactivate' | 'deactivate'>('reactivate');

  const [toUpdate, setToUpdate] = useState<any>();
  const [updatedUser, setUpdatedUser] = useState<any>();
  const [showUserGroups, setShowUserGroups] = useState(false);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const displayItem = (item: UserAccessModel) => {
    const isSelected = selectedUser === item.id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected && !isMobilePhone ? 'white' : 'black'} fontWeight="600">
          {item.full_name}
        </Typography>
      </ContainerRow>
    );
  };

  const getUserDetails = () => {
    if (selectedUser) {
      getUserAccount(selectedUser)
        .then((res) => {
          setSelectedUserDetails(res.data);
        })
        .catch();
    }
  };

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await getUserFacilities(selectedUser);
        setFacilities(response.data.data);
      } catch (error) {
        console.error('Error fetching user facilities:', error);
      }
    };

    fetchFacilities();
  }, [facility.company_id, forceRefresh, selectedUser]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  if (showUserGroups) return <UserGroup setShowUserGroups={setShowUserGroups} />;

  return (
    <InfiniteScrollContainer
      title="List of Users"
      urlKeyId="uid"
      getSingleData={(id) => getUserAccount(id)}
      getData={(query) => getUserAccounts(facility.company_id, query)}
      renderItem={displayItem}
      selected={selectedUser}
      setSelected={setSelectedUser}
      updatedItem={updatedUser}
      containerHeight="calc(100vh - 170px)"
      headerComponents={
        <Box width="100%">
          <RegularButton
            startIcon={<ManageAccountsOutlinedIcon />}
            label="User Groups "
            color="primary"
            variant="outlined"
            onClick={() => setShowUserGroups(true)}
            styles={{ width: '100%' }}
            fullWidth
          />
        </Box>
      }
      displaySelectedTitle={() => (
        <Typography variant="h3" fontWeight={700} color={colors.primary}>
          {selectedUserDetails ? selectedUserDetails.full_name : '-'}
          {selectedUserDetails && !selectedUserDetails.is_active && ' (Inactive)'}
        </Typography>
      )}
      actions={
        !isMobilePhone
          ? [
              {
                icon: <DriveFileRenameOutlineIcon />,
                label: 'Update',
                action: (data) => {
                  setOpenModal(true);
                  setToUpdate(selectedUserDetails);
                },
              },
              {
                label: 'Deactivate',
                icon: <DoNotDisturbIcon />,
                hidden: !(selectedUserDetails && selectedUserDetails.is_active === 1),
                action: () => {
                  setOpenConfirm(true);
                  setActiveType('deactivate');
                },
              },
              {
                label: 'Activate',
                icon: <ToggleOnIcon />,
                hidden: selectedUserDetails && selectedUserDetails.is_active === 1,
                action: () => {
                  setOpenConfirm(true);
                  setActiveType('reactivate');
                },
              },
            ]
          : undefined
      }
    >
      <Box paddingBottom="40px">
        <Typography sx={{ fontSize: '1rem', fontWeight: '600' }} paddingBottom={'20px'}>
          User Details
        </Typography>

        {selectedUserDetails && (
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px">
            <PatientField
              label="Clinics"
              value={
                facilities.length
                  ? formatArray(
                      facilities.map((facility) => facility.facility_name),
                      ', '
                    )
                  : 'No facilities available'
              }
              sx={{ gridColumn: 'span 3' }}
            />
            <PatientField label="First Name" value={selectedUserDetails.first_name} />
            <PatientField label="Middle Name" value={selectedUserDetails.middle_name} />
            <PatientField label="Last Name" value={selectedUserDetails.last_name} />
            <PatientField label="Mobile Number" value={selectedUserDetails.mobile_number} />
            <PatientField label="Email" value={selectedUserDetails.email} />
            <PatientField
              label="User Group"
              value={
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      borderRadius: '10px',
                      padding: '6px 15px',
                      backgroundColor: colors.lightBackground,
                      fontWeight: 'bold',
                      width: 'auto',
                      color: colors.accent,
                    }}
                  >
                    {selectedUserDetails?.group_name}
                  </Typography>
                </Box>
              }
              valueIsComponent
            />
          </Box>
        )}
      </Box>

      <ClinicsTable source="User Access" selectedUser={selectedUserDetails} />

      <CustomModal width={700} open={openModal} setOpen={setOpenModal} header={toUpdate ? 'Update User' : 'Add User'}>
        <UserAccessForm
          userAccess={selectedUserDetails}
          callbackAfterSubmit={(data) => {
            setUpdatedUser(data.company_user);
            setSelectedUserDetails(data.company_user);
            setOpenModal(false);
          }}
        />
      </CustomModal>
      <ConfirmationDialog
        content={`Are you sure you want to ${activeType} this account?`}
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={() => {
          const callbackAfterConfirm = () => {
            selectedUserDetails &&
              setSelectedUserDetails({
                ...selectedUserDetails,
                is_active: activeType === 'reactivate' ? 1 : 0,
              });
            enqueueSnackbar(`Account successfully ${activeType}d!`, { variant: 'success' });
            setOpenConfirm(false);
          };

          if (activeType === 'reactivate') reactivate(selectedUser).then(callbackAfterConfirm);
          if (activeType === 'deactivate') deactivate(selectedUser).then(callbackAfterConfirm);
        }}
      />
    </InfiniteScrollContainer>
  );
};

export default UserAccess;
