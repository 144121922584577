import { Box, Button } from '@mui/material';
import { ConfirmationDialog, CustomModal, CustomTable, PrimaryButton } from 'core/components';
import { deleteBarangayHealthworker, getBarangayHealthworkers } from 'lgu/api/health-workers';

import { ApiQuery } from 'core/model/interface';
import { BarangayHealthworkerModel } from 'lgu/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import HealthworkerForm from './HealthworkerForm';
import HealthworkerUpdateForm from './HealthworkerUpdateForm';
import { changeNullToBlank } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type HealthWorkersModalProps = {
  open: boolean;
  setOpen: (openModal: boolean) => void;
  barangays: any[];
  setRefreshAssignedTable: React.Dispatch<React.SetStateAction<number>>;
};

const HealthWorkersModal: React.FC<HealthWorkersModalProps> = ({
  open,
  setOpen,
  barangays,
  setRefreshAssignedTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddHealthworkerModal, setOpenAddHealthworkerModal] = useState<boolean>(false);
  const [openUpdateHealthworkerModal, setOpenUpdateHealthworkerModal] = useState<boolean>(false);
  const [healthworker, setHealthworker] = useState<BarangayHealthworkerModel>();
  const [openDeleteHealthworkerModal, setOpenDeleteHealthworkerModal] = useState<boolean>(false);
  const [refreshHealthworkers, setRefreshHealthworkers] = useState(0);

  const healthworkersColumns: GridColDef[] = [
    { field: 'full_name', headerName: 'Name', flex: 1 },
    { field: 'mobile_number', headerName: 'Mobile Number', type: 'Date', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setOpenUpdateHealthworkerModal(true);
              setHealthworker(changeNullToBlank(params.row));
            }}
          >
            Update
          </Button>

          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setHealthworker(params.row);
              setOpenDeleteHealthworkerModal(true);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  const getHealthworkers = (query: ApiQuery) => {
    setLoading(true);
    getBarangayHealthworkers(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleDeleteHealthworker = () => {
    deleteBarangayHealthworker(healthworker!.id)
      .then(() => {
        enqueueSnackbar('Health Worker successfully deleted!', { variant: 'success' });
        setOpenDeleteHealthworkerModal(false);
        refreshTable();
        refreshAssignedTable();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const refreshTable = () => {
    setRefreshHealthworkers((prev: number) => prev + 1);
  };

  const refreshAssignedTable = () => {
    setRefreshAssignedTable((prev: number) => prev + 1);
  };

  return (
    <>
      <CustomModal header="Barangay Health Workers" open={open} setOpen={setOpen} width={900}>
        <CustomTable
          columns={healthworkersColumns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getHealthworkers}
          tableHeight={400}
          forceRefresh={refreshHealthworkers}
          headerComponent={
            <PrimaryButton
              label="Add BHW"
              onClick={() => {
                setOpenAddHealthworkerModal(true);
              }}
            />
          }
        />
      </CustomModal>

      <HealthworkerForm
        setRefresh={refreshTable}
        setRefreshAssigned={refreshAssignedTable}
        barangays={barangays}
        open={openAddHealthworkerModal}
        setOpen={setOpenAddHealthworkerModal}
      />

      <HealthworkerUpdateForm
        setRefresh={refreshTable}
        setRefreshAssigned={refreshAssignedTable}
        healthworker={healthworker!}
        open={openUpdateHealthworkerModal}
        setOpen={setOpenUpdateHealthworkerModal}
      />

      <ConfirmationDialog
        open={openDeleteHealthworkerModal}
        setOpen={setOpenDeleteHealthworkerModal}
        content={'Are you sure you want to delete ' + healthworker?.first_name + ' ' + healthworker?.last_name + '?'}
        onConfirm={handleDeleteHealthworker}
      />
    </>
  );
};

export default HealthWorkersModal;
