/* eslint-disable react-hooks/exhaustive-deps */
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material';

type FormTimePickerProps = {
  type?: React.HTMLInputTypeAttribute;
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: (value: any) => void;  value?: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  span?: number;
  size?: 'small' | 'medium' | undefined;
  minutesStep?: number;
  maxTime?: any;
  minTime?: any;
  fieldName: string;
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  format?: string;
  optional?: boolean;
  autoSave?: () => void;
  readOnly?: boolean;
};

const CustomTimePicker: React.FC<FormTimePickerProps> = ({
  type,
  label,
  disabled,
  touched,
  error,
  span,
  size = 'small',
  minutesStep,
  maxTime,
  minTime,
  required,
  setFieldValue,
  fieldName,
  format,
  value,
  optional,
  autoSave,
  readOnly,
  handleChange
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [locale] = useState('en');
  const [time, setTime] = useState<Dayjs | null>(null);
  // const handleChange = (newValue: Dayjs | null) => {
  //   setTime(newValue);
  //   autoSave && autoSave();
  // };

  useEffect(() => {
    if (time !== null) setFieldValue && setFieldValue(fieldName, dayjs(time).format(format ? format : 'HH:mm:ss'));
  }, [time]);

  // useEffect(() => {
  //   setTime(value ? dayjs(value?.slice(0, 5), 'HH:mm', true) : null);
  // }, [value]);

  useEffect(() => {
    if (value === '' || value === undefined) {
      setTime(null);
    } else {
      // Assuming value is in 'HH:mm:ss' format
      setTime(dayjs(value, 'HH:mm:ss'));
    }
  }, [value]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <TimePicker
        label={label + (optional ? ' (optional)' : '')}
        disabled={disabled}
        value={time}
        ampm={true}
        views={['hours', 'minutes']}
        format="hh:mm a"
        // onChange={(newValue: Dayjs | null) => {
        //   setTime(newValue);
        //   // autoSave && autoSave();
        //   handleChange && handleChange(newValue);
        // }}
        onChange={(newValue: Dayjs | null) => {
          setTime(newValue);
          handleChange && handleChange(newValue ? newValue.format('HH:mm:ss') : null);
        }}
        minutesStep={minutesStep}
        maxTime={maxTime}
        minTime={minTime}
        readOnly={readOnly}
        slotProps={{
          textField: {
            variant: 'filled',
            disabled: disabled,
            error: !!touched && !!error,
            helperText: touched ? error : '',
            required: required,
            size: size,
          },
        }}
        sx={{
          '&.MuiTextField-root': { width: '100%' },
          '.MuiFormLabel-root.Mui-focused': { color: colors.light },
          '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
          '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
          '.MuiFilledInput-root:before': { border: 'none' },
          '.Mui-disabled:before': { border: 'none !important' },
          '.MuiFilledInput-root:after': {
            borderBottom: `1px solid ${colors.primary}`,
            borderRadius: 2,
            height: '100%',
          },
          '.MuiFilledInput-input': { height: '22px' },
          '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
          gridColumn: span ? 'span ' + span : undefined,
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
