import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderDrawerType, PageBuilderPageType } from 'core/PageBuilder';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { formatDate, formatDateTime } from 'core/utils';

import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTableGetter from './TemplateTableGetter';

type Props = {
  entityName: string;
  templateTable?: ReactNode;
  childComponent: ReactNode;
  tableRef: any;
  tableExtraParams?: any;
  internalSetDrawerSelectedRow?: (row?: any) => void;
  externalSetDrawerSelectedRow?: (row?: any) => void;
  displayChildHeader?: (selectedRow: any) => string;
  displayChildSubHeader?: (selectedRow: any) => string;
  hideHeader?: boolean;
  mainColumn?: string;
  tableAction?: TableActionProps;
  formatMainColumnAsDate?: boolean;
  formatMainColumnAsDateTime?: boolean;
};

const TemplateDrawer = forwardRef((props: Props, ref) => {
  const {
    entityName,
    childComponent,
    tableRef,
    tableExtraParams = {},
    templateTable,
    internalSetDrawerSelectedRow,
    externalSetDrawerSelectedRow,
    displayChildHeader,
    displayChildSubHeader,
    hideHeader = false,
    mainColumn,
    tableAction,
    formatMainColumnAsDate,
    formatMainColumnAsDateTime
  } = props;
  const [selectedRow, setSelectedRow] = useState<any>();
  useImperativeHandle(ref, () => ({ triggerAddButtonClick }));

  useEffect(() => {
    internalSetDrawerSelectedRow && internalSetDrawerSelectedRow(selectedRow);
    externalSetDrawerSelectedRow && externalSetDrawerSelectedRow(selectedRow);
  }, [externalSetDrawerSelectedRow, internalSetDrawerSelectedRow, selectedRow]);

  const formatMainColumn = (value: any) => {
    if (formatMainColumnAsDate) {
      return mainColumn && typeof value === 'string' ? formatDate(value) : value;
    } else if (formatMainColumnAsDateTime) {
      return mainColumn && typeof value === 'string' ? formatDateTime(value) : value;
    } else {
      return value;
    }
  };

  const defaultChildHeader = () => {
    if (mainColumn && selectedRow) {
      return formatMainColumn(selectedRow[mainColumn]);
    } else {
      return '';
    }
  };

  const triggerAddButtonClick = () => {
    tableRef.current.handleAddButtonClick();
  };

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.DRAWER,
      hideHeader: hideHeader,
      parentHeader: entityName,
      parentSubHeader: 'Click the row to see more details.',
      childHeader: displayChildHeader && selectedRow ? displayChildHeader(selectedRow) : defaultChildHeader(),
      childSubHeader: selectedRow && displayChildSubHeader ? displayChildSubHeader(selectedRow) : '',
      parentWidth: 300,
      showChild: !!selectedRow,
      handleClose: () => setSelectedRow(undefined),
      parentComponent: {
        type: BLOCK_TYPE.CUSTOM,
        block: templateTable ?? (
          <TemplateTableGetter
            tableRef={tableRef}
            entityName={entityName}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            tableAction={tableAction}
            tableExtraParams={tableExtraParams}
          />
        ),
      } as any,
      childComponent: {
        type: BLOCK_TYPE.CUSTOM,
        block: childComponent,
      } as any,
    } as PageBuilderDrawerType,
  } as PageBuilderPageType;

  return <PageBuilder content={content} />;
});

export default TemplateDrawer;
