import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { LguUserModel } from 'carego-admin/model/Entities';
import { updatePassword } from 'carego-admin/api/lgu-user';
import { update_password_schema } from 'carego-admin/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UpdatePasswordProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lguUser: LguUserModel;
  refreshTable: () => void;
};

export type UserInput = {
  new_password: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ open, setOpen, lguUser, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    new_password: '',
  };

  const form_fields: UserFieldInput[] = [
    {
      field_name: 'new_password',
      display_name: 'New Password',
      type: 'password_generator',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('new_password', value);
      },
    },
  ];

  const update = (data: any) => {
    setLoading(true);

    updatePassword(lguUser.id, data)
      .then((res) => {
        enqueueSnackbar('Password successfully updated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  return (
    lguUser && (
      <CustomModal header={'Update Password of ' + lguUser.full_name} open={open} setOpen={setOpen}>
        <CustomForm
          onSubmit={update}
          fields={form_fields}
          schema={update_password_schema}
          initialValues={initialValues}
          loading={loading}
        />
      </CustomModal>
    )
  );
};

export default UpdatePassword;
