import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getPatient, getPatients } from 'company/api/patient';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const PatientSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'Patient'}
        getData={(query: ApiQuery) => getPatients(facility.id, query)}
        getById={(id: number) => getPatient(facility.id, id)}
        processOption={(record) => ({
          key: record.patient_id + ' - ' + record.full_name,
          value: record.patient_id,
          data: record,
        })}
        processNewRecord={(record) => record.patient}
      />
    </WaitForFacility>
  );
};

export default PatientSearchDropdown;
