import { ApiQuery, FieldInput } from 'core/model/interface';
import { CustomForm, CustomGridCell, CustomModal, Header, PrimaryButton } from 'core/components';
import { Link, useNavigate } from 'react-router-dom';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { createNote, deleteNote, getNotes } from 'carego-admin/api/carego-notes-templates';

import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { create_note_template_schema } from 'carego-admin/model/schema';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const Notes = () => {
  const [refreshTable, setRefreshTable] = useState(0);

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const addFormFields: FieldInput[] = [
    {
      field_name: 'template_name',
      display_name: 'Template Name',
      type: 'text',
      span: 6,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'header',
      headerName: 'Header',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/admin/templates/view/${params.row.id}`} className="emphasis">
            {params.row.header}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Date Updated',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.updated_at)}</CustomGridCell>,
    },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getNotes(query);
  };

  const handleDelete = (data: any) => {
    return deleteNote(data);
  };

  const handleSubmit = (data: any) => {
    setButtonLoading(true);

    createNote(data)
      .then(() => {
        enqueueSnackbar(`Note successfully created!`, { variant: 'success' });
        setRefreshTable((prev: number) => prev + 1);
        setOpenAddModal(false);
        setButtonLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // setRefreshTable((prev: number) => prev + 1);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Notes Template',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
          headerComponent: (
            <Box>
              <Header title="Note Templates" mb="10px" />
              <PrimaryButton
                onClick={() => {
                  setOpenAddModal(true);
                }}
                label={'Add Note Template'}
              />
            </Box>
          ),
        },
        tableAction: {
          includeUpdateAction: true,
          includeDeleteAction: true,
          updateButtonLabel: 'Update',
          deleteButtonLabel: 'Delete',
          deleteApiFunction: handleDelete,
          handleRowActionsClick(data, action) {
            if (action === 'update') {
              navigate(`/admin/templates/edit/${data.id}`);
            }
          },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <CustomModal header={'Add Note Template'} open={openAddModal} setOpen={setOpenAddModal} width={500}>
        <Box>
          <CustomForm
            initialValues={{ template_name: '' }}
            onSubmit={handleSubmit}
            fields={addFormFields}
            schema={create_note_template_schema}
            loading={buttonLoading}
          />
        </Box>
      </CustomModal>

      <PageBuilder content={content} />
    </>
  );
};

export default Notes;
