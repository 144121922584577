/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Collapse, Divider, FormControl, IconButton, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { RegularButton, SubmitButton } from 'core/components';

import { FORM_MODE } from 'core/model/interface';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

const dental_condition = [
  { key: 'P', value: 'Present teeth' },
  { key: 'C', value: 'Decayed (Caries indicated for filling)' },
  { key: 'Ex', value: 'Indicated for Extraction' },
  { key: 'M', value: 'Missing (extracted due to caries)' },
  { key: 'MO', value: 'Missing due to Other causes' },
  { key: 'Im', value: 'Impacted tooth' },
  { key: 'SP', value: 'Supernumerary tooth' },
  { key: 'Rf', value: 'Root fragment' },
  { key: 'Un', value: 'Unerupted' },
];

const dental_treatment = [
  { key: 'Am', value: 'Amalgam filling' },
  { key: 'Co', value: 'Composite filling' },
  { key: 'JC', value: 'Jacket Crown' },
  { key: 'Ab', value: 'Abutment' },
  { key: 'Att', value: 'Attachment' },
  { key: 'Po', value: 'Pontic' },
  { key: 'In', value: 'Inlay' },
  { key: 'Imp', value: 'Implant' },
  { key: 'S', value: 'Sealants' },
  { key: 'Rm', value: 'Removable Denture' },
];

type TeethData = {
  id: number;
  condition: string;
  treatment: string;
};

type DentalChartProps = {
  handleSubmit?: (data: TeethData[]) => void;
  initialData?: any;
  mode?: FORM_MODE;
  forceSubmit?: boolean;
  setIsDirty?: (isDirty: boolean) => void;
};

const DentalChart: React.FC<DentalChartProps> = ({ handleSubmit, initialData, mode, forceSubmit, setIsDirty }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openT, setOpenT] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [teethData, setTeethData] = useState<Array<TeethData>>(
    initialData?.dental_chart ??
      Array(32)
        .fill(0)
        .map((_, index) => ({ id: index + 1, condition: '', treatment: '' }))
  );
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleToggles = () => {
    setOpenT(!openT);
  };
  const handleConditionChange = (index: number, condition: string) => {
    setIsDirty && setIsDirty(true);
    const updatedTeethData = [...teethData];
    updatedTeethData[index] = { ...updatedTeethData[index], condition };
    setTeethData(updatedTeethData);
  };

  const handleTreatmentChange = (index: number, treatment: string) => {
    setIsDirty && setIsDirty(true);
    const updatedTeethData = [...teethData];
    updatedTeethData[index] = { ...updatedTeethData[index], treatment };
    setTeethData(updatedTeethData);
  };

  const handleMarkAsPresent = () => {
    const updatedTeethData = teethData.map((tooth, index) => {
      if (tooth.condition === '') {
        return { ...tooth, condition: 'P', treatment: '' };
      }
      return tooth;
    });
    setTeethData(updatedTeethData);
    setIsDirty && setIsDirty(true);
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    if (initialData) {
      initialData.dental_chart = teethData;
      handleSubmit && handleSubmit(initialData);
    }

    e.preventDefault();
  };

  const displayBox = (tooth: TeethData) => {
    return (
      <Box key={tooth.id - 1} width="100%" display="flex" flexDirection="column" gap="3px" marginBottom="10px">
        <Typography textAlign="center">{tooth.id}</Typography>

        {/* Dental Condition Dropdown */}
        <Box
          sx={{
            width: '100%',
            '& .MuiSelect-select': { padding: '10px !important', textAlign: 'center' },
            '& .MuiSvgIcon-root': { display: 'none' },
            // border: '1px solid ' + colors.accent,
          }}
        >
          <FormControl fullWidth>
            <Select
              readOnly={mode && mode !== FORM_MODE.RESPOND}
              value={tooth.condition}
              onChange={(event) => handleConditionChange(tooth.id - 1, event.target.value as string)}
              renderValue={(selected) => {
                const selectedItem = dental_condition.find((item) => item.value === selected);
                return selectedItem ? selectedItem.key : selected;
              }}
              disabled={mode && mode !== FORM_MODE.RESPOND}
              sx={{
                '& .MuiSelect-select': {
                  border: '2px solid ' + colors.accent,
                },
              }}
            >
              {dental_condition.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.key} - {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Dental Treatment Dropdown */}
        <Box
          sx={{
            width: '100%',
            '& .MuiSelect-select': { padding: '10px !important', textAlign: 'center' },
            '& .MuiSvgIcon-root': { display: 'none' },
          }}
        >
          <FormControl fullWidth>
            <Select
              readOnly={mode && mode !== FORM_MODE.RESPOND}
              value={tooth.treatment}
              onChange={(event) => handleTreatmentChange(tooth.id - 1, event.target.value as string)}
              renderValue={(selected) => {
                const selectedItem = dental_treatment.find((item) => item.value === selected);
                return selectedItem ? selectedItem.key : selected;
              }}
              disabled={mode && mode !== FORM_MODE.RESPOND}
              sx={{
                '& .MuiSelect-select': {
                  border: '2px solid orange',
                },
              }}
            >
              {dental_treatment.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.key} - {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (initialData?.dental_chart) {
      setTeethData(initialData.dental_chart);
    }
  }, [initialData]);

  useEffect(() => {
    if (forceSubmit) {
      if (initialData) {
        initialData.dental_chart = teethData;
        handleSubmit && handleSubmit(initialData);
      }
    }
  }, [forceSubmit]);

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <Box display={'flex'} justifyContent="space-between">
          <Box
            display={mode && mode !== FORM_MODE.RESPOND ? 'none' : 'flex'}
            justifyContent="end"
            mb={2}
            mt={-2}
            gap={'5px'}
            py={2}
          >
            <RegularButton label={'Mark the rest as Present'} onClick={handleMarkAsPresent} variant="outlined" />

            {!mode && <SubmitButton label={'Save'} loading={false} disabled={false} width={'auto'} />}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
          <Typography fontWeight="bold">RIGHT</Typography>
          <Typography fontWeight="bold">LEFT</Typography>
        </Box>
        <Box display="flex" gap="3px">
          {teethData?.slice(0, 16).map((tooth) => (
            <Fragment key={tooth.id}>{displayBox(tooth)}</Fragment>
          ))}
        </Box>
        <Image src="/assets/DentalChart/dental_chart_top.png" alt="dental_chart_top" />
        <Image src="/assets/DentalChart/dental_chart_bottom.png" alt="dental_chart_bottom" />
        <Box display="flex" gap="3px" flexDirection="row-reverse">
          {teethData?.slice(16, 32).map((tooth) => (
            <Fragment key={tooth.id}>{displayBox(tooth)}</Fragment>
          ))}
        </Box>

        {!mode && (
          <Box display="flex" justifyContent="end" mt={2} gap={'5px'}>
            <RegularButton label={'Mark the rest as Present'} onClick={handleMarkAsPresent} variant="outlined" />

            <SubmitButton label={'Save'} loading={false} disabled={false} width={'auto'} />
          </Box>
        )}
      </form>

      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="20px" width="100%" marginTop="20px">
        <Box
          style={{
            border: open ? '1px solid transparent' : 'none',
            borderRadius: '10px',
            boxShadow: open ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px' : 'none',
          }}
        >
          <Box
            onClick={handleToggle}
            style={{
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              padding: '15px 0px',
              borderRadius: '10px',
              boxShadow: open ? 'none ' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              border: open ? 'none' : 'transparent',
            }}
          >
            <Box display="flex" gap="10px">
              <img src="/assets/dental2.svg" alt="" />
              <Typography variant="h5" fontWeight="bold">
                Dental Condition
              </Typography>
            </Box>

            <IconButton onClick={handleToggle} style={{ padding: 0 }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          {open && <Divider sx={{ margin: '0 0.5rem ' }} />}

          <Collapse in={open}>
            <Box
              display="grid"
              gridTemplateColumns="35px 1fr"
              gap="10px"
              sx={{
                padding: '40px 40px',
                borderRadius: '10px',
              }}
            >
              {dental_condition.map((item) => (
                <Fragment key={item.key}>
                  <Typography variant="h6" fontWeight="bold">
                    {item.key}
                  </Typography>
                  <Typography variant="h6">{item.value}</Typography>
                </Fragment>
              ))}
            </Box>
          </Collapse>
        </Box>
        <Box
          style={{
            border: openT ? '1px solid transparent' : 'none',
            borderRadius: '10px',
            boxShadow: openT ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px' : 'none',
          }}
        >
          <Box
            onClick={handleToggles}
            style={{
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              padding: '15px 0px',
              borderRadius: '10px',
              boxShadow: openT ? 'none ' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              border: openT ? 'none' : 'transparent',
            }}
          >
            <Box display="flex" gap="10px">
              <img src="/assets/dental1.svg" alt="" />
              <Typography variant="h5" fontWeight="bold">
                Dental Treatment
              </Typography>
            </Box>

            <IconButton onClick={handleToggles} style={{ padding: 0 }}>
              {openT ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          {openT && <Divider sx={{ margin: '0 0.5rem ' }} />}

          <Collapse in={openT}>
            <Box
              display="grid"
              gridTemplateColumns="35px 1fr"
              gap="10px"
              sx={{
                padding: '40px 40px',
                borderRadius: '10px',
              }}
            >
              {dental_treatment.map((item) => (
                <Fragment key={item.key}>
                  <Typography variant="h6" fontWeight="bold">
                    {item.key}
                  </Typography>
                  <Typography variant="h6">{item.value}</Typography>
                </Fragment>
              ))}
            </Box>
          </Collapse>
        </Box>
        {/* <Box padding="20px" borderRadius={2} border={'1px solid orange'} width="100%">
          <Typography variant="h5" fontWeight="bold">
            Treatment Received
          </Typography>
          <Divider sx={{ margin: '0.5rem 0' }} />
          <Box display="grid" gridTemplateColumns="35px 1fr">
            {dental_treatment.map((item) => (
              <Fragment key={item.key}>
                <Typography variant="h6" fontWeight="bold">
                  {item.key}
                </Typography>
                <Typography variant="h6">{item.value}</Typography>
              </Fragment>
            ))}
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default DentalChart;

const Image = styled.img`
  width: 100%;
  border: none;
  z-index: -1;
`;
