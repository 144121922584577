import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import { FieldInput } from 'core/model/interface';
import TermsOfUse from 'company/screens/Settings/screens/TermsOfUse';
import { readTerms } from 'company/api/company';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type TermsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  companyUserID: number;
};

const TermsModal: React.FC<TermsModalProps> = ({ open, setOpen, companyUserID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const field: FieldInput[] = [
    {
      field_name: 'read_terms',
      display_name: 'I have read and agreed to the Terms and Conditions.',
      type: 'checkbox',
      span: 4,
      onChange: (data) => {
        data ? setDisabled(false) : setDisabled(true);
      },
    },
  ];

  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      await readTerms(companyUserID);
      enqueueSnackbar('You have agreed to the terms and conditions', { variant: 'success' });
      setOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal header="Read Terms and Conditions" open={open} setOpen={setOpen} width={800}>
      <TermsOfUse />
      <Box m={'1rem 0.5rem'}>
        <CustomForm
          onSubmit={handleSubmit}
          fields={field}
          initialValues={{ read_terms: false }}
          loading={loading}
          submitButtonText="Proceed"
          disabled={disabled}
        />
      </Box>
    </CustomModal>
  );
};

export default TermsModal;
