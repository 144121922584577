import { Box, Pagination, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import styled from 'styled-components';

const Scroll4 = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentPagePartners, setCurrentPagePartners] = useState(0);
  const [thirdPagePartners, setThirdPagePartners] = useState(0);

  const [totalPagesPartners, setTotalPagesPartners] = useState(3);
  const [totalThirdPagePartners, setTotalThirdPagePartners] = useState(4);

  const [currentPageFeedback, setCurrentPageFeedback] = useState(0);

  const [totalPagesFeedback, setTotalPagesFeedback] = useState(3);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSwipeLeftPartners = () => {
    setCurrentPagePartners((prevPage) => (prevPage === 0 ? totalPagesPartners - 1 : prevPage - 1));
  };

  const handleSwipeRightPartners = () => {
    setCurrentPagePartners((prevPage) => (prevPage === totalPagesPartners - 1 ? 0 : prevPage + 1));
  };

  const handleSwipeLeftLast = () => {
    setThirdPagePartners((prevPage) => (prevPage === 0 ? totalThirdPagePartners - 1 : prevPage - 1));
  };

  const handleSwipeRightLast = () => {
    setThirdPagePartners((prevPage) => (prevPage === totalThirdPagePartners - 1 ? 0 : prevPage + 1));
  };

  const handleSwipeLeftFeedback = () => {
    setCurrentPageFeedback((prevPage) => (prevPage === 0 ? totalPagesFeedback - 1 : prevPage - 1));
  };

  const handleSwipeRightFeedback = () => {
    setCurrentPageFeedback((prevPage) => (prevPage === totalPagesFeedback - 1 ? 0 : prevPage + 1));
  };

  return isMobilePhone ? (
    <>
      <MainContainer>
        <Container>
          <Content>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Feedback>Our partners </Feedback>
            </Box>
            {isMobile && (
              <>
                <SwipeIndicatorLeft onClick={handleSwipeLeftPartners}>{'<'}</SwipeIndicatorLeft>
                <SwipeIndicatorRight onClick={handleSwipeRightPartners}>{'>'}</SwipeIndicatorRight>
              </>
            )}
            {isMobile && (
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }}
                style={{
                  transform: `translateX(-${currentPagePartners * 98}%)`,
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner1.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner2.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner3.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner4.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner5.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner6.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner7.jpg" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner8.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner9.png" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner10.jpg" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner11.jpg" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner14.jpg" />
                  </ImageContainer>
                </MobileBox>
                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/partner16.jpg" />
                  </ImageContainer>
                </MobileBox>
              </Box>
            )}
            {isMobile && (
              <Pagination
                count={totalPagesPartners}
                page={currentPagePartners + 1}
                onChange={(event, page) => setCurrentPagePartners(page - 1)}
                style={{ marginTop: '20px' }}
                color="primary"
              />
            )}
          </Content>
        </Container>
      </MainContainer>
      <MainContainer>
        <Container>
          <Content>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Feedback>
                Feedback from our <br />
                partner clinics
              </Feedback>
              <Description style={{ marginBottom: '50px' }}>
                Read what our partner clinics have to say about their <br />
                experience with our services. Discover their feedback and <br />
                how we've helped improve their operations.
              </Description>
            </Box>
            {isMobile && (
              <>
                <SwipeIndicatorLeft onClick={handleSwipeLeftFeedback}>{'<'}</SwipeIndicatorLeft>
                <SwipeIndicatorRight onClick={handleSwipeRightFeedback}>{'>'}</SwipeIndicatorRight>
              </>
            )}
            {isMobile && (
              <Box
                justifyContent="space-between"
                sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                style={{
                  transform: `translateX(-${currentPageFeedback * 98}%)`,
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <MobileBox
                  display="flex"
                  flexDirection={'column'}
                  justifyContent="center"
                  alignItems="center"
                  width={'90%'}
                  sx={{ backgroundColor: '#f2f9ff', paddingBottom: '30px', flexShrink: 0, margin: '25px' }}
                >
                  <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                    <ImageFeed src="./assets/Landing2/p2/feedback1.svg" alt="Logo" />
                    <Divider>
                      <Name>Rosemarie</Name>
                      <Description>from Batangas</Description>
                      <ImageText>
                        <em>
                          "Fortunately, because of CareGo's <br />
                          reminders, the parents were able to <br />
                          immediately inform me that they could not <br /> go to the injection site because their child{' '}
                          <br />
                          was sick, which is helpful for us as BHWs.".
                        </em>
                      </ImageText>
                    </Divider>
                  </Box>
                  <TextContainer></TextContainer>
                </MobileBox>
                <MobileBox
                  display="flex"
                  flexDirection={'column'}
                  justifyContent="center"
                  alignItems="center"
                  width={'90%'}
                  sx={{ backgroundColor: '#f2f9ff', paddingBottom: '30px', flexShrink: 0 }}
                >
                  <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                    <ImageFeed src="./assets/Landing2/p2/feedback2.svg" alt="Logo" />
                    <Divider>
                      <Name>M. Himor</Name>
                      <Description>NCR Chapter Head</Description>
                      <ImageText>
                        <em>
                          " This would help us increase the uptake of injectables
                          <br /> and other FP methods. Some of our patients <br />
                          forget to come back for their follow-up."
                        </em>
                      </ImageText>
                    </Divider>
                  </Box>
                  <TextContainer></TextContainer>
                </MobileBox>
                <MobileBox
                  display="flex"
                  flexDirection={'column'}
                  justifyContent="center"
                  alignItems="center"
                  width={'90%'}
                  sx={{ backgroundColor: '#f2f9ff', paddingBottom: '30px', flexShrink: 0, marginRight: '30px' }}
                >
                  <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                    <ImageFeed src="./assets/Landing2/p2/feedback3.svg" alt="Logo" />
                    <Divider>
                      <Name>Ate Leah</Name>
                      <Description>Clinic Secretary</Description>
                      <ImageText>
                        <em>
                          "The arrival of patients has really improved; no one is arriving late, and the clinic is no
                          longer crowded because <br />
                          they come on time."
                        </em>
                      </ImageText>
                    </Divider>
                  </Box>
                  <TextContainer></TextContainer>
                </MobileBox>
              </Box>
            )}
            {isMobile && (
              <Pagination
                count={totalPagesFeedback}
                page={currentPageFeedback + 1}
                onChange={(event, page) => setCurrentPageFeedback(page - 1)}
                style={{ marginTop: '20px' }}
                color="primary"
              />
            )}
          </Content>
        </Container>
      </MainContainer>
      <MainContainer>
        <Container>
          <Content>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Feedback>Featured In </Feedback>
            </Box>
            {isMobile && (
              <>
                <SwipeIndicatorLeft onClick={handleSwipeLeftLast}>{'<'}</SwipeIndicatorLeft>
                <SwipeIndicatorRight onClick={handleSwipeRightLast}>{'>'}</SwipeIndicatorRight>
              </>
            )}
            {isMobile && (
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }}
                style={{
                  transform: `translateX(-${thirdPagePartners * 98}%)`,
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <a href="https://www.who.int/westernpacific/initiatives/innovation-for-health-impact/innovation-challenge/cp-health-innovations">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured1,2.png" alt="Featured 1,2" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://www.sunstar.com.ph/manila/local-news/us-supported-digital-platforms-to-increase-access-to-family-planning-resources-in-ph">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured3.png" alt="Featured 3" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://www.tatlerasia.com/people/ernest-troyss">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured4.png" alt="Featured 4" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://www.youtube.com/live/t5bM6MINV4g?si=u4uULskP6ts3S36c">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured5.png" alt="Featured 5" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://fb.watch/seztwSSXaz/">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured6.png" alt="Featured 6" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://www.undp.org/philippines/blog/tawidcovid-innovation-better-normal">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured7.png" alt="Featured 7" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://asia.makesense.org/for-innovators/">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured8.png" alt="Featured 8" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://villgrophilippines.medium.com/this-ones-for-12-year-old-me-a-gen-z-woman-s-insights-on-the-importance-of-comprehensive-sex-175f5b7b380b">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured10,13.png" alt="Featured 10,13" />
                    </ImageContainer>
                  </MobileBox>
                </a>
                <a href="https://www.goodnewspilipinas.com/pinoy-innovations-win-1st-who-western-pacific-public-health-challenge/">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured14.jpg" alt="Featured 14" />
                    </ImageContainer>
                  </MobileBox>
                </a>

                <MobileBox>
                  <ImageContainer>
                    <Image src="./assets/LandingMobile/featured12.jpg" alt="Featured 12" />
                  </ImageContainer>
                </MobileBox>

                <a href="https://newsinfo.inquirer.net/1737576/ph-receives-over-p3-million-from-us-in-support-of-family-planning-campaign">
                  <MobileBox>
                    <ImageContainer>
                      <Image src="./assets/LandingMobile/featured15.jpg" alt="Featured 15" />
                    </ImageContainer>
                  </MobileBox>
                </a>
              </Box>
            )}
            {isMobile && (
              <Pagination
                count={4}
                page={thirdPagePartners + 1}
                onChange={(event, page) => setThirdPagePartners(page - 1)}
                style={{ marginTop: '20px' }}
                color="primary"
              />
            )}
          </Content>
        </Container>
      </MainContainer>
    </>
  ) : (
    <MainContainer>
      <Container>
        <Content>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Images src="./assets/Landing2/p2/Waves.png" alt="Logo" />
            <Feedback>Feedback from our partner clinics</Feedback>
          </Box>
          {isMobile && (
            <>
              <SwipeIndicatorLeft onClick={handleSwipeLeftPartners}>{'<'}</SwipeIndicatorLeft>
              <SwipeIndicatorRight onClick={handleSwipeRightPartners}>{'>'}</SwipeIndicatorRight>
            </>
          )}
          {isMobile && (
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              style={{ transform: `translateX(-${currentPagePartners * 98}%)` }}
            >
              <MobileBox>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      "Fortunately, because of CareGo's reminders, the parents were able to immediately inform me that
                      they could not go to the injection site because their child was sick, which is helpful for us as
                      BHWs.".
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback1.svg" alt="Logo" />
                  <Divider>
                    <Name>Rosemarie</Name>
                    <Description>a BHW from Batangas</Description>
                  </Divider>
                </Box>
              </MobileBox>
              <MobileBox>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      " This would help us increase the uptake of injectables and other FP methods. Some of our patients
                      forget to come back for their follow-up."
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback2.svg" alt="Logo" />
                  <Divider>
                    <Name>M. Himor</Name>
                    <Description>NCR Chapter Head</Description>
                  </Divider>
                </Box>
              </MobileBox>
              <MobileBox>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      "The arrival of patients has really improved; no one is arriving late, and the clinic is no longer
                      crowded because they come on time."
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback3.svg" alt="Logo" />
                  <Divider>
                    <Name>Ate Leah</Name>
                    <Description>Clinic Secretary</Description>
                  </Divider>
                </Box>
              </MobileBox>
            </Box>
          )}
          {isMobile && (
            <Pagination
              count={totalPagesPartners}
              page={currentPagePartners + 1}
              onChange={(event, page) => setCurrentPagePartners(page - 1)}
              style={{ marginTop: '20px' }}
              color="primary"
            />
          )}
          {!isMobile && (
            <Box display="flex" justifyContent="space-between" sx={{ width: '90%' }}>
              <Box>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      "Fortunately, because of CareGo's reminders, the parents were able to immediately inform me that
                      they could not go to the injection site because their child was sick, which is helpful for us as
                      BHWs.".
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback1.svg" alt="Logo" />
                  <Divider>
                    <Name>Rosemarie</Name>
                    <Description>a BHW from Batangas</Description>
                  </Divider>
                </Box>
              </Box>
              <Box>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      " This would help us increase the uptake of injectables and other FP methods. Some of our patients
                      forget to come back for their follow-up."
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback2.svg" alt="Logo" />
                  <Divider>
                    <Name>M. Himor</Name>
                    <Description>NCR Chapter Head</Description>
                  </Divider>
                </Box>
              </Box>
              <Box>
                <ImageContainer>
                  <Image src="./assets/Landing2/p4/bubble.png" />
                  <TextContainer>
                    <ImageText>
                      "The arrival of patients has really improved; no one is arriving late, and the clinic is no longer
                      crowded because they come on time."
                    </ImageText>
                  </TextContainer>
                </ImageContainer>
                <Box display="flex" justifyContent="center" gap="20px" alignItems="center">
                  <ImageFeed src="./assets/Landing2/p2/feedback3.svg" alt="Logo" />
                  <Divider>
                    <Name>Ate Leah</Name>
                    <Description>Clinic Secretary</Description>
                  </Divider>
                </Box>
              </Box>
            </Box>
          )}
        </Content>
      </Container>
    </MainContainer>
  );
};

export default Scroll4;
const ImageContainer = styled.div`
  position: relative;
`;
const ImageText = styled.p`
  margin: 0;
  font-size: 19px;
  color: #000;
  @media (max-width: 1800px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;
const TextContainer = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;

  transform: translateX(-50%);
  width: 500px;
  height: 250px;
  padding: 10px 40px;
  border-radius: 5px;

  text-align: center;
  display: flex;
  gap: 30px;
  flex-direction: column;
  @media (max-width: 1700px) {
    width: 380px;
    height: 150px;
    top: 40px;
    padding: 10px 40px;
    gap: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 150px;
    top: 45px;
    padding: 10px 40px;
    gap: 20px;
  }
`;
const MainContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 105%;
  display: flex;
  justify-content: center;

  background-image: url('./assets//Landing2/p1/tryy.png');
  background-size: cover;
  background-size: 100% 95%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  @media (max-width: 768px) {
    align-items: center;

    height: 70vh;
    background-size: 100% 100%;
    background-image: none;
  }
`;
const Images = styled.img`
  height: auto;
  width: 80px;
  height: 10px;
  @media (max-width: 1700px) {
    width: 70px;
    height: 10px;
  }
`;
const Container = styled.div`
  width: 100%;
  color: white;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;
const Feedback = styled.div`
  font-size: 40px;
  font-weight: bold;
  font-family: Nunito;
  letter-spacing: 1.5px;
  font-family: Fredoka;
  @media (max-width: 1500px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    color: #0192d5;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Name = styled.p`
  font-size: 20px;

  font-family: Nunito;

  font-weight: bold;
  margin-bottom: -15px;
  @media (max-width: 768px) {
    color: #0192d5;
    text-align: center;
  }
`;
const Divider = styled.div`
  /* background-color: yellow; */
`;

const Description = styled.p`
  text-align: center;
  font-family: Nunito;
  line-height: 2rem;
  font-size: 15px;

  @media (max-width: 768px) {
    color: black;
  }
`;
const Image = styled.img`
  width: 500px;
  height: 280px;
  @media (max-width: 1700px) {
    width: 360px;
    height: 220px;
  }
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;
const ImageFeed = styled.img`
  height: auto;

  @media (max-width: 1900px) {
    height: 165px;
  }

  @media (max-width: 768px) {
    height: 100px;
    margin-top: -60px;
  }
`;

const SwipeIndicatorLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 15px;
    width: 30px;
    height: 30px;
    background-color: #0192d5;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    line-height: 1;
  }
`;

const SwipeIndicatorRight = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    background-color: #0192d5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    line-height: 1;
  }
`;
const MobileBox = styled(Box)`
  width: 100%;

  padding: 10px;
  border-radius: 12px;

  margin: 15px;
  transition: transform 0.3s ease-in-out;
`;
