import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { addServices, deleteGroup, getAllGroups, getGroup, removeService } from 'company/api/service-groups';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Chip from '@mui/material/Chip';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupServiceTable from 'company/entities/modules/ClinicManagement/Service/GroupServiceTable';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import ServiceGroupForm from 'company/entities/modules/ClinicManagement/Service/ServiceGroupForm';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';
import { deleteServiceCategory, getAllServiceCategories, getServiceCategory } from 'company/api/service-categories';
import { ServiceCategoryModel } from 'company/entities/modules/ClinicManagement/Service/ServiceCategoryModel';
import ServiceCategoryForm from 'company/entities/modules/ClinicManagement/Service/ServiceCategoryForm';

export default function ServiceCategories({ setOpenServiceCategories }: any) {
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState<ServiceCategoryModel>();
  const [addUpdateFormOpen, setAddUpdateFormOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [newlyAddedCategoryID, setNewlyAddedCategoryID] = useState<number>();
  const [updatedCategory, setUpdatedCategory] = useState<number>();
  const [toDeleteID, setToDeleteID] = useState<number>();
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] = useState<boolean>(false);
  const [loadingDeleteCategory, setLoadingDeleteCategory] = useState<boolean>(false);
  const [deletedID, setDeletedID] = useState<number>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  function getData(query: ApiQuery) {
    return getAllServiceCategories(facility.id, query);
  }

  function getSingleData(id: number) {
    return getServiceCategory(facility.id, id);
  }

  async function handleDeleteCategory(id: number) {
    setLoadingDeleteCategory(true);
    await deleteServiceCategory(facility.id, id);
    setDeletedID(id);
    setLoadingDeleteCategory(false);
  }

  const displayItem = (item: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedCategory ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.service_category_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    async function getGroupDetails() {
      if (!selectedCategory) return;
      const res = await getServiceCategory(facility.id, selectedCategory);
      setSelectedCategoryDetails(res.data);
    }
    getGroupDetails();
  }, [selectedCategory]);
  return (
    <>
      <InfiniteScrollContainer
        title="Service Categories"
        getData={getData}
        deleteId={deletedID}
        updatedItem={updatedCategory}
        addedNewItem={newlyAddedCategoryID}
        getSingleData={(id) => getSingleData(id)}
        selected={selectedCategory}
        setSelected={setSelectedCategory}
        //   onChange={(selected) => {
        //     tableRef.current?.refreshTable();
        //   }}
        actions={[
          // {
          //   icon: <AddIcon />,
          //   label: 'Add Services',
          //   action: (data) => {
          //     setOpenAddServicesForm(true);
          //   },
          // },
          {
            icon: <EditOutlinedIcon />,
            label: 'Update Category',
            action: (data) => {
              setIsUpdate(true);
              setAddUpdateFormOpen(true);
            },
          },
          {
            icon: <DeleteOutlinedIcon />,
            label: 'Delete',
            color: 'error',
            action: (data) => {
              setToDeleteID(data);
              setOpenDeleteCategoryDialog(true);
            },
          },
        ]}
        renderItem={displayItem}
        urlKeyId="scid"
        headerComponents={
          !isMobilePhone && (
            <RegularButton
              onClick={() => {
                setAddUpdateFormOpen(true);
                setIsUpdate(false);
              }}
              label="Add"
              startIcon={<AddIcon />}
              variant="outlined"
              fullWidth
            />
          )
        }
        displaySelectedTitle={() => (
          <ContainerRow sx={{ display: 'flex', justifyContent: 'start', gap: '5px' }}>
            {setOpenServiceCategories && (
              <RegularButton
                variant="text"
                size="small"
                startIcon={<ArrowBackIosOutlinedIcon />}
                label="Back"
                onClick={() => setOpenServiceCategories(false)}
                styles={{ padding: '2px 5px 2px 20px', marginLeft: '-20px' }}
              />
            )}
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {selectedCategoryDetails?.service_category_name}
            </Typography>
          </ContainerRow>
        )}
      >
        {selectedCategory && <Typography>{selectedCategoryDetails?.service_category_name}</Typography>}
      </InfiniteScrollContainer>

      <CustomModal
        open={addUpdateFormOpen}
        setOpen={setAddUpdateFormOpen}
        header={!isUpdate ? `Create New Service Category` : 'Update Service Category'}
        onClose={() => setIsUpdate(false)}
      >
        <ServiceCategoryForm
          facility_id={facility.id}
          serviceCategory={isUpdate ? selectedCategoryDetails : undefined}
          callbackAfterSubmit={(data) => {
            if (isUpdate) {
              setUpdatedCategory(data.data);
              setSelectedCategoryDetails(data.data);
            } else setNewlyAddedCategoryID(data.data.id);

            setAddUpdateFormOpen(false);
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        loadingConfirm={loadingDeleteCategory}
        setOpen={setOpenDeleteCategoryDialog}
        open={openDeleteCategoryDialog}
        title="Confirm Delete Category"
        content={`Are you sure you want to delete this category`}
        onConfirm={() => {
          if (!toDeleteID) return;
          handleDeleteCategory(toDeleteID);
        }}
      ></ConfirmationDialog>
    </>
  );
}

// {selectedGroup && (
//     <>
//       {!!selectedGroupDetails?.is_package && (
//         <Box display={'flex'} alignItems={'center'} justifyContent={'start'} gap={'10px'} mt={'20px'}>
//           <Chip
//             label={`Service Package Price: ${formatCurrency(selectedGroupDetails?.price)}`}
//             variant="outlined"
//             color="primary"
//             sx={{ fontSize: '14px' }}
//             size="small"
//           />
//           {/* <Chip
//             label={`Price: ${formatCurrency(selectedGroupDetails.price)}`}
//             variant="outlined"
//             color="primary"
//             sx={{ fontSize: '12px' }}
//             size="small"
//           /> */}
//           {/* if want separate chip just uncomment^^ */}
//         </Box>
//       )}

//       <GroupServiceTable
//         tableRef={tableRef}
//         selectedGroup={selectedGroup}
//         tableAction={{
//           includeDeleteAction: true,
//           includeUpdateAction: false,
//           deleteButtonLabel: 'Remove',
//           deleteApiFunction: handleDelete,
//           alertCustomObjectColumn: 'service_name',
//           customDeleteSnackBarMessageOnSuccess: 'removed from group',
//         }}
//       ></GroupServiceTable>
//     </>
//   )}

//   {/*  FORM FOR CREATING NEW GROUP */}
//   <CustomModal
//     open={addFormOpen}
//     setOpen={setAddFormOpen}
//     header={!isUpdate ? `Create New Service Group` : 'Update Service Group'}
//     onClose={() => setIsUpdate(false)}
//   >
//     <ServiceGroupForm
//       serviceGroup={isUpdate ? selectedGroupDetails : undefined}
//       callbackAfterSubmit={(data) => {
//         if (isUpdate) {
//           setUpdatedGroup(data.data);
//           setSelectedGroupDetails(data.data);
//         } else setNewlyAddedGroupID(data.data.id);

//         setAddFormOpen(false);
//       }}
//     />
//   </CustomModal>

//   {/* FORM FOR ADDING SERVICES TO A GROUP */}
//   <CustomModal
//     open={openAddServicesForm}
//     setOpen={setOpenAddServicesForm}
//     header={`Add services to group ${selectedGroupDetails?.group_name}`}
//   >
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'space-between' }}>
//       <ServiceSearchDropdown
//         useGetNotAddedServiceAPI
//         groupId={selectedGroup}
//         limit={20}
//         handleChangeCallback={(record) => {
//           setSelectedServicesToAdd(record);
//         }}
//         multiple
//         fieldName="service_id"
//       ></ServiceSearchDropdown>

//       <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
//         <RegularButton onClick={() => addMultipleServices()} loading={addServicesLoading}></RegularButton>
//       </Box>
//     </Box>
//   </CustomModal>

//   <ConfirmationDialog
//     loadingConfirm={loadingDeleteGroup}
//     setOpen={setDeleteGroupDialogOpen}
//     open={deleteGroupDialogOpen}
//     title="Confirm Delete Group"
//     content={`Are you sure you want to delete this group`}
//     onConfirm={() => {
//       if (!toDeleteID) return;
//       handleDeleteGroup(toDeleteID);
//     }}
//   ></ConfirmationDialog>
