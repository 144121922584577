/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { ConfirmationDialog, CustomCheckbox, CustomModal, PrimaryButton, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import {
  getServicesAssigned,
  removeServicesFromClinic,
  updateClinicServicePrice,
  updateServiceVisibility,
} from 'company/api/services';
import { useContext, useEffect, useState } from 'react';

import AddTable from '../AddModal';
import { Box, useMediaQuery } from '@mui/material';
import { CompanyFacilityModel } from 'company/model/Entities';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { PageBuilderTableType } from 'core/PageBuilder';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WeeklySchedule from '../WeeklySchedule';
import { formatNumber } from 'core/utils';
import { updateFacilityServices } from 'core/components/ProtectedRoute';
import { update_clinic_service_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

type ClinicServicesProps = {
  clinic: CompanyFacilityModel;
};

const ClinicServices: React.FC<ClinicServicesProps> = ({ clinic }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setFacility } = useContext(FacilityContext);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [alertContent, setAlertContent] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [service, setService] = useState<any>();
  const [disabled, setDisabled] = useState<any>();

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const columns: GridColDef[] = [
    {
      field: 'service_name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
          {params.row.service_name}
          {params.row.visible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'clinic_price',
      headerName: 'Price (Php)',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.clinic_price)) ? formatNumber(parseFloat(params.row.clinic_price)) : '0',
    },
  ];

  const formFields: FieldInput[] = [{ field_name: 'clinic_price', display_name: 'Price (Php)', type: 'text' }];

  const getServiceList = (query: ApiQuery) => {
    return getServicesAssigned(clinic.id, query);
  };

  const handleServiceDelete = (data: any) => {
    const serviceList = JSON.stringify(data ? [data] : selectedRows);
    const services = { services: serviceList };

    return removeServicesFromClinic(clinic.id, services).then(() => {
      localStorage.removeItem('orders');
      refreshTable();
      setClearSelected((prev) => prev + 1);
      updateFacilityServices(setFacility);
      if (selectedRows.length > 1 && !data) {
        enqueueSnackbar('Services successfully removed!', { variant: 'success' });
      }
    });
  };

  const handleSubmit = (data: any) => {
    if (data.id) return updateClinicServicePrice(clinic.id, data.id, data);
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(Array.from(selected));
  };

  const setCustomSchedule = async (data: any) => {
    setOpenScheduleModal(true);
    setService(data);
    setDisabled(!!!data.available_schedule);
  };

  const setVisibility = (data: any) => {
    const serviceList = JSON.stringify(data ? [data.id] : selectedRows);
    const services = {
      services: serviceList,
      visible: !data.visible,
    };

    updateServiceVisibility(clinic.id, services)
      .then((res: any) => {
        localStorage.removeItem('orders');
        enqueueSnackbar('Service visibility successfully updated!', { variant: 'success' });
        refreshTable();
        setService(undefined);
        updateFacilityServices(setFacility);
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    refreshTable();
  }, [clinic]);

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: 'Service',
    useColoredTable: true,
    tableComponent: {
      columns: columns,
      getData: getServiceList,
      forceRefresh: forceRefresh,
      checkboxSelection: true,
      selectableRow: true,
      clearSelected: clearSelected,
      hideAddSelectedButton: true,
      onSelectRow: handleSelectRows,
      headerComponent: (
        <Box display="flex" gap="10px">
          {!isMobilePhone && (
            <PrimaryButton
              onClick={() => {
                setOpenAddModal(true);
              }}
              label="Add Services"
            />
          )}
          {!isMobilePhone && (
            <RegularButton
              onClick={() => {
                if (selectedRows.length) {
                  setOpenDeleteDialog(true);
                  setAlertContent(
                    'Are you sure you want to remove ' +
                      selectedRows.length +
                      ' ' +
                      (selectedRows.length === 1 ? 'service' : 'services') +
                      ' from ' +
                      clinic?.facility_name +
                      '?'
                  );
                } else {
                  enqueueSnackbar('Invalid action. Select services first!', { variant: 'error' });
                }
              }}
              label="Remove Services"
              disabled={!selectedRows.length}
              startIcon={<RemoveIcon />}
              color="error"
            />
          )}
        </Box>
      ),
    },
    tableAction: !isMobilePhone
      ? {
          includeUpdateAction: true,
          includeDeleteAction: true,
          updateButtonLabel: 'Update Clinic Service',
          deleteButtonLabel: 'Remove',
          deleteApiFunction: handleServiceDelete,
          alertCustomObjectColumn: 'service_name',
          customDeleteSnackBarMessageOnSuccess: 'removed',
          getRow: setService,
          rowActions: [
            { label: 'Set Custom Schedule', action: setCustomSchedule, icon: <DateRangeIcon /> },
            {
              label: service && service.visible ? 'Hide Service' : 'Show Service',
              action: setVisibility,
              icon: service && service.visible ? <VisibilityOffIcon /> : <VisibilityIcon />,
            },
          ],
        }
      : {},
    tableForm: {
      formFields: formFields,
      formSchema: update_clinic_service_schema,
      formInitialValues: service && service.clinic_price,
      formSubmitApiFunction: handleSubmit,
      formAfterSubmitFunction: () => {
        refreshTable();
        localStorage.removeItem('orders');
      },
    },
  } as PageBuilderTableType;

  return (
    <Box width="100%">
      <PageBuilder content={content} />

      <CustomModal width={900} header={'Add Services'} open={openAddModal} setOpen={setOpenAddModal}>
        <AddTable entity={'Services'} clinic={clinic} setRefreshMainTable={setForceRefresh} />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={alertContent}
        onConfirm={handleServiceDelete}
        confirmButtonColor={false}
      />

      <CustomModal
        header={'Set Custom Schedule'}
        subHeader={service?.service_name}
        open={openScheduleModal}
        setOpen={setOpenScheduleModal}
        width={800}
      >
        <Box>
          {service && (
            <Box display={'flex'} gap="2px" alignItems={'center'}>
              <CustomCheckbox
                label={'Use Clinic Schedule'}
                value={disabled}
                handleChange={() => {
                  setDisabled((prev: boolean) => !prev);
                }}
                fieldName={''}
              />
            </Box>
          )}
        </Box>
        {service && (
          <WeeklySchedule
            schedule={service.available_schedule}
            id={service.id}
            type="service"
            refreshTable={refreshTable}
            disabled={disabled}
            clinic_id={service.facility_id}
          /> //company_clinic_services ID
        )}
      </CustomModal>
    </Box>
  );
};

export default ClinicServices;
