import { Box } from '@mui/material';
import CareGoDrawer from './CareGoDrawer';
import CareGoPage from './CareGoPage';
import CareGoTable from './CareGoTable';
import { CustomContainer } from 'core/components';
import { Fragment } from 'react';
import { PageBuilderBlockType } from '.';

type CareGoPageProps = {
  content: PageBuilderBlockType;
};

export enum BLOCK_TYPE {
  PAGE = 'PAGE',
  CONTAINER = 'CONTAINER',
  TABLE = 'TABLE',
  DRAWER = 'DRAWER',
  CUSTOM = 'CUSTOM',
}

const PageBuilder: React.FC<CareGoPageProps> = ({ content }) => {
  const renderContent = (content: any) => {
    if (content.hide) {
      return <></>;
    }
    switch (content.type) {
      case BLOCK_TYPE.PAGE:
        return (
          <CareGoPage
            {...content}
            children={
              <>
                {Array.isArray(content.block) ? (
                  content.block.map((child: any, index: number) => {
                    return <Fragment key={index}>{renderContent(child)}</Fragment>;
                  })
                ) : (
                  <>{renderContent(content.block)}</>
                )}
              </>
            }
          />
        );

      case BLOCK_TYPE.CONTAINER:
        return (
          <CustomContainer m={content.m} p={content.p}>
            <>
              {Array.isArray(content.block) ? (
                content.block.map((child: any, index: number) => {
                  return <Fragment key={index}>{renderContent(child)}</Fragment>;
                })
              ) : (
                <Fragment>{renderContent(content.block)}</Fragment>
              )}
            </>
          </CustomContainer>
        );

      case BLOCK_TYPE.DRAWER:
        return (
          <CareGoDrawer
            {...content}
            parentHeader={!content.hideHeader && content.parentHeader}
            parentComponent={renderContent(content.parentComponent)}
            childComponent={renderContent(content.childComponent)}
          />
        );

      case BLOCK_TYPE.TABLE:
        return <CareGoTable {...content} />;

      case BLOCK_TYPE.CUSTOM:
        return content.block;

      default:
        return content.block;
    }
  };

  return (
    <Box width="100%">
      {Array.isArray(content) ? (
        content.map((child: any, index: number) => {
          return <Fragment key={index}>{renderContent(child)}</Fragment>;
        })
      ) : (
        <Fragment>{renderContent(content)}</Fragment>
      )}
    </Box>
  );
};

export default PageBuilder;
