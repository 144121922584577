/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { CareGoTable, PageBuilderDrawerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { CustomForm, CustomModal, RegularButton } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import {
  createTemplate,
  deleteTemplate,
  getAll,
  updateTemplate,
  updateTemplateBody,
} from 'company/api/email-templates';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { EmailTemplateModel } from 'company/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { add_email_template_schema } from 'carego-admin/model/schema';
import { getAllCategories } from 'carego-admin/api/carego-email-template-categories';
import { useSnackbar } from 'notistack';

type EmailTemplateInput = {
  template_name: string;
  template_code: string;
  email_subject: string;
  category_id: number | string;
  email_body: string;
};

interface EmailTemplateFieldInput extends FieldInput {
  field_name: keyof EmailTemplateInput;
}

const initialValues: EmailTemplateInput = {
  template_name: '',
  template_code: '',
  email_subject: '',
  category_id: '',
  email_body: '',
};

const EmailTemplates = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);

  const [selectedRow, setSelectedRow] = useState<EmailTemplateModel>();
  const [categories, setCategories] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 2 },
    { field: 'template_code', headerName: 'Template Code', flex: 2 },
    { field: 'email_subject', headerName: 'Subject', flex: 2 },
    { field: 'category_name', headerName: 'Category', flex: 2 },
  ];

  const category_columns: GridColDef[] = [{ field: 'category_name', headerName: 'Category Name', flex: 2 }];

  // Form Fields
  const fields: EmailTemplateFieldInput[] = [
    { field_name: 'template_name', display_name: 'Template Name', type: 'text', span: 4 },
    { field_name: 'template_code', display_name: 'Template Code', type: 'text', span: 4 },
    { field_name: 'email_subject', display_name: 'Subject', type: 'text', span: 4 },
    {
      field_name: 'category_id',
      display_name: 'Category',
      type: 'select',
      span: 4,
      options: categories?.map((u: any) => {
        return { key: u.category_name, value: u.id };
      }),
    },
  ];

  // Functions
  const getData = (query: ApiQuery) => {
    return getAll(query);
  };

  const handleSelectRow = (selected: any) => {
    setSelectedRow(selected);
  };

  const closeDrawer = () => {
    setSelectedRow(undefined);
  };

  const handleSubmit = async (data: any) => {
    if (selectedRow?.id)
      return updateTemplate(selectedRow?.id, data).then(() => {
        enqueueSnackbar('Template successfully updated!', { variant: 'success' });
        setForceRefreshTable((prev) => prev + 1);
        setSelectedRow(undefined);
      });
    return createTemplate(data).then(() => {
      enqueueSnackbar('Template successfully created!', { variant: 'success' });
      setForceRefreshTable((prev) => prev + 1);
    });
  };

  const handleSubmitEmailBody = async (data: any) => {
    if (selectedRow?.id)
      return updateTemplateBody(selectedRow?.id, data).then(() => {
        enqueueSnackbar('Template body successfully updated!', { variant: 'success' });
        setForceRefreshTable((prev) => prev + 1);
      });
  };

  const handleDelete = (data: any) => {
    deleteTemplate(data).then(() => {
      setForceRefreshTable((prev) => prev + 1);
      setSelectedRow(undefined);
    });
  };

  const getCategoryData = (query: ApiQuery) => {
    return getAllCategories(query);
  };

  const getCategories = () => {
    getAllCategories().then((categories) => {
      setCategories(categories.data.data);
    });
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Email Templates' }]);
    getCategories();
  }, []);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.DRAWER,
      parentHeader: 'Email Templates',
      parentSubHeader: 'Click the row to see more details.',
      childHeader: selectedRow ? `${selectedRow.template_name}` : '',
      parentWidth: 500,
      showChild: !!selectedRow,
      handleClose: closeDrawer,
      parentComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Email Template',
        doNotWaitForFacility: true,
        tableComponent: {
          columns,
          getData: getData,
          selectableRow: true,
          onSelectRow: handleSelectRow,
          forceRefresh: forceRefreshTable,
          headerComponent: (
            <Box display="flex" gap="10px">
              <RegularButton
                onClick={() => {
                  setOpen(true);
                }}
                label="Template Category"
              />
            </Box>
          ),
        },
        tableForm: {
          formFields: fields,
          formInitialValues: initialValues,
          formSchema: add_email_template_schema,
          formSubmitApiFunction: handleSubmit,
        },
        tableAction: {
          deleteApiFunction: handleDelete,
          includeUpdateAction: true,
          includeDeleteAction: true,
          includeAddButton: true,
          handleRowActionsClick(selectedRow) {
            setSelectedRow(selectedRow);
          },
        },
      } as PageBuilderTableType,
      childComponent: {
        type: BLOCK_TYPE.CUSTOM,
        block: (
          <Box margin="25px" gap="20px" width={'100%'}>
            <Box display="flex" flexDirection={'column'} width={'100%'} gap="20px">
              {selectedRow && (
                <CustomForm
                  onSubmit={handleSubmitEmailBody}
                  fields={[
                    { field_name: 'email_body', display_name: 'Email Body', type: 'rich_text', height: '200px' },
                  ]}
                  initialValues={{ email_body: selectedRow.email_body }}
                  loading={false}
                  submitButtonText="Save"
                />
              )}
            </Box>
          </Box>
        ),
      } as any,
    } as PageBuilderDrawerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />
      <CustomModal open={open} setOpen={setOpen} header="Template Category">
        <CareGoTable
          entityName="Template Category"
          doNotWaitForFacility
          tableComponent={{
            columns: category_columns,
            getData: getCategoryData,
            forceRefresh: forceRefreshTable,
            disableSelectionOnClick: true,
            hideSearch: true,
          }}
        />
      </CustomModal>
    </>
  );
};

export default EmailTemplates;
