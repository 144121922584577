import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'charts';

export const getCharts = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getChart = (query: ApiQuery, id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`, { params: query });
};

export const createChart = (data: any) => {
    return axios.post(`${baseURL}/${path}`, data);
};

export const updateCharts = (id: number, data: any) => {
    return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteChart = (id: number) => {
    return axios.delete(`${baseURL}/${path}/${id}`);
};