import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'outreaches';

export const getOutreaches = (facility_id: number, campaign_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/many/${campaign_id}`, { params: query });
};

export const getOutreach = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createOutreach = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const updateOutreach = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteOutreach = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateOutreachStatus = (facility_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/status/${id}`, data);
};
