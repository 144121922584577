import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getUserGroupById, getUserGroups } from 'company/api/user-groups';

const UserGroupSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity="User Group"
      getData={getUserGroups}
      getById={getUserGroupById}
      processOption={(data) => ({ key: data.description, value: data.id })}
      processNewRecord={(record) => record.user_group}
    />
  );
};

export default UserGroupSearchDropdown;
