import { Box, Button, Checkbox, FormControlLabel, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface DaysOfWeekDropdownProps {
  onApply: (dayToBeCopied: number, selectedDays: number[]) => void;
  selectedDay: string;
  disabled?: boolean;
}

const DaysOfWeekDropdown: React.FC<DaysOfWeekDropdownProps> = ({ onApply, selectedDay, disabled=false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const open = Boolean(anchorEl);
  const id = open ? 'days-of-week-dropdown-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDayToggle = (day: number) => {
    setSelectedDays((prevSelected) =>
      prevSelected.includes(day) ? prevSelected.filter((item) => item !== day) : [...prevSelected, day]
    );
  };

  const handleApply = () => {
    onApply(daysOfWeek.indexOf(selectedDay), selectedDays);
    handleClose();
  };

  useEffect(() => {
    setSelectedDays([]);
  }, [selectedDay]);

  return (
    <Box>
      <Button disabled={disabled ? disabled : false} onClick={handleClick} sx={{ color: 'gray', height: '100%' }}>
        <ContentCopyIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginRight: '1.5rem' }}
      >
        <Box style={{ padding: '16px' }}>
          <Typography variant="h6">COPY TIMES TO..</Typography>
          {daysOfWeek.map((day, index) => (
            <Box display="flex" flexDirection="column" margin={'0.2rem 0'} key={day + '0'}>
              <FormControlLabel
                key={day}
                control={
                  <Checkbox
                    key={day + '1'}
                    checked={selectedDays.includes(index) || day === selectedDay}
                    onChange={() => handleDayToggle(index)}
                  />
                }
                label={day}
              />
            </Box>
          ))}
          <Box marginTop="0.5rem">
            <Button
              variant="contained"
              onClick={handleApply}
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'center',
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DaysOfWeekDropdown;
