import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Health1: React.FC = () => {
  return (
    <Box>
      <Step>1. Go to the “Clinic Management” tab in sidebar. </Step>
      <Step>2. Select “Health Sessions" tab inside the Clinic Management. </Step>
      <Step>3. Click the “Add Health Session" button.</Step>
      <Image src="/assets/FaqAssets/healthpackage/1/1-3.png" alt="Step 1-2 Image" />

      <Step>4. Fill out the form </Step>
      <Image src="/assets/FaqAssets/healthpackage/1/4.png" alt="Image" />
      <Step>5. Click the “Submit” button </Step>
      <Image src="/assets/FaqAssets/healthpackage/1/5.png" alt="Image" />
    </Box>
  );
};

export default Health1;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
