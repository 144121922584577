/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { CustomForm, CustomModal } from 'core/components';

import { Box } from '@mui/material';
import { CompanyModel } from 'company/model/Entities';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { createFacility } from 'carego-admin/api/facility';
import { create_facility_schema } from 'carego-admin/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type CreateFacilityProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  company: CompanyModel;
  languages: any;
};

type UserInput = {
  facility_name: string;
  primary_language: number | string;
  region_name: string;
  province_name: string;
  municipality_name: string;
  address: string;
  smart_mobile_number: string;
  globe_mobile_number: string;
  email: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const CreateFacility: React.FC<CreateFacilityProps> = ({ open, setOpen, refreshTable, company, languages }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    facility_name: '',
    primary_language: company.primary_language,
    region_name: '',
    province_name: '',
    municipality_name: '',
    address: '',
    smart_mobile_number: '',
    globe_mobile_number: '',
    email: '',
  };

  // Form Fields
  const facilityFields: UserFieldInput[] = [
    { field_name: 'facility_name', display_name: 'Facility Name', type: 'text' },
    {
      field_name: 'primary_language',
      display_name: 'Language',
      type: 'select',
      options:
        languages &&
        languages.map((u: any) => {
          return { key: u.language, value: u.id };
        }),
    },
    {
      field_name: 'region_name',
      display_name: 'Region',
      type: 'text',
      // options:
      //   regions &&
      //   regions.map((u: any) => {
      //     return { key: u.name, value: u.id };
      //   }),
      // onChange: (value: string) => {
      //   setRegionCode(value);
      // },
    },
    {
      field_name: 'province_name',
      display_name: 'Province',
      type: 'text',
      // options: regionCode
      //   ? provinces &&
      //     provinces
      //       .filter(function (province: any) {
      //         return province.region_code === regionCode;
      //       })
      //       .map(function (province: any) {
      //         return { key: province.name, value: province.id };
      //       })
      //   : provinces &&
      //     provinces.map((u: any) => {
      //       return { key: u.name, value: u.id };
      //     }),
      // onChange: (value: string) => {
      //   setProvinceCode(value);
      // },
    },
    {
      field_name: 'municipality_name',
      display_name: 'Municipality',
      type: 'text',
      // options: provinceCode
      //   ? cities &&
      //     cities
      //       .filter(function (city: any) {
      //         return city.province_code === provinceCode;
      //       })
      //       .map(function (city: any) {
      //         return { key: city.name, value: city.id };
      //       })
      //   : cities &&
      //     cities.map((u: any) => {
      //       return { key: u.name, value: u.id };
      //     }),
    },
    { field_name: 'address', display_name: 'Address', type: 'text' },
    { field_name: 'smart_mobile_number', display_name: 'Smart Mobile Number (Optional)', type: 'text' },
    { field_name: 'globe_mobile_number', display_name: 'Globe Mobile Number (Optional)', type: 'text' },
    { field_name: 'email', display_name: 'Email (Optional)', type: 'text' },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    // data['municipality_name'] = capitalizeWord(getName(cities, data.municipality));
    // data['region_name'] = getName(regions, regionCode);
    // data['province_name'] = capitalizeWord(getName(provinces, provinceCode));

    createFacility(data, company.id)
      .then((res) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Facility successfully created!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  // useEffect(() => {
  //   getRegionList();
  //   getProvinceList();
  //   getCitiesList();
  // }, []);

  return (
    <CustomModal header={'Create Facility'} open={open} setOpen={setOpen}>
      <Box>
        <CustomForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          fields={facilityFields}
          schema={create_facility_schema}
          loading={buttonLoading}
        />
      </Box>
    </CustomModal>
  );
};

export default CreateFacility;
