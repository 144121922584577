import { Box, Divider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { calculateAge, formatArray } from 'core/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { ContainerColumn } from 'core/components/containers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityDetails } from './PrintablePatientNote';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { formatDate } from 'core/utils';
import { tokens } from 'theme/theme';

type DocumentHeaderProps = {
  mode: FORM_MODE;
  name: string;
  patient?: PatientModel;
  setName: (name: string) => void;
  note?: PatientNoteModel;
  autoSave: () => void;
  hideClinicHeader?: boolean;
};

const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  name,
  setName,
  mode,
  note,
  patient,
  autoSave,
  hideClinicHeader,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const prevName = useRef(name);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (!isNameFocused && prevName.current !== name) {
      autoSave();
      prevName.current = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNameFocused]);

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      setIsNamedFocused(false);
    }
  };

  return (
    <>
      <Box sx={{ padding: '6px 12px' }} mb="20px">
        {user && user.company && mode === FORM_MODE.VIEW && !hideClinicHeader && (
          <ContainerColumn sx={{ alignItems: 'center', mb: SIZES.paddingL, position: 'relative' }}>
            <img
              alt="profile-user"
              width="80px"
              height="auto"
              id="logo"
              style={isMobilePhone ? { display: 'none' } : { position: 'absolute', left: 0, top: 0 }}
              src={
                theme.palette.mode === 'light'
                  ? user.company?.company_logo
                    ? user.company?.company_logo
                    : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
                  : user.company?.company_logo_dark
                  ? user.company?.company_logo_dark
                  : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
              }
            />
            <ContainerColumn gap="5px" sx={{ width: isMobilePhone ? '100%' : '60%', textAlign: 'center' }}>
              <Typography variant="h2" fontWeight="bold" textAlign="center" sx={{ color: colors.accent }}>
                {facility.facility_name}
              </Typography>
              <FacilityDetails
                Icon={CallOutlinedIcon}
                label={formatArray([facility?.smart_mobile_number, facility?.globe_mobile_number], ' | ')}
              />
              <FacilityDetails Icon={EmailOutlinedIcon} label={facility.email} />
              <FacilityDetails
                Icon={LocationOnOutlinedIcon}
                label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
              />
            </ContainerColumn>
          </ContainerColumn>
        )}

        {mode !== FORM_MODE.VIEW && (isNameFocused || !name) ? (
          <TextField
            variant="standard"
            autoFocus
            // inputProps={{ className: classes.name }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyDown={keyPress}
            onBlur={(event) => setIsNamedFocused(false)}
            InputProps={{
              style: {
                fontWeight: '600',
                fontSize: '24px',
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {},
            }}
          />
        ) : (
          <Typography
            variant="h3"
            fontWeight="600"
            onClick={() => {
              setIsNamedFocused(true);
            }}
            sx={
              mode === FORM_MODE.VIEW
                ? undefined
                : {
                    '&:hover': {
                      borderBottom: '1px solid ' + colors.primary,
                      cursor: 'pointer',
                    },
                  }
            }
          >
            {name}
          </Typography>
        )}

        {note?.first_name && note.collection_name !== 'Medical Certificate' && (
          <Box
            paddingTop={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
              {patient && (
                <>
                  <PatientField label="First Name" value={patient.first_name} />
                  <PatientField label="Middle Name" value={patient.middle_name} />
                  <PatientField label="Last Name" value={patient.last_name} />
                  <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                  <PatientField label="Age" value={calculateAge(patient.birthday)} />
                  <PatientField label="Sex" value={patient.sex} />
                </>
              )}
              <Divider sx={{ gridColumn: 'span 2' }} />
            </Box>

            {patient && patient?.corporate_client_id && (
              <Box width="100%" marginTop={SIZES.paddingS}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '4px', mb: '10px' }}>
                  <BusinessCenterIcon color="primary" />
                  <Typography variant="h4" fontWeight={600}>
                    Employee Details
                  </Typography>
                </Box>
                <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
                  <PatientField label="Employee ID" value={patient.employee_id} />
                  <PatientField label="Department" value={patient.department_name} />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DocumentHeader;
