/* eslint-disable react-hooks/exhaustive-deps */

import { CustomForm, CustomModal } from 'core/components';
import { RESPONSE, showSnackbar } from 'constants/snackbar_message';
import { useContext, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { ProgramPatientModel } from 'company/model/Entities';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type EnrollModalProps = {
  header: string;
  programCode: string;
  selectedPatient?: ProgramPatientModel;
  selectedPatients?: ProgramPatientModel[];
  formFields: FieldInput[];
  initialValues: any;
  schema: any;
  modalWidth?: number;
  refreshTable?: () => void;
  setRefreshOtherTable?: (refresh: any) => void; // table in the modal
  open: boolean;
  setOpen: (open: boolean) => void;
  enroll: (facility_id: number, program_code: string, patient_data: any) => Promise<AxiosResponse<any, any>>;
  updatePatient: (facility_id: number, id: number, patient: any) => Promise<AxiosResponse<any, any>>;
  footerComponent?: React.ReactNode;
  setClearSelected?: (prev: any) => void;
  date?: Date;
};

const PatientFormModal: React.FC<EnrollModalProps> = ({
  header,
  programCode,
  selectedPatient,
  formFields,
  initialValues,
  schema,
  modalWidth,
  refreshTable,
  setRefreshOtherTable,
  open,
  setOpen,
  enroll,
  updatePatient,
  footerComponent,
  selectedPatients,
  setClearSelected,
  date,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    initialValues.schedule = date ?? '';
  }, [date]);

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    if ((selectedPatients && selectedPatients.length > 0) || selectedPatient) {
      try {
        setButtonLoading(true);
        if (initialValues.id) {
          const res = await updatePatient(facility.id, initialValues.id, data);
          successFormSubmit(res, formikHelpers);
        } else {
          const final = { ...data };

          const patients = JSON.stringify(
            selectedPatients && selectedPatients.length > 0 ? selectedPatients : [selectedPatient?.patient_id]
          );
          final.patients = patients;

          const res = await enroll(facility.id, programCode, final);
          successFormSubmit(res, formikHelpers);
        }
      } catch (error: any) {
        if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
        setButtonLoading(false);
        showSnackbar(RESPONSE.ERROR, enqueueSnackbar, { customMessage: 'Error occured! Please try again.' });
      }
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      showSnackbar(RESPONSE.SUCCESS, enqueueSnackbar, { entityName: 'Patient', action: 'enrolled' });
      setOpen(false);
      setClearSelected && setClearSelected((prev: number) => prev + 1);
    } else if (res.response.status === 400) {
    }
    setButtonLoading(false);
    refreshTable && refreshTable();
    setRefreshOtherTable && setRefreshOtherTable((prev: number) => prev + 1);
  };

  return (
    <CustomModal
      header={`${header} ${
        selectedPatients && selectedPatients.length > 0
          ? selectedPatients?.length + ' Patients'
          : selectedPatient?.full_name
      }`}
      open={open}
      setOpen={setOpen}
      width={modalWidth}
    >
      <CustomForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        fields={formFields}
        schema={schema}
        loading={buttonLoading}
        footerComponent={footerComponent}
        submitButtonId="add-cycle-to-patient-submit-button"
      />
    </CustomModal>
  );
};

export default PatientFormModal;
