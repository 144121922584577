import { ApiQuery } from 'core/model/interface';
import { Patients } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-packages-patients';

export const getHealthPackagePatient = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getHealthPackagePatients = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const enrollHealthPackagePatients = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const removePatientsInHealthPackage = (facility_id: number, patients: Patients) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}`, { params: patients });
};

export const getClientHealthPackages = (facility_id: number, patient_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/client-packages/${patient_id}`, {
    params: query,
  });
};

export const proceedToNextStage = (id: number) => {
  return axios.post(`${baseURL}/${path}/proceed/${id}`);
};

export const getHomepagePatients = (clinic_id?: number) => {
  return axios.get(`${baseURL}/${path}/homepage-clients`);
};

export const setHealthPackageAsComplete = (id: number) => {
  return axios.put(`${baseURL}/${path}/completed/${id}`);
};
