import { FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Box } from '@mui/material';
import { RegularButton } from 'core/components';
import CustomDatePicker from 'core/components/CustomDatePicker';
import React, { useContext, useState } from 'react';
import BookIcon from '@mui/icons-material/Book';
import DownloadIcon from '@mui/icons-material/Download';

import { getMany } from 'company/api/account-transactions';
import { FacilityContext } from 'core/context/facility.context';
import { formatNumericalDate } from 'core/utils';

type GenerateReportProp = {
  startDate: Date;
  endDate: Date;
  setStartDate: (state: Date) => void;
  setEndDate: (state: Date) => void;
  setReportData: (state: any) => void;
  setReportTypeCopy?: (state: string) => void;
  onPrintHandle?: () => void;
};

const GenerateReportDropDown: React.FC<GenerateReportProp> = (prop) => {
  const { startDate, endDate, setEndDate, setStartDate, setReportData, setReportTypeCopy, onPrintHandle } = prop;
  const { facility } = useContext(FacilityContext);

  const [reportType, setReportType] = useState('');
  const [reportPeriod, setReportPeriod] = useState('');
  const [downloadable, setDownloadable] = useState(false);

  const getDateRange = (selectedRange: string) => {
    const today = new Date();
    let localStartDate, localEndDate;

    switch (selectedRange) {
      case 'week':
        localStartDate = new Date(today);
        localStartDate.setDate(today.getDate() - today.getDay());
        localEndDate = new Date(today);
        localEndDate.setDate(localStartDate.getDate() + 6);
        break;
      case 'last-week':
        localStartDate = new Date(today);
        localStartDate.setDate(today.getDate() - today.getDay() - 7);
        localEndDate = new Date(today);
        localEndDate.setDate(localStartDate.getDate() + 6);
        break;
      case 'month':
        localStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
        localEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'last-month':
        localStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        localEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'quarter':
        const quarter = Math.floor(today.getMonth() / 3);
        localStartDate = new Date(today.getFullYear(), quarter * 3, 1);
        localEndDate = new Date(today.getFullYear(), quarter * 3 + 3, 0);
        break;
      case 'last-quarter':
        const lastQuarter = Math.floor((today.getMonth() - 3) / 3);
        localStartDate = new Date(today.getFullYear(), lastQuarter * 3, 1);
        localEndDate = new Date(today.getFullYear(), lastQuarter * 3 + 3, 0);
        break;
      case 'year':
        localStartDate = new Date(today.getFullYear(), 0, 1);
        localEndDate = new Date(today.getFullYear(), 11, 31);
        break;
      case 'last-year':
        localStartDate = new Date(today.getFullYear() - 1, 0, 1);
        localEndDate = new Date(today.getFullYear() - 1, 11, 31);
        break;
      default:
        localStartDate = new Date(today);
        localEndDate = new Date(today);
        break;
    }

    return { localStartDate, localEndDate };
  };

  return (
    <>
      <Box sx={{ display: 'flex', columnGap: '1vh' }}>
        {/* DROPDOWN FOR REPORT TYPE */}
        <FormControl sx={{ width: '30%' }} size="small">
          <InputLabel>Generate Report</InputLabel>
          <Select
            value={reportType}
            label="Report Type"
            onChange={(event: SelectChangeEvent) => {
              setReportType(event.target.value);
              setReportData(undefined);
              setDownloadable(false);
              setReportTypeCopy && setReportTypeCopy(event.target.value);
            }}
          >
            <MenuItem sx={{ color: 'gray' }} value={''}>
              Generate Report
            </MenuItem>
            <MenuItem value={'ledger'}>Generate Ledger</MenuItem>
            <MenuItem value={'journal'}>Journal Entries</MenuItem>
          </Select>
        </FormControl>

        {/* REPORT PERIOD */}
        <FormControl sx={{ width: '30%' }} disabled={reportType === ''} size="small">
          <InputLabel>{`Report Period ${reportType !== '' ? `(${reportType})` : ''}`}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reportPeriod}
            label="Report Period"
            onChange={(event: SelectChangeEvent) => {
              const { localStartDate, localEndDate } = getDateRange(event.target.value);
              setReportPeriod(event.target.value);
              setStartDate(localStartDate as Date);
              setEndDate(localEndDate as Date);
              setReportData(undefined);
              setDownloadable(false);
            }}
          >
            <MenuItem sx={{ color: 'gray' }} value={''}>
              Report Period
            </MenuItem>
            <MenuItem value={'today'}>Today</MenuItem>
            <MenuItem value={'week'}>This Week</MenuItem>
            <MenuItem value={'last-week'}>Last Week</MenuItem>
            <MenuItem value={'month'}>This Month</MenuItem>
            <MenuItem value={'last-month'}>Last Month</MenuItem>
            <MenuItem value={'quarter'}>This Quarter</MenuItem>
            <MenuItem value={'last-quarter'}>Last Quarter</MenuItem>
            <MenuItem value={'year'}>This Year</MenuItem>
            <MenuItem value={'last-year'}>Last Year</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: '50vh', columnGap: '1vh', marginTop: '1vh', display: reportPeriod === '' ? 'none' : 'flex' }}>
        <CustomDatePicker
          fieldName=""
          label="Start Date"
          value={startDate}
          setFieldValue={(field: string, value: any) => {
            setStartDate(new Date(value['$d']));
            setReportData(undefined);
            setDownloadable(false);
          }}
        />
        <CustomDatePicker
          fieldName=""
          label="End Date"
          value={endDate}
          setFieldValue={(field: string, value: any) => {
            setEndDate(new Date(value['$d']));
            setReportData(undefined);
            setDownloadable(false);
          }}
        />
      </Box>
      <Box sx={{ marginTop: '2vh', marginBottom: '2vh' }} />
      <RegularButton
        label="Generate Report"
        disabled={!(reportType !== '' && reportPeriod !== '')}
        startIcon={<BookIcon />}
        onClick={() => {
          getMany(facility.id, {
            start_date: formatNumericalDate(startDate),
            end_date: formatNumericalDate(endDate),
          }).then((response) => {
            response.data.data = response.data.data.map((reports: any) => ({
              ...reports,
              reference_id: reports.invoice_number,
            }));
            setReportData(response.data.data);
            setDownloadable(true);
          });
        }}
      />
      <RegularButton
        styles={{ marginLeft: '1vh' }}
        label="Download PDF"
        disabled={!downloadable}
        startIcon={<DownloadIcon />}
        onClick={() => {
          onPrintHandle && onPrintHandle();
        }}
      />
    </>
  );
};

export default GenerateReportDropDown;
