import {
  AdminDashboard,
  CareGoBillingInvoices,
  CareGoBillingServices,
  CareGoSMSTemplates,
  Charts,
  ClientManagement,
  HealthPrograms,
  LguManagement,
  SMS,
  SMSCredits,
  TemplatePage,
} from 'carego-admin/screens';

import { AccountType } from 'core/model/Entities';
import BlockedIPs from 'carego-admin/screens/CaregoSecurity/BlockedIPs';
import CareGoEmailTemplates from 'carego-admin/screens/CareGoEmailTemplates';
import CompanyUserManagement from 'company/screens/CompanyUserManagement';
import { DocumentEditor } from 'company/screens';
import FlaggedRequests from 'carego-admin/screens/CaregoSecurity/FlaggedRequests';
import { ProtectedRoute } from 'core/components';
import RequestLogs from 'carego-admin/screens/CaregoSecurity/RequestLogs';
import { Route } from 'react-router-dom';
import SecurityDashboard from 'carego-admin/screens/CaregoSecurity/Dashboard';

const CareGoAdminRoutes = (
  <Route path="/admin" element={<ProtectedRoute accountType={AccountType.CAREGO} />}>
    <Route index element={<AdminDashboard />} />
    <Route path="lgu" element={<LguManagement />} />
    <Route path="client" element={<ClientManagement />} />
    <Route path="sms-credits" element={<SMSCredits />} />

    <Route path="user-management">
      <Route index element={<CompanyUserManagement />} />
    </Route>

    <Route path="templates">
      <Route index element={<TemplatePage />} />
      <Route path=":initialMode/:id" element={<DocumentEditor source="admin" />} />
    </Route>

    <Route path="charts">
      <Route path=":id" element={<Charts />} />
    </Route>
    <Route path="programs" element={<HealthPrograms />} />
    <Route path="carego-sms" element={<CareGoSMSTemplates />} />
    <Route path="carego-email" element={<CareGoEmailTemplates />} />
    <Route path="lgu-sms" element={<SMS />} />
    <Route path="billing-services" element={<CareGoBillingServices />} />
    <Route path="billing-invoices" element={<CareGoBillingInvoices />} />

    {/* firewall, logs, and security */}
    <Route path="security">
      <Route index element={<SecurityDashboard />} />
      <Route path="logs" element={<RequestLogs />} />
      <Route path="flagged-requests" element={<FlaggedRequests />} />
      <Route path="blocked-ips" element={<BlockedIPs />} />
      <Route path="dashboard" element={<SecurityDashboard />} />
    </Route>
  </Route>
);

export default CareGoAdminRoutes;
