import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'firewall/blocked-ip';

export const getAll = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const deleteOne = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
