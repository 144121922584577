import GroupsIcon from '@mui/icons-material/Groups';

const steps_filter = [
  { label: 'All Patients', icon: <GroupsIcon /> },
  { label: 'Chest X-ray', icon: 1, headerTitle: 'Stage 1: Chest X-ray' },
  { label: 'Sputum Test', icon: 2, headerTitle: 'Stage 2: Sputum Test' },
  { label: 'Doctor Appointment', icon: 3, headerTitle: 'Stage 3: Doctor Appointment' },
  { label: 'TB-DOTS Enrollment', icon: 4, headerTitle: 'Stage 4: TB-DOTS Enrollment' },
];

const acf_steps = [
  { label: 'Chest X-ray', icon: 1, headerTitle: 'Stage 1: Chest X-ray' },
  { label: 'Sputum Test', icon: 2, headerTitle: 'Stage 2: Sputum Test' },
  { label: 'Doctor Appointment', icon: 3, headerTitle: 'Stage 3: Doctor Appointment' },
  { label: 'TB-DOTS Enrollment', icon: 4, headerTitle: 'Stage 4: TB-DOTS Enrollment' },
];

const tb_dots_steps_filter = [
  { label: 'All Patients', icon: <GroupsIcon /> },
  { label: 'Intensive', icon: 1 },
  { label: 'Week 8 Sputum Test', icon: 2 },
  { label: 'Week 20 Sputum Test', icon: 3 },
  { label: 'Completed', icon: 4 },
];

export { steps_filter, acf_steps, tb_dots_steps_filter };
