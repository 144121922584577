import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Reg4: React.FC = () => {
  return (
    <Box>
      <Step>1. In Patient Management tab, select the patient you want to delete and click the dropdown icon.</Step>

      <Image src="/assets/FaqAssets/clientreg/4/1.png" alt="Image" />

      <Step>2. Click “View Clieny” button in the dropdown options.</Step>
      <Image src="/assets/FaqAssets/clientreg/4/2.png" alt="Image" />
      <Step>
        3. After Clicking the "View Patient" you will be redirected to the new page which contains the Patient History
        (Appointments, Health Programs, Campaigns)
      </Step>
      <Image src="/assets/FaqAssets/clientreg/4/3.png" alt="Image" />
      <Image src="/assets/FaqAssets/clientreg/4/3.1.png" alt="Image" />
    </Box>
  );
};

export default Reg4;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
