import { Box } from '@mui/material';
import PatientNoteSummaryTemplateTable from 'company/entities/modules/ClinicManagement/PatientNoteSummaryTemplate/PatientNoteSummaryTemplateTable';
import React from 'react';

export default function PatientNoteSummaryTemplate() {
  return (
    <Box>
      <PatientNoteSummaryTemplateTable />
    </Box>
  );
}
