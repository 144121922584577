import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'dashboard';

export const getDashboardStat = () => {
  return axios.get(`${baseURL}/${path}/stat`);
};

export const getPatientCount = () => {
  return axios.get(`${baseURL}/${path}/patient-count`);
};

export const getProgramPerformedCount = () => {
  return axios.get(`${baseURL}/${path}/program-count`);
};

export const getPendingSMSCount = () => {
  return axios.get(`${baseURL}/${path}/pending-sms-count`);
};

export const getSMSCredit = () => {
  return axios.get(`${baseURL}/${path}/sms-credit`);
};
