import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getProductCategories, getProductCategory } from 'company/api/product-categories';

const ProductCategorySearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Product Category'}
      getData={getProductCategories}
      getById={getProductCategory}
      processOption={(record) => ({ key: record.category, value: record.id })}
      processNewRecord={(record) => record.category}
    />
  );
};

export default ProductCategorySearchDropdown;
