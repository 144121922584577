import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-assessments-service-status';

export const getAllHealthAssessmentServices = (assessment_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${assessment_id}`, { params: query });
};

export const getAssessmentServiceNotAdded = (facility_id: number, assessment_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/get-services-not-added/${facility_id}/${assessment_id}`, { params: query });
};

export const addAssessmentServices = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const removeAssessmentService = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
