import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';

export const SERVICE_CATEGORY_ENTITY_NAME = 'Service Category';

export const serviceCategorySchema = yup.object().shape({
  service_category_name: yup
    .string()
    .required('Service category name is required')
    .max(50, 'Must be at most 50 characters'),
});

export const serviceCategoryFields: EntityFields[] = [
  {
    fieldName: 'service_category_name',
    displayName: 'Service Category Name',
    type: 'string',
  },
];

export interface ServiceCategoryInput {
  service_category_name: string;
}

export const serviceCategoryInitialValues: ServiceCategoryInput = {
  service_category_name: '',
};

export interface ServiceCategoryModel {
  id: number;
  service_category_name: string;
}

// table
export const serviceCategoryColumnTable: string[] = [];

// form
export const serviceCategoryFormFields: string[] = ['service_category_name'];
export const serviceCategoryUpdateFormFields: string[] = ['service_category_name'];
