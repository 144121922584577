import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import PaymentTable from 'company/entities/modules/ClinicManagement/Payment/PaymentTable';

const Payments = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([{ label: 'Payments' }]);
  }, [setBreadcrumb]);

  return (
    <CareGoPage header="Payments">
      <PaymentTable visibleColumns={['payment_date', 'amount', 'payment_method', 'invoice_number', 'full_name']} />
    </CareGoPage>
  );
};

export default Payments;
