import { SxProps, Theme, useMediaQuery } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RegularButton from './RegularButton';

type PrimaryButtonProps = {
  label?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  id?: string;
  width?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  styles?: SxProps<Theme>;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label = 'Submit',
  onClick,
  disabled,
  id,
  fullWidth,
  size,
  variant,
  styles,
}) => {
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <RegularButton
      onClick={onClick}
      variant={variant ?? 'contained'}
      fullWidth={fullWidth}
      id={id}
      startIcon={<AddIcon style={{ marginRight: isMobile ? '-12px' : '' }} />}
      disabled={disabled}
      label={label}
      size={size}
      styles={styles}
    />
  );
};

export default PrimaryButton;
