import { Box, Typography, useTheme } from '@mui/material';

import { tokens } from 'theme/theme';

type StatBoxProps = {
  title: string | number;
  subtitle: string;
  icon: JSX.Element;
};

const StatBox: React.FC<StatBoxProps> = ({ title, subtitle, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" p="30px" display="flex" alignItems="center">
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="20px">
        {icon}
        <Box>
          <Typography variant="h4" component="span" fontWeight="bold" sx={{ color: colors.text, marginRight: '10px' }}>
            {title}
          </Typography>
          <Typography variant="h5" component="span" fontWeight="bold" sx={{ color: colors.accent }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
