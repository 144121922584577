import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getPatient, getPatients } from 'company/api/patient';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';
import { UserContext } from 'core/context/user.context';
import { getCompanyFacilities } from 'company/api/facility';

const FacilitySearchDropDown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  // async function getClinics() {
  //   return new Promise((resolve, reject) => {
  //     if (!user) {
  //       return resolve([]);
  //     }
  //     setTimeout(() => {
  //       resolve({ data: { data: user.facilities } });
  //     }, 100);
  //   });
  // }

  function getData(query: ApiQuery) {
    return getCompanyFacilities(query);
  }

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        multiple
        hideAddOption
        entity={'Clinics'}
        getData={(query: ApiQuery) => getData(query)}
        fieldName="facility_id"
        processOption={(record) => {
          return { key: record.facility_name, value: record.id, data: record };
        }}
      />
    </WaitForFacility>
  );
};

export default FacilitySearchDropDown;
