/* eslint-disable no-template-curly-in-string */

import 'react-quill/dist/quill.snow.css';

import { Box, useTheme } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import {
  formatArray,
  formatDate,
  formatDateTime,
  formatDateTimeWithDay,
  formatDateWithCompleteMonth,
  formatTime,
} from 'core/utils';
import { useContext, useEffect, useState } from 'react';

import { Components } from '..';
import DOMPurify from 'dompurify';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { ImageResize } from 'quill-image-resize-module-ts';
import moment from 'moment';
import { tokens } from 'theme/theme';

Quill.register('modules/imageResize', ImageResize);

type DocumentRichTextProps = {
  mode?: FORM_MODE;
  component: Components;
  patientNotes?: boolean;
  patient?: any;
  appointment?: any;
  provider?: any;
  halfWidth?: boolean;
  handleChangeCallback?: (value: string) => void;
  isBodyText?: boolean;
};
const DocumentRichText: React.FC<DocumentRichTextProps> = ({
  mode,
  component,
  patientNotes,
  patient,
  appointment,
  provider,
  halfWidth,
  handleChangeCallback,
  isBodyText,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [value, setValue] = useState<string>('');
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ header: [1, 2, 3, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    // ['undo', 'redo'],
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ direction: 'rtl' }], // text direction

    // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

    // ['clean'], // remove formatting button
  ];

  // function undoChange() {
  //   quill.history.undo();
  // }
  // function redoChange() {
  //   this.quill.history.redo();
  // }

  const module = {
    toolbar: toolbarOptions,
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const handleChange = (value: any) => {
    component.content = value;
    setValue(value);
    handleChangeCallback && handleChangeCallback(value);
  };

  // useEffect(() => {
  //   if ((patientNotes || isBodyText) && component.content) {
  //     setValue(
  //       component.content
  //         .replace('${Patient - Full Name}', patient?.full_name ? `${patient?.first_name} ${patient?.last_name}` : '${Patient - Full Name}')
  //         .replace('${Patient - Full Name (All Caps)}', provider?.first_name && provider?.last_name ? `${patient?.first_name.toUpperCase()} ${patient?.last_name.toUpperCase()}` : '${Patient - Full Name (All Caps)}')
  //         .replace(
  //           '${Patient - Age}',
  //           patient?.birthday ? `${moment().diff(moment(patient.birthday), 'years')} years old` : '${Patient - Age}'
  //         )
  //         .replace('${Patient - Birthday}', patient?.birthday ? formatDate(patient.birthday) : '${Patient - Birthday}')
  //         .replace('${Patient - Sex}', patient?.sex ? patient.sex : '${Patient - Sex}')
  //         .replace('${Patient - Address}', patient?.address ? patient.address : '${Patient - Address}')
  //         .replace('${Doctor - Full Name}',
  //           provider?.full_name ? `${provider.first_name} ${provider.last_name}` : '${Doctor - Full Name}')
  //         .replace('${Doctor - Full Name (All Caps)}',
  //           provider?.first_name && provider?.last_name
  //             ? `${provider.first_name.toUpperCase()} ${provider.last_name.toUpperCase()}`
  //             : '${Doctor - Full Name (All Caps)}')
  //         .replace(
  //           '${Doctor - License Number}',
  //           provider?.license_number ? provider.license_number : '${Doctor - License Number}'
  //       )
  //         .replace(
  //           '${Doctor - PTR Number}',
  //           provider?.ptr_number ? provider.ptr_number : '${Doctor - PTR Number}'
  //         )
  //         .replace(
  //           '${Doctor - Contact Number}',
  //           provider
  //             ? formatArray([provider.mobile_number_1, provider.mobile_number_2], ' / ')
  //             : '${Doctor - Contact Number}'
  //         )
  //         .replace('${Doctor - Email}', provider?.email ? provider.email : '${Doctor - Email}')
  //         .replace('${Clinic - Name}', facility?.facility_name ? facility.facility_name : '${Clinic - Name}')
  //         .replace('${Clinic - Type}', facility?.facility_type ? facility.facility_type : '${Clinic - Type}')
  //         .replace('${Clinic - Address}', facility?.address ? facility.address : '${Clinic - Address}')
  //         .replace(
  //           '${Appointment - Schedule}',
  //           appointment?.schedule ? formatDateTimeWithDay(appointment.schedule) : '${Appointment - Schedule}'
  //         )
  //         .replace(
  //           '${Appointment - Service}',
  //           appointment?.service_names ? appointment.service_names : '${Appointment - Service}'
  //         )
  //         .replace(
  //           '${Current Date}',
  //           formatDateWithCompleteMonth(new Date())
  //         )
  //         .replace(
  //           '${Current Date and Time}',
  //           formatDateTime(new Date())
  //         )
  //         .replace(
  //           '${Current Time}',
  //           formatTime(new Date())
  //       )
  //     );
  //   } else {
  //     setValue(component.content!);
  //   }
  // }, [patientNotes, component, patient, provider, facility, appointment, isBodyText]);

  const replaceTokens = (content: string) => {
    if (!content) return '';

    const replacements = {
      '${Patient - Full Name}': patient?.full_name
        ? `${patient.first_name} ${patient.last_name}`
        : '${Patient - Full Name}',
      '${Patient - Full Name (All Caps)}':
        patient?.first_name && patient?.last_name
          ? `${patient.first_name.toUpperCase()} ${patient.last_name.toUpperCase()}`
          : '${Patient - Full Name (All Caps)}',
      '${Patient - Age}': patient?.birthday
        ? `${moment().diff(moment(patient.birthday), 'years')} years old`
        : '${Patient - Age}',
      '${Patient - Birthday}': patient?.birthday ? formatDate(patient.birthday) : '${Patient - Birthday}',
      '${Patient - Sex}': patient?.sex || '${Patient - Sex}',
      '${Patient - Address}': patient?.address || '${Patient - Address}',
      '${Doctor - Full Name}': provider?.full_name
        ? `${provider.first_name} ${provider.last_name}`
        : '${Doctor - Full Name}',
      '${Doctor - Full Name (All Caps)}':
        provider?.first_name && provider?.last_name
          ? `${provider.first_name.toUpperCase()} ${provider.last_name.toUpperCase()}${
              provider.title ? ', ' + provider.title.toUpperCase() : ''
            }`
          : '${Doctor - Full Name (All Caps)}',
      '${Doctor - License Number}': provider?.license_number || '${Doctor - License Number}',
      '${Doctor - PTR Number}': provider?.ptr_number || '${Doctor - PTR Number}',
      '${Doctor - Contact Number}': provider
        ? formatArray([provider.mobile_number_1, provider.mobile_number_2], ' / ')
        : '${Doctor - Contact Number}',
      '${Doctor - Email}': provider?.email || '${Doctor - Email}',
      '${Clinic - Name}': facility?.facility_name || '${Clinic - Name}',
      '${Clinic - Type}': facility?.facility_type || '${Clinic - Type}',
      '${Clinic - Address}': facility?.address || '${Clinic - Address}',
      '${Appointment - Schedule}': appointment?.schedule
        ? formatDateTimeWithDay(appointment.schedule)
        : '${Appointment - Schedule}',
      '${Appointment - Service}': appointment?.service_names || '${Appointment - Service}',
      '${Current Date}': mode === FORM_MODE.EDIT ? '${Current Date}' : formatDateWithCompleteMonth(new Date()),
      '${Current Date and Time}': mode === FORM_MODE.EDIT ? '${Current Date and Time}' : formatDateTime(new Date()),
      '${Current Time}': mode === FORM_MODE.EDIT ? '${Current Time}' : formatTime(new Date()),
    };

    let replacedContent = content;
    Object.entries(replacements).forEach(([token, replacement]) => {
      replacedContent = replacedContent.replace(
        new RegExp(token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
        replacement
      );
    });

    return replacedContent;
  };

  useEffect(() => {
    // Always replace tokens regardless of mode, but only if we have content
    if (component.content) {
      setValue(replaceTokens(component.content));
    } else {
      setValue('');
    }
  }, [patientNotes, component, patient, provider, facility, appointment, isBodyText]);

  if (mode !== FORM_MODE.EDIT) {
    return (
      <Box sx={{ 'p.ql-align-center': { margin: 0, textAlign: 'center' } }}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& .quill': {
          borderRadius: '10px',
        },
        '& .ql-container': {
          minHeight: mode !== FORM_MODE.EDIT ? undefined : '200px',
          border: 'none',
          borderBottom: mode !== FORM_MODE.EDIT ? 'none' : '1px solid ' + colors.border,
        },
        '& .ql-editor.ql-blank::before': {
          left: 'unset',
          right: 'unset',
        },
        '& .ql-editor': {
          padding: isBodyText ? (mode === FORM_MODE.EDIT ? '12px 0px' : '0px') : '24px 12px',
          lineHeight: '2',
        },
        '& .ql-toolbar': {
          display: mode !== FORM_MODE.EDIT ? 'none' : halfWidth ? 'grid' : 'flex',
          gridTemplateColumns: halfWidth ? '1fr 1fr 1fr' : undefined,
          borderRadius: '10px',
          // border: '1px solid ' + colors.border,
          border: 'none',
          backgroundColor: colors.light_blue_background,
          padding: '10px',
          justifyContent: 'center',
        },
        '& .ql-snow .ql-formats': {
          display: 'flex',
        },
      }}
    >
      <ReactQuill theme="snow" value={value} onChange={handleChange} modules={module} placeholder="Write here" />
    </Box>
  );
};

export default DocumentRichText;
