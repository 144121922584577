/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField, Typography } from '@mui/material';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { ThemeProvider } from '@mui/material/styles';
import { createFeedback } from 'company/api/feedback';
import { getFacilityBookingDetails } from 'company/api/facility';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

type FeedbackData = {
  [key: string]: number | null | string;
  Overall_experience: number | null;
  Appointment_process: number | null;
  Wait_time: number | null;
  Medical_care: number | null;
  Cleanliness_and_comfort: number | null;
  Staff_interaction: number | null;
  Suggestions_for_improvement: string;
};

const customIcons: {
  [index: number]: {
    icon: (currentValue: number | null) => React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (currentValue) => <SentimentVeryDissatisfiedIcon color={currentValue === 1 ? 'error' : 'disabled'} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: (currentValue) => <SentimentDissatisfiedIcon color={currentValue === 2 ? 'error' : 'disabled'} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: (currentValue) => <SentimentSatisfiedIcon color={currentValue === 3 ? 'warning' : 'disabled'} />,
    label: 'Neutral',
  },
  4: {
    icon: (currentValue) => <SentimentSatisfiedAltIcon color={currentValue === 4 ? 'success' : 'disabled'} />,
    label: 'Satisfied',
  },
  5: {
    icon: (currentValue) => <SentimentVerySatisfiedIcon color={currentValue === 5 ? 'success' : 'disabled'} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps & { currentValue: number | null }) {
  const { value, currentValue, ...other } = props;
  return (
    <StyledIconContainer {...other}>
      {customIcons[value].icon(currentValue)}
      <StyledLabel>{customIcons[value].label}</StyledLabel>
    </StyledIconContainer>
  );
}

const Feedback = () => {
  const { facility_code } = useParams<any>();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [feedbackData, setFeedbackData] = useState<FeedbackData>({
    Overall_experience: null,
    Appointment_process: null,
    Wait_time: null,
    Medical_care: null,
    Cleanliness_and_comfort: null,
    Staff_interaction: null,
    Suggestions_for_improvement: '',
  });
  const [clinic, setClinic] = useState<any>();

  const handleRatingChange = (key: string, value: number | null) => {
    setFeedbackData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    const data = {
      overall_experience: feedbackData.Overall_experience,
      appointment_process: feedbackData.Appointment_process,
      wait_time: feedbackData.Wait_time,
      medical_care: feedbackData.Medical_care,
      cleanliness_and_comfort: feedbackData.Cleanliness_and_comfort,
      staff_interaction: feedbackData.Staff_interaction,
      suggestions_for_improvement: feedbackData.Suggestions_for_improvement,
    };
    if (clinic.id) createFeedback(Number(clinic.id), data);
  };

  const getClinicDetails = async () => {
    if (!facility_code) return;
    try {
      const { data } = await getFacilityBookingDetails(facility_code);
      setClinic(data);
    } catch (error) {}
  };

  useEffect(() => {
    getClinicDetails();
  }, [facility_code]);
  
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ padding: '20px', textAlign: 'center', fontWeight: 'bold', fontSize: '19px' }}
        >
          Provide Your Feedback to Our Clinic
        </Typography>

        {clinic && (
          <div
            style={{
              marginBottom: '20px',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              <StyledBusinessIcon />
              {clinic.facility_name}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <StyledLocationOnIcon />
              {clinic.address}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              <StyledPhoneIcon />
              Contact: {clinic.smart_mobile_number}
            </Typography>
          </div>
        )}

        {[
          'Overall_experience',
          'Appointment_process',
          'Wait_time',
          'Medical_care',
          'Cleanliness_and_comfort',
          'Staff_interaction',
        ].map((key) => (
          <FeedbackSection key={key}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ padding: '10px', color: '#6cc5b0;', fontWeight: 'bold' }}
            >
              {key.replace(/_/g, ' ')}
            </Typography>
            <StyledRating
              name={key}
              value={feedbackData[key as keyof FeedbackData] as number | null}
              onChange={(_, value) => handleRatingChange(key, value)}
              IconContainerComponent={(props) => (
                <IconContainer {...props} currentValue={feedbackData[key as keyof FeedbackData] as number | null} />
              )}
              getLabelText={(value: number) => customIcons[value].label}
              highlightSelectedOnly
            />
          </FeedbackSection>
        ))}

        <FeedbackWrapper>
          <TextField
            label="Suggestions for Improvement"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={feedbackData.suggestions_for_improvement}
            onChange={(e) => setFeedbackData((prev) => ({ ...prev, Suggestions_for_improvement: e.target.value }))}
          />
        </FeedbackWrapper>

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ backgroundColor: colors.primary }}>
          Submit Feedback
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default Feedback;

const StyledLabel = styled.span`
  font-size: 0.8em;
  padding: 0 5px 0 5px;
  color: #777;
  text-align: center;
  margin-top: 8px;
`;
const Container = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  overflow: auto;
  @media (max-width: 768px) {
    padding: 10px;
    max-width: 95%;
  }
`;

const FeedbackSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme?.palette?.action?.disabled || 'defaultColorHere',
  },
}));
const FeedbackWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px 0 20px 0;
  align-text: center;
`;
const StyledIconContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-size: 15px;
  width: 100px;
  @media (max-width: 768px) {
    width: 60px;
  }
  @media (max-width: 300px) {
    width: 30px;
  }
`;
const StyledBusinessIcon = styled(BusinessIcon)`
  margin-right: 10px;
  color: #6cc5b0;
`;

const StyledLocationOnIcon = styled(LocationOnIcon)`
  margin-right: 10px;
  color: #6cc5b0;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  margin-right: 10px;
  color: #6cc5b0;
`;
