import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RegularButton from './RegularButton';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onClick) {
      onClick();
    } else {
      const previousPath = localStorage.getItem('previousPath');
      if (previousPath) {
        // Optionally, clear the saved path if you only want to go back once
        localStorage.removeItem('previousPath');
        // Navigate to the previous path
        navigate(previousPath);
      } else {
        // Navigate back in history if no previous path is found
        navigate(-1);
      }
    }
  };

  return (
    <RegularButton
      onClick={handleBackClick}
      label="Back"
      startIcon={<ArrowBackIosIcon />}
      size="small"
      variant="text"
      color="inherit"
    />
  );
};

export default BackButton;
