import ChartOfAccountDrawer from 'company/entities/modules/InventorySystem/ChartOfAccount/ChartOfAccountDrawer';
import ChartOfAccountForm from 'company/entities/modules/InventorySystem/ChartOfAccount/ChartOfAccountForm';
import { CareGoPage } from 'core/PageBuilder';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { useContext, useEffect, useState } from 'react';

const ChartOfAccount = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    setBreadcrumb([{ label: 'Chart of Accounts' }]);
  }, [setBreadcrumb]);

  return (
    <CareGoPage
      header="Chart of Accounts"
      children={
        <ChartOfAccountDrawer
          drawerSelectedRow={selectedRow}
          setDrawerSelectedRow={setSelectedRow}
          childComponent={<ChartOfAccountForm />}
        />
      }
    />
  );
};

export default ChartOfAccount;
