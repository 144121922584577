import { Box, Typography, useTheme } from '@mui/material';
import { CustomForm, CustomModal, RegularButton } from 'core/components';
import { useContext, useEffect, useState } from 'react';

import { ColorModeContext } from 'theme/theme';
import { FieldInput } from 'core/model/interface';
import Navbar from './Navbar';
import Scroll8 from './8Footer';
import { formatDate } from 'core/utils';
import { requestDemo } from 'core/api/public';
import { request_demo_schema } from 'core/model/schema';
import styled from 'styled-components';

type FormData = Record<string, string | number | Date>;
// interface FormModel {
//  name:string;
//  contact_number:number;
//  email:string;
//  schedule
// }
const DemoPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [messageRows, setMessageRows] = useState<number>(10);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    contact_number: '',
    email: '',
    schedule: new Date(),
    address: '',
    message: '',
  });

  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      colorMode.toggleColorMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const handleResize = () => {
    if (window.innerWidth <= 1700) {
      setMessageRows(7);
    } else {
      setMessageRows(12);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const demoFields: FieldInput[] = [
    { field_name: 'name', display_name: ' Name', type: 'text', span: 5 },
    { field_name: 'contact_number', display_name: 'Contact Number', type: 'text' },
    { field_name: 'email', display_name: 'Email', type: 'text', span: 3 },

    {
      field_name: 'schedule',
      display_name: 'Preferred Schedule ',
      type: 'date',
      disablePast: true
    },
    { field_name: 'address', display_name: 'Clinic Address', type: 'text', span: 3 },
    {
      field_name: 'message',
      display_name: 'Message (Optional)',
      type: 'long_text',
      multiline: true,
      rows: messageRows,
      span: 5,
    },
  ];
  
  const handleFormSubmit = async (formData: FormData) => {
    setButtonLoading(true);
    try {
      formData.schedule = formatDate(formData?.schedule as string | Date);
      
      requestDemo(formData).then(() => {
        setConfirmationModalOpen(true);
        setButtonLoading(false);
        setShouldResetForm(true); 
      })
    } catch (error) {
      console.error('Submission error:', error);
      setButtonLoading(false);
    }
  };

  const handleConfirmationModalClose = () => {
    if (shouldResetForm) {
      setFormData({
        name: '',
        contact_number: '',
        email: '',
        schedule: new Date(),
        address: '',
        message: '',
      });
      setShouldResetForm(false);
    }

    setConfirmationModalOpen(false);
  };
  
  return (
    <Cons>
      <Navbar />
      <MainContainer>
        <Container>
          <Content>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Images src="./assets/Landing2/p2/Waves2.png" alt="Logo" />
              <Test>Request A Demo</Test>
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Paragraph>
                Explore the transformative features and functionalities designed to enhance your clinic performance.
                Simply fill out the form below, and our team will guide you through an immersive demo experience
                tailored to meet your specific needs.{' '}
              </Paragraph>
              <Paragraph>Elevate your clinic management with CareGo Health Suite – request a demo today!</Paragraph>
            </Box>
            <DynamicImage src="./assets/Landing2/p1/rafiki.png" />
          </Content>
          <Mod>
            <Box
              height="90%"
              padding="60px"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

                '@media (max-width: 1700px)': {
                  padding: '40px',
                },
                '@media (max-width: 768px)': {
                  padding: '30px',
                  height: '100%',
                },
              }}
            >
              <Box
                padding="30px 0px "
                sx={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  '@media (max-width: 1700px)': {
                    fontSize: '20px',
                    padding: '20px 0',
                  },
                  '@media (max-width: 768px)': {
                    fontSize: '18px',
                    padding: '20px 0',
                  },
                }}
              >
                Send us a message{' '}
              </Box>
              <CustomForm
                initialValues={formData}
                onSubmit={handleFormSubmit}
                fields={demoFields}
                loading={buttonLoading}
                schema={request_demo_schema}
              />
            </Box>
          </Mod>
        </Container>
        <CustomModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          header="SUCCESS!"
          onClose={handleConfirmationModalClose}
          width={500}
        >
          <Box height="200px" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
            <Box gap="5px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Image src="./assets/Landing2/p2/check.png" />
              <Success>SUCCESS!</Success>
            </Box>
            <Typography textAlign="center" sx={{ fontSize: '17px' }}>
              Check your email address for confirmation. We'll talk to you soon.
            </Typography>
            <RegularButton styles={{ padding: '10px 50px' }} label="Okay" onClick={handleConfirmationModalClose} />
          </Box>
        </CustomModal>
      </MainContainer>

      <Scroll8 />
    </Cons>
  );
};

export default DemoPage;
const Cons = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-x: hidden;
`;
const MainContainer = styled.div`
  width: 100vw;
  min-height: 100vh;

  background-image: url('./assets/Landing2/p1/bg3.png');
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    background-image: url('./assets/Landing2/p1/bgmob.png');
    align-items: flex-start;

    padding: 40px 0px;
    min-height: 170%;
  }
  @media (max-height: 700px) {
    min-height: 200%;
  }
`;

const Container = styled.div`
  height: 93vh;
  width: 80%;
  background-color: transparent;

  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    width: 85%;
  }
`;

const DynamicImage = styled.img`
  width: 90%;
  height: auto;
  @media (max-width: 1700px) {
    width: 80%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 1700px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    flex: none;
  }
`;

const Test = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: bold;
  color: white;
  gap: 10px;
  @media (max-width: 1700px) {
    gap: 8px;
    font-size: 30px;
  }
`;

const Images = styled.img`
  height: auto;
  width: 80px;
  height: 10px;
  @media (max-width: 1700px) {
    width: 70px;
    height: 10px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: white;
  letter-spacing: 1px;
  word-spacing: 1px;
  font-family: Nunito;
  width: 90%;

  @media (max-width: 1500px) {
    font-size: 16px;
    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
  @media (max-width: 1500px) {
    font-size: 12px;
    text-align: center;
  }
`;

const Mod = styled.div`
  height: 75%;

  flex: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: yellow;
  border-radius: 20px;
  background-color: white;
  @media (max-width: 1700px) {
    height: 580px;
  }
  @media (max-width: 1700px) {
    height: 600px;
    width: 100%;
  }
  @media (max-width: 768px) {
    max-height: 700px;
    width: 100%;
  }
`;
const Image = styled.img`
  height: auto;
  width: 45px;

  @media (max-width: 1900px) {
    height: 50px;
  }
`;
const Success = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
