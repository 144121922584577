import { EntityFields } from 'company/entities/utils';

export interface CorporateClientDepartmentModel {
  id: number;
  company_id: number;
  corporate_client_id: number;
  department_name: string;
  company_name: string;
  corporate_client_company_name: string;
  created_by: number;
  created_at?: string;
  updated_at?: string;
}

export const departmentFields: EntityFields[] = [
  { fieldName: 'company_id', displayName: 'Company' },
  { fieldName: 'company_name', displayName: 'Company Name', disabled: true },
  { fieldName: 'department_name', displayName: 'Department Name', type: 'text' },
  {
    fieldName: 'corporate_client_id',
    type: 'corporate_client_select',
    displayName: 'Corporate Client',
    disabled: true,
  },
  { fieldName: 'corporate_client_company_name', displayName: 'Client Company Name', disabled: true },
];

export const visibleTableFields = ['company_name', 'corporate_client_company_name', 'department_name'];
export const visibleFormFields = ['corporate_client_id', 'department_name'];

export const corporateClientDepartmentInitialValues = {
  company_id: '',
  corporate_client_id: '',
  department_name: '',
};
