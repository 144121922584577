import { UserGroupAuthority } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-user-group-authority';

export const caregoGetUserGroupAuthorities = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const caregoGetUserGroupAuthority = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const caregoCreateUserGroupAuthority = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const caregoCreateManyUserGroupAuthority = (id: number, groupName: string) => {
  return axios.post(`${baseURL}/${path}/many`, {
    id, groupName
  });
};

export const caregoUpdateUserGroupAuthority = (data: UserGroupAuthority) => {
  return axios.put(`${baseURL}/${path}`, data);
};

export const caregoDeleteUserGroupAuthority = (group_id: number, user_authority_id: number) => {
  return axios.delete(`${baseURL}/${path}/${group_id}/${user_authority_id}`);
};

export const caregoDeleteManyUserGroupAuthority = (group_id: number, group_name: string) => {
  return axios.delete(`${baseURL}/${path}/many/${group_id}/${group_name}`);
};

export const caregoApplyAllUserGroup = (group_id: number) => {
  return axios.put(`${baseURL}/${path}/many/${group_id}`);
};

export const caregoDeleteAllUserGroupAuthority = (group_id: number) => {
  return axios.delete(`${baseURL}/${path}/many/${group_id}`);
};