import { APPOINTMENT_ACTION, ApiQuery, FORM_MODE } from 'core/model/interface';
import { Box, Divider, Skeleton, TablePagination, Tooltip, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomForm,
  CustomIconButton,
  CustomModal,
  CustomTabs,
  Dropdown,
  HideOrShowComponent,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import DocumentEditor, { PrintedNote } from '../DocumentEditor';
import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  addAssessmentServices,
  getAllHealthAssessmentServices,
  removeAssessmentService,
} from 'company/api/health-assessment-services';
import { capitalizeWord, formatDateTime } from 'core/utils';
import {
  getHealthAssessment,
  getHealthAssessments,
  updateHealthAssessment,
  updateHealthAssessmentStatus,
} from 'company/api/health-assessments';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import AnnualPhysicalExam from './Summarized/AnnualPhysicalExam';
import AppointmentSettings from '../Appointments/components/AppointmentSettings';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomDivider from 'core/components/CustomDivider';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EjectIcon from '@mui/icons-material/Eject';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FacilityContext } from 'core/context/facility.context';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import HealthAssessmentBatch from './HealthAssessmentBatch';
import HealthAssessmentForm from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentForm';
import { HealthAssessmentModel } from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentModel';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PatientNoteSummaryTemplateSearchDropDown from 'company/components/dropdown/PatientNoteSummaryTemplateSearchDropdown';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ReportTemplate from '../Templates/ReportTemplate';
import { SIZES } from 'theme/constants';
import SearchBar from '../InventorySystem/POS/components/SearchBar';
import ServiceGroupSearchDropdown from 'company/components/dropdown/ServiceGroupSearchDropdown';
import { ServiceModel } from 'company/entities/modules/ClinicManagement/Service/ServiceModel';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getGroupServices } from 'company/api/service-groups';
import { getNotesByAppointment } from 'company/api/patient-notes';
import { getPatient } from 'company/api/patient';
import { getTemplates } from 'company/api/note-templates';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';
import CustomFilter from 'core/components/CustomFilter';

export default function HealthAssessments() {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState(0);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setBreadcrumb([{ label: 'Health Assessments' }]);
  }, [setBreadcrumb]);

  useEffect(() => localStorage.setItem('previousPath', window.location.pathname + window.location.search), []);

  return (
    <CareGoPage header="Health Assessments">
      <CustomTabs
        selected={selected}
        setSelected={(newValue) => {
          setSelected(newValue);
          setRefresh((prev) => prev + 1);
        }}
        tabs={[
          {
            label: 'Batch View',
            content: <HealthAssessmentBatch key={refresh} />,
          },
          {
            label: 'Patient View',
            content: <Assessments key={refresh} />,
          },
        ]}
      />
    </CareGoPage>
  );
}

export type AssessmentQuery = ApiQuery & {
  batch?: number;
};

export type AssessmentProps = {
  batch_id?: number;
  fromBatch?: boolean;
  refreshFromBatch?: number;
};
// Pwede to ilagay sa utils.. for promisifying state...
const setStateAsync = (stateSetter: Function, value: any) => {
  return new Promise<void>((resolve) => {
    stateSetter(value);
    resolve();
  });
};

export const Assessments = forwardRef((props: AssessmentProps, ref) => {
  const { batch_id, fromBatch = false, refreshFromBatch } = props;
  const { facility } = useContext(FacilityContext);
  const [assessments, setAssessments] = useState<any[]>([]);
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [refreshPage, setRefreshPage] = useState<number>(0);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  useImperativeHandle(ref, () => ({ refreshData }));

  const refreshData = () => {
    setRefreshPage((prev: any) => prev + 1);
  };
  const getData = async (query?: AssessmentQuery) => {
    setLoadingAssessments(true);
    const res = await getHealthAssessments(facility.id, {
      ...query,
      search: searchQuery,
      page: currentPage + 1,
      length: rowsPerPage,
      batch: batch_id ?? undefined,
      status: statusFilters.length > 0 ? statusFilters.join(',') : undefined,
    });

    setAssessments(res.data.data);
    setRowCount(res.data.meta.total);
    setLoadingAssessments(false);
  };

  useEffect(() => {
    if (facility) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, searchQuery, rowsPerPage, currentPage, batch_id, refreshPage, refreshFromBatch, statusFilters]);

  const statusOptions = useMemo(
    () => [
      { label: 'Pending', id: 'pending' },
      { label: 'In Progress', id: 'in progress' },
      { label: 'Completed', id: 'completed' },
    ],
    []
  );

  return (
    <>
      <ContainerColumn>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '10px', minWidth: fromBatch ? '' : '170px' }}>
            <HideOrShowComponent hidden={fromBatch}>
              <PrimaryButton label="Enroll Patient" onClick={() => setOpenCreate(true)} />
            </HideOrShowComponent>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <CustomFilter
              buttonLabel="Status Filter"
              span={2}
              optionList={statusOptions}
              selected={statusFilters}
              setSelected={setStatusFilters}
            />
            <SearchBar handleSearch={(input) => setSearchQuery(input)} />
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!!assessments && !loadingAssessments && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {assessments.map((asm) => (
                <CustomAccordion key={asm.id} data={asm} fromBatch={fromBatch} setRefreshPage={setRefreshPage} />
              ))}
            </Box>
          )}

          {loadingAssessments && <SkeletonDetails />}
        </Box>
        <CustomModal open={openCreate} setOpen={setOpenCreate} header="Enroll Patient">
          <HealthAssessmentForm
            callbackAfterSubmit={() => {
              getData();
              setOpenCreate(false);
            }}
          />
        </CustomModal>
      </ContainerColumn>
      {!!assessments && !loadingAssessments && (
        <TablePagination
          component="div"
          count={rowCount} //for unknown number of items and server side pagination use -1
          page={currentPage}
          onPageChange={(event, page) => {
            setCurrentPage(page);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setCurrentPage(0);
          }}
          showFirstButton
          showLastButton
          slotProps={{
            actions: {
              nextButton: {
                disabled: currentPage >= Math.ceil(rowCount / rowsPerPage) - 1, // Disable if current page is the last page
              },
            },
          }}
        />
      )}
    </>
  );
});

function CustomAccordion({
  data,
  fromBatch,
  setRefreshPage,
}: {
  data: CompanyAppointmentModel;
  fromBatch: boolean;
  setRefreshPage: any;
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [opened, setOpened] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>('pending');
  const [healthAssessment, setHealthAssessment] = useState<HealthAssessmentModel>();
  const { facility } = useContext(FacilityContext);

  useEffect(() => {
    if (data.health_assessment_id && facility?.id) {
      getHealthAssessment(facility.id, data.health_assessment_id).then((res) => {
        setHealthAssessment(res.data);
      });
    }
  }, [data.health_assessment_id, facility?.id]);

  const updateHealthAssessmentData = async () => {
    if (data.health_assessment_id && facility?.id) {
      const res = await getHealthAssessment(facility.id, data.health_assessment_id);
      setHealthAssessment(res.data);
    }
  };
  return (
    <>
      <Accordion
        onChange={(e, expanded) => {
          if (expanded) setOpened(true);
          setExpanded(expanded);
        }}
        sx={{
          backgroundColor: 'none',
          boxShadow: 'none',
          '&:before': { display: 'none' },
          '& .MuiAccordionSummary-root': {
            borderRadius: '15px',
            padding: '0 20px',
            backgroundColor:
              healthAssessment?.status === 'completed' ? colors.light_green_background : colors.light_blue_background, // Background color for header
            fontWeight: 'bold',
            '& .Mui-expanded': {
              borderRadius: '50px',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '16px 5px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <EjectIcon
              sx={{
                rotate: '180deg',
                color: healthAssessment?.status === 'completed' ? 'green' : colors.primary,
                fontSize: '24px',
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{data.full_name}</Typography>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                  color: healthAssessment?.status === 'completed' ? 'green' : colors.primary,
                }}
              >
                {formatDateTime(data.schedule)}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        {opened && (
          <Box sx={!expanded ? { height: 0, overflow: 'hidden' } : undefined}>
            <AssessmentDetails
              appointment={data}
              fromBatch={fromBatch}
              setRefreshPage={setRefreshPage}
              onStatusChange={updateHealthAssessmentData}
            />
          </Box>
        )}
      </Accordion>
    </>
  );
}

function AssessmentDetails({
  appointment,
  fromBatch,
  setRefreshPage,
  onStatusChange,
}: {
  appointment: CompanyAppointmentModel;
  fromBatch: boolean;
  setRefreshPage: any;
  onStatusChange: () => void;
}) {
  const [assessmentServices, setAssessmentServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceModel>();
  const [openServiceModal, setOpenServiceModal] = useState<boolean>(false);
  const [addedServices, setAddedServices] = useState<any>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [openRemoveServiceModal, setOpenRemoveServiceModal] = useState<boolean>(false);
  const [serviceToRemove, setServiceToRemove] = useState<number>();
  const [selectedServiceNotes, setSelectedServiceNotes] = useState<PatientNoteModel[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [appointmentNotes, setAppointmentNotes] = useState<PatientNoteModel[]>([]);
  const [notesForSummary, setNotesForSummary] = useState<PatientNoteModel[]>([]);
  const [generateReportModalOpen, setGenerateReportModalOpen] = useState<boolean>(false);
  const [selectedSummaryTemplate, setSelectedSummaryTemplate] = useState<any>();
  const [selectedNote, setSelectedNote] = useState<number>();
  const [viewAssessmentResult, setViewAssessmentResult] = useState(false);
  const [mode, setMode] = useState<FORM_MODE>(FORM_MODE.RESPOND);
  const [serviceGroupId, setServiceGroupId] = useState<any>(null);
  const [loadingServicesFromGroup, setLoadingServicesFromGroup] = useState<boolean>(false);
  const [healthAssessment, setHealthAssessment] = useState<HealthAssessmentModel>();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [patient, setPatient] = useState<PatientModel>();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [currentStatus, setCurrentStatus] = useState(healthAssessment?.status || 'pending');
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const statusOptions: DropdownOptionProps[] = [
    {
      label: 'Pending',
      // value: 'pending',
      action: () => handleStatusChange('pending'),
      color: 'primary',
    },
    {
      label: 'In Progress',
      // value: 'in progress',
      action: () => handleStatusChange('in progress'),
      color: 'warning',
    },
    {
      label: 'Completed',
      // value: 'completed',
      action: () => handleStatusChange('completed'),
      color: 'success',
    },
  ];

  useEffect(() => {
    if (appointment.health_assessment_id) {
      getHealthAssessment(appointment.facility_id!, appointment.health_assessment_id).then((res) => {
        setHealthAssessment(res.data);
      });
    }
  }, [appointment]);

  useEffect(() => {
    if (healthAssessment) {
      setCurrentStatus(healthAssessment.status || 'pending');
    }
  }, [healthAssessment]);

  useEffect(() => {
    if (facility?.id)
      getPatient(facility.id, appointment.patient_id).then((doc) => {
        setPatient(doc.data);
      });
  }, [appointment.patient_id, facility?.id]);

  useEffect(() => {
    const existingServices = assessmentServices?.map((service: any) => service.service_id);
    async function getGroupService() {
      if (!serviceGroupId) return;
      setLoadingServicesFromGroup(true);
      const res = await getGroupServices(facility.id, serviceGroupId.value, { length: 100 });

      const services = res.data.data
        ?.filter((item: any) => !existingServices.includes(item.id))
        .map((service: any) => {
          return { key: service.service_name, value: service.id, data: { ...service } };
        });

      await setStateAsync(setAddedServices, services);

      setLoadingServicesFromGroup(false);
    }
    getGroupService();
  }, [assessmentServices, facility?.id, serviceGroupId]);

  function refreshSelectedAssessment() {
    setRefresh((prev) => prev + 1);
  }
  // useEffect(() => {
  //   if (!!assessmentServices) setSelectedService(assessmentServices[0]);
  // }, [assessmentServices]);

  useEffect(() => {
    async function getHealthAssessmentServices() {
      setLoadingServices(true);
      const res = await getAllHealthAssessmentServices(appointment.health_assessment_id!, { length: 100 });
      setAssessmentServices(res.data);
      setLoadingServices(false);
    }
    if (!!appointment) {
      getHealthAssessmentServices();
    }
  }, [appointment, refresh]);

  async function handleAddServices() {
    setSubmitLoading(true);
    const pluckServiceIds = addedServices?.map((s: any) => s.value);
    const req = {
      assessment_id: appointment.health_assessment_id,
      service_ids: !!pluckServiceIds ? pluckServiceIds : [],
      start_time: '2024-09-18 08:00:00',
      end_time: '2024-09-18 17:00:00',
    };
    await addAssessmentServices(req);
    setOpenServiceModal(false);
    setSubmitLoading(false);
    refreshSelectedAssessment();
    setAddedServices(null);
    setServiceGroupId(null);
  }

  async function handleRemoveAssessmentService(id: number) {
    await removeAssessmentService(id);
    refreshSelectedAssessment();
    setOpenRemoveServiceModal(false);
    setServiceToRemove(undefined);
    setSelectedService(undefined);
  }

  function getStatusIcon(status: string, selected: boolean) {
    switch (status.toLowerCase()) {
      case 'pending':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <PendingActionsOutlinedIcon
              sx={{ fontSize: '20px', color: selected ? colors.background : colors.primary }}
            />
          </Tooltip>
        );
      case 'in progress':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <PendingOutlinedIcon sx={{ fontSize: '20px', color: selected ? colors.background : colors.primary }} />
          </Tooltip>
        );
      case 'completed':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <CheckCircleOutlinedIcon sx={{ fontSize: '20px', color: selected ? colors.background : colors.primary }} />
          </Tooltip>
        );
    }
  }

  useEffect(() => {
    async function getNotes() {
      try {
        setLoadingNotes(true);
        const notes = await getNotesByAppointment(facility.id, appointment.id, { length: 100 });
        setAppointmentNotes(notes.data.data);
      } finally {
        setLoadingNotes(false);
      }
    }
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment.id, refresh]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
      size: Legal;
      margin: 1cm;
    }
    body {
      margin: 0;
    }
  `,
  });

  const notes = useMemo(
    () => (selectedService ? selectedServiceNotes : appointmentNotes),
    [appointmentNotes, selectedService, selectedServiceNotes]
  );

  useEffect(() => {
    if (selectedService) {
      getTemplates({ service_id: selectedService.service_id, length: 100 })
        .then((res) => {
          setLoadingNotes(true);
          const serviceNotes: number[] = res.data.data.map((template: any) => template.id);
          setSelectedServiceNotes(appointmentNotes.filter((note) => serviceNotes.includes(note.template_id)));
        })
        .finally(() => {
          setLoadingNotes(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, appointmentNotes]);

  useEffect(() => {
    if (notes.length) {
      setSelectedNote(notes[0].id);
      setViewAssessmentResult(false);
    } else {
      setSelectedNote(undefined);
      setViewAssessmentResult(false);
    }
  }, [notes]);

  useEffect(() => {
    if (viewAssessmentResult) {
      getNotesByAppointment(facility.id, appointment.id, { length: 100 }).then((res) => {
        setNotesForSummary(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAssessmentResult]);

  const PrintedNotes = useMemo(() => {
    if (patient)
      return (
        <Box ref={componentRef}>
          {notes.map((note, index) => {
            return (
              <Fragment key={note.id}>
                <PrintedNote
                  note={note}
                  patient={patient}
                  components={selectedService ? [note.body] : JSON.parse(note.body ? note.body : '[]')}
                  appointment={appointment}
                  // printSettings={printSettings}
                  fromPrintOutside
                />
                {index + 1 < selectedServiceNotes.length && <CustomDivider />}
              </Fragment>
            );
          })}
        </Box>
      );
  }, [notes, patient, selectedService, appointment, selectedServiceNotes.length]);

  const handleStatusChange = async (newStatus: string) => {
    try {
      setUpdatingStatus(true);
      const response = await updateHealthAssessmentStatus(facility.id, appointment.health_assessment_id!, newStatus);

      if (response.data) {
        setCurrentStatus(newStatus);
        enqueueSnackbar('Status updated successfully!', { variant: 'success' });
        refreshSelectedAssessment();
        onStatusChange();
      }
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || 'Failed to update status.', {
        variant: 'error',
      });
      console.error('Error updating status:', error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  return (
    <AccordionDetails>
      <Box
        sx={{ display: 'grid', gridTemplateColumns: fromBatch ? '250px 1fr' : '500px 1fr', alignItems: 'flex-start' }}
        position="relative"
      >
        <Box
          sx={{
            borderRadius: '15px',
            padding: '15px',
            backgroundColor: colors.light_blue_background,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            // position: 'sticky',
            // top: fromBatch ? 80 : 0,
          }}
        >
          <RegularButton
            fullWidth
            variant={!!selectedService ? 'outlined' : 'contained'}
            onClick={() => setSelectedService(undefined)}
            label="Assessment Overview"
            startIcon={<FolderSharedOutlinedIcon />}
          />

          <Box
            display={fromBatch ? 'flex' : 'grid'}
            flexDirection={fromBatch ? 'column-reverse' : undefined}
            gridTemplateColumns={'1fr 1fr'}
            gap="10px"
          >
            <Box
              width="100%"
              padding={SIZES.padding}
              sx={{ backgroundColor: colors.background, borderRadius: SIZES.borderRadiusS }}
            >
              <ContainerRow sx={{ alignItems: 'center', marginBottom: SIZES.paddingS }}>
                <Typography variant="h4" fontWeight={500}>
                  Services
                </Typography>
                <RegularButton
                  size="small"
                  startIcon={<AddIcon />}
                  variant="outlined"
                  onClick={() => setOpenServiceModal(true)}
                  label="Add"
                />
              </ContainerRow>
              <Divider />
              <Box paddingTop={SIZES.paddingS}>
                {assessmentServices &&
                  !loadingServices &&
                  assessmentServices.map((service: any) => {
                    const selected = selectedService?.id === service.id;
                    return (
                      <Box
                        key={service.id}
                        onClick={() => setSelectedService(service)}
                        sx={{
                          backgroundColor: selected ? colors.accent : '',
                          borderRadius: '10px',
                          padding: '2px 10px',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        flex={1}
                      >
                        <Box display={'flex'} gap="10px">
                          {getStatusIcon(service.status, selected)}
                          <Typography fontSize="12px" sx={{ color: selected ? colors.background : colors.primary }}>
                            {service.service_name}
                          </Typography>
                        </Box>

                        <CustomIconButton
                          size="small"
                          tooltip="Remove Service"
                          icon={
                            <DoNotDisturbOnOutlinedIcon
                              sx={{ color: selected ? colors.background : colors.redAccent }}
                            />
                          }
                          onClick={() => {
                            setServiceToRemove(service.id);
                            setOpenRemoveServiceModal(true);
                          }}
                        />
                      </Box>
                    );
                  })}
                {assessmentServices.length <= 0 && !loadingServices && (
                  <Box display="flex" justifyContent="center">
                    <Typography>No added services.</Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              width="100%"
              padding={SIZES.padding}
              sx={{ backgroundColor: colors.background, borderRadius: SIZES.borderRadiusS }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="5px"
                sx={{
                  position: 'sticky',
                  paddingRight: SIZES.paddingS,
                }}
              >
                <Dropdown
                  buttonLabel={capitalizeWord(currentStatus)}
                  tooltipPlacement="bottom"
                  optionList={statusOptions}
                  icon={<ExpandMoreOutlinedIcon />}
                  disabled={updatingStatus}
                  buttonColor={
                    currentStatus === 'completed' ? 'success' : currentStatus === 'in progress' ? 'warning' : 'primary'
                  }
                />
                <Box minHeight="35px" display="flex" alignItems="center" gap="5px">
                  {selectedService && getStatusIcon(selectedService.status, false)}
                  <Typography variant="h4" fontWeight={500}>
                    {selectedService?.service_name ?? 'Notes'}
                  </Typography>
                </Box>
                <Divider />
                <Box paddingTop={SIZES.paddingS}>
                  {notes.length === 0 && <Typography>No Notes</Typography>}
                  {notes.map((note, index) => {
                    return (
                      <RegularButton
                        variant={selectedNote !== note.id ? 'text' : undefined}
                        key={note.id}
                        label={note.header}
                        onClick={() => {
                          setSelectedNote(note.id);
                          setViewAssessmentResult(false);
                        }}
                        size="small"
                        styles={{ textAlign: 'left' }}
                      />
                    );
                  })}
                  {!selectedService && (
                    <RegularButton
                      variant={!viewAssessmentResult ? 'text' : undefined}
                      label="Findings and Recommendations"
                      onClick={() => {
                        setViewAssessmentResult(true);
                        setSelectedNote(undefined);
                      }}
                      size="small"
                      styles={{ textAlign: 'left' }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ padding: '0 20px' }}>
          <ContainerRow sx={{ paddingBottom: SIZES.paddingS }}>
            <Box display="flex" alignItems="center" width="100%" gap="10px">
              <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                {selectedService ? selectedService.service_name : 'Assessment Overview'}
              </Typography>
              {!selectedService && (
                <AppointmentSettings
                  appointment={appointment}
                  updateAppointmentData={() => setRefreshPage((prev: any) => prev + 1)}
                  visibleActions={[
                    APPOINTMENT_ACTION.CANCEL,
                    APPOINTMENT_ACTION.UPDATE_BATCH,
                    APPOINTMENT_ACTION.RESCHEDULE,
                  ]}
                />
              )}
            </Box>
            {!viewAssessmentResult && (
              <ContainerRow sx={{ justifyContent: 'flex-end', alignItems: 'center' }} gap={SIZES.paddingS}>
                <Dropdown
                  buttonLabel={(mode === FORM_MODE.VIEW ? 'View' : 'Edit') + ' Mode'}
                  startIcon={mode === FORM_MODE.VIEW ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
                  optionList={[
                    {
                      label: 'Edit',
                      action: () => setMode(FORM_MODE.RESPOND),
                      icon: <EditOutlinedIcon />,
                    },
                    { label: 'View', action: () => setMode(FORM_MODE.VIEW), icon: <VisibilityOutlinedIcon /> },
                  ]}
                  notFullWidth
                  icon={<ExpandMoreOutlinedIcon />}
                  buttonColor={FORM_MODE.VIEW === mode ? 'warning' : 'success'}
                />

                <RegularButton
                  onClick={() => handlePrint()}
                  label="Print All"
                  startIcon={<LocalPrintshopOutlinedIcon />}
                />
              </ContainerRow>
            )}
          </ContainerRow>

          <Box display="none">{PrintedNotes}</Box>

          <Box>
            {selectedNote && (
              <DocumentEditor
                hideClinicHeader
                source={'patient'}
                template_id={selectedNote}
                patient_id={appointment?.patient_id}
                fromPatientRecords
                stickyTop={'0'}
                formMode={mode}
              />
            )}
            {viewAssessmentResult && (
              <ContainerColumn
                sx={{
                  width: '100%',
                  maxWidth: '920px',
                  margin: '0 auto 50px',
                }}
              >
                {patient && healthAssessment && (
                  <AnnualPhysicalExam
                    patient={patient}
                    assessment={healthAssessment}
                    appointment={appointment}
                    notes={notesForSummary}
                    hideRemarksSection
                  />
                )}
                <Box paddingInline="37px">
                  <Typography variant="h3" fontWeight="600" paddingBlock={SIZES.padding}>
                    Findings and Recommendations
                  </Typography>
                  <CustomForm
                    fields={[
                      {
                        field_name: 'findings',
                        display_name: 'Significant Findings',
                        span: 4,
                        rows: 3,
                        multiline: true,
                      },
                      {
                        field_name: 'recommendations',
                        display_name: 'Recommendation',
                        span: 4,
                        rows: 3,
                        multiline: true,
                      },
                      {
                        field_name: 'class',
                        display_name: 'Class',
                        span: 4,
                        type: 'select',
                        options: [
                          {
                            key: 'CLASS A: Physically fit for all types of work. No physical defect noted.',
                            value: 'A',
                          },
                          {
                            key: 'CLASS B: Physically fit for all types of work. Has minor ailment/defect. Easily curable or offers no handicaps.',
                            value: 'B',
                          },
                          { key: 'CLASS C: Employment at risk and discretion of management consider.', value: 'C' },
                          { key: 'PENDING: For further evaluation', value: 'Pending' },
                        ],
                      },
                    ]}
                    onSubmit={async (data) => {
                      const res = await updateHealthAssessment(
                        appointment.facility_id!,
                        appointment.health_assessment_id!,
                        data
                      );
                      enqueueSnackbar('Assessment successfully updated!', { variant: 'success' });
                      setHealthAssessment(res.data.assessment);
                    }}
                    initialValues={{
                      findings: healthAssessment?.findings ?? '',
                      recommendations: healthAssessment?.recommendations ?? '',
                      class: healthAssessment?.class ?? '',
                    }}
                    loading={false}
                  />
                </Box>
              </ContainerColumn>
            )}
          </Box>

          {loadingNotes && <CustomLoadingIndicator />}

          {notes.length < 1 && !loadingNotes && <Typography>No Notes</Typography>}
        </Box>
      </Box>

      <CustomModal
        header="Add Services"
        setOpen={setOpenServiceModal}
        open={openServiceModal}
        onClose={() => {
          setAddedServices([]);
          setServiceGroupId(null);
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
            <PersonIcon sx={{ color: colors.accent }} />
            <Typography sx={{ mb: '15px' }}>Patient {appointment?.full_name}</Typography>
          </Box>
          <ServiceSearchDropdown
            disabled={!!serviceGroupId}
            limit={20}
            limitTags={8}
            assessment_id={appointment.health_assessment_id}
            useAssessmentNotAddedServiceAPI
            multiple
            handleChangeCallback={(data) => setAddedServices(data)}
          />
          <Typography sx={{ color: colors.accent, fontWeight: '600' }}>Add Services from Service Groups</Typography>
          <ServiceGroupSearchDropdown
            hideAddOption
            handleChangeCallback={(data) => {
              if (!data) {
                setServiceGroupId(null);
                return;
              }

              setServiceGroupId(data);
            }}
          />
          {serviceGroupId && (
            <>
              <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                Services under {serviceGroupId.key}:
              </Typography>
              <Typography>
                {serviceGroupId.data.services &&
                  serviceGroupId.data.services
                    .split(',')
                    .map((service: any) => service.trim())
                    .join(', ')}
              </Typography>
            </>
          )}
          <Box sx={{ display: 'flex', width: '100%', marginRight: '10px', justifyContent: 'flex-end' }}>
            <RegularButton
              styles={{ alignSelf: 'flex-end' }}
              onClick={handleAddServices}
              loading={submitLoading}
              label={loadingServicesFromGroup ? 'Loading group services...' : undefined}
              disabled={loadingServicesFromGroup}
            />
          </Box>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        onConfirm={() => {
          if (!!serviceToRemove) handleRemoveAssessmentService(serviceToRemove);
        }}
        content={`Remove ${selectedService?.service_name} from ${appointment.full_name}'s health assessment?`}
        setOpen={setOpenRemoveServiceModal}
        open={openRemoveServiceModal}
      ></ConfirmationDialog>

      <CustomModal
        open={generateReportModalOpen}
        setOpen={setGenerateReportModalOpen}
        header="Generate Report"
        width={1000}
        onClose={() => setSelectedSummaryTemplate(undefined)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <PatientNoteSummaryTemplateSearchDropDown handleChangeCallback={(data) => setSelectedSummaryTemplate(data)} />

          {selectedSummaryTemplate && <ReportTemplate summary_id={selectedSummaryTemplate.value} />}
        </Box>
      </CustomModal>
    </AccordionDetails>
  );
}

type SkeletonProps = {
  length?: number;
  styles?: any;
};
export function SkeletonDetails(props: SkeletonProps) {
  const { length, styles } = props;
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 0 }}>
      {Array.from({ length: length ?? 4 }).map((_, index) => (
        <Skeleton
          animation="wave"
          key={index}
          sx={styles ? styles : { width: '100%', height: '120px', borderRadius: '15px', margin: '-15px', padding: 0 }}
        />
      ))}
    </Box>
  );
}
