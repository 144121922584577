import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'user-authority';

export const getUserAuthorities = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const getUserAuthority = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};
