import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'medicines';

export const getMedicines = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const getMedicine = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createMedicine = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateMedicine = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteMedicine = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
