import * as yup from 'yup';

import { Box, Typography, useTheme } from '@mui/material';
import { CustomForm, CustomModal, RegularButton } from 'core/components';
import { forwardRef, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CustomFilterTable from './CustomFilterTable';
import { FieldInput } from 'core/model/interface';
import { SIZES } from 'theme/constants';
import { Skeleton } from '@mui/material';
import { createFilters } from 'company/api/filter';
import { tokens } from 'theme/theme';

export const filter_schema = yup.object().shape({
  source: yup.string().required('Source is required.'),
  filter_name: yup.string().required('Chart Name is required.'),
  value: yup.string().required('Value is required.'),
  condition: yup.string().required('Field Condition is required.'),
});

type Props = {
  template_id: number;
  fields: FieldInput[];
  patientRecords: any[];
};

const FilterEditor = forwardRef((props: Props, ref) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { template_id, fields } = props;
  const [openCreateFilterForm, setOpenCreateFilterForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [tableLoading, setTableLoading] = useState(true);

  const formatFilterData = (formData: any) => {
    return {
      filter_name: formData.filter_name,
      condition: {
        source_field: formData.source,
        condition: formData.condition,
        value: formData.value,
      },
    };
  };

  const operatorOptions = [
    { operator_option: 'Is equals to' },
    { operator_option: 'Is not equals to' },
    { operator_option: 'Greater than' },
    { operator_option: 'Greater than or equal to' },
    { operator_option: 'Less than' },
    { operator_option: 'Less than or equal to' },
  ];

  const addFilterFields: FieldInput[] = [
    {
      field_name: 'filter_name',
      display_name: 'Filter Name',
      type: 'string',
      disablePast: true,
      span: 4,
    },

    {
      field_name: 'source',
      display_name: 'Source Field',
      type: 'select',

      options: fields
        .filter((field) => field.type === 'radiogroup' || field.type === 'select')
        .map((field) => ({
          key: field.display_name + '',
          value: field.display_name,
        })),
      span: 4,
      onChange: (value, setFieldValue) => {
        setSelectedSource(value);
        if (setFieldValue) {
          setFieldValue('source', value);
          setFieldValue('value', '');
        }
      },
    },
    {
      field_name: 'condition',
      display_name: 'Field Condition',
      type: 'select',
      span: 4,
      options: operatorOptions.map((operator) => ({
        key: operator.operator_option + '',
        value: operator.operator_option,
      })),
      onChange: (value, setFieldValue) => {
        if (setFieldValue) {
          setFieldValue('condition', value);
        }
      },
    },
    {
      field_name: 'value',
      display_name: 'Value',
      type: 'select',
      span: 4,
      options: selectedSource
        ? fields
            .find((field) => field.display_name === selectedSource)
            ?.options?.map((option) => ({
              key: option.key || '',
              value: option.key || '',
            })) || []
        : [],
      onChange: (value, setFieldValue) => {
        setFieldValue && setFieldValue('value', value);
      },
    },
  ];

  const addFilter = () => {
    setOpenCreateFilterForm(true);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const formattedData = formatFilterData(data);

      await createFilters(template_id, formattedData);

      setOpenCreateFilterForm(false);
    } catch (error) {
      console.error('Error submitting filter:', error);
    } finally {
      setLoading(false);
    }
  };

  const TableSkeleton = () => (
    <Box>
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2, mt: 4, borderRadius: '8px' }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" width="100%" height={50} sx={{ mb: 2, borderRadius: '8px' }} />
      ))}
    </Box>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTableLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Box>
      <Typography variant="h3" fontWeight={600} color={colors.primary} mb={SIZES.padding}>
        List of Filters
      </Typography>

      <RegularButton startIcon={<AddIcon />} onClick={() => addFilter()} label="Create Filter" />

      {tableLoading ? (
        <TableSkeleton />
      ) : (
        <CustomFilterTable
          template_id={template_id}
          addFilterFields={addFilterFields}
          formatFilterData={formatFilterData}
        />
      )}

      <CustomModal open={openCreateFilterForm} setOpen={setOpenCreateFilterForm} header={'Create Filter'} width={400}>
        <CustomForm
          fields={addFilterFields}
          schema={filter_schema}
          initialValues={{
            filter_name: '',
            source_field: '',
            condition: '',
            value: '',
          }}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </CustomModal>
    </Box>
  );
});

export default FilterEditor;
