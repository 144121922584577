import { Box, Typography } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { useContext, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { ProgramPatientModel } from 'company/model/Entities';
import { tranformFormErrors } from 'core/utils';
import { updateSputum } from 'company/api/program-patients';
import { update_sputum_company_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

type TbUpdateSputumProps = {
  open: boolean;
  setOpen: (openModal: boolean) => void;
  selectedPatient?: ProgramPatientModel;
  refreshTable: () => void;
};

export type UserInput = {
  id: number | string;
  tb_sputum_schedule1: string;
  tb_sputum_schedule2: string;
  tb_sputum_schedule3: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const TbUpdateSputum: React.FC<TbUpdateSputumProps> = ({ open, setOpen, selectedPatient, refreshTable }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    id: selectedPatient ? selectedPatient.id : '',
    tb_sputum_schedule1: selectedPatient ? selectedPatient.tb_sputum_schedule1 : '',
    tb_sputum_schedule2: selectedPatient ? selectedPatient.tb_sputum_schedule2 : '',
    tb_sputum_schedule3: selectedPatient ? selectedPatient.tb_sputum_schedule3 : '',
  };

  const tbDotsSputumFields: UserFieldInput[] = [
    { field_name: 'tb_sputum_schedule1', display_name: 'Week 8', type: 'datetime', minutesStep: 5 },
    { field_name: 'tb_sputum_schedule2', display_name: 'Week 20', type: 'datetime', minutesStep: 5 },
    { field_name: 'tb_sputum_schedule3', display_name: 'Week 24', type: 'datetime', minutesStep: 5 },
  ];

  const handleUpdateSputumSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (selectedPatient) {
      updateSputum(facility.id, selectedPatient.id!, data).then((res: any) => {
        successFormSubmit(res, formikHelpers);
      });
      refreshTable();
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Sputum Schedules successfully updated!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  return (
    <>
      <CustomModal header={'Update Sputum Schedules'} open={open} setOpen={setOpen}>
        <Box marginBottom={'20px'}>
          <Typography variant="h4">{selectedPatient ? selectedPatient.full_name : ''}</Typography>
        </Box>

        <CustomForm
          initialValues={initialValues}
          onSubmit={handleUpdateSputumSubmit}
          fields={tbDotsSputumFields}
          schema={update_sputum_company_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default TbUpdateSputum;
