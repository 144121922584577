import { Box } from '@mui/material';
import FpPatientEnrollment from './FpPatientEnrollment';
import { Header } from 'core/components';

const FamilyPlanning = () => {
  return (
    <Box>
      <Header title="OCP" />
      <FpPatientEnrollment />
    </Box>
  );
};

export default FamilyPlanning;
