import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CareGoTable } from 'core/PageBuilder';
import { CustomGridCell } from 'core/components';
import { Link } from 'react-router-dom';

import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { SIZES } from 'theme/constants';

const mockData = {
  data: {
    data: [
      {
        id: 1,
        full_name: 'Juan Dela Cruz',
        date: 'Apr 15, 2024',
        services: 'Libreng Tuli, Dental Mission',
        amountPaid: 'P100',
        balance: 'P0',
      },
      {
        id: 2,
        full_name: 'Marie Curie',
        date: 'Apr 15, 2024',
        services: 'Dental Mission',
        amountPaid: 'P100',
        balance: 'P500',
      },
      {
        id: 3,
        full_name: 'Graham Bell',
        date: 'Apr 15, 2024',
        services: 'Libreng Tuli',
        amountPaid: 'P100',
        balance: 'P200',
      },
    ],
    meta: {
      total: 3,
    },
  },
};

export const getMockData = async (): Promise<any> => {
  return mockData;
};

const MockContent: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
            {params.row.full_name}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'date', headerName: 'Date' },
    { field: 'services', headerName: 'Service(s) Engaged', flex: 2 },
    { field: 'amountPaid', headerName: 'Amount Paid' },
    { field: 'balance', headerName: 'Remaining Balance' },
  ];

  return (
    <>
      <Box display={'flex'} gap={'10px'} maxWidth={'80%'}>
        <CustomDashboardWidget count={'100'} label="No. of Transactions" icon={ReceiptOutlinedIcon} />
        <CustomDashboardWidget count={'120'} label="No. of Patients" icon={Person2OutlinedIcon} />
        <CustomDashboardWidget count={'P 100.00'} label="Outstanding Balance" icon={AccountBalanceWalletOutlinedIcon} />
      </Box>
      <Box paddingBlock={SIZES.padding}>
        <CareGoTable
          useColoredTable
          tableComponent={{
            columns: columns,
            getData: getMockData,
            hideViews: true,
            hideSearch: true,
          }}
          tableAction={{
            includeAddButton: false,
            includeDeleteAction: false,
            includeUpdateAction: false,
            doNotHideActionForInactiveCompany: true,
          }}
        />
      </Box>
    </>
  );
};

export default MockContent;
