// import { PATIENT_ENTITY_NAME, PatientModel } from './PatientModel';
import { PROMO_ENTITY_NAME, PromoModel } from './PromoModel';
import { ReactNode, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import PromoForm from './PromoForm';
import TemplateDrawer from '../../../Template/TemplateDrawer';

type Props = { childComponent?: ReactNode; setDrawerSelectedRow?: any };
const PromoDrawer = forwardRef((props: Props, ref) => {
  const { childComponent, setDrawerSelectedRow } = props;
  const { facility } = useContext(FacilityContext);
  const tableRef: any = useRef();
  useImperativeHandle(ref, () => ({ refreshTable }));
  const [selectedRow, setSelectedRow] = useState<PromoModel>();
  const refreshTable = () => {
    tableRef.current.refreshTable();
  };

  return (
    <TemplateDrawer
      entityName={PROMO_ENTITY_NAME}
      internalSetDrawerSelectedRow={setSelectedRow}
      externalSetDrawerSelectedRow={setDrawerSelectedRow}
      mainColumn="title"
      tableRef={tableRef}
      childComponent={
        childComponent ?? (
          <PromoForm
            callbackAfterSubmit={() => tableRef.current.refreshTable()}
            facility={facility}
            promotion={selectedRow}
          />
        )
      }
    />
  );
});

export default PromoDrawer;
