import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Article3: React.FC = () => {
  return (
    <Box>
      <Title>Schedule View</Title>
      <Step>1. Go to the “Family Planning” - “OCP Reminders” - "Schedule View” tab in application.</Step>
      <Step>2. Click on the “Add Cycle” button.</Step>
      <Image src="/assets/FaqAssets/ocp/3/schedule/1-2.png" alt="Step Image" />
      <Step>3. Click “Add” button</Step>
      <Image src="/assets/FaqAssets/ocp/3/schedule/3.png" alt="Step Image" />
      <Step>4. Fill out the form and click “Submit” button.</Step>
      <Image src="/assets/FaqAssets/ocp/3/schedule/4.png" alt="Step Image" />
      <Title>Patient View</Title>
      <Step>1. Go to the “Family Planning” - “OCP Reminders” - "Patient View” tab in application.</Step>
      <Step>2. Click on the “Add Cycle” button.</Step>
      <Image src="/assets/FaqAssets/ocp/3/patient/1-2.png" alt="Step Image" />
      <Step>3. Click “Add Cycle” button</Step>
      <Image src="/assets/FaqAssets/ocp/3/patient/3.png" alt="Step Image" />
      <Step>4. Fill out the form and click “Submit” button</Step>
      <Image src="/assets/FaqAssets/ocp/3/patient/4.png" alt="Step Image" />
    </Box>
  );
};

export default Article3;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;
const Title = styled.p`
  margin-top: 8px;
  font-size: 17px;
  font-weight: bold;
`;
const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
