import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-package-sms';

export const getHealthPackageSms = (package_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${package_id}`, {
    params: query,
  });
};

export const getAllHealthPackageSms = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};
