/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { Box, Divider, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomForm, CustomModal } from 'core/components';

import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { company_onboarding_schema } from 'carego-admin/model/schema';
import { getPrograms } from 'carego-admin/api/company-program';
import { onboardNewClient } from 'carego-admin/api/company';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type CompanyOnboardingModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
  languages: any;
};

type UserInput = {
  company_name: string;
  primary_language: number | string;
  plan: string;
  email: string;

  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  username: string;
  password: string;
  start_free_trial: boolean;

  health_programs: any[];

  patient_limit: number;
  user_limit: number;
  storage_limit: number;

  with_inventory: boolean;
  with_accounting: boolean;
  with_booking_platform: boolean;
  with_ocp_reminders: boolean;
  with_injectable_reminders: boolean;
  with_campaigns: boolean;
  with_health_package: boolean;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  company_name: '',
  primary_language: '',
  plan: 'free',
  email: '',

  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  username: '',
  password: '',
  start_free_trial: true,

  health_programs: [],

  patient_limit: 0,
  user_limit: 0,
  storage_limit: 0,

  with_inventory: false,
  with_accounting: false,
  with_booking_platform: false,
  with_ocp_reminders: false,
  with_injectable_reminders: false,
  with_campaigns: false,
  with_health_package: false,
};

const CompanyOnboardingModal: React.FC<CompanyOnboardingModalProps> = ({ open, setOpen, refreshTable, languages }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [selectedLogoLight, setSelectedLogoLight] = useState<any>();
  const [selectedLogoDark, setSelectedLogoDark] = useState<any>();
  const [companyHealthPrograms, setCompanyHealthPrograms] = useState<any>();

  // Form Fields
  const fields: UserFieldInput[] = [
    { field_name: 'company_name', display_name: 'Company Name', type: 'text' },
    {
      field_name: 'primary_language',
      display_name: 'Language',
      type: 'select',
      options:
        languages &&
        languages.map((u: any) => {
          return { key: u.language, value: u.id };
        }),
    },
    { field_name: 'email', display_name: 'Email', type: 'text' },
    {
      field_name: 'plan',
      display_name: 'Plan',
      type: 'select',
      options: [
        {
          key: 'Free Trial',
          value: 'free',
        },
        {
          key: 'Starter Plan',
          value: 'starter',
        },
        {
          key: 'Standard Plan',
          value: 'standard',
        },
      ],
    },
    {
      type: 'subsection_header',
      subsection_header: 'Health Programs',
      span: 4,
    },
    {
      field_name: 'health_programs',
      display_name: 'Health Programs',
      type: 'multiselect',
      span: 4,
      options:
        companyHealthPrograms &&
        companyHealthPrograms.map((u: any) => {
          return { key: u.program_name, value: u.id };
        }),
    },
    {
      type: 'subsection_header',
      subsection_header: 'User Access',
      span: 4,
    },
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (Optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'username', display_name: 'Username', type: 'text' },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'password_generator',
      onChange: (value: string, setFieldValue: any) => {
        setFieldValue('password', value);
      },
    },
    {
      type: 'subsection_header',
      subsection_header: 'Limits',
      span: 4,
    },
    { field_name: 'patient_limit', display_name: 'Patient Limit', type: 'number' },
    { field_name: 'user_limit', display_name: 'User Limit', type: 'number' },
    { field_name: 'storage_limit', display_name: 'Storage Limit', type: 'number' },
    {
      type: 'subsection_header',
      subsection_header: 'Features',
      span: 4,
    },
    { field_name: 'with_booking_platform', display_name: 'Enable Booking Platform', type: 'checkbox' },
    { field_name: 'with_inventory', display_name: 'Enable Inventory', type: 'checkbox' },
    { field_name: 'with_accounting', display_name: 'Enable Accounting', type: 'checkbox' },
    { field_name: 'with_ocp_reminders', display_name: 'Enable OCP Reminders', type: 'checkbox' },
    { field_name: 'with_injectable_reminders', display_name: 'Enable Injectable Reminders', type: 'checkbox' },
    { field_name: 'with_campaigns', display_name: 'Enable Campaigns', type: 'checkbox' },
    { field_name: 'with_health_package', display_name: 'Enable Health Sessions', type: 'checkbox' },
  ];

  const handleImageChangeLight = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        setSelectedLogoLight(e.target.result);
      }
    };
    reader.readAsDataURL(file!);
  };

  const handleImageChangeDark = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        setSelectedLogoDark(e.target.result);
      }
    };
    reader.readAsDataURL(file!);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    data = { ...data };
    setButtonLoading(true);

    data['company_logo'] = selectedLogoLight;
    data['company_logo_dark'] = selectedLogoDark;

    const health_programs = data.health_programs.map((item: { value: any }) => item.value);
    data['health_programs'] = JSON.stringify(health_programs);

    onboardNewClient(data)
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => {
        console.error(error);
        setButtonLoading(false);
      });
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Client successfully onboarded!`, { variant: 'success' });
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  const getHealthPrograms = () => {
    return getPrograms().then((res) => {
      setCompanyHealthPrograms(res.data);
    });
  };

  useEffect(() => {
    getHealthPrograms();
  }, []);

  useEffect(() => {
    setSelectedLogoLight(process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png');
    setSelectedLogoDark(process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png');
  }, [open]);

  return (
    <CustomModal header={'Onboard New Client'} open={open} setOpen={setOpen}>
      <Box display="block" marginBottom={'2rem'}>
        <Box
          display="block"
          sx={{
            border: '1px solid lightgray',
            justifyContent: 'space-between',
            padding: '1rem',
            borderRadius: '5px',
          }}
        >
          <Box
            display={'flex'}
            sx={{
              gap: '20px',
              marginBottom: '1rem',
            }}
          >
            <img src={selectedLogoLight} width="50px" height="50px" />
            <Box display="flex" marginLeft="3rem" gap="1rem" paddingTop="1rem">
              <Typography> Upload Logo (Light Mode): </Typography>
              <input type="file" onChange={handleImageChangeLight} id="logo_light" accept="image/*" />
            </Box>
          </Box>
          <Box
            display={'flex'}
            sx={{
              gap: '20px',
            }}
          >
            <img src={selectedLogoDark} width="50px" height="50px" />
            <Box display="flex" marginLeft="3rem" gap="1rem" paddingTop="1rem">
              <Typography> Upload Logo (Dark Mode): </Typography>
              <input type="file" onChange={handleImageChangeDark} id="logo_dark" accept="image/*" />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box marginTop={'2rem'}>
          <CustomForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            fields={fields}
            schema={company_onboarding_schema}
            loading={buttonLoading}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

export default CompanyOnboardingModal;
