/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import BiotechIcon from '@mui/icons-material/Biotech';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CustomTabs } from 'core/components';
import MedicationIcon from '@mui/icons-material/Medication';
import { TabProps } from 'core/components/CustomTabs';
import TbAcfReminders from './TbAcfReminders';
import TbDotsReminders from './TbDotsReminders';

const TbReminder = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    { icon: <BiotechIcon />, label: 'TB ACF', content: <TbAcfReminders /> },
    { icon: <MedicationIcon />, label: 'TB-DOTS', content: <TbDotsReminders /> },
  ];

  useEffect(() => {
    setBreadcrumb([{ label: 'TB Reminders' }]);
  }, []);

  return <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />;
};

export default TbReminder;
