import React, { useState } from 'react';
import styled from 'styled-components';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ServiceIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #f2f9ff;
  color: #45464e;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

const MainRow = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const IconColumn = styled.div`
  margin-right: 20px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 700;
  font-family: Poppins;

  font-size: 18px;
  margin: 0;
`;

const Duration = styled.p`
  font-weight: 600;
  font-family: Poppins;

  font-size: 14px;
  margin: 0;
`;

const Price = styled.p`
  font-weight: 600;
  font-family: Poppins, Arial, sans-serif;

  font-size: 14px;
  margin: 0;
  color: #0192d5;
`;

const ButtonRow = styled.div`
  width: 100%;
`;

interface ServiceIconProps {
  title: string;
  duration: string;
  price: string;
  disable?: boolean;
  serviceId: number;
  facilityId: number;
}

const ServiceIcon = ({ title, duration, price, disable, serviceId, facilityId }: ServiceIconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <ServiceIconWrapper
      onMouseEnter={() => !disable && setIsHovered(true)}
      onMouseLeave={() => !disable && setIsHovered(false)}
      style={{
        cursor: disable ? 'default' : 'pointer',
        pointerEvents: disable ? 'none' : 'auto',
      }}
    >
      <MainRow>
        <IconColumn>
          <LocalHospitalOutlinedIcon sx={{ fontSize: '64px', color: '#0192d5' }} />
        </IconColumn>
        <InfoColumn>
          <Title>{title}</Title>
          <Duration>{duration}</Duration>
          <Price>{price}</Price>
        </InfoColumn>
      </MainRow>
      <ButtonRow>
        {!disable && (
          <Button
            variant="contained"
            fullWidth
            style={{ opacity: isHovered ? 1 : 0 }}
            onClick={() => navigate(`/book/appointment/${facilityId}/${serviceId}`)}
          >
            Book this Service
          </Button>
        )}
      </ButtonRow>
    </ServiceIconWrapper>
  );
};

export default ServiceIcon;
