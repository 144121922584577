import {
  APL_ENTITY_NAME,
  AppointmentLegendModel,
  appointmentLegendFieldsTable,
  appointmentLegendTableColumns,
} from './AppointmentLegendModel';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import { deleteLegend, getAllAppointmentLegends } from 'company/api/appointment-legends';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import AppointmentLegendForm from './AppointmentLegendForm';
import { Box } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { useNavigate } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  callBackAfterSubmitFromAppointment?: () => void;
};

const AppointmentLegendTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction, callBackAfterSubmitFromAppointment } = props;
  const [selectedRow, setSelectedRow] = useState<AppointmentLegendModel>();
  const [legends, setLegends] = useState<any[]>([]);
  const [legendFilters, setLegendFilters] = useState<string[]>([]);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);

  const getData = useCallback(
    (query: ApiQuery) => {
      return getAllAppointmentLegends(facility.id, query).then((response) => {
        if (legendFilters.length > 0) {
          const filteredData = response.data.data.filter((legend: any) => legendFilters.includes(legend.id.toString()));
          return {
            data: {
              data: filteredData,
              meta: {
                total: filteredData.length,
              },
            },
          };
        }
        return response;
      });
    },
    [facility.id, legendFilters]
  );

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  function callBackAfterSubmit() {
    refreshTable();
    callBackAfterSubmitFromAppointment && callBackAfterSubmitFromAppointment();
    mainRef.current?.closeFormModal();
  }

  const handleDeleteAppointmentLegend = (data: any) => {
    return deleteLegend(facility.id, data);
  };

  useEffect(() => {
    if (facility) {
      getAllAppointmentLegends(facility.id).then((res) => {
        setLegends(res.data.data);
      });
    }
  }, [facility, legends]);

  const legendOptions = useMemo(() => {
    return legends.map((legend: any) => ({
      label: legend.tag_name,
      id: legend.id.toString(),
    }));
  }, [legends]);

  useEffect(() => {
    refreshTable();
  }, [legendFilters]);

  return (
    <Box sx={{ mt: 2 }}>
      <TemplateTable
        // tableAction={{
        //   rowActions,
        // }}
        // hideAddUpdateDeleteAction
        handleDelete={(data) => handleDeleteAppointmentLegend(data)}
        // hideUpdateAction
        // hideDeleteAction
        useColoredTable
        ref={mainRef}
        entityName={APL_ENTITY_NAME}
        fields={appointmentLegendFieldsTable}
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        visibleColumns={visibleColumns ?? appointmentLegendTableColumns}
        templateForm={<AppointmentLegendForm callbackAfterSubmit={callBackAfterSubmit} legend={selectedRow} />}
        // afterSearchbarComponent={
        //   <Box sx={{ mr: 2 }}>
        //     <CustomFilter
        //       buttonLabel="Filter"
        //       span={2}
        //       optionList={legendOptions}
        //       selected={legendFilters}
        //       setSelected={setLegendFilters}
        //     />
        //   </Box>
        // }
      />
    </Box>
  );
});

export default AppointmentLegendTable;
