/* eslint-disable react-hooks/exhaustive-deps */
import { ConfirmationDialog, CustomContainer, CustomTable, Header, PrimaryButton, RowAction } from 'core/components';
import { changeNullToBlank, formatNumber } from 'core/utils';
import { deactivateLgu, exportLGUs, getLanguages, getLgus, reactivateLgu } from 'carego-admin/api/lgu';
import { useContext, useEffect, useState } from 'react';

import AddLgu from './components/AddLgu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ApiQuery } from 'core/model/interface';
import BarangayList from './components/BarangayList';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridColDef } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { LguModel } from 'carego-admin/model/Entities';
import UpdateLgu from './components/UpdateLgu';
import UserAccess from './components/UserAccess';
import { useSnackbar } from 'notistack';

const LguManagement = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const [lgu, setLgu] = useState<LguModel>();
  const [languages, setLanguages] = useState<any>();

  //update
  const [openUpdateLguModal, setOpenUpdateLguModal] = useState<boolean>(false);

  //barangay list
  const [openBarangayListModal, setOpenBarangayListModal] = useState<boolean>(false);

  //add
  const [openAddLguModal, setOpenAddLguModal] = useState<boolean>(false);

  //reactivate or deactivate
  const [lguStatus, setLguStatus] = useState<number>(1);
  const [openUpdateLguStatusDialog, setOpenUpdateLguStatusDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  //user accounts
  const [openUserAccountsModal, setOpenUserAccountsModal] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: 'lgu_code', headerName: 'LGU Code', flex: 0.75 },
    { field: 'lgu_name', headerName: 'LGU Name', flex: 1 },
    { field: 'province_name', headerName: 'Province', flex: 1 },
    { field: 'region_name', headerName: 'Region', flex: 1 },
    {
      field: 'total_sent',
      headerName: 'Total SMS Sent',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      renderCell: (params) => (formatNumber(params.row.total_sent) ? formatNumber(params.row.total_sent) : '0'),
    },
    {
      field: 'credits_purchased',
      headerName: 'Extra Credits',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseInt(params.row.credits_purchased))
          ? formatNumber(parseInt(params.row.credits_purchased))
          : '0',
    },
    {
      field: 'available_credits',
      headerName: 'Credits Remaining',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      renderCell: (params) =>
        formatNumber(params.row.available_credits) ? formatNumber(params.row.available_credits) : '0',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.75,
      renderCell: (params) => (params.row.is_active ? 'Active' : 'Deactivated'),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => (
        <div
          onClick={() => {
            setLguStatus(params.row.is_active);
          }}
        >
          <RowAction actions={actions} data={params.row} />
        </div>
      ),
    },
  ];

  const showUpdateLgu = (data: any) => {
    setLgu(changeNullToBlank(data));
    setOpenUpdateLguModal(true);
  };

  const showBarangayList = (data: any) => {
    setLgu(data);
    setOpenBarangayListModal(true);
  };

  const updateLguStatus = (data: any) => {
    if (data.is_active === 1) {
      showAlertDialog('Are you sure you want to deactivate ' + data.lgu_name + '?');
    } else {
      showAlertDialog('Are you sure you want to reactivate ' + data.lgu_name + '?');
    }
    setLgu(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenUpdateLguStatusDialog(true);
    setAlertContent(content);
  };

  const handleDeactivateLgu = (data: any) => {
    setLoading(true);
    deactivateLgu(lgu!.lgu_code)
      .then(() => {
        enqueueSnackbar('LGU successfully deactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const handleReactivateLgu = (data: any) => {
    setLoading(true);
    reactivateLgu(lgu!.lgu_code)
      .then(() => {
        enqueueSnackbar('LGU successfully reactivated!', { variant: 'success' });
        setLoading(false);
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const showUserAccounts = (data: any) => {
    setLgu(data);
    setOpenUserAccountsModal(true);
  };

  const actions = [
    { label: 'Update LGU', action: showUpdateLgu, icon: <EditOutlinedIcon /> },
    {
      label: lguStatus === 1 ? 'Deactivate' : 'Reactivate',
      action: updateLguStatus,
      icon: lguStatus === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
    { label: 'User Access', action: showUserAccounts, icon: <AdminPanelSettingsIcon /> },
    { label: 'Barangay List', action: showBarangayList, icon: <HolidayVillageIcon /> },
  ];

  const getRegisteredLgus = (query?: ApiQuery) => {
    setLoading(true);
    getLgus({ page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const getLanguageList = () => {
    getLanguages()
      .then((res) => {
        setLanguages(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    getLanguageList();
    setBreadcrumb([{ label: 'LGU Management' }]);
  }, []);

  return (
    <Box>
      <Header title="Registered LGUs" />
      <CustomContainer>
        <Box display="flex" gap="10px" width="100%">
          <PrimaryButton onClick={() => setOpenAddLguModal(true)} label="Add LGU" />
        </Box>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'lgu_code'}
          getData={getRegisteredLgus}
          exportRecords={exportLGUs}
          forceRefresh={forceRefresh}
        />
      </CustomContainer>

      <BarangayList open={openBarangayListModal} setOpen={setOpenBarangayListModal} lgu={lgu!} />

      <UpdateLgu
        open={openUpdateLguModal}
        setOpen={setOpenUpdateLguModal}
        lgu={lgu!}
        refreshTable={refreshTable}
        languages={languages}
      />

      <AddLgu open={openAddLguModal} setOpen={setOpenAddLguModal} refreshTable={refreshTable} languages={languages} />

      <UserAccess open={openUserAccountsModal} setOpen={setOpenUserAccountsModal} lgu={lgu!} />

      <ConfirmationDialog
        open={openUpdateLguStatusDialog}
        setOpen={setOpenUpdateLguStatusDialog}
        content={alertContent}
        onConfirm={lguStatus === 1 ? handleDeactivateLgu : handleReactivateLgu}
        confirmButtonColor={lguStatus === 1 ? false : true}
      />
    </Box>
  );
};

export default LguManagement;
