import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  PopoverOrigin,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { UserContext } from 'core/context/user.context';
import { isDevMode } from './HideOrShowComponent';
import { tokens } from 'theme/theme';

export type DropdownOptionProps = {
  icon?: ReactNode;
  endIcon?: ReactNode;
  child?: any;
  label?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  action?: (data?: any) => void;
  hidden?: boolean;
  hideCallback?: (data: any) => boolean;
  parent?: boolean;
  optionList?: any;
  component?: React.ReactNode;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  requiredAuth?: string[];
};

type DropdownProps = {
  optionList: DropdownOptionProps[];
  data?: any;
  buttonLabel?: any;
  labelIsComponent?: boolean;
  isIcon?: boolean;
  icon?: any;
  startIcon?: any;
  optionIconStyle?: any;
  tooltip?: string;
  variant?: 'text' | 'contained' | 'outlined';
  styles?: SxProps<Theme>;
  tooltipPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  getData?: (data: any) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popupContainerStyle?: SxProps<Theme>;
  notFullWidth?: boolean;
  disabled?: boolean;
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

const Dropdown: React.FC<DropdownProps> = ({
  optionList,
  data,
  buttonLabel,
  labelIsComponent,
  isIcon,
  icon,
  startIcon,
  optionIconStyle,
  tooltip,
  tooltipPlacement = 'top',
  getData,
  variant = 'contained',
  styles,
  anchorOrigin,
  transformOrigin,
  popupContainerStyle,
  buttonColor,
  notFullWidth,
  disabled,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DropdownOptionProps[]>([]);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
    getData && getData(data);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setOptions(
      optionList.filter((option) => {
        if (option.requiredAuth?.length && isDevMode()) {
          const verified_auths = option.requiredAuth.filter((r) => user.authorities?.includes(r));
          return !!verified_auths.length;
        }

        return !(option.hidden || (option.hideCallback && option.hideCallback(data)));
      })
    );
  }, [optionList, data, user]);

  const OptionIcon = ({ icon }: { icon: ReactNode }) => {
    if (!icon) return <></>;
    return (
      <Typography
        sx={{
          lineHeight: 1,
          '& .MuiSvgIcon-root': {
            fontSize: '15px',
            marginTop: '3px',
            fontWeight: 'bold',
            ...optionIconStyle,
          },
        }}
      >
        {icon}
      </Typography>
    );
  };

  return (
    <Stack direction="row" spacing={2} width={isIcon || notFullWidth ? undefined : '100%'}>
      <>
        {isIcon ? (
          <Tooltip sx={{ backgroundColor: 'red' }} title={tooltip} placement={tooltipPlacement} arrow>
            <IconButton
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              size="small"
              sx={{}}
              disabled={disabled}
            >
              {icon} {buttonLabel}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            variant={variant}
            onClick={handleToggle}
            ref={anchorRef}
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            sx={{
              textTransform: 'none',
              borderRadius: 2,
              padding: '7px 25px',
            }}
            fullWidth={!notFullWidth}
            color={buttonColor}
            disabled={disabled}
          >
            <Box display="flex" gap="10px" alignItems="center" width="100%" justifyContent="space-between">
              <Box display="flex" gap="10px" alignItems="center" width="100%">
                <OptionIcon icon={startIcon} />
                {labelIsComponent ? (
                  buttonLabel
                ) : (
                  <Typography fontSize="12px" sx={{ textWrap: 'wrap', textAlign: 'left' }}>
                    {buttonLabel}
                  </Typography>
                )}
              </Box>
              <OptionIcon icon={icon} />
            </Box>
          </Button>
        )}
        <Popover
          open={open}
          id="popover"
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={anchorOrigin ?? { vertical: 'top', horizontal: 'left' }}
          transformOrigin={transformOrigin ?? { vertical: 'top', horizontal: 'right' }}
          sx={{ '& .MuiPaper-root': { borderRadius: '15px' } }}
        >
          <Paper sx={{ padding: '6px 10px', ...popupContainerStyle }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="dropdown-menu"
                onKeyDown={handleListKeyDown}
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                {options.map((option, index) =>
                  option.label === 'divider' ? (
                    <Divider style={{ marginBlock: '5px', ...option.style }} key={`${option.label}-${index}`} />
                  ) : option.parent ? (
                    <Box
                      key={`${option.label}-${index}`}
                      display="flex"
                      gap="15px"
                      alignItems="center"
                      sx={{ padding: '6px 16px' }}
                    >
                      <OptionIcon icon={option.icon} />

                      <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }} key={`${option.label}-${index}`}>
                        {option.label}
                      </Typography>
                    </Box>
                  ) : (
                    <Box key={`${option.label}-${index}`}>
                      {option.component ? (
                        <MenuItem style={{ padding: 0, width: '100%', borderRadius: '8px' }}>
                          {option.component}
                        </MenuItem>
                      ) : (
                        <Button
                          color={option.color}
                          sx={{
                            width: '100%',
                            borderRadius: '8px',
                            textTransform: 'none',
                            justifyContent: 'flex-start',
                            paddingInline: '15px',
                            backgroundColor:
                              option.color === 'error'
                                ? colors.light_red_background
                                : option.color === 'warning'
                                ? '#FEF9DB'
                                : option.color === 'success'
                                ? '#ECFADC'
                                : colors.light_blue_background,
                            ...option.labelStyle,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            option.action && option.action(data);
                            setOpen(false);
                          }}
                        >
                          <Box display="flex" gap="10px" alignItems="space-between" width="100%">
                            <Box display="flex" gap="10px" alignItems="center" width="100%">
                              <OptionIcon icon={option.icon} />
                              <Typography fontSize="12px" sx={{ ...(option.labelStyle || {}) }}>
                                {option.label}
                              </Typography>
                            </Box>
                            <OptionIcon icon={option.endIcon} />
                          </Box>
                        </Button>
                      )}
                    </Box>
                  )
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </>
    </Stack>
  );
};

export default Dropdown;
