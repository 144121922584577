import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'attribute-values';

export const getAttributeValueById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAttributeValues = (attribute_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/attribute/${attribute_id}`, {
    params: query,
  });
};

export const createAttributeValue = (attribute_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${attribute_id}`, data);
};

export const updateAttributeValue = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteAttributeValue = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
