import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import { Typography } from '@mui/material';

export const USER_ACCESS_ENTITY_NAME = 'User Access';
export interface UserAccessModel {
  id: number;
  company_id: number;
  user_group_id: number;
  username: string;
  google_id: null;
  first_name: string;
  middle_name: string;
  last_name: string;
  title?: string;
  email: string;
  mobile_number: string;
  read_terms: number;
  is_active: number;
  group_name: string;
  description: string;
  full_name: string;
  patient_limit: number;
  user_limit: number;
  storage_limit: number;
  assigned_facilities: string;
  facilities: any[];
}

export const UserAccessFields: EntityFields[] = [
  { fieldName: 'username', displayName: 'Username' },
  { fieldName: 'assigned_facilities', displayName: 'Assigned Facilities' },
  { fieldName: 'full_name', displayName: 'Full Name' },
  { fieldName: 'mobile_number', displayName: 'Mobile Number' },
  { fieldName: 'email', displayName: 'Email' },
  { fieldName: 'first_name', displayName: 'First Name', type: 'text' },
  { fieldName: 'middle_name', displayName: 'Middle Name (optional)', type: 'text' },
  { fieldName: 'last_name', displayName: 'Last Name', type: 'text' },
  { fieldName: 'title', type: 'text' },
  { fieldName: 'description', type: 'text' },
  { fieldName: 'created_at', type: 'date', displayName: 'Date Registered' },
  { fieldName: 'facilities', displayName: 'Clinics', type: 'multiselect', options: [], span: 4 },
  {
    fieldName: 'user_group_id',
    displayName: 'User Type',
    type: DROPDOWN_FIELD.USER_GROUP,
  },
  { fieldName: 'group_name', displayName: 'User Type', type: 'text' },
  {
    fieldName: 'is_active',
    displayName: 'Status',
    renderCell: (params: any) => <Typography>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</Typography>,
  },
];

export const UserAccessTableColumn: string[] = [
  'username',
  'full_name',
  'mobile_number',
  'email',
  'created_at',
  'group_name',
  'is_active',
];

export const UserAccessFormContent: string[] = [
  'facilities',
  'first_name',
  'middle_name',
  'last_name',
  'mobile_number',
  'user_group_id',
];

export const UserAccessInitialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  user_group_id: '',
  facilities: [],
};
