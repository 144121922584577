/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Collapse, Divider, FormControl, IconButton, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { RegularButton, SubmitButton } from 'core/components';

import { FORM_MODE } from 'core/model/interface';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

const dental_condition = [
  { key: 'P', value: 'Present' },
  { key: 'D', value: 'Caries indicated for Filling' },
  { key: '/', value: 'Caries indicated for Extraction' },
  { key: 'R', value: 'Root Fragment' },
  { key: 'M', value: 'Missing due to Caries' },
  { key: 'MO', value: 'Missing due to other causes' },
  { key: '+', value: 'Presence of the condition' },
  { key: 'Un', value: 'Unerupted Tooth' },
  { key: 'Im', value: 'Impacted Tooth' },
];

const dental_treatment = [
  { key: 'G', value: 'Gold Crown' },
  { key: 'J', value: 'Jacket Crown' },
  { key: 'AB', value: 'Abutment' },
  { key: 'Po', value: 'Pontic' },
  { key: 'I', value: 'Inlay' },
  { key: 'Fx', value: 'Fixed Bridge' },
  { key: 'Rm', value: 'Removable Bridge' },
  { key: 'A', value: 'Amalgam Filling' },
  { key: 'S', value: 'Silicon Filling' },
  { key: 'X', value: 'Extraction due to Caries' },
  { key: 'Xo', value: 'Extraction due to other causes' },
  { key: 'Ad', value: 'Adaptive Filling' },
];

type TeethData = {
  id: number;
  condition: string;
  treatment: string;
};

type DentalChartCirclesProps = {
  handleSubmit?: (data: TeethData[]) => void;
  initialData?: any;
  mode?: FORM_MODE;
  forceSubmit?: boolean;
  setIsDirty?: (isDirty: boolean) => void;
};

const DentalChartCircles: React.FC<DentalChartCirclesProps> = ({
  handleSubmit,
  initialData,
  mode,
  forceSubmit,
  setIsDirty,
}) => {
  const [open, setOpen] = useState(false);
  const [openT, setOpenT] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teethData, setTeethData] = useState<Array<TeethData>>(
    initialData?.dental_chart ??
      Array(32)
        .fill(0)
        .map((_, index) => ({ id: index + 1, condition: '', treatment: '' }))
  );

  const handleConditionChange = (index: number, condition: string) => {
    setIsDirty && setIsDirty(true);
    const updatedTeethData = [...teethData];
    updatedTeethData[index] = { ...updatedTeethData[index], condition };
    setTeethData(updatedTeethData);
  };

  const handleTreatmentChange = (index: number, treatment: string) => {
    setIsDirty && setIsDirty(true);
    const updatedTeethData = [...teethData];
    updatedTeethData[index] = { ...updatedTeethData[index], treatment };
    setTeethData(updatedTeethData);
  };

  const handleMarkAsPresent = () => {
    const updatedTeethData = teethData.map((tooth, index) => {
      if (tooth.condition === '') {
        return { ...tooth, condition: 'P', treatment: '' };
      }
      return tooth;
    });
    setTeethData(updatedTeethData);
    setIsDirty && setIsDirty(true);
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    if (initialData) {
      initialData.dental_chart = teethData;
      handleSubmit && handleSubmit(initialData);
    }

    e.preventDefault();
  };

  const displayNumber = (index: number, lower?: boolean) => {
    index = index > 16 ? index - 16 : index;
    let block = 1;
    let number = 9 - index;
    if (number <= 0) {
      number = Math.abs(number) + 1;
      block = 2;
    }
    return (lower ? block + 2 : block) + '' + number;
  };

  const displayBox = (tooth: TeethData, lower?: boolean) => {
    let style: any;
    if (lower) {
      style = tooth.id === 8 ? { borderLeft: '2px solid ' + colors.text, marginLeft: '-2px' } : undefined;
    } else {
      style = tooth.id === 8 ? { borderRight: '2px solid ' + colors.text, marginRight: '-2px' } : undefined;
    }

    return (
      <Box
        key={tooth.id - 1}
        width="100%"
        display="flex"
        flexDirection={lower ? 'column-reverse' : 'column'}
        gap="3px"
        marginBottom="10px"
      >
        {/* Dental Condition Dropdown */}
        <Box display="flex" flexDirection="column" gap="3px">
          <Box
            sx={{
              width: '100%',
              '& .MuiSelect-select': { padding: '10px !important', textAlign: 'center' },
              '& .MuiSvgIcon-root': { display: 'none' },
              // border: '1px solid ' + colors.accent,
            }}
          >
            <FormControl fullWidth>
              <Select
                readOnly={mode && mode !== FORM_MODE.RESPOND}
                value={tooth.condition}
                onChange={(event) => handleConditionChange(tooth.id - 1, event.target.value as string)}
                renderValue={(selected) => {
                  const selectedItem = dental_condition.find((item) => item.value === selected);
                  return selectedItem ? selectedItem.key : selected;
                }}
                disabled={mode && mode !== FORM_MODE.RESPOND}
                sx={{
                  '& .MuiSelect-select': {
                    border: '2px solid ' + colors.accent,
                  },
                }}
              >
                {dental_condition.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} - {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Dental Treatment Dropdown */}
          <Box
            sx={{
              width: '100%',
              '& .MuiSelect-select': { padding: '10px !important', textAlign: 'center' },
              '& .MuiSvgIcon-root': { display: 'none' },
            }}
          >
            <FormControl fullWidth>
              <Select
                readOnly={mode && mode !== FORM_MODE.RESPOND}
                value={tooth.treatment}
                onChange={(event) => handleTreatmentChange(tooth.id - 1, event.target.value as string)}
                renderValue={(selected) => {
                  const selectedItem = dental_treatment.find((item) => item.value === selected);
                  return selectedItem ? selectedItem.key : selected;
                }}
                disabled={mode && mode !== FORM_MODE.RESPOND}
                sx={{
                  '& .MuiSelect-select': {
                    border: '2px solid orange',
                  },
                }}
              >
                {dental_treatment.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.key} - {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Typography textAlign="center" sx={style}>
          {displayNumber(tooth.id, lower)}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (initialData?.dental_chart) {
      setTeethData(initialData.dental_chart);
    }
  }, [initialData]);

  useEffect(() => {
    if (forceSubmit) {
      if (initialData) {
        initialData.dental_chart = teethData;
        handleSubmit && handleSubmit(initialData);
      }
    }
  }, [forceSubmit]);
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleToggles = () => {
    setOpenT(!openT);
  };
  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <Box display={'flex'} justifyContent="space-between">
          <Box
            display={mode && mode !== FORM_MODE.RESPOND ? 'none' : 'flex'}
            justifyContent="end"
            mb={2}
            mt={-2}
            gap={'5px'}
            py={2}
          >
            <RegularButton label={'Mark the rest as Present'} onClick={handleMarkAsPresent} variant="outlined" />

            {!mode && <SubmitButton label={'Save'} loading={false} disabled={false} width={'auto'} />}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" mb="10px">
          <Typography fontWeight="bold">RIGHT</Typography>
          <Typography fontWeight="bold">LEFT</Typography>
        </Box>
        <Box display="flex" gap="3px">
          {teethData?.slice(0, 16).map((tooth) => (
            <Fragment key={tooth.id}>{displayBox(tooth)}</Fragment>
          ))}
        </Box>
        <Image src="/assets/DentalChart/dental_chart_circles.png" alt="dental_chart_top" />
        <Image src="/assets/DentalChart/dental_chart_circles.png" alt="dental_chart_bottom" />
        <Box display="flex" gap="3px" flexDirection="row-reverse">
          {teethData?.slice(16, 32).map((tooth) => (
            <Fragment key={tooth.id}>{displayBox(tooth, true)}</Fragment>
          ))}
        </Box>

        {!mode && (
          <Box display="flex" justifyContent="end" mt={2} gap={'5px'}>
            <RegularButton label={'Mark the rest as Present'} onClick={handleMarkAsPresent} variant="outlined" />
            <SubmitButton label={'Save'} loading={false} disabled={false} width={'auto'} />
          </Box>
        )}
      </form>

      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="20px" width="100%" marginTop="20px">
        <Box
          style={{
            border: open ? '1px solid transparent' : 'none',
            borderRadius: '10px',
            boxShadow: open ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px' : 'none',
          }}
        >
          <Box
            onClick={handleToggle}
            style={{
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              padding: '15px 0px',
              borderRadius: '10px',
              boxShadow: open ? 'none ' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              border: open ? 'none' : 'transparent',
            }}
          >
            <Box display="flex" gap="10px">
              <img src="/assets/dental2.svg" alt="" />
              <Typography variant="h5" fontWeight="bold">
                Dental Condition
              </Typography>
            </Box>

            <IconButton onClick={handleToggle} style={{ padding: 0 }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          {open && <Divider sx={{ margin: '0 0.5rem ' }} />}

          <Collapse in={open}>
            <Box
              display="grid"
              gridTemplateColumns="35px 1fr"
              gap="10px"
              sx={{
                padding: '40px 40px',
                borderRadius: '10px',
              }}
            >
              {dental_condition.map((item) => (
                <Fragment key={item.key}>
                  <Typography variant="h6" fontWeight="bold">
                    {item.key}
                  </Typography>
                  <Typography variant="h6">{item.value}</Typography>
                </Fragment>
              ))}
            </Box>
          </Collapse>
        </Box>

        <Box
          style={{
            border: openT ? '1px solid transparent' : 'none',
            borderRadius: '10px',
            boxShadow: openT ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px' : 'none',
          }}
        >
          <Box
            onClick={handleToggles}
            style={{
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              padding: '15px 0px',
              borderRadius: '10px',
              boxShadow: openT ? 'none ' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              border: openT ? 'none' : 'transparent',
            }}
          >
            <Box display="flex" gap="10px">
              <img src="/assets/dental1.svg" alt="" />
              <Typography variant="h5" fontWeight="bold">
                Dental Treatment
              </Typography>
            </Box>

            <IconButton onClick={handleToggles} style={{ padding: 0 }}>
              {openT ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          {openT && <Divider sx={{ margin: '0 0.5rem ' }} />}

          <Collapse in={openT}>
            <Box
              display="grid"
              gridTemplateColumns="35px 1fr"
              gap="10px"
              sx={{
                padding: '40px 40px',
                borderRadius: '10px',
              }}
            >
              {dental_treatment.map((item) => (
                <Fragment key={item.key}>
                  <Typography variant="h6" fontWeight="bold">
                    {item.key}
                  </Typography>
                  <Typography variant="h6">{item.value}</Typography>
                </Fragment>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default DentalChartCircles;

const Image = styled.img`
  width: 100%;
  border: none;
  z-index: -1;
`;
