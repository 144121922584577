/* eslint-disable react-hooks/exhaustive-deps */
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomTable,
  PrimaryButton,
  RowAction,
} from 'core/components';
import { calculateAge, changeNullToBlank, formatDate } from 'core/utils';
import { exportPatientsEnrolledInProgramReminder, getPatientsEnrolledInProgramReminders } from 'lgu/api/patient';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import BiotechIcon from '@mui/icons-material/Biotech';
import { Box } from '@mui/material';
import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EnrollToProgramReminder from '../components/common/EnrollToProgramReminder';
import { GridColDef } from '@mui/x-data-grid';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TbUpdateModal from '../components/TB/TbUpdateModal';
import { UserContext } from 'core/context/user.context';
import { tb_dots_stages } from 'lgu/data/select-options';
import { unenrollProgramRemindersPatients } from 'lgu/api/program-reminders-patients';
import { useSnackbar } from 'notistack';

type TbDotsProps = {
  refresh: number;
  setRefresh: (refreshTable: any) => void;
};

const TbDots: React.FC<TbDotsProps> = ({ refresh, setRefresh }) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [patient, setPatient] = useState<any>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedStage, setSelectedStage] = useState<number>(0);

  //enroll
  const [openEnrollModal, setOpenEnrollModal] = useState<boolean>(false);

  //unenroll
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //update
  const [openTbDotsUpdateForm, setOpenTbDotsUpdateForm] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<'update-patient' | 'update-sputum'>('update-patient');

  const columns: GridColDef[] = [
    { field: 'full_name', headerName: 'Patient Name', flex: 1 },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1, sortable: false },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    {
      field: 'date_started',
      headerName: 'Date Started',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.date_started)}</CustomGridCell>,
    },
    {
      field: 'tb_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>{params.row.tb_status === 'Intensive' ? 'Active' : params.row.tb_status}</CustomGridCell>
      ),
    },
    {
      field: 'tb_refill_interval',
      headerName: 'Refill Interval (Days)',
      flex: 1,
    },
    {
      field: 'action',
      flex: 1,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const showUnenrollPatient = (data: any) => {
    showUnenrollPatientDialog('Are you sure you want to unenroll ' + data.full_name + '?');
    setPatient(data);
  };

  const updateTbPatient = (data: any) => {
    setUpdateType('update-patient');
    setPatient(changeNullToBlank(data));
    setOpenTbDotsUpdateForm(true);
  };
  const updateTbSputumSchedules = (data: any) => {
    setUpdateType('update-sputum');
    setPatient(changeNullToBlank(data));
    setOpenTbDotsUpdateForm(true);
  };

  const actions = [
    { label: 'Update Patient', action: updateTbPatient, icon: <EditOutlinedIcon /> },
    { label: 'Update Sputum Schedules', action: updateTbSputumSchedules, icon: <BiotechIcon /> },
    { label: 'Unenroll Patient', action: showUnenrollPatient, icon: <PersonRemoveIcon /> },
  ];

  const steps_filter = [
    { label: 'All Patients', icon: <GroupsIcon /> },
    { label: 'Intensive', icon: 1 },
    { label: 'Week 8 Sputum Test', icon: 2 },
    { label: 'Week 20 Sputum Test', icon: 3 },
    { label: 'Completed', icon: 4 },
  ];

  const getPatientsEnrolled = (query: ApiQuery) => {
    setLoading(true);
    query.stage = tb_dots_stages[selectedStage];
    getPatientsEnrolledInProgramReminders('tb_dots', query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showEnrollmentModal = () => {
    setOpenEnrollModal(true);
  };

  const showUnenrollPatientDialog = (content: string) => {
    setOpenPatientUnenrollDialog(true);
    setAlertContent(content);
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify([patient.id]);
    const AcfPatientList = JSON.stringify([patient.patient_id]);
    const data = {
      patients: patientList,
      acf_patients: AcfPatientList,
    };
    unenrollProgramRemindersPatients('tb_dots', data)
      .then((res) => {
        enqueueSnackbar('Patient successfully unenrolled!', { variant: 'success' });
        getPatientsEnrolled({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const exportRecords = async (query: ApiQuery) => {
    exportPatientsEnrolledInProgramReminder('tb_dots', query, user.lgu?.lgu_name);
  };

  useEffect(() => {
    if (!firstLoad) {
      setRefresh((prev: number) => prev + 1);
    }
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    refreshTable();
  }, [selectedStage]);

  return (
    <CustomContainer>
      <Box m={2} width="80%" alignSelf="center">
        <CustomStepperNonLinear steps={steps_filter} activeStep={selectedStage} setActiveStep={setSelectedStage} />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <PrimaryButton
          onClick={() => {
            showEnrollmentModal();
          }}
          label="Enroll Patients"
        />
      </Box>
      <CustomTable
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getPatientsEnrolled}
        forceRefresh={refresh}
        exportRecords={exportRecords}
        showRefreshButton
      />

      <EnrollToProgramReminder
        programCode={'tb_dots'}
        refreshEnrolledTable={refreshTable}
        open={openEnrollModal}
        setOpen={setOpenEnrollModal}
      />

      <ConfirmationDialog
        open={openPatientUnenrollDialog}
        setOpen={setOpenPatientUnenrollDialog}
        content={alertContent}
        onConfirm={handlePatientUnenroll}
      />

      <TbUpdateModal
        header={'Update Patient'}
        open={openTbDotsUpdateForm}
        setOpen={setOpenTbDotsUpdateForm}
        patient={patient}
        updateType={updateType}
        refreshTable={refreshTable}
      />
    </CustomContainer>
  );
};

export default TbDots;
