import { MEDICINE_ENTITY_NAME, MedicineModel, medicineFields, medicineTableColumns } from './MedicineModel';
import { deleteMedicine, getMedicines } from 'company/api/medicines';
import { forwardRef, useRef, useState } from 'react';

import MedicineForm from './MedicineForm';
import TemplateTable from '../../../Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  setDrawerSelectedRow: (row: any) => void;
  drawerSelectedRow?: any;
  tableRef?: any;
};

const MedicineTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, setDrawerSelectedRow, drawerSelectedRow, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<MedicineModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <TemplateTable
      tableComponent={{
        mobileViewConfig: () => ({
          title_fields: ['generic_name', 'brand_name'],
          subtitle_fields: ['dosage'],
        }),
      }}
      ref={mainRef}
      entityName={MEDICINE_ENTITY_NAME}
      fields={medicineFields}
      addActionDisplayOverwrite={'Add ' + MEDICINE_ENTITY_NAME}
      updateActionDisplayOverwrite={'Update ' + MEDICINE_ENTITY_NAME}
      visibleColumns={visibleColumns ?? medicineTableColumns}
      drawerSelectedRow={drawerSelectedRow}
      selectedRow={selectedRow}
      handleDelete={deleteMedicine}
      getData={getMedicines}
      setSelectedRow={setSelectedRow}
      setDrawerSelectedRow={setDrawerSelectedRow}
      templateForm={<MedicineForm medicine={selectedRow} callbackAfterSubmit={callbackAfterSubmit} />}
    />
  );
});

export default MedicineTable;
