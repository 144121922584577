import {
  Dashboard,
  FamilyPlanning,
  GeneralHealthPrograms,
  PastProgramSchedule,
  ProgramSchedule,
  ProgramSchedules,
  RegistrationBoard,
  Settings,
  TB,
  UserManagement,
  ViewPatientProfile,
} from 'lgu/screens';
import { ForbiddenScreen, NotFoundScreen } from 'core/screens';

import { AccountType } from 'core/model/Entities';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';

const LguRoutes = (
  <Route path="/lgu" element={<ProtectedRoute accountType={AccountType.LGU} />}>
    <Route index element={<Dashboard />} />
    <Route path="patient-management">
      <Route index element={<RegistrationBoard />} />
      <Route path=":id" element={<ViewPatientProfile />} />
    </Route>

    <Route path="programs">
      <Route index element={<GeneralHealthPrograms />} />
      <Route path="program-schedules/:id" element={<ProgramSchedules />} />
      <Route path="program-schedules/details/:id" element={<ProgramSchedule />} />
      <Route path="program-schedules/past/:id" element={<PastProgramSchedule />} />
    </Route>

    <Route path="user-management" element={<UserManagement />} />
    <Route path="ocp" element={<FamilyPlanning />} />
    <Route path="tb-dots" element={<TB />} />
    <Route path="settings" element={<Settings />} />

    <Route path="not-found" element={<NotFoundScreen />} />
    <Route path="forbidden" element={<ForbiddenScreen />} />
    <Route path="*" element={<NotFoundScreen />} />
  </Route>
);

export default LguRoutes;
