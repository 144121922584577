import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'admin-sms-credits';

export const getPendingSmsCredits = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getPendingSmsCredit = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getSmsCredits = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-pending`, { params: query });
};

export const approveSmsCredit = (id: number) => {
  return axios.put(`${baseURL}/${path}/${id}`);
};

export const declineSmsCredit = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
