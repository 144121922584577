import { GridColDef } from '@mui/x-data-grid';
import { getAll, deleteOne } from 'carego-admin/api/carego-blocked-ips';
import { CustomGridCell, RegularButton } from 'core/components';
import { PageBuilderPageType } from 'core/PageBuilder';
import { formatDateTime } from 'core/utils';

import FlagIcon from '@mui/icons-material/Flag';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ReplayIcon from '@mui/icons-material/Replay';

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Box } from '@mui/material';

const BlockedIPs: React.FC = () => {
  const [forceRefresh, setForceRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'ip', headerName: 'IP Address', flex: 2 },
    {
      field: 'created_at',
      headerName: 'Block Datetime',
      flex: 2,
      renderCell: (param) => <CustomGridCell>{formatDateTime(param.row.created_at)}</CustomGridCell>,
    },
  ];

  const content: PageBuilderPageType = {
    type: BLOCK_TYPE.PAGE,
    header: 'Blocked IP Address',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Blocked IP Address',
        doNotWaitForFacility: true,
        tableAction: {
          rowActions: [
            {
              color: 'error',
              label: 'Remove',
              icon: <DeleteForeverIcon />,
              action: (param: any) => {
                deleteOne(param.id)
                  .then(() => {
                    enqueueSnackbar('Successfully removed blocked IP Address', { variant: 'success' });
                  })
                  .catch(() => {
                    enqueueSnackbar('Error occured in removing IP Address', { variant: 'error' });
                  })
                  .finally(() => {
                    setForceRefresh(forceRefresh + 1);
                  });
              },
            },
          ],
        },
        tableComponent: {
          forceRefresh: forceRefresh,
          columns: columns,
          getData: getAll,
          headerComponent: (
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <RegularButton
                startIcon={<ReplayIcon />}
                label="Refresh Table"
                onClick={() => setForceRefresh(forceRefresh + 1)}
              />
            </Box>
          ),
        },
      },
    },
  };

  return <PageBuilder content={content} />;
};

export default BlockedIPs;
