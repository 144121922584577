import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'service-products';

export const getServiceProducts = (query: any) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createServiceProduct = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateServiceProduct = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteServiceProduct = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getServiceProductById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getProductsByServiceId = (serviceId: number, query: any = {}) => {
  return axios.get(`${baseURL}/${path}/${serviceId}`, {
    params: {
      ...query,
      service_id: serviceId,
    },
  });
};
