import { CustomContainer, CustomTable, Header } from 'core/components';

import { ApiQuery } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatDateTime } from 'core/utils';
import { getActivityLogs } from 'lgu/api/activity-logs';
import { useState } from 'react';

const ActivityLogs = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const activityLogsColumns: GridColDef[] = [
    { field: 'full_name', headerName: 'User', flex: 1 },
    { field: 'description', headerName: 'Action', flex: 2 },
    {
      field: 'created_at',
      headerName: 'Date and Time',
      flex: 0.7,
      renderCell: (params) => <Typography>{formatDateTime(params.row.created_at)}</Typography>,
    },
  ];

  const getLguLogs = (query: ApiQuery) => {
    setLoading(true);

    getActivityLogs(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <CustomContainer>
      <CustomTable
        columns={activityLogsColumns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        rowId={'id'}
        getData={getLguLogs}
        initialOrder={'desc'}
        headerComponent={<Header title="Activity Logs" mb="0" />}
      />
    </CustomContainer>
  );
};

export default ActivityLogs;
