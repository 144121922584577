import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-hmos';


export const getHMOs = (query?: ApiQuery) => {
    return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getHMO = (id: number) => {
    return axios.get(`${baseURL}/${path}/${id}`);
};


export const createHMO = (data: any) => {
    return axios.post(`${baseURL}/${path}`, data);
};


export const updateHMO = (id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${id}`, data);
}

export const deleteHMO = (id: number) => {
    return axios.delete(`${baseURL}/${path}/${id}`);
}

