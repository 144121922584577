import { IconButton, useMediaQuery } from '@mui/material';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { SIZES } from 'theme/constants';

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 15000;

type CustomScnackBarProps = {
  children: React.ReactNode;
};

const CustomSnackBarWrapper: React.FC<CustomScnackBarProps> = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  
  function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)} sx={{ color: '#141414' }}>
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={AUTO_HIDE_DURATION}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      style={{
        height: '70px',
        fontSize: isMobile ? '13px' : '14px',
        fontWeight: 'bold',
        color: '#141414',
        borderRadius: SIZES.borderRadiusS, //#bae2bc
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackBarWrapper;
