/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Button, Typography } from '@mui/material';
import { BreadcrumbContext, BreadcrumbTabProps } from 'core/context/breadcrumb.context';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomModal,
  CustomTable,
  CustomTabs,
  Header,
  RegularButton,
} from 'core/components';
import { ProgramModel, ProgramScheduleModel } from 'lgu/model/Entities';
import {
  cloneProgramSchedule,
  enrollPatient,
  getProgramSchedule,
  unenrollPatient,
  updateProgramSchedule,
} from 'lgu/api/program-schedule';
import { enrolledColumns, notEnrolledColumns } from '../utils/table-columns';
import { formatDate, formatDateTime, tranformFormErrors } from 'core/utils';
import { getPatientsEnrolledInSchedule, getPatientsNotEnrolledInSchedule } from 'lgu/api/patient';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormikHelpers } from 'formik';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SettingsDropdown from '../components/SettingsDropdown';
import { TabProps } from 'core/components/CustomTabs';
import { clone_type } from 'lgu/data/select-options';
import { getBarangays } from 'lgu/api/barangay';
import { getProgram } from 'lgu/api/program';
import { update_program_schedule_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';

export type UserInput = {
  schedule_name: string;
  venue: string;
  barangay_code: string;
  program_date_time: string;
  clone_type: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const ProgramSchedule = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [enrolledRows, setEnrolledRows] = useState<any[]>([]);
  const [notEnrolledRows, setNotEnrolledRows] = useState<any[]>([]);
  const [enrolledRowCount, setEnrolledRowCount] = useState<number>(0);
  const [notEnrolledRowCount, setNotEnrolledRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [barangays, setBarangays] = useState<any[]>([]);

  //Schedule
  const [schedule, setSchedule] = useState<ProgramScheduleModel>();
  const [program, setProgram] = useState<ProgramModel>();
  const [alertContent, setAlertContent] = useState<string>('');
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [scheduleModalHeader, setScheduleModalHeader] = useState('Edit Schedule');
  const [scheduleInitialValues, setScheduleInitialValues] = useState<UserInput>();
  const [toClone, setToClone] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  //enrolled patients table
  const [openPatientUnenrollDialog, setOpenPatientUnenrollDialog] = useState<boolean>(false);

  //not enroll patients table
  const [openPatientEnrollDialog, setOpenPatientEnrollDialog] = useState<boolean>(false);

  const [selectedPatientIDs, setSelectedPatientIDs] = useState<any[]>([]);
  const [selectedPatientID, setSelectedPatientID] = useState<number>(0);

  // Form Fields
  const scheduleFields: UserFieldInput[] = [
    { field_name: 'schedule_name', display_name: 'Schedule Name', type: 'text' },
    {
      field_name: 'program_date_time',
      display_name: 'Date & Time',
      type: 'datetime',
      disablePast: true,
      minutesStep: 5,
    },
    { field_name: 'venue', display_name: 'Venue', type: 'text' },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay',
      type: 'select',
      options: barangays.map((u) => {
        return { key: u.barangay_name, value: u.barangay_code };
      }),
    },
    {
      field_name: 'clone_type',
      display_name: 'Clone Patients',
      type: 'select',
      hidden: toClone ? false : true,
      options: clone_type.map((u) => {
        return { key: u.key, value: u.value };
      }),
    },
  ];

  const addActionToTable = () => {
    if (!enrolledColumns.find((col) => col.field === 'action'))
      enrolledColumns.push({
        field: 'action',
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                showUnenrollPatientDialog(
                  'Are you sure you want to unenroll ' + params.row.full_name + '?',
                  params.row.patient_id
                );
              }}
            >
              Unenroll
            </Button>
          </Box>
        ),
      });

    if (!notEnrolledColumns.find((col) => col.field === 'action'))
      notEnrolledColumns.push({
        field: 'action',
        sortable: false,
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" gap="5px">
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={() => {
                showEnrollPatientDialog(
                  'Are you sure you want to enroll ' + params.row.full_name + '?',
                  params.row.patient_id
                );
              }}
            >
              Enroll
            </Button>
          </Box>
        ),
      });
  };

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const getEnrolledPatients = (query: ApiQuery) => {
    setLoading(true);
    getPatientsEnrolledInSchedule(parseInt(id as string), query)
      .then((res) => {
        setEnrolledRows(res.data.data);
        setEnrolledRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getNotEnrolledPatients = (query: ApiQuery) => {
    setLoading(true);
    getPatientsNotEnrolledInSchedule(parseInt(id as string), query)
      .then((res) => {
        setNotEnrolledRows(res.data.data);
        setNotEnrolledRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectPatients = (selected: GridRowSelectionModel) => {
    setSelectedPatientIDs(selected);
  };

  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      icon: <PersonAddAlt1Icon />,
      label: 'Enrolled Patients',
      content: (
        <CustomContainer>
          <CustomTable
            columns={enrolledColumns}
            rows={enrolledRows}
            rowCount={enrolledRowCount}
            loading={loading}
            rowId={'patient_id'}
            getData={getEnrolledPatients}
            checkboxSelection
            selectableRow
            handleSelectRow={handleSelectPatients}
            disableSelectionOnClick
            keepNonExistentRowsSelected={false}
            tableHeight={400}
            headerComponent={
              <Box display="flex" justifyContent="end" gap={2} width="100%">
                <RegularButton
                  label="Unenroll Patients"
                  disabled={!selectedPatientIDs.length}
                  onClick={() => {
                    if (selectedPatientIDs.length) {
                      showUnenrollPatientDialog(
                        'Are you sure you want to unenroll ' +
                          selectedPatientIDs.length +
                          ' patient/s from the program?'
                      );
                    } else {
                      enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
                    }
                  }}
                />
              </Box>
            }
          />
        </CustomContainer>
      ),
    },
    {
      icon: <PersonRemoveIcon />,
      label: 'Not Enrolled Patients',
      content: (
        <CustomContainer>
          <CustomTable
            columns={notEnrolledColumns}
            rows={notEnrolledRows}
            rowCount={notEnrolledRowCount}
            loading={loading}
            rowId={'patient_id'}
            getData={getNotEnrolledPatients}
            checkboxSelection
            selectableRow
            handleSelectRow={handleSelectPatients}
            disableSelectionOnClick
            keepNonExistentRowsSelected={false}
            rowHeight={45}
            headerComponent={
              <Box display="flex" justifyContent="end" gap={2} width="100%">
                <RegularButton
                  label="Enroll Patients"
                  disabled={!selectedPatientIDs.length}
                  onClick={() => {
                    if (selectedPatientIDs.length) {
                      showEnrollPatientDialog(
                        'Are you sure you want to enroll ' + selectedPatientIDs.length + ' patient/s to the program?'
                      );
                    } else {
                      enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
                    }
                  }}
                />
              </Box>
            }
          />
        </CustomContainer>
      ),
    },
  ];

  const cloneSchedule = () => {
    if (schedule) {
      setScheduleModalHeader('Clone Schedule');
      const clonedSchedule = Object.assign({}, schedule);
      clonedSchedule.schedule_name = clonedSchedule.schedule_name + ' - Copy';
      setScheduleInitialValues(clonedSchedule);
      setToClone(true);
    }

    setOpenScheduleModal(true);
  };

  const editSchedule = () => {
    if (schedule) {
      setScheduleModalHeader('Edit Schedule');
      setToClone(false);
      setScheduleInitialValues(schedule);
    }
    setOpenScheduleModal(true);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (schedule) {
      if (toClone) {
        cloneProgramSchedule(schedule.id, data)
          .then((res: any) => {
            successFormSubmit(true, res, formikHelpers);
          })
          .catch((error) => console.error(error));
        return;
      }

      updateProgramSchedule(schedule.id, data)
        .then((res: any) => {
          successFormSubmit(false, res, formikHelpers);
        })
        .catch((error) => console.error(error));
    }
  };

  const successFormSubmit = (clone: boolean, res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Program Schedule successfully ${clone ? 'cloned' : 'updated'}!`, { variant: 'success' });

      if (clone) {
        navigate('/program-schedules/' + res.data.program_schedule.id);
      }
      setOpenScheduleModal(false);
      getSchedule();
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  const getSchedule = () => {
    getProgramSchedule(parseInt(id as string))
      .then((res) => {
        if (new Date(res.data.program_date_time) < new Date()) {
          navigate('/programs/program-schedules/past/' + res.data.id);
        }
        setSchedule(res.data);
        getBarangayList();
        addActionToTable();
      })
      .catch();
  };

  const getProgramDetails = () => {
    schedule &&
      getProgram(schedule.program_id)
        .then((res: any) => {
          setProgram(res.data);
        })
        .catch();
  };

  const showUnenrollPatientDialog = (content: string, patient_id?: number) => {
    if ((selectedPatientIDs.length && !patient_id) || patient_id) {
      setOpenPatientUnenrollDialog(true);
      setAlertContent(content);
      setSelectedPatientID(patient_id ? patient_id : 0);
    }
  };

  const handlePatientUnenroll = () => {
    const patientList = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);
    const data = { patients: patientList };
    unenrollPatient(parseInt(id as string), data)
      .then(() => {
        enqueueSnackbar('Patient/s successfully unenrolled!', { variant: 'success' });
        getEnrolledPatients({ page: 1, length: 10 });
        getNotEnrolledPatients({ page: 1, length: 10 });
        setSelectedPatientIDs([]);
        setSelectedPatientID(0);
      })
      .catch((error) => console.error(error));
  };

  const showEnrollPatientDialog = (content: string, patient_id?: number) => {
    if ((selectedPatientIDs.length && !patient_id) || patient_id) {
      setOpenPatientEnrollDialog(true);
      setAlertContent(content);
      setSelectedPatientID(patient_id ? patient_id : 0);
    }
  };

  const handlePatientEnroll = () => {
    const patientList = JSON.stringify(selectedPatientID ? [selectedPatientID] : selectedPatientIDs);
    const data = { patients: patientList };
    enrollPatient(parseInt(id as string), data)
      .then(() => {
        enqueueSnackbar('Patient/s successfully enrolled!', { variant: 'success' });
        getEnrolledPatients({ page: 1, length: 10 });
        getNotEnrolledPatients({ page: 1, length: 10 });
        setSelectedPatientIDs([]);
        setSelectedPatientID(0);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getSchedule();
  }, []);

  useEffect(() => {
    getProgramDetails();
  }, [schedule]);

  useEffect(() => {
    setBreadcrumb([]);
    if (program) {
      const breadcrumbs: BreadcrumbTabProps[] = [
        {
          label: 'Health Programs',
          link: '/programs',
        },
        {
          label: program.program_name,
          link: '/programs/program-schedules/' + program.id,
        },
      ];

      if (schedule)
        breadcrumbs.push({
          label: schedule.schedule_name,
        });

      setBreadcrumb(breadcrumbs);
    }
  }, [program]);

  return (
    <Box>
      {schedule && (
        <>
          <CustomContainer m="0 0 25px 0">
            {schedule && (
              <Box width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Header title={schedule.schedule_name} />
                  <Box>
                    {program && (
                      <SettingsDropdown
                        hideQuickEnroll
                        schedule={schedule}
                        program={program}
                        button_label="Settings"
                        editSchedule={editSchedule}
                        cloneSchedule={cloneSchedule}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="grid" gridTemplateColumns="100px 1fr 100px 1fr" columnGap="20px" alignItems="center">
                  <Typography>Date & Time</Typography>
                  <Typography>{formatDateTime(schedule.program_date_time)}</Typography>

                  <Typography>Created by</Typography>
                  <Typography>{schedule.creator}</Typography>

                  <Typography>Venue</Typography>
                  <Typography>
                    {schedule.venue}, Brgy. {schedule.barangay_name}
                  </Typography>

                  <Typography>Date Created</Typography>
                  <Typography>{formatDate(schedule?.created_at)}</Typography>
                </Box>
              </Box>
            )}
          </CustomContainer>

          <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />

          <ConfirmationDialog
            open={openPatientUnenrollDialog}
            setOpen={setOpenPatientUnenrollDialog}
            content={alertContent}
            onConfirm={handlePatientUnenroll}
          />
          <ConfirmationDialog
            open={openPatientEnrollDialog}
            setOpen={setOpenPatientEnrollDialog}
            content={alertContent}
            onConfirm={handlePatientEnroll}
            confirmButtonColor
          />
          <CustomModal header={scheduleModalHeader} open={openScheduleModal} setOpen={setOpenScheduleModal}>
            <CustomForm
              initialValues={scheduleInitialValues}
              onSubmit={handleSubmit}
              fields={scheduleFields}
              schema={update_program_schedule_schema}
              loading={buttonLoading}
            />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default ProgramSchedule;
