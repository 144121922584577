import { Box, SxProps, Theme } from '@mui/material';

/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode } from 'react';
import { SIZES } from 'theme/constants';

type Props = {
  gap?: string;
  children: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
};

const ContainerRow: React.FC<Props> = ({ gap = SIZES.padding, children, sx }) => {
  return (
    <Box
      display="flex"
      width="100%"
      gap={gap}
      sx={{
        // alignItems: 'flex-start',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerRow;
