/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Divider, TextField, Typography, useMediaQuery } from '@mui/material';
import { Header, HeaderMobile } from 'LandingPage/Styles';
import { useEffect, useState } from 'react';

import { CompanyFacilityModel } from 'company/model/Entities';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Navbar from 'LandingPage/Navbar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ServiceIcon from './ServiceIcon';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { formatCurrency } from 'core/utils';
import { getFacility } from 'company/api/facility';
import { getServicesAssigned } from 'company/api/services';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const BookingPageNew = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [services, setServices] = useState<any[]>([]);
  const { facility_id } = useParams<{ facility_id: string }>();
  const [rawClinic, setRawClinic] = useState<CompanyFacilityModel>();
  const [filteredServices, setFilteredServices] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchServices = async () => {
      if (facility_id) {
        try {
          const response = await getServicesAssigned(Number(facility_id), {});
          setServices(response.data.data);
        } catch (error) {
          console.error('Error fetching services:', error);
        }
      }
    };

    fetchServices();
  }, [facility_id]);

  useEffect(() => {
    getFacility(Number(facility_id)).then((response) => {
      setRawClinic(response.data);
    });
  }, [facility_id]);

  useEffect(() => {
    const filtered = services.filter((service) =>
      service.service_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredServices(filtered);
  }, [searchTerm, services]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box>
      {isMobile ? (
        // Mobile layout
        <>
          <Navbar />
          <MobileContainer>
            <MobileContent>
              <HeaderMobile>Book an Appointment</HeaderMobile>
              <ParagraphMobile>
                Schedule your visit with ease by booking an appointment online. Select your desired service and time,
                and we'll ensure you receive the care you need.
              </ParagraphMobile>
            </MobileContent>
          </MobileContainer>
        </>
      ) : (
        // Desktop layout
        <>
          <Navbar />
          <PageContainer>
            <MainContainer>
              <BoxHorizontal>
                <BoxVertical>
                  <Header>
                    <BoxHorizontal>
                      <TodayOutlinedIcon sx={{ fontSize: '30px', marginRight: '20px' }} /> Book an Appointment
                    </BoxHorizontal>
                  </Header>
                  <Paragraph>
                    Schedule your visit with ease by booking an appointment online. <br /> Select your desired service
                    and time, and we'll ensure you receive the care you need.
                  </Paragraph>
                </BoxVertical>
                <Images src="/assets/bookingImgNew.png" alt="Logo" style={{ width: '200px', marginRight: '100px' }} />
              </BoxHorizontal>
            </MainContainer>
            {rawClinic && (
              <ClinicContainer>
                <BoxHorizontal
                  style={{
                    width: '78vw',
                    height: '160px',
                  }}
                >
                  <BoxVertical
                    style={{ rowGap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Header>
                      <BoxHorizontal
                        style={{
                          color: '#0192D5',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        {/* <Images
                          src="/assets/carego_health_suite_logo.png"
                          alt="Logo"
                          style={{ width: '60px', marginRight: '10px' }}
                        /> */}
                        {rawClinic?.facility_name}
                      </BoxHorizontal>
                      <Divider
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '10px',
                        }}
                      />
                      <BoxHorizontal
                        style={{
                          color: '#0192D5',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '30px',
                        }}
                      >
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center', color: 'black', marginBottom: '20px' }}
                        >
                          <LocalPhoneIcon style={{ marginRight: '20px', color: '#0192D5' }} />{' '}
                          {`${rawClinic?.globe_mobile_number} | ${rawClinic?.globe_mobile_number}`}
                        </Typography>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center', color: 'black', marginBottom: '20px' }}
                        >
                          <Logo1 src="/assets//landingassets/mail.svg" /> {rawClinic?.email}
                        </Typography>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center', color: 'black', marginBottom: '20px' }}
                        >
                          <Logo1 src="/assets//landingassets/location_on.svg" /> {rawClinic?.address}
                        </Typography>
                      </BoxHorizontal>
                    </Header>
                  </BoxVertical>
                </BoxHorizontal>
              </ClinicContainer>
            )}
            <ServicesContainer>
              <BoxHorizontal>
                <Images src="/assets/pointing.png" alt="Point" style={{ width: '180px', marginRight: '-20px' }} />

                <BoxVertical>
                  <BoxHorizontal
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0px 20px',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#0192D5',
                        fontSize: '18px',
                        fontWeight: '500',
                      }}
                    >
                      Select a service
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search services..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchOutlinedIcon style={{ color: '#0192D5', marginRight: '8px' }} />,
                      }}
                    />
                  </BoxHorizontal>

                  <GridContainer>
                    {filteredServices.length > 0 ? (
                      filteredServices.map((service) => (
                        <ServiceIcon
                          key={service.id}
                          title={service.service_name}
                          duration={`${service.duration} mins`}
                          price={formatCurrency(service.price)}
                          serviceId={Number(service.id)}
                          facilityId={Number(facility_id)}
                        />
                      ))
                    ) : (
                      <Typography>No existing service</Typography>
                    )}
                  </GridContainer>
                </BoxVertical>
              </BoxHorizontal>
            </ServicesContainer>
          </PageContainer>
        </>
      )}
    </Box>
  );
};

export default BookingPageNew;

const Images = styled.img`
  height: auto;

  max-width: 100%;
  @media (max-width: 1700px) {
    max-width: 55%;
  }
  @media (max-width: 768px) {
    max-width: 88%;
    right: 0;
  }
`;

const Logo1 = styled.img`
  height: 30px;
  margin-right: 20px;
  @media (max-width: 768px) {
    height: 20px;
  }
`;

const MobileContainer = styled.div`
  width: 95%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('/assets//LandingMobile/background1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 50px;
`;

const MobileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
`;

const MainContainer = styled.div`
  width: 78vw;
  margin-top: 20px;
  height: 40%;
  display: flex;
  justify-content: center;
  background-image: url('/assets//LandingMobile/background1.png');
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  border-radius: 20px;
  padding: 30px 50px;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
    background-size: 100% 100%;
    background-image: url('/assets//LandingMobile/background1.png');
  }
`;

const ClinicContainer = styled.div`
  width: 78vw;
  margin-top: 20px;
  height: 20%;
  display: flex;
  justify-content: center;

  border-radius: 20px;
  padding: 10px 50px 30px 50px;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
    background-size: 100% 100%;
    background-image: url('/assets//LandingMobile/background1.png');
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-height: 200px;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

// const ServiceIcon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: start;
//   text-align: center;
//   padding: 10px 30px;
//   border-radius: 8px;
//   min-height: 50px;
//   background-color: #f2f9ff;
//   color: #45464e;
//   font-weight: 600;
//   font-size: 18px;
// `;

const ServicesContainer = styled.div`
  width: 78vw;
  margin-top: 20px;
  max-height: 40%;
  min-height: 40%;

  display: flex;
  justify-content: center;

  border-radius: 20px 20px 0 0;
  padding: 0px 50px 30px 50px;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
    background-size: 100% 100%;
    background-image: url('/assets//LandingMobile/background1.png');
  }
`;

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
  word-spacing: 2px;
  font-family: Poppins;
  padding-right: 40px;
  color: white;
  @media (max-width: 1500px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ParagraphMobile = styled(Paragraph)`
  padding-right: 0;
  margin: 20px 0;
`;

const BoxVertical = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

const BoxHorizontal = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: Poppins;
`;
