import { Box, useTheme } from '@mui/material';

import { ReactNode } from 'react';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

type Props = {
  viewAll?: boolean;
  children: ReactNode | ReactNode[];
};

const InventoryTransactionContainer: React.FC<Props> = ({ viewAll, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        backgroundColor: colors.light_blue_background,
        borderRadius: SIZES.borderRadiusS,
        display: 'flex',
        alignItems: viewAll ? 'center' : 'flex-start',
        flexDirection: viewAll ? 'row' : 'column',
      }}
    >
      {children}
    </Box>
  );
};

export default InventoryTransactionContainer;
