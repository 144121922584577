/* eslint-disable react-hooks/exhaustive-deps */
import { ApiQuery, FieldInput } from 'core/model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import { HealthCategoriesModel, SMSTemplatesModel } from 'lgu/model/Entities';
import { changeNullToBlank, tranformFormErrors } from 'core/utils';
import { createProgram, deleteProgram, getPrograms, updateProgram } from 'lgu/api/program';
import { create_program_schema, update_program_schema } from 'lgu/model/schema';
import { useContext, useEffect, useState } from 'react';

import AdditionalEndOfDayReceivers from 'lgu/screens/GeneralHealthPrograms/components/AdditionalEndOfDayReceiversModal';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EndOfDayReceivers from '../components/EndOfDayReceiversModal';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from 'react-router-dom';
import { getHealthProgramCategories } from 'lgu/api/setup';
import { getHealthProgramsSMSTemplates } from 'lgu/api/sms';
import { useSnackbar } from 'notistack';

type UserInput = {
  program_name: string;
  program_category: string;
  sms_template: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  program_name: '',
  program_category: '',
  sms_template: '',
};

const HealthPrograms = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openProgramModal, setOpenProgramModal] = useState<boolean>(false);
  const [programSchema, setProgramSchema] = useState<any>(create_program_schema);
  const [programModalHeader, setProgramModalHeader] = useState('Create Program');
  const [programInitialValues, setProgramInitialValues] = useState<UserInput>(initialValues);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [programID, setProgramID] = useState<number>();

  const [categories, setCategories] = useState<HealthCategoriesModel[]>([]);
  const [templates, setTemplates] = useState<SMSTemplatesModel[]>([]);

  //EOD Receivers
  const [openEodFixedModal, setOpenEodFixedModal] = useState<boolean>(false);
  const [openEodReceiverModal, setOpenEodReceiverModal] = useState<boolean>(false);

  const showProgramModal = (from_edit?: boolean, data?: UserInput) => {
    if (!categories.length) getProgramCategories();
    if (!templates.length) getTemplates();

    if (from_edit && data) {
      setProgramInitialValues(data);
      setProgramModalHeader('Update Program');
      setProgramSchema(update_program_schema);
    } else {
      setProgramModalHeader('Create Program');
      setProgramInitialValues(initialValues);
      setProgramSchema(create_program_schema);
    }

    setOpenProgramModal(true);
  };

  const editHealthProgram = (data: any) => {
    const to_update = changeNullToBlank(data);
    showProgramModal(true, to_update);
  };

  const deleteHealthProgram = (data: any) => {
    showDeleteProgramDialog('Are you sure you want to delete ' + data.program_name + '?');
    setProgramID(data.id);
  };

  const showDeleteProgramDialog = (content: string) => {
    setOpenAlertDialog(true);
    setAlertContent(content);
  };

  const handleProgramDelete = () => {
    deleteProgram(programID!)
      .then((res: any) => {
        enqueueSnackbar('Health Program successfully deleted!', { variant: 'success' });
        getProgramsList({ page: 1, length: 10 });
      })
      .catch((error) => console.error(error));
  };

  const viewEodReceivers = (data: any) => {
    setProgramID(data.id);
    setOpenEodReceiverModal(true);
  };

  const actions = [
    { label: 'Edit Program', action: editHealthProgram, icon: <EditOutlinedIcon /> },
    { label: 'Delete Program', action: deleteHealthProgram, icon: <DeleteOutlinedIcon /> },
    { label: 'View EOD Receivers', action: viewEodReceivers, icon: <GroupsIcon /> },
  ];

  const columns: GridColDef[] = [
    {
      field: 'program_name',
      headerName: 'Program Name',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          <Link to={`program-schedules/${params.row.id}`} className="emphasis">
            {params.row.program_name}
          </Link>
        </CustomGridCell>
      ),
    },
    { field: 'category', headerName: 'Category', flex: 1.5 },
    { field: 'incoming_schedules', headerName: 'Schedules', headerAlign: 'right', align: 'right', flex: 1 },
    { field: 'participants', headerName: 'Participants', headerAlign: 'right', align: 'right', flex: 1 },
    { field: 'template_name', headerName: 'SMS Template', flex: 1.5 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  // Form Fields
  const programFields: UserFieldInput[] = [
    { field_name: 'program_name', display_name: 'Program Name', type: 'text' },
    {
      field_name: 'program_category',
      display_name: 'Category',
      type: 'select',
      options: categories?.map((u) => {
        return { key: u.category, value: u.id };
      }),
    },
    {
      field_name: 'sms_template',
      display_name: 'SMS Template',
      type: 'select',
      options: templates?.map((u) => {
        return { key: u.template_name, value: u.id };
      }),
    },
  ];

  const getProgramsList = (query: ApiQuery) => {
    setLoading(true);
    getPrograms(query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getProgramCategories = () => {
    getHealthProgramCategories()
      .then((res) => {
        setCategories(res.data);
      })
      .catch();
  };

  const getTemplates = () => {
    getHealthProgramsSMSTemplates()
      .then((res) => {
        setTemplates(res.data);
      })
      .catch();
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (data.id) {
      updateProgram(data.id, data)
        .then((res: any) => {
          successFormSubmit(true, res, formikHelpers);
        })
        .catch((error) => console.error(error));
      return;
    }

    createProgram(data)
      .then((res: any) => {
        successFormSubmit(false, res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (from_edit: boolean, res: any, { resetForm, setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Health Program successfully ${from_edit ? 'updated' : 'created'}!`, { variant: 'success' });
      getProgramsList({ page: 1, length: 10 });
      if (!from_edit) {
        resetForm();
        setOpenProgramModal(false);
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Health Programs' }]);
  }, []);

  return (
    <Box>
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PrimaryButton onClick={() => showProgramModal()} label="Create Program" />
          <Box display="flex" gap="10px">
            <RegularButton onClick={() => setOpenEodFixedModal(true)} label="View EOD Receivers" />
            {/* <RegularButton onClick={() => undefined} label="View SMS Schedules" /> */}
          </Box>
        </Box>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getProgramsList}
        />
      </CustomContainer>

      <CustomModal header={programModalHeader} open={openProgramModal} setOpen={setOpenProgramModal}>
        <CustomForm
          initialValues={programInitialValues}
          onSubmit={handleSubmit}
          fields={programFields}
          schema={programSchema}
          loading={buttonLoading}
        />
      </CustomModal>

      <AdditionalEndOfDayReceivers
        open={openEodReceiverModal}
        setOpen={setOpenEodReceiverModal}
        programID={programID!}
      />

      <EndOfDayReceivers open={openEodFixedModal} setOpen={setOpenEodFixedModal} />

      <ConfirmationDialog
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
        content={alertContent}
        onConfirm={handleProgramDelete}
      />
    </Box>
  );
};

export default HealthPrograms;
