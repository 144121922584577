import { Box, SxProps, Theme } from '@mui/material';
/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, ReactNode } from 'react';

import { SIZES } from 'theme/constants';

type Props = {
  gap?: string;
  children: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
  className?: string;
  ref?: MutableRefObject<undefined>;
};

const ContainerColumn: React.FC<Props> = ({ gap = SIZES.padding, children, sx, className }) => {
  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      width="100%"
      gap={gap}
      sx={{
        alignItems: 'stretch',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerColumn;
