import { CustomForm, CustomModal } from 'core/components';

import { BarangayHealthworkerModel } from 'lgu/model/Entities';
import { FieldInput } from 'core/model/interface';
import { updateBarangayHealthworker } from 'lgu/api/health-workers';
import { update_bhw_schema } from 'lgu/model/schema';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type HealthworkerUpdateFormProps = {
  open: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setRefreshAssigned: React.Dispatch<React.SetStateAction<number>>;
  setOpen: (openModal: boolean) => void;
  healthworker: BarangayHealthworkerModel;
};

type UserInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  sex: string;
};

interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const HealthworkerUpdateForm: React.FC<HealthworkerUpdateFormProps> = ({
  setRefresh,
  setRefreshAssigned,
  open,
  setOpen,
  healthworker,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: UserInput = {
    first_name: healthworker?.first_name,
    middle_name: healthworker?.middle_name,
    last_name: healthworker?.last_name,
    mobile_number: healthworker?.mobile_number,
    sex: healthworker?.sex,
  };

  const healthworkFields: UserFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    {
      field_name: 'sex',
      display_name: 'Sex Assigned At Birth',
      type: 'select',
      options: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
      ],
    },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
  ];

  //to fix
  const handleUpdate = (data: any) => {
    setLoading(true);

    const healthworkerData = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      mobile_number: data.mobile_number,
      sex: data.sex,
    };

    updateBarangayHealthworker(healthworker.id, healthworkerData)
      .then((res) => {
        enqueueSnackbar('Barangay Health Worker successfully updated!', { variant: 'success' });
        setRefresh((prev: number) => prev + 1);
        setRefreshAssigned((prev: number) => prev + 1);
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <CustomModal header="Update Health Worker" open={open} setOpen={setOpen}>
      <CustomForm
        onSubmit={handleUpdate}
        fields={healthworkFields}
        schema={update_bhw_schema}
        initialValues={initialValues}
        loading={loading}
      />
    </CustomModal>
  );
};

export default HealthworkerUpdateForm;
