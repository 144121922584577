import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getAllHealthAssessmentBatches, getSingleHealthAssessmentBatch } from 'company/api/health-assessment-batches';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const HealthAssessmentBatchDropDown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);
  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'Health Assessment Batch'}
        getData={(query: ApiQuery) => getAllHealthAssessmentBatches(facility.id, query)}
        getById={(id: number) => getSingleHealthAssessmentBatch(facility.id, id)}
        processOption={(record) => ({
          key: !!record.company_name ? `${record.batch_name} - ${record.company_name}` : record.batch_name,
          value: record.id,
        })}
        processNewRecord={(record) => record.data}
      />
    </WaitForFacility>
  );
};

export default HealthAssessmentBatchDropDown;
