import { Box, IconButton, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from './containers';
import { ReactNode, useContext, useEffect, useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import Dropdown from './Dropdown';
import { ForbiddenScreen } from 'core/screens';
import RegularButton from './buttons/RegularButton';
import { SIZES } from 'theme/constants';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import TabPanel from './CustomTabPanels';
import { TabProps } from './CustomTabs';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useSearchParams } from 'react-router-dom';

type SectionDropdownProps = {
  optionList: TabProps[];
  popupContainerStyle?: SxProps<Theme>;
  urlKeyId: string;
  extraActions?: ReactNode | ReactNode[];
  hiddenActionsFor?: string[];
  stickyTop?: number;
};

const SectionDropdown: React.FC<SectionDropdownProps> = ({
  optionList,
  popupContainerStyle,
  urlKeyId,
  extraActions,
  hiddenActionsFor,
  stickyTop = 0,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlKey = searchParams.get(urlKeyId);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [value, setValue] = useState<number>(urlKey ? parseInt(urlKey) : 0);
  const [openSwitchView, setOpenSwitchView] = useState<boolean>(false);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([]);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleOpenSwitchView = () => {
    setOpenSwitchView(!openSwitchView);
  };

  const handleViewChange = (index: number) => {
    setValue(index);
    setOpenSwitchView(false);
  };

  useEffect(() => {
    if (optionList.length && value >= optionList.length) {
      setSearchParams((searchParams) => {
        setValue(0);
        searchParams.set(urlKeyId, '0');
        return searchParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionList.length, value]);

  useEffect(() => {
    if (!selectedTabs.includes(value)) {
      setSelectedTabs((prev) => [...prev, value]);
    }

    if (!firstLoad) {
      setSearchParams((searchParams) => {
        searchParams.set(urlKeyId, value.toString());
        return searchParams;
      });
    }
    setFirstLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const shouldHideActions = hiddenActionsFor && hiddenActionsFor.includes(optionList[value]?.label);

  return (
    <ContainerColumn gap="10px" sx={{ alignItems: 'flex-end' }}>
      <ContainerRow
        sx={{
          position: 'sticky',
          top: stickyTop,
          left: 2,
          zIndex: 3,
          backgroundColor: colors.background,
          paddingBlock: SIZES.paddingS,
          width: '100%',
          borderRadius: '5px',
        }}
      >
        <Typography fontWeight={'600'} variant="h4" flex="1">
          {optionList[value]?.label}
        </Typography>
        {isMobile ? (
          <IconButton onClick={handleOpenSwitchView} style={{ padding: 0 }}>
            <SwitchAccessShortcutIcon sx={{ fontSize: '20px', color: colors.accent }} />
          </IconButton>
        ) : (
          <ContainerRow gap={SIZES.borderRadiusS} sx={{ justifyContent: 'flex-end', width: 'unset', zIndex: 11 }}>
            {!shouldHideActions && extraActions}
            <Dropdown
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              icon={<ArrowDropDownOutlinedIcon />}
              startIcon={optionList[value]?.icon}
              variant="outlined"
              optionList={optionList.map((option, index) => ({
                label: option.label,
                icon: option.icon,
                hidden: option.hidden,
                action: () => setValue(index),
              }))}
              buttonLabel={optionList[value]?.label}
              popupContainerStyle={popupContainerStyle}
            />
          </ContainerRow>
        )}
      </ContainerRow>
      <Box width="100%">
        {optionList.map((option, index) => (
          <TabPanel value={value} index={index} key={index}>
            <Box width="100%" minHeight="calc(100vh - 220px)">
              {selectedTabs.includes(index) && (
                <>
                  {!option.requiredAuth ||
                  option.requiredAuth.filter((r) => user.authorities?.includes(r)).length > 0 ? (
                    option.content
                  ) : (
                    <ForbiddenScreen hideButton />
                  )}
                </>
              )}
            </Box>
          </TabPanel>
        ))}
      </Box>

      <CustomBottomPopover open={openSwitchView} title="Switch View" setOpen={setOpenSwitchView}>
        <Box
          display="flex"
          flexDirection="column"
          gap={SIZES.paddingS}
          alignItems="center"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 0.5rem',
          }}
        >
          {optionList.map((option, index) => (
            <RegularButton
              key={option.label}
              size="small"
              variant="outlined"
              label={option.label}
              onClick={() => handleViewChange(index)}
              startIcon={option.icon}
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
            />
          ))}
        </Box>
      </CustomBottomPopover>
    </ContainerColumn>
  );
};

export default SectionDropdown;
