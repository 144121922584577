/* eslint-disable react-hooks/exhaustive-deps */

import { HideForInactiveCompany, RegularButton } from 'core/components';
import { PATIENT_ENTITY_NAME, patientFields, patientTableColumns } from './PatientModel';
import { deletePatient, getPatients, getPatientsWithClientId, getPatientsWithGroupId } from 'company/api/patient';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { calculateAge } from 'core/utils';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import PatientForm from './PatientForm';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { TbAcfCompanyPatientModel } from 'company/model/Entities';
import TemplateTable from '../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';
import { useNavigate } from 'react-router-dom';

type Props = {
  visibleColumns?: string[];
  serviceProviders?: any[];
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  hideActions?: boolean;
  groupId?: number;
  corporateClientId?: number;
  withContainer?: boolean;
  showViewPatientGroup?: boolean;
  tableRef?: any;
  tableAction?: TableActionProps;
  hideSearch?: boolean;
  hideViews?: boolean;
};

const PatientTable = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const {
    visibleColumns,
    setDrawerSelectedRow,
    drawerSelectedRow,
    hideActions = false,
    groupId,
    corporateClientId,
    withContainer,
    showViewPatientGroup,
    tableRef,
    tableAction,
    hideSearch,
    hideViews,
  } = props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<TbAcfCompanyPatientModel>();

  useEffect(() => {
    mainRef.current.refreshTable();
  }, [groupId, corporateClientId]);

  const getData = (query: ApiQuery) => {
    if (facility) {
      if (groupId) return getPatientsWithGroupId(facility.id, groupId, query);
      else if (corporateClientId) return getPatientsWithClientId(facility.id, corporateClientId, query);
      else return getPatients(facility.id, query);
    } else {
      return getPatients(1, query); //todo
    }
  };

  const handleDelete = (data: any) => {
    return deletePatient(facility!.id, data);
  };

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  return (
    <>
      <TemplateTable
        hideAddButton={hideActions}
        hideDeleteAction={hideActions}
        hideUpdateAction={hideActions}
        hideSearch={hideSearch}
        hideViews={hideViews}
        ref={mainRef}
        tourTriggerIndex={3}
        entityName={PATIENT_ENTITY_NAME}
        fields={patientFields}
        visibleColumns={visibleColumns ?? patientTableColumns}
        rowId="patient_id"
        getData={getData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        withContainer={withContainer}
        tableAction={tableAction}
        useColoredTable
        templateForm={
          <WaitForFacility facility={facility}>
            <PatientForm patient={selectedRow} facility={facility!} callbackAfterSubmit={callbackAfterSubmit} />
          </WaitForFacility>
        }
        processFormInitialValues={(data) => {
          data.tags = data.tags.map((tag: any) => {
            return { key: tag.tag_name, value: tag.id };
          });
        }}
        tableComponent={{
          headerComponent: showViewPatientGroup ? (
            <HideForInactiveCompany>
              <RegularButton
                onClick={() => navigate('/company/patient-management/groups')}
                label="View Patient Groups"
                startIcon={<SellOutlinedIcon />}
              />
            </HideForInactiveCompany>
          ) : undefined,
          mobileViewConfig: (data) => ({
            title_fields: ['full_name'],
            title_link: `/company/patient-management?patient_id=${data.patient_id}`,
            subtitle_fields: ['sex', 'age'],
          }),
        }}
      />
    </>
  );
});

export default PatientTable;
