import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'billing-payments';

export const getPayments = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getPaymentsByInvoiceId = (facility_id: number, invoice_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/all/${invoice_id}`, { params: query });
};

export const getPayment = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createPayment = (facility_id: number, id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const updatePayment = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deletePayment = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getTotalPaid = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/total-paid/year`, { params: query });
};

export const getTotalPaidCustom = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/total-paid/custom`, { params: query });
};

export const getTotalRemainingBalance = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/total/remaining-balance`, { params: query });
};