import * as yup from 'yup';

import { FieldInput, SelectOption } from 'core/model/interface';

import { CompanyTagsModel } from 'company/model/Entities';
import { EntityFields } from '../../../utils/index';

export const PROMO_ENTITY_NAME = 'Promo';

export const create_promotion_schema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  description: yup.string().nullable(),
  discount_type: yup.string().required('Discount Type is required'),
  start: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Start date is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  end: yup
    .date()
    .typeError('Invalid Date & Time')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  discount_percentage: yup.number().when('discount_type', {
    is: 'percentage',
    then: yup.number().required('Discount Percentage is required'),
    otherwise: yup.number().nullable(),
  }),
  discount_fixed_amount: yup.number().when('discount_type', {
    is: 'fixed_amount',
    then: yup.number().required('Discount Fixed Amount is required'),
    otherwise: yup.number().nullable(),
  }),
});

export interface PromoModel {
  created_by: number;
  description: string;
  discount_fixed_amount: any;
  discount_percentage: number;
  discount_type: string;
  id: number;
  status: string;
  title: string;
  tags: CompanyTagsModel[];
}

export type PromoInput = {
  title: string;
  start: string;
  end: string;
  description: string;
  discount_type: string;
  discount_percentage: string;
  discount_fixed_amount: string;
  tags: SelectOption[];
};

export interface PromoFieldInput extends FieldInput {
  field_name?: keyof PromoInput;
}

export const promoInitialValues: PromoInput = {
  title: '',
  start: '',
  end: '',
  description: '',
  discount_type: '',
  discount_percentage: '',
  discount_fixed_amount: '',
  tags: [],
};

export const promoFields: EntityFields[] = [
  {
    fieldName: 'service_name',
    displayName: 'Name',
  },
  { fieldName: 'title', displayName: 'Title', type: 'text' },
  { fieldName: 'description', displayName: 'Description', type: 'text' },
  {
    fieldName: 'discount_type',
    displayName: 'Discount Type',
    type: 'select',
    options: [
      { key: 'Percentage', value: 'percentage' },
      { key: 'Fixed Amount', value: 'fixed_amount' },
    ],
  },
  {
    fieldName: 'status',
    displayName: 'status',
  },
  { fieldName: 'promo_period', displayName: 'Promo Period' },

  {
    fieldName: 'discount_percentage',
    displayName: 'Discount Percentage',
    type: 'number',
    hiddenBasedOnOtherField: (values) => values.discount_type !== 'percentage',
  },
  {
    fieldName: 'discount_fixed_amount',
    displayName: 'Discount Fixed Amount',
    type: 'number',
    hiddenBasedOnOtherField: (values) => values.discount_type !== 'fixed_amount',
  },
  { fieldName: 'start', displayName: 'Start Date', type: 'datetime' },
  { fieldName: 'end', displayName: 'End Date', type: 'datetime' },
];
export const promoTableColumns: string[] = [
  'title',
  'status',
  'promo_period',
  'discount_type',
  'discount_percentage',
  'discount_fixed_amount',
];

export const promoFormFields: string[] = [
  'title',
  'description',
  'discount_type',
  'discount_percentage',
  'discount_fixed_amount',
  'start',
  'end',
];
