/* eslint-disable react-hooks/exhaustive-deps */

import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { getAll, sendInvoiceApi } from 'carego-admin/api/carego-billing-invoices';
import { useContext, useEffect } from 'react';

import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CustomGridCell } from 'core/components';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { GridColDef } from '@mui/x-data-grid';
import { formatDate } from 'core/utils';

const CareGoBillingInvoices = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const columns: GridColDef[] = [
    { field: 'invoice_number', headerName: 'Invoice Number', flex: 2 },
    { field: 'company_name', headerName: 'Name', flex: 2 },
    {
      field: 'total_amount',
      headerName: 'Total Amount',
      flex: 2,
      renderCell: (params) => <CustomGridCell>P {params.row.total_amount}</CustomGridCell>,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.due_date)}</CustomGridCell>,
    },
    {
      field: 'created_at',
      headerName: 'Create Date',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.created_at)}</CustomGridCell>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getAll(query);
  };

  const sendInvoice = () => {
    sendInvoiceApi();
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Billing Invoices' }]);
  }, []);

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Billing Invoices',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Billing Invoice',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
        },
        tableAction: {
          rowActions: [
            {
              label: 'Send Invoice',
              action: sendInvoice,
              icon: <ForwardToInboxIcon />,
            },
          ],
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return <PageBuilder content={content} />;
};

export default CareGoBillingInvoices;
