/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { CustomModal, RegularButton } from 'core/components';
import { exportProductLogs, getAllTransactions } from 'company/api/transactions';
import { useContext, useEffect, useState } from 'react';

import CustomFilter from 'core/components/CustomFilter';
import { FacilityContext } from 'core/context/facility.context';
import IosShareIcon from '@mui/icons-material/IosShare';
import ProductInTransaction from './ProductInTransaction';
import ProductOutTransaction from './ProductOutTransaction';
import ProductTransferTransaction from './ProductTransferTransaction';
import ReversalTransaction from './ReversalTransaction';
import { SIZES } from 'theme/constants';
import SearchBar from '../../POS/components/SearchBar';
import StockAdjustmentTransaction from './StockAdjustmentTransaction';
import TransactionContainer from './TransactionContainer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { tokens } from 'theme/theme';

interface Transaction {
  id: string;
  product_id: string;
  product_name: string;
  variant_name: string;
  transaction_date: string;
  type: 'in' | 'out' | 'transfer' | 'adjustment' | 'reversal';
}

type GeneralProductLogsProps = {
  refresh?: boolean;
};

const GeneralProductLogs: React.FC<GeneralProductLogsProps> = ({ refresh }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [openViewAll, setOpenViewAll] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [allTransactions, setAllTransactions] = useState<any[]>([]);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState<string>('');
  const [transactionFilters, setTransactionFilters] = useState<string[]>([]);
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [expandedProduct, setExpandedProduct] = useState(null);
  const [selectedProductLog, setSelectedProductLog] = useState<any>();
  const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleAccordionToggle = (product_id: any) => {
    setExpandedProduct(expandedProduct === product_id ? null : product_id);
  };

  const fetchAllTransactions = () => {
    if (facility) {
      const start_date = filterDateRange[0];
      const end_date = filterDateRange[1];

      getAllTransactions(facility.id, {
        length: 1000,
        search: transactionSearchQuery,
        start_date,
        end_date,
        type: transactionFilters.length ? transactionFilters : undefined,
      }).then((res) => {
        const transactions = res.data.data;
        setTransactions(transactions);
      });
    }
  };

  const fetchProductTransactions = () => {
    if (selectedProductLog && selectedProductLog.product_id && transactions) {
      const filteredTransactions = transactions.filter(
        (transaction) => transaction.product_id === selectedProductLog.product_id
      );
      setAllTransactions(filteredTransactions);
    }
  };

  const groupedTransactions = transactions.reduce<Record<string, Transaction[]>>((acc, transaction) => {
    const { product_id } = transaction;
    if (!acc[product_id]) {
      acc[product_id] = [];
    }
    acc[product_id].push(transaction);
    return acc;
  }, {});

  const handleExport = () => {
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];

    return exportProductLogs(facility.id, {
      length: openViewAll ? 1000 : 30,
      search: transactionSearchQuery,
      start_date,
      end_date,
      type: transactionFilters.length ? transactionFilters : undefined,
    });
  };

  const handleSearch = (searchKey: string) => {
    setSearchQuery(searchKey);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredTransactions([]);
    } else {
      const newFilteredTransactions: Transaction[] = [];
      Object.entries(groupedTransactions).forEach(([productId, productTransactions]) => {
        const matchedTransactions = productTransactions.filter((transaction: Transaction) => {
          const { product_name, variant_name } = transaction;
          return (
            product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            variant_name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
        newFilteredTransactions.push(...matchedTransactions);
      });
      setFilteredTransactions(newFilteredTransactions);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (refresh) {
      fetchAllTransactions();
    }
  }, [refresh]);

  useEffect(fetchAllTransactions, [facility, transactionSearchQuery, filterDateRange, transactionFilters]);

  useEffect(() => {
    if (openViewAll && facility && selectedProductLog) {
      fetchProductTransactions();
    }
  }, [openViewAll, facility, selectedProductLog, transactions]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: SIZES.paddingS,
          width: '350px',
          position: 'relative',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5" fontWeight="bold">
            General Product Logs
          </Typography>
        </Box>
        <Box display={'flex'} width={'100%'}>
          <SearchBar handleSearch={handleSearch} fullWidth />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={SIZES.paddingS}
          paddingRight={'5px'}
          height="calc(100vh + 72px)"
          sx={{
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          }}
        >
          {searchQuery ? (
            filteredTransactions.length > 0 ? (
              <Box sx={{ background: colors.light_blue_background, borderRadius: '10px', padding: '5px' }}>
                {filteredTransactions.slice(0, 5).map((transaction, index) => {
                  switch (transaction.type) {
                    case 'in':
                      return <ProductInTransaction key={index} transaction={transaction} />;
                    case 'out':
                      return <ProductOutTransaction key={index} transaction={transaction} />;
                    case 'transfer':
                      return <ProductTransferTransaction key={index} transaction={transaction} />;
                    case 'adjustment':
                      return <StockAdjustmentTransaction key={index} transaction={transaction} />;
                    case 'reversal':
                      return <ReversalTransaction key={index} transaction={transaction} />;
                    default:
                      return null;
                  }
                })}
              </Box>
            ) : (
              <Typography variant="h6" color="textSecondary" align="center">
                No transactions found for the search query.
              </Typography>
            )
          ) : (
            <Box>
              {Object.entries(groupedTransactions).map(([productId, productTransactions]) => {
                const { product_id, product_name, variant_name, transaction_date } = productTransactions[0];
                const firstFiveTransactions = productTransactions.slice(0, 5);
                return (
                  <TransactionContainer
                    key={productId}
                    transaction={{ product_id, product_name, variant_name, transaction_date }}
                    expanded={expandedProduct === productId}
                    onChange={() => handleAccordionToggle(productId)}
                  >
                    {expandedProduct === productId && (
                      <Box display="flex" flexDirection="column" gap="10px" mb="10px">
                        {firstFiveTransactions.map((transaction, index) => {
                          switch (transaction.type) {
                            case 'in':
                              return <ProductInTransaction key={index} transaction={transaction} />;
                            case 'out':
                              return <ProductOutTransaction key={index} transaction={transaction} />;
                            case 'transfer':
                              return <ProductTransferTransaction key={index} transaction={transaction} />;
                            case 'adjustment':
                              return <StockAdjustmentTransaction key={index} transaction={transaction} />;
                            case 'reversal':
                              return <ReversalTransaction key={index} transaction={transaction} />;
                            default:
                              return null;
                          }
                        })}
                      </Box>
                    )}
                    <RegularButton
                      fullWidth
                      label="View All Logs"
                      onClick={() => {
                        setTransactionSearchQuery('');
                        setOpenViewAll(true);
                        setSelectedProductLog({ product_id, product_name });
                      }}
                      variant="outlined"
                      size="small"
                      startIcon={<VisibilityOutlinedIcon />}
                    />
                  </TransactionContainer>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      <CustomModal
        open={openViewAll}
        setOpen={setOpenViewAll}
        width={1000}
        header={selectedProductLog ? selectedProductLog.product_name + ' Logs (All)' : 'Logs (All)'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            minHeight: '300px',
          }}
        >
          <Box display={'flex'} gap="5px" justifyContent={'space-between'}>
            <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
            <RegularButton variant="outlined" startIcon={<IosShareIcon />} onClick={handleExport} label="Export" />
            <SearchBar handleSearch={(searchKey: string) => setTransactionSearchQuery(searchKey)} />
            <CustomFilter
              optionList={[
                { label: 'Product In', id: 'in' },
                { label: 'Product Out', id: 'out' },
                { label: 'Product Transfer', id: 'transfer' },
                { label: 'Stock Adjustment', id: 'adjustment' },
                { label: 'Reversal', id: 'reversal' },
              ]}
              selected={transactionFilters}
              setSelected={setTransactionFilters}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={SIZES.paddingS}
            paddingRight={'5px'}
            // height="calc(100vh + 72px)"
            // sx={{ overflowY: 'auto' }}
          >
            {allTransactions.map((transaction, index) => {
              switch (transaction.type) {
                case 'in':
                  return <ProductInTransaction key={index} transaction={transaction} viewAll />;
                case 'out':
                  return <ProductOutTransaction key={index} transaction={transaction} viewAll />;
                case 'transfer':
                  return <ProductTransferTransaction key={index} transaction={transaction} viewAll />;
                case 'adjustment':
                  return <StockAdjustmentTransaction key={index} transaction={transaction} viewAll />;
                case 'reversal':
                  return <ReversalTransaction key={index} transaction={transaction} />;
                default:
                  return null;
              }
            })}
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default GeneralProductLogs;
