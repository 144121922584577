import { EntityFields } from 'company/entities/utils';

export const USER_GROUP_ENTITY_NAME = 'User Groups';

export interface UserGroupModel {
  id?: number;
  group_name: string;
  description: string;
  company_id?: number;
  is_active: number;
}

export const UserGroupFields: EntityFields[] = [
  { fieldName: 'group_name', displayName: 'Group Name' },
  { fieldName: 'description', displayName: 'Description' },
];

export const UserGroupFormFields = ['group_name', 'description'];
export const UserGroupInitialValues = {
  group_name: '',
  description: '',
};
