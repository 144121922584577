import axios from 'core/utils/custom_axios';

export const getRegions = () => {
  return axios.get(`https://ph-locations-api.buonzz.com/v1/regions`);
};

export const getProvinces = () => {
  return axios.get(`https://ph-locations-api.buonzz.com/v1/provinces`);
};

export const getCities = () => {
  return axios.get(`https://ph-locations-api.buonzz.com/v1/cities`);
};
