import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Health2: React.FC = () => {
  return (
    <Box>
      <Step>1. Go to the “Clinic Management” tab in sidebar </Step>
      <Step>2. Select “Health Sessions" tab inside the Clinic Management</Step>
      <Step>3. Click the Health Session you want to add a stage</Step>
      <Image src="/assets/FaqAssets/healthpackage/2/1-3.png" alt="Step 1-2 Image" />

      <Step>4. Click the “Add Stage” button </Step>
      <Image src="/assets/FaqAssets/healthpackage/2/4.png" alt="Image" />
      <Step>5. Fill out the form </Step>
      <Step>6. Click the “Submit” button</Step>
      <Image src="/assets/FaqAssets/healthpackage/2/5-6.png" alt="Image" />
    </Box>
  );
};

export default Health2;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
