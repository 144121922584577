/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';

import {
  BOOKING_ENTITY_NAME,
  bookingFields,
  bookingFormFields,
  bookingInitialValues,
  BookingInput,
  createBookingSchema,
  modifyBookingField,
} from './BookingModel';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { createBooking } from 'company/api/bookings';

const today = new Date();

type Props = {
  facility: FacilityModel;
  booking?: any;
  providedService?: boolean;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const BookingForm = (props: Props) => {
  const { facility, booking, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    return createBooking(facility.id, data);
  };

  const providedServiceIdBookingInitialValues: BookingInput = {
    service_id: [booking],
    first_name: '',
    middle_name: '',
    last_name: '',
    birthday: '',
    email: '',
    mobile_number: '',
    schedule: '',
    sex: '',
  };

  const modifiedBookingFields = booking ? modifyBookingField('service_id', { disabled: true }) : bookingFields;

  return (
    <TemplateForm
      entityName={BOOKING_ENTITY_NAME}
      fields={booking ? modifiedBookingFields : bookingFields}
      visibleFields={bookingFormFields}
      entity={booking}
      initialValues={booking ? providedServiceIdBookingInitialValues : bookingInitialValues}
      schema={createBookingSchema}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default BookingForm;
