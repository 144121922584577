import { AssessmentQuery } from 'company/screens/HealthAssessments';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-assessments';

export const getHealthAssessment = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getHealthAssessments = (facility_id: number, query?: AssessmentQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const createHealthAssessment = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const updateHealthAssessment = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const updateHealthAssessmentStatus = (facility_id: number, id: number, status: string) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}/status`, { status });
};
