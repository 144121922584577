import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const CampaignArticle2: React.FC = () => {
  return (
    <Box>
      <Step>You can only unenroll patients from draft campaigns.</Step>
      <Step>1. In the Action column, click the icon button and then click “Remove”, then confirm.</Step>
      <Image src="/assets/FaqAssets/2.1.png" alt="Step 8 Image" />
    </Box>
  );
};

export default CampaignArticle2;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
