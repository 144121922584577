/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { formatArray } from 'core/utils';
import { getFacilityBookingDetails } from 'company/api/facility';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

type ConfirmationStageProps = {
  number: string;
  formData: any;
  serviceBanner: any;
  address: string;
  facName: string;
  selectedService: any;
  handleConfirmInfos: () => void;
  handleBack: () => void;
  birthday: string;
  sexAssignedAtBirth: string;
  mobileNumber: string;
  email: string;
  personalAddress: string;
  isLoading?: boolean;
};

const ConfirmationStage: React.FC<ConfirmationStageProps> = ({
  formData,

  handleConfirmInfos,
  handleBack,
  birthday,
  sexAssignedAtBirth,
  mobileNumber,
  email,
  personalAddress,
  isLoading,
}) => {
  const { facility_code } = useParams();

  const [clinicEmail, setEmail] = useState('');

  const [smartMobileNumber, setSmartMobileNumber] = useState('');
  const [globeMobileNumber, setGlobeMobileNumber] = useState('');

  const getClinicDetails = async () => {
    if (!facility_code) return;
    try {
      const { data } = await getFacilityBookingDetails(facility_code);

      setEmail(data.email);
      setSmartMobileNumber(data.smart_mobile_number);
      setGlobeMobileNumber(data.globe_mobile_number);
    } catch (error) {}
  };

  useEffect(() => {
    getClinicDetails();
  }, [facility_code]);

  return (
    <Contain>
      <Typography
        sx={{
          padding: '10px 15px 10px 15px',
          fontSize: '12px',
          '@media (min-width: 768px)': {
            fontSize: '17px',
          },
        }}
      >
        Thank you for choosing our clinic for your healthcare needs. Below are the details of your booking:
      </Typography>
      <ConfirmationWrapper>
        <DateTime>
          <Typography
            sx={{
              fontFamily: 'Nunito',
              fontWeight: 'Bold',
              fontSize: '12px;',
              '@media (min-width: 768px)': {
                fontSize: '17px',
                padding: '5px 0px',
              },
            }}
          >
            Date & Time:
          </Typography>
          <Typography>{formData.selectedDate?.format('dddd, MMMM D, YYYY')}</Typography>
          <Typography> {formData.selectedTime}</Typography>
        </DateTime>

        <DateTime>
          <Typography
            sx={{
              fontFamily: 'Nunito',
              fontWeight: 'Bold',
              fontSize: '12px;',
              '@media (min-width: 768px)': {
                fontSize: '17px',
                padding: '5px 0px',
              },
            }}
          >
            Patient Details
          </Typography>
          <Box display="grid" gridTemplateColumns="150px 1fr" rowGap="5px" mt="10px">
            <Typography fontWeight="bold">Name</Typography>
            <Typography>
              {formData.firstName} {formData.lastName}
            </Typography>

            <Typography fontWeight="bold">Birthday</Typography>
            <Typography>{birthday}</Typography>

            <Typography fontWeight="bold">Sex Assigned at Birth</Typography>
            <Typography>{sexAssignedAtBirth}</Typography>

            <Typography fontWeight="bold">Mobile Number</Typography>
            <Typography>{mobileNumber}</Typography>

            <Typography fontWeight="bold">Email</Typography>
            <Typography>{email}</Typography>

            <Typography fontWeight="bold">Address</Typography>
            <Typography>{personalAddress}</Typography>
          </Box>
        </DateTime>
      </ConfirmationWrapper>
      <Typography
        sx={{
          padding: '10px 15px 20px 15px',
          fontSize: '12px',
          '@media (min-width: 768px)': {
            fontSize: '17px',
            padding: '20px 15px 30px 15px',

            maxWidth: '800px',
          },
        }}
      >
        After clicking the <b>‘Book Appointment'</b> button, our staff will contact you within 24-48 hours <br />
        to confirm your appointment. If you have any questions or concerns,
        <br />
        please feel free to reach out to us at {formatArray([smartMobileNumber, globeMobileNumber], ' / ')}
        {clinicEmail ? ' or via email at ' + clinicEmail : ''}.
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        sx={{
          '@media (min-width: 768px)': { alignItems: 'center' },
        }}
      >
        <Button
          type="submit"
          variant="contained"
          onClick={handleConfirmInfos}
          sx={{
            backgroundColor: '#EF7A35',
            height: '40px',
            textTransform: 'none',
            width: '100%',
            margin: '0 0 15px 0',
            borderRadius: '50px',
            fontFamily: 'Nunito',
            color: 'White',
            fontWeight: 'bold',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: '#c05b20',
            },
            '@media (min-width: 768px)': {
              width: '60%',
            },
          }}
        >
          {isLoading ? <CircularProgress color="inherit" size="1.3rem" /> : 'Book Appointment'}
        </Button>

        <Button
          type="button"
          variant="contained"
          sx={{
            backgroundColor: 'white',
            border: '1px solid #0768B0 ',
            height: '40px',
            textTransform: 'none',
            width: '100%',
            margin: '0 0 50px 0',
            borderRadius: '50px',
            fontFamily: 'Nunito',
            color: '#0768B0',
            fontWeight: 'bold',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: '#0768B0',
              color: 'white',
            },
            '@media (min-width: 768px)': {
              width: '60%',
            },
          }}
          onClick={handleBack}
        >
          Review Details
        </Button>
      </Box>
    </Contain>
  );
};

export default ConfirmationStage;

const Contain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ConfirmationWrapper = styled.div`
  margin-top: 15px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    width: 320px;
    margin: 15px 0;
    width: 90%;
    padding: 25px;
  }
  @media (min-width: 768px) {
    width: 60%;
    margin: 15px 0;

    padding: 30px;
  }
`;

const DateTime = styled.div`
  font-family: Nunito;
`;
