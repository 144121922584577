import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { displayAttribute, formatCurrency, formatNumber } from 'core/utils';

import Chip from '@mui/material/Chip';
import { ContainerColumn } from 'core/components/containers';
import { Labels } from '../styles/styles';
import { Link } from 'react-router-dom';
import { OrderProps } from '..';
import { RegularButton } from 'core/components';
import { SIZES } from 'theme/constants';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { tokens } from 'theme/theme';
import { useMemo } from 'react';

export interface LineItem {
  id: number;
  product_id: number;
  variant_price: number;
  price?: number;
  quantity: number;
  product_name: string;
  variant_name: string;
  quantity_in_stock: number;
  made_to_order?: boolean;
  service_name: string;
  attributes: string;
  // Add new service package fields
  services?: Array<{
    id: number;
    service_name: string;
    price: number;
  }>;
  package_name?: string;
  tax?: string;
  type?: 'product' | 'service' | 'service-package';
}

type Props = {
  item: LineItem;
  addToCart: (item: any) => void;
  type: 'product' | 'service' | 'service-package';
  viewMode: 'grid' | 'list';
  orders: OrderProps[];
};

const POSLineItem: React.FC<Props> = ({ item, addToCart, type, viewMode, orders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const itemAttributes = useMemo(() => {
    return displayAttribute(item.attributes);
  }, [item.attributes]);

  const itemsInCart = useMemo(
    () =>
      type === 'product'
        ? orders
            .filter((order) => order.item_id === item.id && order.item_type === 'product')
            .reduce((result, order) => result + order.quantity, 0)
        : 0,
    [item.id, orders, type]
  );

  const MadeToOrderTag = () => {
    if (type !== 'product') return null;
    return item.made_to_order ? (
      <Chip label="Made to Order" variant="outlined" color="primary" sx={{ fontSize: '9px' }} size="small" />
    ) : null;
  };

  const ServicePackageTag = () => {
    return type === 'service-package' ? (
      <Chip
        // icon={<LocalOfferIcon sx={{ fontSize: '14px' }} />}
        label="Package"
        variant="outlined"
        color="secondary"
        sx={{ fontSize: '9px' }}
        size="small"
      />
    ) : null;
  };

  const AddToCartButton = () => {
    const gridView = viewMode === 'grid';

    return (
      <RegularButton
        startIcon={<ShoppingCartOutlinedIcon sx={{ marginRight: gridView ? undefined : '-12px' }} />}
        size="small"
        label={gridView ? 'Add to Cart' : ''}
        onClick={() =>
          addToCart({
            ...item,
            type,
            unit_price: type === 'product' ? item.variant_price : item.price,
            tax: item.tax,
          })
        }
        styles={{ padding: '6px 6px' }}
        disabled={type === 'product' && item.quantity_in_stock - itemsInCart <= 0 && !item.made_to_order}
        fullWidth={gridView}
      />
    );
  };

  const ItemLabel = () => {
    const displayName =
      type === 'service-package' ? item.package_name : type === 'service' ? item.service_name : item.product_name;

    const label = (
      <Labels color={colors.text} sx={{ '&:hover': { color: colors.accent } }}>
        {displayName}
      </Labels>
    );

    if (type === 'product') {
      return (
        <Link
          to={`/company/inventory/products/variant?id=${item.id}`}
          style={{ textDecoration: 'none', fontWeight: 700 }}
          state={{ from: '/company/inventory/pos' }}
        >
          <Tooltip title={displayName}>{label}</Tooltip>
        </Link>
      );
    }

    return <Tooltip title={displayName}>{label}</Tooltip>;
  };

  const ServicePackageDetails = () => {
    if (type !== 'service-package' || !item.services) return null;

    return (
      <Box sx={{ mt: 1 }}>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 0.5 }}>
          Includes:
        </Typography>
        {item.services.map((service) => (
          <Typography
            key={service.id}
            variant="caption"
            sx={{
              display: 'block',
              ml: 1,
              color: colors.text,
              fontSize: '11px',
              lineHeight: '16px',
            }}
          >
            • {service.service_name}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
      }}
    >
      {viewMode === 'grid' ? (
        <Box
          justifyContent={'space-between'}
          sx={{
            backgroundColor: colors.light_blue_background_2,
            padding: '15px',
            width: '100%',
            borderRadius: '20px',
            height: '100%',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ItemLabel />
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                {/* <ServicePackageTag /> */}
                <MadeToOrderTag />
              </Box>
            </Box>
            <Typography fontWeight={600} fontSize={'14px'} color={colors.accent}>
              {formatCurrency(type === 'product' ? item.variant_price : item.price)}
            </Typography>
          </Box>

          <Box sx={{ height: type === 'product' ? '80px' : '50px' }} />

          <ContainerColumn gap="5px" sx={{ position: 'absolute', bottom: 0, left: 0, padding: '15px', width: '100%' }}>
            {type === 'product' && (
              <Box>
                {itemAttributes}
                {!item.made_to_order && (
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    color="#888"
                    lineHeight={1}
                    fontSize={'14px'}
                    marginTop="3px"
                  >
                    Available Qty: {formatNumber(item.quantity_in_stock - itemsInCart, 0)}
                  </Typography>
                )}
              </Box>
            )}
            {type === 'service-package' && <ServicePackageDetails />}
            <AddToCartButton />
          </ContainerColumn>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={SIZES.paddingS}
          sx={{
            backgroundColor: colors.light_blue_background_2,
            padding: '10px 20px',
            width: '100%',
            borderRadius: '12px',
          }}
          width="100%"
        >
          <Box width="250px">
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 0.5 }}>
              <ItemLabel />
              <ServicePackageTag />
              <MadeToOrderTag />
            </Box>
            {type === 'product' && !item.made_to_order && (
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color="#888"
                lineHeight={1}
                fontSize={'14px'}
                marginTop="3px"
              >
                Available Qty: {formatNumber(item.quantity_in_stock - itemsInCart, 0)}
              </Typography>
            )}
            {type === 'service-package' && <ServicePackageDetails />}
          </Box>

          <Box width="100px">
            <Typography fontWeight={600} fontSize={'14px'} color={colors.accent} textAlign="right">
              {formatCurrency(type === 'product' ? item.variant_price : item.price)}
            </Typography>
          </Box>

          <Box width="200px">{type === 'product' ? itemAttributes : null}</Box>

          <AddToCartButton />
        </Box>
      )}
    </Box>
  );
};

export default POSLineItem;
