import { ApiQuery } from 'core/model/interface';
import { PatientModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';
import dayjs from 'dayjs';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patients';

export const getPatients = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getPatientsEnrolledInSchedule = (schedule_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/schedule/${schedule_id}`, {
    params: query,
  });
};

export const getPatientsEnrolledInProgramReminders = (program_code: string, query: ApiQuery, stage?: string) => {
  return axios.get(`${baseURL}/${path}/program/${program_code}`, {
    params: query,
  });
};

export const getPatient = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createPatient = (patient: PatientModel) => {
  return axios.post(`${baseURL}/${path}`, patient);
};

export const updatePatient = (id: number, patient: PatientModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, patient);
};

export const deletePatient = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getPatientsNotEnrolledInSchedule = (schedule_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/schedule/not-enrolled/${schedule_id}`, {
    params: query,
  });
};

export const getPatientsNotEnrolledInProgramReminders = (program_category_code: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program/not-enrolled/${program_category_code}`, { params: query });
};

export const importPatients = (file: any) => {
  return axios.post(
    `${baseURL}/${path}/import`,
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const exportPatients = (query: ApiQuery, lgu_name?: string) => {
  return axios
    .post(
      `${baseURL}/${path}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const date = dayjs();
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', '[' + lgu_name + '] - LGU Patients [' + date.format('YYYY-MM-DD') + '].xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const exportPatientsEnrolledInProgramReminder = (program_code: string, query: ApiQuery, lgu_name?: string) => {
  return axios
    .post(
      `${baseURL}/${path}/export/program/${program_code}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const date = dayjs();
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute(
        'download',
        '[' + lgu_name + '] ' + program_code.toUpperCase() + ' Patients [' + date.format('YYYY-MM-DD') + '].xlsx'
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const exportPatientsEnrolledInTbAcf = (query: ApiQuery, lgu_name?: string) => {
  return axios
    .post(
      `${baseURL}/${path}/export/tb-acf`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const date = dayjs();
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', '[' + lgu_name + '] - TB-ACF Patients [' + date.format('YYYY-MM-DD') + '].xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
