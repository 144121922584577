import { Box, Button, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomTable, RegularButton } from 'core/components';
import { deleteBarangayAssignment, getAllBarangayHealthworkers, getBarangayAssignment } from 'lgu/api/health-workers';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { BarangayHealthworkerModel } from 'lgu/model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import HealthWorkersModal from './HealthworkersModal';
import HealthworkerAssignment from './HealthworkerAssignment';
import MultipleAssign from './MultipleAssign';
import { changeNullToBlank } from 'core/utils';
import { getBarangays } from 'lgu/api/barangay';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const HealthWorkers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [assignedHwRows, setAssignedHwRows] = useState<any[]>([]);
  const [assignedHwRowCount, setAssignedHwRowCount] = useState<number>(0);
  const [loadingHw, setLoadingHw] = useState<boolean>(false);

  const [openViewHealthworkers, setOpenViewHealthworkers] = useState<boolean>(false);
  const [barangays, setBarangays] = useState<any[]>([]);
  const [healthworker, setHealthworker] = useState<BarangayHealthworkerModel>();
  const [openHealthworkerAssignmentModal, setOpenHealthworkerAssignmentModal] = useState<boolean>(false);
  const [refreshAssignedHealthworkers, setRefreshAssignedHealthworkers] = useState(0);
  const [healthWorkers, setHealthWorkers] = useState<any[]>([]);
  const [openConfirmationRemoveBHW, setOpenConfirmationRemoveBHW] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  // multiple assignment
  const [openMultipleAssign, setOpenMultipleAssign] = useState<boolean>(false);

  const assignedHealthworkersColumns: GridColDef[] = [
    { field: 'barangay_name', headerName: 'Barangay', flex: 1.5 },
    { field: 'full_name', headerName: 'Name', flex: 1 },
    { field: 'mobile_number', headerName: 'Mobile Number', type: 'Date', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setHealthworker(changeNullToBlank(params.row));
              setOpenHealthworkerAssignmentModal(true);
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setHealthworker(params.row);
              setAlertContent('Are you sure you want to remove BHW from Barangay ' + params.row.barangay_name + '?');
              setOpenConfirmationRemoveBHW(true);
            }}
          >
            Remove
          </Button>
        </Box>
      ),
    },
  ];

  const getAssignedHealthworkers = (query: ApiQuery) => {
    setLoadingHw(true);
    getBarangayAssignment(query)
      .then((res) => {
        setAssignedHwRows(res.data.data);
        setAssignedHwRowCount(res.data.meta.total);
        setLoadingHw(false);
      })
      .catch(() => setLoadingHw(false));
  };

  const getBarangayList = () => {
    getBarangays()
      .then((res) => {
        setBarangays(res.data);
      })
      .catch();
  };

  const getHealthworkers = () => {
    getAllBarangayHealthworkers()
      .then((res) => {
        setHealthWorkers(res.data.data);
      })
      .catch();
  };

  const refreshAssignedTable = () => {
    setRefreshAssignedHealthworkers((prev: number) => prev + 1);
  };

  const handleDelete = () => {
    if (healthworker) {
      deleteBarangayAssignment(healthworker.barangay_code)
        .then(() => {
          enqueueSnackbar('Barangay Health Worker successfully removed!', { variant: 'success' });
          refreshAssignedTable();
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (!openHealthworkerAssignmentModal) setHealthworker(undefined);
  }, [openHealthworkerAssignmentModal]);

  useEffect(() => {
    getBarangayList();
    getHealthworkers();
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4" component="h3" color={colors.text} fontWeight="bold">
          Barangay Health Worker Management
        </Typography>

        <Box display="flex" gap={2}>
          <RegularButton
            label="Multiple Assign"
            onClick={() => {
              getHealthworkers();
              setOpenMultipleAssign(true);
            }}
          />
          <RegularButton
            label="View BHWs"
            onClick={() => {
              setOpenViewHealthworkers(true);
            }}
          />
        </Box>
      </Box>

      <CustomTable
        columns={assignedHealthworkersColumns}
        rows={assignedHwRows}
        rowCount={assignedHwRowCount}
        loading={loadingHw}
        rowId={'barangay_code'}
        getData={getAssignedHealthworkers}
        forceRefresh={refreshAssignedHealthworkers}
        rowHeight={45}
      />

      <HealthWorkersModal
        open={openViewHealthworkers}
        setOpen={setOpenViewHealthworkers}
        barangays={barangays}
        setRefreshAssignedTable={setRefreshAssignedHealthworkers}
      />

      {healthworker && (
        <HealthworkerAssignment
          barangays={assignedHwRows}
          healthworker={healthworker}
          selectedBarangay={healthworker ? [healthworker.barangay_code] : []}
          open={openHealthworkerAssignmentModal}
          setOpen={setOpenHealthworkerAssignmentModal}
          refreshTable={refreshAssignedTable}
        />
      )}

      <MultipleAssign
        open={openMultipleAssign}
        setRefreshAssigned={refreshAssignedTable}
        setOpen={setOpenMultipleAssign}
        barangays={barangays}
        healthworkers={healthWorkers}
      />

      <ConfirmationDialog
        open={openConfirmationRemoveBHW}
        setOpen={setOpenConfirmationRemoveBHW}
        content={alertContent}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default HealthWorkers;
