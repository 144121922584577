/* eslint-disable react-hooks/exhaustive-deps */

import {
  ENTITIY_NAME,
  contactFields,
  contactInitialValues,
  patientEmergencyContactFormFields,
  schema,
} from './PatientEmergencyContactModel';
import { createEmergencyContact, updateEmergencyContact } from 'company/api/patient-emergency-contact';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  facility: FacilityModel;
  patientEmergencyContact?: any;
  patient_id: number;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PatientEmergencyContactForm = (props: Props) => {
  const { facility, patient_id, patientEmergencyContact, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = (data: any) => {
    return patientEmergencyContact ? updateEmergencyContact(facility.id, patientEmergencyContact.id, data) : createEmergencyContact(facility.id, patient_id, data);
  };


  return (
    <TemplateForm
      entityName={ENTITIY_NAME}
      fields={contactFields}
      visibleFields={patientEmergencyContactFormFields}
      initialValues={patientEmergencyContact ? changeNullToBlank(patientEmergencyContact) : contactInitialValues}
      schema={schema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default PatientEmergencyContactForm;
