import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AppoinArticle6: React.FC = () => {
  return (
    <Box>
      <Step>1. In the appointments tab, select a patient and click the drop down icon.</Step>
      <Image src="/assets/FaqAssets/Appointment/6/1.png" alt="Step 1 Image" />
      <Step>2. Select either “Send result notification” or “Send follow-up reminder”.</Step>
      <Image src="/assets/FaqAssets/Appointment/6/2.png" alt="Step 2 Image" />

      <Step>3. Check if the “Sms Preview” informations are correct, and click “Submit” button.</Step>
      <Image src="/assets/FaqAssets/Appointment/6/3.png" alt="Step 3 Image" />
      <Image src="/assets/FaqAssets/Appointment/6/3.1.png" alt="Step 3 Image" />
    </Box>
  );
};

export default AppoinArticle6;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
