import { PasswordModel } from 'lgu/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company';

export const updateCompanyPassword = (id: number, password: PasswordModel) => {
  return axios.patch(`${baseURL}/${path}/update-password/${id}`, password);
};

export const readTerms = (id: number) => {
  return axios.patch(`${baseURL}/${path}/read-terms/${id}`);
};

export const handleOnboardingDone = () => {
  return axios.put(`${baseURL}/${path}/onboarding_done`);
};