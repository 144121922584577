import TemplateForm from 'company/entities/Template/TemplateForm';
import { caregoCreateAuthority, caregoUpdateGroupAuthority } from 'carego-admin/api/carego-user-authorities';
import {
  USER_GROUP_PERMISSION_ENTITY_NAME,
  userGroupAuthorityFields,
  userGroupAuthorityInitialValues,
  userGroupAuthorityVisibleFields,
} from './UserGroupAuthorityModel';

type Props = {
  selectedGroup?: any;
  callbackAfterSubmit?: () => void;
};

const UserGroupAuthorityForm: React.FC<Props> = (props) => {
  const { selectedGroup, callbackAfterSubmit } = props;

  const handleSubmitApiFunction = (data: any) => {
    if (selectedGroup && selectedGroup.type === 'update') {
      return caregoUpdateGroupAuthority(data);
    }

    return caregoCreateAuthority(data);
  };

  return (
    <TemplateForm
      entityName={USER_GROUP_PERMISSION_ENTITY_NAME}
      fields={userGroupAuthorityFields}
      visibleFields={userGroupAuthorityVisibleFields}
      formSubmitApiFunction={handleSubmitApiFunction}
      callbackAfterSubmit={callbackAfterSubmit}
      initialValues={
        selectedGroup ? { ...userGroupAuthorityInitialValues, ...selectedGroup } : userGroupAuthorityInitialValues
      }
    />
  );
};

export default UserGroupAuthorityForm;
