import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { formatNumber, rotateArray } from 'core/utils';
import { useEffect, useMemo, useState } from 'react';

import { BarChart } from '@mui/x-charts/BarChart';
import { chart_colors } from 'company/screens/PatientRecords/components/ChartEditor';
import { tokens } from 'theme/theme';

interface Props {
  processData: (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => void;
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  colSpan?: number;
}

const DashboardBarChart: React.FC<Props> = ({ title, xAxisLabel, yAxisLabel, processData, colSpan = 6 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chartDataKeys, setChartDataKeys] = useState<string[]>([]);
  const [chartDataValues, setChartDataValues] = useState<number[]>([]);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const chartColors = useMemo(() => rotateArray(chart_colors), []);

  useEffect(() => {
    setTimeout(() => {
      processData(setChartDataKeys, setChartDataValues);
    }, 2000);
  }, [processData]);

  if (!chartDataValues) {
    return (
      <Skeleton
        variant="rounded"
        width={'100%'}
        height={'400px'}
        sx={{ gridColumn: isMobilePhone ? 'span 12' : 'span ' + colSpan, borderRadius: '8px' }}
        animation="wave"
      />
    );
  }

  return (
    <Box
      sx={{
        gridColumn: isMobilePhone ? 'span 12' : 'span ' + colSpan,
        border: '1px solid ' + colors.border,
        borderRadius: '8px',
        minHeight: '400px',
        padding: '12px 24px',
        '& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
          transform: 'translateX(-25px) !important',
        },
        '& .MuiChartsAxis-directionX .MuiChartsAxis-label': {
          transform: 'translateY(15px) !important',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            border: 'none',
            borderBottom: '1px solid #CCCCCC',
            padding: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '30px',
          }}
        >
          <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
            <Typography variant="h3" fontWeight="700">
              {title}
            </Typography>
          </Box>
        </Box>

        <BarChart
          colors={chartColors}
          grid={{ horizontal: true }}
          series={[
            {
              data: chartDataValues,
              highlightScope: { faded: 'global', highlighted: 'item' },
            },
          ]}
          height={290}
          borderRadius={15}
          barLabel={(item) => formatNumber(item.value + '', 0)}
          xAxis={[
            {
              label: xAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '16px' },
              data: chartDataKeys,
              scaleType: 'band',
              colorMap: {
                type: 'ordinal',
                colors: chart_colors,
              },

              tickPlacement: 'middle',
              tickLabelPlacement: 'middle',
              tickSize: 10,
              tickLabelStyle: {
                fontFamily: 'Poppins',
                width: '50px',
                //   textOverflow: 'ellipsis',
                //   whiteSpace: 'nowrap',
                //   overflow: 'hidden',
              },
              // valueFormatter: (value, context) => {
              //   return value.length > 15 ? value.substring(0, 15) + '...' : value;
              // },
            },
          ]}
          yAxis={[
            {
              label: yAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '15px' },
              tickMinStep: 1,
            },
          ]}
          margin={{ top: 10, bottom: 60, left: 70, right: 20 }}
        />
      </Box>
    </Box>
  );
};

export default DashboardBarChart;
