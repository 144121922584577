import React, { useMemo } from 'react';

import { ColoredTable } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material';

interface StatTableProps {
  rows: any[];
}

const StatTable: React.FC<StatTableProps> = ({ rows }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const totals = useMemo(() => {
    return rows.reduce(
      (acc, curr) => {
        acc.actual += curr.actual;
        acc.scheduled += curr.scheduled;
        acc.cancellations += curr.cancellations;
        acc.noShow += curr.noShow;
        return acc;
      },
      {
        day: 'Total',
        actual: 0,
        scheduled: 0,
        cancellations: 0,
        noShow: 0,
        rowColor: colors.light_blue_background_2,
        bold: true,
      }
    );
  }, [colors.light_blue_background_2, rows]);

  const columns: GridColDef[] = [
    {
      field: 'day',
      headerName: 'Day',
      sortable: false,
      flex: 2,
    },
    {
      field: 'actual',
      headerName: 'Attended',
      sortable: false,
      flex: 1,
    },
    {
      field: 'scheduled',
      headerName: 'Scheduled',
      sortable: false,
      flex: 1,
    },
    {
      field: 'cancellations',
      headerName: 'Cancellations',
      sortable: false,
      flex: 1,
    },
    {
      field: 'noShow',
      headerName: 'No Show',
      sortable: false,
      flex: 1,
    },
  ];

  return <ColoredTable columns={columns} dataRows={[...rows, totals]} rowHeight={50} />;
};

export default StatTable;
