export const user_group_all = [
  {
    value: 'RMTL',
    key: 'Regional Monitoring Team Leader',
  },
  {
    value: 'RMTM',
    key: 'Regional Monitoring Team Member',
  },
  {
    value: 'CPAL',
    key: 'City/Provincial Assessment Team Leader',
  },
  {
    value: 'CPAM',
    key: 'City/Provincial Assessment Team Member',
  },
  {
    value: 'FA',
    key: 'Field Assessors',
  },
  {
    value: 'MHO/PHN',
    key: 'MHO/PHN',
  },
  {
    value: 'CHD',
    key: 'Regional Acount (Dashboard View only)',
  },
  {
    value: 'National',
    key: 'National Acount (Dashboard View only)',
  },
];

export const user_group_provincial = [
  {
    value: 'CPAM',
    key: 'City/Provincial Assessment Team Member',
  },
  {
    value: 'FA',
    key: 'Field Assessors',
  },
  {
    value: 'MHO/PHN',
    key: 'MHO/PHN',
  },
];

export const facility_types = [
  {
    value: '1',
    key: 'Infirmary',
  },
  {
    value: '2',
    key: 'Safe Birthing Facility',
  },
  {
    value: '3',
    key: 'Rural Health Unit',
  },
  {
    value: '4',
    key: 'Barangay Health Station',
  },
  {
    value: '5',
    key: 'Nutrition or Health Outpost',
  },
  {
    value: '6',
    key: 'Auxiliary Facility',
  },
];

export const managing_authority = [
  {
    value: 'Department of Health',
    key: 'Department of Health',
  },
  {
    value: 'Provincial Government',
    key: 'Provincial Government',
  },
  {
    value: 'Municipal Government',
    key: 'Municipal Government',
  },
  {
    value: 'Others',
    key: 'Others',
  },
];

export const clone_type = [
  {
    value: 'all',
    key: 'All Enrolled Patients',
  },
  {
    value: 'none',
    key: 'None',
  },
];

export const clone_type_past = [
  {
    value: 'all',
    key: 'All Enrolled Patients',
  },
  {
    value: 1,
    key: 'Present',
  },
  {
    value: 0,
    key: 'Absent',
  },
  {
    value: 'none',
    key: 'None',
  },
];

export const sms_sending_options = [
  {
    key: '1 Week Before',
    value: '1',
  },
  {
    key: '3 Days Before',
    value: '2',
  },
  {
    key: '1 Day Before',
    value: '3',
  },
  {
    key: 'On the Day',
    value: '4',
  },
  {
    key: 'Custom Date',
    value: '5',
  },
];

export const screening_result_options = [
  {
    key: 'Proceed to Sputum Test',
    value: 'Presumptive-Sputum',
  },
  {
    key: 'Proceed to Doctor Appointment',
    value: 'Presumptive-Checkup',
  },
  {
    key: 'Proceed to TB-DOTS Enrollment',
    value: 'Positive',
  },
  {
    key: 'Negative and send result to patient',
    value: 'Negative',
  },
];

export const sputum_result_options = [
  {
    key: 'Proceed to TB-DOTS Enrollment',
    value: 'Positive',
  },
  {
    key: 'Proceed to Doctor Appointment',
    value: 'Negative-Checkup',
  },
  {
    key: 'Negative and send result to patient',
    value: 'Negative',
  },
  {
    key: 'Rifampicin-resistant',
    value: 'Rifampicin-resistant',
  },
];

export const check_up_result_options = [
  {
    key: 'Start TB Treatment',
    value: 'Positive',
  },
  {
    key: 'Do not enroll to TB-DOTS',
    value: 'Negative',
  },
];

export const sms_credits_options = [
  {
    key: '0',
    value: 0,
  },
  {
    key: '2,000',
    value: 2000,
  },
  {
    key: '4,000',
    value: 4000,
  },
  {
    key: '6,000',
    value: 6000,
  },
  {
    key: '8,000',
    value: 8000,
  },
  {
    key: '10,000',
    value: 10000,
  },
];

export const tb_acf_sms_counter = [
  'Screening Details',
  'Screening On The Day',
  'Screening Negative',
  'Sputum Details',
  'Sputum On The Day',
  'Sputum Negative',
  'Check-up Details',
  'Check-up On The Day',
  'Check-up Negative',
  'TB-DOTS Enrollment',
  'TB-DOTS On The Day',
];

export const tb_dots_sputum_sms_counter = [
  '3 Days Before Week 8 Sputum',
  '1 Day Before Week 8 Sputum',
  'Week 8 On The Day',
  '3 Days Before Week 20 Sputum',
  '1 Day Before Week 20 Sputum',
  'Week 20 On The Day',
  '3 Days Before Week 24 Sputum',
  '1 Day Before Week 24 Sputum',
  'Week 24 On The Day',
];

export const tb_acf_stages_filter = ['All', 'Screening', 'Sputum Test', 'Doctor Appointment', 'TB-DOTS Enrollment'];

export const tb_acf_stages = ['Screening', 'Sputum Test', 'Doctor Appointment', 'TB-DOTS Enrollment'];

export const tb_dots_stages = ['All', 'Intensive', 'Week 8', 'Week 20', 'Completed'];
