import { Box, Typography } from '@mui/material';

import React from 'react';
import RichTextEditor from './RichTextEditor';

type CustomRichTextEditorProps = {
  label?: string;
  value?: any;
  fieldName: string;
  span?: number;
  handleChangeCallback?: (value: any) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  height?: string;
  optional?: boolean;
  readOnly?: boolean;
  editFormMode?: boolean;
  autoSave?: () => void;
};

const CustomRichTextEditor: React.FC<CustomRichTextEditorProps> = ({
  label,
  value,
  fieldName,
  setFieldValue,
  span,
  height,
  optional,
  readOnly,
  editFormMode,
  autoSave,
  handleChangeCallback,
}) => {
  const handleChange = (value: any) => {
    setFieldValue && setFieldValue(fieldName, value);
    handleChangeCallback && handleChangeCallback(value);
  };

  return (
    <Box width="100%" gridColumn={'span ' + span}>
      {label && (
        <Typography mb="0.5em" sx={{ padding: '5px 15px' }}>
          {label + (optional ? ' (optional)' : '')} {readOnly ? ':' : '⇩'}
        </Typography>
      )}
      <RichTextEditor
        content={value}
        onChange={handleChange}
        height={height}
        readOnly={readOnly}
        editFormMode={editFormMode}
      />
    </Box>
  );
};

export default CustomRichTextEditor;
