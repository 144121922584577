import { AccountType } from 'core/model/Entities';
import BlockIcon from '@mui/icons-material/Block'; // Import the Block icon
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserContext } from 'core/context/user.context';
import styled from 'styled-components';
import { useContext } from 'react';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ForbiddenButton = styled.button`
  padding: 10px 20px;
  background: #ff5f5f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c93b3b;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

const ErrorIcon = styled(BlockIcon)`
  // Wrap the Block icon with a styled component
  font-size: 100px;
  color: #ff5f5f;
`;

type Props = {
  hideButton?: boolean;
};

const ForbiddenScreen: React.FC<Props> = ({ hideButton }) => {
  const { user } = useContext(UserContext);

  const getHomeLink = () => {
    if (user.account_type === AccountType.COMPANY) {
      return '/company';
    }
    if (user.account_type === AccountType.LGU) {
      return '/company';
    }
    return '/admin';
  };

  return (
    <Container>
      <ErrorIcon sx={{ fontSize: 100, color: '#ff5f5f' }} />
      <Typography variant="h4" color="textPrimary" gutterBottom>
        Access Forbidden
      </Typography>
      <Typography variant="h5" color="textSecondary" align="center" gutterBottom>
        Oops! You don't have permission to access this page. Please contact the administrator for assistance.
      </Typography>
      <br />
      {user && !hideButton && (
        <StyledLink to={getHomeLink()}>
          <ForbiddenButton>Go to Dashboard</ForbiddenButton>
        </StyledLink>
      )}
    </Container>
  );
};

export default ForbiddenScreen;
