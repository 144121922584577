import TemplateForm from 'company/entities/Template/TemplateForm';
import {
  USER_GROUP_ENTITY_NAME,
  UserGroupFields,
  UserGroupFormFields,
  UserGroupInitialValues,
  UserGroupModel,
} from './UserGroupModel';
import { createUserGroup, updateUserGroup } from 'company/api/user-groups';
import { caregoCreateUserGroup, caregoUpdateUserGroup } from 'carego-admin/api/carego-user-groups';
import { create_user_group_schema } from 'company/model/schema';
import { useContext } from 'react';
import { UserContext } from 'core/context/user.context';
import { AccountType } from 'core/model/Entities';

type Props = {
  userGroup?: UserGroupModel;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const UserGroupForm: React.FC<Props> = (props) => {
  const { userGroup, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);

  const handleSubmitApiFunction = (data: UserGroupModel) => {
    if (user?.account_type === AccountType.CAREGO)
      return userGroup ? caregoUpdateUserGroup(userGroup.id as number, data) : caregoCreateUserGroup(data);
    return userGroup ? updateUserGroup(userGroup.id as number, data) : createUserGroup(data);
  };

  return (
    <TemplateForm
      entity={userGroup}
      entityName={USER_GROUP_ENTITY_NAME}
      schema={create_user_group_schema}
      fields={UserGroupFields}
      initialValues={UserGroupInitialValues}
      visibleFields={UserGroupFormFields}
      readOnly={readOnly}
      showSubmitAtTop={showSubmitAtTop}
      formSubmitApiFunction={handleSubmitApiFunction}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default UserGroupForm;
