import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

import { tokens } from 'theme/theme';

type Props = {
  label: string;
  value?: string | number | ReactNode | any[];
  sx?: SxProps<Theme>;
  valueIsComponent?: boolean;
  fieldFontSize?: string;
};

const PatientField: React.FC<Props> = ({ label, value, sx, valueIsComponent, fieldFontSize }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const displayedValue = useMemo(() => {
    if (!valueIsComponent) {
      return Array.isArray(value) ? value.join(', ') : value;
    }
    return '';
  }, [value, valueIsComponent]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1px',
        flexDirection: 'column',
        ...sx,
        '@media screen and (max-width: 768px)': {
          gridColumn: 'span 2',
        },
      }}
    >
      <Typography color={colors.primary} fontSize={isMobilePhone ? '12px' : fieldFontSize ?? '12px'}>
        {label.includes(':') ? label : `${label}: `}
      </Typography>
      {valueIsComponent ? (
        value
      ) : (
        <Typography
          sx={{ textWrap: 'wrap', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          fontSize={isMobilePhone ? '12px' : fieldFontSize ?? '13px'}
        >
          {displayedValue || '-'}
        </Typography>
      )}
    </Box>
  );
};

export default PatientField;
