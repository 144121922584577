/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useTheme } from '@mui/material';
import { CustomModal, RegularButton } from 'core/components';
import { createCampaign, updateCampaign } from 'company/api/campaigns';
import { createOutreach, updateOutreach } from 'company/api/outreaches';
import { useContext, useEffect, useState } from 'react';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CampaignSms from './CampaignSms';
import ConfirmOutreach from './ConfirmOutreach';
import CreateOutreach from './CreateOutreach';
import CustomStepperNonLinear from 'core/components/CustomStepperNonLinear';
import { FacilityContext } from 'core/context/facility.context';
import LockIcon from '@mui/icons-material/Lock';
import { SIZES } from 'theme/constants';
import SelectPatients from './SelectPatients';
import { outreach_steps } from '../data/list';
import { tokens } from 'theme/theme';

type OutreachModalProps = {
  outreach?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshMainTable: (fromEdit?: boolean) => void;
  setClearSelected?: React.Dispatch<React.SetStateAction<number>>;
  setAddedCampaign: React.Dispatch<React.SetStateAction<number | undefined>>;
  onUpdatePatientList?: () => void;
  initialSelectedStage: number;

};

const OutreachModal: React.FC<OutreachModalProps> = ({
  outreach,
  open,
  setOpen,
  refreshMainTable,
  setClearSelected,
  setAddedCampaign,
  onUpdatePatientList,
  initialSelectedStage
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [status, setStatus] = useState<string>('draft');

  //stepper
  const [selectedStage, setSelectedStage] = useState<number>(initialSelectedStage);
  const [currentContent, setCurrentContent] = useState<any>();

  const [continueClicked, setContinueClicked] = useState<boolean>(false);

  //create outreach
  const [outreachName, setOutreachName] = useState<string>('');
  const [outreachID, setOutreachID] = useState<number>(0);
  const [campaignID, setCampaignID] = useState<number>(0);
  const [outreachDetails, setOutreachDetails] = useState<any>({
    name: '',
    event_start: '',
    event_end: '',
    sms_template: '',
    description: '',
  });

  const [updatedOutreach, setUpdatedOutreach] = useState<any>(outreach);

  const handleContinue = async () => {
    if (selectedStage === 0) {
      try {
        setContinueClicked(true);

        if (outreach) {
          await handleUpdateOutreach();
        } else {
          await handleCreateOutreach();
        }
      } catch (error) {
        console.error('Error creating campaign and outreach:', error);
        return;
      }
    }

    setSelectedStage((prevStage) => prevStage + 1);
  };

  const renderStageForms = async (stage: number) => {
    if (stage === 0) {
      setCurrentContent(
        <CreateOutreach
          outreachDetails={outreach ? outreach : outreachDetails}
          setOutreachDetails={setOutreachDetails}
          updatedOutreach={updatedOutreach}
          toEdit={!!outreach}
        />
      );
    } else if (stage === 1) {
      setCurrentContent(
        <SelectPatients
          outreachID={outreach ? outreach.outreach_id : outreachID}
          campaignID={outreach ? outreach.id : campaignID}
          refreshMainTable={() => refreshMainTable(true)}
          onUpdatePatientList={onUpdatePatientList}
        />
      );
    } else if (stage === 2) {
      setCurrentContent(
        <CampaignSms
          outreachID={outreach ? outreach.outreach_id : outreachID}
          campaignID={outreach ? outreach.id : campaignID}
          outreachTemplate={outreachDetails.sms_template ? outreachDetails.sms_template : updatedOutreach.sms_template}
        />
      );
    } else if (stage === 3) {
      setCurrentContent(
        <ConfirmOutreach
          outreachID={outreach ? outreach.outreach_id : outreachID}
          refreshMainTable={() => refreshMainTable(true)}
          status={status}
          setStatus={setStatus}
          smsTemplate={outreachDetails.sms_template ? outreachDetails.sms_template : updatedOutreach.sms_template}
        />
      );
    }
  };

  const handleCreateOutreach = async () => {
    try {
      const createCampaignResponse = await createCampaign(facility.id, outreachDetails);
      const campaign = createCampaignResponse.data.announcement;
      setAddedCampaign(campaign.id);
      setCampaignID(campaign.id);

      const data = {
        campaign_id: campaign.id,
        name: outreachDetails.name,
        sms_template: outreachDetails.sms_template,
        description: outreachDetails.description,
      };

      return createOutreach(facility.id, data).then((res) => {
        setContinueClicked(false);
        setOutreachID(res.data.data.id);
        refreshMainTable();
        setContinueClicked(false);
      });
    } catch (error) {
      console.error('Error creating campaign and outreach:', error);
    }
  };

  const handleUpdateOutreach = async () => {
    try {
      await updateCampaign(facility.id, outreach.id, outreachDetails);

      const data = {
        name: outreachDetails.name,
        event_start: outreachDetails.event_start,
        event_end: outreachDetails.event_end,
        sms_template: outreachDetails.sms_template,
        description: outreachDetails.description,
      };

      return updateOutreach(facility.id, outreach.outreach_id, data).then((res) => {
        setOutreachName(outreachDetails.name);
        setUpdatedOutreach(data);
        setContinueClicked(false);
        refreshMainTable(true);
      });
    } catch (error) {
      console.error('Error creating campaign and outreach:', error);
    }
  };

  useEffect(() => {
    renderStageForms(selectedStage);
  }, [selectedStage, outreach, initialSelectedStage]);

  useEffect(() => {
    if (open === false) {
      setClearSelected && setClearSelected((prev: number) => prev + 1);
      setSelectedStage(initialSelectedStage); //on close setSelectedStage to initialSelectedStage
      setOutreachDetails({});
      setContinueClicked(false);
      setOutreachDetails({
        name: '',
        event_start: '',
        event_end: '',
        sms_template: '',
        description: '',
      });
    }
  }, [open]);

  useEffect(() => {
    if (outreach) {
      setOutreachDetails({
        name: outreach.name,
        event_start: outreach.event_start,
        event_end: outreach.event_end,
        sms_template: outreach.sms_template,
        description: outreach.description,
      });
      setOutreachName(outreach.name);
      setUpdatedOutreach(null);
      setStatus(outreach.outreach_status);
      setSelectedStage(initialSelectedStage);
    } else {
      setOutreachDetails({
        name: '',
        event_start: '',
        event_end: '',
        sms_template: '',
        description: '',
      });
      setOutreachName('');
      setUpdatedOutreach(null);
      setStatus('draft');
    }
  }, [outreach]);

  
  useEffect(() => {
    setSelectedStage(initialSelectedStage);
  }, [initialSelectedStage]);


  useEffect(() => {
    if (status) {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <CustomModal
        open={open}
        setOpen={setOpen}
        header={outreachName ? outreachName : 'Create Outreach'}
        headerIcon={status === 'published' && <LockIcon sx={{ color: 'orange', fontSize: '25px' }} />}
        width={1100}
      >
        <Box>
          <Box
            m="10px 0 40px"
            sx={{
              backgroundColor: colors.light_blue_background,
              padding: SIZES.paddingL,
              borderRadius: SIZES.borderRadiusS,
            }}
          >
            <CustomStepperNonLinear
              activeStep={selectedStage}
              setActiveStep={setSelectedStage}
              steps={outreach_steps}
              strict={outreach ? false : true}
            />
          </Box>

          {selectedStage !== 0 && (
            <RegularButton
              onClick={() => setSelectedStage((prevStage) => prevStage - 1)}
              label={'Back to previous step'}
              startIcon={<ArrowBackOutlinedIcon />}
              variant="text"
              styles={{
                color: colors.text,
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
                mb: '20px',
              }}
            />
          )}

          {currentContent}
        </Box>

        {selectedStage !== 3 && status !== 'published' && (
          <Box display={'flex'} justifyContent={'center'} marginTop={'60px'}>
            <RegularButton
              onClick={handleContinue}
              label={selectedStage === 0 ? 'Save and Continue' : 'Continue'}
              endIcon={<ArrowForwardOutlinedIcon />}
              disabled={continueClicked || outreachDetails.name === '' || outreachDetails.sms_template === ''}
            />
          </Box>
        )}
      </CustomModal>
    </>
  );
};

export default OutreachModal;
