import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const PG_ENTITY_NAME = 'Patient Group';

export const company_tags_schema = yup.object().shape({
  tag_name: yup.string().required('Tag Name is required.'),
});

export interface PGModel {
  tag_name: string;
  category_name: string;
}
export type PatientGroupInput = {
  tag_name: string;
};

export interface PatientGroupFieldInput extends FieldInput {
  field_name?: keyof PatientGroupFieldInput;
}

export const patientGroupinitialValues: PatientGroupInput = {
  tag_name: '',
};

export const patientGroupFields: EntityFields[] = [{ fieldName: 'tag_name', displayName: 'Tag Name', span: 4 }];

export const patientGroupTableColumns: any[] = ['tag_name'];

export const patientGroupFormFields: string[] = ['tag_name'];
