import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { tokens } from 'theme/theme';

type HeaderProps = {
  title?: string;
  subtitle?: string;
  mb?: string;
  icon?: any;
  variant?: any;
};

const Header: React.FC<HeaderProps> = ({ title, subtitle, mb, icon, variant = 'h3' }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box mb={mb ?? '20px'}>
      <Box display="flex" gap="0.5rem">
        {isMobile ? (
          <Typography variant={variant} color={colors.text} fontWeight="600" fontSize="14px" sx={{ m: '0 0 5px 0' }}>
            {title}
          </Typography>
        ) : (
          <Typography variant={variant} color={colors.text} fontWeight="600" sx={{ m: '0 0 5px 0' }}>
            {title}
          </Typography>
        )}
        <Typography variant={variant} fontWeight="600" sx={{ p: '0.15rem 0' }}>
          {icon && icon}
        </Typography>
      </Box>
      {subtitle && (
        <Typography variant="h5" color={colors.accent}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
