import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notes-summary-template-fields';


export const getPatientNotesSummaryTemplateFields = (template_id: number) => {
    return axios.get(`${baseURL}/${path}/${template_id}`);
  };

  export const addPatientNoteSummaryFields = (template_id: number, data:any) => {
    return axios.post(`${baseURL}/${path}/${template_id}`, data);
  };

  export const removeFieldFromNoteSummary = (id: number) => {
    return axios.delete(`${baseURL}/${path}/${id}`)
  }

  export const reorderSummaryTemplateFields = (data:any) => {
    return axios.patch(`${baseURL}/${path}`, data);
  }
