import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'account-chart';

export const getMany = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getManyByIdOrAccountName = (facility_id: number, idOrAccountName: string | number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${idOrAccountName}`, { params: query });
};

export const getAccountTypes = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/account-types`, { params: query });
};

export const getAccountTypeByAccountCode = (accountCode: string | number) => {
  return axios.get(`${baseURL}/${path}/account-types/${accountCode}`);
};

export const create = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const update = (data: any) => {
  return axios.put(`${baseURL}/${path}`, data);
};

export const deleteOne = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
