import { BOOKING_ENTITY_NAME, BookingModel } from './BookingModel';
import { ReactNode, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';

import BookingForm from './BookingForm';
import { FacilityContext } from 'core/context/facility.context';
import TemplateDrawer from '../../../Template/TemplateDrawer';

type Props = { childComponent?: ReactNode; setDrawerSelectedRow?: any };

const BookingDrawer = forwardRef((props: Props, ref) => {
  const { childComponent, setDrawerSelectedRow } = props;
  const { facility } = useContext(FacilityContext);
  const tableRef: any = useRef();
  useImperativeHandle(ref, () => ({ refreshTable }));
  const [selectedRow, setSelectedRow] = useState<BookingModel>();
  const refreshTable = () => {
    tableRef.current.refreshTable();
  };

  return (
    <TemplateDrawer
      entityName={BOOKING_ENTITY_NAME}
      internalSetDrawerSelectedRow={setSelectedRow}
      externalSetDrawerSelectedRow={setDrawerSelectedRow}
      mainColumn="full_name"
      tableRef={tableRef}
      childComponent={
        childComponent ?? (
          <BookingForm
            callbackAfterSubmit={() => tableRef.current.refreshTable()}
            facility={facility}
            booking={selectedRow}
          />
        )
      }
    />
  );
});

export default BookingDrawer;
