import { Box } from '@mui/material';
import { Header } from 'core/components';
import HealthPrograms from './screens/HealthPrograms';

const GeneralHealthPrograms = () => {
  return (
    <Box>
      <Header title="General Health Programs" subtitle="General Announcements and Attendance Tracking" />
      <HealthPrograms />
    </Box>
  );
};

export default GeneralHealthPrograms;
