import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notes';

//getMany
export const getNotes = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

//get
export const getNote = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

//getAll
export const getAllNotes = () => {
  return axios.get(`${baseURL}/${path}/all`);
};

export const createNote = (data: any) => {
    return axios.post(`${baseURL}/${path}`, data);
};

export const updateNotes = (id: number, data: any) => {
    return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteNote = (id: number) => {
    return axios.delete(`${baseURL}/${path}/${id}`);
};