/* eslint-disable react-hooks/exhaustive-deps */

import {
  PROMO_ENTITY_NAME,
  create_promotion_schema,
  promoFields,
  promoFormFields,
  promoInitialValues,
} from './PromoModel';
import { createPromotion, updatePromotion } from 'company/api/promotions';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  facility: FacilityModel;
  promotion?: any;
  readOnly?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PromoForm = (props: Props) => {
  const { facility, promotion, readOnly, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    return promotion ? updatePromotion(facility.id, data.id, data) : createPromotion(facility.id, data);
  };

  return (
    <TemplateForm
      entityName={PROMO_ENTITY_NAME}
      fields={promoFields}
      visibleFields={promoFormFields}
      entity={promotion}
      initialValues={promotion ? changeNullToBlank(promotion) : promoInitialValues}
      schema={create_promotion_schema}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default PromoForm;
