// ThemeToggle.js

import { useContext, useEffect, useState } from 'react';

import { ColorModeContext } from 'theme/theme';
import styled from 'styled-components';
import { useTheme } from '@mui/material';

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleLabel = styled.label`
  margin-right: 10px;
`;

const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: #2196f3;
  }

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  &:checked:before {
    transform: translateX(20px);
  }

  &:before {
    content: '☀'; /* Unicode checkmark symbol */
    font-size: 12px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ThemeToggle = () => {
  const theme = useTheme();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const colorMode = useContext(ColorModeContext);

  const handleToggle = () => {
    colorMode.toggleColorMode();
  };

  useEffect(() => {
    setIsDarkMode(theme.palette.mode === 'dark');
  }, [theme.palette.mode]);

  return (
    <ToggleWrapper>
      <ToggleLabel>Light</ToggleLabel>
      <ToggleSwitch checked={isDarkMode} onChange={handleToggle} />
      <ToggleLabel>Dark</ToggleLabel>
    </ToggleWrapper>
  );
};

export default ThemeToggle;
