import { TourContext } from 'core/context/tour.context';
import { useContext, useEffect } from 'react';

type Props = {
  closeFormModal: () => void;
  handleAddButtonClick: () => void;
  triggerIndex: number;
};

const GuidedTourFormDisplay: React.FC<Props> = ({ closeFormModal, handleAddButtonClick, triggerIndex }) => {
  const {
    tourState: { stepIndex, tourActive },
    setTourState,
  } = useContext(TourContext);

  useEffect(() => {
    if (tourActive) {
      if (stepIndex === triggerIndex) {
        handleAddButtonClick();
        setTimeout(() => {
          setTourState((prev: any) => {
            return { ...prev, run: true, tourActive: true };
          });
        }, 300);
      } else {
        closeFormModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourActive, stepIndex]);

  return <></>;
};

export default GuidedTourFormDisplay;
