import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { BreadcrumbContext, BreadcrumbTabProps } from 'core/context/breadcrumb.context';
import { ReactNode, useContext, useEffect } from 'react';

import CustomVerticalTabs from 'core/components/CustomVerticalTabs';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { LoadingScreen } from 'core/screens';
import MobilePageHeader from 'core/components/MobilePageHeader';
import PageHeader from 'core/components/PageHeader';
import { TabProps } from 'core/components/CustomTabs';

type CareGoPageProps = {
  header?: string;
  subHeader?: string;
  headerMb?: string;
  children?: ReactNode;
  loading?: boolean;
  tabs?: TabProps[];
  tabMinWidth?: string;
  tabGroupId?: string;
  selected?: number;
  setSelected?: (value: number) => void | undefined;
  tabStyles?: SxProps<Theme>;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  actionButtonIcon?: JSX.Element;
  dropdownActions?: DropdownOptionProps[];
  dropdownTextColor?: string;
  breadcrumb?: BreadcrumbTabProps[];

  regularButtonId?: string;
  showRegularButton?: boolean;
  regularButtonLabel?: string;
  regularButtonIcon?: JSX.Element;
  handleRegularButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  regularButtonStyle?: React.CSSProperties;
  showIconButton?: boolean;
  handleIconButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconButtonStyle?: React.CSSProperties;
  iconForCustomButtonIcon?: JSX.Element;
  iconSize?: string;
};

const CareGoPage: React.FC<CareGoPageProps> = ({
  header,
  subHeader,
  children,
  loading,
  headerMb,
  tabs,
  tabMinWidth,
  tabGroupId,
  selected,
  setSelected,
  tabStyles,
  showActionButton = false,
  dropdownActions,
  dropdownTextColor,
  actionButtonLabel,
  actionButtonIcon,
  breadcrumb,

  regularButtonId,
  showRegularButton,
  regularButtonLabel,
  regularButtonIcon,
  handleRegularButtonClick,
  regularButtonStyle,

  showIconButton = false,
  handleIconButtonClick,
  iconButtonStyle,
  iconForCustomButtonIcon,
  iconSize,
}) => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    breadcrumb && setBreadcrumb(breadcrumb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreadcrumb]);

  return (
    <Box width="100%" position="relative">
      <LoadingScreen loading={!!loading}>
        {header && (
          <PageHeader
            title={header}
            // mb={'0'}
            dropdownTextColor={dropdownTextColor}
            actionButton={showActionButton}
            actionButtonLabel={actionButtonLabel}
            actionButtonIcon={actionButtonIcon}
            regularButtonId={regularButtonId}
            showRegularButton={showRegularButton}
            regularButtonLabel={regularButtonLabel}
            regularButtonIcon={regularButtonIcon}
            handleRegularButtonClick={handleRegularButtonClick}
            regularButtonStyle={regularButtonStyle}
            menuOptions={dropdownActions}
            tabs={
              tabs && (
                <CustomVerticalTabs
                  tabs={tabs}
                  selected={selected}
                  setSelected={setSelected}
                  tabMinWidth={tabMinWidth}
                  tabGroupId={tabGroupId}
                  tabStyles={{
                    ...tabStyles,
                    paddingTop: '10px',
                    marginRight: '20px',
                    // height: 'calc(100vh - 100px)',
                  }}
                />
              )
            }
          />
        )}

        <Box width="100%" sx={{ marginBottom: isMobile ? '3.5rem' : '0' }}>
          {children}
        </Box>
      </LoadingScreen>
    </Box>
  );
};

export default CareGoPage;
