/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import CheckupDetailsBatch from './tb-acf-batch-stages/CheckupDetailsBatch';
import CheckupResultBatch from './tb-acf-batch-stages/CheckupResultBatch';
import { CustomSelectField } from 'core/components';
import ScreeningDetailsBatch from './tb-acf-batch-stages/ScreeningDetailsBatch';
import ScreeningResultBatch from './tb-acf-batch-stages/ScreeningResultBatch';
import SputumDetailsBatch from './tb-acf-batch-stages/SputumDetailsBatch';
import SputumResultBatch from './tb-acf-batch-stages/SputumResultBatch';

type TbAcfBatchModalProps = {
  patients: string; //list of selected patients
  selectedStage: number;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable: () => void;
};

const TbAcfBatchModal: React.FC<TbAcfBatchModalProps> = ({ patients, selectedStage, openModal, refreshTable }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const screeningOptions = [
    {
      label: 'Screening Details',
      value: 'Screening Details',
    },
    {
      label: 'Screening Result',
      value: 'Screening Result',
    },
  ];

  const sputumOptions = [
    {
      label: 'Sputum Details',
      value: 'Sputum Details',
    },
    {
      label: 'Sputum Result',
      value: 'Sputum Result',
    },
  ];

  const checkupOptions = [
    {
      label: 'Check-up Details',
      value: 'Check-up Details',
    },
    {
      label: 'Check-up Result',
      value: 'Check-up Result',
    },
  ];

  const [options, setOptions] = useState<any[]>(sputumOptions);

  const handleSelectResult = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (selectedStage === 1) {
      setOptions(screeningOptions);
      setSelectedOption('Screening Details');
    } else if (selectedStage === 2) {
      setOptions(sputumOptions);
      setSelectedOption('Sputum Details');
    } else {
      setOptions(checkupOptions);
      setSelectedOption('Check-up Details');
    }
  }, []);

  const renderContent = () => {
    switch (selectedOption) {
      case 'Screening Details':
        return <ScreeningDetailsBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
      case 'Screening Result':
        return <ScreeningResultBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
      case 'Sputum Details':
        return <SputumDetailsBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
      case 'Sputum Result':
        return <SputumResultBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
      case 'Check-up Details':
        return <CheckupDetailsBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
      case 'Check-up Result':
        return <CheckupResultBatch patients={patients} openModal={openModal} refreshTable={refreshTable} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          flexDirection: 'row',
          padding: '10px',
          width: '100%',
          gap: '20px',
        }}
      >
        <Box width="48%">
          <CustomSelectField
            label={'Update:'}
            handleChange={handleSelectResult}
            fieldName={'Update:'}
            value={selectedOption}
            options={options.map((u) => {
              return { key: u.label, value: u.value };
            })}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'inline-block', width: '100%', marginTop: '20px' }}>{renderContent()}</Box>
    </>
  );
};

export default TbAcfBatchModal;
