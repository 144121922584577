import { CompanySignatory } from 'company/model/Entities';
import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-signatories';

export const getSignatory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getSignatories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, {
    params: query,
  });
};

export const createSignatory = (data: CompanySignatory) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateSignatory = (id: number, data: CompanySignatory) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deactivateSignatory = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getSignatoryClinics = (id: number) => {
  return axios.get(`${baseURL}/${path}/facilities/${id}`);
};

export const getSignatoriesAssigned = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/assigned/${facility_id}`, {
    params: query,
  });
};

export const getClinicsWithoutSignatory = (id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/no-signatories/${id}`, {
    params: query,
  });
};

export const assignSignatoryToClinic = (facility_id: number, signatories: any) => {
  return axios.post(`${baseURL}/${path}/assign-to-facility/${facility_id}`, signatories);
};

export const removeSignatoryFromClinic = (facility_id: number, signatories: any) => {
  return axios.delete(`${baseURL}/${path}/remove-from-facility/${facility_id}`, {
    data: { signatory_ids: signatories },
  });
};
