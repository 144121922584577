import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'service-notes';

export const createServiceNote = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const deleteServiceNote = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
