import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { clearNotifications, getAllNotifications, markAsRead } from 'company/api/notifications';

import Badge from '@mui/material/Badge';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { Link } from 'react-router-dom';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { tokens } from 'theme/theme';

interface DataObject {
  company_id: number;
  created_at: string;
  id: number;
  is_active: number;
  is_read: number;
  message: string;
  type: string;
  updated_at: string;
  user_id: number;
}

const NotificationComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const [notificationData, setNotificationData] = useState<DataObject[]>([]);
  const [refreshNotification, setRefreshNotification] = useState<number>(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { facility } = useContext(FacilityContext);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    async function getData() {
      setIsLoading(true);
      const data = await getAllNotifications();
      setNotificationData(
        data.data.sort(
          (a: DataObject, b: DataObject) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );
      setIsLoading(false);
    }

    getData();

    if (facility) {
      intervalId = setInterval(() => {
        getData();
      }, 60000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [setNotificationData, refreshNotification, facility]);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    // getLowStocks(facility.id);
    setAnchorEl(event.currentTarget);
  };

  async function handleClearNotifications() {
    await clearNotifications();
    setRefreshNotification((notif) => notif + 1);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const unread_notif = notificationData.filter((item: any) => !item.is_read).length;
  return (
    <div>
      <Badge
        badgeContent={open ? null : unread_notif}
        color="error"
        max={99}
        sx={{ cursor: 'pointer' }}
        overlap="circular"
      >
        <Tooltip
          title={
            <Typography variant="h6">{`${unread_notif} unread ${
              unread_notif > 1 ? 'notifications' : 'notification'
            }`}</Typography>
          }
          placement="left"
        >
          {!isMobilePhone ? (
            <NotificationsOutlinedIcon sx={{ color: colors.grey_text, fontSize: '1.8rem' }} onClick={handleClick} />
          ) : (
            <NotificationsIcon sx={{ color: colors.dark_grey_text, fontSize: '1.625rem' }} onClick={handleClick} />
          )}
        </Tooltip>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ '& .MuiPopover-paper': { borderRadius: '10px' } }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1rem',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: colors.background,
            }}
          >
            <Typography variant="h4">Notifications</Typography>
            <Box display="flex" flexDirection="row" gap="10px" justifyContent="center" alignItems="center">
              {/* <Link to="/company/notifications" sx={{textDecoration: "none", color: colors.text}}>See All</Link> */}
              <Tooltip title={<Typography variant="h6">See all</Typography>} placement="left">
                <Link to="/company/notifications" onClick={handleClose}>
                  <MenuOpenIcon
                    sx={{ color: colors.grey_text, fontSize: '26px', cursor: 'pointer', paddingTop: '5px' }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title={<Typography variant="h6">Clear Notifications</Typography>} placement="left">
                <ClearAllOutlinedIcon
                  sx={{ color: colors.grey_text, fontSize: '24px', cursor: 'pointer' }}
                  onClick={handleClearNotifications}
                />
              </Tooltip>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#E74C3C',
                  width: '20px',
                  height: '20px',
                }}
              >
                <Typography variant="h6" color={'#fff'}>
                  {unread_notif}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider variant="middle" sx={{ borderBottomWidth: 2 }} />
          <Box sx={{ maxHeight: 280, overflow: 'auto' }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {!isLoading &&
                notificationData.length > 0 &&
                notificationData?.map((item: any) => {
                  return <NotificationItem key={item.id} data={item} setRefreshNotification={setRefreshNotification} />;
                })}
              {isLoading && (
                <Typography variant="h4" sx={{ padding: '100px' }}>
                  Loading...
                </Typography>
              )}
              {!isLoading && notificationData.length < 1 && (
                <Typography variant="h5" sx={{ padding: '80px' }}>
                  No new notifications.
                </Typography>
              )}
            </List>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
type NotificationProps = {
  data: any;
  setRefreshNotification: any;
};

function NotificationItem(props: NotificationProps) {
  const { data, setRefreshNotification } = props;
  const notificationDate = moment(data.created_at);
  const now = moment();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const diffInMinutes = now.diff(notificationDate, 'minutes');
  const diffInHours = now.diff(notificationDate, 'hours');
  const diffInDays = now.diff(notificationDate, 'days');
  let timeFromNow;

  async function markRead(id: number) {
    try {
      await markAsRead(id);
      setRefreshNotification((count: number) => count + 1);
    } catch (err: any) {
      console.error(err.message);
    }
  }

  if (diffInMinutes < 60) {
    timeFromNow = `${diffInMinutes} ${diffInMinutes > 1 ? 'minutes' : 'minute'} ago`;
  } else if (diffInHours < 24) {
    timeFromNow = `${diffInHours} ${diffInHours > 1 ? 'hours' : 'hour'} ago`;
  } else {
    timeFromNow = `${diffInDays} ${diffInHours > 1 ? 'days' : 'day'} ago`;
  }

  return (
    <>
      <Box sx={{ backgroundColor: data.is_read ? colors.background : colors.hoverTableRow, cursor: 'pointer' }}>
        <ListItem
          sx={{ justifyContent: 'center', display: 'flex', margin: '10px 0' }}
          key={data}
          secondaryAction={
            data.is_read ? (
              <MarkEmailReadOutlinedIcon sx={{ color: colors.grey_text }} />
            ) : (
              <Tooltip title={<Typography variant="h6">Mark as read</Typography>} placement="top">
                <MarkEmailReadOutlinedIcon
                  sx={{ color: colors.primary }}
                  onClick={() => markRead(data.id)}
                ></MarkEmailReadOutlinedIcon>
              </Tooltip>
            )
          }
        >
          {!data.is_read && (
            <Box sx={{ justifyContent: 'center', alignItems: 'center', height: '100%', padding: '5px 12px' }}>
              <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: colors.blueAccent }}></Box>
            </Box>
          )}
          <ListItemAvatar>
            <Avatar
              alt={`${data.message}`}
              src={
                `/assets/carego_health_suite_logo.png` ||
                `https://t4.ftcdn.net/jpg/05/65/72/25/360_F_565722576_O1ukHVh1pIn2D3OS2grnG9w2RwwykPXf.jpg`
              }
            />
          </ListItemAvatar>

          <ListItemText primary={data.message} secondary={timeFromNow} />
        </ListItem>
      </Box>
      <Divider variant="middle" />
    </>
  );
}
export default NotificationComponent;
