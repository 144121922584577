import { IconButton, SxProps, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { ReactNode } from 'react';
import { tokens } from 'theme/theme';

type IconButtonProps = {
  tooltip?: string;
  placement?: any;
  icon: ReactNode;
  onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  className?: string;
  disabled?: boolean;
  iconSize?: string;
};

const CustomIconButton: React.FC<IconButtonProps> = ({
  tooltip,
  icon,
  color,
  placement = 'top',
  onClick,
  size,
  sx,
  className,
  disabled,
  iconSize,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:700px)');
  return (
    <Tooltip title={tooltip} placement={placement} arrow>
      <IconButton
        className={className}
        size={size ? size : isMobile ? 'medium' : 'small'}
        onClick={onClick}
        sx={{
          svg: { fontSize: iconSize ?? (isMobile ? '25px' : '20px') },
          '&: hover': {
            backgroundColor: colors.lightBackground,
          },
          ...sx,
        }}
        color={color}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;
