import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'running-invoices';


export const getPatientActiveRunningInvoices = (facility_id: number, patient_id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/active-per-patient/${patient_id}`);
  };

  export const createRunningInvoice = (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
  };