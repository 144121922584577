import { PatientEnrollment, SetProgramRemindersSchedule, UpdateProgramRemindersSchedule } from 'lgu/model/Entities';

import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'program-reminders-patients';

export const enrollProgramRemindersPatients = (program_code: string, patientData: SetProgramRemindersSchedule) => {
  return axios.post(`${baseURL}/${path}/${program_code}`, patientData);
};

export const unenrollProgramRemindersPatients = (program_code: string, patients: PatientEnrollment) => {
  return axios.delete(`${baseURL}/${path}/${program_code}`, {
    params: patients,
  });
};

export const updateProgramRemindersPatient = (program_code: string, patientData: UpdateProgramRemindersSchedule) => {
  return axios.put(`${baseURL}/${path}/${program_code}`, patientData);
};

export const getProgramRemindersPatient = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const startNewCycle = (patientData: UpdateProgramRemindersSchedule) => {
  return axios.patch(`${baseURL}/${path}/new-cycle`, patientData);
};
