import { Box, Collapse, Typography, useMediaQuery } from '@mui/material';
import { CustomForm, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import { FORM_MODE, FieldInput } from 'core/model/interface';
import { useEffect, useState } from 'react';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { Components } from '..';
import { ContainerRow } from 'core/components/containers';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PatientRecordForm from 'core/components/forms/PatientRecordForm';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { WaitForFacility } from 'company/components';
import { evaluateExpression } from 'core/utils';

type DocumentFormProps = {
  components: Components[];
  noteId: number;
  component: Components;
  mode?: FORM_MODE;
  forceSubmit: boolean;
  fields?: any[];
  autoSave: () => void;
  appointment?: CompanyAppointmentModel;
  patient?: PatientModel;
  setLastUpdate?: React.Dispatch<React.SetStateAction<any>>;
};

const DocumentForm: React.FC<DocumentFormProps> = ({
  components,
  noteId,
  mode,
  component,
  forceSubmit,
  autoSave,
  appointment,
  patient,
  setLastUpdate,
}) => {
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [open, setOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reorderedFields, setReorderedFields] = useState(component.fields || []);
  const [formFields, setFormFields] = useState<FieldInput[]>([]);

  const handleOpenModal = () => {
    setReorderedFields(component.fields!);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setReorderedFields([]);
    setIsModalOpen(false);
  };

  const handleSaveChanges = (data: any) => {
    component.title = data.title;
    component.description = data.description;
    handleCloseModal();
    autoSave && autoSave();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !component.fields) {
      return;
    }

    const newReorderedFields = [...reorderedFields];
    const [movedField] = newReorderedFields.splice(result.source.index, 1);
    newReorderedFields.splice(result.destination.index, 0, movedField);
    component.fields = newReorderedFields;
    setReorderedFields(newReorderedFields);
  };

  useEffect(() => {
    if (component.fields)
      setFormFields(
        component.fields.map((field: FieldInput) => {
          field = { ...field };
          if (field.parent_field && field.condition) {
            field.hiddenBasedOnOtherField = (data: any) => {
              if (!data) return false;
              return field.condition === 'equal'
                ? data[field.parent_field!] === field.condition_value
                : data[field.parent_field!] !== field.condition_value;
            };
          }
          if (field.formula_text) {
            field.formula = (data: any) => {
              let formulatext = field.formula_text + '';
              Object.keys(data).forEach((item) => {
                if (!Array.isArray(data[item])) {
                  formulatext = formulatext.replaceAll(item, data[item] || 0);
                }
              });

              return evaluateExpression(formulatext);
            };
          }
          return field;
        })
      );
  }, [component.fields]);

  return (
    <Box
      sx={{
        borderRadius: '10px',
        padding:
          mode === FORM_MODE.EDIT ? (isMobilePhone ? undefined : '24px 12px') : isMobilePhone ? undefined : '12px 12px',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        {(component.title || component.description || mode === FORM_MODE.EDIT) && (
          <>
            <Box mb="20px">
              <Typography variant="h4" fontWeight="bold">
                {component.title}
              </Typography>
              <Typography>{component.description}</Typography>
            </Box>
          </>
        )}
      </Box>

      {mode === FORM_MODE.EDIT && (
        <ContainerRow>
          <RegularButton
            startIcon={open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            label={open ? 'Hide' : 'Show'}
            onClick={() => setOpen(!open)}
            variant="text"
            color="inherit"
          />
          <CustomIconButton icon={<SettingsOutlinedIcon />} onClick={handleOpenModal} tooltip="Edit Form" />
        </ContainerRow>
      )}
      <Collapse in={open || mode === FORM_MODE.RESPOND} timeout="auto">
        {mode === FORM_MODE.RESPOND ? (
          <WaitForFacility facility={!!patient}>
            <PatientRecordForm
              components={components}
              noteId={noteId}
              fields={formFields}
              responses={component.responses!}
              appointment={appointment!}
              patient={patient!}
              setLastUpdate={setLastUpdate}
            />
          </WaitForFacility>
        ) : (
          <CustomForm
            onSubmit={(data) => (component.responses = data)}
            fields={component.fields!}
            initialValues={component.responses!}
            loading={false}
            hideSubmitButton
            span={12}
            columnNumber={3}
            readOnly
            formMode={mode}
            columnGap={mode === FORM_MODE.EDIT ? '10px' : undefined}
            forceSubmit={forceSubmit}
            component={component}
            autoSave={autoSave}
            appointment={appointment}
            patient={patient}
          />
        )}
      </Collapse>

      <CustomModal header="Edit Form" open={isModalOpen} setOpen={setIsModalOpen} onClose={handleCloseModal}>
        <CustomForm
          onSubmit={handleSaveChanges}
          fields={[
            { field_name: 'title', display_name: 'Title', type: 'text', span: 4 },
            { field_name: 'description', display_name: 'Description', type: 'text', span: 4, multiline: true, rows: 3 },
          ]}
          initialValues={{ title: component.title, description: component.description }}
          loading={false}
          footerComponent={
            <Box mt="20px">
              <DraggableList
                title="Reorder Form Fields"
                fromModal
                droppableId="form-fields"
                handleDragEnd={handleDragEnd}
                list={reorderedFields}
                displayItem={(field, index) => (
                  <div>
                    {field.display_name ?? field.section_header ?? field.subsection_header} ({field.type})
                  </div>
                )}
              />
            </Box>
          }
        />
      </CustomModal>
    </Box>
  );
};

export default DocumentForm;
