/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { PROGRAM_CODES, getFormFields, getFormFieldsValues } from '../Utils/Customization';
import { getAppointmentInDateRange, getTotalPatientsServedToday, scheduleAppointment } from 'company/api/appointments';
import { useContext, useEffect, useRef, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Calendar from './components/Calendar';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import InventoryDashboard from '../InventorySystem/Dashboard';
import PageHeader from 'core/components/PageHeader';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { ProgramPatientModel } from 'company/model/Entities';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SmsPreview from '../Appointments/components/modal/SmsPreview';
import { TourContext } from 'core/context/tour.context';
import { WaitForFacility } from 'company/components';
import { getBookings } from 'company/api/bookings';
import { getDashboardStat } from 'lgu/api/dashboard';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { tranformFormErrors } from 'core/utils';
import { useMount } from 'react-use';
import { useSnackbar } from 'notistack';

type CompanyDashboardProps = {
  setRefreshTable?: (refreshTable: any) => void;
  patient?: ProgramPatientModel;
  date?: Date;
  appointment?: Date;
};

const CompanyDashboard: React.FC<CompanyDashboardProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [stats, setStats] = useState({
    patient_count: 0,
    pending_sms: 0,
    program_count: 0,
    sms_credits: 0,
    transactions: 0,
  });
  const theme = useTheme();
  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const colors = tokens(theme.palette.mode);
  const [schema, setSchema] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>({});
  const [openEnrollModalNew, setOpenEnrollModalNew] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [bookingsNum, setBookingsNum] = useState<number>(0);
  const [appointmentsNum, setAppointmentsNum] = useState<number>(0);
  const [totalPatientsServedToday, setTotalPatientsServedToday] = useState<number>(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const {
    tourState: { tourActive },
    setTourState,
  } = useContext(TourContext);
  const calendarRef: any = useRef() as any;

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setTourState((prev: any) => {
          return { ...prev, run: true, stepIndex: 1 };
        });
      }, 500);
    }
  });

  // const getData = async () => {
  //   return getPrescriptions(facility.id, patient_id);
  // };

  useEffect(() => {
    if (facility) {
      getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility);
      getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
    }
  }, [facility]);

  useEffect(() => {
    setBreadcrumb([]);
    getDashboardStat().then((res) => {
      setStats(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedDate) {
      initialValues.schedule = selectedDate;
      setOpenEnrollModalNew(true);
    }
  }, [selectedDate]);

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    try {
      const final = { ...data };
      final.patients = JSON.stringify(data.patient_id.map((patient: any) => patient.value));
      final.services = JSON.stringify(data.service_id.map((service: any) => service.value));

      const res = await scheduleAppointment(facility.id, final);
      successFormSubmit(res, formikHelpers);
    } catch (error: any) {
      if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
      console.error(error);
      enqueueSnackbar('Error occured! Please try again.', { variant: 'error' });
    } finally {
      setButtonLoading(false);
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully enrolled!`, { variant: 'success' });
      setOpenEnrollModalNew(false);
    } else if (res.response.status === 400) {
    }
    setButtonLoading(false);
    calendarRef.current.refreshEvents();
  };

  const setBookingsCount = async () => {
    if (facility && !facility.is_admin) {
      try {
        getBookings(facility.id, { status: 'pending' }).then((res) => {
          setBookingsNum(res.data.data.length);
        });
      } catch (error) {
        setBookingsNum(0);
      }
    }
  };

  const getCurrentWeekDates = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week'); // Sunday
    const weekEnd = currentDate.clone().endOf('week'); // Saturday

    return {
      start: new Date(weekStart.toDate().toDateString()),
      end: new Date(weekEnd.toDate().toDateString()),
    };
  };

  const setAppointmentsCount = async () => {
    if (facility && !facility.is_admin) {
      const dates = getCurrentWeekDates();
      try {
        getAppointmentInDateRange(facility.id, dates.start, dates.end).then((res) => {
          setAppointmentsNum(res.data.data.length);
        });
      } catch (error) {
        setAppointmentsNum(0);
      }
    }
  };

  useEffect(() => {
    if (facility?.id) {
      setBookingsCount();
      setAppointmentsCount();
      getTotalPatientsServedToday(facility?.id).then((res) => setTotalPatientsServedToday(res?.data?.quantity));
    }
  }, [facility?.id]);

  return (
    <WaitForFacility facility={facility}>
      <Box id="home">
        {/* HEADER */}
        <PageHeader title="Home" />

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns={'repeat(12, 1fr)'}
          // gridAutoRows="140px"
          gap={isMobilePhone ? '5px' : '20px'}
        >
          {/* ROW 1 */}
          {/* <CustomDashboardWidget
            label="Patients Enrolled"
            icon={PersonAddIcon}
            count={stats.patient_count?.toLocaleString()}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
          />
          <CustomDashboardWidget
            count={stats.transactions?.toLocaleString()}
            label="Patient Transactions"
            icon={ReceiptIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
          />

          <CustomDashboardWidget
            count={stats.pending_sms?.toLocaleString()}
            label="Pending SMS"
            icon={ScheduleSendIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
          /> */}

          {/* <CustomDashboardWidget
            count={bookingsNum.toString()}
            label="No. of Bookings for Confirmation"
            icon={EventNoteOutlinedIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            iconBackgroundColor="transparent"
          /> */}
          {/* <CustomDashboardWidget
            count={appointmentsNum.toString()}
            label="No. of Appointments per Week"
            icon={CalendarMonthOutlinedIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            iconBackgroundColor="transparent"
          /> */}
          {/* <CustomDashboardWidget
            count={String(totalPatientsServedToday)}
            label="No. of Patients Served Today"
            icon={PeopleOutlinedIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            iconBackgroundColor="transparent"
          /> */}

          {/* <CustomDashboardWidget
            count={stats.sms_credits?.toLocaleString()}
            label="Available Credits"
            icon={ChatIcon}
            sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
          /> */}

          {/* ROW 2 */}

          <CustomModal header="Schedule Patients" open={openEnrollModalNew} setOpen={setOpenEnrollModalNew}>
            <CustomForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              fields={formFields}
              schema={schema}
              loading={buttonLoading}
              footerComponent={<SmsPreview templateCode="appointment_reminder" facility={facility} mt="20px" />}
            />
          </CustomModal>

          {/* ROW 2 */}
          <Box sx={{ gridColumn: 'span 12' }}>
            <InventoryDashboard fromClinicManagement />
          </Box>
        </Box>
      </Box>
    </WaitForFacility>
  );
};

export default CompanyDashboard;
