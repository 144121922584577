import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Health3: React.FC = () => {
  return (
    <Box>
      <Step>1. Go to the “Clinic Management” tab in sidebar </Step>
      <Step>2. Select “Health Sessions” tab inside the Clinic Management </Step>
      <Step>3. Click the Health Session you want to add a service to a stage </Step>
      <Image src="/assets/FaqAssets/healthpackage/3/1-3.png" alt="Step 1-2 Image" />

      <Step>4. Select a stage and click the “Add Services” button </Step>
      <Image src="/assets/FaqAssets/healthpackage/3/4.png" alt="Image" />
      <Step>
        5. Select services you want to add and click the “Add Selected Services” button, you can also add one by one by
        clicking the “Add” button in the right side of the services.{' '}
      </Step>

      <Image src="/assets/FaqAssets/healthpackage/3/5.png" alt="Image" />
    </Box>
  );
};

export default Health3;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 400px;
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
