import 'core/styles/Components.css';

import { ChangeEvent, useEffect, useState } from 'react';
import { MenuItem, TextField, useTheme } from '@mui/material';

import { SelectOption } from 'core/model/interface';
import { tokens } from 'theme/theme';

type CustomSelectFieldProps = {
  id?: string;
  type?: React.HTMLInputTypeAttribute;
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: unknown;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  span?: number;
  options?: SelectOption[];
  optional?: boolean;
  handleChangeCallback?: (value: string) => void;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  selected?: any;
  variant?: any;
  readOnly?: boolean;
  autoSave?: () => void;
};

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  id,
  type,
  label,
  handleBlur,
  handleChange,
  value,
  fieldName,
  disabled,
  touched,
  error,
  span,
  options,
  optional,
  required,
  handleChangeCallback,
  size = 'small',
  variant,
  readOnly,
  autoSave,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedOption(event.target.value);
    handleChange && handleChange(event);
    handleChangeCallback && handleChangeCallback(event.target.value);
    autoSave && autoSave();
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <TextField
      id={id}
      fullWidth
      select
      variant={variant ?? 'filled'}
      type={type}
      label={label + (optional ? ' (optional)' : '')}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleSelectChange}
      onSelect={(e) => undefined}
      value={selectedOption}
      name={fieldName}
      error={!!touched && !!error}
      helperText={touched && error}
      required={required}
      sx={{
        '.MuiFormLabel-root.Mui-focused': { color: colors.light },
        '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
        '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        '.MuiFilledInput-root:before': { border: 'none' },
        '.Mui-disabled:before': { border: 'none !important' },
        '.MuiFilledInput-root:after': {
          borderBottom: `1px solid ${colors.primary}`,
          borderRadius: 2,
          height: '100%',
        },
        '.MuiFilledInput-input': { height: '22px' },
        '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
        gridColumn: span ? 'span ' + span : undefined,
      }}
      size={size}
    >
      <MenuItem value="">Select {label + (optional ? ' (optional)' : '')}</MenuItem>
      {options?.map((option, index) => (
        <MenuItem key={index} value={option.value} disabled={readOnly}>
          {option.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomSelectField;
