import { PatientAttendance, PatientEnrollment, ProgramScheduleModel } from 'lgu/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'program-schedules';

export const getProgramSchedules = (program_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/program/${program_id}`, {
    params: query,
  });
};

export const getProgramSchedule = (schedule_id: number) => {
  return axios.get(`${baseURL}/${path}/${schedule_id}`);
};

export const createProgramSchedule = (program_id: number, schedule: ProgramScheduleModel) => {
  return axios.post(`${baseURL}/${path}/${program_id}`, schedule);
};

export const updateProgramSchedule = (schedule_id: number, schedule: ProgramScheduleModel) => {
  return axios.put(`${baseURL}/${path}/${schedule_id}`, schedule);
};

export const cancelProgramSchedule = (schedule_id: number) => {
  return axios.delete(`${baseURL}/${path}/${schedule_id}`);
};

export const cloneProgramSchedule = (schedule_id: number, schedule: ProgramScheduleModel) => {
  return axios.post(`${baseURL}/${path}/clone/${schedule_id}`, schedule);
};

export const getProgramScheduleHistory = (program_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/past-program/${program_id}`, {
    params: query,
  });
};

export const unenrollPatient = (schedule_id: number, patients: PatientEnrollment) => {
  return axios.post(`${baseURL}/${path}/unenroll/${schedule_id}`, patients);
};

export const enrollPatient = (schedule_id: number, patients: PatientEnrollment) => {
  return axios.post(`${baseURL}/${path}/enroll/${schedule_id}`, patients);
};

export const updatePatientAttendance = (schedule_id: number, patient: PatientAttendance) => {
  return axios.patch(`${baseURL}/${path}/attendance/${schedule_id}`, patient);
};

export const getProgramSchedulesOfPatient = (patient_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/patient/${patient_id}`, {
    params: query,
  });
};

export const exportPatients = (schedule_id: number, schedule_name: string) => {
  return axios
    .post(
      `${baseURL}/patients/export/schedule/${schedule_id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', schedule_name + '_patients.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
