import { Box, Typography } from '@mui/material';

import { EntityFields } from 'company/entities/utils';
import { generateInstruction } from 'core/utils';

export const MEDICINE_INSTRUCTION_ENTITY_NAME = 'Medicine Instruction';
export interface MedicineInstructionModel {
  id: number;
  instruction: string;
  dosage: string;
  hours_interval: number;
}

export const medicineInstructionFields: EntityFields[] = [
  { fieldName: 'company_id', displayName: 'Company' },
  { fieldName: 'frequency_per_day', displayName: 'Frequency per day (Dosage)', type: 'number' },
  {
    fieldName: 'hours_interval',
    displayName: 'Dosage Interval (in hours)',
    type: 'number',
    hiddenBasedOnOtherField: (data) => {
      return data.frequency_per_day <= 1;
    },
  },
  { fieldName: 'time_to_take', displayName: 'Medication Start Time', type: 'time' },
  { fieldName: 'divider', type: 'divider' },
  {
    fieldName: 'frequency',
    displayName: 'Frequency',
    type: 'select',
    options: [
      { key: 'Daily', value: 'daily' },
      { key: 'Weekly', value: 'weekly' },
      // { key: 'Custom', value: 'custom' },
    ],
  },
  {
    fieldName: 'days_of_week',
    displayName: 'Days of the week',
    type: 'multiselect',
    span: 4,
    options: [
      { key: 'Sunday', value: 'sunday' },
      { key: 'Monday', value: 'monday' },
      { key: 'Tuesday', value: 'tuesday' },
      { key: 'Wednesday', value: 'wednesday' },
      { key: 'Thursday', value: 'thursday' },
      { key: 'Friday', value: 'friday' },
      { key: 'Saturday', value: 'saturday' },
    ],
    hiddenBasedOnOtherField: (data: any) => {
      return !(data.frequency === 'weekly' || data.frequency === 'custom');
    },
  },
  { fieldName: 'instruction_subheader', type: 'subsection_header', subsection_header: 'Instruction' },
  { fieldName: 'duration', type: 'number', displayName: 'Duration of the treatment (in days)' },
  {
    fieldName: 'instruction_template',
    displayName: 'Instruction Template',
    type: 'select',
    span: 4,
    options: [],
    onTemplateUpdate: (value, setFieldValue) => {
      const formattedDaysOfWeek =
        value.days_of_week && value.days_of_week !== null && value.days_of_week !== ''
          ? value.days_of_week.split(',').map((day: string) => ({
              key: day.charAt(0).toUpperCase() + day.slice(1),
              value: day,
            }))
          : [];

      // manual update of other fields
      const { duration = '', frequency = '', frequency_per_day = '', hours_interval = '', instruction = '' } = value;

      // fix for first medicine instructions where other values are null
      setFieldValue('instruction', instruction ?? '');
      setFieldValue('frequency', frequency ?? '');
      setFieldValue('duration', duration ?? '');
      setFieldValue('frequency_per_day', frequency_per_day ?? '');
      setFieldValue('days_of_week', formattedDaysOfWeek ?? '');
      setFieldValue('hours_interval', hours_interval ?? 0);
      setFieldValue('time_to_take', value.time_to_take ?? '');
    },
  },
  {
    fieldName: 'instruction',
    displayName: 'Instruction',
    type: 'text',
    span: 4,
    multiline: true,
    rows: 3,
    // optional: true,
  },
  {
    fieldName: 'full_instruction',
    displayName: 'Instruction',
    renderCell: (params) => <Typography>{generateInstruction(params.row)}</Typography>,
    flex: 4,
  },
  {
    fieldName: 'additional',
    type: 'component',
    span: 4,
    displayComponentBasedOnFormFields: (data) => {
      const instruction = generateInstruction(data);
      return data.frequency ? (
        <Box sx={{ border: '1px solid #239BD7', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ color: '#239BD7' }}>
            {instruction}
          </Typography>
        </Box>
      ) : (
        <></>
      );
    },
  },
];

export const medicineInstructionTableColumn: string[] = ['full_instruction'];

export const medicineInstructionFormFields = [
  'instruction_template',
  'divider',
  'frequency',
  'duration',
  'days_of_week',
  'frequency_per_day',
  'hours_interval',
  'time_to_take',
  'instruction',
  'additional',
];

export const simplifiedMedicineInstructionFormFields = ['instruction', 'additional'];

export const medicineInstructionInitialValues = {
  frequency_per_day: '',
  frequency: '',
  instruction: '',
  duration: '',
  hours_interval: 0,
};
