import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getProduct, getProducts } from 'company/api/products';

const ProductSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Product'}
      getData={getProducts}
      getById={getProduct}
      processOption={(product) => ({ key: product.product_name, value: product.id, data: product })}
      processNewRecord={(record) => record.product}
    />
  );
};

export default ProductSearchDropdown;
