import { Typography } from '@mui/material';
import styled from 'styled-components';

export const SubContainer = styled.div`
  flex: 1;
  width: 100%;
  background: #e4f3fa;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 30px;
  border-left: 8px solid #239bd7;
  position: relative;
  height: 160px;

  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const Labels = styled(Typography)`
  variant: body1;
  font-size: 14px;
  font-weight: 600;
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
