import { ApiQuery, SelectOption } from 'core/model/interface';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomGridCell, CustomModal, CustomSelectField, CustomTable, PrimaryButton } from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { calculateAge, formatDate } from 'core/utils';

/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import FpEnrollModal from '../FP/FpEnrollModal';
import { LoadingButton } from '@mui/lab';
import TbAcfEnrollModal from '../TB/TbAcfEnrollModal';
import TbEnrollModal from '../TB/TbEnrollModal';
import { getNotEnrolledPatients } from 'lgu/api/tb-acf';
import { getPatientsNotEnrolledInProgramReminders } from 'lgu/api/patient';
import { useSnackbar } from 'notistack';

type EnrollToProgramReminderProps = {
  programCode: string;
  options?: SelectOption[];
  refreshEnrolledTable: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EnrollToProgramReminder: React.FC<EnrollToProgramReminderProps> = ({
  programCode,
  options,
  refreshEnrolledTable,
  open,
  setOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [header, setHeader] = useState('');
  const [ocpCount, setOcpCount] = useState<number>(28);
  const [openFamilyPlanningEnrollForm, setOpenFamilyPlanningEnrollForm] = useState<boolean>(false);
  const [openTbDotsEnrollForm, setOpenTbDotsEnrollForm] = useState<boolean>(false);
  const [openTbAcfEnrollForm, setOpenAcfEnrollForm] = useState<boolean>(false);

  const [selectedPatientID, setSelectedPatientID] = useState<number>(0);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [refreshUnenrolled, setRefreshUnenrolled] = useState(0);
  const [selectedPatientIDs, setSelectedPatientIDs] = useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
    },
    { field: 'sex', headerName: 'Sex Assigned At Birth', flex: 1 },
    { field: 'barangay_name', headerName: 'Barangay', flex: 1 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <LoadingButton
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setHeader('Enroll ' + params.row.full_name + '?');
              setSelectedPatientID(params.row.patient_id);
              if (programCode === 'ocp') {
                setOpenFamilyPlanningEnrollForm(true);
              } else if (programCode === 'tb_dots') {
                setOpenTbDotsEnrollForm(true);
              } else {
                setHeader('Choose what stage the patient will start:');
                setOpenAcfEnrollForm(true);
              }
            }}
          >
            Enroll
          </LoadingButton>
        </Box>
      ),
    },
  ];

  const getPatientsList = (query: ApiQuery) => {
    setLoading(true);
    getPatientsNotEnrolledInProgramReminders(selectedProgram, query)
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getPatientsNotEnrolledInTbAcf = () => {
    setLoading(true);
    getNotEnrolledPatients()
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectOcpCount = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setOcpCount(parseInt(event.target.value));
  };

  const handleSelectPatients = (selected: GridRowSelectionModel) => {
    setSelectedPatientIDs(selected);
  };

  const showEnrollPatientDialog = () => {
    if (selectedPatientIDs.length || selectedPatientID) {
      setHeader('Enroll ' + selectedPatientIDs.length + ' patients to the program?');
      if (programCode === 'ocp') {
        setOpenFamilyPlanningEnrollForm(true);
      } else if (programCode === 'tb_dots') {
        setOpenTbDotsEnrollForm(true);
      } else {
        setHeader('Choose what stage the patients (' + selectedPatientIDs.length + ') will start:');
        setOpenAcfEnrollForm(true);
      }
    }
  };

  const refreshNotEnrolledTable = () => {
    setRefreshUnenrolled((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (programCode === 'ocp') {
      setSelectedProgram('ocp');
    } else if (programCode === 'tb_dots') {
      setSelectedProgram('tb_dots');
    } else {
      setSelectedProgram('tb');
    }
  }, []);

  useEffect(() => {
    if (open) {
      setSelectedPatientIDs([]);
    }
  }, [open]);

  const renderContent = () => {
    switch (programCode) {
      case 'ocp':
        return (
          <FpEnrollModal
            header={header}
            open={openFamilyPlanningEnrollForm}
            setOpen={setOpenFamilyPlanningEnrollForm}
            programCode={programCode}
            selectedProgram={selectedProgram}
            selectedPatientIDs={selectedPatientIDs}
            selectedPatientID={selectedPatientID}
            type={'create'}
            ocpCount={ocpCount}
            refreshNotEnrolledTable={refreshNotEnrolledTable}
            refreshEnrolledTable={refreshEnrolledTable}
          />
        );
      case 'tb_dots':
        return (
          <TbEnrollModal
            header={header}
            open={openTbDotsEnrollForm}
            setOpen={setOpenTbDotsEnrollForm}
            selectedProgram={selectedProgram}
            selectedPatientIDs={selectedPatientIDs}
            selectedPatientID={selectedPatientID}
            type={'create'}
            refreshNotEnrolledTable={refreshNotEnrolledTable}
            refreshEnrolledTable={refreshEnrolledTable}
          />
        );
      case 'tb':
        return (
          <TbAcfEnrollModal
            header={header}
            open={openTbAcfEnrollForm}
            setOpen={setOpenAcfEnrollForm}
            programCode={programCode}
            selectedProgram={selectedProgram}
            selectedPatientIDs={selectedPatientIDs}
            selectedPatientID={selectedPatientID}
            type={'create'}
            refreshNotEnrolledTable={refreshNotEnrolledTable}
            refreshEnrolledTable={refreshEnrolledTable}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <CustomModal header={'Enroll Patients'} open={open} setOpen={setOpen} width={1080}>
        {selectedProgram && (
          <Box>
            <Box>
              <CustomTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                loading={loading}
                rowId={'patient_id'}
                getData={selectedProgram !== 'tb' ? getPatientsList : getPatientsNotEnrolledInTbAcf}
                tableHeight={400}
                forceRefresh={refreshUnenrolled}
                headerComponent={
                  <Box display="flex" flexDirection="row" alignItems="flex-end" gap="10px">
                    <PrimaryButton
                      label="Enroll Selected Patients"
                      disabled={!selectedPatientIDs.length}
                      onClick={() => {
                        if (selectedPatientIDs.length > 0) {
                          setSelectedPatientID(0);
                          showEnrollPatientDialog();
                        } else {
                          enqueueSnackbar('Invalid action. Select patients first!', { variant: 'error' });
                        }
                      }}
                    />
                    {options && (
                      <Box width="250px">
                        <CustomSelectField
                          label={'Pill Count'}
                          handleChange={handleSelectOcpCount}
                          fieldName={'ocp_count'}
                          options={options}
                          value={options ? 28 : undefined}
                          size={'small'}
                          variant="standard"
                        />
                      </Box>
                    )}
                  </Box>
                }
                checkboxSelection
                handleSelectRow={handleSelectPatients}
                disableSelectionOnClick
                keepNonExistentRowsSelected={false}
                selectableRow
              />
            </Box>
            {renderContent()}
          </Box>
        )}
      </CustomModal>
    </>
  );
};

export default EnrollToProgramReminder;
