import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getSupplier, getSuppliers } from 'company/api/suppliers';

const SupplierSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Supplier'}
      getData={getSuppliers}
      getById={getSupplier}
      processOption={(record) => ({ key: record.supplier_name, value: record.id })}
      processNewRecord={(record) => record.supplier}
    />
  );
};

export default SupplierSearchDropdown;
