import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useRef, useState } from 'react';
import SignatureSelector, { SIGNATURE_TYPES } from './SignatureSelector';
import {
  blood_typing,
  dental_exam,
  differential_count,
  differential_count_normal_values,
  eye_test,
  fecalysis,
  hematology,
  hematology_normal_values,
  medical_history,
  medical_history2,
  medical_history_index,
  other_tests,
  physical_exam,
  summary_mapping,
  urinalysis,
  vital_signs,
  xray,
} from './source_fields';
import { calculateAge, formatArray, formatDate } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { ContainerColumn } from 'core/components/containers';
import EjectIcon from '@mui/icons-material/Eject';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityDetails } from 'company/screens/DocumentEditor/components/PrintablePatientNote';
import { HealthAssessmentModel } from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentModel';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import { RegularButton } from 'core/components';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';

type Props = {
  patient: PatientModel;
  appointment: CompanyAppointmentModel;
  notes: PatientNoteModel[];
  assessment: HealthAssessmentModel;
  hideRemarksSection?: boolean;
};

const AnnualPhysicalExam: React.FC<Props> = ({ patient, appointment, notes, assessment, hideRemarksSection }) => {
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [printing, setPrinting] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef<HTMLDivElement | null>(null);

  const printNote = () => {
    document.title = `ANNUAL PHYSICAL EXAMINATION - ${patient?.full_name}`;
    setPrintMode(true);
    setPrinting(true);
    setTimeout(() => {
      handlePrint();
      setPrinting(false);
    }, 1000);
    setTimeout(() => {
      document.title = `CareGo Health Suite`;
    }, 2000);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   pageStyle: `
  //   @page {
  //     size: Legal;
  //     margin: 0.635cm;
  //   }
  //   body {
  //     margin: 0;
  //   }
  // `,
  // });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      return new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    },
    onAfterPrint: () => {
      setPrinting(false);
      setPrintMode(false);
    },
    pageStyle: `
      @page {
        size: Legal;
        margin: 0.635cm;
      }
      body {
        margin: 0;
      }
    `,
  });

  const getAnswer = (
    field: string,
    defaultValue?: string,
    occurence?: number,
    prefix?: string,
    columns?: string[]
  ): string => {
    const defValue = defaultValue ?? '-';
    const defOccurence = occurence ?? 1;
    const source_mapping = summary_mapping as any;
    const fieldKey = prefix ? prefix + field : field;
    const mapping = source_mapping[fieldKey];
    if (mapping) {
      const note = notes.find(
        (note) =>
          note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
          note.template_id === mapping.template_id
      );

      if (note) {
        const body = JSON.parse(note.body)[0];
        if (mapping.source_type === 'table') {
          const tableField = body.fields.find(
            (field: any) =>
              (mapping.table_name && field.display_name === mapping.table_name) ||
              (!mapping.table_name && field.type === 'table')
          );

          if (tableField) {
            const questionColumn = tableField.columns.find(
              (column: any) => column.displayName === mapping.table_column_name
            );

            const rowResponses = body.responses[tableField.field_name];
            if (rowResponses) {
              const response = rowResponses.find((response: any) => {
                return response[questionColumn.fieldName] === field;
              });

              if (response) {
                if (columns && columns.length > 0) {
                  // Format columns into a string for easy display
                  return columns
                    .map((columnName) => {
                      const column = tableField.columns.find((col: any) => col.displayName === columnName);
                      return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                    })
                    .join(', ');
                } else {
                  // Default behavior: return only the main answer column
                  const answerColumn = tableField.columns.find(
                    (column: any) => column.displayName === mapping.table_column_name_answer
                  );
                  return response[answerColumn?.fieldName] || defValue;
                }
              }
            }
          }
        } else {
          let count = 0;
          const formField = body.fields.find((item: any) => {
            if (item.display_name?.trim() === field?.trim() && !item.section_header) {
              count++;
              return defOccurence === count;
            }
            return false;
          });

          return body.responses[formField?.field_name] || defValue;
        }
      }
    }

    return defValue;
  };

  if (!(user.company?.company_name === 'CPHI' || user.company?.company_name.includes('St. Elsewhere'))) {
    return <></>;
  }

  const showTickBox = (className: string) => {
    return assessment.class === className ? (
      <CheckBoxOutlinedIcon sx={{ fontSize: '20px', gridRow: 'span 2' }} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: '20px', gridRow: 'span 2' }} />
    );
  };

  return (
    <Accordion
      sx={{
        backgroundColor: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': {
          borderRadius: '15px',
          padding: '0 20px',
          backgroundColor: colors.light_blue_background, // Background color for header
          '& .Mui-expanded': {
            borderRadius: '50px',
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px 5px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<EjectIcon sx={{ rotate: '180deg', color: colors.primary, fontSize: '20px' }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        View Summarized Form
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <RegularButton onClick={() => printNote()} label="Print" startIcon={<LocalPrintshopOutlinedIcon />} />
        </Box>
        <Box
          ref={componentRef}
          sx={{
            padding: 2,
            '& .body p': { fontSize: '12px', textWrap: 'wrap', wordBreak: 'break-all' },
            '& .body': { padding: '5px 3px', margin: '5px 0' },
            '& .header p': { fontSize: '13px', padding: '5px 3px', margin: '5px 0', fontWeight: 600 },
            '& p.header': { fontSize: '13px', padding: '5px 3px', margin: '5px 0', fontWeight: 600 },
            '& p.smaller': { fontSize: '10px', paddingRight: '10px' },
          }}
        >
          <ContainerColumn sx={{ alignItems: 'center', mb: SIZES.paddingL, position: 'relative' }}>
            <img
              alt="profile-user"
              width="80px"
              height="auto"
              id="logo"
              style={{ position: 'absolute', left: 0, top: 0 }}
              src={
                theme.palette.mode === 'light'
                  ? user.company?.company_logo
                    ? user.company?.company_logo
                    : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
                  : user.company?.company_logo_dark
                  ? user.company?.company_logo_dark
                  : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
              }
            />
            <ContainerColumn gap="5px" sx={{ width: '60%', textAlign: 'center' }}>
              <Typography variant="h2" fontWeight="bold" textAlign="center">
                {facility.facility_name}
              </Typography>
              <FacilityDetails
                Icon={CallOutlinedIcon}
                label={formatArray([facility?.smart_mobile_number, facility?.globe_mobile_number], ' | ')}
              />
              <FacilityDetails Icon={EmailOutlinedIcon} label={facility.email} />
              <FacilityDetails
                Icon={LocationOnOutlinedIcon}
                label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
              />
            </ContainerColumn>
          </ContainerColumn>
          <Box>
            {/* 1st Row */}
            <Box>
              <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                <Typography textAlign="center" fontWeight="bold">
                  ANNUAL PHYSICAL EXAMINATION
                </Typography>
              </Box>

              <Box className="body" display="grid" gridTemplateColumns="100px 1fr 100px 1fr 100px 1fr">
                <Typography fontWeight={600}>Name:</Typography>
                <Typography>
                  {patient.first_name} {patient.last_name}
                </Typography>
                <Typography fontWeight={600}>Age:</Typography>
                <Typography>{calculateAge(patient.birthday)}</Typography>
                <Typography fontWeight={600}>ID No.:</Typography>
                <Typography>{patient.employee_id}</Typography>
                <Typography fontWeight={600}>Address:</Typography>
                <Typography>{formatArray([patient.address, patient.province_city])}</Typography>
                <Typography fontWeight={600}>Gender:</Typography>
                <Typography>{patient.sex}</Typography>
                <Typography fontWeight={600}>Company:</Typography>
                <Typography>{patient.company_name}</Typography>
                <Typography fontWeight={600}>Exam Date:</Typography>
                <Typography>{formatDate(appointment.schedule)}</Typography>
                <Typography fontWeight={600}>Civil Status:</Typography>
                <Typography>{patient.civil_status}</Typography>
                <Typography fontWeight={600}>Department:</Typography>
                <Typography>{patient.department_name}</Typography>
              </Box>
            </Box>

            {/* 2nd Row */}
            <Box>
              <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                <Typography fontWeight={'500'}>I. MEDICAL HISTORY</Typography>
              </Box>
              <Grid container className="body">
                <Grid item xs={12}>
                  <Typography>A. Present Symptoms: {getAnswer(medical_history2[0], 'Unremarkable')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>B. Past Medical History</Typography>
                </Grid>
                <Grid item xs={12} paddingLeft={SIZES.padding}>
                  <Box display="grid" gridTemplateColumns="1fr 30px 1fr 30px 1fr 30px" columnGap="5px">
                    {medical_history.map((history, index) => (
                      <Fragment key={index}>
                        <Box display="flex" flexDirection="column">
                          <Typography>
                            {medical_history_index[index]}. {history}:
                          </Typography>
                          <Typography gridColumn="span 2">{getAnswer(history, '', 1, '', ['More Info'])}</Typography>
                        </Box>
                        <Typography>{getAnswer(history)}</Typography>
                      </Fragment>
                    ))}
                    <Box sx={{ gridColumn: 'span 2' }}>
                      <Typography>15. Others</Typography>
                      <Typography>{getAnswer(medical_history2[1], '-')}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 100px 1fr 100px 1fr 100px"
                    columnGap="10px"
                    alignItems="flex-end"
                  >
                    <Box gridColumn="span 4">
                      <Typography>C. Family Medical History: {getAnswer(medical_history2[2], '-')}</Typography>
                      <Typography>D. Operations and Accidents: {getAnswer(medical_history2[3], '-')}</Typography>
                      <Typography>E. Allergies: {getAnswer(medical_history2[4], '-')}</Typography>
                      <Typography>F. OB & Menstrual History: {getAnswer(medical_history2[5], '-')}</Typography>
                      <Typography sx={{ paddingLeft: '77px' }}>
                        OB Score: {getAnswer(medical_history2[6], '-')}
                      </Typography>
                      <Typography>
                        G. Personal & Social History:{' '}
                        {getAnswer(medical_history2[7]) !== '-' || getAnswer(medical_history2[9]) !== '-'
                          ? getAnswer(medical_history2[7]) === 'Non-smoker'
                            ? getAnswer(medical_history2[7])
                            : `${getAnswer(medical_history2[7]) !== '-' ? getAnswer(medical_history2[7]) : ''}${
                                getAnswer(medical_history2[8]) && getAnswer(medical_history2[7]) !== '-'
                                  ? ` (${getAnswer(medical_history2[8])})`
                                  : ''
                              }${
                                getAnswer(medical_history2[9]) !== '-'
                                  ? `${getAnswer(medical_history2[7]) !== '-' ? ', ' : ''}${getAnswer(
                                      medical_history2[9]
                                    )}`
                                  : ''
                              }`
                          : '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* 3rd Row */}
            <Box>
              <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                <Typography fontWeight={'500'}>II. VITAL SIGNS AND PHYSICAL EXAM</Typography>
              </Box>

              <Grid className="body" container>
                {/* Main Column 1 */}
                <Grid item xs={6}>
                  <Box display="grid" gridTemplateColumns="1fr 0.8fr 0.8fr">
                    <Typography sx={{ gridColumn: 'span 3' }}>A. Vitals Signs</Typography>
                    <Typography>1. Height</Typography>
                    {getAnswer(vital_signs[0], '').replace("'", '').trim() ? (
                      <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[0], '0')} (ft'in)</Typography>
                    ) : (
                      <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[1], '0')} m</Typography>
                    )}

                    <Typography>2. Weight</Typography>
                    {getAnswer(vital_signs[2], '').trim() ? (
                      <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[2], '0')} lbs</Typography>
                    ) : (
                      <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[10], '0')} kg</Typography>
                    )}

                    <Typography paddingLeft="20px">BMI</Typography>
                    <Box sx={{ gridColumn: 'span 2', display: 'flex', gap: '10px' }}>
                      <Typography sx={{ width: '60px' }}>
                        {getAnswer(vital_signs[3], '-', getAnswer(vital_signs[2], '') ? 1 : 2)}
                      </Typography>
                      <Typography>BMI Class: {getAnswer(vital_signs[4])}</Typography>
                    </Box>

                    <Typography>3. Blood Pressure</Typography>
                    <Box sx={{ gridColumn: 'span 2', display: 'flex', gap: '10px' }}>
                      <Typography sx={{ width: '60px' }}>{getAnswer(vital_signs[5], '0')} mmHg</Typography>
                      <Typography>BP Class: {getAnswer(vital_signs[9])}</Typography>
                    </Box>

                    <Typography>4. Pulse Rate</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[6], '0')} per min</Typography>

                    <Typography>5. Respiratory Rate</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[7], '0')} per min</Typography>

                    <Typography>6. Temperature</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(vital_signs[8], '0')} °C</Typography>

                    <Typography>B. Eye Test</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(eye_test[0])}</Typography>

                    <Typography></Typography>
                    <Typography>Uncorrected</Typography>
                    <Typography>Corrected</Typography>

                    <Typography>Far Vision</Typography>
                    <Typography>OD: {getAnswer(eye_test[1])}</Typography>
                    <Typography>OD: {getAnswer(eye_test[1], '-', 2)}</Typography>

                    <Typography></Typography>
                    <Typography>OS: {getAnswer(eye_test[2])}</Typography>
                    <Typography>OS: {getAnswer(eye_test[2], '-', 2)}</Typography>

                    <Typography>Near Vision</Typography>
                    <Typography>OU: {getAnswer(eye_test[3])}</Typography>
                    <Typography>OU: {getAnswer(eye_test[3], '-', 2)}</Typography>

                    <Typography>Color Vision (Ishihara)</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>{getAnswer(eye_test[4])}</Typography>
                  </Box>
                </Grid>

                {/* Main Column 2 */}
                <Grid item xs={3}>
                  <Box display="grid" gridTemplateColumns="1fr" columnGap="10px">
                    <Typography sx={{ gridColumn: 'span 2' }}>C. Dental Exam</Typography>
                    <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>For Extraction: {getAnswer(dental_exam[0])}</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>For Restoration: {getAnswer(dental_exam[1])}</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>
                      For Oral Prophylaxis: {getAnswer(dental_exam[2])}
                    </Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>Missing: {getAnswer(dental_exam[3])}</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>Dentures: {getAnswer(dental_exam[4])}</Typography>
                    <Typography sx={{ gridColumn: 'span 2' }}>Others: {getAnswer(dental_exam[5])}</Typography>

                    <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>
                  </Box>
                </Grid>

                {/* Main Column 3 */}
                <Grid item xs={3}>
                  <Box display="grid" gridTemplateColumns="1fr 1fr">
                    <Typography sx={{ gridColumn: 'span 2' }}>D. Physical Exam</Typography>

                    <Typography></Typography>
                    <Typography>Findings</Typography>

                    {physical_exam.map((exam, index) => (
                      <Fragment key={index}>
                        <Typography>{exam}:</Typography>
                        <Typography>{getAnswer(exam)}</Typography>
                      </Fragment>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                <Typography>III. LABORATORY</Typography>
              </Box>

              <Grid className="body" container spacing={0.5}>
                {/* Main Column 1 */}
                <Grid item xs={4}>
                  <Box>
                    <Typography fontWeight="600">A. Hematology</Typography>
                    <Box display="grid" gridTemplateColumns="5fr 3fr 5fr" columnGap="7px">
                      <Typography textAlign="center" fontWeight={600}>
                        Test
                      </Typography>
                      <Typography fontWeight={600}>Result</Typography>
                      <Typography fontWeight={600}>Normal values</Typography>

                      {hematology.map((exam, index) => (
                        <Fragment key={index}>
                          <Typography className={index > 4 ? 'smaller' : undefined}>{exam}:</Typography>
                          <Typography>
                            {getAnswer(exam)}{' '}
                            {(() => {
                              const level = getAnswer(exam, '', 1, '', ['Level']);
                              const levelSuffixMap = {
                                'Level: Low': '(L)',
                                'Level: Normal': '',
                                'Level: High': '(H)',
                              };

                              if (level === 'Level: Low' || level === 'Level: Normal' || level === 'Level: High') {
                                return levelSuffixMap[level];
                              }
                              return '';
                            })()}
                          </Typography>
                          <Typography className={'smaller'}>{hematology_normal_values[index]}</Typography>

                          {index === 4 && (
                            <>
                              <Typography fontWeight={600}>Differential Count</Typography>
                              <Typography></Typography>
                              <Typography></Typography>
                            </>
                          )}
                        </Fragment>
                      ))}

                      <>
                        {differential_count.map((exam, index) => (
                          <Fragment key={index}>
                            <Typography className={index > 4 ? 'smaller' : undefined}>{exam}:</Typography>
                            <Typography>
                              {getAnswer(exam, '', 1, 'DC_')}{' '}
                              {(() => {
                                const level = getAnswer(exam, '', 1, 'DC_', ['Level']);
                                const levelSuffixMap = {
                                  'Level: Low': '(L)',
                                  'Level: Normal': '',
                                  'Level: High': '(H)',
                                };

                                if (level === 'Level: Low' || level === 'Level: Normal' || level === 'Level: High') {
                                  return levelSuffixMap[level];
                                }
                                return '';
                              })()}
                            </Typography>
                            <Typography className={'smaller'}>{differential_count_normal_values[index]}</Typography>
                          </Fragment>
                        ))}
                      </>
                    </Box>
                  </Box>
                </Grid>

                {/* Main Column 2 */}
                <Grid item xs={3}>
                  <Box>
                    <Typography fontWeight="600">B. Urinalysis</Typography>
                    <Box display="grid" gridTemplateColumns="1.3fr 1fr" columnGap="5px">
                      <Typography></Typography>
                      <Typography fontWeight={600}>Result</Typography>

                      {urinalysis.map((exam, index) => (
                        <Fragment key={index}>
                          <Typography>{exam}:</Typography>
                          <Typography>
                            {getAnswer(exam, '-', 1, 'U_')} {exam === 'WBC' || exam === 'RBC' ? '/HPF' : ''}
                          </Typography>
                        </Fragment>
                      ))}

                      <Typography>Remarks</Typography>
                      <Typography>{getAnswer('Remarks (Significant Findings based on Result)')}</Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Main Column 3 */}
                <Grid item xs={3}>
                  <Box>
                    <Typography fontWeight="600">C. Fecalysis</Typography>
                    <Box display="grid" gridTemplateColumns="1.3fr 1fr" columnGap="5px">
                      <Typography></Typography>
                      <Typography fontWeight={600}>Result</Typography>
                      {fecalysis.map((exam, index) =>
                        exam === 'Parasite Seen' ? (
                          <></>
                        ) : (
                          <Fragment key={index}>
                            <Typography>{exam}:</Typography>
                            {exam === 'Parasites' ? (
                              <Typography sx={{ gridColumn: 'span 1' }}>
                                {getAnswer(exam, '', 1, 'F_') === 'Parasite Seen'
                                  ? getAnswer(fecalysis[5], 'Parasite Seen', 1, 'F_')
                                  : getAnswer(exam, '', 1, 'F_')}
                              </Typography>
                            ) : (
                              <Typography>
                                {getAnswer(exam, '-', 1, 'F_')} {exam === 'WBC' || exam === 'RBC' ? '/HPF' : ''}
                              </Typography>
                            )}
                          </Fragment>
                        )
                      )}

                      <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>

                      {/* <Typography fontWeight={600}>D. Pregnancy Test</Typography>
                      <Typography></Typography> */}
                      <Typography fontWeight="600" sx={{ gridColumn: 'span 2' }}>
                        D. Drug Test
                      </Typography>

                      <Typography fontWeight={600}>Result</Typography>
                      <Typography>{getAnswer(other_tests[0])}</Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Main Column 4 */}
                <Grid item xs={2} position="relative">
                  <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap="5px">
                    <Typography fontWeight="600" sx={{ gridColumn: 'span 2' }}>
                      E. Hepatitis B Test
                    </Typography>

                    <Typography fontWeight={600}>Result</Typography>
                    <Typography>{getAnswer('Hepatitis B Result')}</Typography>

                    <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>

                    <Typography fontWeight="600" sx={{ gridColumn: 'span 2' }}>
                      F. Blood Typing
                    </Typography>
                    <Typography fontWeight={600}>Result</Typography>
                    <Typography>
                      {getAnswer('Blood Typing', '-')} {getAnswer('RH Typing', '')}
                    </Typography>
                    <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>

                    <Typography fontWeight="600" sx={{ gridColumn: 'span 2' }}>
                      G. Blood Chemistry
                    </Typography>
                    <Typography fontWeight={600}>Result</Typography>
                    <Typography>{getAnswer(other_tests[1])}</Typography>

                    {/* <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography> */}

                    {/* <Box sx={{ gridColumn: 'span 2', height: '1rem' }} /> */}
                  </Box>
                  <Box
                    sx={{
                      // position: 'absolute',
                      width: '200px',
                      ml: 'auto',
                      textAlign: 'center',
                      right: 50,
                      mt: '30px',
                      marginLeft: '-65px',
                    }}
                  >
                    <SignatureSelector
                      type={SIGNATURE_TYPES.MEDTECH}
                      isPrinting={printMode}
                      defaultStaff={{
                        name: 'Jade Ella B. Vergara, RMT',
                        title: 'Medical Technologist',
                        licenseNumber: 'LN 0114334',
                        signature: '/elsewhere_signatory/Jade Ella Vergara.png',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* 4th row */}
            <Box>
              <Box display="grid" gridTemplateColumns="1fr 1fr">
                <Typography className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                  IV. RADIOLOGIC FINDINGS
                </Typography>

                <Typography className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                  V. ELECTROCARDIOGRAM
                </Typography>

                <Box className="body" sx={{ gridRow: 'span 3' }}>
                  <Typography>Result:</Typography>
                  <Typography>{getAnswer(xray[0])}</Typography>
                </Box>

                <Box className="body" sx={{ gridRow: 'span 3' }}>
                  <Typography>Findings:</Typography>
                  <Typography>{getAnswer(other_tests[2])}</Typography>
                </Box>

                <Box className="body" sx={{ gridRow: 'span 3' }}>
                  <Typography>Impression:</Typography>
                  <Typography>{getAnswer(xray[1])}</Typography>
                </Box>

                <Box className="body" sx={{ gridRow: 'span 3' }}>
                  <Typography></Typography>
                  <Typography></Typography>
                </Box>

                <Box className="body" sx={{ gridRow: 'span 3' }} textAlign="center">
                  <SignatureSelector
                    type={SIGNATURE_TYPES.RADTECH}
                    isPrinting={printMode}
                    defaultStaff={{
                      name: 'Joselito Espeleta Dilla',
                      title: 'Radiological Technologist',
                      licenseNumber: 'LN 4630',
                      signature: '/elsewhere_signatory/Joselito Dilla.png',
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* 5th row */}
            {/* {(!hideRemarksSection || printing) && ( */}
            <Box>
              <Grid className="body" container>
                {/* Main Column 1 */}
                <Grid item xs={6}>
                  <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                    <Typography fontWeight={'500'}> VI. REMARKS AND RECOMMENDATION</Typography>
                  </Box>

                  <Box className="body" sx={{ gridRow: 'span 3' }}>
                    <Typography>Findings:</Typography>
                    <Typography>{assessment.findings}</Typography>
                  </Box>

                  <Box className="body" sx={{ gridRow: 'span 3' }}>
                    <Typography>Recommendation:</Typography>
                    <Typography>{assessment.recommendations}</Typography>
                  </Box>

                  <Box display="column" gap="10px" mt="10px" textAlign="center">
                    <SignatureSelector
                      type={SIGNATURE_TYPES.PHYSICIAN}
                      isPrinting={printMode}
                      signatureWidth="60px"
                      defaultStaff={{
                        name: 'Francis Terukazu Sunagawa, MD',
                        title: 'Evaluating Physician',
                        licenseNumber: 'LN 96317',
                        signature: '/elsewhere_signatory/Francis Sunagawa.png',
                      }}
                    />
                  </Box>
                </Grid>

                {/* Main Column 2 */}
                <Grid item xs={6}>
                  <Typography className="header" sx={{ backgroundColor: '#a5d4a0' }}>
                    VII. CLASSIFICATION
                  </Typography>

                  <Box display="grid" gridTemplateColumns="1fr 8fr">
                    {showTickBox('A')}
                    <Typography>CLASS A: Physically fit for all types of work.</Typography>
                    <Typography>No physical defect noted.</Typography>

                    {showTickBox('B')}
                    <Typography>CLASS B: Physically fit for all types of work.</Typography>
                    <Typography>Has minor ailment/defect. Easily curable or offers no handicap.</Typography>

                    {showTickBox('C')}
                    <Typography>CLASS C: Employment at risk and discretion</Typography>
                    <Typography>of management consider</Typography>

                    {showTickBox('Pending')}
                    <Typography>PENDING for further evaluation</Typography>

                    {/* <Typography sx={{ gridColumn: 'span 2' }}>-</Typography>
                      <Typography sx={{ gridColumn: 'span 2' }}>(Add Encoder)</Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnualPhysicalExam;
