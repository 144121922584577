import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CustomTabs } from 'core/components';
import SmsCredits from './SmsCredits';
import SmsTemplates from './SmsTemplates';
import { TabProps } from 'core/components/CustomTabs';

const SMS = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      label: 'SMS Templates',
      content: <SmsTemplates />,
    },
    {
      label: 'SMS Credits',
      content: <SmsCredits />,
    },
  ];

  useEffect(() => {
    setBreadcrumb([
      {
        label: 'SMS',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default SMS;
