import { ApiQuery } from 'core/model/interface';
import { LguUserModel } from 'carego-admin/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'lgu-user-accounts';

export const getUserAccounts = (lgu_code: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${lgu_code}`, {
    params: query,
  });
};

export const createUserAccount = (lgu_user: LguUserModel) => {
  return axios.post(`${baseURL}/${path}`, lgu_user);
};

export const deactivate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const reactivate = (id: number) => {
  return axios.patch(`${baseURL}/${path}/reactivate/${id}`);
};

export const exportUserAccounts = (query: ApiQuery, lgu_code: string) => {
  return axios
    .post(
      `${baseURL}/${path}/export/${lgu_code}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'lgu_user_accounts.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const updatePassword = (id: number, password: string) => {
  return axios.patch(`${baseURL}/${path}/update-password/${id}`, password);
};
