import {
  SetProgramRemindersSchedule,
  TbAcfScreeningEnrollmentModel,
  TbAcfScreeningResult,
  TbAcfSputumEnrollmentModel,
  TbAcfSputumResult,
  TbAcfStageModel,
  TbAcfUpdateCheckupModel,
  TbAcfUpdateScreeningModel,
  TbAcfUpdateSputumModel,
} from 'lgu/model/Entities';

import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'tb-acf';

export const getEnrolledPatients = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, { params: query });
};

export const enrollPatient = (
  patients: TbAcfScreeningEnrollmentModel | TbAcfSputumEnrollmentModel | TbAcfStageModel
) => {
  return axios.post(`${baseURL}/${path}`, patients);
};

export const unenrollPatient = (patients: any) => {
  return axios.delete(`${baseURL}/${path}`, { data: patients });
};

export const updatePatient = (
  patients: TbAcfScreeningEnrollmentModel | TbAcfSputumEnrollmentModel | TbAcfStageModel
) => {
  return axios.patch(`${baseURL}/${path}`, patients);
};

export const getNotEnrolledPatients = () => {
  return axios.get(`${baseURL}/${path}/patients/not-enrolled`);
};

export const getTbAcfPatient = (patient_id: number) => {
  return axios.get(`${baseURL}/${path}/${patient_id}`);
};

export const updateStage = (patients: TbAcfStageModel) => {
  return axios.patch(`${baseURL}/${path}/stage`, patients);
};

export const updateScreeningDetails = (patients: TbAcfUpdateScreeningModel) => {
  return axios.patch(`${baseURL}/${path}/screening`, patients);
};

export const updateSputumDetails = (patients: TbAcfUpdateSputumModel) => {
  return axios.patch(`${baseURL}/${path}/sputum`, patients);
};

export const updateCheckupDetails = (patients: TbAcfUpdateCheckupModel) => {
  return axios.patch(`${baseURL}/${path}/checkup`, patients);
};

export const updateScreeningResult = (patients: TbAcfScreeningResult) => {
  return axios.patch(`${baseURL}/${path}/screening-result`, patients);
};

export const updateSputumResult = (patients: TbAcfSputumResult) => {
  return axios.patch(`${baseURL}/${path}/sputum-result`, patients);
};

export const saveReferredHospital = (id: number, referred_hospital: string) => {
  return axios.put(`${baseURL}/${path}/referred-hospital/${id}`, referred_hospital);
};

export const enrollToTbDots = (patients: SetProgramRemindersSchedule) => {
  return axios.patch(`${baseURL}/${path}/tb-dots-enrollment`, patients);
};

export const unenrollToTbDots = (patients: SetProgramRemindersSchedule) => {
  return axios.patch(`${baseURL}/${path}/tb-dots-unenrollment`, patients);
};
