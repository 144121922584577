import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'email-templates';

export const getAll = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createTemplate = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateTemplate = (template_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${template_id}`, data);
};

export const updateTemplateBody = (template_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/body/${template_id}`, data);
};

export const deleteTemplate = (template_id: number) => {
  return axios.delete(`${baseURL}/${path}/${template_id}`);
};
